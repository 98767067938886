import React, { useEffect, useRef, useState } from 'react';
import {
  Row,
  Col,
  Table,
  Tag,
  Popover,
  Button,
  Modal,
  Popconfirm,
  Form
} from 'antd';
import {
  InfoCircleOutlined,
  PlusCircleOutlined,
  WarningOutlined,
  ReloadOutlined
} from '@ant-design/icons';
import { SelectCustom, SwitchCustom } from './Filters';
import Actions from './Actions';
import NotificationsCurrent from './NotificationsCurrent';
import Countries from './Countries';
import Selling from './Selling';
import UsersUpload from '../UsersUpload';
import { getUsers, getVendors, getRouting } from '../Users/services';
import {
  getPricelist as getPricelistReq,
  updateUsers
} from '../Users/services';
import ModalForceUpdate from './ModalForceUpdate';
import { useAppContext } from '../../libs/context';
import { addKey } from '../../libs/helpers';
import notificationUI from '../../libs/notificationUI';
import { DEFAULT_USERS } from './../../config';
import { capitalize } from '../../libs/helpers';

const emojis = {
  ignacio: '🤑',
  jose: '🐵',
  nuria: '🧙🏻‍♀️',
  lia: '🌸',
  maja: '🐉',
  milica: '🐺',
  fadi: '🦸🏻‍♂️'
};

const UsersManagement = () => {
  const { userInfo, isSleepy } = useAppContext();
  const [routesChanges, setRoutesChanges] = useState([]);
  const [userIdEditing, setUserIdEditing] = useState('');
  const [loading, setLoading] = useState(true);
  const [loadingCozSaving, setLoadingCozSaving] = useState(false);
  const [loadingExtendable, setLoadingExtendable] = useState(false);
  const [userFiltered, setUserFiltered] = useState(false);
  const [userSelectValue, setUserSelectValue] = useState('User');
  const [usersPricelist, setUsersPricelist] = useState({});
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [users, setUsers] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [isMultiselector, setIsMultiselector] = useState([]);
  const [showAddCountries, setShowAddCountries] = useState(false);
  const [userAddingCountries, setUserAddingCountries] = useState(true);
  const [routing, setRouting] = useState([]);
  const [filters, setFilters] = useState({});
  const [streamingData, setStreamingData] = useState([]);
  const formRef = useRef();
  const filtersDefault = {
    user: 'User',
    mcc: 'Country',
    vendor: 'Vendor',
    table: 'Table',
    lowProfit: 'Low Profit',
    custom: 'Custom'
  };
  const customParams = {
    filters: filters,
    userSelectValue: userSelectValue,
    users: users,
    vendors: vendors,
    filtersDefault: filtersDefault,
    userIdEditing: userIdEditing,
    usersPricelist: usersPricelist,
    routing: routing,
    setLoadingExtendable: setLoadingExtendable,
    setFilters: setFilters,
    setUserFiltered: setUserFiltered,
    setUserSelectValue: setUserSelectValue,
    setExpandedRowKeys: setExpandedRowKeys,
    setUsersPricelist: setUsersPricelist
  };
  const [isForceUpdating, setIsForceUpdating] = useState(false);

  const getPricelist = async (isOpen, row) => {
    if (isOpen) {
      setExpandedRowKeys([row.id]);
      if (!usersPricelist?.[row.id]) {
        setLoadingExtendable(true);
        const pricelist = await getPricelistReq({
          userId: row.id
        });
        setUsersPricelist({ ...usersPricelist, ...pricelist });
        setLoadingExtendable(false);
      }
    } else {
      setExpandedRowKeys([]);
    }
  };

  const updateUserOpenPricelist = async () => {
    if (expandedRowKeys.length) {
      const oldPricelist = usersPricelist[expandedRowKeys];
      const newPricelist = await getPricelistReq({
        userId: expandedRowKeys[0]
      });
      if (
        JSON.stringify(oldPricelist) !==
        JSON.stringify(newPricelist[expandedRowKeys])
      ) {
        setUsersPricelist({ ...usersPricelist, ...newPricelist });
        notificationUI({
          state: 'success',
          action: 'changed',
          what: 'Some routes details of this user have'
        });
      }
    }
  };

  const getUsersReq = async userId => {
    const users = await getUsers();
    // key must be set, if not, all expandables are open at the same time
    const usersWithKey = users.map(u => {
      u.key = u.id;
      u.name = u.name.includes('Default')
        ? DEFAULT_USERS.find(us => us.id === u.id)?.name
        : u.name;
      return u;
    });
    setUsers(usersWithKey);
    setLoading(false);
  };

  const getVendorsReq = async () => {
    const vendors = await getVendors();
    setVendors(vendors.map(({ vendor }) => vendor));
  };

  const resetTable = () => {
    setUsersPricelist({});
    setExpandedRowKeys([]);
    setIsMultiselector([]);
    setRoutesChanges([]);
    setUserIdEditing('');
    setFilters({});
    formRef.current.setFieldsValue({
      User: 'User',
      Country: 'Country',
      Vendor: 'Vendor',
      Table: 'Table'
    });
    setUserFiltered(users);
  };
  const resetChanges = () => {
    // setFilters({}); keep filters
    // setUsersPricelist({});
    // setExpandedRowKeys([]);
    // setIsMultiselector([]);
    // setRoutesChanges([]);
    // setUserIdEditing('');
  };

  const getRoutingRequest = async () => {
    const routingReq = await getRouting();
    setRouting(routingReq);
  };

  useEffect(() => {
    getUsersReq();
    getVendorsReq();
    getRoutingRequest();
  }, []);

  useEffect(() => {
    const savingUsersPricelists = streamingData.find(
      ({ action }) => action === 'savingUsersPricelists'
    );
    if (savingUsersPricelists?.value === false && loadingCozSaving) {
      setLoadingCozSaving(false);
      notificationUI({
        state: 'success',
        action: 'syncing',
        what: 'data in tadoo db'
      });
    }
    const forceUpdate = streamingData.find(
      ({ action }) => action === 'forceUpdate'
    );
    if (forceUpdate?.value && !isForceUpdating) {
      const userName = forceUpdate.user.split('@')[0];
      const who =
        userInfo.email === forceUpdate.user
          ? 'you'
          : `your colleage ${capitalize(userName)} ${emojis?.[userName]}`;
      notificationUI({
        state: 'success',
        action: 'activated',
        what: `${who} - Force update`
      });
      setIsForceUpdating(true);
    }
    if (!forceUpdate?.value && isForceUpdating) {
      // message.success('Force update has finished');
      notificationUI({
        state: 'success',
        action: 'finished',
        what: 'Force update has'
      });
      updateUserOpenPricelist();
      setIsForceUpdating(false);
    }
  }, [streamingData]);

  const columns = [
    {
      title: 'User',
      render: current =>
        current?.name?.includes('Default')
          ? DEFAULT_USERS.find(u => u.id === current.id)?.name
          : current.name
    },
    {
      title: 'Balance €',
      dataIndex: 'balance',
      render: b => {
        const balance = b.toFixed(2);
        return <Tag color={balance >= 1000 ? 'blue' : 'orange'}>{balance}</Tag>;
      }
    },
    {
      title: 'Actions',
      render: current =>
        userInfo?.isAdmin && (
          <Actions
            current={current}
            userIdEditing={userIdEditing}
            setUserIdEditing={setUserIdEditing}
            isMultiselector={isMultiselector}
            setIsMultiselector={setIsMultiselector}
            routesChanges={routesChanges}
            setRoutesChanges={setRoutesChanges}
            resetTable={resetTable}
            resetChanges={resetTable}
            setLoadingCozSaving={setLoadingCozSaving}
            isForceUpdating={isForceUpdating}
          />
        )
    },
    {
      title: 'Add countries',
      align: 'center',
      render: r => (
        <a>
          <PlusCircleOutlined
            onClick={() => {
              setUserAddingCountries(r.id);
              setShowAddCountries(true);
              setUserIdEditing(r.id);
              if (!usersPricelist?.[r.id]) {
                getPricelist(true, r);
              }
            }}
          />
        </a>
      )
    },
    {
      title: 'Emails RN',
      dataIndex: 'address',
      align: 'center',
      render: address => (
        <Popover
          content={address?.split(',').map((a, idx) => (
            <div style={{ marginBottom: '3px' }} key={++idx}>
              <Tag color="green">
                {a}
                <br />
              </Tag>
            </div>
          ))}
        >
          <Tag color="blue">{address ? address.split(',').length : 0}</Tag>
        </Popover>
      )
    },
    {
      title: 'Send RN',
      align: 'center',
      render: current =>
        userInfo &&
        userInfo.isAdmin && (
          <NotificationsCurrent
            current={current}
            pricelist={usersPricelist[current.id]}
          />
        )
    },
    {
      title: (
        <span style={{ visibility: 'hidden' }}>
          '____________________________________________'
        </span>
      )
    }
  ];

  const expandedRow = row => {
    const columns = [
      {
        title: 'Country',
        dataIndex: '_',
        render: (_, rowData) => (
          <Countries
            usersPricelist={usersPricelist}
            userIdEditing={userIdEditing}
            user={row}
            row={rowData}
            routesChanges={routesChanges}
            setRoutesChanges={setRoutesChanges}
            isMultiselector={isMultiselector}
          />
        )
      },
      {
        title: 'Operator',
        dataIndex: 'network',
        render: network => (
          <span>
            {network === 'Default' ? (
              <Tag color="blue"> {network} </Tag>
            ) : network && network.length > 25 ? (
              `${network.slice(0, 25)}...`
            ) : (
              network
            )}
          </span>
        )
      },
      {
        title: 'MCC',
        dataIndex: 'mcc'
      },
      {
        title: 'MNC',
        dataIndex: 'mnc'
      },
      {
        title: 'Vendor',
        dataIndex: 'vendor',
        render: vendor => (
          <span>
            {`${vendor}`.includes('issue') ? (
              <Tag color="red"> {vendor} </Tag>
            ) : (
              `${vendor}`
            )}
          </span>
        )
      },
      {
        title: 'Table',
        dataIndex: 'table',
        render: (_, r) =>
          r?.tableSteps && r.tableSteps.length > 1 ? (
            <Popover
              content={
                <div>
                  <span>{`${r.tableSteps.join(' → ')}`}</span>
                </div>
              }
            >
              <span>
                <span
                  style={{
                    marginRight: '5px'
                  }}
                >
                  {r.table}
                </span>
                <InfoCircleOutlined />
              </span>
            </Popover>
          ) : (
            r.table
          )
      },
      {
        title: 'Buying €',
        dataIndex: 'cost',
        render: (_, r) =>
          r.cost ? (
            r?.mnpCost ? (
              <Popover
                content={
                  <div>
                    <span>
                      {`Vendor: ${(r.cost - r.mnpCost).toFixed(5)}`}
                      <br />
                    </span>
                    <span>
                      {`MNP___: ${r.mnpCost}`}
                      <br />
                    </span>
                  </div>
                }
              >
                <span>
                  <Tag color="blue">
                    <span>{r.cost}</span>
                    <InfoCircleOutlined />
                  </Tag>
                </span>
              </Popover>
            ) : r?.filter ? (
              <Popover
                content={r?.filter.map((filter, idx) => {
                  let limit;
                  if (filter.type === 'limit') {
                    limit = r.tableSteps
                      .map(str => {
                        const match = str.match(/_(\d+)K_/);
                        return match ? parseInt(match[1], 10) : null;
                      })
                      .find(num => num !== null);
                  }
                  return (
                    <div>
                      <span>
                        {filter.type === 'balance'
                          ? `${filter.value}% → ${filter.vendor} | cost: ${filter.cost}€ | profit: ${filter.profit}€`
                          : `${idx === 0 ? `${limit}K` : 'Rest'} → ${
                              filter.vendor
                            } | cost: ${filter.cost}€ | profit: ${
                              filter.profit
                            }€`}
                        <br />
                      </span>
                    </div>
                  );
                })}
              >
                <span>
                  <Tag color="blue">
                    <span>{r.cost}</span>
                    <InfoCircleOutlined />
                  </Tag>
                </span>
              </Popover>
            ) : (
              <span>
                <Tag color="blue">{r.cost}</Tag>
              </span>
            )
          ) : null
      },
      {
        title: 'Selling €',
        dataIndex: 'price',
        render: (selling, row) => (
          <Selling
            usersPricelist={usersPricelist}
            routesChanges={routesChanges}
            setRoutesChanges={setRoutesChanges}
            isMultiselector={isMultiselector}
            userIdEditing={userIdEditing}
            selling={selling}
            row={row}
          />
        )
      },
      {
        title: 'Profit €',
        dataIndex: 'profit',
        render: (_, r) => {
          let profit = r.profit;
          const rChanged =
            routesChanges.length &&
            routesChanges.find(
              route => r.userId === route.userId && r.mccmnc === route.mccmnc
            );
          if (rChanged) {
            profit = (rChanged.price - r.cost).toFixed(4);
          }
          return r.cost ? (
            <span>
              {<Tag color={profit < 0.0007 ? 'red' : 'green'}>{profit}</Tag>}
            </span>
          ) : null;
        }
      }
    ];

    return (
      <Table
        loading={loadingExtendable}
        columns={columns}
        dataSource={
          usersPricelist?.[row.id] &&
          addKey(
            [
              ...routesChanges.filter(({ change }) => change === 'added'),
              ...usersPricelist?.[row.id]
            ]
              .filter(
                ({ mccmnc }) =>
                  !routesChanges.find(
                    r => r.mccmnc === mccmnc && r.change === 'removed'
                  )
              )
              .sort((a, b) => a.country.localeCompare(b.country))
          )
        }
        pagination={false}
        rowClassName={(r, idx) => {
          let classStyle;
          if (r.custom) {
            classStyle = 'differentPrice';
          }
          if (
            (routesChanges.length &&
              routesChanges.find(
                route => r.userId === route.userId && r.mccmnc === route.mccmnc
              )) ||
            r.change
          ) {
            classStyle = 'rowChanged';
          }
          return classStyle;
        }}
      />
    );
  };

  return (
    <>
      <Row
        justify="center"
        style={{
          margin: '30px 0 60px'
        }}
      >
        <Col span={22}>
          <Form
            ref={formRef}
            initialValues={{
              User: 'User',
              Country: 'Country',
              Vendor: 'Vendor',
              Table: 'Table'
            }}
          >
            <div
              style={{
                display: 'flex',
                margin: '10px 0 25px'
              }}
            >
              <SelectCustom name="User" dataKey="userId" {...customParams} />
              <SelectCustom name="Country" dataKey="mcc" {...customParams} />
              <SelectCustom name="Vendor" dataKey="vendor" {...customParams} />
              <SelectCustom name="Table" dataKey="table" {...customParams} />
              <SwitchCustom name="Custom" {...customParams} />
              <SwitchCustom name="Low profit" {...customParams} />
              <Button
                onClick={() => {
                  formRef.current.setFieldsValue({
                    User: 'User',
                    Country: 'Country',
                    Vendor: 'Vendor',
                    Table: 'Table'
                  });
                  setFilters({});
                  setUsersPricelist({});
                  setUserFiltered(false);
                  setExpandedRowKeys([]);
                }}
                type="primary"
                shape="round"
                icon={<ReloadOutlined />}
                style={{
                  marginLeft: '30px'
                }}
              >
                Reset
              </Button>
              <Popconfirm
                title="You don't need this unless routing config has been changed"
                onConfirm={() => {
                  resetTable();
                  updateUsers();
                }}
                cancelText="No, wait"
                okText="Yes, sync all the data"
              >
                <Button
                  type="default"
                  danger
                  shape="round"
                  icon={<WarningOutlined />}
                  style={{
                    marginLeft: 'auto'
                  }}
                >
                  Force update
                </Button>
              </Popconfirm>
            </div>
          </Form>
          <Table
            loading={loading || loadingCozSaving}
            dataSource={
              userFiltered ||
              users.sort((a, b) =>
                a?.name?.localeCompare(b.name, 'es', { sensitivity: 'base' })
              )
            }
            onExpand={getPricelist}
            expandedRowKeys={expandedRowKeys}
            expandable={{
              expandedRowRender: expandedRow,
              expandedRowClassName: () => 'expandedRow'
            }}
            columns={columns}
            size="small"
            pagination={false}
          />
        </Col>
      </Row>
      <Modal
        width="70%"
        visible={showAddCountries}
        destroyOnClose={true}
        onCancel={() => {
          setShowAddCountries(false);
        }}
        footer={null}
      >
        <UsersUpload
          userPricelist={usersPricelist?.[userAddingCountries] || []}
          userName={users?.find(({ id }) => id === userAddingCountries)?.name}
          routesChanges={routesChanges}
          setRoutesChanges={setRoutesChanges}
          isMultiselector={isMultiselector}
          setIsMultiselector={setIsMultiselector}
          setShowAddCountries={setShowAddCountries}
          {...customParams}
        />
      </Modal>
      <ModalForceUpdate
        userInfo={userInfo}
        updateUserOpenPricelist={updateUserOpenPricelist}
        setStreamingData={setStreamingData}
        isSleepy={isSleepy}
        expandedRowKeys={expandedRowKeys}
        isForceUpdating={isForceUpdating}
        setIsForceUpdating={setIsForceUpdating}
      />
    </>
  );
};

export default UsersManagement;
