export const MCCMNC = [
  '2020',
  '20201',
  '20202',
  '20205',
  '20209',
  '20210',
  '20214',
  '2040',
  '20402',
  '20404',
  '20408',
  '20412',
  '20416',
  '20420',
  '2060',
  '20601',
  '20605',
  '20606',
  '20607',
  '20608',
  '20609',
  '20610',
  '20620',
  '20622',
  '20629',
  '20630',
  '20640',
  '2080',
  '20801',
  '20802',
  '20803',
  '20806',
  '20809',
  '20810',
  '20811',
  '20813',
  '20815',
  '20816',
  '20817',
  '20820',
  '20821',
  '20822',
  '20824',
  '20825',
  '20826',
  '20888',
  '20891',
  '20894',
  '2120',
  '21201',
  '2130',
  '21303',
  '2140',
  '21401',
  '21402',
  '21403',
  '21404',
  '21405',
  '21406',
  '21407',
  '21408',
  '21409',
  '21410',
  '21411',
  '21415',
  '21416',
  '21417',
  '21418',
  '21419',
  '21420',
  '21421',
  '21422',
  '21424',
  '21425',
  '21426',
  '21427',
  '21434',
  '21436',
  '2160',
  '21601',
  '21603',
  '21630',
  '21670',
  '2180',
  '21803',
  '21805',
  '21809',
  '21890',
  '2190',
  '21901',
  '21902',
  '21910',
  '2200',
  '22001',
  '22002',
  '22003',
  '22004',
  '22005',
  '2220',
  '22201',
  '22202',
  '22204',
  '22206',
  '22210',
  '22234',
  '22237',
  '22249',
  '22250',
  '22253',
  '22288',
  '22298',
  '22299',
  '2260',
  '22601',
  '22603',
  '22604',
  '22605',
  '22606',
  '22610',
  '2280',
  '22801',
  '22802',
  '22803',
  '22805',
  '22807',
  '22808',
  '22850',
  '22851',
  '22853',
  '22854',
  '2300',
  '23001',
  '23002',
  '23003',
  '23004',
  '2310',
  '23101',
  '23102',
  '23103',
  '23104',
  '23106',
  '2320',
  '23201',
  '23202',
  '23203',
  '23204',
  '23205',
  '23206',
  '23207',
  '23209',
  '23211',
  '23213',
  '2340',
  '234000',
  '234001',
  '234002',
  '234003',
  '234007',
  '234009',
  '23401',
  '234010',
  '234014',
  '234015',
  '234016',
  '234017',
  '234018',
  '234019',
  '23402',
  '234020',
  '234022',
  '234023',
  '234024',
  '234025',
  '234027',
  '234028',
  '23403',
  '234030',
  '234033',
  '23405',
  '234050',
  '234055',
  '234058',
  '23407',
  '234099',
  '23410',
  '23411',
  '23415',
  '23416',
  '23418',
  '23419',
  '23420',
  '23423',
  '23425',
  '23426',
  '23429',
  '23430',
  '23431',
  '23432',
  '23433',
  '23434',
  '23438',
  '23439',
  '23450',
  '23453',
  '23455',
  '23457',
  '23458',
  '23476',
  '23478',
  '23489',
  '23500',
  '23502',
  '23577',
  '23592',
  '2380',
  '23801',
  '23802',
  '23806',
  '23812',
  '23820',
  '23830',
  '2400',
  '24001',
  '24002',
  '24006',
  '24007',
  '24008',
  '24010',
  '24012',
  '24014',
  '24016',
  '24017',
  '24020',
  '2420',
  '24201',
  '24202',
  '24204',
  '24205',
  '24206',
  '24207',
  '24208',
  '24209',
  '24214',
  '24223',
  '2440',
  '24403',
  '24405',
  '24412',
  '24413',
  '24414',
  '24421',
  '24491',
  '2460',
  '24601',
  '24602',
  '24603',
  '2470',
  '24701',
  '24702',
  '24705',
  '2480',
  '24801',
  '24802',
  '24803',
  '24805',
  '2500',
  '25001',
  '25002',
  '25003',
  '25004',
  '25005',
  '25006',
  '25007',
  '25009',
  '25010',
  '25011',
  '25012',
  '25013',
  '25015',
  '25017',
  '25020',
  '25022',
  '25023',
  '25024',
  '25027',
  '25028',
  '25032',
  '25035',
  '25039',
  '25041',
  '25042',
  '25047',
  '25049',
  '25050',
  '25054',
  '25060',
  '25062',
  '25092',
  '25099',
  '2550',
  '25501',
  '25502',
  '25503',
  '25504',
  '25505',
  '25506',
  '25507',
  '25521',
  '25599',
  '2570',
  '25701',
  '25702',
  '25704',
  '2590',
  '25901',
  '25902',
  '2600',
  '26001',
  '26002',
  '26003',
  '26004',
  '26006',
  '26009',
  '26045',
  '2620',
  '26201',
  '26202',
  '26203',
  '26204',
  '26205',
  '26207',
  '26208',
  '26209',
  '26210',
  '26211',
  '26213',
  '26214',
  '26220',
  '26242',
  '26243',
  '26277',
  '2660',
  '26601',
  '2680',
  '26801',
  '26803',
  '26804',
  '26806',
  '2700',
  '27001',
  '27077',
  '27099',
  '2720',
  '27201',
  '27202',
  '27203',
  '27205',
  '27213',
  '2740',
  '27401',
  '27402',
  '27404',
  '2760',
  '27601',
  '27602',
  '27603',
  '27604',
  '2780',
  '27801',
  '27821',
  '2800',
  '28001',
  '28010',
  '28020',
  '2820',
  '28201',
  '28202',
  '28203',
  '28204',
  '28205',
  '28206',
  '28208',
  '2830',
  '28301',
  '28304',
  '28305',
  '28310',
  '2840',
  '28401',
  '28403',
  '28405',
  '2860',
  '28601',
  '28602',
  '28603',
  '28604',
  '2880',
  '28801',
  '28802',
  '2900',
  '29001',
  '2930',
  '29340',
  '29341',
  '29364',
  '29370',
  '2940',
  '29400',
  '29401',
  '29402',
  '29403',
  '2950',
  '29501',
  '29502',
  '29505',
  '29577',
  '302032',
  '302037',
  '302065',
  '302072',
  '302370',
  '302490',
  '3100',
  '310002',
  '310012',
  '310013',
  '310015',
  '310016',
  '310032',
  '310038',
  '310140',
  '310150',
  '310180',
  '310470',
  '310480',
  '310770',
  '311440',
  '311580',
  '311660',
  '311870',
  '316010',
  '3340',
  '33402',
  '334020',
  '33403',
  '33805',
  '3400',
  '34001',
  '34020',
  '34260',
  '34281',
  '3620',
  '36251',
  '36296',
  '3630',
  '36301',
  '36310',
  '3680',
  '36801',
  '37001',
  '3720',
  '37201',
  '37202',
  '37203',
  '4000',
  '40001',
  '40002',
  '40003',
  '40004',
  '40101',
  '40102',
  '40107',
  '40177',
  '4020',
  '40211',
  '4040',
  '40401',
  '40402',
  '40403',
  '40405',
  '40407',
  '40410',
  '40411',
  '40413',
  '40415',
  '40416',
  '40420',
  '40421',
  '40422',
  '40424',
  '40427',
  '40434',
  '40440',
  '40441',
  '40442',
  '40443',
  '40444',
  '40445',
  '40446',
  '40449',
  '40460',
  '40467',
  '40468',
  '40493',
  '40495',
  '40496',
  '40498',
  '405001',
  '405002',
  '405007',
  '405013',
  '405015',
  '405025',
  '405030',
  '405031',
  '405079',
  '40552',
  '405800',
  '405807',
  '405846',
  '405854',
  '405862',
  '405871',
  '405886',
  '4100',
  '41001',
  '41003',
  '41004',
  '41006',
  '41007',
  '4120',
  '41201',
  '41220',
  '41240',
  '41250',
  '41280',
  '41288',
  '4130',
  '41301',
  '41302',
  '41303',
  '41305',
  '41308',
  '41312',
  '4140',
  '41401',
  '41405',
  '41406',
  '41409',
  '4150',
  '41501',
  '41503',
  '4160',
  '41601',
  '41602',
  '41603',
  '41677',
  '4170',
  '41701',
  '41702',
  '4180',
  '41805',
  '41808',
  '41820',
  '41830',
  '41840',
  '41845',
  '41862',
  '41892',
  '4190',
  '41902',
  '41903',
  '4200',
  '42001',
  '42003',
  '42004',
  '42005',
  '42006',
  '4210',
  '42101',
  '42102',
  '42103',
  '42104',
  '4220',
  '42202',
  '42203',
  '4240',
  '42402',
  '42403',
  '4250',
  '42501',
  '42502',
  '42503',
  '42505',
  '42506',
  '42507',
  '42508',
  '42509',
  '42515',
  '42516',
  '42519',
  '42577',
  '4260',
  '42601',
  '42602',
  '42604',
  '42605',
  '4270',
  '42701',
  '42702',
  '4280',
  '42888',
  '42891',
  '42898',
  '42899',
  '4290',
  '42901',
  '42902',
  '42904',
  '4320',
  '43211',
  '43214',
  '43219',
  '43220',
  '43232',
  '43235',
  '4340',
  '43401',
  '43403',
  '43404',
  '43405',
  '43406',
  '43407',
  '43408',
  '4360',
  '43601',
  '43602',
  '43603',
  '43604',
  '43605',
  '43610',
  '43612',
  '4370',
  '43701',
  '43705',
  '4380',
  '43801',
  '43802',
  '4400',
  '44010',
  '44020',
  '4500',
  '45001',
  '45002',
  '45005',
  '45006',
  '45008',
  '4520',
  '452001',
  '452002',
  '452004',
  '452007',
  '45201',
  '45202',
  '45204',
  '45205',
  '45207',
  '45208',
  '452501',
  '452502',
  '452507',
  '4540',
  '45400',
  '45401',
  '45403',
  '45404',
  '45405',
  '45406',
  '45407',
  '45408',
  '45409',
  '45410',
  '45411',
  '45412',
  '45413',
  '45415',
  '45416',
  '45417',
  '45419',
  '45420',
  '45424',
  '45429',
  '4550',
  '45500',
  '45501',
  '45503',
  '4560',
  '45601',
  '45602',
  '45603',
  '45604',
  '45605',
  '45606',
  '45608',
  '45609',
  '45611',
  '45618',
  '4570',
  '45701',
  '45702',
  '45703',
  '45708',
  '4600',
  '46000',
  '46001',
  '46002',
  '46003',
  '46005',
  '46006',
  '46007',
  '46011',
  '4660',
  '46601',
  '46688',
  '46689',
  '46692',
  '46693',
  '46697',
  '46699',
  '46605',
  '4700',
  '47001',
  '47002',
  '47003',
  '47004',
  '47005',
  '47007',
  '4720',
  '47201',
  '47202',
  '5020',
  '50210',
  '50211',
  '50212',
  '50213',
  '50215',
  '502152',
  '502153',
  '50216',
  '50217',
  '50218',
  '50219',
  '5050',
  '50501',
  '50502',
  '50503',
  '50506',
  '50512',
  '5100',
  '51001',
  '51008',
  '51009',
  '51010',
  '51011',
  '51021',
  '51028',
  '51089',
  '5140',
  '51402',
  '5150',
  '51501',
  '51502',
  '51503',
  '51505',
  '51518',
  '51566',
  '5200',
  '52001',
  '52003',
  '52004',
  '52005',
  '52015',
  '52018',
  '52020',
  '52023',
  '52088',
  '52099',
  '5250',
  '52501',
  '52502',
  '52503',
  '52505',
  '52510',
  '5280',
  '52803',
  '52811',
  '5300',
  '53001',
  '53002',
  '53003',
  '53004',
  '53005',
  '53024',
  '5410',
  '54101',
  '5420',
  '54201',
  '5460',
  '54601',
  '5470',
  '54720',
  '6020',
  '60201',
  '60202',
  '60203',
  '60204',
  '6030',
  '60301',
  '60302',
  '60303',
  '6040',
  '60400',
  '60401',
  '60402',
  '6050',
  '60501',
  '60502',
  '60503',
  '6060',
  '60600',
  '60601',
  '60602',
  '6070',
  '60701',
  '60702',
  '60703',
  '60704',
  '6080',
  '60801',
  '60802',
  '60803',
  '6090',
  '60901',
  '60910',
  '6100',
  '61001',
  '61002',
  '61003',
  '6110',
  '61101',
  '61102',
  '61104',
  '61105',
  '61110',
  '6120',
  '61202',
  '61203',
  '61204',
  '61205',
  '61206',
  '6130',
  '61301',
  '61302',
  '61303',
  '6140',
  '61401',
  '61402',
  '61403',
  '61404',
  '6150',
  '61501',
  '61502',
  '61503',
  '6160',
  '61602',
  '61603',
  '6170',
  '61701',
  '61710',
  '6180',
  '61801',
  '61807',
  '6190',
  '61901',
  '61902',
  '61903',
  '61905',
  '61906',
  '61907',
  '6200',
  '620001',
  '620002',
  '620003',
  '620004',
  '620006',
  '62001',
  '62002',
  '62003',
  '62004',
  '62006',
  '62007',
  '620501',
  '6210',
  '62120',
  '62127',
  '62130',
  '62140',
  '62141',
  '62142',
  '62150',
  '62160',
  '6220',
  '62201',
  '62202',
  '62203',
  '6230',
  '62301',
  '62302',
  '62303',
  '62304',
  '62310',
  '6240',
  '62401',
  '62402',
  '62404',
  '6250',
  '62501',
  '6270',
  '62701',
  '62703',
  '6280',
  '62801',
  '62802',
  '62803',
  '6290',
  '62901',
  '62907',
  '62910',
  '62920',
  '6300',
  '63001',
  '63002',
  '63005',
  '63086',
  '63089',
  '63090',
  '6310',
  '63102',
  '63103',
  '6330',
  '63301',
  '63310',
  '6340',
  '63401',
  '63402',
  '63403',
  '63407',
  '6350',
  '63510',
  '6360',
  '63601',
  '63602',
  '6370',
  '63701',
  '63704',
  '63710',
  '63725',
  '63730',
  '63760',
  '63771',
  '63782',
  '6380',
  '63801',
  '6390',
  '63902',
  '63903',
  '63905',
  '63907',
  '6400',
  '64002',
  '64003',
  '64004',
  '64005',
  '64007',
  '64008',
  '64009',
  '6410',
  '64101',
  '64104',
  '64106',
  '64110',
  '64111',
  '64114',
  '64118',
  '64122',
  '64133',
  '6420',
  '64201',
  '64203',
  '64207',
  '64208',
  '64282',
  '6430',
  '64301',
  '64303',
  '64304',
  '6450',
  '64501',
  '64502',
  '64503',
  '6460',
  '64601',
  '64602',
  '64604',
  '6470',
  '64710',
  '6480',
  '64801',
  '64803',
  '64804',
  '6490',
  '64901',
  '64903',
  '6500',
  '65001',
  '65010',
  '6510',
  '65101',
  '65102',
  '6520',
  '65201',
  '65202',
  '65204',
  '6530',
  '65310',
  '6540',
  '65401',
  '6550',
  '65501',
  '65502',
  '65507',
  '65510',
  '6570',
  '65701',
  '6590',
  '65902',
  '65903',
  '65904',
  '65906',
  '7020',
  '70267',
  '7040',
  '70401',
  '70402',
  '70403',
  '7060',
  '70601',
  '70602',
  '70603',
  '70604',
  '70605',
  '7080',
  '70801',
  '70802',
  '70840',
  '7100',
  '71021',
  '71030',
  '710300',
  '71073',
  '7120',
  '71201',
  '71203',
  '71204',
  '7140',
  '71401',
  '71403',
  '71404',
  '7160',
  '71606',
  '71607',
  '71610',
  '71617',
  '71615',
  '7220',
  '72231',
  '72234',
  '72270',
  '7240',
  '72400',
  '72402',
  '72403',
  '72404',
  '72405',
  '72406',
  '72407',
  '72410',
  '72411',
  '72416',
  '72423',
  '72424',
  '72430',
  '72431',
  '72432',
  '72437',
  '7300',
  '73010',
  '7320',
  '732101',
  '732102',
  '732103',
  '732111',
  '732123',
  '732130',
  '732154',
  '732165',
  '7340',
  '73401',
  '73402',
  '73403',
  '73404',
  '73406',
  '7360',
  '73601',
  '73602',
  '73603',
  '7380',
  '73801',
  '7400',
  '74000',
  '74001',
  '74002',
  '7440',
  '74401',
  '74402',
  '74404',
  '74405',
  '7460',
  '74602',
  '7480',
  '74801',
  '74802',
  '74807',
  '74810',
  '90105'
];
