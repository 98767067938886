import React, { useState, useEffect } from 'react';
import 'antd/dist/antd.less';
import {
  Table,
  Tag,
  Button,
  Input,
  Select,
  Alert,
  Popconfirm,
  message
} from 'antd';
import { UploadOutlined, DownloadOutlined } from '@ant-design/icons';
import Dragger from './Dragger.js';
import Papa from 'papaparse';

import { getVendors } from '../Users/services';
import { saveToMcore } from './services';

const Vendor = () => {
  const InputGroup = Input.Group;
  const { Option } = Select;

  const [countries, setCountries] = useState();
  const [vendorSelected, setVendorSelected] = useState(null);
  const [errorMessage, setErrorMessage] = useState();
  const [doubleCheck, setDoubleCheck] = useState(false);
  const [loading, setLoading] = useState(0);
  const [vendors, setVendors] = useState([]);

  const columns = [
    {
      title: 'Country',
      dataIndex: 'country'
    },
    {
      title: 'Operator',
      dataIndex: 'network',
      render: network =>
        network.length > 25 ? `${network.slice(0, 25)}...` : network
    },
    {
      title: 'MCCMNC',
      dataIndex: 'mccmnc'
    },
    {
      title: 'Price €',
      dataIndex: 'price',
      render: price => <span>{<Tag color={'green'}>{price}</Tag>}</span>
    }
  ];

  const createCsv = () => {
    const mutatedCountries = countries.map(row => {
      delete Object.assign(row, { ['currency']: row['key'] })['key'];
      row.currency = 'EUR';
      return row;
    });
    return mutatedCountries;
  };

  const downloadFile = () => {
    const csv = Papa.unparse(createCsv());
    const csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const csvURL = window.URL.createObjectURL(csvData);
    const tempLink = document.createElement('a');
    tempLink.href = csvURL;
    tempLink.setAttribute('download', `${vendorSelected}.csv`);
    tempLink.click();
  };

  const getVendorsReq = async () => {
    const req = await getVendors();
    setVendors(req);
  };

  useEffect(() => {
    getVendorsReq();
  }, []);

  return (
    <div
      style={{
        padding: '25px'
      }}
    >
      <div
        style={{
          marginBottom: '20px',
          display: 'flex'
        }}
      >
        <div>
          <InputGroup compact>
            <Select
              showSearch
              defaultValue="Select the vendor"
              style={{ minWidth: '180px' }}
              onChange={vendor => {
                setVendorSelected(vendor);
              }}
            >
              {vendors
                .sort((a, b) => a.vendor.localeCompare(b.vendor))
                .map(({ vendor }) => (
                  <Option value={vendor}>{vendor}</Option>
                ))}
            </Select>
          </InputGroup>
        </div>
        <div
          style={{
            marginLeft: 'auto'
          }}
        >
          <Popconfirm
            title={`The ${vendorSelected}'s pricelist will be changed`}
            onConfirm={async () => {
              setLoading(true);
              try {
                const resp = await saveToMcore({
                  name: vendorSelected,
                  data: createCsv()
                });
                if (resp.status !== 500) {
                  message.success('Data saved it successfully');
                } else {
                  message.error('Something went wrong');
                  if (resp.data.length) {
                    setErrorMessage(
                      `Errors: "${resp.data.map(
                        (e, idx) => `${idx !== 0 ? ' ' : ''}${e.message}`
                      )}"`
                    );
                  }
                }
              } catch (err) {
                message.error('Something went wrong');
              }
              setDoubleCheck(false);
              setLoading(false);
            }}
            onCancel={() => {
              setDoubleCheck(false);
            }}
            cancelText="No, wait"
            okText="Yes, save it"
            disabled={doubleCheck}
          >
            <Button
              type="primary"
              shape="round"
              icon={<UploadOutlined />}
              style={{
                marginRight: '10px'
              }}
              disabled={countries === undefined || !vendorSelected}
              onClick={() => setDoubleCheck(true)}
            >
              Upload
            </Button>
          </Popconfirm>
          <Button
            type="primary"
            shape="round"
            icon={<DownloadOutlined />}
            onClick={() => downloadFile()}
            disabled={countries === undefined || !vendorSelected}
          >
            Download
          </Button>
        </div>
      </div>
      {errorMessage ? (
        <Alert
          message="Warning"
          description={errorMessage}
          type="error"
          showIcon
          style={{ marginBottom: '15px' }}
        />
      ) : null}
      <Table
        loading={loading}
        dataSource={countries}
        columns={columns}
        size="small"
      />
      <div
        style={{
          height: '200px'
        }}
      >
        <Dragger
          vendorSelected={vendorSelected}
          setCountries={setCountries}
          setErrorMessage={setErrorMessage}
        />
      </div>
    </div>
  );
};

export default Vendor;
