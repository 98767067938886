import React, { useEffect } from 'react';
import { Modal } from 'antd';
import { capitalize } from '../libs/helpers';
import zzz from '../static/zzz.gif';

const Idletime = ({ userInfo, setIsSleepy, logOut }) => {
  const sleepTime = 5 * 60 * 1000;
  let sleepTimeout;
  const signoutTime = 30 * 60 * 1000;
  let logoutTimeout;
  const userName = userInfo.email.split('@')[0];
  let modal;

  const openModal = () => {
    modal = Modal.info({
      title: `Good night ${capitalize(userName)}`,
      content: <img src={zzz} style={{ paddingTop: '20px' }} alt="zzz" />,
      okButtonProps: { style: { display: 'none' } },
      width: 640
    });
  };

  const logout = () => {
    modal.destroy();
    setIsSleepy(false);
    logOut();
  };

  const seepModeActive = () => {
    openModal();
  };

  const setTimeouts = () => {
    sleepTimeout = setTimeout(seepModeActive, sleepTime);
    logoutTimeout = setTimeout(logout, signoutTime);
  };

  const clearTimeouts = () => {
    if (sleepTime) clearTimeout(sleepTimeout);
    if (logoutTimeout) clearTimeout(logoutTimeout);
  };

  useEffect(() => {
    const events = [
      'load',
      'mousemove',
      'mousedown',
      'click',
      'scroll',
      'keypress'
    ];

    const resetTimeout = () => {
      clearTimeouts();
      setTimeouts();
      if (modal?.destroy) {
        modal.destroy();
        setIsSleepy(false);
      }
    };

    for (let i in events) {
      window.addEventListener(events[i], resetTimeout);
    }

    setTimeouts();
    return () => {
      for (let i in events) {
        window.removeEventListener(events[i], resetTimeout);
        clearTimeouts();
      }
    };
  }, []);

  return <></>;
};
export default Idletime;
