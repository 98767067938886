import React, { useEffect } from 'react';
import { API, Auth } from 'aws-amplify';

let token;

const modalAction = async p => {
  let req;
  if (!p.isSleepy) {
    try {
      req = await API.get('sms-api', '/get-streaming', {
        headers: {
          Authorization: token
        }
      });
      p.setStreamingData(req);
    } catch (err) {
      // It seems there are an issue related with expired token,
      // it should get a refreshed one
      const auth = await Auth.currentAuthenticatedUser();
      token = auth.signInUserSession.idToken.jwtToken;
    }
  }
};

const runInterval = async p => {
  const auth = await Auth.currentAuthenticatedUser();
  token = auth.signInUserSession.idToken.jwtToken;
  modalAction(p);
  window.forceInterval = setInterval(async () => {
    modalAction(p);
    clearInterval(p);
  }, 20 * 1000);
};

const ModalForceUpdate = p => {
  useEffect(() => {
    runInterval(p);
  }, [p.isForceUpdating]);

  return <></>;
};

export default ModalForceUpdate;
