import React, { useState, useEffect } from 'react';
import 'antd/dist/antd.less';
import { Table, Tag, Popover, Button, Typography, Switch } from 'antd';
import {
  ReloadOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
  EuroCircleOutlined,
  InfoCircleOutlined,
  ExclamationCircleOutlined
} from '@ant-design/icons';
import { getAutomaticUpdates } from './services';
import { flagEmoji } from '../../libs/helpers';

const dateToString = date => {
  const mom = { base: new Date(date) };
  mom.day = mom.base.toISOString().split('T')[0].split('-').reverse().join('/');
  mom.time = mom.base.toLocaleTimeString('es-ES', {
    timeZone: 'Europe/Madrid',
    timeStyle: 'short'
  });
  if (mom.time.length === 4) {
    mom.time = `0${mom.time}`;
  }
  mom.full = `${mom.day} ${mom.time}`;
  return mom;
};

const printSection = (title, data, icon) => (
  <>
    <span style={{ color: '#241dba' }}>{title}</span>
    <br />
    {data.map(r => (
      <span>
        <a>{icon} </a>
        {`${r.country} ${flagEmoji(r.country)} | ${r.mccmnc} | ${
          r.oldPrice ? `${parseFloat(r.oldPrice)}€ → ` : ''
        }${parseFloat(r.price)}€`}
        <br />
      </span>
    ))}
    <br />
  </>
);

const Automatic = () => {
  const { Text } = Typography;
  const [automaticUpdates, setAutomaticUpdates] = useState();
  const [showUnsuccessful, setShowUnsuccessful] = useState(false);
  const [loading, setLoading] = useState(false);

  const onLoad = async () => {
    setLoading(true);
    const req = await getAutomaticUpdates();
    const automaticOrdered = req
      .sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp))
      .reverse();
    setAutomaticUpdates(automaticOrdered);
    setLoading(false);
  };

  useEffect(() => {
    onLoad();
  }, []);

  const columns = [
    {
      title: 'Status',
      render: el => {
        if (el.status === 'successful') {
          const c = el?.changes ? JSON.parse(el.changes) : null;
          return (
            <Popover
              content={
                c?.changed?.length || c?.added?.length || c?.removed?.length ? (
                  <>
                    {c?.changed?.length &&
                      printSection(
                        'Price change',
                        c.changed,
                        <EuroCircleOutlined />
                      )}
                    {c?.added?.length &&
                      printSection(
                        'Routes added',
                        c.added,
                        <PlusCircleOutlined />
                      )}
                    {c?.removed?.length &&
                      printSection(
                        'Routes removed',
                        c.removed,
                        <DeleteOutlined />
                      )}
                  </>
                ) : (
                  <>Sorry, no updates to show 😢</>
                )
              }
            >
              <a>
                <Tag color={'green'}>
                  <span style={{ textTransform: 'capitalize' }}>
                    {el.status} <InfoCircleOutlined />
                  </span>
                </Tag>
              </a>
            </Popover>
          );
        } else {
          return (
            <Popover
              title="Errors"
              content={el.error.map(msg => (
                <span>
                  {msg}
                  <br />
                </span>
              ))}
            >
              <span>
                {
                  <Tag color={'red'}>
                    <span style={{ textTransform: 'capitalize' }}>
                      {el.status} <ExclamationCircleOutlined />
                    </span>
                  </Tag>
                }
              </span>
            </Popover>
          );
        }
      }
    },
    {
      title: 'Vendor',
      dataIndex: 'vendor'
    },
    {
      title: 'Moment',
      dataIndex: 'timestamp',
      render: d => dateToString(d).full.replace(' ', ' @ ')
    },
    {
      title: 'Email Id',
      dataIndex: 'emailId'
    }
  ];

  return (
    <div
      style={{
        padding: '0 25px 25px',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <div
        style={{
          display: 'flex',
          marginBottom: '10px'
        }}
      >
        <Text>Show unsuccessful</Text>
        <Switch
          onChange={checked => {
            setShowUnsuccessful(checked);
          }}
          style={{ marginLeft: '15px' }}
        />
        <Button
          onClick={() => onLoad()}
          type="primary"
          shape="round"
          icon={<ReloadOutlined />}
          style={{
            marginLeft: 'auto'
          }}
        >
          Sync
        </Button>
      </div>
      <Table
        loading={loading}
        dataSource={
          showUnsuccessful
            ? automaticUpdates.filter(el => el.status === 'unsuccessful')
            : automaticUpdates
        }
        columns={columns}
        size="small"
        pagination={{
          defaultPageSize: 15
        }}
      />
    </div>
  );
};

export default Automatic;
