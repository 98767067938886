export const MNOS = [
  ['Greece', '2020'],
  ['Greece', '20201'],
  ['Greece', '20202'],
  ['Greece', '20205'],
  ['Greece', '20209'],
  ['Greece', '20210'],
  ['Greece', '20214'],
  ['Netherlands', '2040'],
  ['Netherlands', '20402'],
  ['Netherlands', '20404'],
  ['Netherlands', '20408'],
  ['Netherlands', '20412'],
  ['Netherlands', '20416'],
  ['Netherlands', '20420'],
  ['Belgium', '2060'],
  ['Belgium', '20601'],
  ['Belgium', '20605'],
  ['Belgium', '20606'],
  ['Belgium', '20607'],
  ['Belgium', '20608'],
  ['Belgium', '20609'],
  ['Belgium', '20610'],
  ['Belgium', '20620'],
  ['Belgium', '20622'],
  ['Belgium', '20629'],
  ['Belgium', '20630'],
  ['Belgium', '20640'],
  ['France', '2080'],
  ['France', '20801'],
  ['France', '20802'],
  ['France', '20803'],
  ['France', '20806'],
  ['France', '20809'],
  ['France', '20810'],
  ['France', '20811'],
  ['France', '20813'],
  ['France', '20815'],
  ['France', '20816'],
  ['France', '20817'],
  ['France', '20820'],
  ['France', '20821'],
  ['France', '20822'],
  ['France', '20824'],
  ['France', '20825'],
  ['France', '20826'],
  ['France', '20888'],
  ['France', '20891'],
  ['France', '20894'],
  ['Monaco', '2120'],
  ['Monaco', '21201'],
  ['Andorra', '2130'],
  ['Andorra', '21303'],
  ['Spain', '2140'],
  ['Spain', '21401'],
  ['Spain', '21402'],
  ['Spain', '21403'],
  ['Spain', '21404'],
  ['Spain', '21405'],
  ['Spain', '21406'],
  ['Spain', '21407'],
  ['Spain', '21408'],
  ['Spain', '21409'],
  ['Spain', '21410'],
  ['Spain', '21411'],
  ['Spain', '21415'],
  ['Spain', '21416'],
  ['Spain', '21417'],
  ['Spain', '21418'],
  ['Spain', '21419'],
  ['Spain', '21420'],
  ['Spain', '21421'],
  ['Spain', '21422'],
  ['Spain', '21424'],
  ['Spain', '21425'],
  ['Spain', '21426'],
  ['Spain', '21427'],
  ['Spain', '21434'],
  ['Spain', '21436'],
  ['Hungary', '2160'],
  ['Hungary', '21601'],
  ['Hungary', '21603'],
  ['Hungary', '21630'],
  ['Hungary', '21670'],
  ['Bosnia-Herzegovina', '2180'],
  ['Bosnia-Herzegovina', '21803'],
  ['Bosnia-Herzegovina', '21805'],
  ['Bosnia-Herzegovina', '21809'],
  ['Bosnia-Herzegovina', '21890'],
  ['Croatia', '2190'],
  ['Croatia', '21901'],
  ['Croatia', '21902'],
  ['Croatia', '21910'],
  ['Serbia', '2200'],
  ['Serbia', '22001'],
  ['Serbia', '22002'],
  ['Serbia', '22003'],
  ['Serbia', '22004'],
  ['Serbia', '22005'],
  ['Italy', '2220'],
  ['Italy', '22201'],
  ['Italy', '22202'],
  ['Italy', '22204'],
  ['Italy', '22206'],
  ['Italy', '22210'],
  ['Italy', '22234'],
  ['Italy', '22237'],
  ['Italy', '22249'],
  ['Italy', '22250'],
  ['Italy', '22253'],
  ['Italy', '22288'],
  ['Italy', '22298'],
  ['Italy', '22299'],
  ['Romania', '2260'],
  ['Romania', '22601'],
  ['Romania', '22603'],
  ['Romania', '22604'],
  ['Romania', '22605'],
  ['Romania', '22606'],
  ['Romania', '22610'],
  ['Switzerland', '2280'],
  ['Switzerland', '22801'],
  ['Switzerland', '22802'],
  ['Switzerland', '22803'],
  ['Switzerland', '22805'],
  ['Switzerland', '22807'],
  ['Switzerland', '22808'],
  ['Switzerland', '22850'],
  ['Switzerland', '22851'],
  ['Switzerland', '22853'],
  ['Switzerland', '22854'],
  ['Czech Republic', '2300'],
  ['Czech Republic', '23001'],
  ['Czech Republic', '23002'],
  ['Czech Republic', '23003'],
  ['Czech Republic', '23004'],
  ['Slovak Republic', '2310'],
  ['Slovak Republic', '23101'],
  ['Slovak Republic', '23102'],
  ['Slovak Republic', '23103'],
  ['Slovak Republic', '23104'],
  ['Slovak Republic', '23106'],
  ['Austria', '2320'],
  ['Austria', '23201'],
  ['Austria', '23202'],
  ['Austria', '23203'],
  ['Austria', '23204'],
  ['Austria', '23205'],
  ['Austria', '23206'],
  ['Austria', '23207'],
  ['Austria', '23209'],
  ['Austria', '23211'],
  ['Austria', '23213'],
  ['England', '2340'],
  ['England', '234000'],
  ['England', '234001'],
  ['England', '234002'],
  ['England', '234003'],
  ['England', '234007'],
  ['England', '234009'],
  ['England', '23401'],
  ['England', '234010'],
  ['England', '234014'],
  ['England', '234015'],
  ['England', '234016'],
  ['England', '234017'],
  ['England', '234018'],
  ['England', '234019'],
  ['England', '23402'],
  ['England', '234020'],
  ['England', '234022'],
  ['England', '234023'],
  ['England', '234024'],
  ['England', '234025'],
  ['England', '234027'],
  ['England', '234028'],
  ['England', '23403'],
  ['England', '234030'],
  ['England', '234033'],
  ['England', '23405'],
  ['England', '234050'],
  ['England', '234055'],
  ['England', '234058'],
  ['England', '23407'],
  ['England', '234099'],
  ['England', '23410'],
  ['England', '23411'],
  ['England', '23415'],
  ['England', '23416'],
  ['England', '23418'],
  ['England', '23419'],
  ['England', '23420'],
  ['England', '23423'],
  ['England', '23425'],
  ['England', '23426'],
  ['England', '23429'],
  ['England', '23430'],
  ['England', '23431'],
  ['England', '23432'],
  ['England', '23433'],
  ['England', '23434'],
  ['England', '23438'],
  ['England', '23439'],
  ['England', '23450'],
  ['England', '23453'],
  ['England', '23455'],
  ['England', '23457'],
  ['England', '23458'],
  ['England', '23476'],
  ['England', '23478'],
  ['England', '23489'],
  ['England', '23500'],
  ['England', '23502'],
  ['England', '23577'],
  ['England', '23592'],
  ['Denmark', '2380'],
  ['Denmark', '23801'],
  ['Denmark', '23802'],
  ['Denmark', '23806'],
  ['Denmark', '23812'],
  ['Denmark', '23820'],
  ['Denmark', '23830'],
  ['Sweden', '2400'],
  ['Sweden', '24001'],
  ['Sweden', '24002'],
  ['Sweden', '24006'],
  ['Sweden', '24007'],
  ['Sweden', '24008'],
  ['Sweden', '24010'],
  ['Sweden', '24012'],
  ['Sweden', '24014'],
  ['Sweden', '24016'],
  ['Sweden', '24017'],
  ['Sweden', '24020'],
  ['Norway', '2420'],
  ['Norway', '24201'],
  ['Norway', '24202'],
  ['Norway', '24204'],
  ['Norway', '24205'],
  ['Norway', '24206'],
  ['Norway', '24207'],
  ['Norway', '24208'],
  ['Norway', '24209'],
  ['Norway', '24214'],
  ['Norway', '24223'],
  ['Finland', '2440'],
  ['Finland', '24403'],
  ['Finland', '24405'],
  ['Finland', '24412'],
  ['Finland', '24413'],
  ['Finland', '24414'],
  ['Finland', '24421'],
  ['Finland', '24491'],
  ['Lithuania', '2460'],
  ['Lithuania', '24601'],
  ['Lithuania', '24602'],
  ['Lithuania', '24603'],
  ['Latvia', '2470'],
  ['Latvia', '24701'],
  ['Latvia', '24702'],
  ['Latvia', '24705'],
  ['Estonia', '2480'],
  ['Estonia', '24801'],
  ['Estonia', '24802'],
  ['Estonia', '24803'],
  ['Estonia', '24805'],
  ['Russia', '2500'],
  ['Russia', '25001'],
  ['Russia', '25002'],
  ['Russia', '25003'],
  ['Russia', '25004'],
  ['Russia', '25005'],
  ['Russia', '25006'],
  ['Russia', '25007'],
  ['Russia', '25009'],
  ['Russia', '25010'],
  ['Russia', '25011'],
  ['Russia', '25012'],
  ['Russia', '25013'],
  ['Russia', '25015'],
  ['Russia', '25017'],
  ['Russia', '25020'],
  ['Russia', '25022'],
  ['Russia', '25023'],
  ['Russia', '25024'],
  ['Russia', '25027'],
  ['Russia', '25028'],
  ['Russia', '25032'],
  ['Russia', '25035'],
  ['Russia', '25039'],
  ['Russia', '25041'],
  ['Russia', '25042'],
  ['Russia', '25047'],
  ['Russia', '25049'],
  ['Russia', '25050'],
  ['Russia', '25054'],
  ['Russia', '25060'],
  ['Russia', '25062'],
  ['Russia', '25092'],
  ['Russia', '25099'],
  ['Ukraine', '2550'],
  ['Ukraine', '25501'],
  ['Ukraine', '25502'],
  ['Ukraine', '25503'],
  ['Ukraine', '25504'],
  ['Ukraine', '25505'],
  ['Ukraine', '25506'],
  ['Ukraine', '25507'],
  ['Ukraine', '25521'],
  ['Ukraine', '25599'],
  ['Belarus', '2570'],
  ['Belarus', '25701'],
  ['Belarus', '25702'],
  ['Belarus', '25704'],
  ['Moldavia', '2590'],
  ['Moldavia', '25901'],
  ['Moldavia', '25902'],
  ['Poland', '2600'],
  ['Poland', '26001'],
  ['Poland', '26002'],
  ['Poland', '26003'],
  ['Poland', '26004'],
  ['Poland', '26006'],
  ['Poland', '26009'],
  ['Poland', '26045'],
  ['Germany', '2620'],
  ['Germany', '26201'],
  ['Germany', '26202'],
  ['Germany', '26203'],
  ['Germany', '26204'],
  ['Germany', '26205'],
  ['Germany', '26207'],
  ['Germany', '26208'],
  ['Germany', '26209'],
  ['Germany', '26210'],
  ['Germany', '26211'],
  ['Germany', '26213'],
  ['Germany', '26214'],
  ['Germany', '26220'],
  ['Germany', '26242'],
  ['Germany', '26243'],
  ['Germany', '26277'],
  ['Gibraltar', '2660'],
  ['Gibraltar', '26601'],
  ['Portugal', '2680'],
  ['Portugal', '26801'],
  ['Portugal', '26803'],
  ['Portugal', '26804'],
  ['Portugal', '26806'],
  ['Luxembourg', '2700'],
  ['Luxembourg', '27001'],
  ['Luxembourg', '27077'],
  ['Luxembourg', '27099'],
  ['Ireland', '2720'],
  ['Ireland', '27201'],
  ['Ireland', '27202'],
  ['Ireland', '27203'],
  ['Ireland', '27205'],
  ['Ireland', '27213'],
  ['Iceland', '2740'],
  ['Iceland', '27401'],
  ['Iceland', '27402'],
  ['Iceland', '27404'],
  ['Albania', '2760'],
  ['Albania', '27601'],
  ['Albania', '27602'],
  ['Albania', '27603'],
  ['Albania', '27604'],
  ['Malta', '2780'],
  ['Malta', '27801'],
  ['Malta', '27821'],
  ['Cyprus', '2800'],
  ['Cyprus', '28001'],
  ['Cyprus', '28010'],
  ['Cyprus', '28020'],
  ['Georgia Republic', '2820'],
  ['Georgia Republic', '28201'],
  ['Georgia Republic', '28202'],
  ['Georgia Republic', '28203'],
  ['Georgia Republic', '28204'],
  ['Georgia Republic', '28205'],
  ['Georgia Republic', '28206'],
  ['Georgia Republic', '28208'],
  ['Armenia', '2830'],
  ['Armenia', '28301'],
  ['Armenia', '28304'],
  ['Armenia', '28305'],
  ['Armenia', '28310'],
  ['Bulgaria', '2840'],
  ['Bulgaria', '28401'],
  ['Bulgaria', '28403'],
  ['Bulgaria', '28405'],
  ['Turkey', '2860'],
  ['Turkey', '28601'],
  ['Turkey', '28602'],
  ['Turkey', '28603'],
  ['Turkey', '28604'],
  ['Faeroe Islands', '2880'],
  ['Faeroe Islands', '28801'],
  ['Faeroe Islands', '28802'],
  ['Greenland', '2900'],
  ['Greenland', '29001'],
  ['Slovenia', '2930'],
  ['Slovenia', '29340'],
  ['Slovenia', '29341'],
  ['Slovenia', '29364'],
  ['Slovenia', '29370'],
  ['Macedonia', '2940'],
  ['Macedonia', '29400'],
  ['Macedonia', '29401'],
  ['Macedonia', '29402'],
  ['Macedonia', '29403'],
  ['Liechtenstein', '2950'],
  ['Liechtenstein', '29501'],
  ['Liechtenstein', '29502'],
  ['Liechtenstein', '29505'],
  ['Liechtenstein', '29577'],
  ['Canada', '302032'],
  ['Canada', '302037'],
  ['Canada', '302065'],
  ['Canada', '302072'],
  ['Canada', '302370'],
  ['Canada', '302490'],
  ['United States', '3100'],
  ['United States', '310002'],
  ['United States', '310012'],
  ['Solomon Islands', '310013'],
  ['United States', '310015'],
  ['United States', '310016'],
  ['Guam', '310032'],
  ['United States', '310038'],
  ['Guam', '310140'],
  ['Solomon Islands', '310150'],
  ['United States', '310180'],
  ['Guam', '310470'],
  ['Guam', '310480'],
  ['United States', '310770'],
  ['International', '311440'],
  ['International', '311580'],
  ['International', '311660'],
  ['International', '311870'],
  ['International', '316010'],
  ['Mexico', '3340'],
  ['Mexico', '33402'],
  ['Mexico', '334020'],
  ['Mexico', '33403'],
  ['Jamaica', '33805'],
  ['Martinique (French)', '3400'],
  ['Martinique (French)', '34001'],
  ['France', '34020'],
  ['Barbados', '34260'],
  ['Barbados', '34281'],
  ['Netherlands Antilles', '3620'],
  ['Netherlands Antilles', '36251'],
  ['Netherlands Antilles', '36296'],
  ['Aruba', '3630'],
  ['Aruba', '36301'],
  ['Aruba', '36310'],
  ['Cuba', '3680'],
  ['Cuba', '36801'],
  ['Dominica', '37001'],
  ['Haiti', '3720'],
  ['Haiti', '37201'],
  ['Haiti', '37202'],
  ['Haiti', '37203'],
  ['Azerbaijan', '4000'],
  ['Azerbaijan', '40001'],
  ['Azerbaijan', '40002'],
  ['Azerbaijan', '40003'],
  ['Azerbaijan', '40004'],
  ['Kazakhstan', '40101'],
  ['Kazakhstan', '40102'],
  ['Kazakhstan', '40107'],
  ['Kazakhstan', '40177'],
  ['Bhutan', '4020'],
  ['Bhutan', '40211'],
  ['India', '4040'],
  ['India', '40401'],
  ['India', '40402'],
  ['India', '40403'],
  ['India', '40405'],
  ['India', '40407'],
  ['India', '40410'],
  ['India', '40411'],
  ['India', '40413'],
  ['India', '40415'],
  ['India', '40416'],
  ['India', '40420'],
  ['India', '40421'],
  ['India', '40422'],
  ['India', '40424'],
  ['India', '40427'],
  ['India', '40434'],
  ['India', '40440'],
  ['India', '40441'],
  ['India', '40442'],
  ['India', '40443'],
  ['India', '40444'],
  ['India', '40445'],
  ['India', '40446'],
  ['India', '40449'],
  ['India', '40460'],
  ['India', '40467'],
  ['India', '40468'],
  ['India', '40493'],
  ['India', '40495'],
  ['India', '40496'],
  ['India', '40498'],
  ['International', '405001'],
  ['International', '405002'],
  ['India', '405007'],
  ['India', '405013'],
  ['India', '405015'],
  ['India', '405025'],
  ['India', '405030'],
  ['India', '405031'],
  ['International', '405079'],
  ['India', '40552'],
  ['India', '405800'],
  ['India', '405807'],
  ['India', '405846'],
  ['India', '405854'],
  ['India', '405862'],
  ['India', '405871'],
  ['India', '405886'],
  ['Pakistan', '4100'],
  ['Pakistan', '41001'],
  ['Pakistan', '41003'],
  ['Pakistan', '41004'],
  ['Pakistan', '41006'],
  ['Pakistan', '41007'],
  ['Afghanistan', '4120'],
  ['Afghanistan', '41201'],
  ['Afghanistan', '41220'],
  ['Afghanistan', '41240'],
  ['Afghanistan', '41250'],
  ['Afghanistan', '41280'],
  ['Afghanistan', '41288'],
  ['Sri Lanka', '4130'],
  ['Sri Lanka', '41301'],
  ['Sri Lanka', '41302'],
  ['Sri Lanka', '41303'],
  ['Sri Lanka', '41305'],
  ['Sri Lanka', '41308'],
  ['Sri Lanka', '41312'],
  ['Myanmar', '4140'],
  ['Myanmar', '41401'],
  ['Myanmar', '41405'],
  ['Myanmar', '41406'],
  ['Myanmar', '41409'],
  ['Lebanon', '4150'],
  ['Lebanon', '41501'],
  ['Lebanon', '41503'],
  ['Jordan', '4160'],
  ['Jordan', '41601'],
  ['Jordan', '41602'],
  ['Jordan', '41603'],
  ['Jordan', '41677'],
  ['Syria', '4170'],
  ['Syria', '41701'],
  ['Syria', '41702'],
  ['Iraq', '4180'],
  ['Iraq', '41805'],
  ['Iraq', '41808'],
  ['Iraq', '41820'],
  ['Iraq', '41830'],
  ['Iraq', '41840'],
  ['Iraq', '41845'],
  ['Iraq', '41862'],
  ['Iraq', '41892'],
  ['Kuwait', '4190'],
  ['Kuwait', '41902'],
  ['Kuwait', '41903'],
  ['Saudi Arabia', '4200'],
  ['Saudi Arabia', '42001'],
  ['Saudi Arabia', '42003'],
  ['Saudi Arabia', '42004'],
  ['Saudi Arabia', '42005'],
  ['Saudi Arabia', '42006'],
  ['Yemen', '4210'],
  ['Yemen', '42101'],
  ['Yemen', '42102'],
  ['Yemen', '42103'],
  ['Yemen', '42104'],
  ['Oman', '4220'],
  ['Oman', '42202'],
  ['Oman', '42203'],
  ['United Arab Emirates', '4240'],
  ['United Arab Emirates', '42402'],
  ['United Arab Emirates', '42403'],
  ['Israel', '4250'],
  ['Israel', '42501'],
  ['Israel', '42502'],
  ['Israel', '42503'],
  ['Palestine', '42505'],
  ['Israel', '42506'],
  ['Israel', '42507'],
  ['Israel', '42508'],
  ['Israel', '42509'],
  ['Israel', '42515'],
  ['Israel', '42516'],
  ['Israel', '42519'],
  ['Israel', '42577'],
  ['Bahrain', '4260'],
  ['Bahrain', '42601'],
  ['Bahrain', '42602'],
  ['Bahrain', '42604'],
  ['Bahrain', '42605'],
  ['Qatar', '4270'],
  ['Qatar', '42701'],
  ['Qatar', '42702'],
  ['Mongolia', '4280'],
  ['Mongolia', '42888'],
  ['Mongolia', '42891'],
  ['Mongolia', '42898'],
  ['Mongolia', '42899'],
  ['Nepal', '4290'],
  ['Nepal', '42901'],
  ['Nepal', '42902'],
  ['Nepal', '42904'],
  ['Iran', '4320'],
  ['Iran', '43211'],
  ['Iran', '43214'],
  ['Iran', '43219'],
  ['Iran', '43220'],
  ['Iran', '43232'],
  ['Iran', '43235'],
  ['Uzbekistan', '4340'],
  ['Uzbekistan', '43401'],
  ['Uzbekistan', '43403'],
  ['Uzbekistan', '43404'],
  ['Uzbekistan', '43405'],
  ['Uzbekistan', '43406'],
  ['Uzbekistan', '43407'],
  ['Uzbekistan', '43408'],
  ['Tajikistan', '4360'],
  ['Tajikistan', '43601'],
  ['Tajikistan', '43602'],
  ['Tajikistan', '43603'],
  ['Tajikistan', '43604'],
  ['Tajikistan', '43605'],
  ['Tajikistan', '43610'],
  ['Tajikistan', '43612'],
  ['Kyrgyzstan', '4370'],
  ['Kyrgyzstan', '43701'],
  ['Kyrgyzstan', '43705'],
  ['Turkmenistan', '4380'],
  ['Turkmenistan', '43801'],
  ['Turkmenistan', '43802'],
  ['Japan', '4400'],
  ['Japan', '44010'],
  ['Japan', '44020'],
  ['South Korea', '4500'],
  ['South Korea', '45001'],
  ['South Korea', '45002'],
  ['North Korea', '45005'],
  ['North Korea', '45006'],
  ['North Korea', '45008'],
  ['Vietnam', '4520'],
  ['Vietnam', '452001'],
  ['Vietnam', '452002'],
  ['Vietnam', '452004'],
  ['Vietnam', '452007'],
  ['Vietnam', '45201'],
  ['Vietnam', '45202'],
  ['Vietnam', '45204'],
  ['Vietnam', '45205'],
  ['Vietnam', '45207'],
  ['Vietnam', '45208'],
  ['Vietnam', '452501'],
  ['Vietnam', '452502'],
  ['Vietnam', '452507'],
  ['Hong Kong', '4540'],
  ['Hong Kong', '45400'],
  ['Hong Kong', '45401'],
  ['Hong Kong', '45403'],
  ['Hong Kong', '45404'],
  ['Hong Kong', '45405'],
  ['Hong Kong', '45406'],
  ['Hong Kong', '45407'],
  ['Hong Kong', '45408'],
  ['Hong Kong', '45409'],
  ['Hong Kong', '45410'],
  ['Hong Kong', '45411'],
  ['Hong Kong', '45412'],
  ['Hong Kong', '45413'],
  ['Hong Kong', '45415'],
  ['Hong Kong', '45416'],
  ['Hong Kong', '45417'],
  ['Hong Kong', '45419'],
  ['Hong Kong', '45420'],
  ['Hong Kong', '45424'],
  ['Hong Kong', '45429'],
  ['Macao', '4550'],
  ['Macao', '45500'],
  ['Macao', '45501'],
  ['Macao', '45503'],
  ['Cambodia', '4560'],
  ['Cambodia', '45601'],
  ['Cambodia', '45602'],
  ['Cambodia', '45603'],
  ['Cambodia', '45604'],
  ['Cambodia', '45605'],
  ['Cambodia', '45606'],
  ['Cambodia', '45608'],
  ['Cambodia', '45609'],
  ['Cambodia', '45611'],
  ['Cambodia', '45618'],
  ['Laos', '4570'],
  ['Laos', '45701'],
  ['Laos', '45702'],
  ['Laos', '45703'],
  ['Laos', '45708'],
  ['China Peoples Republic', '4600'],
  ['China Peoples Republic', '46000'],
  ['China Peoples Republic', '46001'],
  ['China Peoples Republic', '46002'],
  ['China Peoples Republic', '46003'],
  ['China Peoples Republic', '46005'],
  ['China Peoples Republic', '46006'],
  ['China Peoples Republic', '46007'],
  ['China Peoples Republic', '46011'],
  ['Taiwan', '4660'],
  ['Taiwan', '46601'],
  ['Taiwan', '46688'],
  ['Taiwan', '46689'],
  ['Taiwan', '46692'],
  ['Taiwan', '46693'],
  ['Taiwan', '46697'],
  ['Taiwan', '46699'],
  ['Taiwan', '46605'],
  ['Bangladesh', '4700'],
  ['Bangladesh', '47001'],
  ['Bangladesh', '47002'],
  ['Bangladesh', '47003'],
  ['Bangladesh', '47004'],
  ['Bangladesh', '47005'],
  ['Bangladesh', '47007'],
  ['Maldives', '4720'],
  ['Maldives', '47201'],
  ['Maldives', '47202'],
  ['Malaysia', '5020'],
  ['Malaysia', '50210'],
  ['Malaysia', '50211'],
  ['Malaysia', '50212'],
  ['Malaysia', '50213'],
  ['Malaysia', '50215'],
  ['Malaysia', '502152'],
  ['Malaysia', '502153'],
  ['Malaysia', '50216'],
  ['Malaysia', '50217'],
  ['Malaysia', '50218'],
  ['Malaysia', '50219'],
  ['Australia', '5050'],
  ['Australia', '50501'],
  ['Australia', '50502'],
  ['Australia', '50503'],
  ['Australia', '50506'],
  ['Australia', '50512'],
  ['Indonesia', '5100'],
  ['Indonesia', '51001'],
  ['Indonesia', '51008'],
  ['Indonesia', '51009'],
  ['Indonesia', '51010'],
  ['Indonesia', '51011'],
  ['Indonesia', '51021'],
  ['Indonesia', '51028'],
  ['Indonesia', '51089'],
  ['Timor-Leste', '5140'],
  ['Timor-Leste', '51402'],
  ['Philippines', '5150'],
  ['Philippines', '51501'],
  ['Philippines', '51502'],
  ['Philippines', '51503'],
  ['Philippines', '51505'],
  ['Philippines', '51518'],
  ['Philippines', '51566'],
  ['Thailand', '5200'],
  ['Thailand', '52001'],
  ['Thailand', '52003'],
  ['Thailand', '52004'],
  ['Thailand', '52005'],
  ['Thailand', '52015'],
  ['Thailand', '52018'],
  ['Thailand', '52020'],
  ['Thailand', '52023'],
  ['Thailand', '52088'],
  ['Thailand', '52099'],
  ['Singapore', '5250'],
  ['Singapore,"SINGTEL MOBILE (GSM900', '52501'],
  ['Singapore', '52502'],
  ['Singapore', '52503'],
  ['Singapore', '52505'],
  ['Singapore', '52510'],
  ['Brunei Darussalam', '5280'],
  ['Brunei Darussalam', '52803'],
  ['Brunei Darussalam', '52811'],
  ['New Zealand', '5300'],
  ['New Zealand', '53001'],
  ['New Zealand', '53002'],
  ['New Zealand', '53003'],
  ['New Zealand', '53004'],
  ['New Zealand', '53005'],
  ['New Zealand', '53024'],
  ['Vanuatu', '5410'],
  ['Vanuatu', '54101'],
  ['Fiji', '5420'],
  ['Fiji', '54201'],
  ['Namibia', '5460'],
  ['Namibia', '54601'],
  ['French Polynesia', '5470'],
  ['French Polynesia', '54720'],
  ['Egypt', '6020'],
  ['Egypt', '60201'],
  ['Egypt', '60202'],
  ['Egypt', '60203'],
  ['Egypt', '60204'],
  ['Algeria', '6030'],
  ['Algeria', '60301'],
  ['Algeria', '60302'],
  ['Algeria', '60303'],
  ['Morocco', '6040'],
  ['Morocco', '60400'],
  ['Morocco', '60401'],
  ['Morocco', '60402'],
  ['Tunisia', '6050'],
  ['Tunisia', '60501'],
  ['Tunisia', '60502'],
  ['Tunisia', '60503'],
  ['Libya', '6060'],
  ['International', '60600'],
  ['International', '60601'],
  ['Libya', '60602'],
  ['Gambia', '6070'],
  ['Gambia', '60701'],
  ['Gambia', '60702'],
  ['Gambia', '60703'],
  ['Gambia', '60704'],
  ['Senegal', '6080'],
  ['Senegal', '60801'],
  ['Senegal', '60802'],
  ['Senegal', '60803'],
  ['Mauritania', '6090'],
  ['Mauritania', '60901'],
  ['Mauritania', '60910'],
  ['Mali', '6100'],
  ['Mali', '61001'],
  ['Mali', '61002'],
  ['Mali', '61003'],
  ['Guinea', '6110'],
  ['Guinea', '61101'],
  ['Guinea', '61102'],
  ['Guinea', '61104'],
  ['Guinea', '61105'],
  ['Guinea', '61110'],
  ['Ivory Coast', '6120'],
  ['Ivory Coast', '61202'],
  ['Ivory Coast', '61203'],
  ['Ivory Coast', '61204'],
  ['Ivory Coast', '61205'],
  ['Ivory Coast', '61206'],
  ['Burkina Faso', '6130'],
  ['Burkina Faso', '61301'],
  ['Burkina Faso', '61302'],
  ['Burkina Faso', '61303'],
  ['Niger', '6140'],
  ['Niger', '61401'],
  ['Niger', '61402'],
  ['Niger', '61403'],
  ['Niger', '61404'],
  ['Togo', '6150'],
  ['Togo', '61501'],
  ['Togo', '61502'],
  ['Togo', '61503'],
  ['Benin', '6160'],
  ['Benin', '61602'],
  ['Benin', '61603'],
  ['Mauritius', '6170'],
  ['Mauritius', '61701'],
  ['Mauritius', '61710'],
  ['Liberia', '6180'],
  ['Liberia', '61801'],
  ['Liberia', '61807'],
  ['Sierra Leone', '6190'],
  ['Sierra Leone', '61901'],
  ['Sierra Leone', '61902'],
  ['Sierra Leone', '61903'],
  ['Sierra Leone', '61905'],
  ['Sierra Leone', '61906'],
  ['Sierra Leone', '61907'],
  ['Ghana', '6200'],
  ['Ghana', '620001'],
  ['Ghana', '620002'],
  ['Ghana', '620003'],
  ['Ghana', '620004'],
  ['Ghana', '620006'],
  ['Ghana', '62001'],
  ['Ghana', '62002'],
  ['Ghana', '62003'],
  ['Ghana', '62004'],
  ['Ghana', '62006'],
  ['Ghana', '62007'],
  ['Ghana', '620501'],
  ['Nigeria', '6210'],
  ['Nigeria', '62120'],
  ['Nigeria', '62127'],
  ['Nigeria', '62130'],
  ['Nigeria', '62140'],
  ['Nigeria', '62141'],
  ['Nigeria', '62142'],
  ['Nigeria', '62150'],
  ['Nigeria', '62160'],
  ['Chad', '6220'],
  ['Chad', '62201'],
  ['Chad', '62202'],
  ['Chad', '62203'],
  ['Central Africa', '6230'],
  ['Central Africa', '62301'],
  ['Central Africa', '62302'],
  ['Central Africa', '62303'],
  ['Central Africa', '62304'],
  ['Central Africa', '62310'],
  ['Cameroon', '6240'],
  ['Cameroon', '62401'],
  ['Cameroon', '62402'],
  ['Cameroon', '62404'],
  ['Cape Verde Island', '6250'],
  ['Cape Verde Island', '62501'],
  ['Equatorial Guinea', '6270'],
  ['Equatorial Guinea', '62701'],
  ['Equatorial Guinea', '62703'],
  ['Gabon', '6280'],
  ['Gabon', '62801'],
  ['Gabon', '62802'],
  ['Gabon', '62803'],
  ['Congo', '6290'],
  ['Congo', '62901'],
  ['Congo', '62907'],
  ['Congo', '62910'],
  ['Congo', '62920'],
  ['Democratic Republic of the Congo', '6300'],
  ['Democratic Republic of the Congo', '63001'],
  ['Democratic Republic of the Congo', '63002'],
  ['Democratic Republic of the Congo', '63005'],
  ['Democratic Republic of the Congo', '63086'],
  ['Democratic Republic of the Congo', '63089'],
  ['Democratic Republic of the Congo', '63090'],
  ['Angola', '6310'],
  ['Angola', '63102'],
  ['Angola', '63103'],
  ['Seychelles', '6330'],
  ['Seychelles', '63301'],
  ['Seychelles', '63310'],
  ['Sudan', '6340'],
  ['Sudan', '63401'],
  ['Sudan', '63402'],
  ['Sudan', '63403'],
  ['Sudan', '63407'],
  ['Rwanda', '6350'],
  ['Rwanda', '63510'],
  ['Ethiopia', '6360'],
  ['Ethiopia', '63601'],
  ['Ethiopia', '63602'],
  ['Somalia', '6370'],
  ['Somalia', '63701'],
  ['Somalia', '63704'],
  ['Somalia', '63710'],
  ['Somalia', '63725'],
  ['Somalia', '63730'],
  ['Somalia', '63760'],
  ['Somalia', '63771'],
  ['Somalia', '63782'],
  ['Djibouti', '6380'],
  ['Djibouti', '63801'],
  ['Kenya', '6390'],
  ['Kenya', '63902'],
  ['Kenya', '63903'],
  ['Kenya', '63905'],
  ['Kenya', '63907'],
  ['Tanzania', '6400'],
  ['Tanzania', '64002'],
  ['Tanzania', '64003'],
  ['Tanzania', '64004'],
  ['Tanzania', '64005'],
  ['Tanzania', '64007'],
  ['Tanzania', '64008'],
  ['Tanzania', '64009'],
  ['Uganda', '6410'],
  ['Uganda', '64101'],
  ['Uganda', '64104'],
  ['Uganda', '64106'],
  ['Uganda', '64110'],
  ['Uganda', '64111'],
  ['Uganda', '64114'],
  ['Uganda', '64118'],
  ['Uganda', '64122'],
  ['Uganda', '64133'],
  ['Burundi', '6420'],
  ['Burundi', '64201'],
  ['Burundi', '64203'],
  ['Burundi', '64207'],
  ['Burundi', '64208'],
  ['Burundi', '64282'],
  ['Mozambique', '6430'],
  ['Mozambique', '64301'],
  ['Mozambique', '64303'],
  ['Mozambique', '64304'],
  ['Zambia', '6450'],
  ['Zambia', '64501'],
  ['Zambia', '64502'],
  ['Zambia', '64503'],
  ['Madagascar', '6460'],
  ['Madagascar', '64601'],
  ['Madagascar', '64602'],
  ['Madagascar', '64604'],
  ['Reunion Island', '6470'],
  ['Reunion Island', '64710'],
  ['Zimbabwe', '6480'],
  ['Zimbabwe', '64801'],
  ['Zimbabwe', '64803'],
  ['Zimbabwe', '64804'],
  ['Namibia', '6490'],
  ['Namibia', '64901'],
  ['Namibia', '64903'],
  ['Malawi', '6500'],
  ['Malawi', '65001'],
  ['Malawi', '65010'],
  ['Lesotho', '6510'],
  ['Lesotho', '65101'],
  ['Lesotho', '65102'],
  ['Botswana', '6520'],
  ['Botswana', '65201'],
  ['Botswana', '65202'],
  ['Botswana', '65204'],
  ['Swaziland', '6530'],
  ['Swaziland', '65310'],
  ['Comoros Islands', '6540'],
  ['Comoros Islands', '65401'],
  ['South Africa', '6550'],
  ['South Africa', '65501'],
  ['South Africa', '65502'],
  ['South Africa', '65507'],
  ['South Africa', '65510'],
  ['Eritrea', '6570'],
  ['Eritrea', '65701'],
  ['South Sudan', '6590'],
  ['South Sudan', '65902'],
  ['South Sudan', '65903'],
  ['South Sudan', '65904'],
  ['South Sudan', '65906'],
  ['Belize', '7020'],
  ['Belize', '70267'],
  ['Guatemala', '7040'],
  ['Guatemala', '70401'],
  ['Guatemala', '70402'],
  ['Guatemala', '70403'],
  ['El Salvador', '7060'],
  ['El Salvador', '70601'],
  ['El Salvador', '70602'],
  ['El Salvador', '70603'],
  ['El Salvador', '70604'],
  ['El Salvador', '70605'],
  ['Honduras', '7080'],
  ['Honduras', '70801'],
  ['Honduras', '70802'],
  ['Honduras', '70840'],
  ['Nicaragua', '7100'],
  ['Nicaragua', '71021'],
  ['Nicaragua', '71030'],
  ['Nicaragua', '710300'],
  ['Nicaragua', '71073'],
  ['Costa Rica', '7120'],
  ['Costa Rica', '71201'],
  ['Costa Rica', '71203'],
  ['Costa Rica', '71204'],
  ['Panama', '7140'],
  ['Panama', '71401'],
  ['Panama', '71403'],
  ['Panama', '71404'],
  ['Peru', '7160'],
  ['Peru', '71606'],
  ['Peru', '71607'],
  ['Peru', '71610'],
  ['Peru', '71615'],
  ['Peru', '71617'],
  ['Argentina', '7220'],
  ['Argentina', '72231'],
  ['Argentina', '72234'],
  ['Argentina', '72270'],
  ['Brazil', '7240'],
  ['Brazil', '72400'],
  ['Brazil', '72402'],
  ['Brazil', '72403'],
  ['Brazil', '72404'],
  ['Brazil', '72405'],
  ['Brazil', '72406'],
  ['Brazil', '72407'],
  ['Brazil', '72410'],
  ['Brazil', '72411'],
  ['Brazil', '72416'],
  ['Brazil', '72423'],
  ['Brazil', '72424'],
  ['Brazil', '72430'],
  ['Brazil', '72431'],
  ['Brazil', '72432'],
  ['Brazil', '72437'],
  ['Chile', '7300'],
  ['Chile', '73010'],
  ['Colombia', '7320'],
  ['Colombia', '732101'],
  ['Colombia', '732102'],
  ['Colombia', '732103'],
  ['Colombia', '732111'],
  ['Colombia', '732123'],
  ['Colombia', '732130'],
  ['Colombia', '732154'],
  ['Colombia', '732165'],
  ['Venezuela', '7340'],
  ['Venezuela', '73401'],
  ['Venezuela', '73402'],
  ['Venezuela', '73403'],
  ['Venezuela', '73404'],
  ['Venezuela', '73406'],
  ['Bolivia', '7360'],
  ['Bolivia', '73601'],
  ['Bolivia', '73602'],
  ['Bolivia', '73603'],
  ['Tanzania', '7380'],
  ['Tanzania', '73801'],
  ['Ecuador', '7400'],
  ['Ecuador', '74000'],
  ['Ecuador', '74001'],
  ['Ecuador', '74002'],
  ['Paraguay', '7440'],
  ['Paraguay', '74401'],
  ['Paraguay', '74402'],
  ['Paraguay', '74404'],
  ['Paraguay', '74405'],
  ['Suriname', '7460'],
  ['Suriname', '74602'],
  ['Uruguay', '7480'],
  ['Uruguay', '74801'],
  ['Uruguay', '74802'],
  ['Uruguay', '74807'],
  ['Uruguay', '74810'],
  ['Taiwan', '90105']
];
