import React from 'react';
import { flag } from 'country-emoji';
import ISOcode from './ISOcode';

const camelize = str =>
  str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) =>
    +match === 0 ? '' : index === 0 ? match.toLowerCase() : match.toUpperCase()
  );

const flagEmoji = country =>
  navigator.platform.includes('Mac')
    ? flag(country)
    : ISOcode?.[country] && (
        <img src={`https://flagcdn.com/16x12/${ISOcode[country]}.png`} />
      );

const addKey = data =>
  data.map((r, idx) => {
    r.key = ++idx;
    return r;
  });

const capitalize = str =>
  `${str.substr(0, 1).toUpperCase()}${str.substr(1).toLowerCase()}`;

export { camelize, flagEmoji, addKey, capitalize };
