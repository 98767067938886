export const MCC = [
  ['4120', '412', '0', 'Afghanistan', 'DEFAULT AFGHANISTAN'],
  ['41201', '412', '1', 'Afghanistan', 'Afghan Wireless/AWCC'],
  ['41220', '412', '20', 'Afghanistan', 'Roshan'],
  ['41240', '412', '40', 'Afghanistan', 'MTN'],
  ['41250', '412', '50', 'Afghanistan', 'Etisalat'],
  ['41280', '412', '80', 'Afghanistan', 'Afghan Telecom (Salaam)'],
  ['41280', '412', '80', 'Afghanistan', 'Afghan Telecom (Salaam)'],
  ['41280', '412', '80', 'Afghanistan', 'Afghan Telecom (Salaam)'],
  ['41280', '412', '80', 'Afghanistan', 'Afghan Telecom (Salaam)'],
  ['41280', '412', '80', 'Afghanistan', 'Afghan Telecom (Salaam)'],
  ['41288', '412', '88', 'Afghanistan', 'Afghan Telecom Corp. (AT)'],
  ['2760', '276', '0', 'Albania', 'DEFAULT ALBANIA'],
  ['27601', '276', '1', 'Albania', 'AMC'],
  ['27602', '276', '2', 'Albania', 'VODAFONE'],
  ['27603', '276', '3', 'Albania', 'EAGLE'],
  ['27604', '276', '4', 'Albania', 'PLUS'],
  ['6030', '603', '0', 'Algeria', 'DEFAULT ALGERIA'],
  ['60301', '603', '1', 'Algeria', 'ATM MOBILIS'],
  ['60302', '603', '2', 'Algeria', 'DJEZZY'],
  ['60302', '603', '2', 'Algeria', 'DJEZZY'],
  ['60302', '603', '2', 'Algeria', 'DJEZZY'],
  ['60302', '603', '2', 'Algeria', 'DJEZZY'],
  ['60303', '603', '3', 'Algeria', 'WATANIYA'],
  ['2130', '213', '0', 'Andorra', 'DEFAULT ANDORRA'],
  ['21303', '213', '3', 'Andorra', 'S.T.A.'],
  ['21303', '213', '3', 'Andorra', 'S.T.A.'],
  ['21303', '213', '3', 'Andorra', 'S.T.A.'],
  ['6310', '631', '0', 'Angola', 'DEFAULT ANGOLA'],
  ['63102', '631', '2', 'Angola', 'UNITEL'],
  ['63102', '631', '2', 'Angola', 'UNITEL'],
  ['63103', '631', '3', 'Angola', 'MoviCel'],
  ['310002', '310', '2', 'Antigua and Barbuda', 'CABLE & WIRELESS'],
  ['310002', '310', '2', 'Antigua and Barbuda', 'CINGULAR WIRELESS'],
  ['310002', '310', '2', 'Antigua and Barbuda', 'CABLE & WIRELESS'],
  ['310002', '310', '2', 'Antigua and Barbuda', 'CABLE & WIRELESS'],
  ['310002', '310', '2', 'Antigua and Barbuda', 'CABLE & WIRELESS'],
  ['310002', '310', '2', 'Antigua and Barbuda', 'APUA'],
  ['7220', '722', '0', 'Argentina', 'DEFAULT ARGENTINA'],
  ['72231', '722', '31', 'Argentina', 'CTI'],
  ['72234', '722', '34', 'Argentina', 'TELECOM PERSONAL'],
  ['72270', '722', '70', 'Argentina', 'Unknown-ARGENTINA-1151'],
  ['2830', '283', '0', 'Armenia', 'DEFAULT ARMENIA'],
  ['28301', '283', '1', 'Armenia', 'ARMENTEL'],
  ['28304', '283', '4', 'Armenia', 'K-TELECOM'],
  ['28304', '283', '4', 'Armenia', 'K-TELECOM'],
  ['28305', '283', '5', 'Armenia', 'MTS - Vivacell'],
  ['28305', '283', '5', 'Armenia', 'MTS - Vivacell'],
  ['28305', '283', '5', 'Armenia', 'MTS - Vivacell'],
  ['28305', '283', '5', 'Armenia', 'MTS - Vivacell'],
  ['28310', '283', '10', 'Armenia', 'Orange (Ucom)'],
  ['28310', '283', '10', 'Armenia', 'Orange (Ucom)'],
  ['28310', '283', '10', 'Armenia', 'Orange (Ucom)'],
  ['28310', '283', '10', 'Armenia', 'Orange (Ucom)'],
  ['3630', '363', '0', 'Aruba', 'DEFAULT ARUBA'],
  ['36301', '363', '1', 'Aruba', 'SETAR'],
  ['36301', '363', '1', 'Aruba', 'SETAR'],
  ['36310', '363', '10', 'Aruba', 'MOSSEL DIGICEL'],
  ['36310', '363', '10', 'Aruba', 'MOSSEL DIGICEL'],
  ['5050', '505', '0', 'Australia', 'DEFAULT AUSTRALIA'],
  ['50501', '505', '1', 'Australia', 'TELSTRA'],
  ['50501', '505', '1', 'Australia', 'TELSTRA'],
  ['50501', '505', '1', 'Australia', 'TELSTRA'],
  ['50501', '505', '1', 'Australia', 'TELSTRA'],
  ['50501', '505', '1', 'Australia', 'TELSTRA'],
  ['50501', '505', '1', 'Australia', 'TELSTRA'],
  ['50501', '505', '1', 'Australia', 'TELSTRA'],
  ['50501', '505', '1', 'Australia', 'TELSTRA'],
  ['50501', '505', '1', 'Australia', 'TELSTRA'],
  ['50501', '505', '1', 'Australia', 'TELSTRA'],
  ['50501', '505', '1', 'Australia', 'TELSTRA'],
  ['50501', '505', '1', 'Australia', 'TELSTRA'],
  ['50501', '505', '1', 'Australia', 'TELSTRA'],
  ['50501', '505', '1', 'Australia', 'TELSTRA'],
  ['50501', '505', '1', 'Australia', 'TELSTRA'],
  ['50502', '505', '2', 'Australia', 'OPTUS'],
  ['50502', '505', '2', 'Australia', 'OPTUS'],
  ['50502', '505', '2', 'Australia', 'OPTUS'],
  ['50502', '505', '2', 'Australia', 'OPTUS'],
  ['50502', '505', '2', 'Australia', 'OPTUS'],
  ['50502', '505', '2', 'Australia', 'OPTUS'],
  ['50502', '505', '2', 'Australia', 'OPTUS'],
  ['50502', '505', '2', 'Australia', 'OPTUS'],
  ['50502', '505', '2', 'Australia', 'OPTUS'],
  ['50502', '505', '2', 'Australia', 'OPTUS'],
  ['50502', '505', '2', 'Australia', 'OPTUS'],
  ['50502', '505', '2', 'Australia', 'OPTUS'],
  ['50502', '505', '2', 'Australia', 'OPTUS'],
  ['50503', '505', '3', 'Australia', 'VODAFONE'],
  ['50503', '505', '3', 'Australia', 'VODAFONE'],
  ['50503', '505', '3', 'Australia', 'VODAFONE'],
  ['50503', '505', '3', 'Australia', 'VODAFONE'],
  ['50503', '505', '3', 'Australia', 'VODAFONE'],
  ['50503', '505', '3', 'Australia', 'VODAFONE'],
  ['50503', '505', '3', 'Australia', 'VODAFONE'],
  ['50503', '505', '3', 'Australia', 'VODAFONE'],
  ['50506', '505', '6', 'Australia', 'HUTCHINSON 3G'],
  ['50506', '505', '6', 'Australia', 'HUTCHINSON 3G'],
  ['50512', '505', '12', 'Australia', 'HUTCHINSON TELECOMMUNICATIONS'],
  ['2320', '232', '0', 'Austria', 'DEFAULT AUSTRIA'],
  ['23201', '232', '1', 'Austria', 'MOBILKOM A1'],
  ['23201', '232', '1', 'Austria', 'TRE (H3G)'],
  ['23202', '232', '2', 'Austria', 'A1 (Mobilkom)'],
  ['23202', '232', '2', 'Austria', 'A1 (Mobilkom)'],
  ['23202', '232', '2', 'Austria', 'A1 (Mobilkom)'],
  ['23203', '232', '3', 'Austria', 'T-MOBILE'],
  ['23204', '232', '4', 'Austria', 'T-Mobile'],
  ['23204', '232', '4', 'Austria', 'T-Mobile'],
  ['23204', '232', '4', 'Austria', 'T-Mobile'],
  ['23204', '232', '4', 'Austria', 'T-Mobile'],
  ['23204', '232', '4', 'Austria', 'T-Mobile'],
  ['23205', '232', '5', 'Austria', 'CONNECT ONE'],
  ['23206', '232', '6', 'Austria', 'Hutchinson (Orange)'],
  ['23207', '232', '7', 'Austria', 'TELERING'],
  ['23209', '232', '9', 'Austria', 'TELE 2'],
  ['23211', '232', '11', 'Austria', 'A1 (Mobilkom)'],
  ['23211', '232', '11', 'Austria', 'A1 (Mobilkom)'],
  ['23211', '232', '11', 'Austria', 'A1 (Mobilkom)'],
  ['23213', '232', '13', 'Austria', 'Hutchinson (Orange)'],
  ['23213', '232', '13', 'Austria', 'Hutchinson (Orange)'],
  ['23213', '232', '13', 'Austria', 'Hutchinson (Orange)'],
  ['23213', '232', '13', 'Austria', 'Hutchinson (Orange)'],
  ['23213', '232', '13', 'Austria', 'Hutchinson (Orange)'],
  ['4000', '400', '0', 'Azerbaijan', 'DEFAULT AZERBAIJAN'],
  ['40001', '400', '1', 'Azerbaijan', 'AZERCELL'],
  ['40002', '400', '2', 'Azerbaijan', 'BAKCELL'],
  ['40003', '400', '3', 'Azerbaijan', 'FONEX (Catel)'],
  ['40004', '400', '4', 'Azerbaijan', 'Azerfon LLC (Nar Mobile)'],
  ['4260', '426', '0', 'Bahrain', 'DEFAULT BAHRAIN'],
  ['42601', '426', '1', 'Bahrain', 'BATELCO'],
  ['42602', '426', '2', 'Bahrain', 'VODAFONE'],
  ['42604', '426', '4', 'Bahrain', 'STC Viva'],
  ['42604', '426', '4', 'Bahrain', 'STC Viva'],
  ['42604', '426', '4', 'Bahrain', 'STC Viva'],
  ['42604', '426', '4', 'Bahrain', 'STC Viva'],
  ['42605', '426', '5', 'Bahrain', 'Batelco'],
  ['42605', '426', '5', 'Bahrain', 'Batelco'],
  ['42605', '426', '5', 'Bahrain', 'Batelco'],
  ['42605', '426', '5', 'Bahrain', 'Batelco'],
  ['42605', '426', '5', 'Bahrain', 'Batelco'],
  ['42605', '426', '5', 'Bahrain', 'Batelco'],
  ['42605', '426', '5', 'Bahrain', 'Batelco'],
  ['42605', '426', '5', 'Bahrain', 'Batelco'],
  ['42605', '426', '5', 'Bahrain', 'Batelco'],
  ['4700', '470', '0', 'Bangladesh', 'DEFAULT BANGLADESH'],
  ['47001', '470', '1', 'Bangladesh', 'GRAMEEN PHONE LIMITED'],
  ['47002', '470', '2', 'Bangladesh', 'SHEBA TELECOM'],
  ['47003', '470', '3', 'Bangladesh', 'AKTEL'],
  ['47004', '470', '4', 'Bangladesh', 'Teletalk'],
  ['47005', '470', '5', 'Bangladesh', 'CityCell'],
  ['47007', '470', '7', 'Bangladesh', 'Barthi Airtel Bangladesh'],
  ['310002', '310', '2', 'Barbados', 'MOSSEL DIGICEL'],
  ['34260', '342', '60', 'Barbados', 'CABLE & WIRELESS'],
  ['34260', '342', '60', 'Barbados', 'CABLE & WIRELESS'],
  ['34260', '342', '60', 'Barbados', 'CABLE & WIRELESS'],
  ['34260', '342', '60', 'Barbados', 'CABLE & WIRELESS'],
  ['34281', '342', '81', 'Barbados', 'CINGULAR WIRELESS'],
  ['34281', '342', '81', 'Barbados', 'CINGULAR WIRELESS'],
  ['34281', '342', '81', 'Barbados', 'CINGULAR WIRELESS'],
  ['34281', '342', '81', 'Barbados', 'CINGULAR WIRELESS'],
  ['34281', '342', '81', 'Barbados', 'CINGULAR WIRELESS'],
  ['2570', '257', '0', 'Belarus', 'DEFAULT BELARUS'],
  ['25701', '257', '1', 'Belarus', 'MDC (VELCOM)'],
  ['25701', '257', '1', 'Belarus', 'MDC (VELCOM)'],
  ['25701', '257', '1', 'Belarus', 'MDC (VELCOM)'],
  ['25702', '257', '2', 'Belarus', 'JLLC MOBILE (MTS)'],
  ['25702', '257', '2', 'Belarus', 'JLLC MOBILE (MTS)'],
  ['25702', '257', '2', 'Belarus', 'JLLC MOBILE (MTS)'],
  ['25704', '257', '4', 'Belarus', 'Best - Life'],
  ['2060', '206', '0', 'Belgium', 'DEFAULT BELGIUM'],
  ['20601', '206', '1', 'Belgium', 'PROXIMUS'],
  ['20601', '206', '1', 'Belgium', 'PROXIMUS'],
  ['20601', '206', '1', 'Belgium', 'PROXIMUS'],
  ['20601', '206', '1', 'Belgium', 'PROXIMUS'],
  ['20601', '206', '1', 'Belgium', 'PROXIMUS'],
  ['20601', '206', '1', 'Belgium', 'PROXIMUS'],
  ['20601', '206', '1', 'Belgium', 'PROXIMUS'],
  ['20601', '206', '1', 'Belgium', 'PROXIMUS'],
  ['20601', '206', '1', 'Belgium', 'PROXIMUS'],
  ['20605', '206', '5', 'Belgium', 'Telenet'],
  ['20605', '206', '5', 'Belgium', 'Telenet'],
  ['20605', '206', '5', 'Belgium', 'Telenet'],
  ['20605', '206', '5', 'Belgium', 'Telenet'],
  ['20605', '206', '5', 'Belgium', 'Telenet'],
  ['20605', '206', '5', 'Belgium', 'Telenet'],
  ['20605', '206', '5', 'Belgium', 'Telenet'],
  ['20606', '206', '6', 'Belgium', 'Lycamobile'],
  ['20606', '206', '6', 'Belgium', 'Lycamobile'],
  ['20606', '206', '6', 'Belgium', 'Lycamobile'],
  ['20606', '206', '6', 'Belgium', 'Lycamobile'],
  ['20606', '206', '6', 'Belgium', 'Lycamobile'],
  ['20606', '206', '6', 'Belgium', 'Lycamobile'],
  ['20606', '206', '6', 'Belgium', 'Lycamobile'],
  ['20606', '206', '6', 'Belgium', 'Lycamobile'],
  ['20606', '206', '6', 'Belgium', 'Lycamobile'],
  ['20606', '206', '6', 'Belgium', 'Lycamobile'],
  ['20606', '206', '6', 'Belgium', 'Lycamobile'],
  ['20606', '206', '6', 'Belgium', 'Lycamobile'],
  ['20606', '206', '6', 'Belgium', 'Lycamobile'],
  ['20606', '206', '6', 'Belgium', 'Lycamobile'],
  ['20606', '206', '6', 'Belgium', 'Lycamobile'],
  ['20606', '206', '6', 'Belgium', 'Lycamobile'],
  ['20606', '206', '6', 'Belgium', 'Lycamobile'],
  ['20610', '206', '10', 'Belgium', 'MOBISTAR'],
  ['20610', '206', '10', 'Belgium', 'MOBISTAR'],
  ['20610', '206', '10', 'Belgium', 'MOBISTAR'],
  ['20610', '206', '10', 'Belgium', 'MOBISTAR'],
  ['20610', '206', '10', 'Belgium', 'MOBISTAR'],
  ['20610', '206', '10', 'Belgium', 'MOBISTAR'],
  ['20610', '206', '10', 'Belgium', 'MOBISTAR'],
  ['20610', '206', '10', 'Belgium', 'MOBISTAR'],
  ['20620', '206', '20', 'Belgium', 'BASE'],
  ['20620', '206', '20', 'Belgium', 'BASE'],
  ['20620', '206', '20', 'Belgium', 'BASE'],
  ['20620', '206', '20', 'Belgium', 'BASE'],
  ['20640', '206', '40', 'Belgium', 'Join Experience'],
  ['20640', '206', '40', 'Belgium', 'Join Experience'],
  ['7020', '702', '0', 'Belize', 'DEFAULT BELIZE'],
  ['70267', '702', '67', 'Belize', 'BELIZE TELECOM'],
  ['70267', '702', '67', 'Belize', 'BELIZE TELECOM'],
  ['6160', '616', '0', 'Benin', 'DEFAULT BENIN'],
  ['61602', '616', '2', 'Benin', 'TELECEL'],
  ['61603', '616', '3', 'Benin', 'SPACETEL'],
  ['310002', '310', '2', 'Bermuda', 'MOBILITY'],
  ['310002', '310', '2', 'Bermuda', 'CINGULAR WIRELESS'],
  ['4020', '402', '0', 'Bhutan', 'DEFAULT BHUTAN'],
  ['40211', '402', '11', 'Bhutan', 'B-MOBILE'],
  ['7360', '736', '0', 'Bolivia', 'DEFAULT BOLIVIA'],
  ['73601', '736', '1', 'Bolivia', 'NUEVATEL BOLIVIA'],
  ['73602', '736', '2', 'Bolivia', 'ENTEL'],
  ['73602', '736', '2', 'Bolivia', 'ENTEL'],
  ['73603', '736', '3', 'Bolivia', 'Telcel (Tigo)'],
  ['73603', '736', '3', 'Bolivia', 'Telcel (Tigo)'],
  ['73603', '736', '3', 'Bolivia', 'Telcel (Tigo)'],
  ['2180', '218', '0', 'Bosnia-Herzegovina', 'DEFAULT BOSNIA'],
  ['21803', '218', '3', 'Bosnia-Herzegovina', 'ERONET'],
  ['21803', '218', '3', 'Bosnia-Herzegovina', 'ERONET'],
  ['21805', '218', '5', 'Bosnia-Herzegovina', 'MOBILNA SRPSKE'],
  ['21809', '218', '9', 'Bosnia-Herzegovina', 'GSMBIH'],
  ['21809', '218', '9', 'Bosnia-Herzegovina', 'GSMBIH'],
  ['21890', '218', '90', 'Bosnia-Herzegovina', 'Unknown-BOSNIA-6118'],
  ['6520', '652', '0', 'Botswana', 'DEFAULT BOTSWANA'],
  ['65201', '652', '1', 'Botswana', 'MASCOM'],
  ['65201', '652', '1', 'Botswana', 'MASCOM'],
  ['65201', '652', '1', 'Botswana', 'MASCOM'],
  ['65201', '652', '1', 'Botswana', 'MASCOM'],
  ['65201', '652', '1', 'Botswana', 'MASCOM'],
  ['65201', '652', '1', 'Botswana', 'MASCOM'],
  ['65201', '652', '1', 'Botswana', 'MASCOM'],
  ['65201', '652', '1', 'Botswana', 'MASCOM'],
  ['65201', '652', '1', 'Botswana', 'MASCOM'],
  ['65201', '652', '1', 'Botswana', 'MASCOM'],
  ['65201', '652', '1', 'Botswana', 'MASCOM'],
  ['65201', '652', '1', 'Botswana', 'MASCOM'],
  ['65201', '652', '1', 'Botswana', 'MASCOM'],
  ['65201', '652', '1', 'Botswana', 'MASCOM'],
  ['65201', '652', '1', 'Botswana', 'MASCOM'],
  ['65201', '652', '1', 'Botswana', 'MASCOM'],
  ['65202', '652', '2', 'Botswana', 'ORANGE (VISTA CELLULAR)'],
  ['65202', '652', '2', 'Botswana', 'ORANGE (VISTA CELLULAR)'],
  ['65202', '652', '2', 'Botswana', 'ORANGE (VISTA CELLULAR)'],
  ['65202', '652', '2', 'Botswana', 'ORANGE (VISTA CELLULAR)'],
  ['65202', '652', '2', 'Botswana', 'ORANGE (VISTA CELLULAR)'],
  ['65202', '652', '2', 'Botswana', 'ORANGE (VISTA CELLULAR)'],
  ['65202', '652', '2', 'Botswana', 'ORANGE (VISTA CELLULAR)'],
  ['65202', '652', '2', 'Botswana', 'ORANGE (VISTA CELLULAR)'],
  ['65202', '652', '2', 'Botswana', 'ORANGE (VISTA CELLULAR)'],
  ['65202', '652', '2', 'Botswana', 'ORANGE (VISTA CELLULAR)'],
  ['65204', '652', '4', 'Botswana', 'BTC'],
  ['7240', '724', '0', 'Brazil', 'DEFAULT BRAZIL'],
  ['72400', '724', '0', 'Brazil', 'Nextel Telecomunicacoes Ltda'],
  ['72402', '724', '2', 'Brazil', 'TIM Participacoes SA (TIM)'],
  ['72403', '724', '3', 'Brazil', 'Tim'],
  ['72403', '724', '3', 'Brazil', 'Tim'],
  ['72405', '724', '5', 'Brazil', 'CLARO SA'],
  ['72406', '724', '6', 'Brazil', 'VIVO Participacoes SA (VIVO)'],
  ['72407', '724', '7', 'Brazil', 'Sercomtel Celular SA'],
  ['72410', '724', '10', 'Brazil', 'Vivo'],
  ['72411', '724', '11', 'Brazil', 'Vivo'],
  ['72416', '724', '16', 'Brazil', 'Telemar Norte Leste SA (Oi)'],
  ['72423', '724', '23', 'Brazil', 'Vivo'],
  ['72424', '724', '24', 'Brazil', 'Oi (Brasil Telecom)'],
  ['72430', '724', '30', 'Brazil', 'Oi (Brasil Telecom)'],
  ['72431', '724', '31', 'Brazil', 'OI'],
  ['72432', '724', '32', 'Brazil', 'CTBC Celular SA (CTBC)'],
  ['72437', '724', '37', 'Brazil', 'Unicel do Brasil Telecomunicacoes Ltda'],
  ['5280', '528', '0', 'Brunei Darussalam', 'DEFAULT BRUNEI'],
  ['52803', '528', '3', 'Brunei Darussalam', 'B-MOBILE'],
  ['52811', '528', '11', 'Brunei Darussalam', 'DST COMMUNICATIONS'],
  ['2840', '284', '0', 'Bulgaria', 'DEFAULT BULGARIA'],
  ['28401', '284', '1', 'Bulgaria', 'MOBILTEL'],
  ['28403', '284', '3', 'Bulgaria', 'VIVATEL'],
  ['28405', '284', '5', 'Bulgaria', 'GLOBUL'],
  ['6130', '613', '0', 'Burkina Faso', 'DEFAULT BURKINA FASO'],
  ['61301', '613', '1', 'Burkina Faso', 'ONATEL'],
  ['61302', '613', '2', 'Burkina Faso', 'CELTEL'],
  ['61303', '613', '3', 'Burkina Faso', 'TELECEL'],
  ['6420', '642', '0', 'Burundi', 'DEFAULT BURUNDI'],
  ['64201', '642', '1', 'Burundi', 'Leo - Econet UCOM (Telecel)'],
  ['64201', '642', '1', 'Burundi', 'Leo - Econet UCOM (Telecel)'],
  ['64201', '642', '1', 'Burundi', 'Leo - Econet UCOM (Telecel)'],
  ['64201', '642', '1', 'Burundi', 'Leo - Econet UCOM (Telecel)'],
  ['64201', '642', '1', 'Burundi', 'Leo - Econet UCOM (Telecel)'],
  ['64203', '642', '3', 'Burundi', 'TELECEL'],
  ['64207', '642', '7', 'Burundi', 'LACELL SU'],
  ['64207', '642', '7', 'Burundi', 'LACELL SU'],
  ['64208', '642', '8', 'Burundi', 'Lumitel (Viettel)'],
  ['64208', '642', '8', 'Burundi', 'Lumitel (Viettel)'],
  ['64282', '642', '82', 'Burundi', 'Leo - Econet UCOM (Telecel)'],
  ['64282', '642', '82', 'Burundi', 'Leo - Econet UCOM (Telecel)'],
  ['64282', '642', '82', 'Burundi', 'Leo - Econet UCOM (Telecel)'],
  ['64282', '642', '82', 'Burundi', 'Leo - Econet UCOM (Telecel)'],
  ['64282', '642', '82', 'Burundi', 'Leo - Econet UCOM (Telecel)'],
  ['4560', '456', '0', 'Cambodia', 'DEFAULT CAMBODIA'],
  ['45601', '456', '1', 'Cambodia', 'CAM GSM'],
  ['45602', '456', '2', 'Cambodia', 'SAMART COMMS'],
  ['45602', '456', '2', 'Cambodia', 'SAMART COMMS'],
  ['45603', '456', '3', 'Cambodia', 'SHINAWATRA'],
  ['45604', '456', '4', 'Cambodia', 'qb (Cadcomms)'],
  ['45605', '456', '5', 'Cambodia', 'Smart Axiata (Hello / Latelz)'],
  ['45605', '456', '5', 'Cambodia', 'Smart Axiata (Hello / Latelz)'],
  ['45605', '456', '5', 'Cambodia', 'Smart Axiata (Hello / Latelz)'],
  ['45605', '456', '5', 'Cambodia', 'Smart Axiata (Hello / Latelz)'],
  ['45605', '456', '5', 'Cambodia', 'Smart Axiata (Hello / Latelz)'],
  ['45605', '456', '5', 'Cambodia', 'Smart Axiata (Hello / Latelz)'],
  ['45605', '456', '5', 'Cambodia', 'Smart Axiata (Hello / Latelz)'],
  ['45605', '456', '5', 'Cambodia', 'Smart Axiata (Hello / Latelz)'],
  ['45606', '456', '6', 'Cambodia', 'Smart Axiata (Hello / Latelz)'],
  ['45606', '456', '6', 'Cambodia', 'Smart Axiata (Hello / Latelz)'],
  ['45606', '456', '6', 'Cambodia', 'Smart Axiata (Hello / Latelz)'],
  ['45606', '456', '6', 'Cambodia', 'Smart Axiata (Hello / Latelz)'],
  ['45606', '456', '6', 'Cambodia', 'Smart Axiata (Hello / Latelz)'],
  ['45606', '456', '6', 'Cambodia', 'Smart Axiata (Hello / Latelz)'],
  ['45606', '456', '6', 'Cambodia', 'Smart Axiata (Hello / Latelz)'],
  ['45606', '456', '6', 'Cambodia', 'Smart Axiata (Hello / Latelz)'],
  ['45608', '456', '8', 'Cambodia', 'Metfone (Viettel Cambodia)'],
  ['45608', '456', '8', 'Cambodia', 'Metfone (Viettel Cambodia)'],
  ['45608', '456', '8', 'Cambodia', 'Metfone (Viettel Cambodia)'],
  ['45608', '456', '8', 'Cambodia', 'Metfone (Viettel Cambodia)'],
  ['45608', '456', '8', 'Cambodia', 'Metfone (Viettel Cambodia)'],
  ['45608', '456', '8', 'Cambodia', 'Metfone (Viettel Cambodia)'],
  ['45608', '456', '8', 'Cambodia', 'Metfone (Viettel Cambodia)'],
  ['45608', '456', '8', 'Cambodia', 'Metfone (Viettel Cambodia)'],
  ['45608', '456', '8', 'Cambodia', 'Metfone (Viettel Cambodia)'],
  ['45608', '456', '8', 'Cambodia', 'Metfone (Viettel Cambodia)'],
  ['45608', '456', '8', 'Cambodia', 'Metfone (Viettel Cambodia)'],
  ['45608', '456', '8', 'Cambodia', 'Metfone (Viettel Cambodia)'],
  ['45609', '456', '9', 'Cambodia', 'Metfone (Viettel Cambodia)'],
  ['45609', '456', '9', 'Cambodia', 'Metfone (Viettel Cambodia)'],
  ['45609', '456', '9', 'Cambodia', 'Metfone (Viettel Cambodia)'],
  ['45609', '456', '9', 'Cambodia', 'Metfone (Viettel Cambodia)'],
  ['45609', '456', '9', 'Cambodia', 'Metfone (Viettel Cambodia)'],
  ['45609', '456', '9', 'Cambodia', 'Metfone (Viettel Cambodia)'],
  ['45609', '456', '9', 'Cambodia', 'Metfone (Viettel Cambodia)'],
  ['45609', '456', '9', 'Cambodia', 'Metfone (Viettel Cambodia)'],
  ['45609', '456', '9', 'Cambodia', 'Metfone (Viettel Cambodia)'],
  ['45609', '456', '9', 'Cambodia', 'Metfone (Viettel Cambodia)'],
  ['45609', '456', '9', 'Cambodia', 'Metfone (Viettel Cambodia)'],
  ['45609', '456', '9', 'Cambodia', 'Metfone (Viettel Cambodia)'],
  ['45611', '456', '11', 'Cambodia', 'Seatel Camtel'],
  ['45618', '456', '18', 'Cambodia', 'Cellcard (Mobitel/ CamGSM)'],
  ['45618', '456', '18', 'Cambodia', 'Cellcard (Mobitel/ CamGSM)'],
  ['45618', '456', '18', 'Cambodia', 'Cellcard (Mobitel/ CamGSM)'],
  ['45618', '456', '18', 'Cambodia', 'Cellcard (Mobitel/ CamGSM)'],
  ['45618', '456', '18', 'Cambodia', 'Cellcard (Mobitel/ CamGSM)'],
  ['45618', '456', '18', 'Cambodia', 'Cellcard (Mobitel/ CamGSM)'],
  ['45618', '456', '18', 'Cambodia', 'Cellcard (Mobitel/ CamGSM)'],
  ['45618', '456', '18', 'Cambodia', 'Cellcard (Mobitel/ CamGSM)'],
  ['6240', '624', '0', 'Cameroon', 'DEFAULT CAMEROON'],
  ['62401', '624', '1', 'Cameroon', 'MTN'],
  ['62401', '624', '1', 'Cameroon', 'MTN'],
  ['62401', '624', '1', 'Cameroon', 'MTN'],
  ['62401', '624', '1', 'Cameroon', 'MTN'],
  ['62401', '624', '1', 'Cameroon', 'MTN'],
  ['62401', '624', '1', 'Cameroon', 'MTN'],
  ['62401', '624', '1', 'Cameroon', 'MTN'],
  ['62401', '624', '1', 'Cameroon', 'MTN'],
  ['62401', '624', '1', 'Cameroon', 'MTN'],
  ['62401', '624', '1', 'Cameroon', 'MTN'],
  ['62401', '624', '1', 'Cameroon', 'MTN'],
  ['62402', '624', '2', 'Cameroon', 'ORANGE'],
  ['62402', '624', '2', 'Cameroon', 'ORANGE'],
  ['62402', '624', '2', 'Cameroon', 'ORANGE'],
  ['62402', '624', '2', 'Cameroon', 'ORANGE'],
  ['62402', '624', '2', 'Cameroon', 'ORANGE'],
  ['62402', '624', '2', 'Cameroon', 'ORANGE'],
  ['62402', '624', '2', 'Cameroon', 'ORANGE'],
  ['62402', '624', '2', 'Cameroon', 'ORANGE'],
  ['62402', '624', '2', 'Cameroon', 'ORANGE'],
  ['62402', '624', '2', 'Cameroon', 'ORANGE'],
  ['62402', '624', '2', 'Cameroon', 'ORANGE'],
  ['62404', '624', '4', 'Cameroon', 'Nexttel (Viettel)'],
  ['62404', '624', '4', 'Cameroon', 'Nexttel (Viettel)'],
  ['302032', '302', '32', 'Canada', 'Unknown-CANADA-6478'],
  ['302037', '302', '37', 'Canada', 'MICROCELL'],
  ['302037', '302', '37', 'Canada', 'MICROCELL'],
  ['302037', '302', '37', 'Canada', 'MICROCELL'],
  ['302037', '302', '37', 'Canada', 'MICROCELL'],
  ['302037', '302', '37', 'Canada', 'MICROCELL'],
  ['302037', '302', '37', 'Canada', 'MICROCELL'],
  ['302037', '302', '37', 'Canada', 'MICROCELL'],
  ['302037', '302', '37', 'Canada', 'MICROCELL'],
  ['302037', '302', '37', 'Canada', 'MICROCELL'],
  ['302037', '302', '37', 'Canada', 'MICROCELL'],
  ['302037', '302', '37', 'Canada', 'MICROCELL'],
  ['302037', '302', '37', 'Canada', 'MICROCELL'],
  ['302037', '302', '37', 'Canada', 'MICROCELL'],
  ['302037', '302', '37', 'Canada', 'MICROCELL'],
  ['302037', '302', '37', 'Canada', 'MICROCELL'],
  ['302037', '302', '37', 'Canada', 'MICROCELL'],
  ['302037', '302', '37', 'Canada', 'MICROCELL'],
  ['302037', '302', '37', 'Canada', 'MICROCELL'],
  ['302037', '302', '37', 'Canada', 'MICROCELL'],
  ['302037', '302', '37', 'Canada', 'MICROCELL'],
  ['302037', '302', '37', 'Canada', 'MICROCELL'],
  ['302037', '302', '37', 'Canada', 'MICROCELL'],
  ['302037', '302', '37', 'Canada', 'MICROCELL'],
  ['302037', '302', '37', 'Canada', 'MICROCELL'],
  ['302037', '302', '37', 'Canada', 'MICROCELL'],
  ['302037', '302', '37', 'Canada', 'MICROCELL'],
  ['302037', '302', '37', 'Canada', 'MICROCELL'],
  ['302037', '302', '37', 'Canada', 'MICROCELL'],
  ['302037', '302', '37', 'Canada', 'MICROCELL'],
  ['302037', '302', '37', 'Canada', 'MICROCELL'],
  ['302037', '302', '37', 'Canada', 'MICROCELL'],
  ['302037', '302', '37', 'Canada', 'MICROCELL'],
  ['302037', '302', '37', 'Canada', 'MICROCELL'],
  ['302037', '302', '37', 'Canada', 'MICROCELL'],
  ['302037', '302', '37', 'Canada', 'MICROCELL'],
  ['302037', '302', '37', 'Canada', 'MICROCELL'],
  ['302037', '302', '37', 'Canada', 'MICROCELL'],
  ['302037', '302', '37', 'Canada', 'MICROCELL'],
  ['302037', '302', '37', 'Canada', 'MICROCELL'],
  ['302037', '302', '37', 'Canada', 'MICROCELL'],
  ['302037', '302', '37', 'Canada', 'MICROCELL'],
  ['302037', '302', '37', 'Canada', 'MICROCELL'],
  ['302037', '302', '37', 'Canada', 'MICROCELL'],
  ['302037', '302', '37', 'Canada', 'MICROCELL'],
  ['302037', '302', '37', 'Canada', 'MICROCELL'],
  ['302037', '302', '37', 'Canada', 'MICROCELL'],
  ['302037', '302', '37', 'Canada', 'MICROCELL'],
  ['302037', '302', '37', 'Canada', 'MICROCELL'],
  ['302037', '302', '37', 'Canada', 'MICROCELL'],
  ['302065', '302', '65', 'Canada', 'Unknown-CANADA-6472'],
  ['302072', '302', '72', 'Canada', 'ROGERS WIRELESS'],
  ['302072', '302', '72', 'Canada', 'ROGERS WIRELESS'],
  ['302072', '302', '72', 'Canada', 'ROGERS WIRELESS'],
  ['302072', '302', '72', 'Canada', 'ROGERS WIRELESS'],
  ['302072', '302', '72', 'Canada', 'ROGERS WIRELESS'],
  ['302072', '302', '72', 'Canada', 'ROGERS WIRELESS'],
  ['302072', '302', '72', 'Canada', 'ROGERS WIRELESS'],
  ['302072', '302', '72', 'Canada', 'ROGERS WIRELESS'],
  ['302072', '302', '72', 'Canada', 'ROGERS WIRELESS'],
  ['302072', '302', '72', 'Canada', 'ROGERS WIRELESS'],
  ['302072', '302', '72', 'Canada', 'ROGERS WIRELESS'],
  ['302072', '302', '72', 'Canada', 'ROGERS WIRELESS'],
  ['302072', '302', '72', 'Canada', 'ROGERS WIRELESS'],
  ['302072', '302', '72', 'Canada', 'ROGERS WIRELESS'],
  ['302072', '302', '72', 'Canada', 'ROGERS WIRELESS'],
  ['302072', '302', '72', 'Canada', 'ROGERS WIRELESS'],
  ['302072', '302', '72', 'Canada', 'ROGERS WIRELESS'],
  ['302072', '302', '72', 'Canada', 'ROGERS WIRELESS'],
  ['302072', '302', '72', 'Canada', 'ROGERS WIRELESS'],
  ['302072', '302', '72', 'Canada', 'ROGERS WIRELESS'],
  ['302072', '302', '72', 'Canada', 'ROGERS WIRELESS'],
  ['302072', '302', '72', 'Canada', 'ROGERS WIRELESS'],
  ['302370', '302', '370', 'Canada', 'MICROCELL'],
  ['302490', '302', '490', 'Canada', 'Unknown-CANADA-6477'],
  ['6250', '625', '0', 'Cape Verde Island', 'DEFAULT CAPE VERDE'],
  ['62501', '625', '1', 'Cape Verde Island', 'CV MOVEL'],
  ['62501', '625', '1', 'Cape Verde Island', 'CV MOVEL'],
  ['62501', '625', '1', 'Cape Verde Island', 'CV MOVEL'],
  ['62501', '625', '1', 'Cape Verde Island', 'CV MOVEL'],
  ['310002', '310', '2', 'Cayman Islands', 'CINGULAR WIRELESS'],
  ['310002', '310', '2', 'Cayman Islands', 'CABLE & WIRELESS'],
  ['310002', '310', '2', 'Cayman Islands', 'MOSSEL DIGICEL'],
  ['310002', '310', '2', 'Cayman Islands', 'CINGULAR WIRELESS'],
  ['6230', '623', '0', 'Central Africa', 'DEFAULT CENTRAL AFRICA REP.'],
  ['62310', '623', '10', 'Central Africa', 'TELECEL'],
  [
    '62301',
    '623',
    '1',
    'Central African Republic',
    'Moov - Atlantique Cellulaire RCA (A-Cell - Etisalat)'
  ],
  ['62302', '623', '2', 'Central African Republic', 'Telecel'],
  ['62303', '623', '3', 'Central African Republic', 'Orange'],
  ['62304', '623', '4', 'Central African Republic', 'Nationlink Telecom RCA'],
  ['6220', '622', '0', 'Chad', 'DEFAULT CHAD'],
  ['62201', '622', '1', 'Chad', 'CELLTEL'],
  ['62201', '622', '1', 'Chad', 'CELLTEL'],
  ['62201', '622', '1', 'Chad', 'CELLTEL'],
  ['62202', '622', '2', 'Chad', 'TCHAD MOBILE'],
  ['62203', '622', '3', 'Chad', 'Tchad Mobile - MILLICOM'],
  ['7300', '730', '0', 'Chile', 'DEFAULT CHILE'],
  ['73010', '730', '10', 'Chile', 'ENTEL'],
  ['73010', '730', '10', 'Chile', 'ENTEL'],
  ['73010', '730', '10', 'Chile', 'ENTEL'],
  ['73010', '730', '10', 'Chile', 'ENTEL'],
  ['73010', '730', '10', 'Chile', 'ENTEL'],
  ['73010', '730', '10', 'Chile', 'ENTEL'],
  ['73010', '730', '10', 'Chile', 'ENTEL'],
  ['73010', '730', '10', 'Chile', 'ENTEL'],
  ['73010', '730', '10', 'Chile', 'ENTEL'],
  ['73010', '730', '10', 'Chile', 'ENTEL'],
  ['73010', '730', '10', 'Chile', 'ENTEL'],
  ['73010', '730', '10', 'Chile', 'ENTEL'],
  ['73010', '730', '10', 'Chile', 'ENTEL'],
  ['73010', '730', '10', 'Chile', 'ENTEL'],
  ['73010', '730', '10', 'Chile', 'ENTEL'],
  ['73010', '730', '10', 'Chile', 'ENTEL'],
  ['73010', '730', '10', 'Chile', 'ENTEL'],
  ['73010', '730', '10', 'Chile', 'ENTEL'],
  ['73010', '730', '10', 'Chile', 'ENTEL'],
  ['73010', '730', '10', 'Chile', 'ENTEL'],
  ['73010', '730', '10', 'Chile', 'ENTEL'],
  ['73010', '730', '10', 'Chile', 'ENTEL'],
  ['73010', '730', '10', 'Chile', 'ENTEL'],
  ['73010', '730', '10', 'Chile', 'ENTEL'],
  ['73010', '730', '10', 'Chile', 'ENTEL'],
  ['73010', '730', '10', 'Chile', 'ENTEL'],
  ['73010', '730', '10', 'Chile', 'ENTEL'],
  ['73010', '730', '10', 'Chile', 'ENTEL'],
  ['73010', '730', '10', 'Chile', 'ENTEL'],
  ['73010', '730', '10', 'Chile', 'ENTEL'],
  ['73010', '730', '10', 'Chile', 'ENTEL'],
  ['73010', '730', '10', 'Chile', 'ENTEL'],
  ['73010', '730', '10', 'Chile', 'ENTEL'],
  ['73010', '730', '10', 'Chile', 'ENTEL'],
  ['73010', '730', '10', 'Chile', 'ENTEL'],
  ['73010', '730', '10', 'Chile', 'ENTEL'],
  ['73010', '730', '10', 'Chile', 'ENTEL'],
  ['73010', '730', '10', 'Chile', 'ENTEL'],
  ['73010', '730', '10', 'Chile', 'ENTEL'],
  ['73010', '730', '10', 'Chile', 'ENTEL'],
  ['73010', '730', '10', 'Chile', 'ENTEL'],
  ['73010', '730', '10', 'Chile', 'ENTEL'],
  ['73010', '730', '10', 'Chile', 'ENTEL'],
  ['73010', '730', '10', 'Chile', 'ENTEL'],
  ['73010', '730', '10', 'Chile', 'ENTEL'],
  ['73010', '730', '10', 'Chile', 'ENTEL'],
  ['73010', '730', '10', 'Chile', 'ENTEL'],
  ['73010', '730', '10', 'Chile', 'ENTEL'],
  ['73010', '730', '10', 'Chile', 'ENTEL'],
  ['73010', '730', '10', 'Chile', 'ENTEL'],
  ['73010', '730', '10', 'Chile', 'ENTEL'],
  ['73010', '730', '10', 'Chile', 'ENTEL'],
  ['73010', '730', '10', 'Chile', 'ENTEL'],
  ['73010', '730', '10', 'Chile', 'ENTEL'],
  ['73010', '730', '10', 'Chile', 'ENTEL'],
  ['73010', '730', '10', 'Chile', 'ENTEL'],
  ['73010', '730', '10', 'Chile', 'ENTEL'],
  ['73010', '730', '10', 'Chile', 'ENTEL'],
  ['73010', '730', '10', 'Chile', 'ENTEL'],
  ['73010', '730', '10', 'Chile', 'ENTEL'],
  ['73010', '730', '10', 'Chile', 'ENTEL'],
  ['73010', '730', '10', 'Chile', 'ENTEL'],
  ['73010', '730', '10', 'Chile', 'ENTEL'],
  ['73010', '730', '10', 'Chile', 'ENTEL'],
  ['73010', '730', '10', 'Chile', 'ENTEL'],
  ['73010', '730', '10', 'Chile', 'ENTEL'],
  ['73010', '730', '10', 'Chile', 'ENTEL'],
  ['73010', '730', '10', 'Chile', 'ENTEL'],
  ['73010', '730', '10', 'Chile', 'ENTEL'],
  ['73010', '730', '10', 'Chile', 'ENTEL'],
  ['73010', '730', '10', 'Chile', 'ENTEL'],
  ['73010', '730', '10', 'Chile', 'ENTEL'],
  ['73010', '730', '10', 'Chile', 'ENTEL'],
  ['73010', '730', '10', 'Chile', 'ENTEL'],
  ['73010', '730', '10', 'Chile', 'ENTEL'],
  ['73010', '730', '10', 'Chile', 'ENTEL'],
  ['73010', '730', '10', 'Chile', 'ENTEL'],
  ['73010', '730', '10', 'Chile', 'ENTEL'],
  ['73010', '730', '10', 'Chile', 'ENTEL'],
  ['73010', '730', '10', 'Chile', 'ENTEL'],
  ['73010', '730', '10', 'Chile', 'ENTEL'],
  ['73010', '730', '10', 'Chile', 'ENTEL'],
  ['73010', '730', '10', 'Chile', 'ENTEL'],
  ['73010', '730', '10', 'Chile', 'ENTEL'],
  ['73010', '730', '10', 'Chile', 'ENTEL'],
  ['73010', '730', '10', 'Chile', 'ENTEL'],
  ['73010', '730', '10', 'Chile', 'ENTEL'],
  ['73010', '730', '10', 'Chile', 'ENTEL'],
  ['46002', '460', '2', 'China', 'China Mobile'],
  ['46002', '460', '2', 'China', 'China Mobile'],
  ['46002', '460', '2', 'China', 'China Mobile'],
  ['46002', '460', '2', 'China', 'China Mobile'],
  ['46002', '460', '2', 'China', 'China Mobile'],
  ['46002', '460', '2', 'China', 'China Mobile'],
  ['46002', '460', '2', 'China', 'China Mobile'],
  ['46002', '460', '2', 'China', 'China Mobile'],
  ['46002', '460', '2', 'China', 'China Mobile'],
  ['46002', '460', '2', 'China', 'China Mobile'],
  ['46002', '460', '2', 'China', 'China Mobile'],
  ['46002', '460', '2', 'China', 'China Mobile'],
  ['46002', '460', '2', 'China', 'China Mobile'],
  ['46002', '460', '2', 'China', 'China Mobile'],
  ['46002', '460', '2', 'China', 'China Mobile'],
  ['46002', '460', '2', 'China', 'China Mobile'],
  ['46002', '460', '2', 'China', 'China Mobile'],
  ['46002', '460', '2', 'China', 'China Mobile'],
  ['46002', '460', '2', 'China', 'China Mobile'],
  ['46002', '460', '2', 'China', 'China Mobile'],
  ['46002', '460', '2', 'China', 'China Mobile'],
  ['46002', '460', '2', 'China', 'China Mobile'],
  ['46002', '460', '2', 'China', 'China Mobile'],
  ['46003', '460', '3', 'China', 'China Telecom'],
  ['46003', '460', '3', 'China', 'China Telecom'],
  ['46003', '460', '3', 'China', 'China Telecom'],
  ['46003', '460', '3', 'China', 'China Telecom'],
  ['46003', '460', '3', 'China', 'China Telecom'],
  ['46003', '460', '3', 'China', 'China Telecom'],
  ['46003', '460', '3', 'China', 'China Telecom'],
  ['46003', '460', '3', 'China', 'China Telecom'],
  ['46003', '460', '3', 'China', 'China Telecom'],
  ['46003', '460', '3', 'China', 'China Telecom'],
  ['46003', '460', '3', 'China', 'China Telecom'],
  ['46003', '460', '3', 'China', 'China Telecom'],
  ['46003', '460', '3', 'China', 'China Telecom'],
  ['46003', '460', '3', 'China', 'China Telecom'],
  ['46003', '460', '3', 'China', 'China Telecom'],
  ['46005', '460', '5', 'China', 'China Telecom'],
  ['46005', '460', '5', 'China', 'China Telecom'],
  ['46005', '460', '5', 'China', 'China Telecom'],
  ['46005', '460', '5', 'China', 'China Telecom'],
  ['46005', '460', '5', 'China', 'China Telecom'],
  ['46005', '460', '5', 'China', 'China Telecom'],
  ['46005', '460', '5', 'China', 'China Telecom'],
  ['46005', '460', '5', 'China', 'China Telecom'],
  ['46005', '460', '5', 'China', 'China Telecom'],
  ['46005', '460', '5', 'China', 'China Telecom'],
  ['46005', '460', '5', 'China', 'China Telecom'],
  ['46005', '460', '5', 'China', 'China Telecom'],
  ['46005', '460', '5', 'China', 'China Telecom'],
  ['46005', '460', '5', 'China', 'China Telecom'],
  ['46005', '460', '5', 'China', 'China Telecom'],
  ['46006', '460', '6', 'China', 'China Unicom'],
  ['46006', '460', '6', 'China', 'China Unicom'],
  ['46006', '460', '6', 'China', 'China Unicom'],
  ['46006', '460', '6', 'China', 'China Unicom'],
  ['46006', '460', '6', 'China', 'China Unicom'],
  ['46006', '460', '6', 'China', 'China Unicom'],
  ['46006', '460', '6', 'China', 'China Unicom'],
  ['46006', '460', '6', 'China', 'China Unicom'],
  ['46006', '460', '6', 'China', 'China Unicom'],
  ['46006', '460', '6', 'China', 'China Unicom'],
  ['46006', '460', '6', 'China', 'China Unicom'],
  ['46006', '460', '6', 'China', 'China Unicom'],
  ['46006', '460', '6', 'China', 'China Unicom'],
  ['46006', '460', '6', 'China', 'China Unicom'],
  ['46006', '460', '6', 'China', 'China Unicom'],
  ['46007', '460', '7', 'China', 'China Mobile'],
  ['46007', '460', '7', 'China', 'China Mobile'],
  ['46007', '460', '7', 'China', 'China Mobile'],
  ['46007', '460', '7', 'China', 'China Mobile'],
  ['46007', '460', '7', 'China', 'China Mobile'],
  ['46007', '460', '7', 'China', 'China Mobile'],
  ['46007', '460', '7', 'China', 'China Mobile'],
  ['46007', '460', '7', 'China', 'China Mobile'],
  ['46007', '460', '7', 'China', 'China Mobile'],
  ['46007', '460', '7', 'China', 'China Mobile'],
  ['46007', '460', '7', 'China', 'China Mobile'],
  ['46007', '460', '7', 'China', 'China Mobile'],
  ['46007', '460', '7', 'China', 'China Mobile'],
  ['46007', '460', '7', 'China', 'China Mobile'],
  ['46007', '460', '7', 'China', 'China Mobile'],
  ['46007', '460', '7', 'China', 'China Mobile'],
  ['46007', '460', '7', 'China', 'China Mobile'],
  ['46007', '460', '7', 'China', 'China Mobile'],
  ['46007', '460', '7', 'China', 'China Mobile'],
  ['46007', '460', '7', 'China', 'China Mobile'],
  ['46007', '460', '7', 'China', 'China Mobile'],
  ['46007', '460', '7', 'China', 'China Mobile'],
  ['46007', '460', '7', 'China', 'China Mobile'],
  ['46011', '460', '11', 'China', 'China Telecom'],
  ['46011', '460', '11', 'China', 'China Telecom'],
  ['46011', '460', '11', 'China', 'China Telecom'],
  ['46011', '460', '11', 'China', 'China Telecom'],
  ['46011', '460', '11', 'China', 'China Telecom'],
  ['46011', '460', '11', 'China', 'China Telecom'],
  ['46011', '460', '11', 'China', 'China Telecom'],
  ['46011', '460', '11', 'China', 'China Telecom'],
  ['46011', '460', '11', 'China', 'China Telecom'],
  ['46011', '460', '11', 'China', 'China Telecom'],
  ['46011', '460', '11', 'China', 'China Telecom'],
  ['46011', '460', '11', 'China', 'China Telecom'],
  ['46011', '460', '11', 'China', 'China Telecom'],
  ['46011', '460', '11', 'China', 'China Telecom'],
  ['46011', '460', '11', 'China', 'China Telecom'],
  ['4600', '460', '0', 'China Peoples Republic', 'DEFAULT CHINA'],
  ['46000', '460', '0', 'China Peoples Republic', 'CHINA MOBILE'],
  ['46000', '460', '0', 'China Peoples Republic', 'CHINA MOBILE'],
  ['46000', '460', '0', 'China Peoples Republic', 'CHINA MOBILE'],
  ['46000', '460', '0', 'China Peoples Republic', 'CHINA MOBILE'],
  ['46000', '460', '0', 'China Peoples Republic', 'CHINA MOBILE'],
  ['46001', '460', '1', 'China Peoples Republic', 'CHINA UNICOM'],
  ['46001', '460', '1', 'China Peoples Republic', 'CHINA UNICOM'],
  ['46001', '460', '1', 'China Peoples Republic', 'CHINA UNICOM'],
  ['46001', '460', '1', 'China Peoples Republic', 'CHINA UNICOM'],
  ['7320', '732', '0', 'Colombia', 'DEFAULT COLOMBIA'],
  ['732101', '732', '101', 'Colombia', 'CLARO'],
  ['732101', '732', '101', 'Colombia', 'CLARO'],
  ['732101', '732', '101', 'Colombia', 'CLARO'],
  ['732101', '732', '101', 'Colombia', 'CLARO'],
  ['732102', '732', '102', 'Colombia', 'MOVISTAR'],
  ['732103', '732', '103', 'Colombia', 'TIGO'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732111', '732', '111', 'Colombia', 'Tigo'],
  ['732123', '732', '123', 'Colombia', 'MOVISTAR'],
  ['732130', '732', '130', 'Colombia', 'Avantel'],
  ['732130', '732', '130', 'Colombia', 'Avantel'],
  ['732130', '732', '130', 'Colombia', 'Avantel'],
  ['732130', '732', '130', 'Colombia', 'Avantel'],
  ['732130', '732', '130', 'Colombia', 'Avantel'],
  ['732130', '732', '130', 'Colombia', 'Avantel'],
  ['732130', '732', '130', 'Colombia', 'Avantel'],
  ['732130', '732', '130', 'Colombia', 'Avantel'],
  ['732154', '732', '154', 'Colombia', 'Movistar'],
  ['732154', '732', '154', 'Colombia', 'Movistar'],
  ['732154', '732', '154', 'Colombia', 'Movistar'],
  ['732154', '732', '154', 'Colombia', 'Movistar'],
  ['732154', '732', '154', 'Colombia', 'Movistar'],
  ['732154', '732', '154', 'Colombia', 'Movistar'],
  ['732154', '732', '154', 'Colombia', 'Movistar'],
  ['732154', '732', '154', 'Colombia', 'Movistar'],
  ['732154', '732', '154', 'Colombia', 'Movistar'],
  ['732154', '732', '154', 'Colombia', 'Movistar'],
  ['732154', '732', '154', 'Colombia', 'Movistar'],
  ['732154', '732', '154', 'Colombia', 'Movistar'],
  ['732154', '732', '154', 'Colombia', 'Movistar'],
  ['732154', '732', '154', 'Colombia', 'Movistar'],
  ['732154', '732', '154', 'Colombia', 'Movistar'],
  ['732154', '732', '154', 'Colombia', 'Movistar'],
  ['732154', '732', '154', 'Colombia', 'Movistar'],
  ['732154', '732', '154', 'Colombia', 'Movistar'],
  ['732154', '732', '154', 'Colombia', 'Movistar'],
  ['732154', '732', '154', 'Colombia', 'Movistar'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['732165', '732', '165', 'Colombia', 'Tigo'],
  ['6540', '654', '0', 'Comoros Islands', 'DEFAULT COMOROS & MAYOTTE'],
  ['65401', '654', '1', 'Comoros Islands', 'SNPT'],
  ['6290', '629', '0', 'Congo', 'DEFAULT CONGO'],
  ['62901', '629', '1', 'Congo', 'CELTEL'],
  ['62907', '629', '7', 'Congo', 'WARID'],
  ['62910', '629', '10', 'Congo', 'LIBERTIS'],
  ['62910', '629', '10', 'Congo', 'LIBERTIS'],
  ['62920', '629', '20', 'Congo', 'CYRUS S.A.'],
  ['63090', '630', '90', 'Congo Democratic Republic', 'Africell'],
  ['63090', '630', '90', 'Congo Democratic Republic', 'Africell'],
  ['7120', '712', '0', 'Costa Rica', 'DEFAULT Costa Rica'],
  [
    '71201',
    '712',
    '1',
    'Costa Rica',
    'Instituto Costarricense de Electricidad (ICE)'
  ],
  [
    '71201',
    '712',
    '1',
    'Costa Rica',
    'Instituto Costarricense de Electricidad (ICE)'
  ],
  [
    '71201',
    '712',
    '1',
    'Costa Rica',
    'Instituto Costarricense de Electricidad (ICE)'
  ],
  [
    '71201',
    '712',
    '1',
    'Costa Rica',
    'Instituto Costarricense de Electricidad (ICE)'
  ],
  [
    '71201',
    '712',
    '1',
    'Costa Rica',
    'Instituto Costarricense de Electricidad (ICE)'
  ],
  [
    '71201',
    '712',
    '1',
    'Costa Rica',
    'Instituto Costarricense de Electricidad (ICE)'
  ],
  ['71203', '712', '3', 'Costa Rica', 'Claro CR Telecominicaciones'],
  ['71203', '712', '3', 'Costa Rica', 'Claro CR Telecominicaciones'],
  ['71203', '712', '3', 'Costa Rica', 'Claro CR Telecominicaciones'],
  ['71203', '712', '3', 'Costa Rica', 'Claro CR Telecominicaciones'],
  ['71203', '712', '3', 'Costa Rica', 'Claro CR Telecominicaciones'],
  ['71203', '712', '3', 'Costa Rica', 'Claro CR Telecominicaciones'],
  ['71203', '712', '3', 'Costa Rica', 'Claro CR Telecominicaciones'],
  ['71204', '712', '4', 'Costa Rica', 'Telefonica Moviles Costa Rica'],
  ['71204', '712', '4', 'Costa Rica', 'Telefonica Moviles Costa Rica'],
  ['2190', '219', '0', 'Croatia', 'DEFAULT CROATIA'],
  ['21901', '219', '1', 'Croatia', 'T-MOBILE'],
  ['21901', '219', '1', 'Croatia', 'T-MOBILE'],
  ['21902', '219', '2', 'Croatia', 'Tele2'],
  ['21910', '219', '10', 'Croatia', 'TELE 2'],
  ['21910', '219', '10', 'Croatia', 'VIP-NET'],
  ['3680', '368', '0', 'Cuba', 'DEFAULT CUBA'],
  ['36801', '368', '1', 'Cuba', 'C-COM'],
  ['36801', '368', '1', 'Cuba', 'C-COM'],
  ['2800', '280', '0', 'Cyprus', 'DEFAULT CYPRUS'],
  ['28001', '280', '1', 'Cyprus', 'VODAFONE'],
  ['28001', '280', '1', 'Cyprus', 'VODAFONE'],
  ['28010', '280', '10', 'Cyprus', 'AREEBA'],
  ['28020', '280', '20', 'Cyprus', 'PRIMETEL'],
  ['2300', '230', '0', 'Czech Republic', 'DEFAULT CZEK REPUBLIC'],
  ['23001', '230', '1', 'Czech Republic', 'T-MOBILE'],
  ['23001', '230', '1', 'Czech Republic', 'T-MOBILE'],
  ['23001', '230', '1', 'Czech Republic', 'T-MOBILE'],
  ['23001', '230', '1', 'Czech Republic', 'T-MOBILE'],
  ['23001', '230', '1', 'Czech Republic', 'T-MOBILE'],
  ['23001', '230', '1', 'Czech Republic', 'T-MOBILE'],
  ['23001', '230', '1', 'Czech Republic', 'T-MOBILE'],
  ['23001', '230', '1', 'Czech Republic', 'T-MOBILE'],
  ['23001', '230', '1', 'Czech Republic', 'T-MOBILE'],
  ['23001', '230', '1', 'Czech Republic', 'T-MOBILE'],
  ['23001', '230', '1', 'Czech Republic', 'T-MOBILE'],
  ['23001', '230', '1', 'Czech Republic', 'T-MOBILE'],
  ['23001', '230', '1', 'Czech Republic', 'T-MOBILE'],
  ['23001', '230', '1', 'Czech Republic', 'T-MOBILE'],
  ['23001', '230', '1', 'Czech Republic', 'T-MOBILE'],
  ['23001', '230', '1', 'Czech Republic', 'T-MOBILE'],
  ['23002', '230', '2', 'Czech Republic', 'EUROTEL'],
  ['23002', '230', '2', 'Czech Republic', 'EUROTEL'],
  ['23002', '230', '2', 'Czech Republic', 'EUROTEL'],
  ['23002', '230', '2', 'Czech Republic', 'EUROTEL'],
  ['23002', '230', '2', 'Czech Republic', 'EUROTEL'],
  ['23002', '230', '2', 'Czech Republic', 'EUROTEL'],
  ['23002', '230', '2', 'Czech Republic', 'EUROTEL'],
  ['23002', '230', '2', 'Czech Republic', 'EUROTEL'],
  ['23002', '230', '2', 'Czech Republic', 'EUROTEL'],
  ['23002', '230', '2', 'Czech Republic', 'EUROTEL'],
  ['23002', '230', '2', 'Czech Republic', 'EUROTEL'],
  ['23002', '230', '2', 'Czech Republic', 'EUROTEL'],
  ['23002', '230', '2', 'Czech Republic', 'EUROTEL'],
  ['23002', '230', '2', 'Czech Republic', 'EUROTEL'],
  ['23003', '230', '3', 'Czech Republic', 'VODAFONE'],
  ['23003', '230', '3', 'Czech Republic', 'VODAFONE'],
  ['23003', '230', '3', 'Czech Republic', 'VODAFONE'],
  ['23003', '230', '3', 'Czech Republic', 'VODAFONE'],
  ['23003', '230', '3', 'Czech Republic', 'VODAFONE'],
  ['23003', '230', '3', 'Czech Republic', 'VODAFONE'],
  ['23003', '230', '3', 'Czech Republic', 'VODAFONE'],
  ['23003', '230', '3', 'Czech Republic', 'VODAFONE'],
  ['23003', '230', '3', 'Czech Republic', 'VODAFONE'],
  ['23003', '230', '3', 'Czech Republic', 'VODAFONE'],
  ['23003', '230', '3', 'Czech Republic', 'VODAFONE'],
  ['23004', '230', '4', 'Czech Republic', 'Ufon (Air Telecom)'],
  [
    '6300',
    '630',
    '0',
    'Democratic Republic of the Congo',
    'DEFAULT CONGO DEM. REP. OF'
  ],
  ['63001', '630', '1', 'Democratic Republic of the Congo', 'VODACOM CONGO'],
  ['63001', '630', '1', 'Democratic Republic of the Congo', 'VODACOM CONGO'],
  ['63002', '630', '2', 'Democratic Republic of the Congo', 'Airtel DRC'],
  ['63002', '630', '2', 'Democratic Republic of the Congo', 'Airtel DRC'],
  ['63002', '630', '2', 'Democratic Republic of the Congo', 'Airtel DRC'],
  ['63002', '630', '2', 'Democratic Republic of the Congo', 'Airtel DRC'],
  ['63002', '630', '2', 'Democratic Republic of the Congo', 'Airtel DRC'],
  ['63005', '630', '5', 'Democratic Republic of the Congo', 'Congo Supercell'],
  ['63086', '630', '86', 'Democratic Republic of the Congo', 'Orange RDC'],
  ['63086', '630', '86', 'Democratic Republic of the Congo', 'Orange RDC'],
  ['63089', '630', '89', 'Democratic Republic of the Congo', 'Tigo DRC'],
  ['2380', '238', '0', 'Denmark', 'DEFAULT DENMARK'],
  ['23801', '238', '1', 'Denmark', 'TDC MOBIL'],
  ['23801', '238', '1', 'Denmark', 'CBB MOBIL'],
  ['23801', '238', '1', 'Denmark', 'TDC MOBIL'],
  ['23801', '238', '1', 'Denmark', 'TDC MOBIL'],
  ['23801', '238', '1', 'Denmark', 'TDC MOBIL'],
  ['23801', '238', '1', 'Denmark', 'TDC MOBIL'],
  ['23801', '238', '1', 'Denmark', 'TDC MOBIL'],
  ['23801', '238', '1', 'Denmark', 'TDC MOBIL'],
  ['23801', '238', '1', 'Denmark', 'TDC MOBIL'],
  ['23801', '238', '1', 'Denmark', 'TDC MOBIL'],
  ['23801', '238', '1', 'Denmark', 'TDC MOBIL'],
  ['23801', '238', '1', 'Denmark', 'TDC MOBIL'],
  ['23801', '238', '1', 'Denmark', 'TDC MOBIL'],
  ['23801', '238', '1', 'Denmark', 'TDC MOBIL'],
  ['23801', '238', '1', 'Denmark', 'TDC MOBIL'],
  ['23801', '238', '1', 'Denmark', 'TDC MOBIL'],
  ['23801', '238', '1', 'Denmark', 'TDC MOBIL'],
  ['23801', '238', '1', 'Denmark', 'TDC MOBIL'],
  ['23801', '238', '1', 'Denmark', 'TDC MOBIL'],
  ['23801', '238', '1', 'Denmark', 'TDC MOBIL'],
  ['23801', '238', '1', 'Denmark', 'CBB MOBIL'],
  ['23801', '238', '1', 'Denmark', 'TDC MOBIL'],
  ['23801', '238', '1', 'Denmark', 'TDC MOBIL'],
  ['23801', '238', '1', 'Denmark', 'TDC MOBIL'],
  ['23801', '238', '1', 'Denmark', 'TDC MOBIL'],
  ['23801', '238', '1', 'Denmark', 'TELE 1 EUROPE'],
  ['23801', '238', '1', 'Denmark', 'TDC MOBIL'],
  ['23801', '238', '1', 'Denmark', 'TDC MOBIL'],
  ['23801', '238', '1', 'Denmark', 'TDC MOBIL'],
  ['23801', '238', '1', 'Denmark', 'CBB MOBIL'],
  ['23801', '238', '1', 'Denmark', 'TDC MOBIL'],
  ['23801', '238', '1', 'Denmark', 'TDC MOBIL'],
  ['23801', '238', '1', 'Denmark', 'TDC MOBIL'],
  ['23801', '238', '1', 'Denmark', 'TDC MOBIL'],
  ['23801', '238', '1', 'Denmark', 'TDC MOBIL'],
  ['23801', '238', '1', 'Denmark', 'TDC MOBIL'],
  ['23801', '238', '1', 'Denmark', 'TDC MOBIL'],
  ['23801', '238', '1', 'Denmark', 'TDC MOBIL'],
  ['23801', '238', '1', 'Denmark', 'TDC MOBIL'],
  ['23801', '238', '1', 'Denmark', 'TDC MOBIL'],
  ['23801', '238', '1', 'Denmark', 'TELE 2'],
  ['23801', '238', '1', 'Denmark', 'TELE 2'],
  ['23801', '238', '1', 'Denmark', 'TELE 2'],
  ['23801', '238', '1', 'Denmark', 'TELE 2'],
  ['23801', '238', '1', 'Denmark', 'TELE PASSPORT'],
  ['23801', '238', '1', 'Denmark', 'TDC MOBIL'],
  ['23801', '238', '1', 'Denmark', 'TDC MOBIL'],
  ['23801', '238', '1', 'Denmark', 'TDC MOBIL'],
  ['23801', '238', '1', 'Denmark', 'TDC MOBIL'],
  ['23801', '238', '1', 'Denmark', 'TDC MOBIL'],
  ['23801', '238', '1', 'Denmark', 'TDC MOBIL'],
  ['23801', '238', '1', 'Denmark', 'TDC MOBIL'],
  ['23801', '238', '1', 'Denmark', 'TDC MOBIL'],
  ['23801', '238', '1', 'Denmark', 'TDC MOBIL'],
  ['23801', '238', '1', 'Denmark', 'TDC MOBIL'],
  ['23801', '238', '1', 'Denmark', 'TDC MOBIL'],
  ['23801', '238', '1', 'Denmark', 'CBB MOBIL'],
  ['23801', '238', '1', 'Denmark', 'TDC MOBIL'],
  ['23801', '238', '1', 'Denmark', 'TDC MOBIL'],
  ['23801', '238', '1', 'Denmark', 'TDC MOBIL'],
  ['23801', '238', '1', 'Denmark', 'TDC MOBIL'],
  ['23801', '238', '1', 'Denmark', 'TDC MOBIL'],
  ['23801', '238', '1', 'Denmark', 'TDC MOBIL'],
  ['23801', '238', '1', 'Denmark', 'TDC MOBIL'],
  ['23801', '238', '1', 'Denmark', 'TDC MOBIL'],
  ['23801', '238', '1', 'Denmark', 'TDC MOBIL'],
  ['23801', '238', '1', 'Denmark', 'TDC MOBIL'],
  ['23801', '238', '1', 'Denmark', 'TDC MOBIL'],
  ['23801', '238', '1', 'Denmark', 'TDC MOBIL'],
  ['23801', '238', '1', 'Denmark', 'TDC MOBIL'],
  ['23801', '238', '1', 'Denmark', 'TDC MOBIL'],
  ['23801', '238', '1', 'Denmark', 'TDC MOBIL'],
  ['23801', '238', '1', 'Denmark', 'TDC MOBIL'],
  ['23801', '238', '1', 'Denmark', 'TDC MOBIL'],
  ['23801', '238', '1', 'Denmark', 'TDC MOBIL'],
  ['23801', '238', '1', 'Denmark', 'TDC MOBIL'],
  ['23801', '238', '1', 'Denmark', 'TDC MOBIL'],
  ['23801', '238', '1', 'Denmark', 'TDC MOBIL'],
  ['23801', '238', '1', 'Denmark', 'TDC MOBIL'],
  ['23801', '238', '1', 'Denmark', 'TDC MOBIL'],
  ['23801', '238', '1', 'Denmark', 'TDC MOBIL'],
  ['23801', '238', '1', 'Denmark', 'TDC MOBIL'],
  ['23801', '238', '1', 'Denmark', 'TDC MOBIL'],
  ['23801', '238', '1', 'Denmark', 'TDC MOBIL'],
  ['23801', '238', '1', 'Denmark', 'TDC MOBIL'],
  ['23801', '238', '1', 'Denmark', 'TDC MOBIL'],
  ['23801', '238', '1', 'Denmark', 'T-CELL'],
  ['23801', '238', '1', 'Denmark', 'TDC MOBIL'],
  ['23801', '238', '1', 'Denmark', 'TDC MOBIL'],
  ['23801', '238', '1', 'Denmark', 'TDC MOBIL'],
  ['23801', '238', '1', 'Denmark', 'TDC MOBIL'],
  ['23801', '238', '1', 'Denmark', 'TDC MOBIL'],
  ['23801', '238', '1', 'Denmark', 'TDC MOBIL'],
  ['23801', '238', '1', 'Denmark', 'TDC MOBIL'],
  ['23801', '238', '1', 'Denmark', 'TDC MOBIL'],
  ['23802', '238', '2', 'Denmark', 'SONOFON'],
  ['23802', '238', '2', 'Denmark', 'SONOFON'],
  ['23802', '238', '2', 'Denmark', 'SONOFON'],
  ['23802', '238', '2', 'Denmark', 'SONOFON'],
  ['23802', '238', '2', 'Denmark', 'SONOFON'],
  ['23802', '238', '2', 'Denmark', 'SONOFON'],
  ['23802', '238', '2', 'Denmark', 'SONOFON'],
  ['23802', '238', '2', 'Denmark', 'SONOFON'],
  ['23802', '238', '2', 'Denmark', 'SONOFON'],
  ['23802', '238', '2', 'Denmark', 'SONOFON'],
  ['23802', '238', '2', 'Denmark', 'SONOFON'],
  ['23802', '238', '2', 'Denmark', 'SONOFON'],
  ['23802', '238', '2', 'Denmark', 'SONOFON'],
  ['23802', '238', '2', 'Denmark', 'SONOFON'],
  ['23802', '238', '2', 'Denmark', 'SONOFON'],
  ['23802', '238', '2', 'Denmark', 'SONOFON'],
  ['23802', '238', '2', 'Denmark', 'SONOFON'],
  ['23802', '238', '2', 'Denmark', 'SONOFON'],
  ['23802', '238', '2', 'Denmark', 'SONOFON'],
  ['23802', '238', '2', 'Denmark', 'SONOFON'],
  ['23802', '238', '2', 'Denmark', 'SONOFON'],
  ['23802', '238', '2', 'Denmark', 'SONOFON'],
  ['23802', '238', '2', 'Denmark', 'MIGWAY'],
  ['23802', '238', '2', 'Denmark', 'SONOFON'],
  ['23802', '238', '2', 'Denmark', 'SONOFON'],
  ['23802', '238', '2', 'Denmark', 'SONOFON'],
  ['23802', '238', '2', 'Denmark', 'SONOFON'],
  ['23802', '238', '2', 'Denmark', 'SONOFON'],
  ['23802', '238', '2', 'Denmark', 'SONOFON'],
  ['23802', '238', '2', 'Denmark', 'SONOFON'],
  ['23802', '238', '2', 'Denmark', 'SONOFON'],
  ['23802', '238', '2', 'Denmark', 'SONOFON'],
  ['23802', '238', '2', 'Denmark', 'SONOFON'],
  ['23806', '238', '6', 'Denmark', 'HI3G'],
  ['23820', '238', '20', 'Denmark', 'TELIA'],
  ['23820', '238', '20', 'Denmark', 'TELIA'],
  ['23820', '238', '20', 'Denmark', 'TELIA'],
  ['23820', '238', '20', 'Denmark', 'TELIA'],
  ['23820', '238', '20', 'Denmark', 'TELIA'],
  ['23820', '238', '20', 'Denmark', 'TELIA'],
  ['23820', '238', '20', 'Denmark', 'TELIA'],
  ['23820', '238', '20', 'Denmark', 'TELIA'],
  ['23820', '238', '20', 'Denmark', 'TELIA'],
  ['23820', '238', '20', 'Denmark', 'TELIA'],
  ['23820', '238', '20', 'Denmark', 'TELIA'],
  ['23820', '238', '20', 'Denmark', 'TELIA'],
  ['23820', '238', '20', 'Denmark', 'TELIA'],
  ['23820', '238', '20', 'Denmark', 'SENSE COMMUNICATION'],
  ['23820', '238', '20', 'Denmark', 'TELIA'],
  ['23820', '238', '20', 'Denmark', 'TELIA'],
  ['23830', '238', '30', 'Denmark', 'ORANGE'],
  ['23830', '238', '30', 'Denmark', 'ORANGE'],
  ['23830', '238', '30', 'Denmark', 'ORANGE'],
  ['23830', '238', '30', 'Denmark', 'ORANGE'],
  ['23830', '238', '30', 'Denmark', 'ORANGE'],
  ['23830', '238', '30', 'Denmark', 'ORANGE'],
  ['23830', '238', '30', 'Denmark', 'ORANGE'],
  ['23830', '238', '30', 'Denmark', 'ORANGE'],
  ['23830', '238', '30', 'Denmark', 'ORANGE'],
  ['23830', '238', '30', 'Denmark', 'ORANGE'],
  ['23830', '238', '30', 'Denmark', 'ORANGE'],
  ['23830', '238', '30', 'Denmark', 'ORANGE'],
  ['23830', '238', '30', 'Denmark', 'ORANGE'],
  ['23830', '238', '30', 'Denmark', 'ORANGE'],
  ['23830', '238', '30', 'Denmark', 'ORANGE'],
  ['23830', '238', '30', 'Denmark', 'ORANGE'],
  ['23830', '238', '30', 'Denmark', 'ORANGE'],
  ['23830', '238', '30', 'Denmark', 'ORANGE'],
  ['23830', '238', '30', 'Denmark', 'ORANGE'],
  ['23830', '238', '30', 'Denmark', 'ORANGE'],
  ['23830', '238', '30', 'Denmark', 'ORANGE'],
  ['23830', '238', '30', 'Denmark', 'ORANGE'],
  ['6380', '638', '0', 'Djibouti', 'DEFAULT DJIBOUTI'],
  ['63801', '638', '1', 'Djibouti', 'EVATIS'],
  ['63801', '638', '1', 'Djibouti', 'EVATIS'],
  ['310015', '310', '15', 'Dominica', 'CINGULAR WIRELESS'],
  ['310015', '310', '15', 'Dominica', 'CABLE & WIRELESS'],
  ['310015', '310', '15', 'Dominica', 'CABLE & WIRELESS'],
  ['310015', '310', '15', 'Dominica', 'CABLE & WIRELESS'],
  ['37001', '370', '1', 'Dominica', 'ORANGE'],
  ['7400', '740', '0', 'Ecuador', 'DEFAULT ECUADOR'],
  ['74000', '740', '0', 'Ecuador', 'OTECEL'],
  ['74001', '740', '1', 'Ecuador', 'CONECEL S.A.'],
  ['74002', '740', '2', 'Ecuador', 'TELECSA'],
  ['74002', '740', '2', 'Ecuador', 'TELECSA'],
  ['6020', '602', '0', 'Egypt', 'DEFAULT EGYPT'],
  ['60201', '602', '1', 'Egypt', 'MOBINIL'],
  ['60201', '602', '1', 'Egypt', 'MOBINIL'],
  ['60201', '602', '1', 'Egypt', 'MOBINIL'],
  ['60201', '602', '1', 'Egypt', 'MOBINIL'],
  ['60202', '602', '2', 'Egypt', 'VODAFONE'],
  ['60202', '602', '2', 'Egypt', 'VODAFONE'],
  ['60202', '602', '2', 'Egypt', 'VODAFONE'],
  ['60202', '602', '2', 'Egypt', 'VODAFONE'],
  ['60203', '602', '3', 'Egypt', 'ETISALAT'],
  ['60203', '602', '3', 'Egypt', 'ETISALAT'],
  ['60203', '602', '3', 'Egypt', 'ETISALAT'],
  ['60204', '602', '4', 'Egypt', 'Egypt Telecom'],
  ['7060', '706', '0', 'El Salvador', 'DEFAULT EL SALVADOR'],
  ['70601', '706', '1', 'El Salvador', 'CTE'],
  ['70602', '706', '2', 'El Salvador', 'DIGICEL'],
  ['70603', '706', '3', 'El Salvador', 'TELEMOVIL'],
  ['70604', '706', '4', 'El Salvador', 'Telefonica'],
  ['70604', '706', '4', 'El Salvador', 'Telefonica'],
  ['70604', '706', '4', 'El Salvador', 'Telefonica'],
  ['70604', '706', '4', 'El Salvador', 'Telefonica'],
  ['70604', '706', '4', 'El Salvador', 'Telefonica'],
  ['70604', '706', '4', 'El Salvador', 'Telefonica'],
  ['70604', '706', '4', 'El Salvador', 'Telefonica'],
  ['70604', '706', '4', 'El Salvador', 'Telefonica'],
  ['70604', '706', '4', 'El Salvador', 'Telefonica'],
  ['70604', '706', '4', 'El Salvador', 'Telefonica'],
  ['70604', '706', '4', 'El Salvador', 'Telefonica'],
  ['70604', '706', '4', 'El Salvador', 'Telefonica'],
  ['70604', '706', '4', 'El Salvador', 'Telefonica'],
  ['70604', '706', '4', 'El Salvador', 'Telefonica'],
  ['70604', '706', '4', 'El Salvador', 'Telefonica'],
  ['70604', '706', '4', 'El Salvador', 'Telefonica'],
  ['70604', '706', '4', 'El Salvador', 'Telefonica'],
  ['70604', '706', '4', 'El Salvador', 'Telefonica'],
  ['70604', '706', '4', 'El Salvador', 'Telefonica'],
  ['70604', '706', '4', 'El Salvador', 'Telefonica'],
  ['70604', '706', '4', 'El Salvador', 'Telefonica'],
  ['70604', '706', '4', 'El Salvador', 'Telefonica'],
  ['70604', '706', '4', 'El Salvador', 'Telefonica'],
  ['70604', '706', '4', 'El Salvador', 'Telefonica'],
  ['70604', '706', '4', 'El Salvador', 'Telefonica'],
  ['70604', '706', '4', 'El Salvador', 'Telefonica'],
  ['70604', '706', '4', 'El Salvador', 'Telefonica'],
  ['70604', '706', '4', 'El Salvador', 'Telefonica'],
  ['70604', '706', '4', 'El Salvador', 'Telefonica'],
  ['70604', '706', '4', 'El Salvador', 'Telefonica'],
  ['70604', '706', '4', 'El Salvador', 'Telefonica'],
  ['70604', '706', '4', 'El Salvador', 'Telefonica'],
  ['70604', '706', '4', 'El Salvador', 'Telefonica'],
  ['70604', '706', '4', 'El Salvador', 'Telefonica'],
  ['70604', '706', '4', 'El Salvador', 'Telefonica'],
  ['70604', '706', '4', 'El Salvador', 'Telefonica'],
  ['70604', '706', '4', 'El Salvador', 'Telefonica'],
  ['70604', '706', '4', 'El Salvador', 'Telefonica'],
  ['70604', '706', '4', 'El Salvador', 'Telefonica'],
  ['70604', '706', '4', 'El Salvador', 'Telefonica'],
  ['70604', '706', '4', 'El Salvador', 'Telefonica'],
  ['70604', '706', '4', 'El Salvador', 'Telefonica'],
  ['70604', '706', '4', 'El Salvador', 'Telefonica'],
  ['70605', '706', '5', 'El Salvador', 'RED (Intelfon)'],
  ['70605', '706', '5', 'El Salvador', 'RED (Intelfon)'],
  ['70605', '706', '5', 'El Salvador', 'RED (Intelfon)'],
  ['70605', '706', '5', 'El Salvador', 'RED (Intelfon)'],
  ['70605', '706', '5', 'El Salvador', 'RED (Intelfon)'],
  ['2340', '234', '0', 'England', 'DEFAULT UK'],
  ['234000', '234', '0', 'England', 'BT Group plc'],
  ['234000', '234', '0', 'England', 'BT Group plc'],
  ['234000', '234', '0', 'England', 'BT Group plc'],
  ['234000', '234', '0', 'England', 'BT Group plc'],
  ['234000', '234', '0', 'England', 'BT Group plc'],
  ['234000', '234', '0', 'England', 'BT Group plc'],
  ['234000', '234', '0', 'England', 'BT Group plc'],
  ['234000', '234', '0', 'England', 'BT Group plc'],
  ['234000', '234', '0', 'England', 'BT Group plc'],
  ['234000', '234', '0', 'England', 'BT Group plc'],
  ['234001', '234', '1', 'England', 'Mundio Mobile Ltd (Mundio)'],
  ['234001', '234', '1', 'England', 'Mundio Mobile Ltd (Mundio)'],
  ['234001', '234', '1', 'England', 'Mundio Mobile Ltd (Mundio)'],
  ['234001', '234', '1', 'England', 'Mundio Mobile Ltd (Mundio)'],
  ['234001', '234', '1', 'England', 'Mundio Mobile Ltd (Mundio)'],
  ['234001', '234', '1', 'England', 'Mundio Mobile Ltd (Mundio)'],
  ['234001', '234', '1', 'England', 'Mundio Mobile Ltd (Mundio)'],
  ['234001', '234', '1', 'England', 'Mundio Mobile Ltd (Mundio)'],
  ['234001', '234', '1', 'England', 'Mundio Mobile Ltd (Mundio)'],
  ['234001', '234', '1', 'England', 'Mundio Mobile Ltd (Mundio)'],
  ['234001', '234', '1', 'England', 'Mundio Mobile Ltd (Mundio)'],
  ['234001', '234', '1', 'England', 'Mundio Mobile Ltd (Mundio)'],
  ['234001', '234', '1', 'England', 'Mundio Mobile Ltd (Mundio)'],
  ['234001', '234', '1', 'England', 'Mundio Mobile Ltd (Mundio)'],
  ['234001', '234', '1', 'England', 'Mundio Mobile Ltd (Mundio)'],
  ['234001', '234', '1', 'England', 'Mundio Mobile Ltd (Mundio)'],
  ['234001', '234', '1', 'England', 'Mundio Mobile Ltd (Mundio)'],
  ['234001', '234', '1', 'England', 'Mundio Mobile Ltd (Mundio)'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234002', '234', '2', 'England', 'Telefonica UK Ltd'],
  ['234003', '234', '3', 'England', 'Jersey Airtel  Ltd'],
  ['234003', '234', '3', 'England', 'Jersey Airtel  Ltd'],
  ['234003', '234', '3', 'England', 'Jersey Airtel  Ltd'],
  ['234003', '234', '3', 'England', 'Jersey Airtel  Ltd'],
  ['234003', '234', '3', 'England', 'Jersey Airtel  Ltd'],
  ['234003', '234', '3', 'England', 'Jersey Airtel  Ltd'],
  ['234007', '234', '7', 'England', 'Cable & Wireless Worldwide plc'],
  ['234007', '234', '7', 'England', 'Cable & Wireless Worldwide plc'],
  ['234007', '234', '7', 'England', 'Cable & Wireless Worldwide plc'],
  ['234007', '234', '7', 'England', 'Cable & Wireless Worldwide plc'],
  ['234007', '234', '7', 'England', 'Cable & Wireless Worldwide plc'],
  ['234007', '234', '7', 'England', 'Cable & Wireless Worldwide plc'],
  ['234007', '234', '7', 'England', 'Cable & Wireless Worldwide plc'],
  ['234007', '234', '7', 'England', 'Cable & Wireless Worldwide plc'],
  ['234007', '234', '7', 'England', 'Cable & Wireless Worldwide plc'],
  ['234007', '234', '7', 'England', 'Cable & Wireless Worldwide plc'],
  ['234007', '234', '7', 'England', 'Cable & Wireless Worldwide plc'],
  ['234007', '234', '7', 'England', 'Cable & Wireless Worldwide plc'],
  ['234009', '234', '9', 'England', 'Tismi BV'],
  ['234009', '234', '9', 'England', 'Tismi BV'],
  ['234009', '234', '9', 'England', 'Tismi BV'],
  ['23401', '234', '1', 'England', 'Mundio Mobile (MCom)'],
  ['23401', '234', '1', 'England', 'Mundio Mobile (MCom)'],
  ['23401', '234', '1', 'England', 'Mundio Mobile (MCom)'],
  ['23401', '234', '1', 'England', 'Mundio Mobile (MCom)'],
  ['23401', '234', '1', 'England', 'Mundio Mobile (MCom)'],
  ['23401', '234', '1', 'England', 'Mundio Mobile (MCom)'],
  ['23401', '234', '1', 'England', 'Mundio Mobile (MCom)'],
  ['23401', '234', '1', 'England', 'Mundio Mobile (MCom)'],
  ['23401', '234', '1', 'England', 'Mundio Mobile (MCom)'],
  ['23401', '234', '1', 'England', 'Mundio Mobile (MCom)'],
  ['23401', '234', '1', 'England', 'Mundio Mobile (MCom)'],
  ['23401', '234', '1', 'England', 'Mundio Mobile (MCom)'],
  ['23401', '234', '1', 'England', 'Mundio Mobile (MCom)'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234010', '234', '10', 'England', 'O2 BT CELLNET'],
  ['234014', '234', '14', 'England', 'Hay Systems Ltd (HSL)'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'OPAL TELECOM'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'OPAL TELECOM'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'Vodafone UK Ltd'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  ['234015', '234', '15', 'England', 'VODAFONE'],
  [
    '234016',
    '234',
    '16',
    'England',
    'TalkTalk Communications Ltd (TalkTalk Business)'
  ],
  [
    '234016',
    '234',
    '16',
    'England',
    'TalkTalk Communications Ltd (TalkTalk Business)'
  ],
  [
    '234016',
    '234',
    '16',
    'England',
    'TalkTalk Communications Ltd (TalkTalk Business)'
  ],
  ['234017', '234', '17', 'England', 'FleXtel Ltd'],
  ['234017', '234', '17', 'England', 'FleXtel Ltd'],
  ['234018', '234', '18', 'England', 'Cloud9 Mobile Communications Plc'],
  ['234018', '234', '18', 'England', 'Cloud9 Mobile Communications Plc'],
  ['234018', '234', '18', 'England', 'Cloud9 Mobile Communications Plc'],
  ['234018', '234', '18', 'England', 'Cloud9 Mobile Communications Plc'],
  ['234018', '234', '18', 'England', 'Cloud9 Mobile Communications Plc'],
  ['234018', '234', '18', 'England', 'Cloud9 Mobile Communications Plc'],
  ['234018', '234', '18', 'England', 'Cloud9 Mobile Communications Plc'],
  ['234019', '234', '19', 'England', 'Teleware plc'],
  ['234019', '234', '19', 'England', 'Teleware plc'],
  ['234019', '234', '19', 'England', 'Teleware plc'],
  ['23402', '234', '2', 'England', 'O2'],
  ['23402', '234', '2', 'England', 'O2'],
  ['23402', '234', '2', 'England', 'O2'],
  ['23402', '234', '2', 'England', 'O2'],
  ['23402', '234', '2', 'England', 'O2'],
  ['23402', '234', '2', 'England', 'O2'],
  ['23402', '234', '2', 'England', 'O2'],
  ['23402', '234', '2', 'England', 'O2'],
  ['23402', '234', '2', 'England', 'O2'],
  ['23402', '234', '2', 'England', 'O2'],
  ['23402', '234', '2', 'England', 'O2'],
  ['23402', '234', '2', 'England', 'O2'],
  ['23402', '234', '2', 'England', 'O2'],
  ['23402', '234', '2', 'England', 'O2'],
  ['23402', '234', '2', 'England', 'O2'],
  ['23402', '234', '2', 'England', 'O2'],
  ['23402', '234', '2', 'England', 'O2'],
  ['23402', '234', '2', 'England', 'O2'],
  ['23402', '234', '2', 'England', 'O2'],
  ['23402', '234', '2', 'England', 'O2'],
  ['23402', '234', '2', 'England', 'O2'],
  ['23402', '234', '2', 'England', 'O2'],
  ['23402', '234', '2', 'England', 'O2'],
  ['23402', '234', '2', 'England', 'O2'],
  ['23402', '234', '2', 'England', 'O2'],
  ['23402', '234', '2', 'England', 'O2'],
  ['23402', '234', '2', 'England', 'O2'],
  ['23402', '234', '2', 'England', 'O2'],
  ['23402', '234', '2', 'England', 'O2'],
  ['23402', '234', '2', 'England', 'O2'],
  ['23402', '234', '2', 'England', 'O2'],
  ['23402', '234', '2', 'England', 'O2'],
  ['23402', '234', '2', 'England', 'O2'],
  ['23402', '234', '2', 'England', 'O2'],
  ['23402', '234', '2', 'England', 'O2'],
  ['23402', '234', '2', 'England', 'O2'],
  ['23402', '234', '2', 'England', 'O2'],
  ['23402', '234', '2', 'England', 'O2'],
  ['23402', '234', '2', 'England', 'O2'],
  ['23402', '234', '2', 'England', 'O2'],
  ['23402', '234', '2', 'England', 'O2'],
  ['23402', '234', '2', 'England', 'O2'],
  ['23402', '234', '2', 'England', 'O2'],
  ['23402', '234', '2', 'England', 'O2'],
  ['23402', '234', '2', 'England', 'O2'],
  ['23402', '234', '2', 'England', 'O2'],
  ['23402', '234', '2', 'England', 'O2'],
  ['23402', '234', '2', 'England', 'O2'],
  ['23402', '234', '2', 'England', 'O2'],
  ['23402', '234', '2', 'England', 'O2'],
  ['23402', '234', '2', 'England', 'O2'],
  ['23402', '234', '2', 'England', 'O2'],
  ['23402', '234', '2', 'England', 'O2'],
  ['23402', '234', '2', 'England', 'O2'],
  ['23402', '234', '2', 'England', 'O2'],
  ['23402', '234', '2', 'England', 'O2'],
  ['23402', '234', '2', 'England', 'O2'],
  ['23402', '234', '2', 'England', 'O2'],
  ['23402', '234', '2', 'England', 'O2'],
  ['23402', '234', '2', 'England', 'O2'],
  ['23402', '234', '2', 'England', 'O2'],
  ['23402', '234', '2', 'England', 'O2'],
  ['23402', '234', '2', 'England', 'O2'],
  ['23402', '234', '2', 'England', 'O2'],
  ['23402', '234', '2', 'England', 'O2'],
  ['23402', '234', '2', 'England', 'O2'],
  ['23402', '234', '2', 'England', 'O2'],
  ['23402', '234', '2', 'England', 'O2'],
  ['23402', '234', '2', 'England', 'O2'],
  ['23402', '234', '2', 'England', 'O2'],
  ['23402', '234', '2', 'England', 'O2'],
  ['23402', '234', '2', 'England', 'O2'],
  ['23402', '234', '2', 'England', 'O2'],
  ['23402', '234', '2', 'England', 'O2'],
  ['23402', '234', '2', 'England', 'O2'],
  ['23402', '234', '2', 'England', 'O2'],
  ['23402', '234', '2', 'England', 'O2'],
  ['23402', '234', '2', 'England', 'O2'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'HUTCHINSON 3G'],
  ['234020', '234', '20', 'England', 'HUTCHINSON 3G'],
  ['234020', '234', '20', 'England', 'HUTCHINSON 3G'],
  ['234020', '234', '20', 'England', 'HUTCHINSON 3G'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'HUTCHINSON 3G'],
  ['234020', '234', '20', 'England', 'HUTCHINSON 3G'],
  ['234020', '234', '20', 'England', 'HUTCHINSON 3G'],
  ['234020', '234', '20', 'England', 'HUTCHINSON 3G'],
  ['234020', '234', '20', 'England', 'HUTCHINSON 3G'],
  ['234020', '234', '20', 'England', 'HUTCHINSON 3G'],
  ['234020', '234', '20', 'England', 'HUTCHINSON 3G'],
  ['234020', '234', '20', 'England', 'HUTCHINSON 3G'],
  ['234020', '234', '20', 'England', 'HUTCHINSON 3G'],
  ['234020', '234', '20', 'England', 'HUTCHINSON 3G'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'HUTCHINSON 3G'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'Hutchison 3G UK Ltd'],
  ['234020', '234', '20', 'England', 'HUTCHINSON 3G'],
  ['234020', '234', '20', 'England', 'HUTCHINSON 3G'],
  ['234020', '234', '20', 'England', 'HUTCHINSON 3G'],
  ['234020', '234', '20', 'England', 'HUTCHINSON 3G'],
  ['234020', '234', '20', 'England', 'HUTCHINSON 3G'],
  ['234020', '234', '20', 'England', 'HUTCHINSON 3G'],
  ['234020', '234', '20', 'England', 'HUTCHINSON 3G'],
  ['234020', '234', '20', 'England', 'HUTCHINSON 3G'],
  ['234020', '234', '20', 'England', 'HUTCHINSON 3G'],
  ['234020', '234', '20', 'England', 'HUTCHINSON 3G'],
  ['234020', '234', '20', 'England', 'HUTCHINSON 3G'],
  ['234020', '234', '20', 'England', 'HUTCHINSON 3G'],
  ['234020', '234', '20', 'England', 'HUTCHINSON 3G'],
  ['234020', '234', '20', 'England', 'HUTCHINSON 3G'],
  ['234020', '234', '20', 'England', 'HUTCHINSON 3G'],
  ['234020', '234', '20', 'England', 'HUTCHINSON 3G'],
  ['234020', '234', '20', 'England', 'HUTCHINSON 3G'],
  ['234020', '234', '20', 'England', 'HUTCHINSON 3G'],
  ['234020', '234', '20', 'England', 'HUTCHINSON 3G'],
  ['234020', '234', '20', 'England', 'HUTCHINSON 3G'],
  ['234020', '234', '20', 'England', 'HUTCHINSON 3G'],
  ['234020', '234', '20', 'England', 'HUTCHINSON 3G'],
  ['234020', '234', '20', 'England', 'HUTCHINSON 3G'],
  ['234020', '234', '20', 'England', 'HUTCHINSON 3G'],
  ['234020', '234', '20', 'England', 'HUTCHINSON 3G'],
  ['234020', '234', '20', 'England', 'HUTCHINSON 3G'],
  ['234020', '234', '20', 'England', 'HUTCHINSON 3G'],
  ['234020', '234', '20', 'England', 'HUTCHINSON 3G'],
  ['234020', '234', '20', 'England', 'HUTCHINSON 3G'],
  ['234020', '234', '20', 'England', 'HUTCHINSON 3G'],
  ['234020', '234', '20', 'England', 'HUTCHINSON 3G'],
  ['234020', '234', '20', 'England', 'HUTCHINSON 3G'],
  ['234020', '234', '20', 'England', 'HUTCHINSON 3G'],
  ['234020', '234', '20', 'England', 'HUTCHINSON 3G'],
  ['234020', '234', '20', 'England', 'HUTCHINSON 3G'],
  ['234020', '234', '20', 'England', 'HUTCHINSON 3G'],
  ['234020', '234', '20', 'England', 'HUTCHINSON 3G'],
  ['234020', '234', '20', 'England', 'HUTCHINSON 3G'],
  ['234020', '234', '20', 'England', 'HUTCHINSON 3G'],
  ['234020', '234', '20', 'England', 'HUTCHINSON 3G'],
  ['234020', '234', '20', 'England', 'HUTCHINSON 3G'],
  ['234020', '234', '20', 'England', 'HUTCHINSON 3G'],
  ['234020', '234', '20', 'England', 'HUTCHINSON 3G'],
  ['234020', '234', '20', 'England', 'HUTCHINSON 3G'],
  ['234020', '234', '20', 'England', 'HUTCHINSON 3G'],
  ['234022', '234', '22', 'England', 'Routo Telecommunications Ltd'],
  ['234023', '234', '23', 'England', 'Vectone Network Ltd'],
  ['234023', '234', '23', 'England', 'Vectone Network Ltd'],
  ['234024', '234', '24', 'England', 'Stour Marine Ltd'],
  ['234024', '234', '24', 'England', 'Stour Marine Ltd'],
  ['234025', '234', '25', 'England', 'Truphone UK'],
  ['234025', '234', '25', 'England', 'Truphone UK'],
  ['234025', '234', '25', 'England', 'Truphone UK'],
  ['234025', '234', '25', 'England', 'Truphone UK'],
  ['234025', '234', '25', 'England', 'Truphone UK'],
  ['234025', '234', '25', 'England', 'Truphone UK'],
  ['234025', '234', '25', 'England', 'Truphone UK'],
  ['234025', '234', '25', 'England', 'Truphone UK'],
  ['234026', '234', '26', 'England', 'Lycamobile UK Ltd'],
  ['234026', '234', '26', 'England', 'Lycamobile UK Ltd'],
  ['234026', '234', '26', 'England', 'Lycamobile UK Ltd'],
  ['234026', '234', '26', 'England', 'Lycamobile UK Ltd'],
  ['234026', '234', '26', 'England', 'Lycamobile UK Ltd'],
  ['234026', '234', '26', 'England', 'Lycamobile UK Ltd'],
  ['234026', '234', '26', 'England', 'Lycamobile UK Ltd'],
  ['234026', '234', '26', 'England', 'Lycamobile UK Ltd'],
  ['234026', '234', '26', 'England', 'Lycamobile UK Ltd'],
  ['234026', '234', '26', 'England', 'Lycamobile UK Ltd'],
  ['234026', '234', '26', 'England', 'Lycamobile UK Ltd'],
  ['234026', '234', '26', 'England', 'Lycamobile UK Ltd'],
  ['234026', '234', '26', 'England', 'Lycamobile UK Ltd'],
  ['234026', '234', '26', 'England', 'Lycamobile UK Ltd'],
  ['234026', '234', '26', 'England', 'Lycamobile UK Ltd'],
  ['234026', '234', '26', 'England', 'Lycamobile UK Ltd'],
  ['234026', '234', '26', 'England', 'Lycamobile UK Ltd'],
  ['234026', '234', '26', 'England', 'Lycamobile UK Ltd'],
  ['234026', '234', '26', 'England', 'Lycamobile UK Ltd'],
  ['234026', '234', '26', 'England', 'Lycamobile UK Ltd'],
  ['234026', '234', '26', 'England', 'Lycamobile UK Ltd'],
  ['234026', '234', '26', 'England', 'Lycamobile UK Ltd'],
  ['234026', '234', '26', 'England', 'Lycamobile UK Ltd'],
  ['234026', '234', '26', 'England', 'Lycamobile UK Ltd'],
  ['234026', '234', '26', 'England', 'Lycamobile UK Ltd'],
  ['234026', '234', '26', 'England', 'Lycamobile UK Ltd'],
  ['234026', '234', '26', 'England', 'Lycamobile UK Ltd'],
  ['234026', '234', '26', 'England', 'Lycamobile UK Ltd'],
  ['234026', '234', '26', 'England', 'Lycamobile UK Ltd'],
  ['234026', '234', '26', 'England', 'Lycamobile UK Ltd'],
  ['234026', '234', '26', 'England', 'Lycamobile UK Ltd'],
  ['234026', '234', '26', 'England', 'Lycamobile UK Ltd'],
  ['234026', '234', '26', 'England', 'Lycamobile UK Ltd'],
  ['234026', '234', '26', 'England', 'Lycamobile UK Ltd'],
  ['234026', '234', '26', 'England', 'Lycamobile UK Ltd'],
  ['234026', '234', '26', 'England', 'Lycamobile UK Ltd'],
  ['234026', '234', '26', 'England', 'Lycamobile UK Ltd'],
  ['234026', '234', '26', 'England', 'Lycamobile UK Ltd'],
  ['234026', '234', '26', 'England', 'Lycamobile UK Ltd'],
  ['234026', '234', '26', 'England', 'Lycamobile UK Ltd'],
  ['234026', '234', '26', 'England', 'Lycamobile UK Ltd'],
  ['234026', '234', '26', 'England', 'Lycamobile UK Ltd'],
  ['234026', '234', '26', 'England', 'Lycamobile UK Ltd'],
  ['234026', '234', '26', 'England', 'Lycamobile UK Ltd'],
  ['234026', '234', '26', 'England', 'Lycamobile UK Ltd'],
  ['234026', '234', '26', 'England', 'Lycamobile UK Ltd'],
  ['234026', '234', '26', 'England', 'Lycamobile UK Ltd'],
  ['234026', '234', '26', 'England', 'Lycamobile UK Ltd'],
  ['234026', '234', '26', 'England', 'Lycamobile UK Ltd'],
  ['234026', '234', '26', 'England', 'Lycamobile UK Ltd'],
  ['234026', '234', '26', 'England', 'Lycamobile UK Ltd'],
  ['234026', '234', '26', 'England', 'Lycamobile UK Ltd'],
  ['234026', '234', '26', 'England', 'Lycamobile UK Ltd'],
  ['234026', '234', '26', 'England', 'Lycamobile UK Ltd'],
  ['234026', '234', '26', 'England', 'Lycamobile UK Ltd'],
  ['234026', '234', '26', 'England', 'Lycamobile UK Ltd'],
  ['234026', '234', '26', 'England', 'Lycamobile UK Ltd'],
  ['234026', '234', '26', 'England', 'Lycamobile UK Ltd'],
  ['234026', '234', '26', 'England', 'Lycamobile UK Ltd'],
  ['234026', '234', '26', 'England', 'Lycamobile UK Ltd'],
  ['234026', '234', '26', 'England', 'Lycamobile UK Ltd'],
  ['234026', '234', '26', 'England', 'Lycamobile UK Ltd'],
  ['234026', '234', '26', 'England', 'Lycamobile UK Ltd'],
  ['234026', '234', '26', 'England', 'Lycamobile UK Ltd'],
  ['234026', '234', '26', 'England', 'Lycamobile UK Ltd'],
  ['234026', '234', '26', 'England', 'Lycamobile UK Ltd'],
  ['234026', '234', '26', 'England', 'Lycamobile UK Ltd'],
  ['234026', '234', '26', 'England', 'Lycamobile UK Ltd'],
  ['234026', '234', '26', 'England', 'Lycamobile UK Ltd'],
  ['234026', '234', '26', 'England', 'Lycamobile UK Ltd'],
  ['234026', '234', '26', 'England', 'Lycamobile UK Ltd'],
  ['234026', '234', '26', 'England', 'Lycamobile UK Ltd'],
  ['234026', '234', '26', 'England', 'Lycamobile UK Ltd'],
  ['234026', '234', '26', 'England', 'Lycamobile UK Ltd'],
  ['234026', '234', '26', 'England', 'Lycamobile UK Ltd'],
  ['234026', '234', '26', 'England', 'Lycamobile UK Ltd'],
  ['234026', '234', '26', 'England', 'Lycamobile UK Ltd'],
  ['234026', '234', '26', 'England', 'Lycamobile UK Ltd'],
  ['234026', '234', '26', 'England', 'Lycamobile UK Ltd'],
  ['234026', '234', '26', 'England', 'Lycamobile UK Ltd'],
  ['234026', '234', '26', 'England', 'Lycamobile UK Ltd'],
  ['234027', '234', '27', 'England', 'Unknown-UK-7748'],
  ['234028', '234', '28', 'England', 'Marathon Telecom Ltd'],
  ['234028', '234', '28', 'England', 'Marathon Telecom Ltd'],
  ['23403', '234', '3', 'England', 'Jersey Airtel (Vodafone)'],
  ['23403', '234', '3', 'England', 'Jersey Airtel (Vodafone)'],
  ['23403', '234', '3', 'England', 'Jersey Airtel (Vodafone)'],
  ['23403', '234', '3', 'England', 'Jersey Airtel (Vodafone)'],
  ['23403', '234', '3', 'England', 'Jersey Airtel (Vodafone)'],
  ['23403', '234', '3', 'England', 'Jersey Airtel (Vodafone)'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'T-MOBILE'],
  ['234030', '234', '30', 'England', 'T-MOBILE'],
  ['234030', '234', '30', 'England', 'T-MOBILE'],
  ['234030', '234', '30', 'England', 'T-MOBILE'],
  ['234030', '234', '30', 'England', 'T-MOBILE'],
  ['234030', '234', '30', 'England', 'T-MOBILE'],
  ['234030', '234', '30', 'England', 'T-MOBILE'],
  ['234030', '234', '30', 'England', 'T-MOBILE'],
  ['234030', '234', '30', 'England', 'T-MOBILE'],
  ['234030', '234', '30', 'England', 'T-MOBILE'],
  ['234030', '234', '30', 'England', 'T-MOBILE'],
  ['234030', '234', '30', 'England', 'T-MOBILE'],
  ['234030', '234', '30', 'England', 'T-MOBILE'],
  ['234030', '234', '30', 'England', 'T-MOBILE'],
  ['234030', '234', '30', 'England', 'T-MOBILE'],
  ['234030', '234', '30', 'England', 'T-MOBILE'],
  ['234030', '234', '30', 'England', 'T-MOBILE'],
  ['234030', '234', '30', 'England', 'T-MOBILE'],
  ['234030', '234', '30', 'England', 'T-MOBILE'],
  ['234030', '234', '30', 'England', 'T-MOBILE'],
  ['234030', '234', '30', 'England', 'T-MOBILE'],
  ['234030', '234', '30', 'England', 'T-MOBILE'],
  ['234030', '234', '30', 'England', 'T-MOBILE'],
  ['234030', '234', '30', 'England', 'T-MOBILE'],
  ['234030', '234', '30', 'England', 'T-MOBILE'],
  ['234030', '234', '30', 'England', 'T-MOBILE'],
  ['234030', '234', '30', 'England', 'T-MOBILE'],
  ['234030', '234', '30', 'England', 'T-MOBILE'],
  ['234030', '234', '30', 'England', 'T-MOBILE'],
  ['234030', '234', '30', 'England', 'T-MOBILE'],
  ['234030', '234', '30', 'England', 'T-MOBILE'],
  ['234030', '234', '30', 'England', 'T-MOBILE'],
  ['234030', '234', '30', 'England', 'T-MOBILE'],
  ['234030', '234', '30', 'England', 'T-MOBILE'],
  ['234030', '234', '30', 'England', 'T-MOBILE'],
  ['234030', '234', '30', 'England', 'T-MOBILE'],
  ['234030', '234', '30', 'England', 'T-MOBILE'],
  ['234030', '234', '30', 'England', 'T-MOBILE'],
  ['234030', '234', '30', 'England', 'T-MOBILE'],
  ['234030', '234', '30', 'England', 'T-MOBILE'],
  ['234030', '234', '30', 'England', 'T-MOBILE'],
  ['234030', '234', '30', 'England', 'T-MOBILE'],
  ['234030', '234', '30', 'England', 'T-MOBILE'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'T-MOBILE'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'T-MOBILE'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'T-MOBILE'],
  ['234030', '234', '30', 'England', 'T-MOBILE'],
  ['234030', '234', '30', 'England', 'T-MOBILE'],
  ['234030', '234', '30', 'England', 'T-MOBILE'],
  ['234030', '234', '30', 'England', 'T-MOBILE'],
  ['234030', '234', '30', 'England', 'T-MOBILE'],
  ['234030', '234', '30', 'England', 'T-MOBILE'],
  ['234030', '234', '30', 'England', 'T-MOBILE'],
  ['234030', '234', '30', 'England', 'T-MOBILE'],
  ['234030', '234', '30', 'England', 'T-MOBILE'],
  ['234030', '234', '30', 'England', 'T-MOBILE'],
  ['234030', '234', '30', 'England', 'T-MOBILE'],
  ['234030', '234', '30', 'England', 'T-MOBILE'],
  ['234030', '234', '30', 'England', 'T-MOBILE'],
  ['234030', '234', '30', 'England', 'T-MOBILE'],
  ['234030', '234', '30', 'England', 'T-MOBILE'],
  ['234030', '234', '30', 'England', 'T-MOBILE'],
  ['234030', '234', '30', 'England', 'T-MOBILE'],
  ['234030', '234', '30', 'England', 'T-MOBILE'],
  ['234030', '234', '30', 'England', 'T-MOBILE'],
  ['234030', '234', '30', 'England', 'T-MOBILE'],
  ['234030', '234', '30', 'England', 'T-MOBILE'],
  ['234030', '234', '30', 'England', 'T-MOBILE'],
  ['234030', '234', '30', 'England', 'T-MOBILE'],
  ['234030', '234', '30', 'England', 'T-MOBILE'],
  ['234030', '234', '30', 'England', 'T-MOBILE'],
  ['234030', '234', '30', 'England', 'INQUAM TELECOM'],
  ['234030', '234', '30', 'England', 'INQUAM TELECOM'],
  ['234030', '234', '30', 'England', 'INQUAM TELECOM'],
  ['234030', '234', '30', 'England', 'T-MOBILE'],
  ['234030', '234', '30', 'England', 'T-MOBILE'],
  ['234030', '234', '30', 'England', 'T-MOBILE'],
  ['234030', '234', '30', 'England', 'T-MOBILE'],
  ['234030', '234', '30', 'England', 'T-MOBILE'],
  ['234030', '234', '30', 'England', 'T-MOBILE'],
  ['234030', '234', '30', 'England', 'T-MOBILE'],
  ['234030', '234', '30', 'England', 'T-MOBILE'],
  ['234030', '234', '30', 'England', 'T-MOBILE'],
  ['234030', '234', '30', 'England', 'T-MOBILE'],
  ['234030', '234', '30', 'England', 'T-MOBILE'],
  ['234030', '234', '30', 'England', 'INQUAM TELECOM'],
  ['234030', '234', '30', 'England', 'T-MOBILE'],
  ['234030', '234', '30', 'England', 'T-MOBILE'],
  ['234030', '234', '30', 'England', 'T-MOBILE'],
  ['234030', '234', '30', 'England', 'T-MOBILE'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234030', '234', '30', 'England', 'Everything Everywhere Ltd'],
  ['234033', '234', '33', 'England', 'ORANGE'],
  ['234033', '234', '33', 'England', 'ORANGE'],
  ['234033', '234', '33', 'England', 'ORANGE'],
  ['234033', '234', '33', 'England', 'ORANGE'],
  ['234033', '234', '33', 'England', 'ORANGE'],
  ['234033', '234', '33', 'England', 'ORANGE'],
  ['234033', '234', '33', 'England', 'ORANGE'],
  ['234033', '234', '33', 'England', 'ORANGE'],
  ['234033', '234', '33', 'England', 'ORANGE'],
  ['234033', '234', '33', 'England', 'ORANGE'],
  ['234033', '234', '33', 'England', 'ORANGE'],
  ['234033', '234', '33', 'England', 'ORANGE'],
  ['234033', '234', '33', 'England', 'ORANGE'],
  ['234033', '234', '33', 'England', 'ORANGE'],
  ['234033', '234', '33', 'England', 'ORANGE'],
  ['234033', '234', '33', 'England', 'ORANGE'],
  ['234033', '234', '33', 'England', 'ORANGE'],
  ['234033', '234', '33', 'England', 'ORANGE'],
  ['234033', '234', '33', 'England', 'ORANGE'],
  ['234033', '234', '33', 'England', 'ORANGE'],
  ['234033', '234', '33', 'England', 'ORANGE'],
  ['234033', '234', '33', 'England', 'ORANGE'],
  ['234033', '234', '33', 'England', 'ORANGE'],
  ['234033', '234', '33', 'England', 'ORANGE'],
  ['234033', '234', '33', 'England', 'ORANGE'],
  ['234033', '234', '33', 'England', 'ORANGE'],
  ['234033', '234', '33', 'England', 'ORANGE'],
  ['234033', '234', '33', 'England', 'ORANGE'],
  ['234033', '234', '33', 'England', 'ORANGE'],
  ['234033', '234', '33', 'England', 'ORANGE'],
  ['234033', '234', '33', 'England', 'ORANGE'],
  ['234033', '234', '33', 'England', 'ORANGE'],
  ['234033', '234', '33', 'England', 'ORANGE'],
  ['234033', '234', '33', 'England', 'ORANGE'],
  ['234033', '234', '33', 'England', 'ORANGE'],
  ['234033', '234', '33', 'England', 'ORANGE'],
  ['234033', '234', '33', 'England', 'ORANGE'],
  ['234033', '234', '33', 'England', 'ORANGE'],
  ['234033', '234', '33', 'England', 'ORANGE'],
  ['234033', '234', '33', 'England', 'ORANGE'],
  ['234033', '234', '33', 'England', 'ORANGE'],
  ['234033', '234', '33', 'England', 'ORANGE'],
  ['234033', '234', '33', 'England', 'ORANGE'],
  ['234033', '234', '33', 'England', 'ORANGE'],
  ['234033', '234', '33', 'England', 'ORANGE'],
  ['234033', '234', '33', 'England', 'ORANGE'],
  ['234033', '234', '33', 'England', 'ORANGE'],
  ['234033', '234', '33', 'England', 'ORANGE'],
  ['234033', '234', '33', 'England', 'ORANGE'],
  ['234033', '234', '33', 'England', 'ORANGE'],
  ['234033', '234', '33', 'England', 'ORANGE'],
  ['234033', '234', '33', 'England', 'ORANGE'],
  ['234033', '234', '33', 'England', 'ORANGE'],
  ['234033', '234', '33', 'England', 'ORANGE'],
  ['234033', '234', '33', 'England', 'ORANGE'],
  ['234033', '234', '33', 'England', 'ORANGE'],
  ['234033', '234', '33', 'England', 'ORANGE'],
  ['234033', '234', '33', 'England', 'ORANGE'],
  ['234033', '234', '33', 'England', 'ORANGE'],
  ['234033', '234', '33', 'England', 'ORANGE'],
  ['234033', '234', '33', 'England', 'ORANGE'],
  ['234033', '234', '33', 'England', 'ORANGE'],
  ['234033', '234', '33', 'England', 'ORANGE'],
  ['234033', '234', '33', 'England', 'ORANGE'],
  ['234033', '234', '33', 'England', 'ORANGE'],
  ['234033', '234', '33', 'England', 'ORANGE'],
  ['234033', '234', '33', 'England', 'ORANGE'],
  ['234033', '234', '33', 'England', 'ORANGE'],
  ['234033', '234', '33', 'England', 'ORANGE'],
  ['234033', '234', '33', 'England', 'ORANGE'],
  ['234033', '234', '33', 'England', 'ORANGE'],
  ['234033', '234', '33', 'England', 'ORANGE'],
  ['234033', '234', '33', 'England', 'ORANGE'],
  ['234033', '234', '33', 'England', 'ORANGE'],
  ['234033', '234', '33', 'England', 'ORANGE'],
  ['234033', '234', '33', 'England', 'ORANGE'],
  ['234050', '234', '50', 'England', 'Jersey Telecom Ltd'],
  ['234050', '234', '50', 'England', 'Jersey Telecom Ltd'],
  ['234050', '234', '50', 'England', 'Jersey Telecom Ltd'],
  ['234050', '234', '50', 'England', 'Jersey Telecom Ltd'],
  ['234050', '234', '50', 'England', 'Jersey Telecom Ltd'],
  ['234050', '234', '50', 'England', 'Jersey Telecom Ltd'],
  ['234050', '234', '50', 'England', 'Jersey Telecom Ltd'],
  ['234050', '234', '50', 'England', 'Jersey Telecom Ltd'],
  ['234050', '234', '50', 'England', 'Jersey Telecom Ltd'],
  ['234050', '234', '50', 'England', 'Jersey Telecom Ltd'],
  ['234050', '234', '50', 'England', 'Jersey Telecom Ltd'],
  ['234050', '234', '50', 'England', 'Jersey Telecom Ltd'],
  ['234050', '234', '50', 'England', 'Jersey Telecom Ltd'],
  ['234050', '234', '50', 'England', 'Jersey Telecom Ltd'],
  ['234050', '234', '50', 'England', 'Jersey Telecom Ltd'],
  ['234050', '234', '50', 'England', 'Jersey Telecom Ltd'],
  ['234050', '234', '50', 'England', 'Jersey Telecom Ltd'],
  ['234050', '234', '50', 'England', 'Jersey Telecom Ltd'],
  ['234050', '234', '50', 'England', 'Jersey Telecom Ltd'],
  ['234050', '234', '50', 'England', 'JERSEY TEL'],
  ['234050', '234', '50', 'England', 'JERSEY TEL'],
  ['234050', '234', '50', 'England', 'Jersey Telecom Ltd'],
  ['234050', '234', '50', 'England', 'JERSEY TEL'],
  ['234050', '234', '50', 'England', 'JERSEY TEL'],
  ['234050', '234', '50', 'England', 'JERSEY TEL'],
  ['234050', '234', '50', 'England', 'Jersey Telecom Ltd'],
  ['234050', '234', '50', 'England', 'JERSEY TEL'],
  ['234055', '234', '55', 'England', 'Cable & Wireless Guernsey Ltd'],
  ['234055', '234', '55', 'England', 'Cable & Wireless Guernsey Ltd'],
  ['234055', '234', '55', 'England', 'GUERNSEY TEL'],
  ['234055', '234', '55', 'England', 'Cable & Wireless Guernsey Ltd'],
  ['234055', '234', '55', 'England', 'Cable & Wireless Guernsey Ltd'],
  ['234055', '234', '55', 'England', 'Cable & Wireless Guernsey Ltd'],
  ['234055', '234', '55', 'England', 'GUERNSEY TEL'],
  ['234055', '234', '55', 'England', 'GUERNSEY TEL'],
  ['234055', '234', '55', 'England', 'Cable & Wireless Guernsey Ltd'],
  ['234055', '234', '55', 'England', 'Cable & Wireless Guernsey Ltd'],
  ['234055', '234', '55', 'England', 'Cable & Wireless Guernsey Ltd'],
  ['234055', '234', '55', 'England', 'Cable & Wireless Guernsey Ltd'],
  ['234055', '234', '55', 'England', 'Cable & Wireless Guernsey Ltd'],
  ['234055', '234', '55', 'England', 'Cable & Wireless Guernsey Ltd'],
  ['234055', '234', '55', 'England', 'GUERNSEY TEL'],
  ['234055', '234', '55', 'England', 'Cable & Wireless Guernsey Ltd'],
  ['234055', '234', '55', 'England', 'Cable & Wireless Guernsey Ltd'],
  ['234055', '234', '55', 'England', 'Cable & Wireless Guernsey Ltd'],
  ['234058', '234', '58', 'England', 'MANX TELECOM'],
  ['234058', '234', '58', 'England', 'MANX TELECOM'],
  ['234058', '234', '58', 'England', 'MANX TELECOM'],
  ['234058', '234', '58', 'England', 'MANX TELECOM'],
  ['234058', '234', '58', 'England', 'Manx Telecom'],
  ['23407', '234', '7', 'England', 'Cable & Wireless'],
  ['23407', '234', '7', 'England', 'Cable & Wireless'],
  ['23407', '234', '7', 'England', 'Cable & Wireless'],
  ['23407', '234', '7', 'England', 'Cable & Wireless'],
  ['23407', '234', '7', 'England', 'Cable & Wireless'],
  ['23407', '234', '7', 'England', 'Cable & Wireless'],
  ['23407', '234', '7', 'England', 'Cable & Wireless'],
  ['23407', '234', '7', 'England', 'Cable & Wireless'],
  ['23407', '234', '7', 'England', 'Cable & Wireless'],
  ['23407', '234', '7', 'England', 'Cable & Wireless'],
  ['23407', '234', '7', 'England', 'Cable & Wireless'],
  ['23407', '234', '7', 'England', 'Cable & Wireless'],
  ['23407', '234', '7', 'England', 'Cable & Wireless'],
  ['23407', '234', '7', 'England', 'Cable & Wireless'],
  ['23407', '234', '7', 'England', 'Cable & Wireless'],
  ['23407', '234', '7', 'England', 'Cable & Wireless'],
  ['23407', '234', '7', 'England', 'Cable & Wireless'],
  ['23407', '234', '7', 'England', 'Cable & Wireless'],
  ['23407', '234', '7', 'England', 'Cable & Wireless'],
  ['23407', '234', '7', 'England', 'Cable & Wireless'],
  ['23407', '234', '7', 'England', 'Cable & Wireless'],
  ['23407', '234', '7', 'England', 'Cable & Wireless'],
  ['23407', '234', '7', 'England', 'Cable & Wireless'],
  ['23407', '234', '7', 'England', 'Cable & Wireless'],
  ['23407', '234', '7', 'England', 'Cable & Wireless'],
  ['23407', '234', '7', 'England', 'Cable & Wireless'],
  ['23407', '234', '7', 'England', 'Cable & Wireless'],
  ['23407', '234', '7', 'England', 'Cable & Wireless'],
  ['23407', '234', '7', 'England', 'Cable & Wireless'],
  ['23407', '234', '7', 'England', 'Cable & Wireless'],
  ['23407', '234', '7', 'England', 'Cable & Wireless'],
  ['23407', '234', '7', 'England', 'Cable & Wireless'],
  ['23407', '234', '7', 'England', 'Cable & Wireless'],
  ['23407', '234', '7', 'England', 'Cable & Wireless'],
  ['23407', '234', '7', 'England', 'Cable & Wireless'],
  ['23407', '234', '7', 'England', 'Cable & Wireless'],
  ['23407', '234', '7', 'England', 'Cable & Wireless'],
  ['23407', '234', '7', 'England', 'Cable & Wireless'],
  ['23407', '234', '7', 'England', 'Cable & Wireless'],
  ['23407', '234', '7', 'England', 'Cable & Wireless'],
  ['23407', '234', '7', 'England', 'Cable & Wireless'],
  ['23407', '234', '7', 'England', 'Cable & Wireless'],
  ['23407', '234', '7', 'England', 'Cable & Wireless'],
  ['23407', '234', '7', 'England', 'Cable & Wireless'],
  ['23407', '234', '7', 'England', 'Cable & Wireless'],
  ['23407', '234', '7', 'England', 'Cable & Wireless'],
  ['23407', '234', '7', 'England', 'Cable & Wireless'],
  ['23407', '234', '7', 'England', 'Cable & Wireless'],
  ['23407', '234', '7', 'England', 'Cable & Wireless'],
  ['23407', '234', '7', 'England', 'Cable & Wireless'],
  ['23407', '234', '7', 'England', 'Cable & Wireless'],
  ['23407', '234', '7', 'England', 'Cable & Wireless'],
  ['23407', '234', '7', 'England', 'Cable & Wireless'],
  ['23407', '234', '7', 'England', 'Cable & Wireless'],
  ['23407', '234', '7', 'England', 'Cable & Wireless'],
  ['23407', '234', '7', 'England', 'Cable & Wireless'],
  ['23407', '234', '7', 'England', 'Cable & Wireless'],
  ['23407', '234', '7', 'England', 'Cable & Wireless'],
  ['23407', '234', '7', 'England', 'Cable & Wireless'],
  ['234099', '234', '99', 'England', '08Direct Ltd'],
  ['23410', '234', '10', 'England', 'O2'],
  ['23410', '234', '10', 'England', 'O2'],
  ['23410', '234', '10', 'England', 'O2'],
  ['23410', '234', '10', 'England', 'O2'],
  ['23410', '234', '10', 'England', 'O2'],
  ['23410', '234', '10', 'England', 'O2'],
  ['23410', '234', '10', 'England', 'O2'],
  ['23410', '234', '10', 'England', 'O2'],
  ['23410', '234', '10', 'England', 'O2'],
  ['23410', '234', '10', 'England', 'O2'],
  ['23410', '234', '10', 'England', 'O2'],
  ['23410', '234', '10', 'England', 'O2'],
  ['23410', '234', '10', 'England', 'O2'],
  ['23410', '234', '10', 'England', 'O2'],
  ['23410', '234', '10', 'England', 'O2'],
  ['23410', '234', '10', 'England', 'O2'],
  ['23410', '234', '10', 'England', 'O2'],
  ['23410', '234', '10', 'England', 'O2'],
  ['23410', '234', '10', 'England', 'O2'],
  ['23410', '234', '10', 'England', 'O2'],
  ['23410', '234', '10', 'England', 'O2'],
  ['23410', '234', '10', 'England', 'O2'],
  ['23410', '234', '10', 'England', 'O2'],
  ['23410', '234', '10', 'England', 'O2'],
  ['23410', '234', '10', 'England', 'O2'],
  ['23410', '234', '10', 'England', 'O2'],
  ['23410', '234', '10', 'England', 'O2'],
  ['23410', '234', '10', 'England', 'O2'],
  ['23410', '234', '10', 'England', 'O2'],
  ['23410', '234', '10', 'England', 'O2'],
  ['23410', '234', '10', 'England', 'O2'],
  ['23410', '234', '10', 'England', 'O2'],
  ['23410', '234', '10', 'England', 'O2'],
  ['23410', '234', '10', 'England', 'O2'],
  ['23410', '234', '10', 'England', 'O2'],
  ['23410', '234', '10', 'England', 'O2'],
  ['23410', '234', '10', 'England', 'O2'],
  ['23410', '234', '10', 'England', 'O2'],
  ['23410', '234', '10', 'England', 'O2'],
  ['23410', '234', '10', 'England', 'O2'],
  ['23410', '234', '10', 'England', 'O2'],
  ['23410', '234', '10', 'England', 'O2'],
  ['23410', '234', '10', 'England', 'O2'],
  ['23410', '234', '10', 'England', 'O2'],
  ['23410', '234', '10', 'England', 'O2'],
  ['23410', '234', '10', 'England', 'O2'],
  ['23410', '234', '10', 'England', 'O2'],
  ['23410', '234', '10', 'England', 'O2'],
  ['23410', '234', '10', 'England', 'O2'],
  ['23410', '234', '10', 'England', 'O2'],
  ['23410', '234', '10', 'England', 'O2'],
  ['23410', '234', '10', 'England', 'O2'],
  ['23410', '234', '10', 'England', 'O2'],
  ['23410', '234', '10', 'England', 'O2'],
  ['23410', '234', '10', 'England', 'O2'],
  ['23410', '234', '10', 'England', 'O2'],
  ['23410', '234', '10', 'England', 'O2'],
  ['23410', '234', '10', 'England', 'O2'],
  ['23410', '234', '10', 'England', 'O2'],
  ['23410', '234', '10', 'England', 'O2'],
  ['23410', '234', '10', 'England', 'O2'],
  ['23410', '234', '10', 'England', 'O2'],
  ['23410', '234', '10', 'England', 'O2'],
  ['23410', '234', '10', 'England', 'O2'],
  ['23410', '234', '10', 'England', 'O2'],
  ['23410', '234', '10', 'England', 'O2'],
  ['23410', '234', '10', 'England', 'O2'],
  ['23410', '234', '10', 'England', 'O2'],
  ['23410', '234', '10', 'England', 'O2'],
  ['23410', '234', '10', 'England', 'O2'],
  ['23410', '234', '10', 'England', 'O2'],
  ['23410', '234', '10', 'England', 'O2'],
  ['23410', '234', '10', 'England', 'O2'],
  ['23410', '234', '10', 'England', 'O2'],
  ['23410', '234', '10', 'England', 'O2'],
  ['23410', '234', '10', 'England', 'O2'],
  ['23410', '234', '10', 'England', 'O2'],
  ['23410', '234', '10', 'England', 'O2'],
  ['23411', '234', '11', 'England', 'O2'],
  ['23411', '234', '11', 'England', 'O2'],
  ['23411', '234', '11', 'England', 'O2'],
  ['23411', '234', '11', 'England', 'O2'],
  ['23411', '234', '11', 'England', 'O2'],
  ['23411', '234', '11', 'England', 'O2'],
  ['23411', '234', '11', 'England', 'O2'],
  ['23411', '234', '11', 'England', 'O2'],
  ['23411', '234', '11', 'England', 'O2'],
  ['23411', '234', '11', 'England', 'O2'],
  ['23411', '234', '11', 'England', 'O2'],
  ['23411', '234', '11', 'England', 'O2'],
  ['23411', '234', '11', 'England', 'O2'],
  ['23411', '234', '11', 'England', 'O2'],
  ['23411', '234', '11', 'England', 'O2'],
  ['23411', '234', '11', 'England', 'O2'],
  ['23411', '234', '11', 'England', 'O2'],
  ['23411', '234', '11', 'England', 'O2'],
  ['23411', '234', '11', 'England', 'O2'],
  ['23411', '234', '11', 'England', 'O2'],
  ['23411', '234', '11', 'England', 'O2'],
  ['23411', '234', '11', 'England', 'O2'],
  ['23411', '234', '11', 'England', 'O2'],
  ['23411', '234', '11', 'England', 'O2'],
  ['23411', '234', '11', 'England', 'O2'],
  ['23411', '234', '11', 'England', 'O2'],
  ['23411', '234', '11', 'England', 'O2'],
  ['23411', '234', '11', 'England', 'O2'],
  ['23411', '234', '11', 'England', 'O2'],
  ['23411', '234', '11', 'England', 'O2'],
  ['23411', '234', '11', 'England', 'O2'],
  ['23411', '234', '11', 'England', 'O2'],
  ['23411', '234', '11', 'England', 'O2'],
  ['23411', '234', '11', 'England', 'O2'],
  ['23411', '234', '11', 'England', 'O2'],
  ['23411', '234', '11', 'England', 'O2'],
  ['23411', '234', '11', 'England', 'O2'],
  ['23411', '234', '11', 'England', 'O2'],
  ['23411', '234', '11', 'England', 'O2'],
  ['23411', '234', '11', 'England', 'O2'],
  ['23411', '234', '11', 'England', 'O2'],
  ['23411', '234', '11', 'England', 'O2'],
  ['23411', '234', '11', 'England', 'O2'],
  ['23411', '234', '11', 'England', 'O2'],
  ['23411', '234', '11', 'England', 'O2'],
  ['23411', '234', '11', 'England', 'O2'],
  ['23411', '234', '11', 'England', 'O2'],
  ['23411', '234', '11', 'England', 'O2'],
  ['23411', '234', '11', 'England', 'O2'],
  ['23411', '234', '11', 'England', 'O2'],
  ['23411', '234', '11', 'England', 'O2'],
  ['23411', '234', '11', 'England', 'O2'],
  ['23411', '234', '11', 'England', 'O2'],
  ['23411', '234', '11', 'England', 'O2'],
  ['23411', '234', '11', 'England', 'O2'],
  ['23411', '234', '11', 'England', 'O2'],
  ['23411', '234', '11', 'England', 'O2'],
  ['23411', '234', '11', 'England', 'O2'],
  ['23411', '234', '11', 'England', 'O2'],
  ['23411', '234', '11', 'England', 'O2'],
  ['23411', '234', '11', 'England', 'O2'],
  ['23411', '234', '11', 'England', 'O2'],
  ['23411', '234', '11', 'England', 'O2'],
  ['23411', '234', '11', 'England', 'O2'],
  ['23411', '234', '11', 'England', 'O2'],
  ['23411', '234', '11', 'England', 'O2'],
  ['23411', '234', '11', 'England', 'O2'],
  ['23411', '234', '11', 'England', 'O2'],
  ['23411', '234', '11', 'England', 'O2'],
  ['23411', '234', '11', 'England', 'O2'],
  ['23411', '234', '11', 'England', 'O2'],
  ['23411', '234', '11', 'England', 'O2'],
  ['23411', '234', '11', 'England', 'O2'],
  ['23411', '234', '11', 'England', 'O2'],
  ['23411', '234', '11', 'England', 'O2'],
  ['23411', '234', '11', 'England', 'O2'],
  ['23411', '234', '11', 'England', 'O2'],
  ['23411', '234', '11', 'England', 'O2'],
  ['23415', '234', '15', 'England', 'Vodafone'],
  ['23415', '234', '15', 'England', 'Vodafone'],
  ['23415', '234', '15', 'England', 'Vodafone'],
  ['23415', '234', '15', 'England', 'Vodafone'],
  ['23415', '234', '15', 'England', 'Vodafone'],
  ['23415', '234', '15', 'England', 'Vodafone'],
  ['23415', '234', '15', 'England', 'Vodafone'],
  ['23415', '234', '15', 'England', 'Vodafone'],
  ['23415', '234', '15', 'England', 'Vodafone'],
  ['23415', '234', '15', 'England', 'Vodafone'],
  ['23415', '234', '15', 'England', 'Vodafone'],
  ['23415', '234', '15', 'England', 'Vodafone'],
  ['23415', '234', '15', 'England', 'Vodafone'],
  ['23415', '234', '15', 'England', 'Vodafone'],
  ['23415', '234', '15', 'England', 'Vodafone'],
  ['23415', '234', '15', 'England', 'Vodafone'],
  ['23415', '234', '15', 'England', 'Vodafone'],
  ['23415', '234', '15', 'England', 'Vodafone'],
  ['23415', '234', '15', 'England', 'Vodafone'],
  ['23415', '234', '15', 'England', 'Vodafone'],
  ['23415', '234', '15', 'England', 'Vodafone'],
  ['23415', '234', '15', 'England', 'Vodafone'],
  ['23415', '234', '15', 'England', 'Vodafone'],
  ['23415', '234', '15', 'England', 'Vodafone'],
  ['23415', '234', '15', 'England', 'Vodafone'],
  ['23415', '234', '15', 'England', 'Vodafone'],
  ['23415', '234', '15', 'England', 'Vodafone'],
  ['23415', '234', '15', 'England', 'Vodafone'],
  ['23415', '234', '15', 'England', 'Vodafone'],
  ['23415', '234', '15', 'England', 'Vodafone'],
  ['23415', '234', '15', 'England', 'Vodafone'],
  ['23415', '234', '15', 'England', 'Vodafone'],
  ['23415', '234', '15', 'England', 'Vodafone'],
  ['23415', '234', '15', 'England', 'Vodafone'],
  ['23415', '234', '15', 'England', 'Vodafone'],
  ['23415', '234', '15', 'England', 'Vodafone'],
  ['23415', '234', '15', 'England', 'Vodafone'],
  ['23415', '234', '15', 'England', 'Vodafone'],
  ['23415', '234', '15', 'England', 'Vodafone'],
  ['23415', '234', '15', 'England', 'Vodafone'],
  ['23415', '234', '15', 'England', 'Vodafone'],
  ['23415', '234', '15', 'England', 'Vodafone'],
  ['23415', '234', '15', 'England', 'Vodafone'],
  ['23415', '234', '15', 'England', 'Vodafone'],
  ['23415', '234', '15', 'England', 'Vodafone'],
  ['23415', '234', '15', 'England', 'Vodafone'],
  ['23415', '234', '15', 'England', 'Vodafone'],
  ['23415', '234', '15', 'England', 'Vodafone'],
  ['23415', '234', '15', 'England', 'Vodafone'],
  ['23415', '234', '15', 'England', 'Vodafone'],
  ['23415', '234', '15', 'England', 'Vodafone'],
  ['23415', '234', '15', 'England', 'Vodafone'],
  ['23415', '234', '15', 'England', 'Vodafone'],
  ['23415', '234', '15', 'England', 'Vodafone'],
  ['23415', '234', '15', 'England', 'Vodafone'],
  ['23415', '234', '15', 'England', 'Vodafone'],
  ['23415', '234', '15', 'England', 'Vodafone'],
  ['23415', '234', '15', 'England', 'Vodafone'],
  ['23415', '234', '15', 'England', 'Vodafone'],
  ['23416', '234', '16', 'England', 'Opal Telecom (Talk talk)'],
  ['23416', '234', '16', 'England', 'Opal Telecom (Talk talk)'],
  ['23416', '234', '16', 'England', 'Opal Telecom (Talk talk)'],
  ['23416', '234', '16', 'England', 'Opal Telecom (Talk talk)'],
  ['23416', '234', '16', 'England', 'Opal Telecom (Talk talk)'],
  ['23416', '234', '16', 'England', 'Opal Telecom (Talk talk)'],
  ['23416', '234', '16', 'England', 'Opal Telecom (Talk talk)'],
  ['23416', '234', '16', 'England', 'Opal Telecom (Talk talk)'],
  ['23416', '234', '16', 'England', 'Opal Telecom (Talk talk)'],
  ['23418', '234', '18', 'England', 'Cloud 9'],
  ['23418', '234', '18', 'England', 'Cloud 9'],
  ['23418', '234', '18', 'England', 'Cloud 9'],
  ['23418', '234', '18', 'England', 'Cloud 9'],
  ['23418', '234', '18', 'England', 'Cloud 9'],
  ['23418', '234', '18', 'England', 'Cloud 9'],
  ['23418', '234', '18', 'England', 'Cloud 9'],
  ['23419', '234', '19', 'England', 'Teleware (plc)'],
  ['23420', '234', '20', 'England', 'Hutchison 3G'],
  ['23420', '234', '20', 'England', 'Hutchison 3G'],
  ['23420', '234', '20', 'England', 'Hutchison 3G'],
  ['23420', '234', '20', 'England', 'Hutchison 3G'],
  ['23420', '234', '20', 'England', 'Hutchison 3G'],
  ['23420', '234', '20', 'England', 'Hutchison 3G'],
  ['23420', '234', '20', 'England', 'Hutchison 3G'],
  ['23420', '234', '20', 'England', 'Hutchison 3G'],
  ['23420', '234', '20', 'England', 'Hutchison 3G'],
  ['23420', '234', '20', 'England', 'Hutchison 3G'],
  ['23420', '234', '20', 'England', 'Hutchison 3G'],
  ['23420', '234', '20', 'England', 'Hutchison 3G'],
  ['23420', '234', '20', 'England', 'Hutchison 3G'],
  ['23420', '234', '20', 'England', 'Hutchison 3G'],
  ['23420', '234', '20', 'England', 'Hutchison 3G'],
  ['23420', '234', '20', 'England', 'Hutchison 3G'],
  ['23420', '234', '20', 'England', 'Hutchison 3G'],
  ['23420', '234', '20', 'England', 'Hutchison 3G'],
  ['23420', '234', '20', 'England', 'Hutchison 3G'],
  ['23420', '234', '20', 'England', 'Hutchison 3G'],
  ['23420', '234', '20', 'England', 'Hutchison 3G'],
  ['23420', '234', '20', 'England', 'Hutchison 3G'],
  ['23420', '234', '20', 'England', 'Hutchison 3G'],
  ['23420', '234', '20', 'England', 'Hutchison 3G'],
  ['23420', '234', '20', 'England', 'Hutchison 3G'],
  ['23420', '234', '20', 'England', 'Hutchison 3G'],
  ['23420', '234', '20', 'England', 'Hutchison 3G'],
  ['23420', '234', '20', 'England', 'Hutchison 3G'],
  ['23420', '234', '20', 'England', 'Hutchison 3G'],
  ['23420', '234', '20', 'England', 'Hutchison 3G'],
  ['23420', '234', '20', 'England', 'Hutchison 3G'],
  ['23420', '234', '20', 'England', 'Hutchison 3G'],
  ['23420', '234', '20', 'England', 'Hutchison 3G'],
  ['23420', '234', '20', 'England', 'Hutchison 3G'],
  ['23420', '234', '20', 'England', 'Hutchison 3G'],
  ['23420', '234', '20', 'England', 'Hutchison 3G'],
  ['23420', '234', '20', 'England', 'Hutchison 3G'],
  ['23420', '234', '20', 'England', 'Hutchison 3G'],
  ['23420', '234', '20', 'England', 'Hutchison 3G'],
  ['23420', '234', '20', 'England', 'Hutchison 3G'],
  ['23420', '234', '20', 'England', 'Hutchison 3G'],
  ['23420', '234', '20', 'England', 'Hutchison 3G'],
  ['23420', '234', '20', 'England', 'Hutchison 3G'],
  ['23420', '234', '20', 'England', 'Hutchison 3G'],
  ['23420', '234', '20', 'England', 'Hutchison 3G'],
  ['23420', '234', '20', 'England', 'Hutchison 3G'],
  ['23420', '234', '20', 'England', 'Hutchison 3G'],
  ['23420', '234', '20', 'England', 'Hutchison 3G'],
  ['23420', '234', '20', 'England', 'Hutchison 3G'],
  ['23420', '234', '20', 'England', 'Hutchison 3G'],
  ['23420', '234', '20', 'England', 'Hutchison 3G'],
  ['23420', '234', '20', 'England', 'Hutchison 3G'],
  ['23420', '234', '20', 'England', 'Hutchison 3G'],
  ['23420', '234', '20', 'England', 'Hutchison 3G'],
  ['23420', '234', '20', 'England', 'Hutchison 3G'],
  ['23420', '234', '20', 'England', 'Hutchison 3G'],
  ['23420', '234', '20', 'England', 'Hutchison 3G'],
  ['23420', '234', '20', 'England', 'Hutchison 3G'],
  ['23420', '234', '20', 'England', 'Hutchison 3G'],
  ['23420', '234', '20', 'England', 'Hutchison 3G'],
  ['23420', '234', '20', 'England', 'Hutchison 3G'],
  ['23420', '234', '20', 'England', 'Hutchison 3G'],
  ['23420', '234', '20', 'England', 'Hutchison 3G'],
  ['23420', '234', '20', 'England', 'Hutchison 3G'],
  ['23420', '234', '20', 'England', 'Hutchison 3G'],
  ['23420', '234', '20', 'England', 'Hutchison 3G'],
  ['23420', '234', '20', 'England', 'Hutchison 3G'],
  ['23420', '234', '20', 'England', 'Hutchison 3G'],
  ['23420', '234', '20', 'England', 'Hutchison 3G'],
  ['23420', '234', '20', 'England', 'Hutchison 3G'],
  ['23420', '234', '20', 'England', 'Hutchison 3G'],
  ['23423', '234', '23', 'England', 'Vodafone'],
  ['23423', '234', '23', 'England', 'Vodafone'],
  ['23425', '234', '25', 'England', 'Truphone'],
  ['23425', '234', '25', 'England', 'Truphone'],
  ['23425', '234', '25', 'England', 'Truphone'],
  ['23425', '234', '25', 'England', 'Truphone'],
  ['23425', '234', '25', 'England', 'Truphone'],
  ['23425', '234', '25', 'England', 'Truphone'],
  ['23425', '234', '25', 'England', 'Truphone'],
  ['23425', '234', '25', 'England', 'Truphone'],
  ['23426', '234', '26', 'England', 'Lycamobile'],
  ['23426', '234', '26', 'England', 'Lycamobile'],
  ['23426', '234', '26', 'England', 'Lycamobile'],
  ['23426', '234', '26', 'England', 'Lycamobile'],
  ['23426', '234', '26', 'England', 'Lycamobile'],
  ['23426', '234', '26', 'England', 'Lycamobile'],
  ['23426', '234', '26', 'England', 'Lycamobile'],
  ['23426', '234', '26', 'England', 'Lycamobile'],
  ['23426', '234', '26', 'England', 'Lycamobile'],
  ['23426', '234', '26', 'England', 'Lycamobile'],
  ['23426', '234', '26', 'England', 'Lycamobile'],
  ['23430', '234', '30', 'England', 'EE (T-Mobile)'],
  ['23430', '234', '30', 'England', 'EE (T-Mobile)'],
  ['23430', '234', '30', 'England', 'EE (T-Mobile)'],
  ['23430', '234', '30', 'England', 'EE (T-Mobile)'],
  ['23430', '234', '30', 'England', 'EE (T-Mobile)'],
  ['23430', '234', '30', 'England', 'EE (T-Mobile)'],
  ['23430', '234', '30', 'England', 'EE (T-Mobile)'],
  ['23430', '234', '30', 'England', 'EE (T-Mobile)'],
  ['23430', '234', '30', 'England', 'EE (T-Mobile)'],
  ['23430', '234', '30', 'England', 'EE (T-Mobile)'],
  ['23430', '234', '30', 'England', 'EE (T-Mobile)'],
  ['23430', '234', '30', 'England', 'EE (T-Mobile)'],
  ['23430', '234', '30', 'England', 'EE (T-Mobile)'],
  ['23430', '234', '30', 'England', 'EE (T-Mobile)'],
  ['23430', '234', '30', 'England', 'EE (T-Mobile)'],
  ['23430', '234', '30', 'England', 'EE (T-Mobile)'],
  ['23430', '234', '30', 'England', 'EE (T-Mobile)'],
  ['23430', '234', '30', 'England', 'EE (T-Mobile)'],
  ['23430', '234', '30', 'England', 'EE (T-Mobile)'],
  ['23430', '234', '30', 'England', 'EE (T-Mobile)'],
  ['23430', '234', '30', 'England', 'EE (T-Mobile)'],
  ['23430', '234', '30', 'England', 'EE (T-Mobile)'],
  ['23430', '234', '30', 'England', 'EE (T-Mobile)'],
  ['23430', '234', '30', 'England', 'EE (T-Mobile)'],
  ['23430', '234', '30', 'England', 'EE (T-Mobile)'],
  ['23430', '234', '30', 'England', 'EE (T-Mobile)'],
  ['23430', '234', '30', 'England', 'EE (T-Mobile)'],
  ['23430', '234', '30', 'England', 'EE (T-Mobile)'],
  ['23430', '234', '30', 'England', 'EE (T-Mobile)'],
  ['23430', '234', '30', 'England', 'EE (T-Mobile)'],
  ['23430', '234', '30', 'England', 'EE (T-Mobile)'],
  ['23430', '234', '30', 'England', 'EE (T-Mobile)'],
  ['23430', '234', '30', 'England', 'EE (T-Mobile)'],
  ['23430', '234', '30', 'England', 'EE (T-Mobile)'],
  ['23430', '234', '30', 'England', 'EE (T-Mobile)'],
  ['23430', '234', '30', 'England', 'EE (T-Mobile)'],
  ['23430', '234', '30', 'England', 'EE (T-Mobile)'],
  ['23430', '234', '30', 'England', 'EE (T-Mobile)'],
  ['23430', '234', '30', 'England', 'EE (T-Mobile)'],
  ['23430', '234', '30', 'England', 'EE (T-Mobile)'],
  ['23430', '234', '30', 'England', 'EE (T-Mobile)'],
  ['23430', '234', '30', 'England', 'EE (T-Mobile)'],
  ['23431', '234', '31', 'England', 'EE (T-Mobile)'],
  ['23431', '234', '31', 'England', 'EE (T-Mobile)'],
  ['23431', '234', '31', 'England', 'EE (T-Mobile)'],
  ['23431', '234', '31', 'England', 'EE (T-Mobile)'],
  ['23431', '234', '31', 'England', 'EE (T-Mobile)'],
  ['23431', '234', '31', 'England', 'EE (T-Mobile)'],
  ['23431', '234', '31', 'England', 'EE (T-Mobile)'],
  ['23431', '234', '31', 'England', 'EE (T-Mobile)'],
  ['23431', '234', '31', 'England', 'EE (T-Mobile)'],
  ['23431', '234', '31', 'England', 'EE (T-Mobile)'],
  ['23431', '234', '31', 'England', 'EE (T-Mobile)'],
  ['23431', '234', '31', 'England', 'EE (T-Mobile)'],
  ['23431', '234', '31', 'England', 'EE (T-Mobile)'],
  ['23431', '234', '31', 'England', 'EE (T-Mobile)'],
  ['23431', '234', '31', 'England', 'EE (T-Mobile)'],
  ['23431', '234', '31', 'England', 'EE (T-Mobile)'],
  ['23431', '234', '31', 'England', 'EE (T-Mobile)'],
  ['23431', '234', '31', 'England', 'EE (T-Mobile)'],
  ['23431', '234', '31', 'England', 'EE (T-Mobile)'],
  ['23431', '234', '31', 'England', 'EE (T-Mobile)'],
  ['23431', '234', '31', 'England', 'EE (T-Mobile)'],
  ['23431', '234', '31', 'England', 'EE (T-Mobile)'],
  ['23431', '234', '31', 'England', 'EE (T-Mobile)'],
  ['23431', '234', '31', 'England', 'EE (T-Mobile)'],
  ['23431', '234', '31', 'England', 'EE (T-Mobile)'],
  ['23431', '234', '31', 'England', 'EE (T-Mobile)'],
  ['23431', '234', '31', 'England', 'EE (T-Mobile)'],
  ['23431', '234', '31', 'England', 'EE (T-Mobile)'],
  ['23431', '234', '31', 'England', 'EE (T-Mobile)'],
  ['23431', '234', '31', 'England', 'EE (T-Mobile)'],
  ['23431', '234', '31', 'England', 'EE (T-Mobile)'],
  ['23431', '234', '31', 'England', 'EE (T-Mobile)'],
  ['23431', '234', '31', 'England', 'EE (T-Mobile)'],
  ['23431', '234', '31', 'England', 'EE (T-Mobile)'],
  ['23431', '234', '31', 'England', 'EE (T-Mobile)'],
  ['23431', '234', '31', 'England', 'EE (T-Mobile)'],
  ['23431', '234', '31', 'England', 'EE (T-Mobile)'],
  ['23431', '234', '31', 'England', 'EE (T-Mobile)'],
  ['23431', '234', '31', 'England', 'EE (T-Mobile)'],
  ['23431', '234', '31', 'England', 'EE (T-Mobile)'],
  ['23431', '234', '31', 'England', 'EE (T-Mobile)'],
  ['23431', '234', '31', 'England', 'EE (T-Mobile)'],
  ['23432', '234', '32', 'England', 'EE (T-Mobile)'],
  ['23432', '234', '32', 'England', 'EE (T-Mobile)'],
  ['23432', '234', '32', 'England', 'EE (T-Mobile)'],
  ['23432', '234', '32', 'England', 'EE (T-Mobile)'],
  ['23432', '234', '32', 'England', 'EE (T-Mobile)'],
  ['23432', '234', '32', 'England', 'EE (T-Mobile)'],
  ['23432', '234', '32', 'England', 'EE (T-Mobile)'],
  ['23432', '234', '32', 'England', 'EE (T-Mobile)'],
  ['23432', '234', '32', 'England', 'EE (T-Mobile)'],
  ['23432', '234', '32', 'England', 'EE (T-Mobile)'],
  ['23432', '234', '32', 'England', 'EE (T-Mobile)'],
  ['23432', '234', '32', 'England', 'EE (T-Mobile)'],
  ['23432', '234', '32', 'England', 'EE (T-Mobile)'],
  ['23432', '234', '32', 'England', 'EE (T-Mobile)'],
  ['23432', '234', '32', 'England', 'EE (T-Mobile)'],
  ['23432', '234', '32', 'England', 'EE (T-Mobile)'],
  ['23432', '234', '32', 'England', 'EE (T-Mobile)'],
  ['23432', '234', '32', 'England', 'EE (T-Mobile)'],
  ['23432', '234', '32', 'England', 'EE (T-Mobile)'],
  ['23432', '234', '32', 'England', 'EE (T-Mobile)'],
  ['23432', '234', '32', 'England', 'EE (T-Mobile)'],
  ['23432', '234', '32', 'England', 'EE (T-Mobile)'],
  ['23432', '234', '32', 'England', 'EE (T-Mobile)'],
  ['23432', '234', '32', 'England', 'EE (T-Mobile)'],
  ['23432', '234', '32', 'England', 'EE (T-Mobile)'],
  ['23432', '234', '32', 'England', 'EE (T-Mobile)'],
  ['23432', '234', '32', 'England', 'EE (T-Mobile)'],
  ['23432', '234', '32', 'England', 'EE (T-Mobile)'],
  ['23432', '234', '32', 'England', 'EE (T-Mobile)'],
  ['23432', '234', '32', 'England', 'EE (T-Mobile)'],
  ['23432', '234', '32', 'England', 'EE (T-Mobile)'],
  ['23432', '234', '32', 'England', 'EE (T-Mobile)'],
  ['23432', '234', '32', 'England', 'EE (T-Mobile)'],
  ['23432', '234', '32', 'England', 'EE (T-Mobile)'],
  ['23432', '234', '32', 'England', 'EE (T-Mobile)'],
  ['23432', '234', '32', 'England', 'EE (T-Mobile)'],
  ['23432', '234', '32', 'England', 'EE (T-Mobile)'],
  ['23432', '234', '32', 'England', 'EE (T-Mobile)'],
  ['23432', '234', '32', 'England', 'EE (T-Mobile)'],
  ['23432', '234', '32', 'England', 'EE (T-Mobile)'],
  ['23432', '234', '32', 'England', 'EE (T-Mobile)'],
  ['23432', '234', '32', 'England', 'EE (T-Mobile)'],
  ['23433', '234', '33', 'England', 'EE (Orange)'],
  ['23433', '234', '33', 'England', 'EE (Orange)'],
  ['23433', '234', '33', 'England', 'EE (Orange)'],
  ['23433', '234', '33', 'England', 'EE (Orange)'],
  ['23433', '234', '33', 'England', 'EE (Orange)'],
  ['23433', '234', '33', 'England', 'EE (Orange)'],
  ['23433', '234', '33', 'England', 'EE (Orange)'],
  ['23433', '234', '33', 'England', 'EE (Orange)'],
  ['23433', '234', '33', 'England', 'EE (Orange)'],
  ['23433', '234', '33', 'England', 'EE (Orange)'],
  ['23433', '234', '33', 'England', 'EE (Orange)'],
  ['23433', '234', '33', 'England', 'EE (Orange)'],
  ['23433', '234', '33', 'England', 'EE (Orange)'],
  ['23433', '234', '33', 'England', 'EE (Orange)'],
  ['23433', '234', '33', 'England', 'EE (Orange)'],
  ['23433', '234', '33', 'England', 'EE (Orange)'],
  ['23433', '234', '33', 'England', 'EE (Orange)'],
  ['23433', '234', '33', 'England', 'EE (Orange)'],
  ['23433', '234', '33', 'England', 'EE (Orange)'],
  ['23433', '234', '33', 'England', 'EE (Orange)'],
  ['23433', '234', '33', 'England', 'EE (Orange)'],
  ['23433', '234', '33', 'England', 'EE (Orange)'],
  ['23433', '234', '33', 'England', 'EE (Orange)'],
  ['23433', '234', '33', 'England', 'EE (Orange)'],
  ['23433', '234', '33', 'England', 'EE (Orange)'],
  ['23433', '234', '33', 'England', 'EE (Orange)'],
  ['23433', '234', '33', 'England', 'EE (Orange)'],
  ['23433', '234', '33', 'England', 'EE (Orange)'],
  ['23433', '234', '33', 'England', 'EE (Orange)'],
  ['23433', '234', '33', 'England', 'EE (Orange)'],
  ['23434', '234', '34', 'England', 'EE (Orange)'],
  ['23434', '234', '34', 'England', 'EE (Orange)'],
  ['23434', '234', '34', 'England', 'EE (Orange)'],
  ['23434', '234', '34', 'England', 'EE (Orange)'],
  ['23434', '234', '34', 'England', 'EE (Orange)'],
  ['23434', '234', '34', 'England', 'EE (Orange)'],
  ['23434', '234', '34', 'England', 'EE (Orange)'],
  ['23434', '234', '34', 'England', 'EE (Orange)'],
  ['23434', '234', '34', 'England', 'EE (Orange)'],
  ['23434', '234', '34', 'England', 'EE (Orange)'],
  ['23434', '234', '34', 'England', 'EE (Orange)'],
  ['23434', '234', '34', 'England', 'EE (Orange)'],
  ['23434', '234', '34', 'England', 'EE (Orange)'],
  ['23434', '234', '34', 'England', 'EE (Orange)'],
  ['23434', '234', '34', 'England', 'EE (Orange)'],
  ['23434', '234', '34', 'England', 'EE (Orange)'],
  ['23434', '234', '34', 'England', 'EE (Orange)'],
  ['23434', '234', '34', 'England', 'EE (Orange)'],
  ['23434', '234', '34', 'England', 'EE (Orange)'],
  ['23434', '234', '34', 'England', 'EE (Orange)'],
  ['23434', '234', '34', 'England', 'EE (Orange)'],
  ['23434', '234', '34', 'England', 'EE (Orange)'],
  ['23434', '234', '34', 'England', 'EE (Orange)'],
  ['23434', '234', '34', 'England', 'EE (Orange)'],
  ['23434', '234', '34', 'England', 'EE (Orange)'],
  ['23434', '234', '34', 'England', 'EE (Orange)'],
  ['23434', '234', '34', 'England', 'EE (Orange)'],
  ['23434', '234', '34', 'England', 'EE (Orange)'],
  ['23434', '234', '34', 'England', 'EE (Orange)'],
  ['23434', '234', '34', 'England', 'EE (Orange)'],
  ['23438', '234', '38', 'England', 'Virgin'],
  ['23450', '234', '50', 'England', 'Jersey Telecom (JT-Wave)'],
  ['23450', '234', '50', 'England', 'Jersey Telecom (JT-Wave)'],
  ['23450', '234', '50', 'England', 'Jersey Telecom (JT-Wave)'],
  ['23450', '234', '50', 'England', 'Jersey Telecom (JT-Wave)'],
  ['23450', '234', '50', 'England', 'Jersey Telecom (JT-Wave)'],
  ['23450', '234', '50', 'England', 'Jersey Telecom (JT-Wave)'],
  ['23450', '234', '50', 'England', 'Jersey Telecom (JT-Wave)'],
  ['23450', '234', '50', 'England', 'Jersey Telecom (JT-Wave)'],
  ['23450', '234', '50', 'England', 'Jersey Telecom (JT-Wave)'],
  ['23450', '234', '50', 'England', 'Jersey Telecom (JT-Wave)'],
  ['23450', '234', '50', 'England', 'Jersey Telecom (JT-Wave)'],
  ['23453', '234', '53', 'England', 'Limitless'],
  ['23453', '234', '53', 'England', 'Limitless'],
  ['23453', '234', '53', 'England', 'Limitless'],
  ['23455', '234', '55', 'England', 'Cable & Wireless Guernsey/Sure'],
  ['23455', '234', '55', 'England', 'Cable & Wireless Guernsey/Sure'],
  ['23455', '234', '55', 'England', 'Cable & Wireless Guernsey/Sure'],
  ['23455', '234', '55', 'England', 'Cable & Wireless Guernsey/Sure'],
  ['23455', '234', '55', 'England', 'Cable & Wireless Guernsey/Sure'],
  ['23455', '234', '55', 'England', 'Cable & Wireless Guernsey/Sure'],
  ['23455', '234', '55', 'England', 'Cable & Wireless Guernsey/Sure'],
  ['23458', '234', '58', 'England', 'Manx'],
  ['23458', '234', '58', 'England', 'Manx'],
  ['23458', '234', '58', 'England', 'Manx'],
  ['23476', '234', '76', 'England', 'British Telecom'],
  ['6270', '627', '0', 'Equatorial Guinea', 'DEFAULT EQUATORIAL GUINEA'],
  ['62701', '627', '1', 'Equatorial Guinea', 'GETESA'],
  ['62701', '627', '1', 'Equatorial Guinea', 'GETESA'],
  ['62701', '627', '1', 'Equatorial Guinea', 'GETESA'],
  ['62701', '627', '1', 'Equatorial Guinea', 'GETESA'],
  ['62701', '627', '1', 'Equatorial Guinea', 'GETESA'],
  ['62703', '627', '3', 'Equatorial Guinea', 'Green Com HiTs'],
  ['6570', '657', '0', 'Eritrea', 'Eritel'],
  ['65701', '657', '1', 'Eritrea', 'Eritel'],
  ['65701', '657', '1', 'Eritrea', 'Eritel'],
  ['2480', '248', '0', 'Estonia', 'DEFAULT ESTONIA'],
  ['24801', '248', '1', 'Estonia', 'EMT'],
  ['24801', '248', '1', 'Estonia', 'EMT'],
  ['24801', '248', '1', 'Estonia', 'EMT'],
  ['24801', '248', '1', 'Estonia', 'EMT'],
  ['24802', '248', '2', 'Estonia', 'RADIOLINJA'],
  ['24802', '248', '2', 'Estonia', 'RADIOLINJA'],
  ['24802', '248', '2', 'Estonia', 'RADIOLINJA'],
  ['24802', '248', '2', 'Estonia', 'RADIOLINJA'],
  ['24803', '248', '3', 'Estonia', 'TELE2'],
  ['24803', '248', '3', 'Estonia', 'TELE2'],
  ['24805', '248', '5', 'Estonia', 'Elisa Eesti'],
  ['24805', '248', '5', 'Estonia', 'Elisa Eesti'],
  ['24805', '248', '5', 'Estonia', 'Elisa Eesti'],
  ['24805', '248', '5', 'Estonia', 'Elisa Eesti'],
  ['24805', '248', '5', 'Estonia', 'Elisa Eesti'],
  ['24805', '248', '5', 'Estonia', 'Elisa Eesti'],
  ['24805', '248', '5', 'Estonia', 'Elisa Eesti'],
  ['24805', '248', '5', 'Estonia', 'Elisa Eesti'],
  ['24805', '248', '5', 'Estonia', 'Elisa Eesti'],
  ['24805', '248', '5', 'Estonia', 'Elisa Eesti'],
  ['24805', '248', '5', 'Estonia', 'Elisa Eesti'],
  ['24805', '248', '5', 'Estonia', 'Elisa Eesti'],
  ['6360', '636', '0', 'Ethiopia', 'DEFAULT ETHIOPIA'],
  ['63601', '636', '1', 'Ethiopia', 'ETHIO-TELECOM'],
  ['63601', '636', '1', 'Ethiopia', 'ETHIO-TELECOM'],
  ['63601', '636', '1', 'Ethiopia', 'ETHIO-TELECOM'],
  ['63601', '636', '1', 'Ethiopia', 'ETHIO-TELECOM'],
  ['63601', '636', '1', 'Ethiopia', 'ETHIO-TELECOM'],
  ['63601', '636', '1', 'Ethiopia', 'ETHIO-TELECOM'],
  ['63601', '636', '1', 'Ethiopia', 'ETHIO-TELECOM'],
  ['2880', '288', '0', 'Faeroe Islands', 'DEFAULT FAROE ISLANDS'],
  ['28801', '288', '1', 'Faeroe Islands', 'FAROESE TELECOM'],
  ['28801', '288', '1', 'Faeroe Islands', 'FAROESE TELECOM'],
  ['28801', '288', '1', 'Faeroe Islands', 'FAROESE TELECOM'],
  ['28801', '288', '1', 'Faeroe Islands', 'FAROESE TELECOM'],
  ['28801', '288', '1', 'Faeroe Islands', 'FAROESE TELECOM'],
  ['28801', '288', '1', 'Faeroe Islands', 'FAROESE TELECOM'],
  ['28801', '288', '1', 'Faeroe Islands', 'FAROESE TELECOM'],
  ['28801', '288', '1', 'Faeroe Islands', 'FAROESE TELECOM'],
  ['28801', '288', '1', 'Faeroe Islands', 'FAROESE TELECOM'],
  ['28802', '288', '2', 'Faeroe Islands', 'KALL-GSM'],
  ['28802', '288', '2', 'Faeroe Islands', 'KALL-GSM'],
  ['5420', '542', '0', 'Fiji', 'DEFAULT FIJI'],
  ['54201', '542', '1', 'Fiji', 'VODAFONE'],
  ['54201', '542', '1', 'Fiji', 'VODAFONE'],
  ['54201', '542', '1', 'Fiji', 'VODAFONE'],
  ['54201', '542', '1', 'Fiji', 'VODAFONE'],
  ['54201', '542', '1', 'Fiji', 'VODAFONE'],
  ['54201', '542', '1', 'Fiji', 'VODAFONE'],
  ['54201', '542', '1', 'Fiji', 'VODAFONE'],
  ['54201', '542', '1', 'Fiji', 'VODAFONE'],
  ['54201', '542', '1', 'Fiji', 'VODAFONE'],
  ['54201', '542', '1', 'Fiji', 'VODAFONE'],
  ['2440', '244', '0', 'Finland', 'DEFAULT FINLAND'],
  ['24403', '244', '3', 'Finland', 'TELIA'],
  ['24405', '244', '5', 'Finland', 'TELE 2'],
  ['24405', '244', '5', 'Finland', 'ELISA'],
  ['24405', '244', '5', 'Finland', 'ELISA'],
  ['24412', '244', '12', 'Finland', 'FINNET'],
  ['24412', '244', '12', 'Finland', 'DNA'],
  ['24412', '244', '12', 'Finland', 'FINNET'],
  ['24412', '244', '12', 'Finland', 'FINNET'],
  ['24412', '244', '12', 'Finland', 'FINNET'],
  ['24412', '244', '12', 'Finland', 'FINNET'],
  ['24413', '244', '13', 'Finland', 'DNA (Finnet)'],
  ['24413', '244', '13', 'Finland', 'DNA (Finnet)'],
  ['24413', '244', '13', 'Finland', 'DNA (Finnet)'],
  ['24414', '244', '14', 'Finland', 'ALANDS'],
  ['24414', '244', '14', 'Finland', 'ALANDS'],
  ['24414', '244', '14', 'Finland', 'GLOBAL NETWORKS'],
  ['24414', '244', '14', 'Finland', 'ALANDS'],
  ['24421', '244', '21', 'Finland', 'SAUNALAHTI'],
  ['24421', '244', '21', 'Finland', 'SAUNALAHTI'],
  ['24491', '244', '91', 'Finland', 'SONERA'],
  ['24491', '244', '91', 'Finland', 'SONERA'],
  ['24491', '244', '91', 'Finland', 'SONERA'],
  ['2080', '208', '0', 'France', 'DEFAULT FRANCE'],
  ['20801', '208', '1', 'France', 'FTMI ORANGE'],
  ['20801', '208', '1', 'France', 'FTMI ORANGE'],
  ['20801', '208', '1', 'France', 'FTMI ORANGE'],
  ['20801', '208', '1', 'France', 'FTMI ORANGE'],
  ['20801', '208', '1', 'France', 'FTMI ORANGE'],
  ['20801', '208', '1', 'France', 'FTMI ORANGE'],
  ['20801', '208', '1', 'France', 'FTMI ORANGE'],
  ['20801', '208', '1', 'France', 'FTMI ORANGE'],
  ['20801', '208', '1', 'France', 'FTMI ORANGE'],
  ['20801', '208', '1', 'France', 'FTMI ORANGE'],
  ['20801', '208', '1', 'France', 'FTMI ORANGE'],
  ['20801', '208', '1', 'France', 'FTMI ORANGE'],
  ['20801', '208', '1', 'France', 'FTMI ORANGE'],
  ['20801', '208', '1', 'France', 'FTMI ORANGE'],
  ['20801', '208', '1', 'France', 'FTMI ORANGE'],
  ['20801', '208', '1', 'France', 'FTMI ORANGE'],
  ['20801', '208', '1', 'France', 'FTMI ORANGE'],
  ['20801', '208', '1', 'France', 'FTMI ORANGE'],
  ['20801', '208', '1', 'France', 'FTMI ORANGE'],
  ['20801', '208', '1', 'France', 'FTMI ORANGE'],
  ['20801', '208', '1', 'France', 'FTMI ORANGE'],
  ['20801', '208', '1', 'France', 'FTMI ORANGE'],
  ['20801', '208', '1', 'France', 'FTMI ORANGE'],
  ['20801', '208', '1', 'France', 'FTMI ORANGE'],
  ['20801', '208', '1', 'France', 'FTMI ORANGE'],
  ['20801', '208', '1', 'France', 'FTMI ORANGE'],
  ['20801', '208', '1', 'France', 'FTMI ORANGE'],
  ['20801', '208', '1', 'France', 'FTMI ORANGE'],
  ['20801', '208', '1', 'France', 'FTMI ORANGE'],
  ['20802', '208', '2', 'France', 'Orange'],
  ['20802', '208', '2', 'France', 'Orange'],
  ['20802', '208', '2', 'France', 'Orange'],
  ['20802', '208', '2', 'France', 'Orange'],
  ['20802', '208', '2', 'France', 'Orange'],
  ['20802', '208', '2', 'France', 'Orange'],
  ['20802', '208', '2', 'France', 'Orange'],
  ['20802', '208', '2', 'France', 'Orange'],
  ['20802', '208', '2', 'France', 'Orange'],
  ['20802', '208', '2', 'France', 'Orange'],
  ['20802', '208', '2', 'France', 'Orange'],
  ['20802', '208', '2', 'France', 'Orange'],
  ['20802', '208', '2', 'France', 'Orange'],
  ['20802', '208', '2', 'France', 'Orange'],
  ['20802', '208', '2', 'France', 'Orange'],
  ['20802', '208', '2', 'France', 'Orange'],
  ['20802', '208', '2', 'France', 'Orange'],
  ['20802', '208', '2', 'France', 'Orange'],
  ['20802', '208', '2', 'France', 'Orange'],
  ['20802', '208', '2', 'France', 'Orange'],
  ['20802', '208', '2', 'France', 'Orange'],
  ['20802', '208', '2', 'France', 'Orange'],
  ['20802', '208', '2', 'France', 'Orange'],
  ['20802', '208', '2', 'France', 'Orange'],
  ['20802', '208', '2', 'France', 'Orange'],
  ['20803', '208', '3', 'France', 'MobiquiThings'],
  ['20803', '208', '3', 'France', 'MobiquiThings'],
  ['20803', '208', '3', 'France', 'MobiquiThings'],
  ['20803', '208', '3', 'France', 'MobiquiThings'],
  ['20803', '208', '3', 'France', 'MobiquiThings'],
  ['20803', '208', '3', 'France', 'MobiquiThings'],
  ['20803', '208', '3', 'France', 'MobiquiThings'],
  ['20803', '208', '3', 'France', 'MobiquiThings'],
  ['20803', '208', '3', 'France', 'MobiquiThings'],
  ['20803', '208', '3', 'France', 'MobiquiThings'],
  ['20803', '208', '3', 'France', 'MobiquiThings'],
  ['20803', '208', '3', 'France', 'MobiquiThings'],
  ['20803', '208', '3', 'France', 'MobiquiThings'],
  ['20806', '208', '6', 'France', 'NRJ Mobile'],
  ['20806', '208', '6', 'France', 'NRJ Mobile'],
  ['20806', '208', '6', 'France', 'NRJ Mobile'],
  ['20806', '208', '6', 'France', 'NRJ Mobile'],
  ['20806', '208', '6', 'France', 'NRJ Mobile'],
  ['20806', '208', '6', 'France', 'NRJ Mobile'],
  ['20806', '208', '6', 'France', 'NRJ Mobile'],
  ['20806', '208', '6', 'France', 'NRJ Mobile'],
  ['20809', '208', '9', 'France', 'SFR'],
  ['20809', '208', '9', 'France', 'SFR'],
  ['20809', '208', '9', 'France', 'SFR'],
  ['20809', '208', '9', 'France', 'SFR'],
  ['20809', '208', '9', 'France', 'SFR'],
  ['20809', '208', '9', 'France', 'SFR'],
  ['20809', '208', '9', 'France', 'SFR'],
  ['20809', '208', '9', 'France', 'SFR'],
  ['20809', '208', '9', 'France', 'SFR'],
  ['20809', '208', '9', 'France', 'SFR'],
  ['20809', '208', '9', 'France', 'SFR'],
  ['20809', '208', '9', 'France', 'SFR'],
  ['20809', '208', '9', 'France', 'SFR'],
  ['20809', '208', '9', 'France', 'SFR'],
  ['20809', '208', '9', 'France', 'SFR'],
  ['20809', '208', '9', 'France', 'SFR'],
  ['20809', '208', '9', 'France', 'SFR'],
  ['20809', '208', '9', 'France', 'SFR'],
  ['20809', '208', '9', 'France', 'SFR'],
  ['20809', '208', '9', 'France', 'SFR'],
  ['20809', '208', '9', 'France', 'SFR'],
  ['20809', '208', '9', 'France', 'SFR'],
  ['20809', '208', '9', 'France', 'SFR'],
  ['20809', '208', '9', 'France', 'SFR'],
  ['20809', '208', '9', 'France', 'SFR'],
  ['20809', '208', '9', 'France', 'SFR'],
  ['20809', '208', '9', 'France', 'SFR'],
  ['20809', '208', '9', 'France', 'SFR'],
  ['20809', '208', '9', 'France', 'SFR'],
  ['20809', '208', '9', 'France', 'SFR'],
  ['20809', '208', '9', 'France', 'SFR'],
  ['20809', '208', '9', 'France', 'SFR'],
  ['20809', '208', '9', 'France', 'SFR'],
  ['20809', '208', '9', 'France', 'SFR'],
  ['20809', '208', '9', 'France', 'SFR'],
  ['20809', '208', '9', 'France', 'SFR'],
  ['20809', '208', '9', 'France', 'SFR'],
  ['20809', '208', '9', 'France', 'SFR'],
  ['20809', '208', '9', 'France', 'SFR'],
  ['20809', '208', '9', 'France', 'SFR'],
  ['20809', '208', '9', 'France', 'SFR'],
  ['20809', '208', '9', 'France', 'SFR'],
  ['20809', '208', '9', 'France', 'SFR'],
  ['20809', '208', '9', 'France', 'SFR'],
  ['20809', '208', '9', 'France', 'SFR'],
  ['20809', '208', '9', 'France', 'SFR'],
  ['20809', '208', '9', 'France', 'SFR'],
  ['20810', '208', '10', 'France', 'SFR'],
  ['20810', '208', '10', 'France', 'SFR'],
  ['20810', '208', '10', 'France', 'SFR'],
  ['20810', '208', '10', 'France', 'SFR'],
  ['20810', '208', '10', 'France', 'SFR'],
  ['20810', '208', '10', 'France', 'SFR'],
  ['20810', '208', '10', 'France', 'SFR'],
  ['20810', '208', '10', 'France', 'SFR'],
  ['20810', '208', '10', 'France', 'SFR'],
  ['20810', '208', '10', 'France', 'SFR'],
  ['20810', '208', '10', 'France', 'SFR'],
  ['20810', '208', '10', 'France', 'SFR'],
  ['20810', '208', '10', 'France', 'SFR'],
  ['20810', '208', '10', 'France', 'SFR'],
  ['20810', '208', '10', 'France', 'SFR'],
  ['20810', '208', '10', 'France', 'SFR'],
  ['20810', '208', '10', 'France', 'SFR'],
  ['20810', '208', '10', 'France', 'SFR'],
  ['20810', '208', '10', 'France', 'SFR'],
  ['20810', '208', '10', 'France', 'SFR'],
  ['20810', '208', '10', 'France', 'SFR'],
  ['20811', '208', '11', 'France', 'SFR'],
  ['20811', '208', '11', 'France', 'SFR'],
  ['20811', '208', '11', 'France', 'SFR'],
  ['20811', '208', '11', 'France', 'SFR'],
  ['20811', '208', '11', 'France', 'SFR'],
  ['20811', '208', '11', 'France', 'SFR'],
  ['20811', '208', '11', 'France', 'SFR'],
  ['20811', '208', '11', 'France', 'SFR'],
  ['20811', '208', '11', 'France', 'SFR'],
  ['20811', '208', '11', 'France', 'SFR'],
  ['20811', '208', '11', 'France', 'SFR'],
  ['20811', '208', '11', 'France', 'SFR'],
  ['20811', '208', '11', 'France', 'SFR'],
  ['20811', '208', '11', 'France', 'SFR'],
  ['20811', '208', '11', 'France', 'SFR'],
  ['20811', '208', '11', 'France', 'SFR'],
  ['20811', '208', '11', 'France', 'SFR'],
  ['20811', '208', '11', 'France', 'SFR'],
  ['20811', '208', '11', 'France', 'SFR'],
  ['20811', '208', '11', 'France', 'SFR'],
  ['20811', '208', '11', 'France', 'SFR'],
  ['20811', '208', '11', 'France', 'SFR'],
  ['20811', '208', '11', 'France', 'SFR'],
  ['20811', '208', '11', 'France', 'SFR'],
  ['20811', '208', '11', 'France', 'SFR'],
  ['20811', '208', '11', 'France', 'SFR'],
  ['20811', '208', '11', 'France', 'SFR'],
  ['20811', '208', '11', 'France', 'SFR'],
  ['20811', '208', '11', 'France', 'SFR'],
  ['20811', '208', '11', 'France', 'SFR'],
  ['20811', '208', '11', 'France', 'SFR'],
  ['20811', '208', '11', 'France', 'SFR'],
  ['20811', '208', '11', 'France', 'SFR'],
  ['20811', '208', '11', 'France', 'SFR'],
  ['20811', '208', '11', 'France', 'SFR'],
  ['20811', '208', '11', 'France', 'SFR'],
  ['20811', '208', '11', 'France', 'SFR'],
  ['20811', '208', '11', 'France', 'SFR'],
  ['20811', '208', '11', 'France', 'SFR'],
  ['20811', '208', '11', 'France', 'SFR'],
  ['20811', '208', '11', 'France', 'SFR'],
  ['20811', '208', '11', 'France', 'SFR'],
  ['20811', '208', '11', 'France', 'SFR'],
  ['20811', '208', '11', 'France', 'SFR'],
  ['20811', '208', '11', 'France', 'SFR'],
  ['20811', '208', '11', 'France', 'SFR'],
  ['20811', '208', '11', 'France', 'SFR'],
  ['20813', '208', '13', 'France', 'SFR'],
  ['20813', '208', '13', 'France', 'SFR'],
  ['20813', '208', '13', 'France', 'SFR'],
  ['20813', '208', '13', 'France', 'SFR'],
  ['20813', '208', '13', 'France', 'SFR'],
  ['20813', '208', '13', 'France', 'SFR'],
  ['20813', '208', '13', 'France', 'SFR'],
  ['20813', '208', '13', 'France', 'SFR'],
  ['20813', '208', '13', 'France', 'SFR'],
  ['20813', '208', '13', 'France', 'SFR'],
  ['20813', '208', '13', 'France', 'SFR'],
  ['20813', '208', '13', 'France', 'SFR'],
  ['20813', '208', '13', 'France', 'SFR'],
  ['20813', '208', '13', 'France', 'SFR'],
  ['20813', '208', '13', 'France', 'SFR'],
  ['20813', '208', '13', 'France', 'SFR'],
  ['20813', '208', '13', 'France', 'SFR'],
  ['20813', '208', '13', 'France', 'SFR'],
  ['20813', '208', '13', 'France', 'SFR'],
  ['20813', '208', '13', 'France', 'SFR'],
  ['20813', '208', '13', 'France', 'SFR'],
  ['20813', '208', '13', 'France', 'SFR'],
  ['20813', '208', '13', 'France', 'SFR'],
  ['20813', '208', '13', 'France', 'SFR'],
  ['20813', '208', '13', 'France', 'SFR'],
  ['20813', '208', '13', 'France', 'SFR'],
  ['20813', '208', '13', 'France', 'SFR'],
  ['20813', '208', '13', 'France', 'SFR'],
  ['20813', '208', '13', 'France', 'SFR'],
  ['20813', '208', '13', 'France', 'SFR'],
  ['20813', '208', '13', 'France', 'SFR'],
  ['20813', '208', '13', 'France', 'SFR'],
  ['20813', '208', '13', 'France', 'SFR'],
  ['20813', '208', '13', 'France', 'SFR'],
  ['20813', '208', '13', 'France', 'SFR'],
  ['20813', '208', '13', 'France', 'SFR'],
  ['20813', '208', '13', 'France', 'SFR'],
  ['20813', '208', '13', 'France', 'SFR'],
  ['20813', '208', '13', 'France', 'SFR'],
  ['20813', '208', '13', 'France', 'SFR'],
  ['20813', '208', '13', 'France', 'SFR'],
  ['20813', '208', '13', 'France', 'SFR'],
  ['20813', '208', '13', 'France', 'SFR'],
  ['20813', '208', '13', 'France', 'SFR'],
  ['20813', '208', '13', 'France', 'SFR'],
  ['20813', '208', '13', 'France', 'SFR'],
  ['20813', '208', '13', 'France', 'SFR'],
  ['20815', '208', '15', 'France', 'Unknown-FRANCE-6147'],
  ['20816', '208', '16', 'France', 'Free'],
  ['20816', '208', '16', 'France', 'Free'],
  ['20816', '208', '16', 'France', 'Free'],
  ['20816', '208', '16', 'France', 'Free'],
  ['20816', '208', '16', 'France', 'Free'],
  ['20816', '208', '16', 'France', 'Free'],
  ['20816', '208', '16', 'France', 'Free'],
  ['20816', '208', '16', 'France', 'Free'],
  ['20816', '208', '16', 'France', 'Free'],
  ['20816', '208', '16', 'France', 'Free'],
  ['20816', '208', '16', 'France', 'Free'],
  ['20816', '208', '16', 'France', 'Free'],
  ['20816', '208', '16', 'France', 'Free'],
  ['20816', '208', '16', 'France', 'Free'],
  ['20816', '208', '16', 'France', 'Free'],
  ['20816', '208', '16', 'France', 'Free'],
  ['20816', '208', '16', 'France', 'Free'],
  ['20817', '208', '17', 'France', 'LEGOS'],
  ['20817', '208', '17', 'France', 'LEGOS'],
  ['20817', '208', '17', 'France', 'LEGOS'],
  ['20817', '208', '17', 'France', 'LEGOS'],
  ['20817', '208', '17', 'France', 'LEGOS'],
  ['20817', '208', '17', 'France', 'LEGOS'],
  ['20817', '208', '17', 'France', 'LEGOS'],
  ['20817', '208', '17', 'France', 'LEGOS'],
  ['20820', '208', '20', 'France', 'BOUYGUES TEL.'],
  ['20820', '208', '20', 'France', 'UNALLOCATED PREFIX'],
  ['20820', '208', '20', 'France', 'BOUYGUES TEL.'],
  ['20820', '208', '20', 'France', 'BOUYGUES TEL.'],
  ['20820', '208', '20', 'France', 'BOUYGUES TEL.'],
  ['20820', '208', '20', 'France', 'BOUYGUES TEL.'],
  ['20820', '208', '20', 'France', 'BOUYGUES TEL.'],
  ['20820', '208', '20', 'France', 'BOUYGUES TEL.'],
  ['20820', '208', '20', 'France', 'BOUYGUES TEL.'],
  ['20820', '208', '20', 'France', 'BOUYGUES TEL.'],
  ['20820', '208', '20', 'France', 'BOUYGUES TEL.'],
  ['20820', '208', '20', 'France', 'BOUYGUES TEL.'],
  ['20820', '208', '20', 'France', 'BOUYGUES TEL.'],
  ['20820', '208', '20', 'France', 'BOUYGUES TEL.'],
  ['20821', '208', '21', 'France', 'Bouygues'],
  ['20821', '208', '21', 'France', 'Bouygues'],
  ['20821', '208', '21', 'France', 'Bouygues'],
  ['20821', '208', '21', 'France', 'Bouygues'],
  ['20821', '208', '21', 'France', 'Bouygues'],
  ['20821', '208', '21', 'France', 'Bouygues'],
  ['20821', '208', '21', 'France', 'Bouygues'],
  ['20821', '208', '21', 'France', 'Bouygues'],
  ['20821', '208', '21', 'France', 'Bouygues'],
  ['20821', '208', '21', 'France', 'Bouygues'],
  ['20821', '208', '21', 'France', 'Bouygues'],
  ['20821', '208', '21', 'France', 'Bouygues'],
  ['20821', '208', '21', 'France', 'Bouygues'],
  ['20821', '208', '21', 'France', 'Bouygues'],
  ['20821', '208', '21', 'France', 'Bouygues'],
  ['20821', '208', '21', 'France', 'Bouygues'],
  ['20822', '208', '22', 'France', 'Transatel'],
  ['20822', '208', '22', 'France', 'Transatel'],
  ['20822', '208', '22', 'France', 'Transatel'],
  ['20822', '208', '22', 'France', 'Transatel'],
  ['20822', '208', '22', 'France', 'Transatel'],
  ['20822', '208', '22', 'France', 'Transatel'],
  ['20822', '208', '22', 'France', 'Transatel'],
  ['20822', '208', '22', 'France', 'Transatel'],
  ['20824', '208', '24', 'France', 'MobiquiThings'],
  ['20824', '208', '24', 'France', 'MobiquiThings'],
  ['20824', '208', '24', 'France', 'MobiquiThings'],
  ['20824', '208', '24', 'France', 'MobiquiThings'],
  ['20824', '208', '24', 'France', 'MobiquiThings'],
  ['20824', '208', '24', 'France', 'MobiquiThings'],
  ['20824', '208', '24', 'France', 'MobiquiThings'],
  ['20824', '208', '24', 'France', 'MobiquiThings'],
  ['20824', '208', '24', 'France', 'MobiquiThings'],
  ['20824', '208', '24', 'France', 'MobiquiThings'],
  ['20824', '208', '24', 'France', 'MobiquiThings'],
  ['20824', '208', '24', 'France', 'MobiquiThings'],
  ['20824', '208', '24', 'France', 'MobiquiThings'],
  ['20825', '208', '25', 'France', 'Lycamobile'],
  ['20825', '208', '25', 'France', 'Lycamobile'],
  ['20825', '208', '25', 'France', 'Lycamobile'],
  ['20825', '208', '25', 'France', 'Lycamobile'],
  ['20825', '208', '25', 'France', 'Lycamobile'],
  ['20825', '208', '25', 'France', 'Lycamobile'],
  ['20825', '208', '25', 'France', 'Lycamobile'],
  ['20825', '208', '25', 'France', 'Lycamobile'],
  ['20826', '208', '26', 'France', 'NRJ Mobile'],
  ['20826', '208', '26', 'France', 'NRJ Mobile'],
  ['20826', '208', '26', 'France', 'NRJ Mobile'],
  ['20826', '208', '26', 'France', 'NRJ Mobile'],
  ['20826', '208', '26', 'France', 'NRJ Mobile'],
  ['20826', '208', '26', 'France', 'NRJ Mobile'],
  ['20826', '208', '26', 'France', 'NRJ Mobile'],
  ['20826', '208', '26', 'France', 'NRJ Mobile'],
  ['20826', '208', '26', 'France', 'NRJ Mobile'],
  ['20826', '208', '26', 'France', 'NRJ Mobile'],
  ['20826', '208', '26', 'France', 'NRJ Mobile'],
  ['20826', '208', '26', 'France', 'NRJ Mobile'],
  ['20826', '208', '26', 'France', 'NRJ Mobile'],
  ['20826', '208', '26', 'France', 'NRJ Mobile'],
  ['20826', '208', '26', 'France', 'NRJ Mobile'],
  ['20826', '208', '26', 'France', 'NRJ Mobile'],
  ['20826', '208', '26', 'France', 'NRJ Mobile'],
  ['20826', '208', '26', 'France', 'NRJ Mobile'],
  ['20826', '208', '26', 'France', 'NRJ Mobile'],
  ['20826', '208', '26', 'France', 'NRJ Mobile'],
  ['20826', '208', '26', 'France', 'NRJ Mobile'],
  ['20826', '208', '26', 'France', 'NRJ Mobile'],
  ['20826', '208', '26', 'France', 'NRJ Mobile'],
  ['20826', '208', '26', 'France', 'NRJ Mobile'],
  ['20826', '208', '26', 'France', 'NRJ Mobile'],
  ['20826', '208', '26', 'France', 'NRJ Mobile'],
  ['20826', '208', '26', 'France', 'NRJ Mobile'],
  ['20826', '208', '26', 'France', 'NRJ Mobile'],
  ['20826', '208', '26', 'France', 'NRJ Mobile'],
  ['20826', '208', '26', 'France', 'NRJ Mobile'],
  ['20826', '208', '26', 'France', 'NRJ Mobile'],
  ['20826', '208', '26', 'France', 'NRJ Mobile'],
  ['20826', '208', '26', 'France', 'NRJ Mobile'],
  ['20826', '208', '26', 'France', 'NRJ Mobile'],
  ['20826', '208', '26', 'France', 'NRJ Mobile'],
  ['20826', '208', '26', 'France', 'NRJ Mobile'],
  ['20826', '208', '26', 'France', 'NRJ Mobile'],
  ['20826', '208', '26', 'France', 'NRJ Mobile'],
  ['20826', '208', '26', 'France', 'NRJ Mobile'],
  ['20826', '208', '26', 'France', 'NRJ Mobile'],
  ['20826', '208', '26', 'France', 'NRJ Mobile'],
  ['20826', '208', '26', 'France', 'NRJ Mobile'],
  ['20826', '208', '26', 'France', 'NRJ Mobile'],
  ['20826', '208', '26', 'France', 'NRJ Mobile'],
  ['20826', '208', '26', 'France', 'NRJ Mobile'],
  ['20826', '208', '26', 'France', 'NRJ Mobile'],
  ['20826', '208', '26', 'France', 'NRJ Mobile'],
  ['20826', '208', '26', 'France', 'NRJ Mobile'],
  ['20826', '208', '26', 'France', 'NRJ Mobile'],
  ['20826', '208', '26', 'France', 'NRJ Mobile'],
  ['20826', '208', '26', 'France', 'NRJ Mobile'],
  ['20826', '208', '26', 'France', 'NRJ Mobile'],
  ['20826', '208', '26', 'France', 'NRJ Mobile'],
  ['20826', '208', '26', 'France', 'NRJ Mobile'],
  ['20826', '208', '26', 'France', 'NRJ Mobile'],
  ['20826', '208', '26', 'France', 'NRJ Mobile'],
  ['20826', '208', '26', 'France', 'NRJ Mobile'],
  ['20826', '208', '26', 'France', 'NRJ Mobile'],
  ['20826', '208', '26', 'France', 'NRJ Mobile'],
  ['20826', '208', '26', 'France', 'NRJ Mobile'],
  ['20826', '208', '26', 'France', 'NRJ Mobile'],
  ['20826', '208', '26', 'France', 'NRJ Mobile'],
  ['20826', '208', '26', 'France', 'NRJ Mobile'],
  ['20888', '208', '88', 'France', 'Bouygues'],
  ['20888', '208', '88', 'France', 'Bouygues'],
  ['20888', '208', '88', 'France', 'Bouygues'],
  ['20888', '208', '88', 'France', 'Bouygues'],
  ['20888', '208', '88', 'France', 'Bouygues'],
  ['20888', '208', '88', 'France', 'Bouygues'],
  ['20888', '208', '88', 'France', 'Bouygues'],
  ['20888', '208', '88', 'France', 'Bouygues'],
  ['20888', '208', '88', 'France', 'Bouygues'],
  ['20888', '208', '88', 'France', 'Bouygues'],
  ['20888', '208', '88', 'France', 'Bouygues'],
  ['20888', '208', '88', 'France', 'Bouygues'],
  ['20888', '208', '88', 'France', 'Bouygues'],
  ['20888', '208', '88', 'France', 'Bouygues'],
  ['20888', '208', '88', 'France', 'Bouygues'],
  ['20888', '208', '88', 'France', 'Bouygues'],
  ['20891', '208', '91', 'France', 'Orange'],
  ['20891', '208', '91', 'France', 'Orange'],
  ['20891', '208', '91', 'France', 'Orange'],
  ['20891', '208', '91', 'France', 'Orange'],
  ['20891', '208', '91', 'France', 'Orange'],
  ['20891', '208', '91', 'France', 'Orange'],
  ['20891', '208', '91', 'France', 'Orange'],
  ['20891', '208', '91', 'France', 'Orange'],
  ['20891', '208', '91', 'France', 'Orange'],
  ['20891', '208', '91', 'France', 'Orange'],
  ['20891', '208', '91', 'France', 'Orange'],
  ['20891', '208', '91', 'France', 'Orange'],
  ['20891', '208', '91', 'France', 'Orange'],
  ['20891', '208', '91', 'France', 'Orange'],
  ['20891', '208', '91', 'France', 'Orange'],
  ['20891', '208', '91', 'France', 'Orange'],
  ['20891', '208', '91', 'France', 'Orange'],
  ['20891', '208', '91', 'France', 'Orange'],
  ['20891', '208', '91', 'France', 'Orange'],
  ['20891', '208', '91', 'France', 'Orange'],
  ['20891', '208', '91', 'France', 'Orange'],
  ['20891', '208', '91', 'France', 'Orange'],
  ['20891', '208', '91', 'France', 'Orange'],
  ['20891', '208', '91', 'France', 'Orange'],
  ['20891', '208', '91', 'France', 'Orange'],
  ['20894', '208', '94', 'France', 'Lleida Networks'],
  ['34020', '340', '20', 'France', 'ORANGE CARAIBE'],
  ['34020', '340', '20', 'France', 'BOUYGUES TEL.'],
  ['34020', '340', '20', 'France', 'BOUYGUES TEL.'],
  ['34020', '340', '20', 'France', 'BOUYGUES TEL.'],
  ['34020', '340', '20', 'France', 'ORANGE CARAIBE'],
  ['5470', '547', '0', 'French Polynesia', 'DEFAULT FRENCH POLYNESIA'],
  ['54720', '547', '20', 'French Polynesia', 'VINI (TIKIPHONE)'],
  ['54720', '547', '20', 'French Polynesia', 'VINI (TIKIPHONE)'],
  ['6280', '628', '0', 'Gabon', 'DEFAULT GABON'],
  ['62801', '628', '1', 'Gabon', 'LIBERTIS'],
  ['62802', '628', '2', 'Gabon', 'CELTEL GABON'],
  ['62803', '628', '3', 'Gabon', 'Zain-Airtel-Celtel'],
  ['62803', '628', '3', 'Gabon', 'Zain-Airtel-Celtel'],
  ['62803', '628', '3', 'Gabon', 'Zain-Airtel-Celtel'],
  ['6070', '607', '0', 'Gambia', 'DEFAULT GAMBIA'],
  ['60701', '607', '1', 'Gambia', 'GAMCELL'],
  ['60701', '607', '1', 'Gambia', 'GAMCELL'],
  ['60702', '607', '2', 'Gambia', 'AFRICELL'],
  ['60702', '607', '2', 'Gambia', 'AFRICELL'],
  ['60703', '607', '3', 'Gambia', 'Comium'],
  ['60704', '607', '4', 'Gambia', 'Gambia Qcell (Qcell) '],
  ['60704', '607', '4', 'Gambia', 'Gambia Qcell (Qcell) '],
  ['60704', '607', '4', 'Gambia', 'Gambia Qcell (Qcell) '],
  ['60704', '607', '4', 'Gambia', 'Gambia Qcell (Qcell) '],
  ['60704', '607', '4', 'Gambia', 'Gambia Qcell (Qcell) '],
  ['60704', '607', '4', 'Gambia', 'Gambia Qcell (Qcell) '],
  ['60704', '607', '4', 'Gambia', 'Gambia Qcell (Qcell) '],
  ['28203', '282', '3', 'Georgia', 'MagtiCom GSM'],
  ['28203', '282', '3', 'Georgia', 'MagtiCom GSM'],
  ['28203', '282', '3', 'Georgia', 'MagtiCom GSM'],
  ['28203', '282', '3', 'Georgia', 'MagtiCom GSM'],
  ['28203', '282', '3', 'Georgia', 'MagtiCom GSM'],
  ['28203', '282', '3', 'Georgia', 'MagtiCom GSM'],
  ['28203', '282', '3', 'Georgia', 'MagtiCom GSM'],
  ['28203', '282', '3', 'Georgia', 'MagtiCom GSM'],
  ['28203', '282', '3', 'Georgia', 'MagtiCom GSM'],
  ['28203', '282', '3', 'Georgia', 'MagtiCom GSM'],
  ['28203', '282', '3', 'Georgia', 'MagtiCom GSM'],
  ['28203', '282', '3', 'Georgia', 'MagtiCom GSM'],
  ['28203', '282', '3', 'Georgia', 'MagtiCom GSM'],
  ['28203', '282', '3', 'Georgia', 'MagtiCom GSM'],
  ['28203', '282', '3', 'Georgia', 'MagtiCom GSM'],
  ['28203', '282', '3', 'Georgia', 'MagtiCom GSM'],
  ['28204', '282', '4', 'Georgia', 'Mobitel (Beeline)'],
  ['28205', '282', '5', 'Georgia', 'Silknet'],
  ['28206', '282', '6', 'Georgia', 'Silknet'],
  ['28208', '282', '8', 'Georgia', 'Silknet'],
  ['2820', '282', '0', 'Georgia Republic', 'DEFAULT GEORGIA'],
  ['28201', '282', '1', 'Georgia Republic', 'GEOCELL'],
  ['28201', '282', '1', 'Georgia Republic', 'GEOCELL'],
  ['28202', '282', '2', 'Georgia Republic', 'MAGTICOM'],
  ['28202', '282', '2', 'Georgia Republic', 'MAGTICOM'],
  ['2620', '262', '0', 'Germany', 'DEFAULT GERMANY'],
  ['26201', '262', '1', 'Germany', 'T-MOBILE'],
  ['26201', '262', '1', 'Germany', 'T-MOBILE'],
  ['26201', '262', '1', 'Germany', 'T-MOBILE'],
  ['26201', '262', '1', 'Germany', 'T-MOBILE'],
  ['26201', '262', '1', 'Germany', 'T-MOBILE'],
  ['26202', '262', '2', 'Germany', 'VODAFONE'],
  ['26202', '262', '2', 'Germany', 'VODAFONE'],
  ['26202', '262', '2', 'Germany', 'VODAFONE'],
  ['26202', '262', '2', 'Germany', 'VODAFONE'],
  ['26202', '262', '2', 'Germany', 'VODAFONE'],
  ['26203', '262', '3', 'Germany', 'E-PLUS'],
  ['26203', '262', '3', 'Germany', 'E-PLUS'],
  ['26203', '262', '3', 'Germany', 'E-PLUS'],
  ['26203', '262', '3', 'Germany', 'E-PLUS'],
  ['26203', '262', '3', 'Germany', 'E-PLUS'],
  ['26204', '262', '4', 'Germany', 'Vodafone'],
  ['26204', '262', '4', 'Germany', 'Vodafone'],
  ['26204', '262', '4', 'Germany', 'Vodafone'],
  ['26204', '262', '4', 'Germany', 'Vodafone'],
  ['26204', '262', '4', 'Germany', 'Vodafone'],
  ['26205', '262', '5', 'Germany', 'O2 (EPlus)'],
  ['26207', '262', '7', 'Germany', 'O2'],
  ['26207', '262', '7', 'Germany', 'O2'],
  ['26207', '262', '7', 'Germany', 'O2'],
  ['26207', '262', '7', 'Germany', 'O2'],
  ['26207', '262', '7', 'Germany', 'O2'],
  ['26207', '262', '7', 'Germany', 'O2'],
  ['26208', '262', '8', 'Germany', 'O2 (EPlus)'],
  ['26209', '262', '9', 'Germany', 'Vodafone'],
  ['26209', '262', '9', 'Germany', 'Vodafone'],
  ['26209', '262', '9', 'Germany', 'Vodafone'],
  ['26209', '262', '9', 'Germany', 'Vodafone'],
  ['26209', '262', '9', 'Germany', 'Vodafone'],
  ['26210', '262', '10', 'Germany', 'Vodafone'],
  ['26210', '262', '10', 'Germany', 'Vodafone'],
  ['26210', '262', '10', 'Germany', 'Vodafone'],
  ['26210', '262', '10', 'Germany', 'Vodafone'],
  ['26210', '262', '10', 'Germany', 'Vodafone'],
  ['26211', '262', '11', 'Germany', 'O2 (EPlus)'],
  ['26213', '262', '13', 'Germany', 'MOBILCOM'],
  ['26214', '262', '14', 'Germany', 'QUAM'],
  ['26220', '262', '20', 'Germany', 'O2 (EPlus)'],
  ['26242', '262', '42', 'Germany', 'Vodafone'],
  ['26242', '262', '42', 'Germany', 'Vodafone'],
  ['26242', '262', '42', 'Germany', 'Vodafone'],
  ['26242', '262', '42', 'Germany', 'Vodafone'],
  ['26242', '262', '42', 'Germany', 'Vodafone'],
  ['26243', '262', '43', 'Germany', 'Lycamobile'],
  ['26277', '262', '77', 'Germany', 'O2 (EPlus)'],
  ['6200', '620', '0', 'Ghana', 'DEFAULT GHANA'],
  ['620001', '620', '1', 'Ghana', 'SCANCOM'],
  ['620001', '620', '1', 'Ghana', 'MTN'],
  ['620002', '620', '2', 'Ghana', 'VODAFONE'],
  ['620002', '620', '2', 'Ghana', 'GT-ONETOUCH'],
  ['620003', '620', '3', 'Ghana', 'TIGO'],
  ['620003', '620', '3', 'Ghana', 'MOBITEL'],
  ['620004', '620', '4', 'Ghana', 'GLO'],
  ['620006', '620', '6', 'Ghana', 'AIRTEL'],
  ['62001', '620', '1', 'Ghana', 'Scancom (MTN)'],
  ['62001', '620', '1', 'Ghana', 'Scancom (MTN)'],
  ['62001', '620', '1', 'Ghana', 'Scancom (MTN)'],
  ['62002', '620', '2', 'Ghana', 'Vodafone (Telecom Mobile GSM)'],
  ['62003', '620', '3', 'Ghana', 'Tigo Millicom'],
  ['62003', '620', '3', 'Ghana', 'Tigo Millicom'],
  ['62004', '620', '4', 'Ghana', 'Kasapa/Hutchison Telecom (Expresso)'],
  ['62006', '620', '6', 'Ghana', 'Zain-Airtel'],
  ['62006', '620', '6', 'Ghana', 'Zain-Airtel'],
  ['62007', '620', '7', 'Ghana', 'Glo Ghana'],
  ['620501', '620', '501', 'Ghana', 'KASAPA'],
  ['2660', '266', '0', 'Gibraltar', 'DEFAULT GIBILTERRA'],
  ['26601', '266', '1', 'Gibraltar', 'GIBTEL'],
  ['26601', '266', '1', 'Gibraltar', 'GIBTEL'],
  ['26601', '266', '1', 'Gibraltar', 'GIBTEL'],
  ['26601', '266', '1', 'Gibraltar', 'GIBTEL'],
  ['2020', '202', '0', 'Greece', 'DEFAULT GREECE'],
  ['20201', '202', '1', 'Greece', 'COSMOTE'],
  ['20202', '202', '2', 'Greece', 'COSMOTE'],
  ['20205', '202', '5', 'Greece', 'VODAFONE'],
  ['20205', '202', '5', 'Greece', 'VODAFONE'],
  ['20209', '202', '9', 'Greece', 'Q-TELECOM'],
  ['20210', '202', '10', 'Greece', 'WIND'],
  ['20210', '202', '10', 'Greece', 'WIND'],
  ['20214', '202', '14', 'Greece', 'CYTA'],
  ['2900', '290', '0', 'Greenland', 'DEFAULT GREENLAND'],
  ['29001', '290', '1', 'Greenland', 'TELE GREENLAND'],
  ['29001', '290', '1', 'Greenland', 'TELE GREENLAND'],
  ['29001', '290', '1', 'Greenland', 'TELE GREENLAND'],
  ['29001', '290', '1', 'Greenland', 'TELE GREENLAND'],
  ['29001', '290', '1', 'Greenland', 'TELE GREENLAND'],
  ['29001', '290', '1', 'Greenland', 'TELE GREENLAND'],
  ['310015', '310', '15', 'Grenada', 'MOSSEL DIGITEL'],
  ['310015', '310', '15', 'Grenada', 'CABLE & WIRELESS'],
  ['310015', '310', '15', 'Grenada', 'CABLE & WIRELESS'],
  ['310015', '310', '15', 'Grenada', 'CABLE & WIRELESS'],
  ['310015', '310', '15', 'Grenada', 'CABLE & WIRELESS'],
  ['310015', '310', '15', 'Grenada', 'MOSSEL DIGITEL'],
  ['310015', '310', '15', 'Grenada', 'MOSSEL DIGITEL'],
  ['310015', '310', '15', 'Grenada', 'MOSSEL DIGITEL'],
  ['310015', '310', '15', 'Grenada', 'MOSSEL DIGITEL'],
  ['310015', '310', '15', 'Grenada', 'MOSSEL DIGITEL'],
  ['310015', '310', '15', 'Grenada', 'CABLE & WIRELESS'],
  ['310015', '310', '15', 'Grenada', 'CINGULAR WIRELESS'],
  ['310002', '310', '2', 'Guam', 'GUAM WIRELESS'],
  ['310032', '310', '32', 'Guam', 'GUAM WIRELESS'],
  ['310032', '310', '32', 'Guam', 'GUAM WIRELESS'],
  ['310032', '310', '32', 'Guam', 'GUAM WIRELESS'],
  ['310032', '310', '32', 'Guam', 'GUAM WIRELESS'],
  ['310032', '310', '32', 'Guam', 'GUAM WIRELESS'],
  ['310032', '310', '32', 'Guam', 'GUAM WIRELESS'],
  ['310032', '310', '32', 'Guam', 'GUAM WIRELESS'],
  ['310032', '310', '32', 'Guam', 'GUAM WIRELESS'],
  ['310140', '310', '140', 'Guam', 'GTA Wireless'],
  ['310140', '310', '140', 'Guam', 'GTA Wireless'],
  ['310140', '310', '140', 'Guam', 'GTA Wireless'],
  ['310140', '310', '140', 'Guam', 'GTA Wireless'],
  ['310140', '310', '140', 'Guam', 'GTA Wireless'],
  ['310140', '310', '140', 'Guam', 'GTA Wireless'],
  ['310470', '310', '470', 'Guam', 'Docomo Pacific'],
  ['310470', '310', '470', 'Guam', 'Docomo Pacific'],
  ['310470', '310', '470', 'Guam', 'Docomo Pacific'],
  ['310470', '310', '470', 'Guam', 'Docomo Pacific'],
  ['310470', '310', '470', 'Guam', 'Docomo Pacific'],
  ['310480', '310', '480', 'Guam', 'iConnect'],
  ['310480', '310', '480', 'Guam', 'iConnect'],
  ['310480', '310', '480', 'Guam', 'iConnect'],
  ['310480', '310', '480', 'Guam', 'iConnect'],
  ['7040', '704', '0', 'Guatemala', 'DEFAULT GUATEMALA'],
  ['70401', '704', '1', 'Guatemala', 'SERCOM'],
  ['70402', '704', '2', 'Guatemala', 'Tigo (COMCEL)'],
  ['70402', '704', '2', 'Guatemala', 'Tigo (COMCEL)'],
  ['70402', '704', '2', 'Guatemala', 'Tigo (COMCEL)'],
  ['70402', '704', '2', 'Guatemala', 'Tigo (COMCEL)'],
  ['70402', '704', '2', 'Guatemala', 'Tigo (COMCEL)'],
  ['70402', '704', '2', 'Guatemala', 'Tigo (COMCEL)'],
  ['70402', '704', '2', 'Guatemala', 'Tigo (COMCEL)'],
  ['70402', '704', '2', 'Guatemala', 'Tigo (COMCEL)'],
  ['70402', '704', '2', 'Guatemala', 'Tigo (COMCEL)'],
  ['70402', '704', '2', 'Guatemala', 'Tigo (COMCEL)'],
  ['70402', '704', '2', 'Guatemala', 'Tigo (COMCEL)'],
  ['70402', '704', '2', 'Guatemala', 'Tigo (COMCEL)'],
  ['70402', '704', '2', 'Guatemala', 'Tigo (COMCEL)'],
  ['70402', '704', '2', 'Guatemala', 'Tigo (COMCEL)'],
  ['70402', '704', '2', 'Guatemala', 'Tigo (COMCEL)'],
  ['70402', '704', '2', 'Guatemala', 'Tigo (COMCEL)'],
  ['70402', '704', '2', 'Guatemala', 'Tigo (COMCEL)'],
  ['70402', '704', '2', 'Guatemala', 'Tigo (COMCEL)'],
  ['70402', '704', '2', 'Guatemala', 'Tigo (COMCEL)'],
  ['70402', '704', '2', 'Guatemala', 'Tigo (COMCEL)'],
  ['70402', '704', '2', 'Guatemala', 'Tigo (COMCEL)'],
  ['70402', '704', '2', 'Guatemala', 'Tigo (COMCEL)'],
  ['70402', '704', '2', 'Guatemala', 'Tigo (COMCEL)'],
  ['70402', '704', '2', 'Guatemala', 'Tigo (COMCEL)'],
  ['70402', '704', '2', 'Guatemala', 'Tigo (COMCEL)'],
  ['70402', '704', '2', 'Guatemala', 'Tigo (COMCEL)'],
  ['70402', '704', '2', 'Guatemala', 'Tigo (COMCEL)'],
  ['70402', '704', '2', 'Guatemala', 'Tigo (COMCEL)'],
  ['70402', '704', '2', 'Guatemala', 'Tigo (COMCEL)'],
  ['70402', '704', '2', 'Guatemala', 'Tigo (COMCEL)'],
  ['70402', '704', '2', 'Guatemala', 'Tigo (COMCEL)'],
  ['70402', '704', '2', 'Guatemala', 'Tigo (COMCEL)'],
  ['70402', '704', '2', 'Guatemala', 'Tigo (COMCEL)'],
  ['70402', '704', '2', 'Guatemala', 'Tigo (COMCEL)'],
  ['70402', '704', '2', 'Guatemala', 'Tigo (COMCEL)'],
  ['70402', '704', '2', 'Guatemala', 'Tigo (COMCEL)'],
  ['70402', '704', '2', 'Guatemala', 'Tigo (COMCEL)'],
  ['70402', '704', '2', 'Guatemala', 'Tigo (COMCEL)'],
  ['70402', '704', '2', 'Guatemala', 'Tigo (COMCEL)'],
  ['70402', '704', '2', 'Guatemala', 'Tigo (COMCEL)'],
  ['70402', '704', '2', 'Guatemala', 'Tigo (COMCEL)'],
  ['70402', '704', '2', 'Guatemala', 'Tigo (COMCEL)'],
  ['70402', '704', '2', 'Guatemala', 'Tigo (COMCEL)'],
  ['70402', '704', '2', 'Guatemala', 'Tigo (COMCEL)'],
  ['70402', '704', '2', 'Guatemala', 'Tigo (COMCEL)'],
  ['70402', '704', '2', 'Guatemala', 'Tigo (COMCEL)'],
  ['70402', '704', '2', 'Guatemala', 'Tigo (COMCEL)'],
  ['70402', '704', '2', 'Guatemala', 'Tigo (COMCEL)'],
  ['70402', '704', '2', 'Guatemala', 'Tigo (COMCEL)'],
  ['70402', '704', '2', 'Guatemala', 'Tigo (COMCEL)'],
  ['70402', '704', '2', 'Guatemala', 'Tigo (COMCEL)'],
  ['70403', '704', '3', 'Guatemala', 'Movistar'],
  ['70403', '704', '3', 'Guatemala', 'Movistar'],
  ['70403', '704', '3', 'Guatemala', 'Movistar'],
  ['70403', '704', '3', 'Guatemala', 'Movistar'],
  ['70403', '704', '3', 'Guatemala', 'Movistar'],
  ['70403', '704', '3', 'Guatemala', 'Movistar'],
  ['70403', '704', '3', 'Guatemala', 'Movistar'],
  ['70403', '704', '3', 'Guatemala', 'Movistar'],
  ['70403', '704', '3', 'Guatemala', 'Movistar'],
  ['70403', '704', '3', 'Guatemala', 'Movistar'],
  ['70403', '704', '3', 'Guatemala', 'Movistar'],
  ['70403', '704', '3', 'Guatemala', 'Movistar'],
  ['70403', '704', '3', 'Guatemala', 'Movistar'],
  ['70403', '704', '3', 'Guatemala', 'Movistar'],
  ['70403', '704', '3', 'Guatemala', 'Movistar'],
  ['70403', '704', '3', 'Guatemala', 'Movistar'],
  ['70403', '704', '3', 'Guatemala', 'Movistar'],
  ['70403', '704', '3', 'Guatemala', 'Movistar'],
  ['70403', '704', '3', 'Guatemala', 'Movistar'],
  ['70403', '704', '3', 'Guatemala', 'Movistar'],
  ['70403', '704', '3', 'Guatemala', 'Movistar'],
  ['70403', '704', '3', 'Guatemala', 'Movistar'],
  ['70403', '704', '3', 'Guatemala', 'Movistar'],
  ['70403', '704', '3', 'Guatemala', 'Movistar'],
  ['70403', '704', '3', 'Guatemala', 'Movistar'],
  ['70403', '704', '3', 'Guatemala', 'Movistar'],
  ['70403', '704', '3', 'Guatemala', 'Movistar'],
  ['70403', '704', '3', 'Guatemala', 'Movistar'],
  ['70403', '704', '3', 'Guatemala', 'Movistar'],
  ['70403', '704', '3', 'Guatemala', 'Movistar'],
  ['70403', '704', '3', 'Guatemala', 'Movistar'],
  ['6110', '611', '0', 'Guinea', 'DEFAULT GUINEA'],
  ['61101', '611', '1', 'Guinea', 'SPACETEL'],
  ['61102', '611', '2', 'Guinea', 'SOTELGUI'],
  ['61104', '611', '4', 'Guinea', 'AREEBA'],
  ['61105', '611', '5', 'Guinea', 'Cellcom'],
  ['61110', '611', '10', 'Guinea', 'TELECEL'],
  ['3720', '372', '0', 'Haiti', 'DEFAULT HAITI'],
  ['37201', '372', '1', 'Haiti', 'VOILA!'],
  ['37201', '372', '1', 'Haiti', 'VOILA!'],
  ['37201', '372', '1', 'Haiti', 'VOILA!'],
  ['37202', '372', '2', 'Haiti', 'DIGICEL'],
  ['37202', '372', '2', 'Haiti', 'DIGICEL'],
  ['37202', '372', '2', 'Haiti', 'DIGICEL'],
  ['37203', '372', '3', 'Haiti', 'NATCOM'],
  ['37203', '372', '3', 'Haiti', 'NATCOM'],
  ['37203', '372', '3', 'Haiti', 'NATCOM'],
  ['37203', '372', '3', 'Haiti', 'NATCOM'],
  ['7080', '708', '0', 'Honduras', 'DEFAULT HONDURAS'],
  ['70801', '708', '1', 'Honduras', 'CLARO GSM'],
  ['70802', '708', '2', 'Honduras', 'CELTEL'],
  ['70802', '708', '2', 'Honduras', 'CELTEL'],
  ['70840', '708', '40', 'Honduras', 'DIGITEL HONDURAS'],
  ['4540', '454', '0', 'Hong Kong', 'DEFAULT HONG KONG'],
  ['45400', '454', '0', 'Hong Kong', 'HONG KONG CSL'],
  ['45400', '454', '0', 'Hong Kong', 'HONG KONG CSL'],
  ['45400', '454', '0', 'Hong Kong', 'HONG KONG CSL'],
  ['45400', '454', '0', 'Hong Kong', 'HONG KONG CSL'],
  ['45400', '454', '0', 'Hong Kong', 'HONG KONG CSL'],
  ['45400', '454', '0', 'Hong Kong', 'HONG KONG CSL'],
  ['45400', '454', '0', 'Hong Kong', 'HONG KONG CSL'],
  ['45400', '454', '0', 'Hong Kong', 'HONG KONG CSL'],
  ['45400', '454', '0', 'Hong Kong', 'HONG KONG CSL'],
  ['45400', '454', '0', 'Hong Kong', 'HONG KONG CSL'],
  ['45400', '454', '0', 'Hong Kong', 'HONG KONG CSL'],
  ['45400', '454', '0', 'Hong Kong', 'HONG KONG CSL'],
  ['45400', '454', '0', 'Hong Kong', 'HONG KONG CSL'],
  ['45400', '454', '0', 'Hong Kong', 'HONG KONG CSL'],
  ['45400', '454', '0', 'Hong Kong', 'HONG KONG CSL'],
  ['45400', '454', '0', 'Hong Kong', 'HONG KONG CSL'],
  ['45400', '454', '0', 'Hong Kong', 'HONG KONG CSL'],
  ['45400', '454', '0', 'Hong Kong', 'HONG KONG CSL'],
  ['45400', '454', '0', 'Hong Kong', 'HONG KONG CSL'],
  ['45400', '454', '0', 'Hong Kong', 'HONG KONG CSL'],
  ['45400', '454', '0', 'Hong Kong', 'HONG KONG CSL'],
  ['45400', '454', '0', 'Hong Kong', 'HONG KONG CSL'],
  ['45400', '454', '0', 'Hong Kong', 'HONG KONG CSL'],
  ['45400', '454', '0', 'Hong Kong', 'HONG KONG CSL'],
  ['45400', '454', '0', 'Hong Kong', 'HONG KONG CSL'],
  ['45400', '454', '0', 'Hong Kong', 'HONG KONG CSL'],
  ['45401', '454', '1', 'Hong Kong', 'CITIC'],
  ['45401', '454', '1', 'Hong Kong', 'CITIC'],
  ['45403', '454', '3', 'Hong Kong', 'H3G'],
  ['45403', '454', '3', 'Hong Kong', 'H3G'],
  ['45404', '454', '4', 'Hong Kong', 'HUTCHINSON'],
  ['45404', '454', '4', 'Hong Kong', 'HUTCHINSON'],
  ['45404', '454', '4', 'Hong Kong', 'HUTCHINSON'],
  ['45404', '454', '4', 'Hong Kong', 'HUTCHINSON'],
  ['45404', '454', '4', 'Hong Kong', 'HUTCHINSON'],
  ['45404', '454', '4', 'Hong Kong', 'HUTCHINSON'],
  ['45404', '454', '4', 'Hong Kong', 'HUTCHINSON'],
  ['45404', '454', '4', 'Hong Kong', 'HUTCHINSON'],
  ['45404', '454', '4', 'Hong Kong', 'HUTCHINSON'],
  ['45404', '454', '4', 'Hong Kong', 'HUTCHINSON'],
  ['45404', '454', '4', 'Hong Kong', 'HUTCHINSON'],
  ['45404', '454', '4', 'Hong Kong', 'HUTCHINSON'],
  ['45404', '454', '4', 'Hong Kong', 'HUTCHINSON'],
  ['45404', '454', '4', 'Hong Kong', 'HUTCHINSON'],
  ['45404', '454', '4', 'Hong Kong', 'HUTCHINSON'],
  ['45404', '454', '4', 'Hong Kong', 'HUTCHINSON'],
  ['45404', '454', '4', 'Hong Kong', 'HUTCHINSON'],
  ['45404', '454', '4', 'Hong Kong', 'HUTCHINSON'],
  ['45404', '454', '4', 'Hong Kong', 'HUTCHINSON'],
  ['45404', '454', '4', 'Hong Kong', 'HUTCHINSON'],
  ['45404', '454', '4', 'Hong Kong', 'HUTCHINSON'],
  ['45404', '454', '4', 'Hong Kong', 'HUTCHINSON'],
  ['45404', '454', '4', 'Hong Kong', 'HUTCHINSON'],
  ['45404', '454', '4', 'Hong Kong', 'HUTCHINSON'],
  ['45404', '454', '4', 'Hong Kong', 'HUTCHINSON'],
  ['45404', '454', '4', 'Hong Kong', 'HUTCHINSON'],
  ['45404', '454', '4', 'Hong Kong', 'HUTCHINSON'],
  ['45404', '454', '4', 'Hong Kong', 'HUTCHINSON'],
  ['45404', '454', '4', 'Hong Kong', 'HUTCHINSON'],
  ['45404', '454', '4', 'Hong Kong', 'HUTCHINSON'],
  ['45404', '454', '4', 'Hong Kong', 'HUTCHINSON'],
  ['45404', '454', '4', 'Hong Kong', 'HUTCHINSON'],
  ['45404', '454', '4', 'Hong Kong', 'HUTCHINSON'],
  ['45405', '454', '5', 'Hong Kong', 'Hutchison'],
  ['45405', '454', '5', 'Hong Kong', 'Hutchison'],
  ['45405', '454', '5', 'Hong Kong', 'Hutchison'],
  ['45405', '454', '5', 'Hong Kong', 'Hutchison'],
  ['45405', '454', '5', 'Hong Kong', 'Hutchison'],
  ['45405', '454', '5', 'Hong Kong', 'Hutchison'],
  ['45405', '454', '5', 'Hong Kong', 'Hutchison'],
  ['45405', '454', '5', 'Hong Kong', 'Hutchison'],
  ['45405', '454', '5', 'Hong Kong', 'Hutchison'],
  ['45405', '454', '5', 'Hong Kong', 'Hutchison'],
  ['45405', '454', '5', 'Hong Kong', 'Hutchison'],
  ['45405', '454', '5', 'Hong Kong', 'Hutchison'],
  ['45405', '454', '5', 'Hong Kong', 'Hutchison'],
  ['45405', '454', '5', 'Hong Kong', 'Hutchison'],
  ['45405', '454', '5', 'Hong Kong', 'Hutchison'],
  ['45406', '454', '6', 'Hong Kong', 'SMARTONE'],
  ['45406', '454', '6', 'Hong Kong', 'SMARTONE'],
  ['45406', '454', '6', 'Hong Kong', 'SMARTONE'],
  ['45406', '454', '6', 'Hong Kong', 'SMARTONE'],
  ['45406', '454', '6', 'Hong Kong', 'SMARTONE'],
  ['45406', '454', '6', 'Hong Kong', 'SMARTONE'],
  ['45406', '454', '6', 'Hong Kong', 'SMARTONE'],
  ['45406', '454', '6', 'Hong Kong', 'SMARTONE'],
  ['45406', '454', '6', 'Hong Kong', 'SMARTONE'],
  ['45406', '454', '6', 'Hong Kong', 'SMARTONE'],
  ['45406', '454', '6', 'Hong Kong', 'SMARTONE'],
  ['45406', '454', '6', 'Hong Kong', 'SMARTONE'],
  ['45406', '454', '6', 'Hong Kong', 'SMARTONE'],
  ['45406', '454', '6', 'Hong Kong', 'SMARTONE'],
  ['45406', '454', '6', 'Hong Kong', 'SMARTONE'],
  ['45406', '454', '6', 'Hong Kong', 'SMARTONE'],
  ['45406', '454', '6', 'Hong Kong', 'SMARTONE'],
  ['45406', '454', '6', 'Hong Kong', 'SMARTONE'],
  ['45406', '454', '6', 'Hong Kong', 'SMARTONE'],
  ['45406', '454', '6', 'Hong Kong', 'SMARTONE'],
  ['45406', '454', '6', 'Hong Kong', 'SMARTONE'],
  ['45407', '454', '7', 'Hong Kong', 'China Unicom'],
  ['45407', '454', '7', 'Hong Kong', 'China Unicom'],
  ['45407', '454', '7', 'Hong Kong', 'China Unicom'],
  ['45407', '454', '7', 'Hong Kong', 'China Unicom'],
  ['45407', '454', '7', 'Hong Kong', 'China Unicom'],
  ['45407', '454', '7', 'Hong Kong', 'China Unicom'],
  ['45407', '454', '7', 'Hong Kong', 'China Unicom'],
  ['45407', '454', '7', 'Hong Kong', 'China Unicom'],
  ['45407', '454', '7', 'Hong Kong', 'China Unicom'],
  ['45407', '454', '7', 'Hong Kong', 'China Unicom'],
  ['45407', '454', '7', 'Hong Kong', 'China Unicom'],
  ['45407', '454', '7', 'Hong Kong', 'China Unicom'],
  ['45407', '454', '7', 'Hong Kong', 'China Unicom'],
  ['45407', '454', '7', 'Hong Kong', 'China Unicom'],
  ['45408', '454', '8', 'Hong Kong', 'Trident (Truphone)'],
  ['45408', '454', '8', 'Hong Kong', 'Trident (Truphone)'],
  ['45409', '454', '9', 'Hong Kong', 'China Motion'],
  ['45409', '454', '9', 'Hong Kong', 'China Motion'],
  ['45409', '454', '9', 'Hong Kong', 'China Motion'],
  ['45409', '454', '9', 'Hong Kong', 'China Motion'],
  ['45409', '454', '9', 'Hong Kong', 'China Motion'],
  ['45409', '454', '9', 'Hong Kong', 'China Motion'],
  ['45409', '454', '9', 'Hong Kong', 'China Motion'],
  ['45409', '454', '9', 'Hong Kong', 'China Motion'],
  ['45409', '454', '9', 'Hong Kong', 'China Motion'],
  ['45409', '454', '9', 'Hong Kong', 'China Motion'],
  ['45409', '454', '9', 'Hong Kong', 'China Motion'],
  ['45409', '454', '9', 'Hong Kong', 'China Motion'],
  ['45409', '454', '9', 'Hong Kong', 'China Motion'],
  ['45409', '454', '9', 'Hong Kong', 'China Motion'],
  ['45410', '454', '10', 'Hong Kong', 'NEW WORLD PCS'],
  ['45410', '454', '10', 'Hong Kong', 'NEW WORLD PCS'],
  ['45410', '454', '10', 'Hong Kong', 'NEW WORLD PCS'],
  ['45410', '454', '10', 'Hong Kong', 'NEW WORLD PCS'],
  ['45410', '454', '10', 'Hong Kong', 'NEW WORLD PCS'],
  ['45410', '454', '10', 'Hong Kong', 'NEW WORLD PCS'],
  ['45410', '454', '10', 'Hong Kong', 'NEW WORLD PCS'],
  ['45410', '454', '10', 'Hong Kong', 'NEW WORLD PCS'],
  ['45410', '454', '10', 'Hong Kong', 'NEW WORLD PCS'],
  ['45410', '454', '10', 'Hong Kong', 'NEW WORLD PCS'],
  ['45410', '454', '10', 'Hong Kong', 'NEW WORLD PCS'],
  ['45410', '454', '10', 'Hong Kong', 'NEW WORLD PCS'],
  ['45411', '454', '11', 'Hong Kong', 'China-Hong Kong Telecom'],
  ['45411', '454', '11', 'Hong Kong', 'China-Hong Kong Telecom'],
  ['45411', '454', '11', 'Hong Kong', 'China-Hong Kong Telecom'],
  ['45411', '454', '11', 'Hong Kong', 'China-Hong Kong Telecom'],
  ['45411', '454', '11', 'Hong Kong', 'China-Hong Kong Telecom'],
  ['45411', '454', '11', 'Hong Kong', 'China-Hong Kong Telecom'],
  ['45411', '454', '11', 'Hong Kong', 'China-Hong Kong Telecom'],
  ['45411', '454', '11', 'Hong Kong', 'China-Hong Kong Telecom'],
  ['45411', '454', '11', 'Hong Kong', 'China-Hong Kong Telecom'],
  ['45411', '454', '11', 'Hong Kong', 'China-Hong Kong Telecom'],
  ['45411', '454', '11', 'Hong Kong', 'China-Hong Kong Telecom'],
  ['45411', '454', '11', 'Hong Kong', 'China-Hong Kong Telecom'],
  ['45411', '454', '11', 'Hong Kong', 'China-Hong Kong Telecom'],
  ['45411', '454', '11', 'Hong Kong', 'China-Hong Kong Telecom'],
  ['45412', '454', '12', 'Hong Kong', 'PEOPLES'],
  ['45412', '454', '12', 'Hong Kong', 'PEOPLES'],
  ['45412', '454', '12', 'Hong Kong', 'PEOPLES'],
  ['45412', '454', '12', 'Hong Kong', 'PEOPLES'],
  ['45412', '454', '12', 'Hong Kong', 'PEOPLES'],
  ['45412', '454', '12', 'Hong Kong', 'PEOPLES'],
  ['45412', '454', '12', 'Hong Kong', 'PEOPLES'],
  ['45412', '454', '12', 'Hong Kong', 'PEOPLES'],
  ['45412', '454', '12', 'Hong Kong', 'PEOPLES'],
  ['45412', '454', '12', 'Hong Kong', 'PEOPLES'],
  ['45413', '454', '13', 'Hong Kong', 'China Mobile (Peoples)'],
  ['45413', '454', '13', 'Hong Kong', 'China Mobile (Peoples)'],
  ['45413', '454', '13', 'Hong Kong', 'China Mobile (Peoples)'],
  ['45413', '454', '13', 'Hong Kong', 'China Mobile (Peoples)'],
  ['45413', '454', '13', 'Hong Kong', 'China Mobile (Peoples)'],
  ['45413', '454', '13', 'Hong Kong', 'China Mobile (Peoples)'],
  ['45413', '454', '13', 'Hong Kong', 'China Mobile (Peoples)'],
  ['45413', '454', '13', 'Hong Kong', 'China Mobile (Peoples)'],
  ['45413', '454', '13', 'Hong Kong', 'China Mobile (Peoples)'],
  ['45413', '454', '13', 'Hong Kong', 'China Mobile (Peoples)'],
  ['45413', '454', '13', 'Hong Kong', 'China Mobile (Peoples)'],
  ['45413', '454', '13', 'Hong Kong', 'China Mobile (Peoples)'],
  ['45413', '454', '13', 'Hong Kong', 'China Mobile (Peoples)'],
  ['45413', '454', '13', 'Hong Kong', 'China Mobile (Peoples)'],
  ['45413', '454', '13', 'Hong Kong', 'China Mobile (Peoples)'],
  ['45413', '454', '13', 'Hong Kong', 'China Mobile (Peoples)'],
  ['45413', '454', '13', 'Hong Kong', 'China Mobile (Peoples)'],
  ['45413', '454', '13', 'Hong Kong', 'China Mobile (Peoples)'],
  ['45413', '454', '13', 'Hong Kong', 'China Mobile (Peoples)'],
  ['45413', '454', '13', 'Hong Kong', 'China Mobile (Peoples)'],
  ['45413', '454', '13', 'Hong Kong', 'China Mobile (Peoples)'],
  ['45413', '454', '13', 'Hong Kong', 'China Mobile (Peoples)'],
  ['45413', '454', '13', 'Hong Kong', 'China Mobile (Peoples)'],
  ['45413', '454', '13', 'Hong Kong', 'China Mobile (Peoples)'],
  ['45413', '454', '13', 'Hong Kong', 'China Mobile (Peoples)'],
  ['45413', '454', '13', 'Hong Kong', 'China Mobile (Peoples)'],
  ['45413', '454', '13', 'Hong Kong', 'China Mobile (Peoples)'],
  ['45413', '454', '13', 'Hong Kong', 'China Mobile (Peoples)'],
  ['45413', '454', '13', 'Hong Kong', 'China Mobile (Peoples)'],
  ['45413', '454', '13', 'Hong Kong', 'China Mobile (Peoples)'],
  ['45413', '454', '13', 'Hong Kong', 'China Mobile (Peoples)'],
  ['45413', '454', '13', 'Hong Kong', 'China Mobile (Peoples)'],
  ['45413', '454', '13', 'Hong Kong', 'China Mobile (Peoples)'],
  ['45413', '454', '13', 'Hong Kong', 'China Mobile (Peoples)'],
  ['45413', '454', '13', 'Hong Kong', 'China Mobile (Peoples)'],
  ['45413', '454', '13', 'Hong Kong', 'China Mobile (Peoples)'],
  ['45413', '454', '13', 'Hong Kong', 'China Mobile (Peoples)'],
  ['45413', '454', '13', 'Hong Kong', 'China Mobile (Peoples)'],
  ['45413', '454', '13', 'Hong Kong', 'China Mobile (Peoples)'],
  ['45413', '454', '13', 'Hong Kong', 'China Mobile (Peoples)'],
  ['45413', '454', '13', 'Hong Kong', 'China Mobile (Peoples)'],
  ['45413', '454', '13', 'Hong Kong', 'China Mobile (Peoples)'],
  ['45413', '454', '13', 'Hong Kong', 'China Mobile (Peoples)'],
  ['45413', '454', '13', 'Hong Kong', 'China Mobile (Peoples)'],
  ['45413', '454', '13', 'Hong Kong', 'China Mobile (Peoples)'],
  ['45413', '454', '13', 'Hong Kong', 'China Mobile (Peoples)'],
  ['45413', '454', '13', 'Hong Kong', 'China Mobile (Peoples)'],
  ['45413', '454', '13', 'Hong Kong', 'China Mobile (Peoples)'],
  ['45413', '454', '13', 'Hong Kong', 'China Mobile (Peoples)'],
  ['45413', '454', '13', 'Hong Kong', 'China Mobile (Peoples)'],
  ['45413', '454', '13', 'Hong Kong', 'China Mobile (Peoples)'],
  ['45413', '454', '13', 'Hong Kong', 'China Mobile (Peoples)'],
  ['45413', '454', '13', 'Hong Kong', 'China Mobile (Peoples)'],
  ['45413', '454', '13', 'Hong Kong', 'China Mobile (Peoples)'],
  ['45413', '454', '13', 'Hong Kong', 'China Mobile (Peoples)'],
  ['45413', '454', '13', 'Hong Kong', 'China Mobile (Peoples)'],
  ['45413', '454', '13', 'Hong Kong', 'China Mobile (Peoples)'],
  ['45413', '454', '13', 'Hong Kong', 'China Mobile (Peoples)'],
  ['45413', '454', '13', 'Hong Kong', 'China Mobile (Peoples)'],
  ['45415', '454', '15', 'Hong Kong', 'SmarTone'],
  ['45415', '454', '15', 'Hong Kong', 'SmarTone'],
  ['45415', '454', '15', 'Hong Kong', 'SmarTone'],
  ['45415', '454', '15', 'Hong Kong', 'SmarTone'],
  ['45415', '454', '15', 'Hong Kong', 'SmarTone'],
  ['45415', '454', '15', 'Hong Kong', 'SmarTone'],
  ['45415', '454', '15', 'Hong Kong', 'SmarTone'],
  ['45415', '454', '15', 'Hong Kong', 'SmarTone'],
  ['45415', '454', '15', 'Hong Kong', 'SmarTone'],
  ['45415', '454', '15', 'Hong Kong', 'SmarTone'],
  ['45415', '454', '15', 'Hong Kong', 'SmarTone'],
  ['45415', '454', '15', 'Hong Kong', 'SmarTone'],
  ['45415', '454', '15', 'Hong Kong', 'SmarTone'],
  ['45415', '454', '15', 'Hong Kong', 'SmarTone'],
  ['45415', '454', '15', 'Hong Kong', 'SmarTone'],
  ['45415', '454', '15', 'Hong Kong', 'SmarTone'],
  ['45415', '454', '15', 'Hong Kong', 'SmarTone'],
  ['45415', '454', '15', 'Hong Kong', 'SmarTone'],
  ['45415', '454', '15', 'Hong Kong', 'SmarTone'],
  ['45415', '454', '15', 'Hong Kong', 'SmarTone'],
  ['45415', '454', '15', 'Hong Kong', 'SmarTone'],
  ['45415', '454', '15', 'Hong Kong', 'SmarTone'],
  ['45415', '454', '15', 'Hong Kong', 'SmarTone'],
  ['45415', '454', '15', 'Hong Kong', 'SmarTone'],
  ['45415', '454', '15', 'Hong Kong', 'SmarTone'],
  ['45415', '454', '15', 'Hong Kong', 'SmarTone'],
  ['45415', '454', '15', 'Hong Kong', 'SmarTone'],
  ['45415', '454', '15', 'Hong Kong', 'SmarTone'],
  ['45415', '454', '15', 'Hong Kong', 'SmarTone'],
  ['45415', '454', '15', 'Hong Kong', 'SmarTone'],
  ['45415', '454', '15', 'Hong Kong', 'SmarTone'],
  ['45415', '454', '15', 'Hong Kong', 'SmarTone'],
  ['45415', '454', '15', 'Hong Kong', 'SmarTone'],
  ['45415', '454', '15', 'Hong Kong', 'SmarTone'],
  ['45415', '454', '15', 'Hong Kong', 'SmarTone'],
  ['45416', '454', '16', 'Hong Kong', 'SUNDAY'],
  ['45416', '454', '16', 'Hong Kong', 'SUNDAY'],
  ['45416', '454', '16', 'Hong Kong', 'SUNDAY'],
  ['45416', '454', '16', 'Hong Kong', 'SUNDAY'],
  ['45416', '454', '16', 'Hong Kong', 'SUNDAY'],
  ['45416', '454', '16', 'Hong Kong', 'SUNDAY'],
  ['45416', '454', '16', 'Hong Kong', 'SUNDAY'],
  ['45416', '454', '16', 'Hong Kong', 'SUNDAY'],
  ['45416', '454', '16', 'Hong Kong', 'SUNDAY'],
  ['45416', '454', '16', 'Hong Kong', 'SUNDAY'],
  ['45417', '454', '17', 'Hong Kong', 'SmarTone'],
  ['45417', '454', '17', 'Hong Kong', 'SmarTone'],
  ['45417', '454', '17', 'Hong Kong', 'SmarTone'],
  ['45417', '454', '17', 'Hong Kong', 'SmarTone'],
  ['45417', '454', '17', 'Hong Kong', 'SmarTone'],
  ['45417', '454', '17', 'Hong Kong', 'SmarTone'],
  ['45417', '454', '17', 'Hong Kong', 'SmarTone'],
  ['45417', '454', '17', 'Hong Kong', 'SmarTone'],
  ['45417', '454', '17', 'Hong Kong', 'SmarTone'],
  ['45417', '454', '17', 'Hong Kong', 'SmarTone'],
  ['45417', '454', '17', 'Hong Kong', 'SmarTone'],
  ['45417', '454', '17', 'Hong Kong', 'SmarTone'],
  ['45417', '454', '17', 'Hong Kong', 'SmarTone'],
  ['45417', '454', '17', 'Hong Kong', 'SmarTone'],
  ['45417', '454', '17', 'Hong Kong', 'SmarTone'],
  ['45417', '454', '17', 'Hong Kong', 'SmarTone'],
  ['45417', '454', '17', 'Hong Kong', 'SmarTone'],
  ['45417', '454', '17', 'Hong Kong', 'SmarTone'],
  ['45417', '454', '17', 'Hong Kong', 'SmarTone'],
  ['45417', '454', '17', 'Hong Kong', 'SmarTone'],
  ['45417', '454', '17', 'Hong Kong', 'SmarTone'],
  ['45417', '454', '17', 'Hong Kong', 'SmarTone'],
  ['45417', '454', '17', 'Hong Kong', 'SmarTone'],
  ['45417', '454', '17', 'Hong Kong', 'SmarTone'],
  ['45417', '454', '17', 'Hong Kong', 'SmarTone'],
  ['45417', '454', '17', 'Hong Kong', 'SmarTone'],
  ['45417', '454', '17', 'Hong Kong', 'SmarTone'],
  ['45417', '454', '17', 'Hong Kong', 'SmarTone'],
  ['45417', '454', '17', 'Hong Kong', 'SmarTone'],
  ['45417', '454', '17', 'Hong Kong', 'SmarTone'],
  ['45417', '454', '17', 'Hong Kong', 'SmarTone'],
  ['45417', '454', '17', 'Hong Kong', 'SmarTone'],
  ['45417', '454', '17', 'Hong Kong', 'SmarTone'],
  ['45417', '454', '17', 'Hong Kong', 'SmarTone'],
  ['45417', '454', '17', 'Hong Kong', 'SmarTone'],
  ['45419', '454', '19', 'Hong Kong', 'PCCW'],
  ['45419', '454', '19', 'Hong Kong', 'PCCW'],
  ['45419', '454', '19', 'Hong Kong', 'PCCW'],
  ['45419', '454', '19', 'Hong Kong', 'PCCW'],
  ['45419', '454', '19', 'Hong Kong', 'PCCW'],
  ['45419', '454', '19', 'Hong Kong', 'PCCW'],
  ['45419', '454', '19', 'Hong Kong', 'PCCW'],
  ['45419', '454', '19', 'Hong Kong', 'PCCW'],
  ['45419', '454', '19', 'Hong Kong', 'PCCW'],
  ['45419', '454', '19', 'Hong Kong', 'PCCW'],
  ['45419', '454', '19', 'Hong Kong', 'PCCW'],
  ['45419', '454', '19', 'Hong Kong', 'PCCW'],
  ['45419', '454', '19', 'Hong Kong', 'PCCW'],
  ['45419', '454', '19', 'Hong Kong', 'PCCW'],
  ['45419', '454', '19', 'Hong Kong', 'PCCW'],
  ['45419', '454', '19', 'Hong Kong', 'PCCW'],
  ['45419', '454', '19', 'Hong Kong', 'PCCW'],
  ['45419', '454', '19', 'Hong Kong', 'PCCW'],
  ['45419', '454', '19', 'Hong Kong', 'PCCW'],
  ['45419', '454', '19', 'Hong Kong', 'PCCW'],
  ['45419', '454', '19', 'Hong Kong', 'PCCW'],
  ['45419', '454', '19', 'Hong Kong', 'PCCW'],
  ['45419', '454', '19', 'Hong Kong', 'PCCW'],
  ['45419', '454', '19', 'Hong Kong', 'PCCW'],
  ['45419', '454', '19', 'Hong Kong', 'PCCW'],
  ['45419', '454', '19', 'Hong Kong', 'PCCW'],
  ['45419', '454', '19', 'Hong Kong', 'PCCW'],
  ['45419', '454', '19', 'Hong Kong', 'PCCW'],
  ['45419', '454', '19', 'Hong Kong', 'PCCW'],
  ['45419', '454', '19', 'Hong Kong', 'PCCW'],
  ['45419', '454', '19', 'Hong Kong', 'PCCW'],
  ['45419', '454', '19', 'Hong Kong', 'PCCW'],
  ['45419', '454', '19', 'Hong Kong', 'PCCW'],
  ['45419', '454', '19', 'Hong Kong', 'PCCW'],
  ['45419', '454', '19', 'Hong Kong', 'PCCW'],
  ['45419', '454', '19', 'Hong Kong', 'PCCW'],
  ['45419', '454', '19', 'Hong Kong', 'PCCW'],
  ['45419', '454', '19', 'Hong Kong', 'PCCW'],
  ['45419', '454', '19', 'Hong Kong', 'PCCW'],
  ['45419', '454', '19', 'Hong Kong', 'PCCW'],
  ['45419', '454', '19', 'Hong Kong', 'PCCW'],
  ['45419', '454', '19', 'Hong Kong', 'PCCW'],
  ['45419', '454', '19', 'Hong Kong', 'PCCW'],
  ['45419', '454', '19', 'Hong Kong', 'PCCW'],
  ['45419', '454', '19', 'Hong Kong', 'PCCW'],
  ['45419', '454', '19', 'Hong Kong', 'PCCW'],
  ['45419', '454', '19', 'Hong Kong', 'PCCW'],
  ['45419', '454', '19', 'Hong Kong', 'PCCW'],
  ['45419', '454', '19', 'Hong Kong', 'PCCW'],
  ['45419', '454', '19', 'Hong Kong', 'PCCW'],
  ['45420', '454', '20', 'Hong Kong', 'PCCW'],
  ['45420', '454', '20', 'Hong Kong', 'PCCW'],
  ['45420', '454', '20', 'Hong Kong', 'PCCW'],
  ['45420', '454', '20', 'Hong Kong', 'PCCW'],
  ['45420', '454', '20', 'Hong Kong', 'PCCW'],
  ['45420', '454', '20', 'Hong Kong', 'PCCW'],
  ['45420', '454', '20', 'Hong Kong', 'PCCW'],
  ['45420', '454', '20', 'Hong Kong', 'PCCW'],
  ['45420', '454', '20', 'Hong Kong', 'PCCW'],
  ['45420', '454', '20', 'Hong Kong', 'PCCW'],
  ['45420', '454', '20', 'Hong Kong', 'PCCW'],
  ['45420', '454', '20', 'Hong Kong', 'PCCW'],
  ['45420', '454', '20', 'Hong Kong', 'PCCW'],
  ['45420', '454', '20', 'Hong Kong', 'PCCW'],
  ['45420', '454', '20', 'Hong Kong', 'PCCW'],
  ['45420', '454', '20', 'Hong Kong', 'PCCW'],
  ['45420', '454', '20', 'Hong Kong', 'PCCW'],
  ['45420', '454', '20', 'Hong Kong', 'PCCW'],
  ['45420', '454', '20', 'Hong Kong', 'PCCW'],
  ['45420', '454', '20', 'Hong Kong', 'PCCW'],
  ['45420', '454', '20', 'Hong Kong', 'PCCW'],
  ['45420', '454', '20', 'Hong Kong', 'PCCW'],
  ['45420', '454', '20', 'Hong Kong', 'PCCW'],
  ['45420', '454', '20', 'Hong Kong', 'PCCW'],
  ['45420', '454', '20', 'Hong Kong', 'PCCW'],
  ['45420', '454', '20', 'Hong Kong', 'PCCW'],
  ['45420', '454', '20', 'Hong Kong', 'PCCW'],
  ['45420', '454', '20', 'Hong Kong', 'PCCW'],
  ['45420', '454', '20', 'Hong Kong', 'PCCW'],
  ['45420', '454', '20', 'Hong Kong', 'PCCW'],
  ['45420', '454', '20', 'Hong Kong', 'PCCW'],
  ['45420', '454', '20', 'Hong Kong', 'PCCW'],
  ['45420', '454', '20', 'Hong Kong', 'PCCW'],
  ['45420', '454', '20', 'Hong Kong', 'PCCW'],
  ['45420', '454', '20', 'Hong Kong', 'PCCW'],
  ['45420', '454', '20', 'Hong Kong', 'PCCW'],
  ['45420', '454', '20', 'Hong Kong', 'PCCW'],
  ['45420', '454', '20', 'Hong Kong', 'PCCW'],
  ['45420', '454', '20', 'Hong Kong', 'PCCW'],
  ['45420', '454', '20', 'Hong Kong', 'PCCW'],
  ['45420', '454', '20', 'Hong Kong', 'PCCW'],
  ['45420', '454', '20', 'Hong Kong', 'PCCW'],
  ['45420', '454', '20', 'Hong Kong', 'PCCW'],
  ['45420', '454', '20', 'Hong Kong', 'PCCW'],
  ['45420', '454', '20', 'Hong Kong', 'PCCW'],
  ['45420', '454', '20', 'Hong Kong', 'PCCW'],
  ['45420', '454', '20', 'Hong Kong', 'PCCW'],
  ['45420', '454', '20', 'Hong Kong', 'PCCW'],
  ['45420', '454', '20', 'Hong Kong', 'PCCW'],
  ['45420', '454', '20', 'Hong Kong', 'PCCW'],
  ['45424', '454', '24', 'Hong Kong', 'MultiByte'],
  ['45424', '454', '24', 'Hong Kong', 'MultiByte'],
  ['45424', '454', '24', 'Hong Kong', 'MultiByte'],
  ['45424', '454', '24', 'Hong Kong', 'MultiByte'],
  ['45424', '454', '24', 'Hong Kong', 'MultiByte'],
  ['45424', '454', '24', 'Hong Kong', 'MultiByte'],
  ['45424', '454', '24', 'Hong Kong', 'MultiByte'],
  ['45424', '454', '24', 'Hong Kong', 'MultiByte'],
  ['45424', '454', '24', 'Hong Kong', 'MultiByte'],
  ['45424', '454', '24', 'Hong Kong', 'MultiByte'],
  ['45424', '454', '24', 'Hong Kong', 'MultiByte'],
  ['45429', '454', '29', 'Hong Kong', 'PCCW'],
  ['45429', '454', '29', 'Hong Kong', 'PCCW'],
  ['45429', '454', '29', 'Hong Kong', 'PCCW'],
  ['45429', '454', '29', 'Hong Kong', 'PCCW'],
  ['45429', '454', '29', 'Hong Kong', 'PCCW'],
  ['45429', '454', '29', 'Hong Kong', 'PCCW'],
  ['45429', '454', '29', 'Hong Kong', 'PCCW'],
  ['45429', '454', '29', 'Hong Kong', 'PCCW'],
  ['45429', '454', '29', 'Hong Kong', 'PCCW'],
  ['45429', '454', '29', 'Hong Kong', 'PCCW'],
  ['45429', '454', '29', 'Hong Kong', 'PCCW'],
  ['45429', '454', '29', 'Hong Kong', 'PCCW'],
  ['45429', '454', '29', 'Hong Kong', 'PCCW'],
  ['45429', '454', '29', 'Hong Kong', 'PCCW'],
  ['45429', '454', '29', 'Hong Kong', 'PCCW'],
  ['45429', '454', '29', 'Hong Kong', 'PCCW'],
  ['45429', '454', '29', 'Hong Kong', 'PCCW'],
  ['45429', '454', '29', 'Hong Kong', 'PCCW'],
  ['45429', '454', '29', 'Hong Kong', 'PCCW'],
  ['45429', '454', '29', 'Hong Kong', 'PCCW'],
  ['45429', '454', '29', 'Hong Kong', 'PCCW'],
  ['45429', '454', '29', 'Hong Kong', 'PCCW'],
  ['45429', '454', '29', 'Hong Kong', 'PCCW'],
  ['45429', '454', '29', 'Hong Kong', 'PCCW'],
  ['45429', '454', '29', 'Hong Kong', 'PCCW'],
  ['45429', '454', '29', 'Hong Kong', 'PCCW'],
  ['45429', '454', '29', 'Hong Kong', 'PCCW'],
  ['45429', '454', '29', 'Hong Kong', 'PCCW'],
  ['45429', '454', '29', 'Hong Kong', 'PCCW'],
  ['45429', '454', '29', 'Hong Kong', 'PCCW'],
  ['45429', '454', '29', 'Hong Kong', 'PCCW'],
  ['45429', '454', '29', 'Hong Kong', 'PCCW'],
  ['45429', '454', '29', 'Hong Kong', 'PCCW'],
  ['45429', '454', '29', 'Hong Kong', 'PCCW'],
  ['45429', '454', '29', 'Hong Kong', 'PCCW'],
  ['45429', '454', '29', 'Hong Kong', 'PCCW'],
  ['45429', '454', '29', 'Hong Kong', 'PCCW'],
  ['45429', '454', '29', 'Hong Kong', 'PCCW'],
  ['45429', '454', '29', 'Hong Kong', 'PCCW'],
  ['45429', '454', '29', 'Hong Kong', 'PCCW'],
  ['45429', '454', '29', 'Hong Kong', 'PCCW'],
  ['45429', '454', '29', 'Hong Kong', 'PCCW'],
  ['45429', '454', '29', 'Hong Kong', 'PCCW'],
  ['45429', '454', '29', 'Hong Kong', 'PCCW'],
  ['45429', '454', '29', 'Hong Kong', 'PCCW'],
  ['45429', '454', '29', 'Hong Kong', 'PCCW'],
  ['45429', '454', '29', 'Hong Kong', 'PCCW'],
  ['45429', '454', '29', 'Hong Kong', 'PCCW'],
  ['45429', '454', '29', 'Hong Kong', 'PCCW'],
  ['45429', '454', '29', 'Hong Kong', 'PCCW'],
  ['2160', '216', '0', 'Hungary', 'DEFAULT HUNGARY'],
  ['21601', '216', '1', 'Hungary', 'PANNON'],
  ['21603', '216', '3', 'Hungary', 'Digi'],
  ['21603', '216', '3', 'Hungary', 'Digi'],
  ['21630', '216', '30', 'Hungary', 'T-MOBILE'],
  ['21670', '216', '70', 'Hungary', 'VODAFONE'],
  ['2740', '274', '0', 'Iceland', 'DEFAULT ICELAND'],
  ['27401', '274', '1', 'Iceland', 'LANDSSIMINN'],
  ['27401', '274', '1', 'Iceland', 'LANDSSIMINN'],
  ['27401', '274', '1', 'Iceland', 'LANDSSIMINN'],
  ['27401', '274', '1', 'Iceland', 'LANDSSIMINN'],
  ['27401', '274', '1', 'Iceland', 'LANDSSIMINN'],
  ['27401', '274', '1', 'Iceland', 'LANDSSIMINN'],
  ['27401', '274', '1', 'Iceland', 'LANDSSIMINN'],
  ['27401', '274', '1', 'Iceland', 'LANDSSIMINN'],
  ['27401', '274', '1', 'Iceland', 'LANDSSIMINN'],
  ['27401', '274', '1', 'Iceland', 'LANDSSIMINN'],
  ['27402', '274', '2', 'Iceland', 'VODAFONE'],
  ['27402', '274', '2', 'Iceland', 'VODAFONE'],
  ['27402', '274', '2', 'Iceland', 'VODAFONE'],
  ['27402', '274', '2', 'Iceland', 'VODAFONE'],
  ['27404', '274', '4', 'Iceland', 'IMC'],
  ['27404', '274', '4', 'Iceland', 'IMC'],
  ['27404', '274', '4', 'Iceland', 'IMC'],
  ['4040', '404', '0', 'India', 'DEFAULT INDIA'],
  ['40401', '404', '1', 'India', 'AIRCELL DIGILINK (HARYANA)'],
  ['40402', '404', '2', 'India', 'AIRTEL BHARTI CELLULAR (PUNJAB)'],
  ['40402', '404', '2', 'India', 'AIRTEL BHARTI CELLULAR (PUNJAB)'],
  ['40402', '404', '2', 'India', 'AIRTEL BHARTI CELLULAR (PUNJAB)'],
  ['40402', '404', '2', 'India', 'AIRTEL BHARTI CELLULAR (PUNJAB)'],
  ['40402', '404', '2', 'India', 'AIRTEL BHARTI CELLULAR (PUNJAB)'],
  ['40402', '404', '2', 'India', 'AIRTEL BHARTI CELLULAR (MUMBAI)'],
  ['40402', '404', '2', 'India', 'AIRTEL BHARTI CELLULAR (DELHI)'],
  ['40402', '404', '2', 'India', 'AIRTEL BHARTI CELLULAR (PUNJAB)'],
  ['40402', '404', '2', 'India', 'AIRTEL BHARTI CELLULAR (PUNJAB)'],
  ['40402', '404', '2', 'India', 'AIRTEL BHARTI CELLULAR (PUNJAB)'],
  ['40402', '404', '2', 'India', 'AIRTEL BHARTI CELLULAR (DELHI)'],
  ['40402', '404', '2', 'India', 'AIRTEL BHARTI CELLULAR (DELHI)'],
  ['40402', '404', '2', 'India', 'AIRTEL BHARTI CELLULAR (DELHI)'],
  ['40402', '404', '2', 'India', 'AIRTEL BHARTI CELLULAR (HARYANA)'],
  ['40402', '404', '2', 'India', 'AIRTEL BHARTI CELLULAR (HIMACHAL P)'],
  ['40402', '404', '2', 'India', 'AIRTEL BHARTI CELLULAR (KARNATAKA)'],
  ['40402', '404', '2', 'India', 'AIRTEL BHARTI CELLULAR (DELHI)'],
  ['40402', '404', '2', 'India', 'AIRTEL BHARTI CELLULAR (MUMBAI)'],
  ['40402', '404', '2', 'India', 'AIRTEL BHARTI CELLULAR (MUMBAI)'],
  ['40402', '404', '2', 'India', 'AIRTEL BHARTI CELLULAR (MUMBAI)'],
  ['40402', '404', '2', 'India', 'AIRTEL BHARTI CELLULAR (DELHI)'],
  ['40402', '404', '2', 'India', 'AIRTEL BHARTI CELLULAR (DELHI)'],
  ['40402', '404', '2', 'India', 'AIRTEL BHARTI CELLULAR (DELHI)'],
  ['40402', '404', '2', 'India', 'AIRTEL BHARTI CELLULAR (MUMBAI)'],
  ['40402', '404', '2', 'India', 'AIRTEL BHARTI CELLULAR (DELHI)'],
  ['40402', '404', '2', 'India', 'AIRTEL BHARTI CELLULAR (DELHI)'],
  ['40402', '404', '2', 'India', 'AIRTEL BHARTI CELLULAR (DELHI)'],
  ['40402', '404', '2', 'India', 'AIRTEL BHARTI CELLULAR (CHENNAI)'],
  ['40402', '404', '2', 'India', 'AIRTEL BHARTI CELLULAR (ANDHRA P)'],
  ['40402', '404', '2', 'India', 'AIRTEL BHARTI CELLULAR (ANDHRA P)'],
  ['40402', '404', '2', 'India', 'AIRTEL BHARTI CELLULAR (ANDHRA P)'],
  ['40402', '404', '2', 'India', 'AIRTEL BHARTI CELLULAR (ANDHRA P)'],
  ['40402', '404', '2', 'India', 'AIRTEL BHARTI CELLULAR (ANDHRA P)'],
  ['40402', '404', '2', 'India', 'AIRTEL BHARTI CELLULAR (ANDHRA P)'],
  ['40402', '404', '2', 'India', 'AIRTEL BHARTI CELLULAR (ANDHRA P)'],
  ['40402', '404', '2', 'India', 'AIRTEL BHARTI CELLULAR (ANDHRA P)'],
  ['40402', '404', '2', 'India', 'AIRTEL BHARTI CELLULAR (ANDHRA P)'],
  ['40402', '404', '2', 'India', 'AIRTEL BHARTI CELLULAR (ANDHRA P)'],
  ['40402', '404', '2', 'India', 'AIRTEL BHARTI CELLULAR (UPWEST)'],
  ['40402', '404', '2', 'India', 'AIRTEL BHARTI CELLULAR (MUMBAI)'],
  ['40402', '404', '2', 'India', 'AIRTEL BHARTI CELLULAR (RAJASTHAN)'],
  ['40402', '404', '2', 'India', 'AIRTEL BHARTI CELLULAR (PUNJAB)'],
  ['40402', '404', '2', 'India', 'AIRTEL BHARTI CELLULAR (ANDHRA P)'],
  ['40402', '404', '2', 'India', 'AIRTEL BHARTI CELLULAR (MAHARASHTRA)'],
  ['40402', '404', '2', 'India', 'AIRTEL BHARTI CELLULAR (PUNJAB)'],
  ['40402', '404', '2', 'India', 'AIRTEL BHARTI CELLULAR (PUNJAB)'],
  ['40402', '404', '2', 'India', 'AIRTEL BHARTI CELLULAR (PUNJAB)'],
  ['40402', '404', '2', 'India', 'AIRTEL BHARTI CELLULAR (MAHARASHTRA)'],
  ['40402', '404', '2', 'India', 'AIRTEL BHARTI CELLULAR (MAHARASHTRA)'],
  ['40402', '404', '2', 'India', 'AIRTEL BHARTI CELLULAR (KARNATAKA)'],
  ['40402', '404', '2', 'India', 'AIRTEL BHARTI CELLULAR (KARNATAKA)'],
  ['40402', '404', '2', 'India', 'AIRTEL BHARTI CELLULAR (DELHI)'],
  ['40402', '404', '2', 'India', 'AIRTEL BHARTI CELLULAR (MAHARASHTRA)'],
  ['40402', '404', '2', 'India', 'AIRTEL BHARTI CELLULAR (KARNATAKA)'],
  ['40402', '404', '2', 'India', 'AIRTEL BHARTI CELLULAR (KARNATAKA)'],
  ['40402', '404', '2', 'India', 'AIRTEL BHARTI CELLULAR (KARNATAKA)'],
  ['40402', '404', '2', 'India', 'AIRTEL BHARTI CELLULAR (KARNATAKA)'],
  ['40402', '404', '2', 'India', 'AIRTEL BHARTI CELLULAR (KERALA)'],
  ['40402', '404', '2', 'India', 'AIRTEL BHARTI CELLULAR (KOLKOTA)'],
  ['40402', '404', '2', 'India', 'AIRTEL BHARTI CELLULAR (MADHYA P)'],
  ['40402', '404', '2', 'India', 'AIRTEL BHARTI CELLULAR (MAHARASHTRA)'],
  ['40402', '404', '2', 'India', 'AIRTEL BHARTI CELLULAR (MAHARASHTRA)'],
  ['40403', '404', '3', 'India', 'Unknown-INDIA-9805'],
  ['40405', '404', '5', 'India', 'FASCEL LIMITED'],
  ['40405', '404', '5', 'India', 'FASCEL LIMITED'],
  ['40407', '404', '7', 'India', 'IDEA MOBILE COMM (HARYANA)'],
  ['40407', '404', '7', 'India', 'IDEA CELLULAR'],
  ['40407', '404', '7', 'India', 'IDEA MOBILE COMM (UPWEST)'],
  ['40407', '404', '7', 'India', 'IDEA MOBILE COMM (KERALA)'],
  ['40407', '404', '7', 'India', 'IDEA CELLULAR (MAHARASHTRA)'],
  ['40407', '404', '7', 'India', 'IDEA CELLULAR (MAHARASHTRA)'],
  ['40407', '404', '7', 'India', 'IDEA CELLULAR (MAHARASHTRA)'],
  ['40407', '404', '7', 'India', 'IDEA CELLULAR (GUJARAT)'],
  ['40407', '404', '7', 'India', 'IDEA CELLULAR (GUJARAT)'],
  ['40407', '404', '7', 'India', 'IDEA CELLULAR (NEW DELHI)'],
  ['40407', '404', '7', 'India', 'IDEA CELLULAR (NEW DELHI)'],
  ['40407', '404', '7', 'India', 'IDEA MOBILE COMM (HARYANA)'],
  ['40407', '404', '7', 'India', 'IDEA MOBILE COMM (HARYANA)'],
  ['40407', '404', '7', 'India', 'IDEA MOBILE COMM (HARYANA)'],
  ['40407', '404', '7', 'India', 'IDEA MOBILE COMM (HARYANA)'],
  ['40407', '404', '7', 'India', 'IDEA MOBILE COMM (HARYANA)'],
  ['40410', '404', '10', 'India', 'BHARTI AIRTEL (DELHI)'],
  ['40411', '404', '11', 'India', 'HUTCHISON ESSAR TELECOM'],
  ['40413', '404', '13', 'India', 'HUTCHISON MAX TELECOM'],
  ['40413', '404', '13', 'India', 'HUTCHISON MAX TELECOM'],
  ['40413', '404', '13', 'India', 'HUTCHISON MAX TELECOM'],
  ['40413', '404', '13', 'India', 'HUTCHISON MAX TELECOM'],
  ['40413', '404', '13', 'India', 'HUTCHISON MAX TELECOM'],
  ['40413', '404', '13', 'India', 'HUTCHISON MAX TELECOM'],
  ['40413', '404', '13', 'India', 'HUTCHISON MAX TELECOM'],
  ['40413', '404', '13', 'India', 'HUTCHISON MAX TELECOM'],
  ['40413', '404', '13', 'India', 'HUTCHISON EAST TELECOM'],
  ['40413', '404', '13', 'India', 'HUTCHISON ESSAR TELECOM'],
  ['40413', '404', '13', 'India', 'HUTCHISON ESSAR TELECOM'],
  ['40413', '404', '13', 'India', 'HUTCHISON ESSAR TELECOM'],
  ['40413', '404', '13', 'India', 'HUTCHISON MAX TELECOM'],
  ['40413', '404', '13', 'India', 'HUTCHISON MAX TELECOM'],
  ['40413', '404', '13', 'India', 'HUTCHISON MAX TELECOM'],
  ['40413', '404', '13', 'India', 'HUTCHISON MAX TELECOM'],
  ['40413', '404', '13', 'India', 'HUTCHISON MAX TELECOM'],
  ['40413', '404', '13', 'India', 'HUTCHISON MAX TELECOM'],
  ['40413', '404', '13', 'India', 'HUTCHISON MAX TELECOM'],
  ['40413', '404', '13', 'India', 'HUTCHISON MAX TELECOM'],
  ['40413', '404', '13', 'India', 'HUTCHISON MAX TELECOM'],
  ['40413', '404', '13', 'India', 'HUTCHISON MAX TELECOM'],
  ['40413', '404', '13', 'India', 'HUTCHISON MAX TELECOM'],
  ['40416', '404', '16', 'India', 'Unknown-INDIA-9894'],
  ['40420', '404', '20', 'India', 'HUTCHISON ESSAR TELECOM'],
  ['40421', '404', '21', 'India', 'Loop Telecom // Mumbai'],
  ['40422', '404', '22', 'India', 'IDEA CELLULAR (MAHARASHTRA)'],
  ['40424', '404', '24', 'India', 'IDEA CELLULAR (GUJARAT)'],
  ['40427', '404', '27', 'India', 'HUTCHISON ESSAR (MAHARASHTRA)'],
  ['40434', '404', '34', 'India', 'BSNL (BIHAR)'],
  ['40434', '404', '34', 'India', 'BSNL (ANDHRA PRADESH)'],
  ['40434', '404', '34', 'India', 'BSNL (WEST BENGAL)'],
  ['40434', '404', '34', 'India', 'BSNL (MATHYA PRADESH)'],
  ['40434', '404', '34', 'India', 'BSNL (ORISSA)'],
  ['40434', '404', '34', 'India', 'BSNL (RAJASTAN)'],
  ['40434', '404', '34', 'India', 'BSNL (TAMILNADU)'],
  ['40434', '404', '34', 'India', 'BSNL (UP WEST)'],
  ['40434', '404', '34', 'India', 'BSNL (KARNATAKA)'],
  ['40434', '404', '34', 'India', 'BSNL (KERALA)'],
  ['40434', '404', '34', 'India', 'BSNL (MAHARASHTRA)'],
  ['40434', '404', '34', 'India', 'BSNL (WEST BENGAL)'],
  ['40434', '404', '34', 'India', 'BSNL (HARYANA)'],
  ['40434', '404', '34', 'India', 'BSNL (HIMACHAL PRADESH)'],
  ['40440', '404', '40', 'India', 'Unknown-INDIA-9500'],
  ['40441', '404', '41', 'India', 'RPG CELLULAR SERVICES'],
  ['40442', '404', '42', 'India', 'AIRCEL (TAMIL NADU)'],
  ['40443', '404', '43', 'India', 'HUTCHISON ESSAR (TAMIL NADU)'],
  ['40444', '404', '44', 'India', 'SPICE TELECOM (KARNATAKA)'],
  ['40444', '404', '44', 'India', 'SPICE TELECOM (PUNJAB)'],
  ['40444', '404', '44', 'India', 'SPICE TELECOM (PUNJAB)'],
  ['40444', '404', '44', 'India', 'SPICE TELECOM (PUNJAB)'],
  ['40444', '404', '44', 'India', 'SPICE TELECOM (PUNJAB)'],
  ['40445', '404', '45', 'India', 'AIRTEL'],
  ['40446', '404', '46', 'India', 'BPL MOBILE CELLULAR (MAHARASHTRA)'],
  ['40446', '404', '46', 'India', 'BPL MOBILE CELLULAR (KERALA)'],
  ['40446', '404', '46', 'India', 'BPL MOBILE CELLULAR (TAMILNADU)'],
  ['40446', '404', '46', 'India', 'BPL MOBILE COMM (MUMBAI)'],
  ['40446', '404', '46', 'India', 'BPL MOBILE COMM (MUMBAI)'],
  ['40449', '404', '49', 'India', 'BHARTI AIRTEL (ANDRA PRADESH)'],
  ['40460', '404', '60', 'India', 'AIRCELL DIGILINK (RAJASTHAN)'],
  ['40460', '404', '60', 'India', 'AIRCELL DIGILINK (RAJASTHAN)'],
  ['40460', '404', '60', 'India', 'AIRCELL DIGILINK (RAJASTHAN)'],
  ['40460', '404', '60', 'India', 'AIRCELL DIGILINK (RAJASTHAN)'],
  ['40460', '404', '60', 'India', 'AIRCELL DIGILINK (RAJASTHAN)'],
  ['40467', '404', '67', 'India', 'RELIANCE TELECOM'],
  ['40467', '404', '67', 'India', 'RELIANCE TELECOM'],
  ['40467', '404', '67', 'India', 'RELIANCE TELECOM'],
  ['40467', '404', '67', 'India', 'RELIANCE TELECOM'],
  ['40467', '404', '67', 'India', 'RELIANCE TELECOM'],
  ['40467', '404', '67', 'India', 'RELIANCE TELECOM'],
  ['40467', '404', '67', 'India', 'RELIANCE TELECOM'],
  ['40468', '404', '68', 'India', 'MTNL (DELHI)'],
  ['40493', '404', '93', 'India', 'BHARTI AIRTEL (MADHYA PRADESH)'],
  ['40495', '404', '95', 'India', 'BHARTI AIRTEL (KERALA)'],
  ['40496', '404', '96', 'India', 'BHARTI AIRTEL (HARYANA)'],
  ['40498', '404', '98', 'India', 'BHARTI AIRTEL (GUJARAT)'],
  ['40498', '404', '98', 'India', 'BHARTI AIRTEL (GUJARAT)'],
  ['40498', '404', '98', 'India', 'BHARTI AIRTEL (GUJARAT)'],
  ['40498', '404', '98', 'India', 'BHARTI AIRTEL (GUJARAT)'],
  ['405007', '405', '7', 'India', 'RELIANCE (HARYANA)'],
  ['405013', '405', '13', 'India', 'RELIANCE (MAHARASHTRA)'],
  ['405015', '405', '15', 'India', 'RELIANCE (MUMBAI)'],
  ['405025', '405', '25', 'India', 'TATA TELESERVICES (Andhra Pradesh)'],
  ['405030', '405', '30', 'India', 'TEL (ORISSA)'],
  ['405031', '405', '31', 'India', 'TATA TELESERVICES (HARYANA)'],
  ['405800', '405', '800', 'India', 'AIRCEL (DELHI)'],
  ['405807', '405', '807', 'India', 'AIRCEL'],
  ['405846', '405', '846', 'India', 'Unknown-INDIA-9884'],
  ['405862', '405', '862', 'India', 'LOOP (KARNATAKA)'],
  ['405886', '405', '886', 'India', 'TEL (ORISSA)'],
  ['5100', '510', '0', 'Indonesia', 'DEFAULT INDONESIA'],
  ['51001', '510', '1', 'Indonesia', 'SATELINDO'],
  ['51001', '510', '1', 'Indonesia', 'SATELINDO'],
  ['51010', '510', '10', 'Indonesia', 'TELKOMSEL'],
  ['51010', '510', '10', 'Indonesia', 'TELKOMSEL'],
  ['51010', '510', '10', 'Indonesia', 'TELKOMSEL'],
  ['51010', '510', '10', 'Indonesia', 'TELKOMSEL'],
  ['51011', '510', '11', 'Indonesia', 'EXCELCOMINDO'],
  ['51011', '510', '11', 'Indonesia', 'EXCELCOMINDO'],
  ['51011', '510', '11', 'Indonesia', 'EXCELCOMINDO'],
  ['51011', '510', '11', 'Indonesia', 'EXCELCOMINDO'],
  ['51021', '510', '21', 'Indonesia', 'INDOSAT'],
  ['51021', '510', '21', 'Indonesia', 'INDOSAT'],
  ['51021', '510', '21', 'Indonesia', 'INDOSAT'],
  ['311440', '311', '440', 'International', 'Unknown-Unknown-0772'],
  ['311580', '311', '580', 'International', 'Unknown-Unknown-4577'],
  ['311660', '311', '660', 'International', 'Unknown-Unknown-7293'],
  ['311870', '311', '870', 'International', 'Unknown-Unknown-5120'],
  ['316010', '316', '10', 'International', 'Unknown-Unknown-2503'],
  ['405001', '405', '1', 'International', 'Unknown-Unknown-5337'],
  ['405002', '405', '2', 'International', 'TATA (DOCOMO)'],
  ['405079', '405', '79', 'International', 'IDEA (MUMBAI)'],
  ['60600', '606', '0', 'International', 'Unknown-Unknown-9274'],
  ['60601', '606', '1', 'International', 'Unknown-Unknown-9137'],
  ['4320', '432', '0', 'Iran', 'DEFAULT IRAN'],
  ['43211', '432', '11', 'Iran', 'MCI'],
  ['43211', '432', '11', 'Iran', 'MCI'],
  ['43211', '432', '11', 'Iran', 'MCI'],
  ['43211', '432', '11', 'Iran', 'MCI'],
  ['43211', '432', '11', 'Iran', 'MCI'],
  ['43211', '432', '11', 'Iran', 'MCI'],
  ['43214', '432', '14', 'Iran', 'KFZO (TKC)'],
  ['43219', '432', '19', 'Iran', 'MTCE'],
  ['43220', '432', '20', 'Iran', 'RighTel Communications (Tamin Telecom)'],
  ['43220', '432', '20', 'Iran', 'RighTel Communications (Tamin Telecom)'],
  ['43232', '432', '32', 'Iran', 'Taliya'],
  ['43235', '432', '35', 'Iran', 'MTN Irancell'],
  ['43235', '432', '35', 'Iran', 'MTN Irancell'],
  ['43235', '432', '35', 'Iran', 'MTN Irancell'],
  ['4180', '418', '0', 'Iraq', 'DEFAULT IRAQ'],
  ['41805', '418', '5', 'Iraq', 'ASIA CELL'],
  ['41808', '418', '8', 'Iraq', 'SANATEL'],
  ['41820', '418', '20', 'Iraq', 'ATHEER'],
  ['41830', '418', '30', 'Iraq', 'ORASCOM'],
  ['41840', '418', '40', 'Iraq', 'KOREK'],
  ['41840', '418', '40', 'Iraq', 'KOREK TEL'],
  ['41845', '418', '45', 'Iraq', 'Mobitel'],
  ['41862', '418', '62', 'Iraq', 'ITISALUNA'],
  ['41892', '418', '92', 'Iraq', 'Omnea (Al-Nakheel)'],
  ['2720', '272', '0', 'Ireland', 'DEFAULT IRELAND'],
  ['27201', '272', '1', 'Ireland', 'VODAFONE'],
  ['27202', '272', '2', 'Ireland', 'O2 DIGIFONE'],
  ['27203', '272', '3', 'Ireland', 'METEOR'],
  ['27205', '272', '5', 'Ireland', 'HI3G'],
  ['27213', '272', '13', 'Ireland', 'Lycamobile'],
  ['27213', '272', '13', 'Ireland', 'Lycamobile'],
  ['27213', '272', '13', 'Ireland', 'Lycamobile'],
  ['27213', '272', '13', 'Ireland', 'Lycamobile'],
  ['27213', '272', '13', 'Ireland', 'Lycamobile'],
  ['27213', '272', '13', 'Ireland', 'Lycamobile'],
  ['27213', '272', '13', 'Ireland', 'Lycamobile'],
  ['27213', '272', '13', 'Ireland', 'Lycamobile'],
  ['27213', '272', '13', 'Ireland', 'Lycamobile'],
  ['27213', '272', '13', 'Ireland', 'Lycamobile'],
  ['27213', '272', '13', 'Ireland', 'Lycamobile'],
  ['4250', '425', '0', 'Israel', 'DEFAULT ISRAEL'],
  ['42501', '425', '1', 'Israel', 'PARTNER ORANGE'],
  ['42501', '425', '1', 'Israel', 'PARTNER ORANGE'],
  ['42501', '425', '1', 'Israel', 'PARTNER ORANGE'],
  ['42501', '425', '1', 'Israel', 'PARTNER ORANGE'],
  ['42502', '425', '2', 'Israel', 'CELLCOM'],
  ['42502', '425', '2', 'Israel', 'CELLCOM'],
  ['42502', '425', '2', 'Israel', 'CELLCOM'],
  ['42502', '425', '2', 'Israel', 'CELLCOM'],
  ['42502', '425', '2', 'Israel', 'CELLCOM'],
  ['42508', '425', '8', 'Israel', 'Unknown-ISRAEL-5493'],
  ['42577', '425', '77', 'Israel', 'MIRS'],
  ['42577', '425', '77', 'Israel', 'PELEPHONE'],
  ['2220', '222', '0', 'Italy', 'DEFAULT ITALY'],
  ['22201', '222', '1', 'Italy', 'TIM'],
  ['22201', '222', '1', 'Italy', 'TIM'],
  ['22201', '222', '1', 'Italy', 'TIM'],
  ['22201', '222', '1', 'Italy', 'TIM'],
  ['22201', '222', '1', 'Italy', 'TIM'],
  ['22201', '222', '1', 'Italy', 'TIM'],
  ['22201', '222', '1', 'Italy', 'TIM'],
  ['22201', '222', '1', 'Italy', 'TIM'],
  ['22201', '222', '1', 'Italy', 'TIM'],
  ['22201', '222', '1', 'Italy', 'TIM'],
  ['22201', '222', '1', 'Italy', 'TIM'],
  ['22201', '222', '1', 'Italy', 'TIM'],
  ['22202', '222', '2', 'Italy', 'RFI'],
  ['22202', '222', '2', 'Italy', 'ELSACOM'],
  ['22204', '222', '4', 'Italy', 'Intermatica'],
  ['22206', '222', '6', 'Italy', 'Vodafone Omnitel NV'],
  ['22206', '222', '6', 'Italy', 'Vodafone Omnitel NV'],
  ['22210', '222', '10', 'Italy', 'VODAFONE'],
  ['22210', '222', '10', 'Italy', 'VODAFONE'],
  ['22210', '222', '10', 'Italy', 'VODAFONE'],
  ['22210', '222', '10', 'Italy', 'VODAFONE'],
  ['22210', '222', '10', 'Italy', 'VODAFONE'],
  ['22210', '222', '10', 'Italy', 'VODAFONE'],
  ['22234', '222', '34', 'Italy', 'TIM'],
  ['22234', '222', '34', 'Italy', 'TIM'],
  ['22234', '222', '34', 'Italy', 'TIM'],
  ['22237', '222', '37', 'Italy', 'H3G'],
  ['22237', '222', '37', 'Italy', 'H3G'],
  ['22249', '222', '49', 'Italy', 'TIM'],
  ['22249', '222', '49', 'Italy', 'TIM'],
  ['22249', '222', '49', 'Italy', 'TIM'],
  ['22250', '222', '50', 'Italy', 'Iliad'],
  ['22250', '222', '50', 'Italy', 'Iliad'],
  ['22253', '222', '53', 'Italy', 'TIM'],
  ['22253', '222', '53', 'Italy', 'TIM'],
  ['22253', '222', '53', 'Italy', 'TIM'],
  ['22288', '222', '88', 'Italy', 'WIND'],
  ['22288', '222', '88', 'Italy', 'WIND'],
  ['22288', '222', '88', 'Italy', 'WIND'],
  ['22288', '222', '88', 'Italy', 'WIND'],
  ['22288', '222', '88', 'Italy', 'WIND'],
  ['22288', '222', '88', 'Italy', 'WIND'],
  ['22288', '222', '88', 'Italy', 'WIND'],
  ['22288', '222', '88', 'Italy', 'WIND'],
  ['22298', '222', '98', 'Italy', 'Wind'],
  ['22298', '222', '98', 'Italy', 'Wind'],
  ['22299', '222', '99', 'Italy', 'TRE (H3G)'],
  ['22299', '222', '99', 'Italy', 'TRE (H3G)'],
  ['22299', '222', '99', 'Italy', 'TRE (H3G)'],
  ['22299', '222', '99', 'Italy', 'TRE (H3G)'],
  ['45701', '457', '1', 'Italy', 'LAOS'],
  ['6120', '612', '0', 'Ivory Coast', 'DEFAULT IVORY COAST'],
  ['61202', '612', '2', 'Ivory Coast', 'MOOV'],
  ['61202', '612', '2', 'Ivory Coast', 'MOOV'],
  ['61202', '612', '2', 'Ivory Coast', 'MOOV'],
  ['61203', '612', '3', 'Ivory Coast', 'ORANGE (SIM IVOIRIS)'],
  ['61203', '612', '3', 'Ivory Coast', 'ORANGE'],
  ['61203', '612', '3', 'Ivory Coast', 'ORANGE (SIM IVOIRIS)'],
  ['61203', '612', '3', 'Ivory Coast', 'ORANGE'],
  ['61203', '612', '3', 'Ivory Coast', 'ORANGE'],
  ['61203', '612', '3', 'Ivory Coast', 'ORANGE'],
  ['61204', '612', '4', 'Ivory Coast', 'KOZ'],
  ['61204', '612', '4', 'Ivory Coast', 'KOZ'],
  ['61205', '612', '5', 'Ivory Coast', 'MTN'],
  ['61205', '612', '5', 'Ivory Coast', 'MTN'],
  ['61205', '612', '5', 'Ivory Coast', 'MTN'],
  ['61205', '612', '5', 'Ivory Coast', 'MTN'],
  ['61205', '612', '5', 'Ivory Coast', 'MTN'],
  ['61205', '612', '5', 'Ivory Coast', 'MTN'],
  ['61205', '612', '5', 'Ivory Coast', 'MTN'],
  ['61206', '612', '6', 'Ivory Coast', 'GREEN'],
  ['310002', '310', '2', 'Jamaica', 'CABLE & WIRELESS'],
  ['310002', '310', '2', 'Jamaica', 'CABLE & WIRELESS'],
  ['310002', '310', '2', 'Jamaica', 'CABLE & WIRELESS'],
  ['310002', '310', '2', 'Jamaica', 'CABLE & WIRELESS'],
  ['310002', '310', '2', 'Jamaica', 'CABLE & WIRELESS'],
  ['310002', '310', '2', 'Jamaica', 'CABLE & WIRELESS'],
  ['310002', '310', '2', 'Jamaica', 'CABLE & WIRELESS'],
  ['310002', '310', '2', 'Jamaica', 'CABLE & WIRELESS'],
  ['33805', '338', '5', 'Jamaica', 'MOSSEL DIGICEL'],
  ['33805', '338', '5', 'Jamaica', 'MOSSEL DIGICEL'],
  ['4400', '440', '0', 'Japan', 'DEFAULT JAPAN'],
  ['44010', '440', '10', 'Japan', 'VODAFONE'],
  ['44020', '440', '20', 'Japan', 'NTT DOCOMO'],
  ['44020', '440', '20', 'Japan', 'NTT DOCOMO'],
  ['44020', '440', '20', 'Japan', 'NTT DOCOMO'],
  ['44020', '440', '20', 'Japan', 'NTT DOCOMO'],
  ['44020', '440', '20', 'Japan', 'NTT DOCOMO'],
  ['44020', '440', '20', 'Japan', 'NTT DOCOMO'],
  ['44020', '440', '20', 'Japan', 'NTT DOCOMO'],
  ['44020', '440', '20', 'Japan', 'NTT DOCOMO'],
  ['44020', '440', '20', 'Japan', 'NTT DOCOMO'],
  ['44020', '440', '20', 'Japan', 'NTT DOCOMO'],
  ['44020', '440', '20', 'Japan', 'NTT DOCOMO'],
  ['44020', '440', '20', 'Japan', 'NTT DOCOMO'],
  ['44020', '440', '20', 'Japan', 'NTT DOCOMO'],
  ['44020', '440', '20', 'Japan', 'NTT DOCOMO'],
  ['44020', '440', '20', 'Japan', 'NTT DOCOMO'],
  ['44020', '440', '20', 'Japan', 'NTT DOCOMO'],
  ['44020', '440', '20', 'Japan', 'NTT DOCOMO'],
  ['44020', '440', '20', 'Japan', 'NTT DOCOMO'],
  ['44020', '440', '20', 'Japan', 'NTT DOCOMO'],
  ['44020', '440', '20', 'Japan', 'NTT DOCOMO'],
  ['44020', '440', '20', 'Japan', 'NTT DOCOMO'],
  ['44020', '440', '20', 'Japan', 'NTT DOCOMO'],
  ['44020', '440', '20', 'Japan', 'NTT DOCOMO'],
  ['44020', '440', '20', 'Japan', 'NTT DOCOMO'],
  ['44020', '440', '20', 'Japan', 'NTT DOCOMO'],
  ['44020', '440', '20', 'Japan', 'NTT DOCOMO'],
  ['44020', '440', '20', 'Japan', 'NTT DOCOMO'],
  ['44020', '440', '20', 'Japan', 'NTT DOCOMO'],
  ['44020', '440', '20', 'Japan', 'NTT DOCOMO'],
  ['44020', '440', '20', 'Japan', 'NTT DOCOMO'],
  ['44020', '440', '20', 'Japan', 'NTT DOCOMO'],
  ['44020', '440', '20', 'Japan', 'NTT DOCOMO'],
  ['44020', '440', '20', 'Japan', 'NTT DOCOMO'],
  ['44020', '440', '20', 'Japan', 'NTT DOCOMO'],
  ['44020', '440', '20', 'Japan', 'NTT DOCOMO'],
  ['44020', '440', '20', 'Japan', 'NTT DOCOMO'],
  ['44020', '440', '20', 'Japan', 'NTT DOCOMO'],
  ['44020', '440', '20', 'Japan', 'NTT DOCOMO'],
  ['44020', '440', '20', 'Japan', 'NTT DOCOMO'],
  ['44020', '440', '20', 'Japan', 'NTT DOCOMO'],
  ['44020', '440', '20', 'Japan', 'NTT DOCOMO'],
  ['44020', '440', '20', 'Japan', 'NTT DOCOMO'],
  ['44020', '440', '20', 'Japan', 'NTT DOCOMO'],
  ['44020', '440', '20', 'Japan', 'NTT DOCOMO'],
  ['44020', '440', '20', 'Japan', 'NTT DOCOMO'],
  ['44020', '440', '20', 'Japan', 'NTT DOCOMO'],
  ['44020', '440', '20', 'Japan', 'NTT DOCOMO'],
  ['44020', '440', '20', 'Japan', 'NTT DOCOMO'],
  ['44020', '440', '20', 'Japan', 'NTT DOCOMO'],
  ['44020', '440', '20', 'Japan', 'NTT DOCOMO'],
  ['44020', '440', '20', 'Japan', 'NTT DOCOMO'],
  ['44020', '440', '20', 'Japan', 'NTT DOCOMO'],
  ['44020', '440', '20', 'Japan', 'NTT DOCOMO'],
  ['44020', '440', '20', 'Japan', 'NTT DOCOMO'],
  ['44020', '440', '20', 'Japan', 'NTT DOCOMO'],
  ['44020', '440', '20', 'Japan', 'NTT DOCOMO'],
  ['44020', '440', '20', 'Japan', 'NTT DOCOMO'],
  ['44020', '440', '20', 'Japan', 'NTT DOCOMO'],
  ['44020', '440', '20', 'Japan', 'NTT DOCOMO'],
  ['44020', '440', '20', 'Japan', 'NTT DOCOMO'],
  ['44020', '440', '20', 'Japan', 'NTT DOCOMO'],
  ['44020', '440', '20', 'Japan', 'NTT DOCOMO'],
  ['44020', '440', '20', 'Japan', 'NTT DOCOMO'],
  ['44020', '440', '20', 'Japan', 'NTT DOCOMO'],
  ['44020', '440', '20', 'Japan', 'NTT DOCOMO'],
  ['44020', '440', '20', 'Japan', 'NTT DOCOMO'],
  ['44020', '440', '20', 'Japan', 'NTT DOCOMO'],
  ['44020', '440', '20', 'Japan', 'NTT DOCOMO'],
  ['44020', '440', '20', 'Japan', 'NTT DOCOMO'],
  ['44020', '440', '20', 'Japan', 'NTT DOCOMO'],
  ['44020', '440', '20', 'Japan', 'NTT DOCOMO'],
  ['44020', '440', '20', 'Japan', 'NTT DOCOMO'],
  ['44020', '440', '20', 'Japan', 'NTT DOCOMO'],
  ['44020', '440', '20', 'Japan', 'NTT DOCOMO'],
  ['44020', '440', '20', 'Japan', 'NTT DOCOMO'],
  ['44020', '440', '20', 'Japan', 'NTT DOCOMO'],
  ['44020', '440', '20', 'Japan', 'NTT DOCOMO'],
  ['44020', '440', '20', 'Japan', 'NTT DOCOMO'],
  ['44020', '440', '20', 'Japan', 'NTT DOCOMO'],
  ['44020', '440', '20', 'Japan', 'NTT DOCOMO'],
  ['44020', '440', '20', 'Japan', 'NTT DOCOMO'],
  ['44020', '440', '20', 'Japan', 'NTT DOCOMO'],
  ['44020', '440', '20', 'Japan', 'NTT DOCOMO'],
  ['44020', '440', '20', 'Japan', 'NTT DOCOMO'],
  ['44020', '440', '20', 'Japan', 'NTT DOCOMO'],
  ['4160', '416', '0', 'Jordan', 'DEFAULT JORDAN'],
  ['41601', '416', '1', 'Jordan', 'FASTLINK'],
  ['41601', '416', '1', 'Jordan', 'ZAIN JO'],
  ['41602', '416', '2', 'Jordan', 'UMNIAH (UMC)'],
  ['41603', '416', '3', 'Jordan', 'XPRESS'],
  ['41677', '416', '77', 'Jordan', 'MOBILECOM'],
  ['40101', '401', '1', 'Kazakhstan', 'KAR TEL'],
  ['40102', '401', '2', 'Kazakhstan', 'KCELL'],
  ['40107', '401', '7', 'Kazakhstan', 'Altel - Dalacom'],
  ['40107', '401', '7', 'Kazakhstan', 'Altel - Dalacom'],
  ['40177', '401', '77', 'Kazakhstan', 'Mobile Telecom Service - Tele2'],
  ['40177', '401', '77', 'Kazakhstan', 'Mobile Telecom Service - Tele2'],
  ['40177', '401', '77', 'Kazakhstan', 'Mobile Telecom Service - Tele2'],
  ['40177', '401', '77', 'Kazakhstan', 'Mobile Telecom Service - Tele2'],
  ['40177', '401', '77', 'Kazakhstan', 'Mobile Telecom Service - Tele2'],
  ['40177', '401', '77', 'Kazakhstan', 'Mobile Telecom Service - Tele2'],
  ['40177', '401', '77', 'Kazakhstan', 'Mobile Telecom Service - Tele2'],
  ['40177', '401', '77', 'Kazakhstan', 'Mobile Telecom Service - Tele2'],
  ['40177', '401', '77', 'Kazakhstan', 'Mobile Telecom Service - Tele2'],
  ['40177', '401', '77', 'Kazakhstan', 'Mobile Telecom Service - Tele2'],
  ['40177', '401', '77', 'Kazakhstan', 'Mobile Telecom Service - Tele2'],
  ['40177', '401', '77', 'Kazakhstan', 'Mobile Telecom Service - Tele2'],
  ['40177', '401', '77', 'Kazakhstan', 'Mobile Telecom Service - Tele2'],
  ['40177', '401', '77', 'Kazakhstan', 'Mobile Telecom Service - Tele2'],
  ['40177', '401', '77', 'Kazakhstan', 'Mobile Telecom Service - Tele2'],
  ['40177', '401', '77', 'Kazakhstan', 'Mobile Telecom Service - Tele2'],
  ['40177', '401', '77', 'Kazakhstan', 'Mobile Telecom Service - Tele2'],
  ['40177', '401', '77', 'Kazakhstan', 'Mobile Telecom Service - Tele2'],
  ['40177', '401', '77', 'Kazakhstan', 'Mobile Telecom Service - Tele2'],
  ['40177', '401', '77', 'Kazakhstan', 'Mobile Telecom Service - Tele2'],
  ['40177', '401', '77', 'Kazakhstan', 'Mobile Telecom Service - Tele2'],
  ['40177', '401', '77', 'Kazakhstan', 'Mobile Telecom Service - Tele2'],
  ['40177', '401', '77', 'Kazakhstan', 'Mobile Telecom Service - Tele2'],
  ['40177', '401', '77', 'Kazakhstan', 'Mobile Telecom Service - Tele2'],
  ['40177', '401', '77', 'Kazakhstan', 'Mobile Telecom Service - Tele2'],
  ['40177', '401', '77', 'Kazakhstan', 'Mobile Telecom Service - Tele2'],
  ['40177', '401', '77', 'Kazakhstan', 'Mobile Telecom Service - Tele2'],
  ['40177', '401', '77', 'Kazakhstan', 'Mobile Telecom Service - Tele2'],
  ['40177', '401', '77', 'Kazakhstan', 'Mobile Telecom Service - Tele2'],
  ['40177', '401', '77', 'Kazakhstan', 'Mobile Telecom Service - Tele2'],
  ['40177', '401', '77', 'Kazakhstan', 'Mobile Telecom Service - Tele2'],
  ['40177', '401', '77', 'Kazakhstan', 'Mobile Telecom Service - Tele2'],
  ['40177', '401', '77', 'Kazakhstan', 'Mobile Telecom Service - Tele2'],
  ['40177', '401', '77', 'Kazakhstan', 'Mobile Telecom Service - Tele2'],
  ['40177', '401', '77', 'Kazakhstan', 'Mobile Telecom Service - Tele2'],
  ['40177', '401', '77', 'Kazakhstan', 'Mobile Telecom Service - Tele2'],
  ['40177', '401', '77', 'Kazakhstan', 'Mobile Telecom Service - Tele2'],
  ['40177', '401', '77', 'Kazakhstan', 'Mobile Telecom Service - Tele2'],
  ['40177', '401', '77', 'Kazakhstan', 'Mobile Telecom Service - Tele2'],
  ['40177', '401', '77', 'Kazakhstan', 'Mobile Telecom Service - Tele2'],
  ['40177', '401', '77', 'Kazakhstan', 'Mobile Telecom Service - Tele2'],
  ['40177', '401', '77', 'Kazakhstan', 'Mobile Telecom Service - Tele2'],
  ['40177', '401', '77', 'Kazakhstan', 'Mobile Telecom Service - Tele2'],
  ['40177', '401', '77', 'Kazakhstan', 'Mobile Telecom Service - Tele2'],
  ['40177', '401', '77', 'Kazakhstan', 'Mobile Telecom Service - Tele2'],
  ['40177', '401', '77', 'Kazakhstan', 'Mobile Telecom Service - Tele2'],
  ['40177', '401', '77', 'Kazakhstan', 'Mobile Telecom Service - Tele2'],
  ['40177', '401', '77', 'Kazakhstan', 'Mobile Telecom Service - Tele2'],
  ['40177', '401', '77', 'Kazakhstan', 'Mobile Telecom Service - Tele2'],
  ['40177', '401', '77', 'Kazakhstan', 'Mobile Telecom Service - Tele2'],
  ['40177', '401', '77', 'Kazakhstan', 'Mobile Telecom Service - Tele2'],
  ['40177', '401', '77', 'Kazakhstan', 'Mobile Telecom Service - Tele2'],
  ['40177', '401', '77', 'Kazakhstan', 'Mobile Telecom Service - Tele2'],
  ['40177', '401', '77', 'Kazakhstan', 'Mobile Telecom Service - Tele2'],
  ['40177', '401', '77', 'Kazakhstan', 'Mobile Telecom Service - Tele2'],
  ['40177', '401', '77', 'Kazakhstan', 'Mobile Telecom Service - Tele2'],
  ['40177', '401', '77', 'Kazakhstan', 'Mobile Telecom Service - Tele2'],
  ['40177', '401', '77', 'Kazakhstan', 'Mobile Telecom Service - Tele2'],
  ['40177', '401', '77', 'Kazakhstan', 'Mobile Telecom Service - Tele2'],
  ['40177', '401', '77', 'Kazakhstan', 'Mobile Telecom Service - Tele2'],
  ['40177', '401', '77', 'Kazakhstan', 'Mobile Telecom Service - Tele2'],
  ['40177', '401', '77', 'Kazakhstan', 'Mobile Telecom Service - Tele2'],
  ['40177', '401', '77', 'Kazakhstan', 'Mobile Telecom Service - Tele2'],
  ['40177', '401', '77', 'Kazakhstan', 'Mobile Telecom Service - Tele2'],
  ['40177', '401', '77', 'Kazakhstan', 'Mobile Telecom Service - Tele2'],
  ['40177', '401', '77', 'Kazakhstan', 'Mobile Telecom Service - Tele2'],
  ['40177', '401', '77', 'Kazakhstan', 'Mobile Telecom Service - Tele2'],
  ['40177', '401', '77', 'Kazakhstan', 'Mobile Telecom Service - Tele2'],
  ['40177', '401', '77', 'Kazakhstan', 'Mobile Telecom Service - Tele2'],
  ['40177', '401', '77', 'Kazakhstan', 'Mobile Telecom Service - Tele2'],
  ['40177', '401', '77', 'Kazakhstan', 'Mobile Telecom Service - Tele2'],
  ['40177', '401', '77', 'Kazakhstan', 'Mobile Telecom Service - Tele2'],
  ['40177', '401', '77', 'Kazakhstan', 'Mobile Telecom Service - Tele2'],
  ['40177', '401', '77', 'Kazakhstan', 'Mobile Telecom Service - Tele2'],
  ['40177', '401', '77', 'Kazakhstan', 'Mobile Telecom Service - Tele2'],
  ['40177', '401', '77', 'Kazakhstan', 'Mobile Telecom Service - Tele2'],
  ['40177', '401', '77', 'Kazakhstan', 'Mobile Telecom Service - Tele2'],
  ['40177', '401', '77', 'Kazakhstan', 'Mobile Telecom Service - Tele2'],
  ['40177', '401', '77', 'Kazakhstan', 'Mobile Telecom Service - Tele2'],
  ['40177', '401', '77', 'Kazakhstan', 'Mobile Telecom Service - Tele2'],
  ['40177', '401', '77', 'Kazakhstan', 'Mobile Telecom Service - Tele2'],
  ['40177', '401', '77', 'Kazakhstan', 'Mobile Telecom Service - Tele2'],
  ['40177', '401', '77', 'Kazakhstan', 'Mobile Telecom Service - Tele2'],
  ['40177', '401', '77', 'Kazakhstan', 'Mobile Telecom Service - Tele2'],
  ['40177', '401', '77', 'Kazakhstan', 'Mobile Telecom Service - Tele2'],
  ['6390', '639', '0', 'Kenya', 'DEFAULT KENYA'],
  ['63902', '639', '2', 'Kenya', 'SAFARICOM'],
  ['63902', '639', '2', 'Kenya', 'SAFARICOM'],
  ['63902', '639', '2', 'Kenya', 'SAFARICOM'],
  ['63903', '639', '3', 'Kenya', 'AIRTEL'],
  ['63903', '639', '3', 'Kenya', 'AIRTEL'],
  ['63905', '639', '5', 'Kenya', 'ESSAR(YU)'],
  ['63907', '639', '7', 'Kenya', 'ORANGE'],
  ['45005', '450', '5', 'Korea', 'SK Telecom'],
  ['45005', '450', '5', 'Korea', 'SK Telecom'],
  ['45005', '450', '5', 'Korea', 'SK Telecom'],
  ['45005', '450', '5', 'Korea', 'SK Telecom'],
  ['45005', '450', '5', 'Korea', 'SK Telecom'],
  ['45005', '450', '5', 'Korea', 'SK Telecom'],
  ['45005', '450', '5', 'Korea', 'SK Telecom'],
  ['45005', '450', '5', 'Korea', 'SK Telecom'],
  ['45005', '450', '5', 'Korea', 'SK Telecom'],
  ['45005', '450', '5', 'Korea', 'SK Telecom'],
  ['45005', '450', '5', 'Korea', 'SK Telecom'],
  ['45005', '450', '5', 'Korea', 'SK Telecom'],
  ['45005', '450', '5', 'Korea', 'SK Telecom'],
  ['45005', '450', '5', 'Korea', 'SK Telecom'],
  ['45005', '450', '5', 'Korea', 'SK Telecom'],
  ['45005', '450', '5', 'Korea', 'SK Telecom'],
  ['45005', '450', '5', 'Korea', 'SK Telecom'],
  ['45005', '450', '5', 'Korea', 'SK Telecom'],
  ['45005', '450', '5', 'Korea', 'SK Telecom'],
  ['45005', '450', '5', 'Korea', 'SK Telecom'],
  ['45005', '450', '5', 'Korea', 'SK Telecom'],
  ['45005', '450', '5', 'Korea', 'SK Telecom'],
  ['45005', '450', '5', 'Korea', 'SK Telecom'],
  ['45005', '450', '5', 'Korea', 'SK Telecom'],
  ['45005', '450', '5', 'Korea', 'SK Telecom'],
  ['45005', '450', '5', 'Korea', 'SK Telecom'],
  ['45006', '450', '6', 'Korea', 'LG Telecom'],
  ['45006', '450', '6', 'Korea', 'LG Telecom'],
  ['45006', '450', '6', 'Korea', 'LG Telecom'],
  ['45006', '450', '6', 'Korea', 'LG Telecom'],
  ['45006', '450', '6', 'Korea', 'LG Telecom'],
  ['45006', '450', '6', 'Korea', 'LG Telecom'],
  ['45006', '450', '6', 'Korea', 'LG Telecom'],
  ['45006', '450', '6', 'Korea', 'LG Telecom'],
  ['45006', '450', '6', 'Korea', 'LG Telecom'],
  ['45006', '450', '6', 'Korea', 'LG Telecom'],
  ['45006', '450', '6', 'Korea', 'LG Telecom'],
  ['45006', '450', '6', 'Korea', 'LG Telecom'],
  ['45006', '450', '6', 'Korea', 'LG Telecom'],
  ['45006', '450', '6', 'Korea', 'LG Telecom'],
  ['45006', '450', '6', 'Korea', 'LG Telecom'],
  ['45006', '450', '6', 'Korea', 'LG Telecom'],
  ['45006', '450', '6', 'Korea', 'LG Telecom'],
  ['45008', '450', '8', 'Korea', 'KT FreeTel'],
  ['45008', '450', '8', 'Korea', 'KT FreeTel'],
  ['45008', '450', '8', 'Korea', 'KT FreeTel'],
  ['45008', '450', '8', 'Korea', 'KT FreeTel'],
  ['45008', '450', '8', 'Korea', 'KT FreeTel'],
  ['45008', '450', '8', 'Korea', 'KT FreeTel'],
  ['45008', '450', '8', 'Korea', 'KT FreeTel'],
  ['45008', '450', '8', 'Korea', 'KT FreeTel'],
  ['45008', '450', '8', 'Korea', 'KT FreeTel'],
  ['45008', '450', '8', 'Korea', 'KT FreeTel'],
  ['45008', '450', '8', 'Korea', 'KT FreeTel'],
  ['45008', '450', '8', 'Korea', 'KT FreeTel'],
  ['45008', '450', '8', 'Korea', 'KT FreeTel'],
  ['45008', '450', '8', 'Korea', 'KT FreeTel'],
  ['45008', '450', '8', 'Korea', 'KT FreeTel'],
  ['45008', '450', '8', 'Korea', 'KT FreeTel'],
  ['45008', '450', '8', 'Korea', 'KT FreeTel'],
  ['45008', '450', '8', 'Korea', 'KT FreeTel'],
  ['45008', '450', '8', 'Korea', 'KT FreeTel'],
  ['45008', '450', '8', 'Korea', 'KT FreeTel'],
  ['45008', '450', '8', 'Korea', 'KT FreeTel'],
  ['45008', '450', '8', 'Korea', 'KT FreeTel'],
  ['45008', '450', '8', 'Korea', 'KT FreeTel'],
  ['45008', '450', '8', 'Korea', 'KT FreeTel'],
  ['45008', '450', '8', 'Korea', 'KT FreeTel'],
  ['45008', '450', '8', 'Korea', 'KT FreeTel'],
  ['4190', '419', '0', 'Kuwait', 'DEFAULT KUWAIT'],
  ['41902', '419', '2', 'Kuwait', 'MTC'],
  ['41902', '419', '2', 'Kuwait', 'MTC'],
  ['41902', '419', '2', 'Kuwait', 'MTC'],
  ['41902', '419', '2', 'Kuwait', 'MTC'],
  ['41902', '419', '2', 'Kuwait', 'MTC'],
  ['41902', '419', '2', 'Kuwait', 'MTC'],
  ['41902', '419', '2', 'Kuwait', 'MTC'],
  ['41902', '419', '2', 'Kuwait', 'MTC'],
  ['41902', '419', '2', 'Kuwait', 'MTC'],
  ['41902', '419', '2', 'Kuwait', 'MTC'],
  ['41902', '419', '2', 'Kuwait', 'MTC'],
  ['41902', '419', '2', 'Kuwait', 'MTC'],
  ['41902', '419', '2', 'Kuwait', 'MTC'],
  ['41902', '419', '2', 'Kuwait', 'MTC'],
  ['41902', '419', '2', 'Kuwait', 'MTC'],
  ['41902', '419', '2', 'Kuwait', 'MTC'],
  ['41902', '419', '2', 'Kuwait', 'MTC'],
  ['41902', '419', '2', 'Kuwait', 'MTC'],
  ['41902', '419', '2', 'Kuwait', 'MTC'],
  ['41903', '419', '3', 'Kuwait', 'WATANIYA'],
  ['41903', '419', '3', 'Kuwait', 'WATANIYA'],
  ['41903', '419', '3', 'Kuwait', 'WATANIYA'],
  ['41903', '419', '3', 'Kuwait', 'WATANIYA'],
  ['41903', '419', '3', 'Kuwait', 'WATANIYA'],
  ['41903', '419', '3', 'Kuwait', 'WATANIYA'],
  ['41903', '419', '3', 'Kuwait', 'WATANIYA'],
  ['41903', '419', '3', 'Kuwait', 'WATANIYA'],
  ['41903', '419', '3', 'Kuwait', 'WATANIYA'],
  ['41903', '419', '3', 'Kuwait', 'WATANIYA'],
  ['41903', '419', '3', 'Kuwait', 'WATANIYA'],
  ['4370', '437', '0', 'Kyrgyzstan', 'DEFAULT KYRGYSTHAN'],
  ['43701', '437', '1', 'Kyrgyzstan', 'BITEL'],
  ['43701', '437', '1', 'Kyrgyzstan', 'BITEL'],
  ['43705', '437', '5', 'Kyrgyzstan', 'Megacom'],
  ['43705', '437', '5', 'Kyrgyzstan', 'Megacom'],
  ['43705', '437', '5', 'Kyrgyzstan', 'Megacom'],
  ['43705', '437', '5', 'Kyrgyzstan', 'Megacom'],
  ['43705', '437', '5', 'Kyrgyzstan', 'Megacom'],
  ['43705', '437', '5', 'Kyrgyzstan', 'Megacom'],
  ['43705', '437', '5', 'Kyrgyzstan', 'Megacom'],
  ['4570', '457', '0', 'Laos', 'DEFAULT LAO P.D.R.'],
  ['45701', '457', '1', 'Laos', 'LAOTEL'],
  ['45702', '457', '2', 'Laos', 'ETL MOBILE'],
  ['45703', '457', '3', 'Laos', 'Unitel (Viettel)'],
  ['45708', '457', '8', 'Laos', 'MILLICOM'],
  ['2470', '247', '0', 'Latvia', 'DEFAULT LATVIA'],
  ['24701', '247', '1', 'Latvia', 'LMT'],
  ['24701', '247', '1', 'Latvia', 'LMT'],
  ['24701', '247', '1', 'Latvia', 'LMT'],
  ['24701', '247', '1', 'Latvia', 'LMT'],
  ['24701', '247', '1', 'Latvia', 'LMT'],
  ['24701', '247', '1', 'Latvia', 'LMT'],
  ['24701', '247', '1', 'Latvia', 'LMT'],
  ['24701', '247', '1', 'Latvia', 'LMT'],
  ['24701', '247', '1', 'Latvia', 'LMT'],
  ['24701', '247', '1', 'Latvia', 'LMT'],
  ['24702', '247', '2', 'Latvia', 'TELE 2 (BALTCOM)'],
  ['24702', '247', '2', 'Latvia', 'TELE 2 (BALTCOM)'],
  ['24702', '247', '2', 'Latvia', 'TELE 2 (BALTCOM)'],
  ['24702', '247', '2', 'Latvia', 'TELE 2 (BALTCOM)'],
  ['24702', '247', '2', 'Latvia', 'TELE 2 (BALTCOM)'],
  ['24702', '247', '2', 'Latvia', 'TELE 2 (BALTCOM)'],
  ['24702', '247', '2', 'Latvia', 'TELE 2 (BALTCOM)'],
  ['24702', '247', '2', 'Latvia', 'TELE 2 (BALTCOM)'],
  ['24702', '247', '2', 'Latvia', 'TELE 2 (BALTCOM)'],
  ['310002', '310', '2', 'Latvia', 'LMT'],
  ['310002', '310', '2', 'Latvia', 'LMT'],
  ['4150', '415', '0', 'Lebanon', 'DEFAULT LEBANON'],
  ['41501', '415', '1', 'Lebanon', 'MIC 1 ALFA'],
  ['41501', '415', '1', 'Lebanon', 'MIC 1 ALFA'],
  ['41501', '415', '1', 'Lebanon', 'MIC 1 ALFA'],
  ['41501', '415', '1', 'Lebanon', 'MIC 1 ALFA'],
  ['41501', '415', '1', 'Lebanon', 'MIC 1 ALFA'],
  ['41501', '415', '1', 'Lebanon', 'MIC 1 ALFA'],
  ['41501', '415', '1', 'Lebanon', 'MIC 1 ALFA'],
  ['41503', '415', '3', 'Lebanon', 'MTC LEBANON'],
  ['41503', '415', '3', 'Lebanon', 'MTC LEBANON'],
  ['41503', '415', '3', 'Lebanon', 'MTC LEBANON'],
  ['41503', '415', '3', 'Lebanon', 'MTC LEBANON'],
  ['41503', '415', '3', 'Lebanon', 'MTC LEBANON'],
  ['41503', '415', '3', 'Lebanon', 'MTC LEBANON'],
  ['6510', '651', '0', 'Lesotho', 'DEFAULT LESOTHO'],
  ['65101', '651', '1', 'Lesotho', 'VODACOM'],
  ['65102', '651', '2', 'Lesotho', 'ECONET EZI CEL'],
  ['6180', '618', '0', 'Liberia', 'MTN (Lonestar Cell)'],
  ['6180', '618', '0', 'Liberia', 'Cellcom Telecommunications - Orange'],
  ['61801', '618', '1', 'Liberia', 'MTN (Lonestar Cell)'],
  ['61801', '618', '1', 'Liberia', 'MTN (Lonestar Cell)'],
  ['61801', '618', '1', 'Liberia', 'MTN (Lonestar Cell)'],
  ['61807', '618', '7', 'Liberia', 'Cellcom Telecommunications - Orange'],
  ['6000', '600', '0', 'Libya', 'DEFAULT LIBYA'],
  ['60601', '600', '1', 'Libya', 'EL MADAR'],
  ['60602', '600', '2', 'Libya', 'LIBYANA'],
  ['2950', '295', '0', 'Liechtenstein', 'DEFAULT LIECHTENSTEIN'],
  ['29501', '295', '1', 'Liechtenstein', 'TELECOM FLAG'],
  ['29502', '295', '2', 'Liechtenstein', 'VIAG'],
  ['29505', '295', '5', 'Liechtenstein', 'MOBILKOM LIE'],
  ['29505', '295', '5', 'Liechtenstein', 'MOBILKOM LIE'],
  ['29577', '295', '77', 'Liechtenstein', 'TELE 2'],
  ['2460', '246', '0', 'Lithuania', 'DEFAULT LITHUANIA'],
  ['24601', '246', '1', 'Lithuania', 'OMNITEL'],
  ['24601', '246', '1', 'Lithuania', 'OMNITEL'],
  ['24601', '246', '1', 'Lithuania', 'OMNITEL'],
  ['24601', '246', '1', 'Lithuania', 'OMNITEL'],
  ['24601', '246', '1', 'Lithuania', 'OMNITEL'],
  ['24602', '246', '2', 'Lithuania', 'BITE'],
  ['24602', '246', '2', 'Lithuania', 'BITE'],
  ['24602', '246', '2', 'Lithuania', 'BITE'],
  ['24602', '246', '2', 'Lithuania', 'BITE'],
  ['24602', '246', '2', 'Lithuania', 'BITE'],
  ['24603', '246', '3', 'Lithuania', 'TELE 2'],
  ['24603', '246', '3', 'Lithuania', 'TELE 2'],
  ['24603', '246', '3', 'Lithuania', 'TELE 2'],
  ['24603', '246', '3', 'Lithuania', 'TELE 2'],
  ['24603', '246', '3', 'Lithuania', 'TELE 2'],
  ['24603', '246', '3', 'Lithuania', 'TELE 2'],
  ['24603', '246', '3', 'Lithuania', 'TELE 2'],
  ['2700', '270', '0', 'Luxembourg', 'DEFAULT LUXEMBOURG'],
  ['27001', '270', '1', 'Luxembourg', 'P&T LUX'],
  ['27077', '270', '77', 'Luxembourg', 'TANGO'],
  ['27099', '270', '99', 'Luxembourg', 'VOX MOBILE'],
  ['27099', '270', '99', 'Luxembourg', 'VOX MOBILE'],
  ['4550', '455', '0', 'Macao', 'DEFAULT MACAO'],
  ['45500', '455', '0', 'Macao', 'SMARTONE'],
  ['45501', '455', '1', 'Macao', 'CTM'],
  ['45501', '455', '1', 'Macao', 'CTM'],
  ['45501', '455', '1', 'Macao', 'CTM'],
  ['45503', '455', '3', 'Macao', 'HUTCHISON'],
  ['2940', '294', '0', 'Macedonia', 'DEFAULT FYROM'],
  ['29400', '294', '0', 'Macedonia', 'ALO (MVNO)'],
  ['29401', '294', '1', 'Macedonia', 'T-MOBILE'],
  ['29401', '294', '1', 'Macedonia', 'T-MOBILE'],
  ['29401', '294', '1', 'Macedonia', 'T-MOBILE'],
  ['29402', '294', '2', 'Macedonia', 'COSMOFON'],
  ['29402', '294', '2', 'Macedonia', 'ONE'],
  ['29403', '294', '3', 'Macedonia', 'VIP'],
  ['29403', '294', '3', 'Macedonia', 'VIP'],
  ['6460', '646', '0', 'Madagascar', 'DEFAULT MADACASCAR'],
  ['64601', '646', '1', 'Madagascar', 'MADACOM'],
  ['64602', '646', '2', 'Madagascar', 'SMM (ANTARIS)'],
  ['6500', '650', '0', 'Malawi', 'DEFAULT MALAWI'],
  ['65001', '650', '1', 'Malawi', 'TELEKOM NET'],
  ['65010', '650', '10', 'Malawi', 'CELTEL'],
  ['65010', '650', '10', 'Malawi', 'CELTEL'],
  ['65010', '650', '10', 'Malawi', 'CELTEL'],
  ['65010', '650', '10', 'Malawi', 'CELTEL'],
  ['65010', '650', '10', 'Malawi', 'CELTEL'],
  ['65010', '650', '10', 'Malawi', 'CELTEL'],
  ['65010', '650', '10', 'Malawi', 'CELTEL'],
  ['65010', '650', '10', 'Malawi', 'CELTEL'],
  ['65010', '650', '10', 'Malawi', 'CELTEL'],
  ['65010', '650', '10', 'Malawi', 'CELTEL'],
  ['5020', '502', '0', 'Malaysia', 'DEFAULT MALAYSIA'],
  ['50210', '502', '10', 'Malaysia', 'Digi'],
  ['50210', '502', '10', 'Malaysia', 'Digi'],
  ['50210', '502', '10', 'Malaysia', 'Digi'],
  ['50210', '502', '10', 'Malaysia', 'Digi'],
  ['50210', '502', '10', 'Malaysia', 'Digi'],
  ['50210', '502', '10', 'Malaysia', 'Digi'],
  ['50210', '502', '10', 'Malaysia', 'Digi'],
  ['50210', '502', '10', 'Malaysia', 'Digi'],
  ['50210', '502', '10', 'Malaysia', 'Digi'],
  ['50210', '502', '10', 'Malaysia', 'Digi'],
  ['50210', '502', '10', 'Malaysia', 'Digi'],
  ['50210', '502', '10', 'Malaysia', 'Digi'],
  ['50210', '502', '10', 'Malaysia', 'Digi'],
  ['50210', '502', '10', 'Malaysia', 'Digi'],
  ['50210', '502', '10', 'Malaysia', 'Digi'],
  ['50210', '502', '10', 'Malaysia', 'Digi'],
  ['50210', '502', '10', 'Malaysia', 'Digi'],
  ['50210', '502', '10', 'Malaysia', 'Digi'],
  ['50210', '502', '10', 'Malaysia', 'Digi'],
  ['50210', '502', '10', 'Malaysia', 'Digi'],
  ['50210', '502', '10', 'Malaysia', 'Digi'],
  ['50210', '502', '10', 'Malaysia', 'Digi'],
  ['50210', '502', '10', 'Malaysia', 'Digi'],
  ['50210', '502', '10', 'Malaysia', 'Digi'],
  ['50210', '502', '10', 'Malaysia', 'Digi'],
  ['50210', '502', '10', 'Malaysia', 'Digi'],
  ['50210', '502', '10', 'Malaysia', 'Digi'],
  ['50210', '502', '10', 'Malaysia', 'Digi'],
  ['50210', '502', '10', 'Malaysia', 'Digi'],
  ['50210', '502', '10', 'Malaysia', 'Digi'],
  ['50210', '502', '10', 'Malaysia', 'Digi'],
  ['50210', '502', '10', 'Malaysia', 'Digi'],
  ['50210', '502', '10', 'Malaysia', 'Digi'],
  ['50210', '502', '10', 'Malaysia', 'Digi'],
  ['50210', '502', '10', 'Malaysia', 'Digi'],
  ['50211', '502', '11', 'Malaysia', 'MTX (Telekom Malaysia Bhd)'],
  ['50211', '502', '11', 'Malaysia', 'MTX (Telekom Malaysia Bhd)'],
  ['50211', '502', '11', 'Malaysia', 'MTX (Telekom Malaysia Bhd)'],
  ['50211', '502', '11', 'Malaysia', 'MTX (Telekom Malaysia Bhd)'],
  ['50211', '502', '11', 'Malaysia', 'MTX (Telekom Malaysia Bhd)'],
  ['50212', '502', '12', 'Malaysia', 'MAXIS COMMUNICATIONS'],
  ['50213', '502', '13', 'Malaysia', 'TM TOUCH'],
  ['50215', '502', '15', 'Malaysia', 'YTL Communications'],
  ['50215', '502', '15', 'Malaysia', 'YTL Communications'],
  ['50215', '502', '15', 'Malaysia', 'YTL Communications'],
  ['50215', '502', '15', 'Malaysia', 'YTL Communications'],
  ['50215', '502', '15', 'Malaysia', 'YTL Communications'],
  ['50215', '502', '15', 'Malaysia', 'YTL Communications'],
  ['50215', '502', '15', 'Malaysia', 'YTL Communications'],
  ['50215', '502', '15', 'Malaysia', 'YTL Communications'],
  ['50215', '502', '15', 'Malaysia', 'YTL Communications'],
  ['50215', '502', '15', 'Malaysia', 'YTL Communications'],
  ['50215', '502', '15', 'Malaysia', 'YTL Communications'],
  ['50215', '502', '15', 'Malaysia', 'YTL Communications'],
  ['50215', '502', '15', 'Malaysia', 'YTL Communications'],
  ['50215', '502', '15', 'Malaysia', 'YTL Communications'],
  ['50215', '502', '15', 'Malaysia', 'YTL Communications'],
  ['50215', '502', '15', 'Malaysia', 'YTL Communications'],
  ['50215', '502', '15', 'Malaysia', 'YTL Communications'],
  ['50215', '502', '15', 'Malaysia', 'YTL Communications'],
  ['50215', '502', '15', 'Malaysia', 'YTL Communications'],
  ['50215', '502', '15', 'Malaysia', 'YTL Communications'],
  ['50215', '502', '15', 'Malaysia', 'YTL Communications'],
  ['50215', '502', '15', 'Malaysia', 'YTL Communications'],
  ['50215', '502', '15', 'Malaysia', 'YTL Communications'],
  ['50215', '502', '15', 'Malaysia', 'YTL Communications'],
  ['50215', '502', '15', 'Malaysia', 'YTL Communications'],
  ['50215', '502', '15', 'Malaysia', 'YTL Communications'],
  ['50215', '502', '15', 'Malaysia', 'YTL Communications'],
  ['50215', '502', '15', 'Malaysia', 'YTL Communications'],
  ['50215', '502', '15', 'Malaysia', 'YTL Communications'],
  ['50215', '502', '15', 'Malaysia', 'YTL Communications'],
  ['50215', '502', '15', 'Malaysia', 'YTL Communications'],
  ['50215', '502', '15', 'Malaysia', 'YTL Communications'],
  ['50215', '502', '15', 'Malaysia', 'YTL Communications'],
  ['50215', '502', '15', 'Malaysia', 'YTL Communications'],
  ['502152', '502', '152', 'Malaysia', 'YTL Communications'],
  ['502152', '502', '152', 'Malaysia', 'YTL Communications'],
  ['502152', '502', '152', 'Malaysia', 'YTL Communications'],
  ['502152', '502', '152', 'Malaysia', 'YTL Communications'],
  ['502152', '502', '152', 'Malaysia', 'YTL Communications'],
  ['502152', '502', '152', 'Malaysia', 'YTL Communications'],
  ['502152', '502', '152', 'Malaysia', 'YTL Communications'],
  ['502152', '502', '152', 'Malaysia', 'YTL Communications'],
  ['502152', '502', '152', 'Malaysia', 'YTL Communications'],
  ['502152', '502', '152', 'Malaysia', 'YTL Communications'],
  ['502152', '502', '152', 'Malaysia', 'YTL Communications'],
  ['502152', '502', '152', 'Malaysia', 'YTL Communications'],
  ['502152', '502', '152', 'Malaysia', 'YTL Communications'],
  ['502152', '502', '152', 'Malaysia', 'YTL Communications'],
  ['502152', '502', '152', 'Malaysia', 'YTL Communications'],
  ['502152', '502', '152', 'Malaysia', 'YTL Communications'],
  ['502152', '502', '152', 'Malaysia', 'YTL Communications'],
  ['502152', '502', '152', 'Malaysia', 'YTL Communications'],
  ['502152', '502', '152', 'Malaysia', 'YTL Communications'],
  ['502152', '502', '152', 'Malaysia', 'YTL Communications'],
  ['502152', '502', '152', 'Malaysia', 'YTL Communications'],
  ['502152', '502', '152', 'Malaysia', 'YTL Communications'],
  ['502152', '502', '152', 'Malaysia', 'YTL Communications'],
  ['502152', '502', '152', 'Malaysia', 'YTL Communications'],
  ['502152', '502', '152', 'Malaysia', 'YTL Communications'],
  ['502152', '502', '152', 'Malaysia', 'YTL Communications'],
  ['502152', '502', '152', 'Malaysia', 'YTL Communications'],
  ['502152', '502', '152', 'Malaysia', 'YTL Communications'],
  ['502152', '502', '152', 'Malaysia', 'YTL Communications'],
  ['502152', '502', '152', 'Malaysia', 'YTL Communications'],
  ['502152', '502', '152', 'Malaysia', 'YTL Communications'],
  ['502152', '502', '152', 'Malaysia', 'YTL Communications'],
  ['502152', '502', '152', 'Malaysia', 'YTL Communications'],
  ['502152', '502', '152', 'Malaysia', 'YTL Communications'],
  ['502153', '502', '153', 'Malaysia', 'UNIFI - Webe Digital (Packet One)'],
  ['502153', '502', '153', 'Malaysia', 'UNIFI - Webe Digital (Packet One)'],
  ['502153', '502', '153', 'Malaysia', 'UNIFI - Webe Digital (Packet One)'],
  ['502153', '502', '153', 'Malaysia', 'UNIFI - Webe Digital (Packet One)'],
  ['502153', '502', '153', 'Malaysia', 'UNIFI - Webe Digital (Packet One)'],
  ['502153', '502', '153', 'Malaysia', 'UNIFI - Webe Digital (Packet One)'],
  ['502153', '502', '153', 'Malaysia', 'UNIFI - Webe Digital (Packet One)'],
  ['502153', '502', '153', 'Malaysia', 'UNIFI - Webe Digital (Packet One)'],
  ['502153', '502', '153', 'Malaysia', 'UNIFI - Webe Digital (Packet One)'],
  ['502153', '502', '153', 'Malaysia', 'UNIFI - Webe Digital (Packet One)'],
  ['502153', '502', '153', 'Malaysia', 'UNIFI - Webe Digital (Packet One)'],
  ['502153', '502', '153', 'Malaysia', 'UNIFI - Webe Digital (Packet One)'],
  ['502153', '502', '153', 'Malaysia', 'UNIFI - Webe Digital (Packet One)'],
  ['502153', '502', '153', 'Malaysia', 'UNIFI - Webe Digital (Packet One)'],
  ['502153', '502', '153', 'Malaysia', 'UNIFI - Webe Digital (Packet One)'],
  ['502153', '502', '153', 'Malaysia', 'UNIFI - Webe Digital (Packet One)'],
  ['502153', '502', '153', 'Malaysia', 'UNIFI - Webe Digital (Packet One)'],
  ['502153', '502', '153', 'Malaysia', 'UNIFI - Webe Digital (Packet One)'],
  ['502153', '502', '153', 'Malaysia', 'UNIFI - Webe Digital (Packet One)'],
  ['502153', '502', '153', 'Malaysia', 'UNIFI - Webe Digital (Packet One)'],
  ['502153', '502', '153', 'Malaysia', 'UNIFI - Webe Digital (Packet One)'],
  ['502153', '502', '153', 'Malaysia', 'UNIFI - Webe Digital (Packet One)'],
  ['502153', '502', '153', 'Malaysia', 'UNIFI - Webe Digital (Packet One)'],
  ['502153', '502', '153', 'Malaysia', 'UNIFI - Webe Digital (Packet One)'],
  ['502153', '502', '153', 'Malaysia', 'UNIFI - Webe Digital (Packet One)'],
  ['502153', '502', '153', 'Malaysia', 'UNIFI - Webe Digital (Packet One)'],
  ['502153', '502', '153', 'Malaysia', 'UNIFI - Webe Digital (Packet One)'],
  ['502153', '502', '153', 'Malaysia', 'UNIFI - Webe Digital (Packet One)'],
  ['502153', '502', '153', 'Malaysia', 'UNIFI - Webe Digital (Packet One)'],
  ['502153', '502', '153', 'Malaysia', 'UNIFI - Webe Digital (Packet One)'],
  ['502153', '502', '153', 'Malaysia', 'UNIFI - Webe Digital (Packet One)'],
  ['502153', '502', '153', 'Malaysia', 'UNIFI - Webe Digital (Packet One)'],
  ['502153', '502', '153', 'Malaysia', 'UNIFI - Webe Digital (Packet One)'],
  ['502153', '502', '153', 'Malaysia', 'UNIFI - Webe Digital (Packet One)'],
  ['502153', '502', '153', 'Malaysia', 'UNIFI - Webe Digital (Packet One)'],
  ['502153', '502', '153', 'Malaysia', 'UNIFI - Webe Digital (Packet One)'],
  ['502153', '502', '153', 'Malaysia', 'UNIFI - Webe Digital (Packet One)'],
  ['502153', '502', '153', 'Malaysia', 'UNIFI - Webe Digital (Packet One)'],
  ['50216', '502', '16', 'Malaysia', 'DIGI TELECOM'],
  ['50217', '502', '17', 'Malaysia', 'TIME WIRELESS'],
  ['50218', '502', '18', 'Malaysia', 'Umobile'],
  ['50218', '502', '18', 'Malaysia', 'Umobile'],
  ['50218', '502', '18', 'Malaysia', 'Umobile'],
  ['50218', '502', '18', 'Malaysia', 'Umobile'],
  ['50218', '502', '18', 'Malaysia', 'Umobile'],
  ['50218', '502', '18', 'Malaysia', 'Umobile'],
  ['50218', '502', '18', 'Malaysia', 'Umobile'],
  ['50218', '502', '18', 'Malaysia', 'Umobile'],
  ['50218', '502', '18', 'Malaysia', 'Umobile'],
  ['50218', '502', '18', 'Malaysia', 'Umobile'],
  ['50218', '502', '18', 'Malaysia', 'Umobile'],
  ['50218', '502', '18', 'Malaysia', 'Umobile'],
  ['50218', '502', '18', 'Malaysia', 'Umobile'],
  ['50218', '502', '18', 'Malaysia', 'Umobile'],
  ['50218', '502', '18', 'Malaysia', 'Umobile'],
  ['50218', '502', '18', 'Malaysia', 'Umobile'],
  ['50218', '502', '18', 'Malaysia', 'Umobile'],
  ['50218', '502', '18', 'Malaysia', 'Umobile'],
  ['50218', '502', '18', 'Malaysia', 'Umobile'],
  ['50218', '502', '18', 'Malaysia', 'Umobile'],
  ['50218', '502', '18', 'Malaysia', 'Umobile'],
  ['50218', '502', '18', 'Malaysia', 'Umobile'],
  ['50218', '502', '18', 'Malaysia', 'Umobile'],
  ['50218', '502', '18', 'Malaysia', 'Umobile'],
  ['50218', '502', '18', 'Malaysia', 'Umobile'],
  ['50218', '502', '18', 'Malaysia', 'Umobile'],
  ['50218', '502', '18', 'Malaysia', 'Umobile'],
  ['50218', '502', '18', 'Malaysia', 'Umobile'],
  ['50218', '502', '18', 'Malaysia', 'Umobile'],
  ['50218', '502', '18', 'Malaysia', 'Umobile'],
  ['50218', '502', '18', 'Malaysia', 'Umobile'],
  ['50218', '502', '18', 'Malaysia', 'Umobile'],
  ['50218', '502', '18', 'Malaysia', 'Umobile'],
  ['50218', '502', '18', 'Malaysia', 'Umobile'],
  ['50218', '502', '18', 'Malaysia', 'Umobile'],
  ['50218', '502', '18', 'Malaysia', 'Umobile'],
  ['50218', '502', '18', 'Malaysia', 'Umobile'],
  ['50218', '502', '18', 'Malaysia', 'Umobile'],
  ['50218', '502', '18', 'Malaysia', 'Umobile'],
  ['50218', '502', '18', 'Malaysia', 'Umobile'],
  ['50218', '502', '18', 'Malaysia', 'Umobile'],
  ['50218', '502', '18', 'Malaysia', 'Umobile'],
  ['50218', '502', '18', 'Malaysia', 'Umobile'],
  ['50218', '502', '18', 'Malaysia', 'Umobile'],
  ['50218', '502', '18', 'Malaysia', 'Umobile'],
  ['50219', '502', '19', 'Malaysia', 'CELCOM'],
  ['4720', '472', '0', 'Maldives', 'DEFAULT MALDIVES'],
  ['47201', '472', '1', 'Maldives', 'DHIRAAGU'],
  ['47201', '472', '1', 'Maldives', 'DHIRAAGU'],
  ['47201', '472', '1', 'Maldives', 'DHIRAAGU'],
  ['47201', '472', '1', 'Maldives', 'DHIRAAGU'],
  ['47202', '472', '2', 'Maldives', 'WATANIYA'],
  ['47202', '472', '2', 'Maldives', 'WATANIYA'],
  ['47202', '472', '2', 'Maldives', 'WATANIYA'],
  ['47202', '472', '2', 'Maldives', 'WATANIYA'],
  ['6100', '610', '0', 'Mali', 'DEFAULT MALI'],
  ['61001', '610', '1', 'Mali', 'MALITEL'],
  ['61002', '610', '2', 'Mali', 'IKATEL'],
  ['61002', '610', '2', 'Mali', 'IKATEL'],
  ['61002', '610', '2', 'Mali', 'IKATEL'],
  ['61002', '610', '2', 'Mali', 'IKATEL'],
  ['61002', '610', '2', 'Mali', 'IKATEL'],
  ['61003', '610', '3', 'Mali', 'Telecel (ATEL)'],
  ['2780', '278', '0', 'Malta', 'DEFAULT MALTA'],
  ['27801', '278', '1', 'Malta', 'VODAFONE'],
  ['27801', '278', '1', 'Malta', 'VODAFONE'],
  ['27801', '278', '1', 'Malta', 'VODAFONE'],
  ['27801', '278', '1', 'Malta', 'VODAFONE'],
  ['27821', '278', '21', 'Malta', 'GO MOBILE'],
  ['3400', '340', '0', 'Martinique (French)', 'DEFAULT MARTINIQUE'],
  ['34001', '340', '1', 'Martinique (French)', 'ORANGE CARAIBE'],
  ['34001', '340', '1', 'Martinique (French)', 'ORANGE CARAIBE'],
  ['34001', '340', '1', 'Martinique (French)', 'ORANGE CARAIBE'],
  ['34001', '340', '1', 'Martinique (French)', 'ORANGE CARAIBE'],
  ['34020', '340', '20', 'Martinique (French)', 'BOUYGUES TELECOM'],
  ['34020', '340', '20', 'Martinique (French)', 'BOUYGUES TELECOM'],
  ['6090', '609', '0', 'Mauritania', 'DEFAULT MAURITANIA'],
  ['60901', '609', '1', 'Mauritania', 'MATTEL'],
  ['60901', '609', '1', 'Mauritania', 'MATTEL'],
  ['60901', '609', '1', 'Mauritania', 'MATTEL'],
  ['60910', '609', '10', 'Mauritania', 'MAURITEL MOBILES'],
  ['60910', '609', '10', 'Mauritania', 'MAURITEL MOBILES'],
  ['60910', '609', '10', 'Mauritania', 'MAURITEL MOBILES'],
  ['60910', '609', '10', 'Mauritania', 'MAURITEL MOBILES'],
  ['60910', '609', '10', 'Mauritania', 'MAURITEL MOBILES'],
  ['6170', '617', '0', 'Mauritius', 'DEFAULT MAURITIUS'],
  ['61701', '617', '1', 'Mauritius', 'CELLPLUS'],
  ['61701', '617', '1', 'Mauritius', 'CELLPLUS'],
  ['61701', '617', '1', 'Mauritius', 'CELLPLUS'],
  ['61701', '617', '1', 'Mauritius', 'CELLPLUS'],
  ['61701', '617', '1', 'Mauritius', 'CELLPLUS'],
  ['61701', '617', '1', 'Mauritius', 'CELLPLUS'],
  ['61710', '617', '10', 'Mauritius', 'EMTEL'],
  ['61710', '617', '10', 'Mauritius', 'EMTEL'],
  ['61710', '617', '10', 'Mauritius', 'EMTEL'],
  ['61710', '617', '10', 'Mauritius', 'EMTEL'],
  ['61710', '617', '10', 'Mauritius', 'EMTEL'],
  ['61710', '617', '10', 'Mauritius', 'EMTEL'],
  ['3340', '334', '0', 'Mexico', 'DEFAULT MEXICO'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33402', '334', '2', 'Mexico', 'TELCEL (RADIOMOVIL)'],
  ['33403', '334', '3', 'Mexico', 'MOVISTAR'],
  ['33403', '334', '3', 'Mexico', 'MOVISTAR'],
  ['33403', '334', '3', 'Mexico', 'MOVISTAR'],
  ['33403', '334', '3', 'Mexico', 'MOVISTAR'],
  ['2590', '259', '0', 'Moldavia', 'DEFAULT MOLDOVA'],
  ['25901', '259', '1', 'Moldavia', 'VOXTEL SA'],
  ['25902', '259', '2', 'Moldavia', 'MOLDCELL'],
  ['2120', '212', '0', 'Monaco', 'DEFAULT MONACO'],
  ['21201', '212', '1', 'Monaco', 'MONACO TELECOM'],
  ['21201', '212', '1', 'Monaco', 'MONACO TELECOM'],
  ['4280', '428', '0', 'Mongolia', 'DEFAULT MONGOLIA'],
  ['42888', '428', '88', 'Mongolia', 'Unitel'],
  ['42888', '428', '88', 'Mongolia', 'Unitel'],
  ['42888', '428', '88', 'Mongolia', 'Unitel'],
  ['42888', '428', '88', 'Mongolia', 'Unitel'],
  ['42888', '428', '88', 'Mongolia', 'Unitel'],
  ['42888', '428', '88', 'Mongolia', 'Unitel'],
  ['42891', '428', '91', 'Mongolia', 'Skytel'],
  ['42891', '428', '91', 'Mongolia', 'Skytel'],
  ['42891', '428', '91', 'Mongolia', 'Skytel'],
  ['42891', '428', '91', 'Mongolia', 'Skytel'],
  ['42898', '428', '98', 'Mongolia', 'GMobile'],
  ['42898', '428', '98', 'Mongolia', 'GMobile'],
  ['42898', '428', '98', 'Mongolia', 'GMobile'],
  ['42898', '428', '98', 'Mongolia', 'GMobile'],
  ['42899', '428', '99', 'Mongolia', 'MOBICOM'],
  ['6040', '604', '0', 'Morocco', 'DEFAULT MOROCCO'],
  ['60400', '604', '0', 'Morocco', 'MEDITELECOM'],
  ['60400', '604', '0', 'Morocco', 'MEDITELECOM'],
  ['60400', '604', '0', 'Morocco', 'MEDITELECOM'],
  ['60400', '604', '0', 'Morocco', 'MEDITELECOM'],
  ['60400', '604', '0', 'Morocco', 'MEDITELECOM'],
  ['60401', '604', '1', 'Morocco', 'ITISSALAT AL-MAGHRIB'],
  ['60401', '604', '1', 'Morocco', 'ITISSALAT AL-MAGHRIB'],
  ['60401', '604', '1', 'Morocco', 'ITISSALAT AL-MAGHRIB'],
  ['60401', '604', '1', 'Morocco', 'ITISSALAT AL-MAGHRIB'],
  ['60401', '604', '1', 'Morocco', 'ITISSALAT AL-MAGHRIB'],
  ['60401', '604', '1', 'Morocco', 'ITISSALAT AL-MAGHRIB'],
  ['60401', '604', '1', 'Morocco', 'ITISSALAT AL-MAGHRIB'],
  ['60401', '604', '1', 'Morocco', 'ITISSALAT AL-MAGHRIB'],
  ['60401', '604', '1', 'Morocco', 'ITISSALAT AL-MAGHRIB'],
  ['60401', '604', '1', 'Morocco', 'ITISSALAT AL-MAGHRIB'],
  ['60401', '604', '1', 'Morocco', 'ITISSALAT AL-MAGHRIB'],
  ['60401', '604', '1', 'Morocco', 'ITISSALAT AL-MAGHRIB'],
  ['60401', '604', '1', 'Morocco', 'ITISSALAT AL-MAGHRIB'],
  ['60401', '604', '1', 'Morocco', 'ITISSALAT AL-MAGHRIB'],
  ['60402', '604', '2', 'Morocco', 'Unknown-MOROCCO-6332'],
  ['6430', '643', '0', 'Mozambique', 'DEFAULT MOZAMBIQUE'],
  ['64301', '643', '1', 'Mozambique', 'T.D.M. MCELL'],
  ['64303', '643', '3', 'Mozambique', 'Movitel (Viettel)'],
  ['64303', '643', '3', 'Mozambique', 'Movitel (Viettel)'],
  ['64304', '643', '4', 'Mozambique', 'VODACOM'],
  ['4140', '414', '0', 'Myanmar', 'DEFAULT Myanmar'],
  ['4140', '414', '0', 'Myanmar', 'Telenor'],
  ['4140', '414', '0', 'Myanmar', 'Ooredo'],
  ['4140', '414', '0', 'Myanmar', 'MPT GSM Network'],
  ['4140', '414', '0', 'Myanmar', 'Mytel (Viettel)'],
  ['41401', '414', '1', 'Myanmar', 'MPT GSM Network'],
  ['41401', '414', '1', 'Myanmar', 'MPT GSM Network'],
  ['41401', '414', '1', 'Myanmar', 'MPT GSM Network'],
  ['41401', '414', '1', 'Myanmar', 'MPT GSM Network'],
  ['41401', '414', '1', 'Myanmar', 'MPT GSM Network'],
  ['41401', '414', '1', 'Myanmar', 'MPT GSM Network'],
  ['41401', '414', '1', 'Myanmar', 'MPT GSM Network'],
  ['41401', '414', '1', 'Myanmar', 'MPT GSM Network'],
  ['41401', '414', '1', 'Myanmar', 'MPT GSM Network'],
  ['41401', '414', '1', 'Myanmar', 'MPT GSM Network'],
  ['41401', '414', '1', 'Myanmar', 'MPT GSM Network'],
  ['41401', '414', '1', 'Myanmar', 'MPT GSM Network'],
  ['41401', '414', '1', 'Myanmar', 'MPT GSM Network'],
  ['41401', '414', '1', 'Myanmar', 'MPT GSM Network'],
  ['41401', '414', '1', 'Myanmar', 'MPT GSM Network'],
  ['41401', '414', '1', 'Myanmar', 'MPT GSM Network'],
  ['41401', '414', '1', 'Myanmar', 'MPT GSM Network'],
  ['41401', '414', '1', 'Myanmar', 'MPT GSM Network'],
  ['41401', '414', '1', 'Myanmar', 'MPT GSM Network'],
  ['41401', '414', '1', 'Myanmar', 'MPT GSM Network'],
  ['41401', '414', '1', 'Myanmar', 'MPT GSM Network'],
  ['41405', '414', '5', 'Myanmar', 'Ooredo'],
  ['41405', '414', '5', 'Myanmar', 'Ooredo'],
  ['41405', '414', '5', 'Myanmar', 'Ooredo'],
  ['41405', '414', '5', 'Myanmar', 'Ooredo'],
  ['41405', '414', '5', 'Myanmar', 'Ooredo'],
  ['41406', '414', '6', 'Myanmar', 'Telenor'],
  ['41409', '414', '9', 'Myanmar', 'Mytel (Viettel)'],
  ['5460', '546', '0', 'Namibia', 'DEFAULT NEW CALEDONIA'],
  ['54601', '546', '1', 'Namibia', 'OPT'],
  ['54601', '546', '1', 'Namibia', 'OPT'],
  ['54601', '546', '1', 'Namibia', 'OPT'],
  ['54601', '546', '1', 'Namibia', 'OPT'],
  ['54601', '546', '1', 'Namibia', 'OPT'],
  ['6490', '649', '0', 'Namibia', 'DEFAULT NAMIDIA'],
  ['64901', '649', '1', 'Namibia', 'MTC'],
  ['64903', '649', '3', 'Namibia', 'Cell One / Leo Orascom'],
  ['64903', '649', '3', 'Namibia', 'Cell One / Leo Orascom'],
  ['4290', '429', '0', 'Nepal', 'Ncell Spice - Mero'],
  ['4290', '429', '0', 'Nepal', 'Nepal Telecom'],
  ['4290', '429', '0', 'Nepal', 'Smart Telecom'],
  ['4290', '429', '0', 'Nepal', 'DEFAULT Nepal'],
  ['42901', '429', '1', 'Nepal', 'Nepal Telecom'],
  ['42901', '429', '1', 'Nepal', 'Nepal Telecom'],
  ['42901', '429', '1', 'Nepal', 'Nepal Telecom'],
  ['42902', '429', '2', 'Nepal', 'Ncell Spice - Mero'],
  ['42902', '429', '2', 'Nepal', 'Ncell Spice - Mero'],
  ['42902', '429', '2', 'Nepal', 'Ncell Spice - Mero'],
  ['42904', '429', '4', 'Nepal', 'Smart Telecom'],
  ['42904', '429', '4', 'Nepal', 'Smart Telecom'],
  ['42904', '429', '4', 'Nepal', 'Smart Telecom'],
  ['2040', '204', '0', 'Netherlands', 'DEFAULT NETHERLANDS'],
  ['20402', '204', '2', 'Netherlands', 'TELE 2'],
  ['20402', '204', '2', 'Netherlands', 'TELE 2'],
  ['20404', '204', '4', 'Netherlands', 'VODAFONE'],
  ['20404', '204', '4', 'Netherlands', 'VODAFONE'],
  ['20404', '204', '4', 'Netherlands', 'VODAFONE'],
  ['20404', '204', '4', 'Netherlands', 'VODAFONE'],
  ['20404', '204', '4', 'Netherlands', 'VODAFONE'],
  ['20404', '204', '4', 'Netherlands', 'VODAFONE'],
  ['20404', '204', '4', 'Netherlands', 'VODAFONE'],
  ['20404', '204', '4', 'Netherlands', 'VODAFONE'],
  ['20404', '204', '4', 'Netherlands', 'VODAFONE'],
  ['20404', '204', '4', 'Netherlands', 'VODAFONE'],
  ['20404', '204', '4', 'Netherlands', 'VODAFONE'],
  ['20408', '204', '8', 'Netherlands', 'KPN MOBILE'],
  ['20408', '204', '8', 'Netherlands', 'KPN MOBILE'],
  ['20408', '204', '8', 'Netherlands', 'KPN MOBILE'],
  ['20408', '204', '8', 'Netherlands', 'KPN MOBILE'],
  ['20408', '204', '8', 'Netherlands', 'KPN MOBILE'],
  ['20408', '204', '8', 'Netherlands', 'KPN MOBILE'],
  ['20408', '204', '8', 'Netherlands', 'KPN MOBILE'],
  ['20408', '204', '8', 'Netherlands', 'KPN MOBILE'],
  ['20408', '204', '8', 'Netherlands', 'KPN MOBILE'],
  ['20412', '204', '12', 'Netherlands', 'TELFORT'],
  ['20412', '204', '12', 'Netherlands', 'TELFORT'],
  ['20412', '204', '12', 'Netherlands', 'TELFORT'],
  ['20412', '204', '12', 'Netherlands', 'TELFORT'],
  ['20412', '204', '12', 'Netherlands', 'TELFORT'],
  ['20412', '204', '12', 'Netherlands', 'TELFORT'],
  ['20412', '204', '12', 'Netherlands', 'TELFORT'],
  ['20412', '204', '12', 'Netherlands', 'TELFORT'],
  ['20412', '204', '12', 'Netherlands', 'TELFORT'],
  ['20416', '204', '16', 'Netherlands', 'T-MOBILE'],
  ['20416', '204', '16', 'Netherlands', 'T-MOBILE'],
  ['20416', '204', '16', 'Netherlands', 'T-MOBILE'],
  ['20416', '204', '16', 'Netherlands', 'T-MOBILE'],
  ['20416', '204', '16', 'Netherlands', 'T-MOBILE'],
  ['20420', '204', '20', 'Netherlands', 'ORANGE'],
  ['20420', '204', '20', 'Netherlands', 'ORANGE'],
  ['20420', '204', '20', 'Netherlands', 'ORANGE'],
  ['20420', '204', '20', 'Netherlands', 'ORANGE'],
  ['3620', '362', '0', 'Netherlands Antilles', 'DEFAULT NETHERLANDS ANTILLES'],
  ['36251', '362', '51', 'Netherlands Antilles', 'TELCELL (ST MAARTEN)'],
  ['36251', '362', '51', 'Netherlands Antilles', 'TELCELL (ST MAARTEN)'],
  ['36251', '362', '51', 'Netherlands Antilles', 'TELEM (ST MAARTEN)'],
  ['36251', '362', '51', 'Netherlands Antilles', 'TELEM (ST MAARTEN)'],
  ['36251', '362', '51', 'Netherlands Antilles', 'TELCELL (ST MAARTEN)'],
  ['36251', '362', '51', 'Netherlands Antilles', 'TELCELL (ST MAARTEN)'],
  ['36251', '362', '51', 'Netherlands Antilles', 'TELCELL (ST MAARTEN)'],
  ['36251', '362', '51', 'Netherlands Antilles', 'TELCELL (ST MAARTEN)'],
  ['36251', '362', '51', 'Netherlands Antilles', 'TELCELL (ST MAARTEN)'],
  ['36251', '362', '51', 'Netherlands Antilles', 'TELCELL (ST MAARTEN)'],
  ['36251', '362', '51', 'Netherlands Antilles', 'TELCELL (ST MAARTEN)'],
  ['36251', '362', '51', 'Netherlands Antilles', 'TELEM (ST MAARTEN)'],
  ['36296', '362', '96', 'Netherlands Antilles', 'SETEL (CURACAO)'],
  ['36296', '362', '96', 'Netherlands Antilles', 'SETEL (CURACAO)'],
  ['36296', '362', '96', 'Netherlands Antilles', 'SETEL (CURACAO)'],
  ['36296', '362', '96', 'Netherlands Antilles', 'SETEL (CURACAO)'],
  ['36296', '362', '96', 'Netherlands Antilles', 'SETEL (CURACAO)'],
  ['36296', '362', '96', 'Netherlands Antilles', 'SETEL (CURACAO)'],
  ['36296', '362', '96', 'Netherlands Antilles', 'SETEL (CURACAO)'],
  ['36296', '362', '96', 'Netherlands Antilles', 'SETEL (CURACAO)'],
  ['5300', '530', '0', 'New Zealand', 'DEFAULT NEW ZELAND'],
  ['53001', '530', '1', 'New Zealand', 'TELSTRA'],
  ['53001', '530', '1', 'New Zealand', 'VODAFONE'],
  ['53002', '530', '2', 'New Zealand', 'Telecom New Zealand - Spark'],
  ['53003', '530', '3', 'New Zealand', 'Telecom New Zealand - Spark'],
  ['53004', '530', '4', 'New Zealand', 'Vodafone - Telstra'],
  ['53004', '530', '4', 'New Zealand', 'Vodafone - Telstra'],
  ['53004', '530', '4', 'New Zealand', 'Vodafone - Telstra'],
  ['53004', '530', '4', 'New Zealand', 'Vodafone - Telstra'],
  ['53004', '530', '4', 'New Zealand', 'Vodafone - Telstra'],
  ['53004', '530', '4', 'New Zealand', 'Vodafone - Telstra'],
  ['53005', '530', '5', 'New Zealand', 'Telecom New Zealand - Spark'],
  ['53024', '530', '24', 'New Zealand', 'Two Degrees - Northelia Holdings'],
  ['53024', '530', '24', 'New Zealand', 'Two Degrees - Northelia Holdings'],
  ['7100', '710', '0', 'Nicaragua', 'DEFAULT NICARAGUA'],
  ['71021', '710', '21', 'Nicaragua', 'ENITEL'],
  ['71030', '710', '30', 'Nicaragua', 'Movistar'],
  ['71030', '710', '30', 'Nicaragua', 'Movistar'],
  ['71030', '710', '30', 'Nicaragua', 'Movistar'],
  ['71030', '710', '30', 'Nicaragua', 'Movistar'],
  ['71030', '710', '30', 'Nicaragua', 'Movistar'],
  ['71030', '710', '30', 'Nicaragua', 'Movistar'],
  ['71030', '710', '30', 'Nicaragua', 'Movistar'],
  ['71030', '710', '30', 'Nicaragua', 'Movistar'],
  ['71030', '710', '30', 'Nicaragua', 'Movistar'],
  ['71030', '710', '30', 'Nicaragua', 'Movistar'],
  ['71030', '710', '30', 'Nicaragua', 'Movistar'],
  ['71030', '710', '30', 'Nicaragua', 'Movistar'],
  ['71030', '710', '30', 'Nicaragua', 'Movistar'],
  ['71030', '710', '30', 'Nicaragua', 'Movistar'],
  ['71030', '710', '30', 'Nicaragua', 'Movistar'],
  ['71030', '710', '30', 'Nicaragua', 'Movistar'],
  ['71030', '710', '30', 'Nicaragua', 'Movistar'],
  ['71030', '710', '30', 'Nicaragua', 'Movistar'],
  ['71030', '710', '30', 'Nicaragua', 'Movistar'],
  ['71030', '710', '30', 'Nicaragua', 'Movistar'],
  ['71030', '710', '30', 'Nicaragua', 'Movistar'],
  ['71030', '710', '30', 'Nicaragua', 'Movistar'],
  ['71030', '710', '30', 'Nicaragua', 'Movistar'],
  ['71030', '710', '30', 'Nicaragua', 'Movistar'],
  ['71030', '710', '30', 'Nicaragua', 'Movistar'],
  ['71030', '710', '30', 'Nicaragua', 'Movistar'],
  ['71030', '710', '30', 'Nicaragua', 'Movistar'],
  ['71030', '710', '30', 'Nicaragua', 'Movistar'],
  ['71030', '710', '30', 'Nicaragua', 'Movistar'],
  ['710300', '710', '300', 'Nicaragua', 'Movistar'],
  ['710300', '710', '300', 'Nicaragua', 'Movistar'],
  ['710300', '710', '300', 'Nicaragua', 'Movistar'],
  ['710300', '710', '300', 'Nicaragua', 'Movistar'],
  ['710300', '710', '300', 'Nicaragua', 'Movistar'],
  ['710300', '710', '300', 'Nicaragua', 'Movistar'],
  ['710300', '710', '300', 'Nicaragua', 'Movistar'],
  ['710300', '710', '300', 'Nicaragua', 'Movistar'],
  ['710300', '710', '300', 'Nicaragua', 'Movistar'],
  ['710300', '710', '300', 'Nicaragua', 'Movistar'],
  ['710300', '710', '300', 'Nicaragua', 'Movistar'],
  ['710300', '710', '300', 'Nicaragua', 'Movistar'],
  ['710300', '710', '300', 'Nicaragua', 'Movistar'],
  ['710300', '710', '300', 'Nicaragua', 'Movistar'],
  ['710300', '710', '300', 'Nicaragua', 'Movistar'],
  ['710300', '710', '300', 'Nicaragua', 'Movistar'],
  ['710300', '710', '300', 'Nicaragua', 'Movistar'],
  ['710300', '710', '300', 'Nicaragua', 'Movistar'],
  ['710300', '710', '300', 'Nicaragua', 'Movistar'],
  ['710300', '710', '300', 'Nicaragua', 'Movistar'],
  ['710300', '710', '300', 'Nicaragua', 'Movistar'],
  ['710300', '710', '300', 'Nicaragua', 'Movistar'],
  ['710300', '710', '300', 'Nicaragua', 'Movistar'],
  ['710300', '710', '300', 'Nicaragua', 'Movistar'],
  ['710300', '710', '300', 'Nicaragua', 'Movistar'],
  ['710300', '710', '300', 'Nicaragua', 'Movistar'],
  ['710300', '710', '300', 'Nicaragua', 'Movistar'],
  ['710300', '710', '300', 'Nicaragua', 'Movistar'],
  ['710300', '710', '300', 'Nicaragua', 'Movistar'],
  ['71073', '710', '73', 'Nicaragua', 'Claro (Enitel)'],
  ['71073', '710', '73', 'Nicaragua', 'Claro (Enitel)'],
  ['71073', '710', '73', 'Nicaragua', 'Claro (Enitel)'],
  ['71073', '710', '73', 'Nicaragua', 'Claro (Enitel)'],
  ['71073', '710', '73', 'Nicaragua', 'Claro (Enitel)'],
  ['71073', '710', '73', 'Nicaragua', 'Claro (Enitel)'],
  ['71073', '710', '73', 'Nicaragua', 'Claro (Enitel)'],
  ['71073', '710', '73', 'Nicaragua', 'Claro (Enitel)'],
  ['71073', '710', '73', 'Nicaragua', 'Claro (Enitel)'],
  ['71073', '710', '73', 'Nicaragua', 'Claro (Enitel)'],
  ['71073', '710', '73', 'Nicaragua', 'Claro (Enitel)'],
  ['71073', '710', '73', 'Nicaragua', 'Claro (Enitel)'],
  ['71073', '710', '73', 'Nicaragua', 'Claro (Enitel)'],
  ['71073', '710', '73', 'Nicaragua', 'Claro (Enitel)'],
  ['71073', '710', '73', 'Nicaragua', 'Claro (Enitel)'],
  ['71073', '710', '73', 'Nicaragua', 'Claro (Enitel)'],
  ['71073', '710', '73', 'Nicaragua', 'Claro (Enitel)'],
  ['71073', '710', '73', 'Nicaragua', 'Claro (Enitel)'],
  ['71073', '710', '73', 'Nicaragua', 'Claro (Enitel)'],
  ['71073', '710', '73', 'Nicaragua', 'Claro (Enitel)'],
  ['71073', '710', '73', 'Nicaragua', 'Claro (Enitel)'],
  ['71073', '710', '73', 'Nicaragua', 'Claro (Enitel)'],
  ['71073', '710', '73', 'Nicaragua', 'Claro (Enitel)'],
  ['71073', '710', '73', 'Nicaragua', 'Claro (Enitel)'],
  ['71073', '710', '73', 'Nicaragua', 'Claro (Enitel)'],
  ['71073', '710', '73', 'Nicaragua', 'Claro (Enitel)'],
  ['71073', '710', '73', 'Nicaragua', 'Claro (Enitel)'],
  ['71073', '710', '73', 'Nicaragua', 'Claro (Enitel)'],
  ['71073', '710', '73', 'Nicaragua', 'Claro (Enitel)'],
  ['6140', '614', '0', 'Niger', 'DEFAULT NIGER'],
  ['61401', '614', '1', 'Niger', 'Sahelcom'],
  ['61401', '614', '1', 'Niger', 'Sahelcom'],
  ['61402', '614', '2', 'Niger', 'CELTEL'],
  ['61402', '614', '2', 'Niger', 'CELTEL'],
  ['61402', '614', '2', 'Niger', 'CELTEL'],
  ['61402', '614', '2', 'Niger', 'CELTEL'],
  ['61403', '614', '3', 'Niger', 'TELECEL'],
  ['61404', '614', '4', 'Niger', 'Orange'],
  ['61404', '614', '4', 'Niger', 'Orange'],
  ['61404', '614', '4', 'Niger', 'Orange'],
  ['61404', '614', '4', 'Niger', 'Orange'],
  ['61404', '614', '4', 'Niger', 'Orange'],
  ['61404', '614', '4', 'Niger', 'Orange'],
  ['61404', '614', '4', 'Niger', 'Orange'],
  ['61404', '614', '4', 'Niger', 'Orange'],
  ['6210', '621', '0', 'Nigeria', 'DEFAULT NIGERIA'],
  ['62120', '621', '20', 'Nigeria', 'VMOBILE'],
  ['62120', '621', '20', 'Nigeria', 'VMOBILE'],
  ['62120', '621', '20', 'Nigeria', 'VMOBILE'],
  ['62120', '621', '20', 'Nigeria', 'VMOBILE'],
  ['62127', '621', '27', 'Nigeria', 'Smile'],
  ['62130', '621', '30', 'Nigeria', 'MTN'],
  ['62130', '621', '30', 'Nigeria', 'MTN'],
  ['62130', '621', '30', 'Nigeria', 'MTN'],
  ['62130', '621', '30', 'Nigeria', 'MTN'],
  ['62130', '621', '30', 'Nigeria', 'NIGERIA MTN'],
  ['62130', '621', '30', 'Nigeria', 'MTN'],
  ['62130', '621', '30', 'Nigeria', 'NIGERIA MTN'],
  ['62130', '621', '30', 'Nigeria', 'MTN'],
  ['62130', '621', '30', 'Nigeria', 'MTN'],
  ['62130', '621', '30', 'Nigeria', 'NIGERIA MTN'],
  ['62130', '621', '30', 'Nigeria', 'NIGERIA MTN'],
  ['62130', '621', '30', 'Nigeria', 'NIGERIA MTN'],
  ['62130', '621', '30', 'Nigeria', 'MTN'],
  ['62140', '621', '40', 'Nigeria', 'M-TEL'],
  ['62140', '621', '40', 'Nigeria', 'M-TEL'],
  ['62140', '621', '40', 'Nigeria', 'M-TEL'],
  ['62141', '621', '41', 'Nigeria', 'Unknown-Nigeria-9028'],
  ['62142', '621', '42', 'Nigeria', 'Unknown-Nigeria-8076'],
  ['62150', '621', '50', 'Nigeria', 'GLO MOBILE'],
  ['62150', '621', '50', 'Nigeria', 'GLO MOBILE'],
  ['62150', '621', '50', 'Nigeria', 'GLO MOBILE'],
  ['62160', '621', '60', 'Nigeria', 'ETISALAT'],
  ['62160', '621', '60', 'Nigeria', 'EMTS'],
  ['62160', '621', '60', 'Nigeria', 'ETISALAT'],
  ['62160', '621', '60', 'Nigeria', 'EMTS'],
  ['62160', '621', '60', 'Nigeria', 'EMTS'],
  ['62160', '621', '60', 'Nigeria', 'EMTS'],
  ['310002', '310', '2', 'Northern Mariana Islands', 'VERIZON PACIFICA'],
  ['2420', '242', '0', 'Norway', 'DEFAULT NORWAY'],
  ['24201', '242', '1', 'Norway', 'TELENOR'],
  ['24201', '242', '1', 'Norway', 'TELENOR'],
  ['24201', '242', '1', 'Norway', 'TELENOR'],
  ['24201', '242', '1', 'Norway', 'TELENOR'],
  ['24201', '242', '1', 'Norway', 'TELENOR'],
  ['24201', '242', '1', 'Norway', 'TELENOR'],
  ['24201', '242', '1', 'Norway', 'TELENOR'],
  ['24201', '242', '1', 'Norway', 'TELENOR'],
  ['24201', '242', '1', 'Norway', 'TELENOR'],
  ['24201', '242', '1', 'Norway', 'TELENOR'],
  ['24201', '242', '1', 'Norway', 'TELENOR'],
  ['24201', '242', '1', 'Norway', 'TELENOR'],
  ['24201', '242', '1', 'Norway', 'TELENOR'],
  ['24201', '242', '1', 'Norway', 'TELENOR'],
  ['24201', '242', '1', 'Norway', 'TELENOR'],
  ['24201', '242', '1', 'Norway', 'TELENOR'],
  ['24201', '242', '1', 'Norway', 'TELENOR'],
  ['24201', '242', '1', 'Norway', 'TELENOR'],
  ['24201', '242', '1', 'Norway', 'TELENOR'],
  ['24201', '242', '1', 'Norway', 'TELENOR'],
  ['24201', '242', '1', 'Norway', 'TELENOR'],
  ['24201', '242', '1', 'Norway', 'TELENOR'],
  ['24201', '242', '1', 'Norway', 'TELENOR'],
  ['24201', '242', '1', 'Norway', 'TELENOR'],
  ['24201', '242', '1', 'Norway', 'TELENOR'],
  ['24201', '242', '1', 'Norway', 'TELENOR'],
  ['24201', '242', '1', 'Norway', 'TELENOR'],
  ['24201', '242', '1', 'Norway', 'TELENOR'],
  ['24201', '242', '1', 'Norway', 'TELENOR'],
  ['24201', '242', '1', 'Norway', 'TELENOR'],
  ['24201', '242', '1', 'Norway', 'TELENOR'],
  ['24201', '242', '1', 'Norway', 'TELENOR'],
  ['24201', '242', '1', 'Norway', 'TELENOR'],
  ['24201', '242', '1', 'Norway', 'TELENOR'],
  ['24201', '242', '1', 'Norway', 'TELENOR'],
  ['24201', '242', '1', 'Norway', 'TELENOR'],
  ['24201', '242', '1', 'Norway', 'TELENOR'],
  ['24201', '242', '1', 'Norway', 'TELENOR'],
  ['24201', '242', '1', 'Norway', 'TELENOR'],
  ['24201', '242', '1', 'Norway', 'TELENOR'],
  ['24201', '242', '1', 'Norway', 'TELENOR'],
  ['24201', '242', '1', 'Norway', 'TELENOR'],
  ['24201', '242', '1', 'Norway', 'TELENOR'],
  ['24201', '242', '1', 'Norway', 'TELENOR'],
  ['24201', '242', '1', 'Norway', 'TELENOR'],
  ['24201', '242', '1', 'Norway', 'TELENOR'],
  ['24201', '242', '1', 'Norway', 'TELENOR'],
  ['24201', '242', '1', 'Norway', 'TELENOR'],
  ['24201', '242', '1', 'Norway', 'TELENOR'],
  ['24201', '242', '1', 'Norway', 'TELENOR'],
  ['24201', '242', '1', 'Norway', 'TELENOR'],
  ['24201', '242', '1', 'Norway', 'TELENOR'],
  ['24201', '242', '1', 'Norway', 'TELENOR'],
  ['24201', '242', '1', 'Norway', 'TELENOR'],
  ['24202', '242', '2', 'Norway', 'SENSE COMMUNICATIONS'],
  ['24202', '242', '2', 'Norway', 'NETCOM'],
  ['24202', '242', '2', 'Norway', 'NETCOM'],
  ['24202', '242', '2', 'Norway', 'NETCOM'],
  ['24202', '242', '2', 'Norway', 'NETCOM'],
  ['24202', '242', '2', 'Norway', 'NETCOM'],
  ['24202', '242', '2', 'Norway', 'TELE 2'],
  ['24202', '242', '2', 'Norway', 'TELE 2'],
  ['24202', '242', '2', 'Norway', 'TELE 2'],
  ['24202', '242', '2', 'Norway', 'TELE 2'],
  ['24202', '242', '2', 'Norway', 'TELE 2'],
  ['24202', '242', '2', 'Norway', 'TELE 2'],
  ['24202', '242', '2', 'Norway', 'TELE 2'],
  ['24202', '242', '2', 'Norway', 'TDC NORGE'],
  ['24202', '242', '2', 'Norway', 'TDC NORGE'],
  ['24202', '242', '2', 'Norway', 'TDC NORGE'],
  ['24202', '242', '2', 'Norway', 'TDC NORGE'],
  ['24202', '242', '2', 'Norway', 'TDC NORGE'],
  ['24202', '242', '2', 'Norway', 'TDC NORGE'],
  ['24202', '242', '2', 'Norway', 'TDC NORGE'],
  ['24202', '242', '2', 'Norway', 'TDC NORGE'],
  ['24202', '242', '2', 'Norway', 'TDC NORGE'],
  ['24202', '242', '2', 'Norway', 'TDC NORGE'],
  ['24202', '242', '2', 'Norway', 'TDC NORGE'],
  ['24202', '242', '2', 'Norway', 'TDC NORGE'],
  ['24202', '242', '2', 'Norway', 'TDC NORGE'],
  ['24202', '242', '2', 'Norway', 'TDC NORGE'],
  ['24202', '242', '2', 'Norway', 'TALKMORE'],
  ['24202', '242', '2', 'Norway', 'TALKMORE'],
  ['24202', '242', '2', 'Norway', 'TALKMORE'],
  ['24202', '242', '2', 'Norway', 'TALKMORE'],
  ['24202', '242', '2', 'Norway', 'TALKMORE'],
  ['24202', '242', '2', 'Norway', 'SILVER MOBILITY'],
  ['24202', '242', '2', 'Norway', 'SILVER MOBILITY'],
  ['24202', '242', '2', 'Norway', 'SILVER MOBILITY'],
  ['24202', '242', '2', 'Norway', 'SENSE COMMUNICATIONS'],
  ['24202', '242', '2', 'Norway', 'SENSE COMMUNICATIONS'],
  ['24202', '242', '2', 'Norway', 'SENSE COMMUNICATIONS'],
  ['24202', '242', '2', 'Norway', 'SENSE COMMUNICATIONS'],
  ['24202', '242', '2', 'Norway', 'SENSE COMMUNICATIONS'],
  ['24202', '242', '2', 'Norway', 'SENSE COMMUNICATIONS'],
  ['24202', '242', '2', 'Norway', 'SENSE COMMUNICATIONS'],
  ['24202', '242', '2', 'Norway', 'SENSE COMMUNICATIONS'],
  ['24202', '242', '2', 'Norway', 'SENSE COMMUNICATIONS'],
  ['24202', '242', '2', 'Norway', 'SENSE COMMUNICATIONS'],
  ['24202', '242', '2', 'Norway', 'SENSE COMMUNICATIONS'],
  ['24202', '242', '2', 'Norway', 'YOU COMMUNICATIONS'],
  ['24202', '242', '2', 'Norway', 'YOU COMMUNICATIONS'],
  ['24202', '242', '2', 'Norway', 'YOU COMMUNICATIONS'],
  ['24202', '242', '2', 'Norway', 'YOU COMMUNICATIONS'],
  ['24202', '242', '2', 'Norway', 'YOU COMMUNICATIONS'],
  ['24202', '242', '2', 'Norway', 'YOU COMMUNICATIONS'],
  ['24202', '242', '2', 'Norway', 'YOU COMMUNICATIONS'],
  ['24202', '242', '2', 'Norway', 'YOU COMMUNICATIONS'],
  ['24202', '242', '2', 'Norway', 'YOU COMMUNICATIONS'],
  ['24202', '242', '2', 'Norway', 'YOU COMMUNICATIONS'],
  ['24202', '242', '2', 'Norway', 'YOU COMMUNICATIONS'],
  ['24202', 'mcc', 'mnc', 'Norway', 'YOU COMMUNICATIONS'],
  ['24202', '412', '0', 'Norway', 'YOU COMMUNICATIONS'],
  ['24202', '412', '1', 'Norway', 'YOU COMMUNICATIONS'],
  ['24202', '412', '20', 'Norway', 'YOU COMMUNICATIONS'],
  ['24202', '412', '40', 'Norway', 'YOU COMMUNICATIONS'],
  ['24202', '412', '50', 'Norway', 'FOON AS'],
  ['24202', '412', '80', 'Norway', 'FOON AS'],
  ['24202', '412', '80', 'Norway', 'FIX TELECOM'],
  ['24202', '412', '80', 'Norway', 'YOU COMMUNICATIONS'],
  ['24202', '412', '80', 'Norway', 'FIX TELECOM'],
  ['24202', '412', '80', 'Norway', 'MAINGATE'],
  ['24202', '412', '88', 'Norway', 'FIX TELECOM'],
  ['24202', '276', '0', 'Norway', 'FIX TELECOM'],
  ['24202', '276', '1', 'Norway', 'VENTELO'],
  ['24202', '276', '2', 'Norway', 'FIX TELECOM'],
  ['24202', '276', '3', 'Norway', 'FIX TELECOM'],
  ['24202', '276', '4', 'Norway', 'FIX TELECOM'],
  ['24202', '603', '0', 'Norway', 'TELETOPIA'],
  ['24202', '603', '1', 'Norway', 'COMBITEL NETWORKS'],
  ['24202', '603', '2', 'Norway', 'COMBITEL NETWORKS'],
  ['24202', '603', '2', 'Norway', 'COMBITEL NETWORKS'],
  ['24202', '603', '2', 'Norway', 'COMBITEL NETWORKS'],
  ['24202', '603', '2', 'Norway', 'COMBITEL NETWORKS'],
  ['24202', '603', '3', 'Norway', 'COMBITEL NETWORKS'],
  ['24202', '213', '0', 'Norway', 'COMBITEL NETWORKS'],
  ['24202', '213', '3', 'Norway', 'COMBITEL NETWORKS'],
  ['24202', '213', '3', 'Norway', 'COMBITEL NETWORKS'],
  ['24202', '213', '3', 'Norway', 'SENSE COMMUNICATIONS'],
  ['24202', '631', '0', 'Norway', 'SENSE COMMUNICATIONS'],
  ['24202', '631', '2', 'Norway', 'SENSE COMMUNICATIONS'],
  ['24202', '631', '2', 'Norway', 'TELETOPIA'],
  ['24202', '631', '3', 'Norway', 'SENSE COMMUNICATIONS'],
  ['24202', '310', '2', 'Norway', 'TELENORDIC'],
  ['24202', '310', '2', 'Norway', 'TELENORDIC'],
  ['24202', '310', '2', 'Norway', 'TELETOPIA'],
  ['24202', '310', '2', 'Norway', 'TELETOPIA'],
  ['24202', '310', '2', 'Norway', 'TELETOPIA'],
  ['24202', '310', '2', 'Norway', 'TELETOPIA'],
  ['24202', '722', '0', 'Norway', 'TELETOPIA'],
  ['24202', '722', '31', 'Norway', 'TELETOPIA'],
  ['24202', '722', '34', 'Norway', 'FOON AS'],
  ['24202', '722', '70', 'Norway', 'SENSE COMMUNICATIONS'],
  ['24202', '283', '0', 'Norway', 'NETCOM'],
  ['24202', '283', '1', 'Norway', 'MAINGATE'],
  ['24202', '283', '4', 'Norway', 'NETCOM'],
  ['24202', '283', '4', 'Norway', 'TELETOPIA'],
  ['24202', '283', '5', 'Norway', 'NETCOM'],
  ['24202', '283', '5', 'Norway', 'NETCOM'],
  ['24202', '283', '5', 'Norway', 'NETCOM'],
  ['24202', '283', '5', 'Norway', 'NETCOM'],
  ['24202', '283', '10', 'Norway', 'NETCOM'],
  ['24202', '283', '10', 'Norway', 'NETCOM'],
  ['24202', '283', '10', 'Norway', 'NETCOM'],
  ['24202', '283', '10', 'Norway', 'NETCOM'],
  ['24202', '363', '0', 'Norway', 'NETCOM'],
  ['24202', '363', '1', 'Norway', 'NETCOM'],
  ['24202', '363', '1', 'Norway', 'NETCOM'],
  ['24202', '363', '10', 'Norway', 'NETCOM'],
  ['24202', '363', '10', 'Norway', 'NETCOM'],
  ['24202', '505', '0', 'Norway', 'NETCOM'],
  ['24202', '505', '1', 'Norway', 'NETCOM'],
  ['24202', '505', '1', 'Norway', 'NETCOM'],
  ['24202', '505', '1', 'Norway', 'NETCOM'],
  ['24202', '505', '1', 'Norway', 'NETCOM'],
  ['24202', '505', '1', 'Norway', 'NETCOM'],
  ['24202', '505', '1', 'Norway', 'NETCOM'],
  ['24202', '505', '1', 'Norway', 'NETCOM'],
  ['24202', '505', '1', 'Norway', 'NETCOM'],
  ['24202', '505', '1', 'Norway', 'NETCOM'],
  ['24202', '505', '1', 'Norway', 'NEWPHONE NORGE'],
  ['24202', '505', '1', 'Norway', 'NEWPHONE NORGE'],
  ['24202', '505', '1', 'Norway', 'NEWPHONE NORGE'],
  ['24202', '505', '1', 'Norway', 'NEWPHONE NORGE'],
  ['24202', '505', '1', 'Norway', 'NEWPHONE NORGE'],
  ['24202', '505', '1', 'Norway', 'SENSE COMMUNICATIONS'],
  ['24202', '505', '2', 'Norway', 'NETCOM'],
  ['24202', '505', '2', 'Norway', 'SENSE COMMUNICATIONS'],
  ['24202', '505', '2', 'Norway', 'SENSE COMMUNICATIONS'],
  ['24202', '505', '2', 'Norway', 'SENSE COMMUNICATIONS'],
  ['24202', '505', '2', 'Norway', 'SENSE COMMUNICATIONS'],
  ['24202', '505', '2', 'Norway', 'SENSE COMMUNICATIONS'],
  ['24202', '505', '2', 'Norway', 'SENSE COMMUNICATIONS'],
  ['24202', '505', '2', 'Norway', 'SENSE COMMUNICATIONS'],
  ['24202', '505', '2', 'Norway', 'TELE 2'],
  ['24202', '505', '2', 'Norway', 'SENSE COMMUNICATIONS'],
  ['24202', '505', '2', 'Norway', 'SENSE COMMUNICATIONS'],
  ['24202', '505', '2', 'Norway', 'SENSE COMMUNICATIONS'],
  ['24202', '505', '2', 'Norway', 'SENSE COMMUNICATIONS'],
  ['24202', '505', '3', 'Norway', 'SENSE COMMUNICATIONS'],
  ['24202', '505', '3', 'Norway', 'SENSE COMMUNICATIONS'],
  ['24202', '505', '3', 'Norway', 'TELE 2'],
  ['24202', '505', '3', 'Norway', 'SENSE COMMUNICATIONS'],
  ['24202', '505', '3', 'Norway', 'MAINGATE'],
  ['24202', '505', '3', 'Norway', 'CHESS COMMUNICATIONS'],
  ['24202', '505', '3', 'Norway', 'VENTELO'],
  ['24202', '505', '3', 'Norway', 'VENTELO'],
  ['24202', '505', '6', 'Norway', 'VENTELO'],
  ['24202', '505', '6', 'Norway', 'VENTELO'],
  ['24202', '505', '12', 'Norway', 'VENTELO'],
  ['24202', '232', '0', 'Norway', 'VENTELO'],
  ['24202', '232', '1', 'Norway', 'VENTELO'],
  ['24202', '232', '1', 'Norway', 'VENTELO'],
  ['24202', '232', '2', 'Norway', 'CHESS COMMUNICATIONS'],
  ['24202', '232', '2', 'Norway', 'CHESS COMMUNICATIONS'],
  ['24202', '232', '2', 'Norway', 'CHESS COMMUNICATIONS'],
  ['24202', '232', '3', 'Norway', 'CHESS COMMUNICATIONS'],
  ['24202', '232', '4', 'Norway', 'MAINGATE'],
  ['24202', '232', '4', 'Norway', 'CHESS COMMUNICATIONS'],
  ['24202', '232', '4', 'Norway', 'CHESS COMMUNICATIONS'],
  ['24202', '232', '4', 'Norway', 'AXITI ASA'],
  ['24202', '232', '4', 'Norway', 'AXITI ASA'],
  ['24202', '232', '5', 'Norway', 'AXITI ASA'],
  ['24202', '232', '6', 'Norway', 'AXITI ASA'],
  ['24202', '232', '7', 'Norway', 'AXITI ASA'],
  ['24202', '232', '9', 'Norway', 'AXITI ASA'],
  ['24202', '232', '11', 'Norway', 'ACN NORGE'],
  ['24202', '232', '11', 'Norway', 'ACN NORGE'],
  ['24202', '232', '11', 'Norway', 'ACN NORGE'],
  ['24202', '232', '13', 'Norway', 'ACN NORGE'],
  ['24202', '232', '13', 'Norway', 'VENTELO'],
  ['24202', '232', '13', 'Norway', 'ACN NORGE'],
  ['24202', '232', '13', 'Norway', 'VENTELO'],
  ['24202', '232', '13', 'Norway', 'VENTELO'],
  ['24202', '400', '0', 'Norway', 'ACN NORGE'],
  ['24202', '400', '1', 'Norway', 'ACN NORGE'],
  ['24202', '400', '2', 'Norway', 'ACN NORGE'],
  ['24202', '400', '3', 'Norway', 'NETCOM'],
  ['24202', '400', '4', 'Norway', 'NETCOM'],
  ['24202', '426', '0', 'Norway', 'SENSE COMMUNICATIONS'],
  ['24202', '426', '1', 'Norway', 'VENTELO'],
  ['24202', '426', '2', 'Norway', 'YOU COMMUNICATIONS'],
  ['24202', '426', '4', 'Norway', 'YOU COMMUNICATIONS'],
  ['24202', '426', '4', 'Norway', 'MAINGATE'],
  ['24202', '426', '4', 'Norway', 'TELETOPIA'],
  ['24202', '426', '4', 'Norway', 'SENSE COMMUNICATIONS'],
  ['24202', '426', '5', 'Norway', 'SENSE COMMUNICATIONS'],
  ['24202', '426', '5', 'Norway', 'SENSE COMMUNICATIONS'],
  ['24202', '426', '5', 'Norway', 'SENSE COMMUNICATIONS'],
  ['24202', '426', '5', 'Norway', 'SENSE COMMUNICATIONS'],
  ['24202', '426', '5', 'Norway', 'SENSE COMMUNICATIONS'],
  ['24202', '426', '5', 'Norway', 'SENSE COMMUNICATIONS'],
  ['24202', '426', '5', 'Norway', 'SENSE COMMUNICATIONS'],
  ['24202', '426', '5', 'Norway', 'SENSE COMMUNICATIONS'],
  ['24202', '426', '5', 'Norway', 'TELETOPIA'],
  ['24202', '470', '0', 'Norway', 'SENSE COMMUNICATIONS'],
  ['24202', '470', '1', 'Norway', 'SENSE COMMUNICATIONS'],
  ['24202', '470', '2', 'Norway', 'SENSE COMMUNICATIONS'],
  ['24202', '470', '3', 'Norway', 'SENSE COMMUNICATIONS'],
  ['24202', '470', '4', 'Norway', 'VENTELO'],
  ['24202', '470', '5', 'Norway', 'SENSE COMMUNICATIONS'],
  ['24202', '470', '7', 'Norway', 'SENSE COMMUNICATIONS'],
  ['24202', '310', '2', 'Norway', 'SILVER MOBILITY'],
  ['24202', '342', '60', 'Norway', 'MOBYSON'],
  ['24202', '342', '60', 'Norway', 'MOLLER LOGISTIKK'],
  ['24202', '342', '60', 'Norway', 'NET NET'],
  ['24202', '342', '60', 'Norway', 'YOU COMMUNICATIONS'],
  ['24202', '342', '81', 'Norway', 'YOU COMMUNICATIONS'],
  ['24202', '342', '81', 'Norway', 'NET NET'],
  ['24202', '342', '81', 'Norway', 'YOU COMMUNICATIONS'],
  ['24202', '342', '81', 'Norway', 'VENTELO'],
  ['24202', '342', '81', 'Norway', 'VENTELO'],
  ['24202', '257', '0', 'Norway', 'VENTELO'],
  ['24202', '257', '1', 'Norway', 'VENTELO'],
  ['24202', '257', '1', 'Norway', 'VENTELO'],
  ['24202', '257', '1', 'Norway', 'VENTELO'],
  ['24202', '257', '2', 'Norway', 'FOON AS'],
  ['24202', '257', '2', 'Norway', 'MAINGATE'],
  ['24202', '257', '2', 'Norway', 'MOBILE CHOICE'],
  ['24202', '257', '4', 'Norway', 'MOBILE CHOICE'],
  ['24202', '206', '0', 'Norway', 'MOBILFABRIKEN'],
  ['24202', '206', '1', 'Norway', 'FOON AS'],
  ['24202', '206', '1', 'Norway', 'MOBILFABRIKEN'],
  ['24202', '206', '1', 'Norway', 'MOBILFABRIKEN'],
  ['24202', '206', '1', 'Norway', 'VENTELO'],
  ['24202', '206', '1', 'Norway', 'MOBILFABRIKEN'],
  ['24202', '206', '1', 'Norway', 'MOBILFABRIKEN'],
  ['24202', '206', '1', 'Norway', 'MOBYSON'],
  ['24202', '206', '1', 'Norway', 'VENTELO'],
  ['24202', '206', '1', 'Norway', 'LYSE TELE AS'],
  ['24202', '206', '5', 'Norway', 'MOBYSON'],
  ['24202', '206', '5', 'Norway', 'MOBYSON'],
  ['24202', '206', '5', 'Norway', 'NET NET'],
  ['24202', '206', '5', 'Norway', 'NETCOM'],
  ['24202', '206', '5', 'Norway', 'SENSE COMMUNICATIONS'],
  ['24202', '206', '5', 'Norway', 'SENSE COMMUNICATIONS'],
  ['24202', '206', '5', 'Norway', 'NETCOM'],
  ['24202', '206', '6', 'Norway', 'SENSE COMMUNICATIONS'],
  ['24202', '206', '6', 'Norway', 'CHESS COMMUNICATIONS'],
  ['24202', '206', '6', 'Norway', 'CHESS COMMUNICATIONS'],
  ['24202', '206', '6', 'Norway', 'CHESS COMMUNICATIONS'],
  ['24202', '206', '6', 'Norway', 'CHESS COMMUNICATIONS'],
  ['24202', '206', '6', 'Norway', 'CHESS COMMUNICATIONS'],
  ['24202', '206', '6', 'Norway', 'CHESS COMMUNICATIONS'],
  ['24202', '206', '6', 'Norway', 'TELETOPIA'],
  ['24202', '206', '6', 'Norway', 'CHESS COMMUNICATIONS'],
  ['24202', '206', '6', 'Norway', 'CHESS COMMUNICATIONS'],
  ['24202', '206', '6', 'Norway', 'COMBITEL NETWORKS'],
  ['24202', '206', '6', 'Norway', 'COMBITEL NETWORKS'],
  ['24202', '206', '6', 'Norway', 'COMBITEL NETWORKS'],
  ['24204', '206', '6', 'Norway', 'TeliaSonera (Netcom)'],
  ['24204', '206', '6', 'Norway', 'TeliaSonera (Netcom)'],
  ['24204', '206', '6', 'Norway', 'TeliaSonera (Netcom)'],
  ['24204', '206', '6', 'Norway', 'TeliaSonera (Netcom)'],
  ['24204', '206', '10', 'Norway', 'TeliaSonera (Netcom)'],
  ['24204', '206', '10', 'Norway', 'TeliaSonera (Netcom)'],
  ['24204', '206', '10', 'Norway', 'TeliaSonera (Netcom)'],
  ['24204', '206', '10', 'Norway', 'TeliaSonera (Netcom)'],
  ['24204', '206', '10', 'Norway', 'TeliaSonera (Netcom)'],
  ['24204', '206', '10', 'Norway', 'TeliaSonera (Netcom)'],
  ['24204', '206', '10', 'Norway', 'TeliaSonera (Netcom)'],
  ['24204', '206', '10', 'Norway', 'TeliaSonera (Netcom)'],
  ['24204', '206', '20', 'Norway', 'TeliaSonera (Netcom)'],
  ['24204', '206', '20', 'Norway', 'TeliaSonera (Netcom)'],
  ['24204', '206', '20', 'Norway', 'TeliaSonera (Netcom)'],
  ['24204', '206', '20', 'Norway', 'TeliaSonera (Netcom)'],
  ['24204', '206', '40', 'Norway', 'TeliaSonera (Netcom)'],
  ['24204', '206', '40', 'Norway', 'TeliaSonera (Netcom)'],
  ['24204', '702', '0', 'Norway', 'TeliaSonera (Netcom)'],
  ['24204', '702', '67', 'Norway', 'TeliaSonera (Netcom)'],
  ['24204', '702', '67', 'Norway', 'TeliaSonera (Netcom)'],
  ['24204', '616', '0', 'Norway', 'TeliaSonera (Netcom)'],
  ['24204', '616', '2', 'Norway', 'TeliaSonera (Netcom)'],
  ['24204', '616', '3', 'Norway', 'TeliaSonera (Netcom)'],
  ['24204', '310', '2', 'Norway', 'TeliaSonera (Netcom)'],
  ['24204', '310', '2', 'Norway', 'TeliaSonera (Netcom)'],
  ['24204', '402', '0', 'Norway', 'TeliaSonera (Netcom)'],
  ['24204', '402', '11', 'Norway', 'TeliaSonera (Netcom)'],
  ['24204', '736', '0', 'Norway', 'TeliaSonera (Netcom)'],
  ['24204', '736', '1', 'Norway', 'TeliaSonera (Netcom)'],
  ['24204', '736', '2', 'Norway', 'TeliaSonera (Netcom)'],
  ['24204', '736', '2', 'Norway', 'TeliaSonera (Netcom)'],
  ['24204', '736', '3', 'Norway', 'TeliaSonera (Netcom)'],
  ['24204', '736', '3', 'Norway', 'TeliaSonera (Netcom)'],
  ['24204', '736', '3', 'Norway', 'TeliaSonera (Netcom)'],
  ['24204', '218', '0', 'Norway', 'TeliaSonera (Netcom)'],
  ['24204', '218', '3', 'Norway', 'TeliaSonera (Netcom)'],
  ['24204', '218', '3', 'Norway', 'TeliaSonera (Netcom)'],
  ['24204', '218', '5', 'Norway', 'TeliaSonera (Netcom)'],
  ['24204', '218', '9', 'Norway', 'TeliaSonera (Netcom)'],
  ['24204', '218', '9', 'Norway', 'TeliaSonera (Netcom)'],
  ['24204', '218', '90', 'Norway', 'TeliaSonera (Netcom)'],
  ['24204', '652', '0', 'Norway', 'TeliaSonera (Netcom)'],
  ['24204', '652', '1', 'Norway', 'TeliaSonera (Netcom)'],
  ['24204', '652', '1', 'Norway', 'TeliaSonera (Netcom)'],
  ['24204', '652', '1', 'Norway', 'TeliaSonera (Netcom)'],
  ['24204', '652', '1', 'Norway', 'TeliaSonera (Netcom)'],
  ['24204', '652', '1', 'Norway', 'TeliaSonera (Netcom)'],
  ['24204', '652', '1', 'Norway', 'TeliaSonera (Netcom)'],
  ['24204', '652', '1', 'Norway', 'TeliaSonera (Netcom)'],
  ['24204', '652', '1', 'Norway', 'TeliaSonera (Netcom)'],
  ['24204', '652', '1', 'Norway', 'TeliaSonera (Netcom)'],
  ['24204', '652', '1', 'Norway', 'TeliaSonera (Netcom)'],
  ['24204', '652', '1', 'Norway', 'TeliaSonera (Netcom)'],
  ['24204', '652', '1', 'Norway', 'TeliaSonera (Netcom)'],
  ['24204', '652', '1', 'Norway', 'TeliaSonera (Netcom)'],
  ['24204', '652', '1', 'Norway', 'TeliaSonera (Netcom)'],
  ['24204', '652', '1', 'Norway', 'TeliaSonera (Netcom)'],
  ['24204', '652', '1', 'Norway', 'TeliaSonera (Netcom)'],
  ['24204', '652', '2', 'Norway', 'TeliaSonera (Netcom)'],
  ['24204', '652', '2', 'Norway', 'TeliaSonera (Netcom)'],
  ['24204', '652', '2', 'Norway', 'TeliaSonera (Netcom)'],
  ['24204', '652', '2', 'Norway', 'TeliaSonera (Netcom)'],
  ['24204', '652', '2', 'Norway', 'TeliaSonera (Netcom)'],
  ['24204', '652', '2', 'Norway', 'TeliaSonera (Netcom)'],
  ['24204', '652', '2', 'Norway', 'TeliaSonera (Netcom)'],
  ['24204', '652', '2', 'Norway', 'TeliaSonera (Netcom)'],
  ['24204', '652', '2', 'Norway', 'TeliaSonera (Netcom)'],
  ['24204', '652', '2', 'Norway', 'TeliaSonera (Netcom)'],
  ['24204', '652', '4', 'Norway', 'TeliaSonera (Netcom)'],
  ['24204', '724', '0', 'Norway', 'TeliaSonera (Netcom)'],
  ['24204', '724', '0', 'Norway', 'TeliaSonera (Netcom)'],
  ['24204', '724', '2', 'Norway', 'TeliaSonera (Netcom)'],
  ['24204', '724', '3', 'Norway', 'TeliaSonera (Netcom)'],
  ['24204', '724', '3', 'Norway', 'TeliaSonera (Netcom)'],
  ['24204', '724', '5', 'Norway', 'TeliaSonera (Netcom)'],
  ['24204', '724', '6', 'Norway', 'TeliaSonera (Netcom)'],
  ['24204', '724', '7', 'Norway', 'TeliaSonera (Netcom)'],
  ['24204', '724', '10', 'Norway', 'TeliaSonera (Netcom)'],
  ['24204', '724', '11', 'Norway', 'TeliaSonera (Netcom)'],
  ['24204', '724', '16', 'Norway', 'TeliaSonera (Netcom)'],
  ['24204', '724', '23', 'Norway', 'TeliaSonera (Netcom)'],
  ['24204', '724', '24', 'Norway', 'TeliaSonera (Netcom)'],
  ['24204', '724', '30', 'Norway', 'TeliaSonera (Netcom)'],
  ['24204', '724', '31', 'Norway', 'TeliaSonera (Netcom)'],
  ['24204', '724', '32', 'Norway', 'TeliaSonera (Netcom)'],
  ['24204', '724', '37', 'Norway', 'TeliaSonera (Netcom)'],
  ['24204', '528', '0', 'Norway', 'TeliaSonera (Netcom)'],
  ['24204', '528', '3', 'Norway', 'TeliaSonera (Netcom)'],
  ['24204', '528', '11', 'Norway', 'TeliaSonera (Netcom)'],
  ['24204', '284', '0', 'Norway', 'TeliaSonera (Netcom)'],
  ['24204', '284', '1', 'Norway', 'TeliaSonera (Netcom)'],
  ['24204', '284', '3', 'Norway', 'TeliaSonera (Netcom)'],
  ['24204', '284', '5', 'Norway', 'TeliaSonera (Netcom)'],
  ['24204', '613', '0', 'Norway', 'TeliaSonera (Netcom)'],
  ['24204', '613', '1', 'Norway', 'TeliaSonera (Netcom)'],
  ['24204', '613', '2', 'Norway', 'TeliaSonera (Netcom)'],
  ['24205', '613', '3', 'Norway', 'TeliaSonera (Netcom)'],
  ['24205', '642', '0', 'Norway', 'TeliaSonera (Netcom)'],
  ['24205', '642', '1', 'Norway', 'TeliaSonera (Netcom)'],
  ['24205', '642', '1', 'Norway', 'TeliaSonera (Netcom)'],
  ['24205', '642', '1', 'Norway', 'TeliaSonera (Netcom)'],
  ['24205', '642', '1', 'Norway', 'TeliaSonera (Netcom)'],
  ['24205', '642', '1', 'Norway', 'TeliaSonera (Netcom)'],
  ['24205', '642', '3', 'Norway', 'TeliaSonera (Netcom)'],
  ['24205', '642', '7', 'Norway', 'TeliaSonera (Netcom)'],
  ['24205', '642', '7', 'Norway', 'TeliaSonera (Netcom)'],
  ['24205', '642', '8', 'Norway', 'TeliaSonera (Netcom)'],
  ['24205', '642', '8', 'Norway', 'TeliaSonera (Netcom)'],
  ['24205', '642', '82', 'Norway', 'TeliaSonera (Netcom)'],
  ['24205', '642', '82', 'Norway', 'TeliaSonera (Netcom)'],
  ['24205', '642', '82', 'Norway', 'TeliaSonera (Netcom)'],
  ['24205', '642', '82', 'Norway', 'TeliaSonera (Netcom)'],
  ['24205', '642', '82', 'Norway', 'TeliaSonera (Netcom)'],
  ['24205', '456', '0', 'Norway', 'TeliaSonera (Netcom)'],
  ['24205', '456', '1', 'Norway', 'TeliaSonera (Netcom)'],
  ['24205', '456', '2', 'Norway', 'TeliaSonera (Netcom)'],
  ['24205', '456', '2', 'Norway', 'TeliaSonera (Netcom)'],
  ['24205', '456', '3', 'Norway', 'TeliaSonera (Netcom)'],
  ['24205', '456', '4', 'Norway', 'TeliaSonera (Netcom)'],
  ['24205', '456', '5', 'Norway', 'TeliaSonera (Netcom)'],
  ['24205', '456', '5', 'Norway', 'TeliaSonera (Netcom)'],
  ['24205', '456', '5', 'Norway', 'TeliaSonera (Netcom)'],
  ['24205', '456', '5', 'Norway', 'TeliaSonera (Netcom)'],
  ['24205', '456', '5', 'Norway', 'TeliaSonera (Netcom)'],
  ['24205', '456', '5', 'Norway', 'TeliaSonera (Netcom)'],
  ['24205', '456', '5', 'Norway', 'TeliaSonera (Netcom)'],
  ['24205', '456', '5', 'Norway', 'TeliaSonera (Netcom)'],
  ['24205', '456', '6', 'Norway', 'TeliaSonera (Netcom)'],
  ['24205', '456', '6', 'Norway', 'TeliaSonera (Netcom)'],
  ['24205', '456', '6', 'Norway', 'TeliaSonera (Netcom)'],
  ['24205', '456', '6', 'Norway', 'TeliaSonera (Netcom)'],
  ['24205', '456', '6', 'Norway', 'TeliaSonera (Netcom)'],
  ['24205', '456', '6', 'Norway', 'TeliaSonera (Netcom)'],
  ['24205', '456', '6', 'Norway', 'TeliaSonera (Netcom)'],
  ['24205', '456', '6', 'Norway', 'TeliaSonera (Netcom)'],
  ['24205', '456', '8', 'Norway', 'TeliaSonera (Netcom)'],
  ['24205', '456', '8', 'Norway', 'TeliaSonera (Netcom)'],
  ['24205', '456', '8', 'Norway', 'TeliaSonera (Netcom)'],
  ['24205', '456', '8', 'Norway', 'TeliaSonera (Netcom)'],
  ['24205', '456', '8', 'Norway', 'TeliaSonera (Netcom)'],
  ['24205', '456', '8', 'Norway', 'TeliaSonera (Netcom)'],
  ['24205', '456', '8', 'Norway', 'TeliaSonera (Netcom)'],
  ['24205', '456', '8', 'Norway', 'TeliaSonera (Netcom)'],
  ['24205', '456', '8', 'Norway', 'TeliaSonera (Netcom)'],
  ['24205', '456', '8', 'Norway', 'TeliaSonera (Netcom)'],
  ['24205', '456', '8', 'Norway', 'TeliaSonera (Netcom)'],
  ['24205', '456', '8', 'Norway', 'TeliaSonera (Netcom)'],
  ['24205', '456', '9', 'Norway', 'TeliaSonera (Netcom)'],
  ['24205', '456', '9', 'Norway', 'TeliaSonera (Netcom)'],
  ['24205', '456', '9', 'Norway', 'TeliaSonera (Netcom)'],
  ['24205', '456', '9', 'Norway', 'TeliaSonera (Netcom)'],
  ['24205', '456', '9', 'Norway', 'TeliaSonera (Netcom)'],
  ['24205', '456', '9', 'Norway', 'TeliaSonera (Netcom)'],
  ['24205', '456', '9', 'Norway', 'TeliaSonera (Netcom)'],
  ['24205', '456', '9', 'Norway', 'TeliaSonera (Netcom)'],
  ['24205', '456', '9', 'Norway', 'TeliaSonera (Netcom)'],
  ['24205', '456', '9', 'Norway', 'TeliaSonera (Netcom)'],
  ['24205', '456', '9', 'Norway', 'TeliaSonera (Netcom)'],
  ['24205', '456', '9', 'Norway', 'TeliaSonera (Netcom)'],
  ['24205', '456', '11', 'Norway', 'TeliaSonera (Netcom)'],
  ['24205', '456', '18', 'Norway', 'TeliaSonera (Netcom)'],
  ['24205', '456', '18', 'Norway', 'TeliaSonera (Netcom)'],
  ['24205', '456', '18', 'Norway', 'TeliaSonera (Netcom)'],
  ['24205', '456', '18', 'Norway', 'TeliaSonera (Netcom)'],
  ['24205', '456', '18', 'Norway', 'TeliaSonera (Netcom)'],
  ['24205', '456', '18', 'Norway', 'TeliaSonera (Netcom)'],
  ['24205', '456', '18', 'Norway', 'TeliaSonera (Netcom)'],
  ['24205', '456', '18', 'Norway', 'TeliaSonera (Netcom)'],
  ['24205', '624', '0', 'Norway', 'TeliaSonera (Netcom)'],
  ['24205', '624', '1', 'Norway', 'TeliaSonera (Netcom)'],
  ['24205', '624', '1', 'Norway', 'TeliaSonera (Netcom)'],
  ['24205', '624', '1', 'Norway', 'TeliaSonera (Netcom)'],
  ['24205', '624', '1', 'Norway', 'TeliaSonera (Netcom)'],
  ['24205', '624', '1', 'Norway', 'TeliaSonera (Netcom)'],
  ['24205', '624', '1', 'Norway', 'TeliaSonera (Netcom)'],
  ['24205', '624', '1', 'Norway', 'TeliaSonera (Netcom)'],
  ['24205', '624', '1', 'Norway', 'TeliaSonera (Netcom)'],
  ['24205', '624', '1', 'Norway', 'TeliaSonera (Netcom)'],
  ['24205', '624', '1', 'Norway', 'TeliaSonera (Netcom)'],
  ['24205', '624', '1', 'Norway', 'TeliaSonera (Netcom)'],
  ['24205', '624', '2', 'Norway', 'TeliaSonera (Netcom)'],
  ['24205', '624', '2', 'Norway', 'TeliaSonera (Netcom)'],
  ['24205', '624', '2', 'Norway', 'TeliaSonera (Netcom)'],
  ['24205', '624', '2', 'Norway', 'TeliaSonera (Netcom)'],
  ['24205', '624', '2', 'Norway', 'TeliaSonera (Netcom)'],
  ['24205', '624', '2', 'Norway', 'TeliaSonera (Netcom)'],
  ['24205', '624', '2', 'Norway', 'TeliaSonera (Netcom)'],
  ['24205', '624', '2', 'Norway', 'TeliaSonera (Netcom)'],
  ['24205', '624', '2', 'Norway', 'TeliaSonera (Netcom)'],
  ['24205', '624', '2', 'Norway', 'TeliaSonera (Netcom)'],
  ['24205', '624', '2', 'Norway', 'TeliaSonera (Netcom)'],
  ['24205', '624', '4', 'Norway', 'TeliaSonera (Netcom)'],
  ['24205', '624', '4', 'Norway', 'TeliaSonera (Netcom)'],
  ['24206', '302', '32', 'Norway', 'ICE'],
  ['24206', '302', '37', 'Norway', 'ICE'],
  ['24206', '302', '37', 'Norway', 'ICE'],
  ['24206', '302', '37', 'Norway', 'ICE'],
  ['24206', '302', '37', 'Norway', 'ICE'],
  ['24206', '302', '37', 'Norway', 'ICE'],
  ['24206', '302', '37', 'Norway', 'ICE'],
  ['24207', '302', '37', 'Norway', 'PHONERO'],
  ['24207', '302', '37', 'Norway', 'PHONERO'],
  ['24207', '302', '37', 'Norway', 'PHONERO'],
  ['24207', '302', '37', 'Norway', 'PHONERO'],
  ['24207', '302', '37', 'Norway', 'PHONERO'],
  ['24207', '302', '37', 'Norway', 'PHONERO'],
  ['24207', '302', '37', 'Norway', 'PHONERO'],
  ['24207', '302', '37', 'Norway', 'PHONERO'],
  ['24207', '302', '37', 'Norway', 'PHONERO'],
  ['24207', '302', '37', 'Norway', 'PHONERO'],
  ['24207', '302', '37', 'Norway', 'PHONERO'],
  ['24207', '302', '37', 'Norway', 'PHONERO'],
  ['24207', '302', '37', 'Norway', 'PHONERO'],
  ['24207', '302', '37', 'Norway', 'PHONERO'],
  ['24207', '302', '37', 'Norway', 'PHONERO'],
  ['24207', '302', '37', 'Norway', 'PHONERO'],
  ['24207', '302', '37', 'Norway', 'PHONERO'],
  ['24207', '302', '37', 'Norway', 'PHONERO'],
  ['24208', '302', '37', 'Norway', 'TDC Mobil'],
  ['24208', '302', '37', 'Norway', 'TDC Mobil'],
  ['24208', '302', '37', 'Norway', 'TDC Mobil'],
  ['24208', '302', '37', 'Norway', 'TDC Mobil'],
  ['24208', '302', '37', 'Norway', 'TDC Mobil'],
  ['24208', '302', '37', 'Norway', 'TDC Mobil'],
  ['24208', '302', '37', 'Norway', 'TDC Mobil'],
  ['24208', '302', '37', 'Norway', 'TDC Mobil'],
  ['24208', '302', '37', 'Norway', 'TDC Mobil'],
  ['24208', '302', '37', 'Norway', 'TDC Mobil'],
  ['24208', '302', '37', 'Norway', 'TDC Mobil'],
  ['24208', '302', '37', 'Norway', 'TDC Mobil'],
  ['24208', '302', '37', 'Norway', 'TDC Mobil'],
  ['24208', '302', '37', 'Norway', 'TDC Mobil'],
  ['24208', '302', '37', 'Norway', 'TDC Mobil'],
  ['24208', '302', '37', 'Norway', 'TDC Mobil'],
  ['24208', '302', '37', 'Norway', 'TDC Mobil'],
  ['24208', '302', '37', 'Norway', 'TDC Mobil'],
  ['24208', '302', '37', 'Norway', 'TDC Mobil'],
  ['24208', '302', '37', 'Norway', 'TDC Mobil'],
  ['24208', '302', '37', 'Norway', 'TDC Mobil'],
  ['24208', '302', '37', 'Norway', 'TDC Mobil'],
  ['24208', '302', '37', 'Norway', 'TDC Mobil'],
  ['24208', '302', '37', 'Norway', 'TDC Mobil'],
  ['24208', '302', '37', 'Norway', 'TDC Mobil'],
  ['24208', '302', '65', 'Norway', 'TDC Mobil'],
  ['24208', '302', '72', 'Norway', 'TDC Mobil'],
  ['24208', '302', '72', 'Norway', 'TDC Mobil'],
  ['24208', '302', '72', 'Norway', 'TDC Mobil'],
  ['24208', '302', '72', 'Norway', 'TDC Mobil'],
  ['24208', '302', '72', 'Norway', 'TDC Mobil'],
  ['24208', '302', '72', 'Norway', 'TDC Mobil'],
  ['24208', '302', '72', 'Norway', 'TDC Mobil'],
  ['24208', '302', '72', 'Norway', 'TDC Mobil'],
  ['24208', '302', '72', 'Norway', 'TDC Mobil'],
  ['24208', '302', '72', 'Norway', 'TDC Mobil'],
  ['24208', '302', '72', 'Norway', 'TDC Mobil'],
  ['24208', '302', '72', 'Norway', 'TDC Mobil'],
  ['24208', '302', '72', 'Norway', 'TDC Mobil'],
  ['24208', '302', '72', 'Norway', 'TDC Mobil'],
  ['24208', '302', '72', 'Norway', 'TDC Mobil'],
  ['24208', '302', '72', 'Norway', 'TDC Mobil'],
  ['24208', '302', '72', 'Norway', 'TDC Mobil'],
  ['24208', '302', '72', 'Norway', 'TDC Mobil'],
  ['24208', '302', '72', 'Norway', 'TDC Mobil'],
  ['24208', '302', '72', 'Norway', 'TDC Mobil'],
  ['24208', '302', '72', 'Norway', 'TDC Mobil'],
  ['24208', '302', '72', 'Norway', 'TDC Mobil'],
  ['24208', '302', '370', 'Norway', 'TDC Mobil'],
  ['24208', '302', '490', 'Norway', 'TDC Mobil'],
  ['24208', '625', '0', 'Norway', 'TDC Mobil'],
  ['24208', '625', '1', 'Norway', 'TDC Mobil'],
  ['24208', '625', '1', 'Norway', 'TDC Mobil'],
  ['24208', '625', '1', 'Norway', 'TDC Mobil'],
  ['24208', '625', '1', 'Norway', 'TDC Mobil'],
  ['24208', '310', '2', 'Norway', 'TDC Mobil'],
  ['24208', '310', '2', 'Norway', 'TDC Mobil'],
  ['24208', '310', '2', 'Norway', 'TDC Mobil'],
  ['24208', '310', '2', 'Norway', 'TDC Mobil'],
  ['24208', '623', '0', 'Norway', 'TDC Mobil'],
  ['24208', '623', '10', 'Norway', 'TDC Mobil'],
  ['24208', '623', '1', 'Norway', 'TDC Mobil'],
  ['24208', '623', '2', 'Norway', 'TDC Mobil'],
  ['24208', '623', '3', 'Norway', 'TDC Mobil'],
  ['24208', '623', '4', 'Norway', 'TDC Mobil'],
  ['24208', '622', '0', 'Norway', 'TDC Mobil'],
  ['24208', '622', '1', 'Norway', 'TDC Mobil'],
  ['24208', '622', '1', 'Norway', 'TDC Mobil'],
  ['24208', '622', '1', 'Norway', 'TDC Mobil'],
  ['24208', '622', '2', 'Norway', 'TDC Mobil'],
  ['24208', '622', '3', 'Norway', 'TDC Mobil'],
  ['24208', '730', '0', 'Norway', 'TDC Mobil'],
  ['24208', '730', '10', 'Norway', 'TDC Mobil'],
  ['24208', '730', '10', 'Norway', 'TDC Mobil'],
  ['24208', '730', '10', 'Norway', 'TDC Mobil'],
  ['24208', '730', '10', 'Norway', 'TDC Mobil'],
  ['24208', '730', '10', 'Norway', 'TDC Mobil'],
  ['24208', '730', '10', 'Norway', 'TDC Mobil'],
  ['24208', '730', '10', 'Norway', 'TDC Mobil'],
  ['24208', '730', '10', 'Norway', 'TDC Mobil'],
  ['24208', '730', '10', 'Norway', 'TDC Mobil'],
  ['24208', '730', '10', 'Norway', 'TDC Mobil'],
  ['24208', '730', '10', 'Norway', 'TDC Mobil'],
  ['24208', '730', '10', 'Norway', 'TDC Mobil'],
  ['24208', '730', '10', 'Norway', 'TDC Mobil'],
  ['24208', '730', '10', 'Norway', 'TDC Mobil'],
  ['24208', '730', '10', 'Norway', 'TDC Mobil'],
  ['24208', '730', '10', 'Norway', 'TDC Mobil'],
  ['24208', '730', '10', 'Norway', 'TDC Mobil'],
  ['24208', '730', '10', 'Norway', 'TDC Mobil'],
  ['24208', '730', '10', 'Norway', 'TDC Mobil'],
  ['24208', '730', '10', 'Norway', 'TDC Mobil'],
  ['24208', '730', '10', 'Norway', 'TDC Mobil'],
  ['24208', '730', '10', 'Norway', 'TDC Mobil'],
  ['24208', '730', '10', 'Norway', 'TDC Mobil'],
  ['24208', '730', '10', 'Norway', 'TDC Mobil'],
  ['24208', '730', '10', 'Norway', 'TDC Mobil'],
  ['24209', '730', '10', 'Norway', 'com4'],
  ['24214', '730', '10', 'Norway', 'ICE'],
  ['24214', '730', '10', 'Norway', 'ICE'],
  ['24214', '730', '10', 'Norway', 'ICE'],
  ['24214', '730', '10', 'Norway', 'ICE'],
  ['24214', '730', '10', 'Norway', 'ICE'],
  ['24214', '730', '10', 'Norway', 'ICE'],
  ['24214', '730', '10', 'Norway', 'ICE'],
  ['24223', '730', '10', 'Norway', 'Lycamobile'],
  ['24223', '730', '10', 'Norway', 'Lycamobile'],
  ['24223', '730', '10', 'Norway', 'Lycamobile'],
  ['24223', '730', '10', 'Norway', 'Lycamobile'],
  ['24223', '730', '10', 'Norway', 'Lycamobile'],
  ['24223', '730', '10', 'Norway', 'Lycamobile'],
  ['24223', '730', '10', 'Norway', 'Lycamobile'],
  ['24223', '730', '10', 'Norway', 'Lycamobile'],
  ['24223', '730', '10', 'Norway', 'Lycamobile'],
  ['24223', '730', '10', 'Norway', 'Lycamobile'],
  ['24223', '730', '10', 'Norway', 'Lycamobile'],
  ['24223', '730', '10', 'Norway', 'Lycamobile'],
  ['24223', '730', '10', 'Norway', 'Lycamobile'],
  ['24223', '730', '10', 'Norway', 'Lycamobile'],
  ['4220', '730', '10', 'Oman', 'DEFAULT OMAN'],
  ['42202', '730', '10', 'Oman', 'OMANTEL'],
  ['42202', '730', '10', 'Oman', 'OMANTEL'],
  ['42202', '730', '10', 'Oman', 'OMANTEL'],
  ['42203', '730', '10', 'Oman', 'NAWRAS'],
  ['42203', '730', '10', 'Oman', 'NAWRAS'],
  ['4100', '730', '10', 'Pakistan', 'DEFAULT PAKISTAN'],
  ['41001', '730', '10', 'Pakistan', 'PMC MOBILINK'],
  ['41001', '730', '10', 'Pakistan', 'PMC MOBILINK'],
  ['41001', '730', '10', 'Pakistan', 'PMC MOBILINK'],
  ['41001', '730', '10', 'Pakistan', 'PMC MOBILINK'],
  ['41001', '730', '10', 'Pakistan', 'PMC MOBILINK'],
  ['41001', '730', '10', 'Pakistan', 'PMC MOBILINK'],
  ['41001', '730', '10', 'Pakistan', 'PMC MOBILINK'],
  ['41001', '730', '10', 'Pakistan', 'PMC MOBILINK'],
  ['41001', '730', '10', 'Pakistan', 'PMC MOBILINK'],
  ['41003', '730', '10', 'Pakistan', 'PAK UFONE'],
  ['41003', '730', '10', 'Pakistan', 'PAK UFONE'],
  ['41003', '730', '10', 'Pakistan', 'PAK UFONE'],
  ['41003', '730', '10', 'Pakistan', 'PAK UFONE'],
  ['41003', '730', '10', 'Pakistan', 'PAK UFONE'],
  ['41003', '730', '10', 'Pakistan', 'PAK UFONE'],
  ['41004', '730', '10', 'Pakistan', 'ZONG'],
  ['41004', '730', '10', 'Pakistan', 'PAKTEL LTD'],
  ['41004', '730', '10', 'Pakistan', 'ZONG'],
  ['41004', '730', '10', 'Pakistan', 'ZONG'],
  ['41004', '730', '10', 'Pakistan', 'ZONG'],
  ['41004', '730', '10', 'Pakistan', 'ZONG'],
  ['41006', '730', '10', 'Pakistan', 'TELENOR PAKISTAN'],
  ['41006', '730', '10', 'Pakistan', 'TELENOR PAKISTAN'],
  ['41006', '730', '10', 'Pakistan', 'TELENOR PAKISTAN'],
  ['41006', '730', '10', 'Pakistan', 'TELENOR PAKISTAN'],
  ['41006', '730', '10', 'Pakistan', 'TELENOR PAKISTAN'],
  ['41006', '730', '10', 'Pakistan', 'TELENOR PAKISTAN'],
  ['41006', '730', '10', 'Pakistan', 'TELENOR PAKISTAN'],
  ['41007', '730', '10', 'Pakistan', 'WARID TELECOM'],
  ['41007', '730', '10', 'Pakistan', 'WARID TELECOM'],
  ['41007', '730', '10', 'Pakistan', 'WARID TELECOM'],
  ['41007', '730', '10', 'Pakistan', 'WARID TELECOM'],
  ['41007', '730', '10', 'Pakistan', 'WARID TELECOM'],
  ['41007', '730', '10', 'Pakistan', 'WARID TELECOM'],
  ['42505', '460', '2', 'Palestine', 'PALTEL'],
  ['7140', '460', '2', 'Panama', 'DEFAULT PANAMA'],
  ['71401', '460', '2', 'Panama', 'CABLE & WIRELESS'],
  ['71403', '460', '2', 'Panama', 'Claro'],
  ['71403', '460', '2', 'Panama', 'Claro'],
  ['71403', '460', '2', 'Panama', 'Claro'],
  ['71403', '460', '2', 'Panama', 'Claro'],
  ['71403', '460', '2', 'Panama', 'Claro'],
  ['71404', '460', '2', 'Panama', 'Digicel'],
  ['71404', '460', '2', 'Panama', 'Digicel'],
  ['7440', '460', '2', 'Paraguay', 'DEFAULT PARAGUAY'],
  ['74401', '460', '2', 'Paraguay', 'HOLA PARAGUAY'],
  ['74402', '460', '2', 'Paraguay', 'HUTCHINSON TEL'],
  ['74404', '460', '2', 'Paraguay', 'Tigo'],
  ['74405', '460', '2', 'Paraguay', 'PERSONAL'],
  ['7160', '460', '2', 'Peru', 'DEFAULT PERU'],
  ['71606', '460', '2', 'Peru', 'Unknown-PERU-9494'],
  ['71607', '460', '2', 'Peru', 'Entel'],
  ['71607', '460', '2', 'Peru', 'Entel'],
  ['71607', '460', '2', 'Peru', 'Entel'],
  ['71607', '460', '2', 'Peru', 'Entel'],
  ['71607', '460', '2', 'Peru', 'Entel'],
  ['71607', '460', '2', 'Peru', 'Entel'],
  ['71607', '460', '3', 'Peru', 'Entel'],
  ['71607', '460', '3', 'Peru', 'Entel'],
  ['71607', '460', '3', 'Peru', 'Entel'],
  ['71607', '460', '3', 'Peru', 'Entel'],
  ['71607', '460', '3', 'Peru', 'Entel'],
  ['71607', '460', '3', 'Peru', 'Entel'],
  ['71607', '460', '3', 'Peru', 'Entel'],
  ['71607', '460', '3', 'Peru', 'Entel'],
  ['71607', '460', '3', 'Peru', 'Entel'],
  ['71607', '460', '3', 'Peru', 'Entel'],
  ['71607', '460', '3', 'Peru', 'Entel'],
  ['71607', '460', '3', 'Peru', 'Entel'],
  ['71607', '460', '3', 'Peru', 'Entel'],
  ['71607', '460', '3', 'Peru', 'Entel'],
  ['71607', '460', '3', 'Peru', 'Entel'],
  ['71607', '460', '5', 'Peru', 'Entel'],
  ['71607', '460', '5', 'Peru', 'Entel'],
  ['71607', '460', '5', 'Peru', 'Entel'],
  ['71607', '460', '5', 'Peru', 'Entel'],
  ['71607', '460', '5', 'Peru', 'Entel'],
  ['71607', '460', '5', 'Peru', 'Entel'],
  ['71607', '460', '5', 'Peru', 'Entel'],
  ['71607', '460', '5', 'Peru', 'Entel'],
  ['71607', '460', '5', 'Peru', 'Entel'],
  ['71607', '460', '5', 'Peru', 'Entel'],
  ['71607', '460', '5', 'Peru', 'Entel'],
  ['71607', '460', '5', 'Peru', 'Entel'],
  ['71607', '460', '5', 'Peru', 'Entel'],
  ['71607', '460', '5', 'Peru', 'Entel'],
  ['71607', '460', '5', 'Peru', 'Entel'],
  ['71607', '460', '6', 'Peru', 'Entel'],
  ['71607', '460', '6', 'Peru', 'Entel'],
  ['71607', '460', '6', 'Peru', 'Entel'],
  ['71607', '460', '6', 'Peru', 'Entel'],
  ['71607', '460', '6', 'Peru', 'Entel'],
  ['71607', '460', '6', 'Peru', 'Entel'],
  ['71607', '460', '6', 'Peru', 'Entel'],
  ['71607', '460', '6', 'Peru', 'Entel'],
  ['71607', '460', '6', 'Peru', 'Entel'],
  ['71607', '460', '6', 'Peru', 'Entel'],
  ['71607', '460', '6', 'Peru', 'Entel'],
  ['71607', '460', '6', 'Peru', 'Entel'],
  ['71607', '460', '6', 'Peru', 'Entel'],
  ['71607', '460', '6', 'Peru', 'Entel'],
  ['71607', '460', '6', 'Peru', 'Entel'],
  ['71607', '460', '7', 'Peru', 'Entel'],
  ['71607', '460', '7', 'Peru', 'Entel'],
  ['71607', '460', '7', 'Peru', 'Entel'],
  ['71607', '460', '7', 'Peru', 'Entel'],
  ['71607', '460', '7', 'Peru', 'Entel'],
  ['71607', '460', '7', 'Peru', 'Entel'],
  ['71607', '460', '7', 'Peru', 'Entel'],
  ['71610', '460', '7', 'Peru', 'TIM PERU'],
  ['71610', '460', '7', 'Peru', 'TIM PERU'],
  ['71610', '460', '7', 'Peru', 'TIM PERU'],
  ['71610', '460', '7', 'Peru', 'TIM PERU'],
  ['71610', '460', '7', 'Peru', 'TIM PERU'],
  ['71610', '460', '7', 'Peru', 'TIM PERU'],
  ['71610', '460', '7', 'Peru', 'TIM PERU'],
  ['71610', '460', '7', 'Peru', 'TIM PERU'],
  ['71610', '460', '7', 'Peru', 'TIM PERU'],
  ['71610', '460', '7', 'Peru', 'TIM PERU'],
  ['71610', '460', '7', 'Peru', 'TIM PERU'],
  ['71610', '460', '7', 'Peru', 'TIM PERU'],
  ['71610', '460', '7', 'Peru', 'TIM PERU'],
  ['71610', '460', '7', 'Peru', 'TIM PERU'],
  ['71610', '460', '7', 'Peru', 'TIM PERU'],
  ['71610', '460', '7', 'Peru', 'TIM PERU'],
  ['71610', '460', '11', 'Peru', 'TIM PERU'],
  ['71610', '460', '11', 'Peru', 'TIM PERU'],
  ['71610', '460', '11', 'Peru', 'TIM PERU'],
  ['71610', '460', '11', 'Peru', 'TIM PERU'],
  ['71610', '460', '11', 'Peru', 'TIM PERU'],
  ['71610', '460', '11', 'Peru', 'TIM PERU'],
  ['71610', '460', '11', 'Peru', 'TIM PERU'],
  ['71610', '460', '11', 'Peru', 'TIM PERU'],
  ['71610', '460', '11', 'Peru', 'TIM PERU'],
  ['71610', '460', '11', 'Peru', 'TIM PERU'],
  ['71610', '460', '11', 'Peru', 'TIM PERU'],
  ['71610', '460', '11', 'Peru', 'TIM PERU'],
  ['71610', '460', '11', 'Peru', 'TIM PERU'],
  ['71610', '460', '11', 'Peru', 'TIM PERU'],
  ['71610', '460', '11', 'Peru', 'TIM PERU'],
  ['71610', '460', '0', 'Peru', 'TIM PERU'],
  ['71610', '460', '0', 'Peru', 'TIM PERU'],
  ['71610', '460', '0', 'Peru', 'TIM PERU'],
  ['71610', '460', '0', 'Peru', 'TIM PERU'],
  ['71610', '460', '0', 'Peru', 'TIM PERU'],
  ['71610', '460', '0', 'Peru', 'TIM PERU'],
  ['71610', '460', '1', 'Peru', 'TIM PERU'],
  ['71610', '460', '1', 'Peru', 'TIM PERU'],
  ['71610', '460', '1', 'Peru', 'TIM PERU'],
  ['71610', '460', '1', 'Peru', 'TIM PERU'],
  ['71610', '732', '0', 'Peru', 'TIM PERU'],
  ['71610', '732', '101', 'Peru', 'TIM PERU'],
  ['71610', '732', '101', 'Peru', 'TIM PERU'],
  ['71610', '732', '101', 'Peru', 'TIM PERU'],
  ['71610', '732', '101', 'Peru', 'TIM PERU'],
  ['71610', '732', '102', 'Peru', 'TIM PERU'],
  ['71610', '732', '103', 'Peru', 'TIM PERU'],
  ['71610', '732', '111', 'Peru', 'Default'],
  ['71610', '732', '111', 'Peru', 'TIM PERU'],
  ['71610', '732', '111', 'Peru', 'TIM PERU'],
  ['71610', '732', '111', 'Peru', 'TIM PERU'],
  ['71610', '732', '111', 'Peru', 'TIM PERU'],
  ['71610', '732', '111', 'Peru', 'TIM PERU'],
  ['71610', '732', '111', 'Peru', 'TIM PERU'],
  ['71610', '732', '111', 'Peru', 'TIM PERU'],
  ['71610', '732', '111', 'Peru', 'TIM PERU'],
  ['71610', '732', '111', 'Peru', 'TIM PERU'],
  ['71610', '732', '111', 'Peru', 'TIM PERU'],
  ['71610', '732', '111', 'Peru', 'TIM PERU'],
  ['71610', '732', '111', 'Peru', 'TIM PERU'],
  ['71610', '732', '111', 'Peru', 'TIM PERU'],
  ['71610', '732', '111', 'Peru', 'TIM PERU'],
  ['71610', '732', '111', 'Peru', 'TIM PERU'],
  ['71610', '732', '111', 'Peru', 'TIM PERU'],
  ['71610', '732', '111', 'Peru', 'TIM PERU'],
  ['71610', '732', '111', 'Peru', 'TIM PERU'],
  ['71610', '732', '111', 'Peru', 'TIM PERU'],
  ['71610', '732', '111', 'Peru', 'TIM PERU'],
  ['71610', '732', '111', 'Peru', 'TIM PERU'],
  ['71610', '732', '111', 'Peru', 'TIM PERU'],
  ['71610', '732', '111', 'Peru', 'TIM PERU'],
  ['71610', '732', '111', 'Peru', 'TIM PERU'],
  ['71610', '732', '111', 'Peru', 'TIM PERU'],
  ['71617', '732', '111', 'Peru', 'Entel'],
  ['71617', '732', '111', 'Peru', 'Entel'],
  ['71617', '732', '111', 'Peru', 'Entel'],
  ['71617', '732', '111', 'Peru', 'Entel'],
  ['71617', '732', '111', 'Peru', 'Entel'],
  ['71617', '732', '111', 'Peru', 'Entel'],
  ['71617', '732', '111', 'Peru', 'Entel'],
  ['71617', '732', '111', 'Peru', 'Entel'],
  ['71617', '732', '111', 'Peru', 'Entel'],
  ['71617', '732', '111', 'Peru', 'Entel'],
  ['71617', '732', '111', 'Peru', 'Entel'],
  ['71617', '732', '111', 'Peru', 'Entel'],
  ['71617', '732', '111', 'Peru', 'Entel'],
  ['71617', '732', '111', 'Peru', 'Entel'],
  ['71617', '732', '111', 'Peru', 'Entel'],
  ['71617', '732', '111', 'Peru', 'Entel'],
  ['71617', '732', '111', 'Peru', 'Entel'],
  ['71617', '732', '111', 'Peru', 'Entel'],
  ['71617', '732', '111', 'Peru', 'Entel'],
  ['71617', '732', '111', 'Peru', 'Entel'],
  ['71617', '732', '111', 'Peru', 'Entel'],
  ['71617', '732', '111', 'Peru', 'Entel'],
  ['71617', '732', '111', 'Peru', 'Entel'],
  ['71617', '732', '111', 'Peru', 'Entel'],
  ['71617', '732', '111', 'Peru', 'Entel'],
  ['71617', '732', '111', 'Peru', 'Entel'],
  ['71617', '732', '111', 'Peru', 'Entel'],
  ['71617', '732', '111', 'Peru', 'Entel'],
  ['71617', '732', '111', 'Peru', 'Entel'],
  ['71617', '732', '111', 'Peru', 'Entel'],
  ['71617', '732', '111', 'Peru', 'Entel'],
  ['71617', '732', '111', 'Peru', 'Entel'],
  ['71617', '732', '111', 'Peru', 'Entel'],
  ['71617', '732', '111', 'Peru', 'Entel'],
  ['71617', '732', '111', 'Peru', 'Entel'],
  ['71617', '732', '111', 'Peru', 'Entel'],
  ['71617', '732', '111', 'Peru', 'Entel'],
  ['71617', '732', '111', 'Peru', 'Entel'],
  ['71617', '732', '111', 'Peru', 'Entel'],
  ['71617', '732', '111', 'Peru', 'Entel'],
  ['71617', '732', '111', 'Peru', 'Entel'],
  ['71617', '732', '111', 'Peru', 'Entel'],
  ['71617', '732', '111', 'Peru', 'Entel'],
  ['71617', '732', '111', 'Peru', 'Entel'],
  ['71617', '732', '111', 'Peru', 'Entel'],
  ['71617', '732', '111', 'Peru', 'Entel'],
  ['71617', '732', '111', 'Peru', 'Entel'],
  ['71617', '732', '111', 'Peru', 'Entel'],
  ['71617', '732', '111', 'Peru', 'Entel'],
  ['71617', '732', '111', 'Peru', 'Entel'],
  ['71617', '732', '111', 'Peru', 'Entel'],
  ['71617', '732', '111', 'Peru', 'Entel'],
  ['71617', '732', '111', 'Peru', 'Entel'],
  ['71617', '732', '111', 'Peru', 'Entel'],
  ['71617', '732', '111', 'Peru', 'Entel'],
  ['71617', '732', '111', 'Peru', 'Entel'],
  ['71617', '732', '111', 'Peru', 'Entel'],
  ['71617', '732', '111', 'Peru', 'Entel'],
  ['5150', '732', '111', 'Philippines', 'DEFAULT PHILIPPINES'],
  ['51501', '732', '111', 'Philippines', 'ISLACOM (INNOVE)'],
  ['51502', '732', '111', 'Philippines', 'GLOBE'],
  ['51502', '732', '111', 'Philippines', 'GLOBE'],
  ['51502', '732', '111', 'Philippines', 'GLOBE'],
  ['51502', '732', '111', 'Philippines', 'GLOBE'],
  ['51502', '732', '111', 'Philippines', 'GLOBE'],
  ['51502', '732', '111', 'Philippines', 'GLOBE'],
  ['51503', '732', '111', 'Philippines', 'SMART'],
  ['51503', '732', '111', 'Philippines', 'SMART'],
  ['51503', '732', '111', 'Philippines', 'SMART'],
  ['51503', '732', '111', 'Philippines', 'SMART'],
  ['51503', '732', '111', 'Philippines', 'SMART'],
  ['51503', '732', '111', 'Philippines', 'SMART'],
  ['51503', '732', '111', 'Philippines', 'SMART'],
  ['51505', '732', '111', 'Philippines', 'DIGITEL'],
  ['51505', '732', '111', 'Philippines', 'DIGITEL'],
  ['51505', '732', '111', 'Philippines', 'Unknown-PHILIPPINES-9327'],
  ['51518', '732', '111', 'Philippines', 'Smart Communications'],
  ['51518', '732', '111', 'Philippines', 'Smart Communications'],
  ['51518', '732', '111', 'Philippines', 'Smart Communications'],
  ['51518', '732', '111', 'Philippines', 'Smart Communications'],
  ['51518', '732', '111', 'Philippines', 'Smart Communications'],
  ['51518', '732', '111', 'Philippines', 'Smart Communications'],
  ['51518', '732', '111', 'Philippines', 'Smart Communications'],
  ['51518', '732', '111', 'Philippines', 'Smart Communications'],
  ['51518', '732', '111', 'Philippines', 'Smart Communications'],
  ['51518', '732', '111', 'Philippines', 'Smart Communications'],
  ['51518', '732', '111', 'Philippines', 'Smart Communications'],
  ['51518', '732', '111', 'Philippines', 'Smart Communications'],
  ['51518', '732', '111', 'Philippines', 'Smart Communications'],
  ['51518', '732', '111', 'Philippines', 'Smart Communications'],
  ['51518', '732', '111', 'Philippines', 'Smart Communications'],
  ['51518', '732', '111', 'Philippines', 'Smart Communications'],
  ['51518', '732', '111', 'Philippines', 'Smart Communications'],
  ['51518', '732', '111', 'Philippines', 'Smart Communications'],
  ['51518', '732', '111', 'Philippines', 'Smart Communications'],
  ['51518', '732', '111', 'Philippines', 'Smart Communications'],
  ['51518', '732', '111', 'Philippines', 'Smart Communications'],
  ['51518', '732', '111', 'Philippines', 'Smart Communications'],
  ['51518', '732', '111', 'Philippines', 'Smart Communications'],
  ['51518', '732', '111', 'Philippines', 'Smart Communications'],
  ['51518', '732', '111', 'Philippines', 'Smart Communications'],
  ['2600', '732', '111', 'Poland', 'DEFAULT POLAND'],
  ['26001', '732', '111', 'Poland', 'POLKOMTEL'],
  ['26001', '732', '111', 'Poland', 'POLKOMTEL'],
  ['26001', '732', '123', 'Poland', 'POLKOMTEL'],
  ['26001', '732', '130', 'Poland', 'POLKOMTEL'],
  ['26001', '732', '130', 'Poland', 'POLKOMTEL'],
  ['26001', '732', '130', 'Poland', 'POLKOMTEL'],
  ['26001', '732', '130', 'Poland', 'POLKOMTEL'],
  ['26001', '732', '130', 'Poland', 'POLKOMTEL'],
  ['26001', '732', '130', 'Poland', 'POLKOMTEL'],
  ['26001', '732', '130', 'Poland', 'POLKOMTEL'],
  ['26001', '732', '130', 'Poland', 'POLKOMTEL'],
  ['26001', '732', '154', 'Poland', 'POLKOMTEL'],
  ['26001', '732', '154', 'Poland', 'POLKOMTEL'],
  ['26001', '732', '154', 'Poland', 'POLKOMTEL'],
  ['26001', '732', '154', 'Poland', 'POLKOMTEL'],
  ['26001', '732', '154', 'Poland', 'POLKOMTEL'],
  ['26002', '732', '154', 'Poland', 'ERA GSM'],
  ['26002', '732', '154', 'Poland', 'ERA GSM'],
  ['26002', '732', '154', 'Poland', 'ERA GSM'],
  ['26002', '732', '154', 'Poland', 'ERA GSM'],
  ['26002', '732', '154', 'Poland', 'ERA GSM'],
  ['26002', '732', '154', 'Poland', 'ERA GSM'],
  ['26002', '732', '154', 'Poland', 'ERA GSM'],
  ['26002', '732', '154', 'Poland', 'ERA GSM'],
  ['26002', '732', '154', 'Poland', 'ERA GSM'],
  ['26002', '732', '154', 'Poland', 'ERA GSM'],
  ['26002', '732', '154', 'Poland', 'ERA GSM'],
  ['26002', '732', '154', 'Poland', 'ERA GSM'],
  ['26002', '732', '154', 'Poland', 'ERA GSM'],
  ['26002', '732', '154', 'Poland', 'ERA GSM'],
  ['26002', '732', '154', 'Poland', 'ERA GSM'],
  ['26002', '732', '165', 'Poland', 'ERA GSM'],
  ['26002', '732', '165', 'Poland', 'ERA GSM'],
  ['26002', '732', '165', 'Poland', 'ERA GSM'],
  ['26002', '732', '165', 'Poland', 'ERA GSM'],
  ['26002', '732', '165', 'Poland', 'ERA GSM'],
  ['26003', '732', '165', 'Poland', 'CENTERTEL'],
  ['26003', '732', '165', 'Poland', 'CENTERTEL'],
  ['26003', '732', '165', 'Poland', 'CENTERTEL'],
  ['26003', '732', '165', 'Poland', 'CENTERTEL'],
  ['26003', '732', '165', 'Poland', 'CENTERTEL'],
  ['26003', '732', '165', 'Poland', 'CENTERTEL'],
  ['26003', '732', '165', 'Poland', 'CENTERTEL'],
  ['26003', '732', '165', 'Poland', 'CENTERTEL'],
  ['26003', '732', '165', 'Poland', 'CENTERTEL'],
  ['26003', '732', '165', 'Poland', 'CENTERTEL'],
  ['26003', '732', '165', 'Poland', 'CENTERTEL'],
  ['26003', '732', '165', 'Poland', 'CENTERTEL'],
  ['26003', '732', '165', 'Poland', 'CENTERTEL'],
  ['26003', '732', '165', 'Poland', 'CENTERTEL'],
  ['26006', '732', '165', 'Poland', 'Play (P4)'],
  ['26006', '732', '165', 'Poland', 'Play (P4)'],
  ['26006', '732', '165', 'Poland', 'Play (P4)'],
  ['26006', '732', '165', 'Poland', 'Play (P4)'],
  ['26006', '732', '165', 'Poland', 'Play (P4)'],
  ['26006', '732', '165', 'Poland', 'Play (P4)'],
  ['26006', '732', '165', 'Poland', 'Play (P4)'],
  ['26006', '732', '165', 'Poland', 'Play (P4)'],
  ['26006', '732', '165', 'Poland', 'Play (P4)'],
  ['26006', '732', '165', 'Poland', 'Play (P4)'],
  ['26006', '732', '165', 'Poland', 'Play (P4)'],
  ['26006', '732', '165', 'Poland', 'Play (P4)'],
  ['26006', '732', '165', 'Poland', 'Play (P4)'],
  ['26006', '732', '165', 'Poland', 'Play (P4)'],
  ['26006', '732', '165', 'Poland', 'Play (P4)'],
  ['26006', '732', '165', 'Poland', 'Play (P4)'],
  ['26006', '732', '165', 'Poland', 'Play (P4)'],
  ['26006', '732', '165', 'Poland', 'Play (P4)'],
  ['26006', '732', '165', 'Poland', 'Play (P4)'],
  ['26006', '732', '165', 'Poland', 'Play (P4)'],
  ['26006', '732', '165', 'Poland', 'Play (P4)'],
  ['26006', '732', '165', 'Poland', 'Play (P4)'],
  ['26006', '732', '165', 'Poland', 'Play (P4)'],
  ['26006', '732', '165', 'Poland', 'Play (P4)'],
  ['26006', '732', '165', 'Poland', 'Play (P4)'],
  ['26006', '732', '165', 'Poland', 'Play (P4)'],
  ['26006', '732', '165', 'Poland', 'Play (P4)'],
  ['26006', '732', '165', 'Poland', 'Play (P4)'],
  ['26006', '732', '165', 'Poland', 'Play (P4)'],
  ['26006', '732', '165', 'Poland', 'Play (P4)'],
  ['26006', '732', '165', 'Poland', 'Play (P4)'],
  ['26006', '732', '165', 'Poland', 'Play (P4)'],
  ['26006', '732', '165', 'Poland', 'Play (P4)'],
  ['26006', '732', '165', 'Poland', 'Play (P4)'],
  ['26006', '732', '165', 'Poland', 'Play (P4)'],
  ['26006', '732', '165', 'Poland', 'Play (P4)'],
  ['26006', '732', '165', 'Poland', 'Play (P4)'],
  ['26006', '732', '165', 'Poland', 'Play (P4)'],
  ['26006', '732', '165', 'Poland', 'Play (P4)'],
  ['26006', '732', '165', 'Poland', 'Play (P4)'],
  ['26006', '732', '165', 'Poland', 'Play (P4)'],
  ['26006', '732', '165', 'Poland', 'Play (P4)'],
  ['26006', '732', '165', 'Poland', 'Play (P4)'],
  ['26006', '732', '165', 'Poland', 'Play (P4)'],
  ['26006', '732', '165', 'Poland', 'Play (P4)'],
  ['26006', '732', '165', 'Poland', 'Play (P4)'],
  ['26006', '732', '165', 'Poland', 'Play (P4)'],
  ['26006', '732', '165', 'Poland', 'Play (P4)'],
  ['26006', '732', '165', 'Poland', 'Play (P4)'],
  ['26006', '732', '165', 'Poland', 'Play (P4)'],
  ['26006', '732', '165', 'Poland', 'Play (P4)'],
  ['26006', '732', '165', 'Poland', 'Play (P4)'],
  ['26006', '732', '165', 'Poland', 'Play (P4)'],
  ['26006', '732', '165', 'Poland', 'Play (P4)'],
  ['26006', '732', '165', 'Poland', 'Play (P4)'],
  ['26006', '732', '165', 'Poland', 'Play (P4)'],
  ['26006', '732', '165', 'Poland', 'Play (P4)'],
  ['26006', '732', '165', 'Poland', 'Play (P4)'],
  ['26006', '732', '165', 'Poland', 'Play (P4)'],
  ['26006', '732', '165', 'Poland', 'Play (P4)'],
  ['26006', '732', '165', 'Poland', 'Play (P4)'],
  ['26006', '732', '165', 'Poland', 'Play (P4)'],
  ['26006', '732', '165', 'Poland', 'Play (P4)'],
  ['26006', '732', '165', 'Poland', 'Play (P4)'],
  ['26006', '732', '165', 'Poland', 'Play (P4)'],
  ['26006', '732', '165', 'Poland', 'Play (P4)'],
  ['26006', '732', '165', 'Poland', 'Play (P4)'],
  ['26006', '732', '165', 'Poland', 'Play (P4)'],
  ['26006', '732', '165', 'Poland', 'Play (P4)'],
  ['26006', '732', '165', 'Poland', 'Play (P4)'],
  ['26006', '732', '165', 'Poland', 'Play (P4)'],
  ['26006', '732', '165', 'Poland', 'Play (P4)'],
  ['26006', '732', '165', 'Poland', 'Play (P4)'],
  ['26006', '732', '165', 'Poland', 'Play (P4)'],
  ['26006', '732', '165', 'Poland', 'Play (P4)'],
  ['26006', '732', '165', 'Poland', 'Play (P4)'],
  ['26006', '732', '165', 'Poland', 'Play (P4)'],
  ['26045', '732', '165', 'Poland', 'Play (P4)'],
  ['26045', '732', '165', 'Poland', 'Play (P4)'],
  ['26045', '732', '165', 'Poland', 'Play (P4)'],
  ['26045', '732', '165', 'Poland', 'Play (P4)'],
  ['26045', '732', '165', 'Poland', 'Play (P4)'],
  ['26045', '732', '165', 'Poland', 'Play (P4)'],
  ['26045', '732', '165', 'Poland', 'Play (P4)'],
  ['26045', '732', '165', 'Poland', 'Play (P4)'],
  ['26045', '732', '165', 'Poland', 'Play (P4)'],
  ['26045', '732', '165', 'Poland', 'Play (P4)'],
  ['26045', '732', '165', 'Poland', 'Play (P4)'],
  ['26045', '732', '165', 'Poland', 'Play (P4)'],
  ['26045', '732', '165', 'Poland', 'Play (P4)'],
  ['26045', '732', '165', 'Poland', 'Play (P4)'],
  ['26045', '732', '165', 'Poland', 'Play (P4)'],
  ['26045', '732', '165', 'Poland', 'Play (P4)'],
  ['26045', '732', '165', 'Poland', 'Play (P4)'],
  ['26045', '732', '165', 'Poland', 'Play (P4)'],
  ['26045', '732', '165', 'Poland', 'Play (P4)'],
  ['26045', '732', '165', 'Poland', 'Play (P4)'],
  ['26045', '732', '165', 'Poland', 'Play (P4)'],
  ['26045', '732', '165', 'Poland', 'Play (P4)'],
  ['26045', '732', '165', 'Poland', 'Play (P4)'],
  ['26045', '732', '165', 'Poland', 'Play (P4)'],
  ['26045', '732', '165', 'Poland', 'Play (P4)'],
  ['26045', '732', '165', 'Poland', 'Play (P4)'],
  ['26045', '732', '165', 'Poland', 'Play (P4)'],
  ['26045', '732', '165', 'Poland', 'Play (P4)'],
  ['26045', '732', '165', 'Poland', 'Play (P4)'],
  ['26045', '732', '165', 'Poland', 'Play (P4)'],
  ['26045', '732', '165', 'Poland', 'Play (P4)'],
  ['26045', '732', '165', 'Poland', 'Play (P4)'],
  ['26045', '732', '165', 'Poland', 'Play (P4)'],
  ['26045', '732', '165', 'Poland', 'Play (P4)'],
  ['26045', '654', '0', 'Poland', 'Play (P4)'],
  ['26045', '654', '1', 'Poland', 'Play (P4)'],
  ['26045', '629', '0', 'Poland', 'Play (P4)'],
  ['26045', '629', '1', 'Poland', 'Play (P4)'],
  ['26045', '629', '7', 'Poland', 'Play (P4)'],
  ['26045', '629', '10', 'Poland', 'Play (P4)'],
  ['26045', '629', '10', 'Poland', 'Play (P4)'],
  ['26045', '629', '20', 'Poland', 'Play (P4)'],
  ['26045', '630', '90', 'Poland', 'Play (P4)'],
  ['26045', '630', '90', 'Poland', 'Play (P4)'],
  ['26045', '712', '0', 'Poland', 'Play (P4)'],
  ['26045', '712', '1', 'Poland', 'Play (P4)'],
  ['26045', '712', '1', 'Poland', 'Play (P4)'],
  ['26045', '712', '1', 'Poland', 'Play (P4)'],
  ['26045', '712', '1', 'Poland', 'Play (P4)'],
  ['26045', '712', '1', 'Poland', 'Play (P4)'],
  ['26045', '712', '1', 'Poland', 'Play (P4)'],
  ['26045', '712', '3', 'Poland', 'Play (P4)'],
  ['26045', '712', '3', 'Poland', 'Play (P4)'],
  ['26045', '712', '3', 'Poland', 'Play (P4)'],
  ['26045', '712', '3', 'Poland', 'Play (P4)'],
  ['26045', '712', '3', 'Poland', 'Play (P4)'],
  ['26045', '712', '3', 'Poland', 'Play (P4)'],
  ['26045', '712', '3', 'Poland', 'Play (P4)'],
  ['26045', '712', '4', 'Poland', 'Play (P4)'],
  ['26045', '712', '4', 'Poland', 'Play (P4)'],
  ['26045', '219', '0', 'Poland', 'Play (P4)'],
  ['26045', '219', '1', 'Poland', 'Play (P4)'],
  ['26045', '219', '1', 'Poland', 'Play (P4)'],
  ['26045', '219', '2', 'Poland', 'Play (P4)'],
  ['26045', '219', '10', 'Poland', 'Play (P4)'],
  ['26045', '219', '10', 'Poland', 'Play (P4)'],
  ['26045', '368', '0', 'Poland', 'Play (P4)'],
  ['26045', '368', '1', 'Poland', 'Play (P4)'],
  ['26045', '368', '1', 'Poland', 'Play (P4)'],
  ['26045', '280', '0', 'Poland', 'Play (P4)'],
  ['26045', '280', '1', 'Poland', 'Play (P4)'],
  ['26045', '280', '1', 'Poland', 'Play (P4)'],
  ['26045', '280', '10', 'Poland', 'Play (P4)'],
  ['26045', '280', '20', 'Poland', 'Play (P4)'],
  ['26045', '230', '0', 'Poland', 'Play (P4)'],
  ['26045', '230', '1', 'Poland', 'Play (P4)'],
  ['26045', '230', '1', 'Poland', 'Play (P4)'],
  ['2680', '268', '1', 'Portugal', 'DEFAULT PORTUGAL'],
  ['26801', '268', '1', 'Portugal', 'VODAFONE'],
  ['26803', '268', '1', 'Portugal', 'OPTIMUS'],
  ['26804', '268', '1', 'Portugal', 'Lycamobile'],
  ['26804', '268', '1', 'Portugal', 'Lycamobile'],
  ['26804', '268', '1', 'Portugal', 'Lycamobile'],
  ['26804', '268', '1', 'Portugal', 'Lycamobile'],
  ['26804', '268', '1', 'Portugal', 'Lycamobile'],
  ['26804', '268', '1', 'Portugal', 'Lycamobile'],
  ['26806', '268', '1', 'Portugal', 'TMN'],
  ['26806', '268', '1', 'Portugal', 'TMN'],
  ['310015', '310', '1', 'Puerto Rico', 'CINGULAR WIRELESS'],
  ['310015', '310', '1', 'Puerto Rico', 'CINGULAR WIRELESS'],
  ['4270', '427', '1', 'Qatar', 'DEFAULT QATAR'],
  ['42701', '427', '2', 'Qatar', 'Q-TEL'],
  ['42701', '427', '2', 'Qatar', 'Q-TEL'],
  ['42701', '427', '2', 'Qatar', 'Q-TEL'],
  ['42701', '427', '2', 'Qatar', 'Q-TEL'],
  ['42702', '427', '2', 'Qatar', 'VODAFONE'],
  ['42702', '427', '2', 'Qatar', 'VODAFONE'],
  ['42702', '427', '2', 'Qatar', 'VODAFONE'],
  ['6470', '647', '2', 'Reunion Island', 'DEFAULT REUNION'],
  ['64710', '647', '2', 'Reunion Island', 'SFR'],
  ['64710', '647', '2', 'Reunion Island', 'SFR'],
  ['64710', '647', '2', 'Reunion Island', 'SFR'],
  ['64710', '647', '2', 'Reunion Island', 'SFR'],
  ['64710', '647', '2', 'Reunion Island', 'SFR'],
  ['64710', '647', '2', 'Reunion Island', 'SFR'],
  ['64710', '647', '3', 'Reunion Island', 'SFR'],
  ['64710', '647', '3', 'Reunion Island', 'SFR'],
  ['64710', '647', '3', 'Reunion Island', 'SFR'],
  ['64710', '647', '3', 'Reunion Island', 'SFR'],
  ['64710', '647', '3', 'Reunion Island', 'SFR'],
  ['64710', '647', '3', 'Reunion Island', 'SFR'],
  ['64710', '647', '3', 'Reunion Island', 'SFR'],
  ['64710', '647', '3', 'Reunion Island', 'SFR'],
  ['64710', '647', '3', 'Reunion Island', 'SFR'],
  ['64710', '647', '3', 'Reunion Island', 'SFR'],
  ['64710', '647', '3', 'Reunion Island', 'SFR'],
  ['64710', '647', '4', 'Reunion Island', 'SFR'],
  ['64710', '647', '0', 'Reunion Island', 'SFR'],
  ['64710', '647', '1', 'Reunion Island', 'SFR'],
  ['2260', '226', '1', 'Romania', 'DEFAULT ROMANIA'],
  ['22601', '226', '2', 'Romania', 'MOBIFON (CONNEX)'],
  ['22601', '226', '2', 'Romania', 'MOBIFON (CONNEX)'],
  ['22601', '226', '2', 'Romania', 'MOBIFON (CONNEX)'],
  ['22601', '226', '2', 'Romania', 'MOBIFON (CONNEX)'],
  ['22601', '226', '2', 'Romania', 'MOBIFON (CONNEX)'],
  ['22601', '226', '5', 'Romania', 'MOBIFON (CONNEX)'],
  ['22601', '226', '86', 'Romania', 'MOBIFON (CONNEX)'],
  ['22601', '226', '86', 'Romania', 'MOBIFON (CONNEX)'],
  ['22601', '226', '89', 'Romania', 'MOBIFON (CONNEX)'],
  ['22601', '226', '0', 'Romania', 'MOBIFON (CONNEX)'],
  ['22603', '226', '1', 'Romania', 'COSMOTE ROMANIAN MOBILE TEL.'],
  ['22603', '226', '1', 'Romania', 'COSMOTE ROMANIAN MOBILE TEL.'],
  ['22603', '226', '1', 'Romania', 'COSMOTE ROMANIAN MOBILE TEL.'],
  ['22603', '226', '1', 'Romania', 'COSMOTE ROMANIAN MOBILE TEL.'],
  ['22603', '226', '1', 'Romania', 'COSMOTE ROMANIAN MOBILE TEL.'],
  ['22603', '226', '1', 'Romania', 'COSMOTE ROMANIAN MOBILE TEL.'],
  ['22603', '226', '1', 'Romania', 'COSMOTE ROMANIAN MOBILE TEL.'],
  ['22603', '226', '1', 'Romania', 'COSMOTE ROMANIAN MOBILE TEL.'],
  ['22603', '226', '1', 'Romania', 'COSMOTE ROMANIAN MOBILE TEL.'],
  ['22603', '226', '1', 'Romania', 'COSMOTE ROMANIAN MOBILE TEL.'],
  ['22604', '226', '1', 'Romania', 'Cosmote - Telekom Romania Mobile'],
  ['22604', '226', '1', 'Romania', 'Cosmote - Telekom Romania Mobile'],
  ['22604', '226', '1', 'Romania', 'Cosmote - Telekom Romania Mobile'],
  ['22605', '226', '1', 'Romania', 'Digi.Mobil'],
  ['22605', '226', '1', 'Romania', 'Digi.Mobil'],
  ['22605', '226', '1', 'Romania', 'Digi.Mobil'],
  ['22605', '226', '1', 'Romania', 'Digi.Mobil'],
  ['22605', '226', '1', 'Romania', 'Digi.Mobil'],
  ['22605', '226', '1', 'Romania', 'Digi.Mobil'],
  ['22605', '226', '1', 'Romania', 'Digi.Mobil'],
  ['22605', '226', '1', 'Romania', 'Digi.Mobil'],
  ['22606', '226', '1', 'Romania', 'Cosmote - Telekom Romania Mobile'],
  ['22606', '226', '1', 'Romania', 'Cosmote - Telekom Romania Mobile'],
  ['22606', '226', '1', 'Romania', 'Cosmote - Telekom Romania Mobile'],
  ['22610', '226', '1', 'Romania', 'ORANGE MOBIL ROM'],
  ['22610', '226', '1', 'Romania', 'ORANGE MOBIL ROM'],
  ['22610', '226', '1', 'Romania', 'ORANGE MOBIL ROM'],
  ['22610', '226', '1', 'Romania', 'ORANGE MOBIL ROM'],
  ['22610', '226', '1', 'Romania', 'ORANGE MOBIL ROM'],
  ['22610', '226', '1', 'Romania', 'ORANGE MOBIL ROM'],
  ['22610', '226', '1', 'Romania', 'ORANGE MOBIL ROM'],
  ['22610', '226', '1', 'Romania', 'ORANGE MOBIL ROM'],
  ['22610', '226', '1', 'Romania', 'ORANGE MOBIL ROM'],
  ['22610', '226', '1', 'Romania', 'ORANGE MOBIL ROM'],
  ['2500', '250', '1', 'Russia', 'DEFAULT RUSSIA'],
  ['25001', '250', '1', 'Russia', 'MOBILE TELESYSTEMS (MTS)'],
  ['25001', '250', '1', 'Russia', 'MOBILE TELESYSTEMS (MTS)'],
  ['25001', '250', '1', 'Russia', 'MOBILE TELESYSTEMS (MTS)'],
  ['25001', '250', '1', 'Russia', 'MOBILE TELESYSTEMS (MTS)'],
  ['25001', '250', '1', 'Russia', 'MOBILE TELESYSTEMS (MTS)'],
  ['25001', '250', '1', 'Russia', 'MOBILE TELESYSTEMS (MTS)'],
  ['25001', '250', '1', 'Russia', 'MOBILE TELESYSTEMS (MTS)'],
  ['25001', '250', '1', 'Russia', 'MOBILE TELESYSTEMS (MTS)'],
  ['25001', '250', '1', 'Russia', 'MOBILE TELESYSTEMS (MTS)'],
  ['25001', '250', '1', 'Russia', 'MOBILE TELESYSTEMS (MTS)'],
  ['25001', '250', '1', 'Russia', 'MOBILE TELESYSTEMS (MTS)'],
  ['25001', '250', '1', 'Russia', 'MOBILE TELESYSTEMS (MTS)'],
  ['25001', '250', '1', 'Russia', 'MOBILE TELESYSTEMS (MTS)'],
  ['25001', '250', '1', 'Russia', 'MOBILE TELESYSTEMS (MTS)'],
  ['25001', '250', '1', 'Russia', 'MOBILE TELESYSTEMS (MTS)'],
  ['25001', '250', '1', 'Russia', 'MOBILE TELESYSTEMS (MTS)'],
  ['25001', '250', '1', 'Russia', 'MOBILE TELESYSTEMS (MTS)'],
  ['25001', '250', '1', 'Russia', 'MOBILE TELESYSTEMS (MTS)'],
  ['25001', '250', '1', 'Russia', 'MOBILE TELESYSTEMS (MTS)'],
  ['25001', '250', '1', 'Russia', 'MOBILE TELESYSTEMS (MTS)'],
  ['25001', '250', '1', 'Russia', 'MOBILE TELESYSTEMS (MTS)'],
  ['25001', '250', '1', 'Russia', 'MOBILE TELESYSTEMS (MTS)'],
  ['25001', '250', '1', 'Russia', 'MOBILE TELESYSTEMS (MTS)'],
  ['25001', '250', '1', 'Russia', 'MOBILE TELESYSTEMS (MTS)'],
  ['25001', '250', '1', 'Russia', 'MOBILE TELESYSTEMS (MTS)'],
  ['25001', '250', '1', 'Russia', 'MOBILE TELESYSTEMS (MTS)'],
  ['25001', '250', '1', 'Russia', 'MOBILE TELESYSTEMS (MTS)'],
  ['25001', '250', '1', 'Russia', 'MOBILE TELESYSTEMS (MTS)'],
  ['25001', '250', '1', 'Russia', 'MOBILE TELESYSTEMS (MTS)'],
  ['25001', '250', '1', 'Russia', 'MOBILE TELESYSTEMS (MTS)'],
  ['25001', '250', '1', 'Russia', 'MOBILE TELESYSTEMS (MTS)'],
  ['25001', '250', '1', 'Russia', 'MOBILE TELESYSTEMS (MTS)'],
  ['25001', '250', '1', 'Russia', 'MOBILE TELESYSTEMS (MTS)'],
  ['25001', '250', '1', 'Russia', 'MOBILE TELESYSTEMS (MTS)'],
  ['25001', '250', '1', 'Russia', 'MOBILE TELESYSTEMS (MTS)'],
  ['25001', '250', '1', 'Russia', 'MOBILE TELESYSTEMS (MTS)'],
  ['25001', '250', '1', 'Russia', 'MOBILE TELESYSTEMS (MTS)'],
  ['25001', '250', '1', 'Russia', 'MOBILE TELESYSTEMS (MTS)'],
  ['25001', '250', '1', 'Russia', 'MOBILE TELESYSTEMS (MTS)'],
  ['25001', '250', '1', 'Russia', 'MOBILE TELESYSTEMS (MTS)'],
  ['25001', '250', '1', 'Russia', 'MOBILE TELESYSTEMS (MTS)'],
  ['25001', '250', '1', 'Russia', 'MOBILE TELESYSTEMS (MTS)'],
  ['25001', '250', '1', 'Russia', 'MOBILE TELESYSTEMS (MTS)'],
  ['25001', '250', '1', 'Russia', 'MOBILE TELESYSTEMS (MTS)'],
  ['25001', '250', '1', 'Russia', 'MOBILE TELESYSTEMS (MTS)'],
  ['25001', '250', '1', 'Russia', 'MOBILE TELESYSTEMS (MTS)'],
  ['25001', '250', '1', 'Russia', 'MOBILE TELESYSTEMS (MTS)'],
  ['25001', '250', '1', 'Russia', 'MOBILE TELESYSTEMS (MTS)'],
  ['25001', '250', '1', 'Russia', 'MOBILE TELESYSTEMS (MTS)'],
  ['25001', '250', '1', 'Russia', 'MOBILE TELESYSTEMS (MTS)'],
  ['25001', '250', '1', 'Russia', 'MOBILE TELESYSTEMS (MTS)'],
  ['25001', '250', '1', 'Russia', 'MOBILE TELESYSTEMS (MTS)'],
  ['25001', '250', '1', 'Russia', 'MOBILE TELESYSTEMS (MTS)'],
  ['25001', '250', '1', 'Russia', 'MOBILE TELESYSTEMS (MTS)'],
  ['25001', '250', '1', 'Russia', 'MOBILE TELESYSTEMS (MTS)'],
  ['25001', '250', '1', 'Russia', 'MOBILE TELESYSTEMS (MTS)'],
  ['25001', '250', '1', 'Russia', 'MOBILE TELESYSTEMS (MTS)'],
  ['25001', '250', '1', 'Russia', 'MOBILE TELESYSTEMS (MTS)'],
  ['25001', '250', '1', 'Russia', 'MOBILE TELESYSTEMS (MTS)'],
  ['25001', '250', '1', 'Russia', 'MOBILE TELESYSTEMS (MTS)'],
  ['25001', '250', '2', 'Russia', 'MOBILE TELESYSTEMS (MTS)'],
  ['25001', '250', '2', 'Russia', 'MOBILE TELESYSTEMS (MTS)'],
  ['25001', '250', '2', 'Russia', 'MOBILE TELESYSTEMS (MTS)'],
  ['25001', '250', '2', 'Russia', 'MOBILE TELESYSTEMS (MTS)'],
  ['25001', '250', '2', 'Russia', 'MOBILE TELESYSTEMS (MTS)'],
  ['25001', '250', '2', 'Russia', 'MOBILE TELESYSTEMS (MTS)'],
  ['25001', '250', '2', 'Russia', 'MOBILE TELESYSTEMS (MTS)'],
  ['25001', '250', '2', 'Russia', 'MOBILE TELESYSTEMS (MTS)'],
  ['25001', '250', '2', 'Russia', 'MOBILE TELESYSTEMS (MTS)'],
  ['25001', '250', '2', 'Russia', 'MOBILE TELESYSTEMS (MTS)'],
  ['25001', '250', '2', 'Russia', 'MOBILE TELESYSTEMS (MTS)'],
  ['25001', '250', '2', 'Russia', 'MOBILE TELESYSTEMS (MTS)'],
  ['25001', '250', '2', 'Russia', 'MOBILE TELESYSTEMS (MTS)'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '6', 'Russia', 'MEGAFON'],
  ['25002', '250', '20', 'Russia', 'MEGAFON'],
  ['25002', '250', '20', 'Russia', 'MEGAFON'],
  ['25002', '250', '20', 'Russia', 'MEGAFON'],
  ['25002', '250', '20', 'Russia', 'MEGAFON'],
  ['25002', '250', '20', 'Russia', 'MEGAFON'],
  ['25002', '250', '20', 'Russia', 'MEGAFON'],
  ['25002', '250', '20', 'Russia', 'MEGAFON'],
  ['25002', '250', '20', 'Russia', 'MEGAFON'],
  ['25002', '250', '20', 'Russia', 'MEGAFON'],
  ['25002', '250', '20', 'Russia', 'MEGAFON'],
  ['25002', '250', '20', 'Russia', 'MEGAFON'],
  ['25002', '250', '20', 'Russia', 'MEGAFON'],
  ['25002', '250', '20', 'Russia', 'MEGAFON'],
  ['25002', '250', '20', 'Russia', 'MEGAFON'],
  ['25002', '250', '20', 'Russia', 'MEGAFON'],
  ['25002', '250', '20', 'Russia', 'MEGAFON'],
  ['25002', '250', '30', 'Russia', 'MEGAFON'],
  ['25002', '250', '30', 'Russia', 'MEGAFON'],
  ['25002', '250', '30', 'Russia', 'MEGAFON'],
  ['25002', '250', '30', 'Russia', 'MEGAFON'],
  ['25002', '250', '30', 'Russia', 'MEGAFON'],
  ['25002', '250', '30', 'Russia', 'MEGAFON'],
  ['25002', '250', '30', 'Russia', 'MEGAFON'],
  ['25002', '250', '30', 'Russia', 'MEGAFON'],
  ['25002', '250', '30', 'Russia', 'MEGAFON'],
  ['25002', '250', '30', 'Russia', 'MEGAFON'],
  ['25002', '250', '30', 'Russia', 'MEGAFON'],
  ['25002', '250', '30', 'Russia', 'MEGAFON'],
  ['25002', '250', '30', 'Russia', 'MEGAFON'],
  ['25002', '250', '30', 'Russia', 'MEGAFON'],
  ['25002', '250', '30', 'Russia', 'MEGAFON'],
  ['25002', '250', '30', 'Russia', 'MEGAFON'],
  ['25002', '250', '30', 'Russia', 'MEGAFON'],
  ['25002', '250', '30', 'Russia', 'MEGAFON'],
  ['25002', '250', '30', 'Russia', 'MEGAFON'],
  ['25002', '250', '30', 'Russia', 'MEGAFON'],
  ['25002', '250', '30', 'Russia', 'MEGAFON'],
  ['25002', '250', '30', 'Russia', 'MEGAFON'],
  ['25002', '250', '0', 'Russia', 'MEGAFON'],
  ['25002', '250', '1', 'Russia', 'MEGAFON'],
  ['25002', '250', '1', 'Russia', 'MEGAFON'],
  ['25002', '250', '15', 'Russia', 'MEGAFON'],
  ['25002', '250', '15', 'Russia', 'MEGAFON'],
  ['25002', '250', '15', 'Russia', 'MEGAFON'],
  ['25002', '250', '15', 'Russia', 'MEGAFON'],
  ['25002', '250', '1', 'Russia', 'MEGAFON'],
  ['25002', '250', '0', 'Russia', 'MEGAFON'],
  ['25002', '250', '0', 'Russia', 'MEGAFON'],
  ['25002', '250', '1', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '0', 'Russia', 'MEGAFON'],
  ['25002', '250', '1', 'Russia', 'MEGAFON'],
  ['25002', '250', '1', 'Russia', 'MEGAFON'],
  ['25002', '250', '1', 'Russia', 'MEGAFON'],
  ['25002', '250', '1', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '3', 'Russia', 'MEGAFON'],
  ['25002', '250', '3', 'Russia', 'MEGAFON'],
  ['25002', '250', '3', 'Russia', 'MEGAFON'],
  ['25002', '250', '4', 'Russia', 'MEGAFON'],
  ['25002', '250', '0', 'Russia', 'MEGAFON'],
  ['25002', '250', '1', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '3', 'Russia', 'MEGAFON'],
  ['25002', '250', '4', 'Russia', 'MEGAFON'],
  ['25002', '250', '4', 'Russia', 'MEGAFON'],
  ['25002', '250', '4', 'Russia', 'MEGAFON'],
  ['25002', '250', '4', 'Russia', 'MEGAFON'],
  ['25002', '250', '4', 'Russia', 'MEGAFON'],
  ['25002', '250', '4', 'Russia', 'MEGAFON'],
  ['25002', '250', '4', 'Russia', 'MEGAFON'],
  ['25002', '250', '4', 'Russia', 'MEGAFON'],
  ['25002', '250', '4', 'Russia', 'MEGAFON'],
  ['25002', '250', '4', 'Russia', 'MEGAFON'],
  ['25002', '250', '4', 'Russia', 'MEGAFON'],
  ['25002', '250', '4', 'Russia', 'MEGAFON'],
  ['25002', '250', '4', 'Russia', 'MEGAFON'],
  ['25002', '250', '4', 'Russia', 'MEGAFON'],
  ['25002', '250', '4', 'Russia', 'MEGAFON'],
  ['25002', '250', '4', 'Russia', 'MEGAFON'],
  ['25002', '250', '4', 'Russia', 'MEGAFON'],
  ['25002', '250', '4', 'Russia', 'MEGAFON'],
  ['25002', '250', '4', 'Russia', 'MEGAFON'],
  ['25002', '250', '4', 'Russia', 'MEGAFON'],
  ['25002', '250', '4', 'Russia', 'MEGAFON'],
  ['25002', '250', '4', 'Russia', 'MEGAFON'],
  ['25002', '250', '4', 'Russia', 'MEGAFON'],
  ['25002', '250', '4', 'Russia', 'MEGAFON'],
  ['25002', '250', '4', 'Russia', 'MEGAFON'],
  ['25002', '250', '4', 'Russia', 'MEGAFON'],
  ['25002', '250', '4', 'Russia', 'MEGAFON'],
  ['25002', '250', '4', 'Russia', 'MEGAFON'],
  ['25002', '250', '4', 'Russia', 'MEGAFON'],
  ['25002', '250', '4', 'Russia', 'MEGAFON'],
  ['25002', '250', '4', 'Russia', 'MEGAFON'],
  ['25002', '250', '4', 'Russia', 'MEGAFON'],
  ['25002', '250', '4', 'Russia', 'MEGAFON'],
  ['25002', '250', '4', 'Russia', 'MEGAFON'],
  ['25002', '250', '4', 'Russia', 'MEGAFON'],
  ['25002', '250', '4', 'Russia', 'MEGAFON'],
  ['25002', '250', '4', 'Russia', 'MEGAFON'],
  ['25002', '250', '4', 'Russia', 'MEGAFON'],
  ['25002', '250', '4', 'Russia', 'MEGAFON'],
  ['25002', '250', '4', 'Russia', 'MEGAFON'],
  ['25002', '250', '4', 'Russia', 'MEGAFON'],
  ['25002', '250', '4', 'Russia', 'MEGAFON'],
  ['25002', '250', '4', 'Russia', 'MEGAFON'],
  ['25002', '250', '5', 'Russia', 'MEGAFON'],
  ['25002', '250', '5', 'Russia', 'MEGAFON'],
  ['25002', '250', '5', 'Russia', 'MEGAFON'],
  ['25002', '250', '5', 'Russia', 'MEGAFON'],
  ['25002', '250', '5', 'Russia', 'MEGAFON'],
  ['25002', '250', '0', 'Russia', 'MEGAFON'],
  ['25002', '250', '0', 'Russia', 'MEGAFON'],
  ['25002', '250', '0', 'Russia', 'MEGAFON'],
  ['25002', '250', '0', 'Russia', 'MEGAFON'],
  ['25002', '250', '0', 'Russia', 'MEGAFON'],
  ['25002', '250', '0', 'Russia', 'MEGAFON'],
  ['25002', '250', '0', 'Russia', 'MEGAFON'],
  ['25002', '250', '0', 'Russia', 'MEGAFON'],
  ['25002', '250', '0', 'Russia', 'MEGAFON'],
  ['25002', '250', '0', 'Russia', 'MEGAFON'],
  ['25002', '250', '0', 'Russia', 'MEGAFON'],
  ['25002', '250', '1', 'Russia', 'MEGAFON'],
  ['25002', '250', '1', 'Russia', 'MEGAFON'],
  ['25002', '250', '1', 'Russia', 'MEGAFON'],
  ['25002', '250', '1', 'Russia', 'MEGAFON'],
  ['25002', '250', '1', 'Russia', 'MEGAFON'],
  ['25002', '250', '1', 'Russia', 'MEGAFON'],
  ['25002', '250', '1', 'Russia', 'MEGAFON'],
  ['25002', '250', '1', 'Russia', 'MEGAFON'],
  ['25002', '250', '1', 'Russia', 'MEGAFON'],
  ['25002', '250', '1', 'Russia', 'MEGAFON'],
  ['25002', '250', '1', 'Russia', 'MEGAFON'],
  ['25002', '250', '1', 'Russia', 'MEGAFON'],
  ['25002', '250', '1', 'Russia', 'MEGAFON'],
  ['25002', '250', '1', 'Russia', 'MEGAFON'],
  ['25002', '250', '1', 'Russia', 'MEGAFON'],
  ['25002', '250', '1', 'Russia', 'MEGAFON'],
  ['25002', '250', '1', 'Russia', 'MEGAFON'],
  ['25002', '250', '1', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25002', '250', '2', 'Russia', 'MEGAFON'],
  ['25003', '250', '2', 'Russia', 'NIZHEGORODSKAYA CELLULAR COM (NCC)'],
  ['25003', '250', '2', 'Russia', 'NIZHEGORODSKAYA CELLULAR COM (NCC)'],
  ['25003', '250', '2', 'Russia', 'NIZHEGORODSKAYA CELLULAR COM (NCC)'],
  ['25003', '250', '2', 'Russia', 'NIZHEGORODSKAYA CELLULAR COM (NCC)'],
  ['25003', '250', '2', 'Russia', 'NIZHEGORODSKAYA CELLULAR COM (NCC)'],
  ['25003', '250', '2', 'Russia', 'NIZHEGORODSKAYA CELLULAR COM (NCC)'],
  ['25003', '250', '2', 'Russia', 'NIZHEGORODSKAYA CELLULAR COM (NCC)'],
  ['25003', '250', '2', 'Russia', 'NIZHEGORODSKAYA CELLULAR COM (NCC)'],
  ['25003', '250', '2', 'Russia', 'NIZHEGORODSKAYA CELLULAR COM (NCC)'],
  ['25003', '250', '2', 'Russia', 'NIZHEGORODSKAYA CELLULAR COM (NCC)'],
  ['25003', '250', '2', 'Russia', 'NIZHEGORODSKAYA CELLULAR COM (NCC)'],
  ['25003', '250', '2', 'Russia', 'NIZHEGORODSKAYA CELLULAR COM (NCC)'],
  ['25003', '250', '2', 'Russia', 'NIZHEGORODSKAYA CELLULAR COM (NCC)'],
  ['25003', '250', '2', 'Russia', 'NIZHEGORODSKAYA CELLULAR COM (NCC)'],
  ['25003', '250', '2', 'Russia', 'NIZHEGORODSKAYA CELLULAR COM (NCC)'],
  ['25003', '250', '2', 'Russia', 'NIZHEGORODSKAYA CELLULAR COM (NCC)'],
  ['25003', '250', '2', 'Russia', 'NIZHEGORODSKAYA CELLULAR COM (NCC)'],
  ['25003', '250', '2', 'Russia', 'NIZHEGORODSKAYA CELLULAR COM (NCC)'],
  ['25003', '250', '2', 'Russia', 'NIZHEGORODSKAYA CELLULAR COM (NCC)'],
  ['25003', '250', '2', 'Russia', 'NIZHEGORODSKAYA CELLULAR COM (NCC)'],
  ['25003', '250', '2', 'Russia', 'NIZHEGORODSKAYA CELLULAR COM (NCC)'],
  ['25003', '250', '2', 'Russia', 'NIZHEGORODSKAYA CELLULAR COM (NCC)'],
  ['25003', '250', '2', 'Russia', 'NIZHEGORODSKAYA CELLULAR COM (NCC)'],
  ['25003', '250', '2', 'Russia', 'NIZHEGORODSKAYA CELLULAR COM (NCC)'],
  ['25003', '250', '2', 'Russia', 'NIZHEGORODSKAYA CELLULAR COM (NCC)'],
  ['25003', '250', '2', 'Russia', 'NIZHEGORODSKAYA CELLULAR COM (NCC)'],
  ['25003', '250', '2', 'Russia', 'NIZHEGORODSKAYA CELLULAR COM (NCC)'],
  ['25003', '250', '2', 'Russia', 'NIZHEGORODSKAYA CELLULAR COM (NCC)'],
  ['25003', '250', '2', 'Russia', 'NIZHEGORODSKAYA CELLULAR COM (NCC)'],
  ['25003', '250', '2', 'Russia', 'NIZHEGORODSKAYA CELLULAR COM (NCC)'],
  ['25003', '250', '2', 'Russia', 'NIZHEGORODSKAYA CELLULAR COM (NCC)'],
  ['25003', '250', '2', 'Russia', 'NIZHEGORODSKAYA CELLULAR COM (NCC)'],
  ['25003', '250', '2', 'Russia', 'NIZHEGORODSKAYA CELLULAR COM (NCC)'],
  ['25003', '250', '2', 'Russia', 'NIZHEGORODSKAYA CELLULAR COM (NCC)'],
  ['25003', '250', '2', 'Russia', 'NIZHEGORODSKAYA CELLULAR COM (NCC)'],
  ['25004', '250', '2', 'Russia', 'SIBCHALLENGE'],
  ['25004', '250', '2', 'Russia', 'SIBCHALLENGE'],
  ['25004', '250', '2', 'Russia', 'SIBCHALLENGE'],
  ['25004', '250', '2', 'Russia', 'SIBCHALLENGE'],
  ['25004', '250', '2', 'Russia', 'SIBCHALLENGE'],
  ['25004', '250', '2', 'Russia', 'SIBCHALLENGE'],
  ['25004', '250', '2', 'Russia', 'SIBCHALLENGE'],
  ['25004', '250', '2', 'Russia', 'SIBCHALLENGE'],
  ['25004', '250', '2', 'Russia', 'SIBCHALLENGE'],
  ['25004', '250', '2', 'Russia', 'SIBCHALLENGE'],
  ['25005', '250', '2', 'Russia', 'SCS 900'],
  ['25005', '250', '2', 'Russia', 'SCS 900'],
  ['25005', '250', '2', 'Russia', 'SCS 900'],
  ['25005', '250', '2', 'Russia', 'SCS 900'],
  ['25005', '250', '2', 'Russia', 'SCS 900'],
  ['25005', '250', '2', 'Russia', 'SCS 900'],
  ['25005', '250', '2', 'Russia', 'SCS 900'],
  ['25005', '250', '2', 'Russia', 'SCS 900'],
  ['25005', '250', '2', 'Russia', 'SCS 900'],
  ['25005', '250', '2', 'Russia', 'SCS 900'],
  ['25005', '250', '2', 'Russia', 'SCS 900'],
  ['25005', '250', '2', 'Russia', 'SCS 900'],
  ['25005', '250', '2', 'Russia', 'SCS 900'],
  ['25005', '250', '2', 'Russia', 'SCS 900'],
  ['25005', '250', '2', 'Russia', 'SCS 900'],
  ['25005', '250', '2', 'Russia', 'SCS 900'],
  ['25005', '250', '2', 'Russia', 'SCS 900'],
  ['25005', '250', '2', 'Russia', 'SCS 900'],
  ['25005', '250', '2', 'Russia', 'SCS 900'],
  ['25005', '250', '2', 'Russia', 'SCS 900'],
  ['25005', '250', '2', 'Russia', 'SCS 900'],
  ['25005', '250', '2', 'Russia', 'SCS 900'],
  ['25005', '250', '2', 'Russia', 'SCS 900'],
  ['25005', '250', '2', 'Russia', 'SCS 900'],
  ['25005', '250', '2', 'Russia', 'SCS 900'],
  ['25005', '250', '2', 'Russia', 'SCS 900'],
  ['25005', '250', '2', 'Russia', 'SCS 900'],
  ['25005', '250', '2', 'Russia', 'SCS 900'],
  ['25005', '250', '2', 'Russia', 'SCS 900'],
  ['25005', '250', '2', 'Russia', 'SCS 900'],
  ['25005', '250', '2', 'Russia', 'SCS 900'],
  ['25005', '250', '2', 'Russia', 'SCS 900'],
  ['25005', '250', '2', 'Russia', 'SCS 900'],
  ['25005', '250', '2', 'Russia', 'SCS 900'],
  ['25005', '250', '2', 'Russia', 'SCS 900'],
  ['25005', '250', '2', 'Russia', 'SCS 900'],
  ['25005', '250', '2', 'Russia', 'SCS 900'],
  ['25005', '250', '2', 'Russia', 'SCS 900'],
  ['25005', '250', '2', 'Russia', 'SCS 900'],
  ['25005', '250', '2', 'Russia', 'SCS 900'],
  ['25005', '250', '2', 'Russia', 'SCS 900'],
  ['25005', '250', '2', 'Russia', 'SCS 900'],
  ['25005', '250', '2', 'Russia', 'SCS 900'],
  ['25005', '250', '2', 'Russia', 'SCS 900'],
  ['25005', '250', '2', 'Russia', 'SCS 900'],
  ['25005', '250', '2', 'Russia', 'SCS 900'],
  ['25005', '250', '2', 'Russia', 'SCS 900'],
  ['25005', '250', '2', 'Russia', 'SCS 900'],
  ['25005', '250', '2', 'Russia', 'SCS 900'],
  ['25005', '250', '2', 'Russia', 'SCS 900'],
  ['25005', '250', '2', 'Russia', 'SCS 900'],
  ['25005', '250', '2', 'Russia', 'SCS 900'],
  ['25005', '250', '2', 'Russia', 'SCS 900'],
  ['25005', '250', '2', 'Russia', 'SCS 900'],
  ['25005', '250', '2', 'Russia', 'SCS 900'],
  ['25005', '250', '2', 'Russia', 'SCS 900'],
  ['25005', '250', '2', 'Russia', 'SCS 900'],
  ['25005', '250', '2', 'Russia', 'SCS 900'],
  ['25005', '250', '2', 'Russia', 'SCS 900'],
  ['25005', '250', '2', 'Russia', 'SCS 900'],
  ['25005', '250', '2', 'Russia', 'SCS 900'],
  ['25005', '250', '2', 'Russia', 'SCS 900'],
  ['25005', '250', '2', 'Russia', 'SCS 900'],
  ['25005', '250', '2', 'Russia', 'SCS 900'],
  ['25005', '250', '2', 'Russia', 'SCS 900'],
  ['25005', '250', '2', 'Russia', 'SCS 900'],
  ['25005', '250', '2', 'Russia', 'SCS 900'],
  ['25005', '250', '2', 'Russia', 'SCS 900'],
  ['25005', '250', '2', 'Russia', 'SCS 900'],
  ['25005', '250', '2', 'Russia', 'SCS 900'],
  ['25005', '250', '2', 'Russia', 'SCS 900'],
  ['25005', '250', '2', 'Russia', 'SCS 900'],
  ['25005', '250', '2', 'Russia', 'SCS 900'],
  ['25005', '250', '2', 'Russia', 'SCS 900'],
  ['25005', '250', '2', 'Russia', 'SCS 900'],
  ['25005', '250', '2', 'Russia', 'SCS 900'],
  ['25005', '250', '2', 'Russia', 'SCS 900'],
  ['25005', '250', '2', 'Russia', 'SCS 900'],
  ['25005', '250', '2', 'Russia', 'SCS 900'],
  ['25005', '250', '2', 'Russia', 'SCS 900'],
  ['25005', '250', '2', 'Russia', 'SCS 900'],
  ['25005', '250', '2', 'Russia', 'SCS 900'],
  ['25005', '250', '2', 'Russia', 'SCS 900'],
  ['25005', '250', '2', 'Russia', 'SCS 900'],
  ['25005', '250', '2', 'Russia', 'SCS 900'],
  ['25005', '250', '2', 'Russia', 'SCS 900'],
  ['25005', '250', '2', 'Russia', 'SCS 900'],
  ['25005', '250', '2', 'Russia', 'SCS 900'],
  ['25005', '250', '2', 'Russia', 'SCS 900'],
  ['25005', '250', '2', 'Russia', 'SCS 900'],
  ['25005', '250', '2', 'Russia', 'SCS 900'],
  ['25005', '250', '2', 'Russia', 'SCS 900'],
  ['25005', '250', '2', 'Russia', 'SCS 900'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25007', '250', '2', 'Russia', 'ZAO SMARTS'],
  ['25010', '250', '2', 'Russia', 'DONTELECOM'],
  ['25010', '250', '2', 'Russia', 'DONTELECOM'],
  ['25010', '250', '2', 'Russia', 'DONTELECOM'],
  ['25010', '250', '2', 'Russia', 'DONTELECOM'],
  ['25010', '250', '2', 'Russia', 'DONTELECOM'],
  ['25010', '250', '2', 'Russia', 'DONTELECOM'],
  ['25010', '250', '2', 'Russia', 'DONTELECOM'],
  ['25010', '250', '2', 'Russia', 'DONTELECOM'],
  ['25010', '250', '2', 'Russia', 'DONTELECOM'],
  ['25010', '250', '2', 'Russia', 'DONTELECOM'],
  ['25010', '250', '2', 'Russia', 'DONTELECOM'],
  ['25010', '250', '2', 'Russia', 'DONTELECOM'],
  ['25010', '250', '2', 'Russia', 'DONTELECOM'],
  ['25010', '250', '2', 'Russia', 'DONTELECOM'],
  ['25010', '250', '2', 'Russia', 'DONTELECOM'],
  ['25010', '250', '2', 'Russia', 'DONTELECOM'],
  ['25010', '250', '2', 'Russia', 'DONTELECOM'],
  ['25010', '250', '2', 'Russia', 'DONTELECOM'],
  ['25010', '250', '2', 'Russia', 'DONTELECOM'],
  ['25010', '250', '2', 'Russia', 'DONTELECOM'],
  ['25010', '250', '2', 'Russia', 'DONTELECOM'],
  ['25010', '250', '2', 'Russia', 'DONTELECOM'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25012', '250', '2', 'Russia', 'FAR-EASTERN CELLULAR SYSTEMS 900'],
  ['25013', '250', '2', 'Russia', 'KUBAN GSM'],
  ['25017', '250', '2', 'Russia', 'ERMAK RMS'],
  ['25017', '250', '2', 'Russia', 'ERMAK RMS'],
  ['25017', '250', '2', 'Russia', 'ERMAK RMS'],
  ['25017', '250', '2', 'Russia', 'ERMAK RMS'],
  ['25017', '250', '2', 'Russia', 'ERMAK RMS'],
  ['25017', '250', '2', 'Russia', 'ERMAK RMS'],
  ['25017', '250', '2', 'Russia', 'ERMAK RMS'],
  ['25017', '250', '2', 'Russia', 'ERMAK RMS'],
  ['25017', '250', '2', 'Russia', 'ERMAK RMS'],
  ['25017', '250', '2', 'Russia', 'ERMAK RMS'],
  ['25017', '250', '2', 'Russia', 'ERMAK RMS'],
  ['25017', '250', '2', 'Russia', 'ERMAK RMS'],
  ['25017', '250', '2', 'Russia', 'ERMAK RMS'],
  ['25017', '250', '2', 'Russia', 'ERMAK RMS'],
  ['25017', '250', '2', 'Russia', 'ERMAK RMS'],
  ['25017', '250', '2', 'Russia', 'ERMAK RMS'],
  ['25017', '250', '2', 'Russia', 'ERMAK RMS'],
  ['25017', '250', '2', 'Russia', 'ERMAK RMS'],
  ['25017', '250', '2', 'Russia', 'ERMAK RMS'],
  ['25017', '250', '2', 'Russia', 'ERMAK RMS'],
  ['25017', '250', '2', 'Russia', 'ERMAK RMS'],
  ['25017', '250', '2', 'Russia', 'ERMAK RMS'],
  ['25017', '250', '2', 'Russia', 'ERMAK RMS'],
  ['25017', '250', '2', 'Russia', 'ERMAK RMS'],
  ['25017', '250', '2', 'Russia', 'ERMAK RMS'],
  ['25017', '250', '2', 'Russia', 'ERMAK RMS'],
  ['25017', '250', '2', 'Russia', 'ERMAK RMS'],
  ['25017', '250', '2', 'Russia', 'ERMAK RMS'],
  ['25017', '250', '2', 'Russia', 'ERMAK RMS'],
  ['25017', '250', '2', 'Russia', 'ERMAK RMS'],
  ['25017', '250', '2', 'Russia', 'ERMAK RMS'],
  ['25017', '250', '2', 'Russia', 'ERMAK RMS'],
  ['25017', '250', '2', 'Russia', 'ERMAK RMS'],
  ['25017', '250', '2', 'Russia', 'ERMAK RMS'],
  ['25017', '250', '2', 'Russia', 'ERMAK RMS'],
  ['25017', '250', '2', 'Russia', 'ERMAK RMS'],
  ['25017', '250', '2', 'Russia', 'ERMAK RMS'],
  ['25017', '250', '2', 'Russia', 'ERMAK RMS'],
  ['25017', '250', '2', 'Russia', 'ERMAK RMS'],
  ['25017', '250', '2', 'Russia', 'ERMAK RMS'],
  ['25017', '250', '2', 'Russia', 'ERMAK RMS'],
  ['25017', '250', '2', 'Russia', 'ERMAK RMS'],
  ['25017', '250', '2', 'Russia', 'ERMAK RMS'],
  ['25017', '250', '2', 'Russia', 'ERMAK RMS'],
  ['25017', '250', '2', 'Russia', 'ERMAK RMS'],
  ['25017', '250', '2', 'Russia', 'ERMAK RMS'],
  ['25017', '250', '2', 'Russia', 'ERMAK RMS'],
  ['25017', '250', '2', 'Russia', 'ERMAK RMS'],
  ['25017', '250', '2', 'Russia', 'ERMAK RMS'],
  ['25017', '250', '2', 'Russia', 'ERMAK RMS'],
  ['25017', '250', '2', 'Russia', 'ERMAK RMS'],
  ['25017', '250', '2', 'Russia', 'ERMAK RMS'],
  ['25017', '250', '2', 'Russia', 'ERMAK RMS'],
  ['25017', '250', '2', 'Russia', 'ERMAK RMS'],
  ['25017', '250', '2', 'Russia', 'ERMAK RMS'],
  ['25017', '250', '2', 'Russia', 'ERMAK RMS'],
  ['25017', '250', '2', 'Russia', 'ERMAK RMS'],
  ['25017', '250', '2', 'Russia', 'ERMAK RMS'],
  ['25017', '250', '2', 'Russia', 'ERMAK RMS'],
  ['25017', '250', '2', 'Russia', 'ERMAK RMS'],
  ['25020', '250', '2', 'Russia', 'TELE 2'],
  ['25020', '250', '2', 'Russia', 'TELE 2'],
  ['25020', '250', '2', 'Russia', 'TELE 2'],
  ['25020', '250', '2', 'Russia', 'TELE 2'],
  ['25023', '250', '2', 'Russia', 'Unknown-RUSSIA-9232'],
  ['25028', '250', '2', 'Russia', 'EXTEL'],
  ['25028', '250', '2', 'Russia', 'EXTEL'],
  ['25028', '250', '2', 'Russia', 'EXTEL'],
  ['25028', '250', '2', 'Russia', 'EXTEL'],
  ['25028', '250', '2', 'Russia', 'EXTEL'],
  ['25028', '250', '2', 'Russia', 'EXTEL'],
  ['25028', '250', '2', 'Russia', 'EXTEL'],
  ['25028', '250', '2', 'Russia', 'EXTEL'],
  ['25028', '250', '2', 'Russia', 'EXTEL'],
  ['25039', '250', '2', 'Russia', 'URALTEL'],
  ['25039', '250', '2', 'Russia', 'URALTEL'],
  ['25039', '250', '2', 'Russia', 'URALTEL'],
  ['25039', '250', '2', 'Russia', 'URALTEL'],
  ['25039', '250', '2', 'Russia', 'URALTEL'],
  ['25039', '250', '2', 'Russia', 'URALTEL'],
  ['25039', '250', '2', 'Russia', 'URALTEL'],
  ['25039', '250', '2', 'Russia', 'URALTEL'],
  ['25039', '250', '2', 'Russia', 'URALTEL'],
  ['25039', '250', '2', 'Russia', 'URALTEL'],
  ['25039', '250', '2', 'Russia', 'URALTEL'],
  ['25039', '250', '2', 'Russia', 'URALTEL'],
  ['25039', '250', '2', 'Russia', 'URALTEL'],
  ['25039', '250', '2', 'Russia', 'URALTEL'],
  ['25039', '250', '2', 'Russia', 'URALTEL'],
  ['25039', '250', '2', 'Russia', 'URALTEL'],
  ['25039', '250', '2', 'Russia', 'URALTEL'],
  ['25039', '250', '2', 'Russia', 'URALTEL'],
  ['25039', '250', '2', 'Russia', 'URALTEL'],
  ['25039', '250', '2', 'Russia', 'URALTEL'],
  ['25039', '250', '2', 'Russia', 'URALTEL'],
  ['25039', '250', '2', 'Russia', 'URALTEL'],
  ['25039', '250', '2', 'Russia', 'URALTEL'],
  ['25039', '250', '2', 'Russia', 'URALTEL'],
  ['25039', '250', '2', 'Russia', 'URALTEL'],
  ['25039', '250', '2', 'Russia', 'URALTEL'],
  ['25039', '250', '2', 'Russia', 'URALTEL'],
  ['25039', '250', '2', 'Russia', 'URALTEL'],
  ['25039', '250', '2', 'Russia', 'URALTEL'],
  ['25039', '250', '2', 'Russia', 'URALTEL'],
  ['25092', '250', '2', 'Russia', 'PRIMTELEFON'],
  ['25092', '250', '2', 'Russia', 'PRIMTELEFON'],
  ['25092', '250', '2', 'Russia', 'PRIMTELEFON'],
  ['25092', '250', '2', 'Russia', 'PRIMTELEFON'],
  ['25092', '250', '2', 'Russia', 'PRIMTELEFON'],
  ['25092', '250', '2', 'Russia', 'PRIMTELEFON'],
  ['25092', '250', '2', 'Russia', 'PRIMTELEFON'],
  ['25092', '250', '2', 'Russia', 'PRIMTELEFON'],
  ['25092', '250', '2', 'Russia', 'PRIMTELEFON'],
  ['25092', '250', '2', 'Russia', 'PRIMTELEFON'],
  ['25092', '250', '2', 'Russia', 'PRIMTELEFON'],
  ['25092', '250', '2', 'Russia', 'PRIMTELEFON'],
  ['25092', '250', '2', 'Russia', 'PRIMTELEFON'],
  ['25092', '250', '2', 'Russia', 'PRIMTELEFON'],
  ['25092', '250', '2', 'Russia', 'PRIMTELEFON'],
  ['25092', '250', '2', 'Russia', 'PRIMTELEFON'],
  ['25099', '250', '2', 'Russia', 'VIMPELCOM (KB IMPULS)'],
  ['25099', '250', '2', 'Russia', 'VIMPELCOM (KB IMPULS)'],
  ['25099', '250', '2', 'Russia', 'VIMPELCOM (KB IMPULS)'],
  ['25099', '250', '2', 'Russia', 'VIMPELCOM (KB IMPULS)'],
  ['25099', '250', '2', 'Russia', 'VIMPELCOM (KB IMPULS)'],
  ['25099', '250', '2', 'Russia', 'VIMPELCOM (KB IMPULS)'],
  ['25099', '250', '2', 'Russia', 'VIMPELCOM (KB IMPULS)'],
  ['25099', '250', '2', 'Russia', 'VIMPELCOM (KB IMPULS)'],
  ['25099', '250', '2', 'Russia', 'VIMPELCOM (KB IMPULS)'],
  ['25099', '250', '2', 'Russia', 'VIMPELCOM (KB IMPULS)'],
  ['25099', '250', '2', 'Russia', 'VIMPELCOM (KB IMPULS)'],
  ['25099', '250', '2', 'Russia', 'VIMPELCOM (KB IMPULS)'],
  ['25099', '250', '2', 'Russia', 'VIMPELCOM (KB IMPULS)'],
  ['25099', '250', '2', 'Russia', 'VIMPELCOM (KB IMPULS)'],
  ['25099', '250', '2', 'Russia', 'VIMPELCOM (KB IMPULS)'],
  ['25099', '250', '2', 'Russia', 'VIMPELCOM (KB IMPULS)'],
  ['25099', '250', '2', 'Russia', 'VIMPELCOM (KB IMPULS)'],
  ['25099', '250', '2', 'Russia', 'VIMPELCOM (KB IMPULS)'],
  ['25099', '250', '2', 'Russia', 'VIMPELCOM (KB IMPULS)'],
  ['25099', '250', '2', 'Russia', 'VIMPELCOM (KB IMPULS)'],
  ['25099', '250', '2', 'Russia', 'VIMPELCOM (KB IMPULS, STAVTELESOT)'],
  ['25099', '250', '2', 'Russia', 'VIMPELCOM (KB IMPULS, STAVTELESOT)'],
  ['25099', '250', '2', 'Russia', 'VIMPELCOM (KB IMPULS)'],
  ['25099', '250', '2', 'Russia', 'VIMPELCOM (KB IMPULS, STAVTELESOT)'],
  ['25099', '250', '2', 'Russia', 'VIMPELCOM (KB IMPULS, STAVTELESOT)'],
  ['25099', '250', '2', 'Russia', 'VIMPELCOM (KB IMPULS, STAVTELESOT)'],
  ['25099', '250', '2', 'Russia', 'VIMPELCOM (KB IMPULS, STAVTELESOT)'],
  ['25099', '250', '2', 'Russia', 'VIMPELCOM (KB IMPULS, STAVTELESOT)'],
  ['25099', '250', '2', 'Russia', 'VIMPELCOM (KB IMPULS, STAVTELESOT)'],
  ['25099', '250', '2', 'Russia', 'VIMPELCOM (KB IMPULS, STAVTELESOT)'],
  ['25099', '250', '2', 'Russia', 'VIMPELCOM (KB IMPULS, STAVTELESOT)'],
  ['25099', '250', '2', 'Russia', 'VIMPELCOM (KB IMPULS)'],
  ['25099', '250', '2', 'Russia', 'VIMPELCOM (KB IMPULS, STAVTELESOT)'],
  ['25099', '250', '2', 'Russia', 'VIMPELCOM (KB IMPULS, STAVTELESOT)'],
  ['25099', '250', '2', 'Russia', 'VIMPELCOM (KB IMPULS, STAVTELESOT)'],
  ['25099', '250', '2', 'Russia', 'VIMPELCOM (KB IMPULS, STAVTELESOT)'],
  ['25099', '250', '2', 'Russia', 'VIMPELCOM (KB IMPULS, STAVTELESOT)'],
  ['25099', '250', '2', 'Russia', 'VIMPELCOM (KB IMPULS)'],
  ['25099', '250', '2', 'Russia', 'VIMPELCOM (KB IMPULS)'],
  ['25099', '250', '2', 'Russia', 'VIMPELCOM (KB IMPULS)'],
  ['25099', '250', '2', 'Russia', 'VIMPELCOM (KB IMPULS)'],
  ['25099', '250', '2', 'Russia', 'VIMPELCOM (KB IMPULS)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25006', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '2', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '3', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '3', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '3', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '3', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '3', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '3', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '7', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '7', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '7', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '7', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '7', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '7', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '7', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '7', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '7', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '7', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '7', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '7', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '9', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '9', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '9', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '1', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '1', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '1', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '1', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '1', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '1', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '1', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '1', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '1', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '1', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '1', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '1', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '1', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '10', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '14', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25009', '250', '15', 'Russian Federation', 'Skylink (Tele2)'],
  ['25011', '250', '15', 'Russian Federation', 'Megafon'],
  ['25011', '250', '15', 'Russian Federation', 'Megafon'],
  ['25011', '250', '15', 'Russian Federation', 'Megafon'],
  ['25011', '250', '15', 'Russian Federation', 'Megafon'],
  ['25011', '250', '15', 'Russian Federation', 'Megafon'],
  ['25011', '250', '15', 'Russian Federation', 'Megafon'],
  ['25011', '250', '15', 'Russian Federation', 'Megafon'],
  ['25011', '250', '15', 'Russian Federation', 'Megafon'],
  ['25011', '250', '15', 'Russian Federation', 'Megafon'],
  ['25011', '250', '15', 'Russian Federation', 'Megafon'],
  ['25011', '250', '15', 'Russian Federation', 'Megafon'],
  ['25011', '250', '15', 'Russian Federation', 'Megafon'],
  ['25011', '250', '15', 'Russian Federation', 'Megafon'],
  ['25011', '250', '15', 'Russian Federation', 'Megafon'],
  ['25011', '250', '15', 'Russian Federation', 'Megafon'],
  ['25011', '250', '15', 'Russian Federation', 'Megafon'],
  ['25011', '250', '15', 'Russian Federation', 'Megafon'],
  ['25011', '250', '15', 'Russian Federation', 'Megafon'],
  ['25011', '250', '15', 'Russian Federation', 'Megafon'],
  ['25011', '250', '15', 'Russian Federation', 'Megafon'],
  ['25011', '250', '15', 'Russian Federation', 'Megafon'],
  ['25011', '250', '15', 'Russian Federation', 'Megafon'],
  ['25011', '250', '15', 'Russian Federation', 'Megafon'],
  ['25011', '250', '15', 'Russian Federation', 'Megafon'],
  ['25011', '250', '15', 'Russian Federation', 'Megafon'],
  ['25011', '250', '15', 'Russian Federation', 'Megafon'],
  ['25011', '250', '15', 'Russian Federation', 'Megafon'],
  ['25011', '250', '15', 'Russian Federation', 'Megafon'],
  ['25011', '250', '15', 'Russian Federation', 'Megafon'],
  ['25011', '250', '15', 'Russian Federation', 'Megafon'],
  ['25011', '250', '15', 'Russian Federation', 'Megafon'],
  ['25011', '250', '15', 'Russian Federation', 'Megafon'],
  ['25011', '250', '15', 'Russian Federation', 'Megafon'],
  ['25011', '250', '15', 'Russian Federation', 'Megafon'],
  ['25011', '250', '15', 'Russian Federation', 'Megafon'],
  ['25011', '250', '15', 'Russian Federation', 'Megafon'],
  ['25011', '250', '15', 'Russian Federation', 'Megafon'],
  ['25011', '250', '15', 'Russian Federation', 'Megafon'],
  ['25011', '250', '15', 'Russian Federation', 'Megafon'],
  ['25011', '250', '15', 'Russian Federation', 'Megafon'],
  ['25011', '250', '15', 'Russian Federation', 'Megafon'],
  ['25011', '250', '15', 'Russian Federation', 'Megafon'],
  ['25011', '250', '15', 'Russian Federation', 'Megafon'],
  ['25011', '250', '15', 'Russian Federation', 'Megafon'],
  ['25011', '250', '15', 'Russian Federation', 'Megafon'],
  ['25011', '250', '15', 'Russian Federation', 'Megafon'],
  ['25011', '250', '15', 'Russian Federation', 'Megafon'],
  ['25011', '250', '15', 'Russian Federation', 'Megafon'],
  ['25011', '250', '15', 'Russian Federation', 'Megafon'],
  ['25011', '250', '15', 'Russian Federation', 'Megafon'],
  ['25011', '250', '15', 'Russian Federation', 'Megafon'],
  ['25011', '250', '15', 'Russian Federation', 'Megafon'],
  ['25011', '250', '15', 'Russian Federation', 'Megafon'],
  ['25011', '250', '15', 'Russian Federation', 'Megafon'],
  ['25011', '250', '15', 'Russian Federation', 'Megafon'],
  ['25011', '250', '15', 'Russian Federation', 'Megafon'],
  ['25011', '250', '15', 'Russian Federation', 'Megafon'],
  ['25011', '250', '15', 'Russian Federation', 'Megafon'],
  ['25011', '250', '15', 'Russian Federation', 'Megafon'],
  ['25011', '250', '15', 'Russian Federation', 'Megafon'],
  ['25011', '250', '15', 'Russian Federation', 'Megafon'],
  ['25011', '250', '15', 'Russian Federation', 'Megafon'],
  ['25011', '250', '15', 'Russian Federation', 'Megafon'],
  ['25011', '250', '15', 'Russian Federation', 'Megafon'],
  ['25011', '250', '15', 'Russian Federation', 'Megafon'],
  ['25011', '250', '15', 'Russian Federation', 'Megafon'],
  ['25011', '250', '15', 'Russian Federation', 'Megafon'],
  ['25011', '250', '15', 'Russian Federation', 'Megafon'],
  ['25011', '250', '15', 'Russian Federation', 'Megafon'],
  ['25011', '250', '15', 'Russian Federation', 'Megafon'],
  ['25011', '250', '15', 'Russian Federation', 'Megafon'],
  ['25011', '250', '15', 'Russian Federation', 'Megafon'],
  ['25011', '250', '15', 'Russian Federation', 'Megafon'],
  ['25011', '250', '15', 'Russian Federation', 'Megafon'],
  ['25011', '250', '15', 'Russian Federation', 'Megafon'],
  ['25011', '250', '15', 'Russian Federation', 'Megafon'],
  ['25011', '250', '15', 'Russian Federation', 'Megafon'],
  ['25011', '250', '15', 'Russian Federation', 'Megafon'],
  ['25011', '250', '15', 'Russian Federation', 'Megafon'],
  ['25011', '250', '15', 'Russian Federation', 'Megafon'],
  ['25011', '250', '15', 'Russian Federation', 'Megafon'],
  ['25011', '250', '15', 'Russian Federation', 'Megafon'],
  ['25011', '250', '15', 'Russian Federation', 'Megafon'],
  ['25011', '250', '15', 'Russian Federation', 'Megafon'],
  ['25011', '250', '15', 'Russian Federation', 'Megafon'],
  ['25011', '250', '15', 'Russian Federation', 'Megafon'],
  ['25011', '250', '15', 'Russian Federation', 'Megafon'],
  ['25011', '250', '15', 'Russian Federation', 'Megafon'],
  ['25011', '250', '15', 'Russian Federation', 'Megafon'],
  ['25011', '250', '15', 'Russian Federation', 'Megafon'],
  ['25011', '250', '15', 'Russian Federation', 'Megafon'],
  ['25011', '250', '15', 'Russian Federation', 'Megafon'],
  ['25011', '250', '15', 'Russian Federation', 'Megafon'],
  ['25011', '250', '15', 'Russian Federation', 'Megafon'],
  ['25011', '250', '15', 'Russian Federation', 'Megafon'],
  ['25011', '250', '15', 'Russian Federation', 'Megafon'],
  ['25011', '250', '15', 'Russian Federation', 'Megafon'],
  ['25011', '250', '15', 'Russian Federation', 'Megafon'],
  ['25011', '250', '15', 'Russian Federation', 'Megafon'],
  ['25011', '250', '15', 'Russian Federation', 'Megafon'],
  ['25011', '250', '15', 'Russian Federation', 'Megafon'],
  ['25011', '250', '15', 'Russian Federation', 'Megafon'],
  ['25011', '250', '15', 'Russian Federation', 'Megafon'],
  ['25011', '250', '15', 'Russian Federation', 'Megafon'],
  ['25011', '250', '15', 'Russian Federation', 'Megafon'],
  ['25011', '250', '15', 'Russian Federation', 'Megafon'],
  ['25011', '250', '15', 'Russian Federation', 'Megafon'],
  ['25011', '250', '15', 'Russian Federation', 'Megafon'],
  ['25011', '250', '15', 'Russian Federation', 'Megafon'],
  ['25015', '250', '15', 'Russian Federation', 'Smarts'],
  ['25015', '250', '15', 'Russian Federation', 'Smarts'],
  ['25015', '250', '15', 'Russian Federation', 'Smarts'],
  ['25015', '250', '15', 'Russian Federation', 'Smarts'],
  ['25015', '250', '15', 'Russian Federation', 'Smarts'],
  ['25015', '250', '15', 'Russian Federation', 'Smarts'],
  ['25015', '250', '15', 'Russian Federation', 'Smarts'],
  ['25015', '250', '15', 'Russian Federation', 'Smarts'],
  ['25015', '250', '15', 'Russian Federation', 'Smarts'],
  ['25015', '250', '15', 'Russian Federation', 'Smarts'],
  ['25015', '250', '15', 'Russian Federation', 'Smarts'],
  ['25015', '250', '15', 'Russian Federation', 'Smarts'],
  ['25015', '250', '15', 'Russian Federation', 'Smarts'],
  ['25015', '250', '15', 'Russian Federation', 'Smarts'],
  ['25015', '250', '15', 'Russian Federation', 'Smarts'],
  ['25015', '250', '15', 'Russian Federation', 'Smarts'],
  ['25015', '250', '15', 'Russian Federation', 'Smarts'],
  ['25015', '250', '15', 'Russian Federation', 'Smarts'],
  ['25015', '250', '15', 'Russian Federation', 'Smarts'],
  ['25015', '250', '15', 'Russian Federation', 'Smarts'],
  ['25015', '250', '15', 'Russian Federation', 'Smarts'],
  ['25015', '250', '15', 'Russian Federation', 'Smarts'],
  ['25015', '250', '15', 'Russian Federation', 'Smarts'],
  ['25015', '250', '15', 'Russian Federation', 'Smarts'],
  ['25015', '250', '15', 'Russian Federation', 'Smarts'],
  ['25015', '250', '15', 'Russian Federation', 'Smarts'],
  ['25015', '250', '15', 'Russian Federation', 'Smarts'],
  ['25015', '250', '15', 'Russian Federation', 'Smarts'],
  ['25015', '250', '15', 'Russian Federation', 'Smarts'],
  ['25015', '250', '15', 'Russian Federation', 'Smarts'],
  ['25015', '250', '15', 'Russian Federation', 'Smarts'],
  ['25015', '250', '15', 'Russian Federation', 'Smarts'],
  ['25015', '250', '15', 'Russian Federation', 'Smarts'],
  ['25015', '250', '15', 'Russian Federation', 'Smarts'],
  ['25015', '250', '15', 'Russian Federation', 'Smarts'],
  ['25015', '250', '15', 'Russian Federation', 'Smarts'],
  ['25015', '250', '15', 'Russian Federation', 'Smarts'],
  ['25015', '250', '15', 'Russian Federation', 'Smarts'],
  ['25015', '250', '15', 'Russian Federation', 'Smarts'],
  ['25015', '250', '15', 'Russian Federation', 'Smarts'],
  ['25015', '250', '15', 'Russian Federation', 'Smarts'],
  ['25015', '250', '15', 'Russian Federation', 'Smarts'],
  ['25015', '250', '15', 'Russian Federation', 'Smarts'],
  ['25015', '250', '15', 'Russian Federation', 'Smarts'],
  ['25015', '250', '15', 'Russian Federation', 'Smarts'],
  ['25015', '250', '15', 'Russian Federation', 'Smarts'],
  ['25015', '250', '15', 'Russian Federation', 'Smarts'],
  ['25015', '250', '15', 'Russian Federation', 'Smarts'],
  ['25015', '250', '15', 'Russian Federation', 'Smarts'],
  ['25015', '250', '15', 'Russian Federation', 'Smarts'],
  ['25015', '250', '15', 'Russian Federation', 'Smarts'],
  ['25015', '250', '15', 'Russian Federation', 'Smarts'],
  ['25015', '250', '15', 'Russian Federation', 'Smarts'],
  ['25015', '250', '15', 'Russian Federation', 'Smarts'],
  ['25015', '250', '15', 'Russian Federation', 'Smarts'],
  ['25015', '250', '15', 'Russian Federation', 'Smarts'],
  ['25015', '250', '15', 'Russian Federation', 'Smarts'],
  ['25015', '250', '15', 'Russian Federation', 'Smarts'],
  ['25015', '250', '15', 'Russian Federation', 'Smarts'],
  [
    '25022',
    '250',
    '15',
    'Russian Federation',
    'RT Mobile - Rostelecom - Akos - Utel (Tele2)'
  ],
  [
    '25022',
    '250',
    '15',
    'Russian Federation',
    'RT Mobile - Rostelecom - Akos - Utel (Tele2)'
  ],
  [
    '25022',
    '250',
    '15',
    'Russian Federation',
    'RT Mobile - Rostelecom - Akos - Utel (Tele2)'
  ],
  ['25035', '250', '15', 'Russian Federation', 'MOTIV  Ekaterinburg2000'],
  ['25035', '250', '15', 'Russian Federation', 'MOTIV  Ekaterinburg2000'],
  ['25035', '250', '15', 'Russian Federation', 'MOTIV  Ekaterinburg2000'],
  ['25035', '250', '15', 'Russian Federation', 'MOTIV  Ekaterinburg2000'],
  ['25035', '250', '15', 'Russian Federation', 'MOTIV  Ekaterinburg2000'],
  ['25035', '250', '15', 'Russian Federation', 'MOTIV  Ekaterinburg2000'],
  ['25035', '250', '15', 'Russian Federation', 'MOTIV  Ekaterinburg2000'],
  ['25035', '250', '15', 'Russian Federation', 'MOTIV  Ekaterinburg2000'],
  ['25035', '250', '15', 'Russian Federation', 'MOTIV  Ekaterinburg2000'],
  ['25035', '250', '15', 'Russian Federation', 'MOTIV  Ekaterinburg2000'],
  ['25035', '250', '15', 'Russian Federation', 'MOTIV  Ekaterinburg2000'],
  ['25035', '250', '15', 'Russian Federation', 'MOTIV  Ekaterinburg2000'],
  ['25035', '250', '15', 'Russian Federation', 'MOTIV  Ekaterinburg2000'],
  ['25035', '250', '15', 'Russian Federation', 'MOTIV  Ekaterinburg2000'],
  ['25035', '250', '15', 'Russian Federation', 'MOTIV  Ekaterinburg2000'],
  ['25035', '250', '15', 'Russian Federation', 'MOTIV  Ekaterinburg2000'],
  ['25035', '250', '15', 'Russian Federation', 'MOTIV  Ekaterinburg2000'],
  ['25035', '250', '15', 'Russian Federation', 'MOTIV  Ekaterinburg2000'],
  ['25035', '250', '15', 'Russian Federation', 'MOTIV  Ekaterinburg2000'],
  ['25035', '250', '15', 'Russian Federation', 'MOTIV  Ekaterinburg2000'],
  ['25035', '250', '15', 'Russian Federation', 'MOTIV  Ekaterinburg2000'],
  ['25035', '250', '15', 'Russian Federation', 'MOTIV  Ekaterinburg2000'],
  ['25035', '250', '15', 'Russian Federation', 'MOTIV  Ekaterinburg2000'],
  ['25035', '250', '15', 'Russian Federation', 'MOTIV  Ekaterinburg2000'],
  ['25035', '250', '15', 'Russian Federation', 'MOTIV  Ekaterinburg2000'],
  ['25035', '250', '15', 'Russian Federation', 'MOTIV  Ekaterinburg2000'],
  ['25035', '250', '15', 'Russian Federation', 'MOTIV  Ekaterinburg2000'],
  ['25035', '250', '15', 'Russian Federation', 'MOTIV  Ekaterinburg2000'],
  ['25035', '250', '15', 'Russian Federation', 'MOTIV  Ekaterinburg2000'],
  ['25035', '250', '15', 'Russian Federation', 'MOTIV  Ekaterinburg2000'],
  ['25035', '250', '15', 'Russian Federation', 'MOTIV  Ekaterinburg2000'],
  ['25035', '250', '15', 'Russian Federation', 'MOTIV  Ekaterinburg2000'],
  ['25035', '250', '15', 'Russian Federation', 'MOTIV  Ekaterinburg2000'],
  ['25035', '250', '15', 'Russian Federation', 'MOTIV  Ekaterinburg2000'],
  ['25035', '250', '15', 'Russian Federation', 'MOTIV  Ekaterinburg2000'],
  ['25035', '250', '15', 'Russian Federation', 'MOTIV  Ekaterinburg2000'],
  ['25035', '250', '15', 'Russian Federation', 'MOTIV  Ekaterinburg2000'],
  ['25035', '250', '15', 'Russian Federation', 'MOTIV  Ekaterinburg2000'],
  ['25035', '250', '15', 'Russian Federation', 'MOTIV  Ekaterinburg2000'],
  ['25035', '250', '15', 'Russian Federation', 'MOTIV  Ekaterinburg2000'],
  ['25035', '250', '15', 'Russian Federation', 'MOTIV  Ekaterinburg2000'],
  ['25035', '250', '15', 'Russian Federation', 'MOTIV  Ekaterinburg2000'],
  ['25035', '250', '15', 'Russian Federation', 'MOTIV  Ekaterinburg2000'],
  ['25035', '250', '15', 'Russian Federation', 'MOTIV  Ekaterinburg2000'],
  ['25035', '250', '15', 'Russian Federation', 'MOTIV  Ekaterinburg2000'],
  ['25035', '250', '15', 'Russian Federation', 'MOTIV  Ekaterinburg2000'],
  ['25035', '250', '15', 'Russian Federation', 'MOTIV  Ekaterinburg2000'],
  ['25035', '250', '15', 'Russian Federation', 'MOTIV  Ekaterinburg2000'],
  ['25035', '250', '15', 'Russian Federation', 'MOTIV  Ekaterinburg2000'],
  ['25035', '250', '15', 'Russian Federation', 'MOTIV  Ekaterinburg2000'],
  ['25035', '250', '15', 'Russian Federation', 'MOTIV  Ekaterinburg2000'],
  ['25035', '250', '15', 'Russian Federation', 'MOTIV  Ekaterinburg2000'],
  ['25035', '250', '15', 'Russian Federation', 'MOTIV  Ekaterinburg2000'],
  ['25035', '250', '15', 'Russian Federation', 'MOTIV  Ekaterinburg2000'],
  ['25035', '250', '15', 'Russian Federation', 'MOTIV  Ekaterinburg2000'],
  ['25035', '250', '15', 'Russian Federation', 'MOTIV  Ekaterinburg2000'],
  ['25035', '250', '15', 'Russian Federation', 'MOTIV  Ekaterinburg2000'],
  ['25050', '250', '15', 'Russian Federation', 'Sberbank Telecom'],
  ['25050', '250', '15', 'Russian Federation', 'Sberbank Telecom'],
  ['25050', '250', '15', 'Russian Federation', 'Sberbank Telecom'],
  ['25050', '250', '15', 'Russian Federation', 'Sberbank Telecom'],
  ['25050', '250', '15', 'Russian Federation', 'Sberbank Telecom'],
  ['25050', '250', '15', 'Russian Federation', 'Sberbank Telecom'],
  ['25050', '250', '15', 'Russian Federation', 'Sberbank Telecom'],
  ['25050', '250', '15', 'Russian Federation', 'Sberbank Telecom'],
  ['25050', '250', '15', 'Russian Federation', 'Sberbank Telecom'],
  ['25050', '250', '15', 'Russian Federation', 'Sberbank Telecom'],
  ['25050', '250', '15', 'Russian Federation', 'Sberbank Telecom'],
  ['25050', '250', '15', 'Russian Federation', 'Sberbank Telecom'],
  ['25050', '250', '15', 'Russian Federation', 'Sberbank Telecom'],
  ['25050', '250', '15', 'Russian Federation', 'Sberbank Telecom'],
  ['25050', '250', '15', 'Russian Federation', 'Sberbank Telecom'],
  ['25050', '250', '15', 'Russian Federation', 'Sberbank Telecom'],
  ['25050', '250', '15', 'Russian Federation', 'Sberbank Telecom'],
  ['25050', '250', '15', 'Russian Federation', 'Sberbank Telecom'],
  ['25050', '250', '15', 'Russian Federation', 'Sberbank Telecom'],
  ['25050', '250', '15', 'Russian Federation', 'Sberbank Telecom'],
  ['25050', '250', '15', 'Russian Federation', 'Sberbank Telecom'],
  ['25050', '250', '15', 'Russian Federation', 'Sberbank Telecom'],
  ['25050', '250', '15', 'Russian Federation', 'Sberbank Telecom'],
  ['25050', '250', '15', 'Russian Federation', 'Sberbank Telecom'],
  ['25050', '250', '15', 'Russian Federation', 'Sberbank Telecom'],
  ['25050', '250', '15', 'Russian Federation', 'Sberbank Telecom'],
  ['25050', '250', '15', 'Russian Federation', 'Sberbank Telecom'],
  ['25050', '250', '15', 'Russian Federation', 'Sberbank Telecom'],
  ['25050', '250', '15', 'Russian Federation', 'Sberbank Telecom'],
  ['25050', '250', '15', 'Russian Federation', 'Sberbank Telecom'],
  ['25050', '250', '15', 'Russian Federation', 'Sberbank Telecom'],
  ['25050', '250', '15', 'Russian Federation', 'Sberbank Telecom'],
  ['25050', '250', '15', 'Russian Federation', 'Sberbank Telecom'],
  ['25050', '250', '15', 'Russian Federation', 'Sberbank Telecom'],
  ['25050', '250', '15', 'Russian Federation', 'Sberbank Telecom'],
  ['25050', '250', '15', 'Russian Federation', 'Sberbank Telecom'],
  ['25050', '250', '15', 'Russian Federation', 'Sberbank Telecom'],
  ['25050', '250', '15', 'Russian Federation', 'Sberbank Telecom'],
  ['25050', '250', '15', 'Russian Federation', 'Sberbank Telecom'],
  ['25050', '250', '15', 'Russian Federation', 'Sberbank Telecom'],
  ['25050', '250', '15', 'Russian Federation', 'Sberbank Telecom'],
  ['25050', '250', '15', 'Russian Federation', 'Sberbank Telecom'],
  ['25050', '250', '15', 'Russian Federation', 'Sberbank Telecom'],
  ['25050', '250', '15', 'Russian Federation', 'Sberbank Telecom'],
  ['25050', '250', '15', 'Russian Federation', 'Sberbank Telecom'],
  ['25050', '250', '15', 'Russian Federation', 'Sberbank Telecom'],
  ['25050', '250', '15', 'Russian Federation', 'Sberbank Telecom'],
  ['25050', '250', '15', 'Russian Federation', 'Sberbank Telecom'],
  ['25050', '250', '15', 'Russian Federation', 'Sberbank Telecom'],
  ['25050', '250', '15', 'Russian Federation', 'Sberbank Telecom'],
  ['25050', '250', '15', 'Russian Federation', 'Sberbank Telecom'],
  ['25050', '250', '15', 'Russian Federation', 'Sberbank Telecom'],
  ['25050', '250', '15', 'Russian Federation', 'Sberbank Telecom'],
  ['25050', '250', '15', 'Russian Federation', 'Sberbank Telecom'],
  ['25050', '250', '15', 'Russian Federation', 'Sberbank Telecom'],
  ['25050', '250', '15', 'Russian Federation', 'Sberbank Telecom'],
  ['25050', '250', '15', 'Russian Federation', 'Sberbank Telecom'],
  ['25050', '250', '15', 'Russian Federation', 'Sberbank Telecom'],
  ['25050', '250', '15', 'Russian Federation', 'Sberbank Telecom'],
  ['6350', '635', '15', 'Rwanda', 'DEFAULT RWANDA'],
  ['63510', '635', '15', 'Rwanda', 'MTN RWANDA CELL'],
  ['4200', '420', '15', 'Saudi Arabia', 'DEFAULT SAUDI ARABIA'],
  ['42001', '420', '15', 'Saudi Arabia', 'STC'],
  ['42001', '420', '15', 'Saudi Arabia', 'STC'],
  ['42001', '420', '15', 'Saudi Arabia', 'STC'],
  ['42003', '420', '15', 'Saudi Arabia', 'MOBILY'],
  ['42003', '420', '15', 'Saudi Arabia', 'MOBILY'],
  ['42004', '420', '15', 'Saudi Arabia', 'ZAIN'],
  ['42004', '420', '15', 'Saudi Arabia', 'ZAIN'],
  ['42005', '420', '15', 'Saudi Arabia', 'Saudi Telecom (STC) - Al Jawwal'],
  ['42005', '420', '15', 'Saudi Arabia', 'Saudi Telecom (STC) - Al Jawwal'],
  ['42005', '420', '15', 'Saudi Arabia', 'Saudi Telecom (STC) - Al Jawwal'],
  ['42005', '420', '15', 'Saudi Arabia', 'Saudi Telecom (STC) - Al Jawwal'],
  ['42006', '420', '15', 'Saudi Arabia', 'Etisalat (Mobily)'],
  ['6080', '608', '15', 'Senegal', 'DEFAULT SENEGAL'],
  ['60801', '608', '15', 'Senegal', 'SONATEL'],
  ['60801', '608', '15', 'Senegal', 'SONATEL'],
  ['60801', '608', '15', 'Senegal', 'SONATEL'],
  ['60801', '608', '15', 'Senegal', 'SONATEL'],
  ['60801', '608', '15', 'Senegal', 'SONATEL'],
  ['60801', '608', '15', 'Senegal', 'SONATEL'],
  ['60801', '608', '15', 'Senegal', 'SONATEL'],
  ['60801', '608', '15', 'Senegal', 'SONATEL'],
  ['60801', '608', '15', 'Senegal', 'SONATEL'],
  ['60801', '608', '15', 'Senegal', 'SONATEL'],
  ['60801', '608', '15', 'Senegal', 'SONATEL'],
  ['60801', '608', '15', 'Senegal', 'SONATEL'],
  ['60801', '608', '15', 'Senegal', 'SONATEL'],
  ['60801', '608', '15', 'Senegal', 'SONATEL'],
  ['60801', '608', '15', 'Senegal', 'SONATEL'],
  ['60801', '608', '15', 'Senegal', 'SONATEL'],
  ['60801', '608', '15', 'Senegal', 'SONATEL'],
  ['60802', '608', '15', 'Senegal', 'SENTEL'],
  ['60802', '608', '15', 'Senegal', 'SENTEL'],
  ['60802', '608', '15', 'Senegal', 'SENTEL'],
  ['60802', '608', '15', 'Senegal', 'SENTEL'],
  ['60802', '608', '15', 'Senegal', 'SENTEL'],
  ['60802', '608', '15', 'Senegal', 'SENTEL'],
  ['60802', '608', '15', 'Senegal', 'SENTEL'],
  ['60802', '608', '15', 'Senegal', 'SENTEL'],
  ['60802', '608', '15', 'Senegal', 'SENTEL'],
  ['60803', '608', '15', 'Senegal', 'EXPRESSO'],
  ['2200', '220', '15', 'Serbia', 'DEFAULT SERBIA AND MONTENEGRO'],
  ['22001', '220', '15', 'Serbia', 'MOBTEL'],
  ['22001', '220', '15', 'Serbia', 'TELENOR'],
  ['22002', '220', '15', 'Serbia', 'MONTENEGROPROMONTE'],
  ['22003', '220', '15', 'Serbia', 'TELECOM SRBIJA'],
  ['22003', '220', '15', 'Serbia', 'TELECOM SRBIJA'],
  ['22003', '220', '15', 'Serbia', 'TELECOM SRBIJA'],
  ['22004', '220', '15', 'Serbia', 'MONET'],
  ['22005', '220', '15', 'Serbia', 'VIP MOBILE'],
  ['22005', '220', '15', 'Serbia', 'VIP MOBILE'],
  ['22005', '220', '15', 'Serbia', 'VIP MOBILE'],
  ['6330', '633', '15', 'Seychelles', 'DEFAULT SEYCHELLES'],
  ['63301', '633', '15', 'Seychelles', 'CABLE & WIRELESS'],
  ['63310', '633', '15', 'Seychelles', 'AIRTEL TELECOM'],
  ['6190', '619', '15', 'Sierra Leone', 'DEFAULT SIERRA LEONE'],
  ['61901', '619', '15', 'Sierra Leone', 'CELTEL'],
  ['61902', '619', '15', 'Sierra Leone', 'Millicom (Tigo)'],
  ['61902', '619', '15', 'Sierra Leone', 'Millicom (Tigo)'],
  ['61902', '619', '15', 'Sierra Leone', 'Millicom (Tigo)'],
  ['61902', '619', '15', 'Sierra Leone', 'Millicom (Tigo)'],
  ['61902', '619', '15', 'Sierra Leone', 'Millicom (Tigo)'],
  ['61903', '619', '15', 'Sierra Leone', 'Africell'],
  ['61903', '619', '15', 'Sierra Leone', 'Africell'],
  ['61903', '619', '15', 'Sierra Leone', 'Africell'],
  ['61903', '619', '15', 'Sierra Leone', 'Africell'],
  ['61903', '619', '15', 'Sierra Leone', 'Africell'],
  ['61905', '619', '15', 'Sierra Leone', 'Africell'],
  ['61905', '619', '15', 'Sierra Leone', 'Africell'],
  ['61905', '619', '15', 'Sierra Leone', 'Africell'],
  ['61905', '619', '15', 'Sierra Leone', 'Africell'],
  ['61905', '619', '15', 'Sierra Leone', 'Africell'],
  ['61906', '619', '15', 'Sierra Leone', 'Sierratel'],
  ['61907', '619', '15', 'Sierra Leone', 'QCell'],
  ['61907', '619', '15', 'Sierra Leone', 'QCell'],
  ['5250', '525', '15', 'Singapore', 'DEFAULT SINGAPORE'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52501', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM900, 3G)'],
  ['52502', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM1800)'],
  ['52502', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM1800)'],
  ['52502', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM1800)'],
  ['52502', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM1800)'],
  ['52502', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM1800)'],
  ['52502', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM1800)'],
  ['52502', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM1800)'],
  ['52502', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM1800)'],
  ['52502', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM1800)'],
  ['52502', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM1800)'],
  ['52502', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM1800)'],
  ['52502', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM1800)'],
  ['52502', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM1800)'],
  ['52502', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM1800)'],
  ['52502', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM1800)'],
  ['52502', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM1800)'],
  ['52502', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM1800)'],
  ['52502', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM1800)'],
  ['52502', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM1800)'],
  ['52502', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM1800)'],
  ['52502', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM1800)'],
  ['52502', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM1800)'],
  ['52502', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM1800)'],
  ['52502', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM1800)'],
  ['52502', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM1800)'],
  ['52502', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM1800)'],
  ['52502', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM1800)'],
  ['52502', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM1800)'],
  ['52502', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM1800)'],
  ['52502', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM1800)'],
  ['52502', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM1800)'],
  ['52502', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM1800)'],
  ['52502', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM1800)'],
  ['52502', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM1800)'],
  ['52502', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM1800)'],
  ['52502', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM1800)'],
  ['52502', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM1800)'],
  ['52502', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM1800)'],
  ['52502', '525', '15', 'Singapore', 'SINGTEL MOBILE (GSM1800)'],
  ['52503', '525', '15', 'Singapore', 'MOBILEONE (M1)'],
  ['52503', '525', '15', 'Singapore', 'MOBILEONE (M1)'],
  ['52503', '525', '15', 'Singapore', 'MOBILEONE (M1)'],
  ['52503', '525', '15', 'Singapore', 'MOBILEONE (M1)'],
  ['52503', '525', '15', 'Singapore', 'MOBILEONE (M1)'],
  ['52503', '525', '15', 'Singapore', 'MOBILEONE (M1)'],
  ['52503', '525', '15', 'Singapore', 'MOBILEONE (M1)'],
  ['52503', '525', '15', 'Singapore', 'MOBILEONE (M1)'],
  ['52503', '525', '15', 'Singapore', 'MOBILEONE (M1)'],
  ['52503', '525', '15', 'Singapore', 'MOBILEONE (M1)'],
  ['52503', '525', '15', 'Singapore', 'MOBILEONE (M1)'],
  ['52503', '525', '15', 'Singapore', 'MOBILEONE (M1)'],
  ['52503', '525', '15', 'Singapore', 'MOBILEONE (M1)'],
  ['52503', '525', '15', 'Singapore', 'MOBILEONE (M1)'],
  ['52503', '525', '15', 'Singapore', 'MOBILEONE (M1)'],
  ['52503', '525', '15', 'Singapore', 'MOBILEONE (M1)'],
  ['52503', '525', '15', 'Singapore', 'MOBILEONE (M1)'],
  ['52503', '525', '15', 'Singapore', 'MOBILEONE (M1)'],
  ['52503', '525', '15', 'Singapore', 'MOBILEONE (M1)'],
  ['52503', '525', '15', 'Singapore', 'MOBILEONE (M1)'],
  ['52503', '525', '15', 'Singapore', 'MOBILEONE (M1)'],
  ['52503', '525', '15', 'Singapore', 'MOBILEONE (M1)'],
  ['52503', '525', '15', 'Singapore', 'MOBILEONE (M1)'],
  ['52503', '525', '15', 'Singapore', 'MOBILEONE (M1)'],
  ['52503', '525', '15', 'Singapore', 'MOBILEONE (M1)'],
  ['52503', '525', '15', 'Singapore', 'MOBILEONE (M1)'],
  ['52503', '525', '15', 'Singapore', 'MOBILEONE (M1)'],
  ['52503', '525', '15', 'Singapore', 'MOBILEONE (M1)'],
  ['52503', '525', '15', 'Singapore', 'MOBILEONE (M1)'],
  ['52503', '525', '15', 'Singapore', 'MOBILEONE (M1)'],
  ['52503', '525', '15', 'Singapore', 'MOBILEONE (M1)'],
  ['52503', '525', '15', 'Singapore', 'MOBILEONE (M1)'],
  ['52503', '525', '15', 'Singapore', 'MOBILEONE (M1)'],
  ['52503', '525', '15', 'Singapore', 'MOBILEONE (M1)'],
  ['52503', '525', '15', 'Singapore', 'MOBILEONE (M1)'],
  ['52503', '525', '15', 'Singapore', 'MOBILEONE (M1)'],
  ['52503', '525', '15', 'Singapore', 'MOBILEONE (M1)'],
  ['52503', '525', '15', 'Singapore', 'MOBILEONE (M1)'],
  ['52503', '525', '15', 'Singapore', 'MOBILEONE (M1)'],
  ['52503', '525', '15', 'Singapore', 'MOBILEONE (M1)'],
  ['52503', '525', '15', 'Singapore', 'MOBILEONE (M1)'],
  ['52503', '525', '15', 'Singapore', 'MOBILEONE (M1)'],
  ['52503', '525', '15', 'Singapore', 'MOBILEONE (M1)'],
  ['52503', '525', '15', 'Singapore', 'MOBILEONE (M1)'],
  ['52503', '525', '15', 'Singapore', 'MOBILEONE (M1)'],
  ['52503', '525', '15', 'Singapore', 'MOBILEONE (M1)'],
  ['52503', '525', '15', 'Singapore', 'MOBILEONE (M1)'],
  ['52503', '525', '15', 'Singapore', 'MOBILEONE (M1)'],
  ['52503', '525', '15', 'Singapore', 'MOBILEONE (M1)'],
  ['52503', '525', '15', 'Singapore', 'MOBILEONE (M1)'],
  ['52503', '525', '15', 'Singapore', 'MOBILEONE (M1)'],
  ['52503', '525', '15', 'Singapore', 'MOBILEONE (M1)'],
  ['52503', '525', '15', 'Singapore', 'MOBILEONE (M1)'],
  ['52503', '525', '15', 'Singapore', 'MOBILEONE (M1)'],
  ['52503', '525', '15', 'Singapore', 'MOBILEONE (M1)'],
  ['52503', '525', '15', 'Singapore', 'MOBILEONE (M1)'],
  ['52503', '525', '15', 'Singapore', 'MOBILEONE (M1)'],
  ['52503', '525', '15', 'Singapore', 'MOBILEONE (M1)'],
  ['52503', '525', '15', 'Singapore', 'MOBILEONE (M1)'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52505', '525', '15', 'Singapore', 'STARHUB'],
  ['52510', '525', '15', 'Singapore', 'TPG Telecom'],
  ['52510', '525', '15', 'Singapore', 'TPG Telecom'],
  ['52510', '525', '15', 'Singapore', 'TPG Telecom'],
  ['52510', '525', '15', 'Singapore', 'TPG Telecom'],
  ['52510', '525', '15', 'Singapore', 'TPG Telecom'],
  ['52510', '525', '15', 'Singapore', 'TPG Telecom'],
  ['52510', '525', '15', 'Singapore', 'TPG Telecom'],
  ['52510', '525', '15', 'Singapore', 'TPG Telecom'],
  ['52510', '525', '15', 'Singapore', 'TPG Telecom'],
  ['52510', '525', '15', 'Singapore', 'TPG Telecom'],
  ['52510', '525', '15', 'Singapore', 'TPG Telecom'],
  ['52510', '525', '15', 'Singapore', 'TPG Telecom'],
  ['52510', '525', '15', 'Singapore', 'TPG Telecom'],
  ['52510', '525', '15', 'Singapore', 'TPG Telecom'],
  ['52510', '525', '15', 'Singapore', 'TPG Telecom'],
  ['52510', '525', '15', 'Singapore', 'TPG Telecom'],
  ['52510', '525', '15', 'Singapore', 'TPG Telecom'],
  ['52510', '525', '15', 'Singapore', 'TPG Telecom'],
  ['52510', '525', '15', 'Singapore', 'TPG Telecom'],
  ['52510', '525', '15', 'Singapore', 'TPG Telecom'],
  ['52510', '525', '15', 'Singapore', 'TPG Telecom'],
  ['52510', '525', '15', 'Singapore', 'TPG Telecom'],
  ['52510', '525', '15', 'Singapore', 'TPG Telecom'],
  ['52510', '525', '15', 'Singapore', 'TPG Telecom'],
  ['52510', '525', '15', 'Singapore', 'TPG Telecom'],
  ['52510', '525', '15', 'Singapore', 'TPG Telecom'],
  ['52510', '525', '15', 'Singapore', 'TPG Telecom'],
  ['52510', '525', '15', 'Singapore', 'TPG Telecom'],
  ['52510', '525', '15', 'Singapore', 'TPG Telecom'],
  ['52510', '525', '15', 'Singapore', 'TPG Telecom'],
  ['52510', '525', '15', 'Singapore', 'TPG Telecom'],
  ['52510', '525', '15', 'Singapore', 'TPG Telecom'],
  ['52510', '525', '15', 'Singapore', 'TPG Telecom'],
  ['52510', '525', '16', 'Singapore', 'TPG Telecom'],
  ['52510', '525', '16', 'Singapore', 'TPG Telecom'],
  ['52510', '525', '16', 'Singapore', 'TPG Telecom'],
  ['52510', '525', '17', 'Singapore', 'TPG Telecom'],
  ['52510', '525', '17', 'Singapore', 'TPG Telecom'],
  ['52510', '525', '18', 'Singapore', 'TPG Telecom'],
  ['23103', '231', '18', 'Slovakia', 'Swan'],
  ['23103', '231', '18', 'Slovakia', 'Swan'],
  ['23104', '231', '18', 'Slovakia', 'T-Mobile SK'],
  ['23104', '231', '18', 'Slovakia', 'T-Mobile SK'],
  ['23104', '231', '18', 'Slovakia', 'T-Mobile SK'],
  ['23104', '231', '18', 'Slovakia', 'T-Mobile SK'],
  ['23104', '231', '19', 'Slovakia', 'T-Mobile SK'],
  ['23104', '231', '19', 'Slovakia', 'T-Mobile SK'],
  ['23104', '231', '19', 'Slovakia', 'T-Mobile SK'],
  ['23104', '231', '2', 'Slovakia', 'T-Mobile SK'],
  ['23104', '231', '2', 'Slovakia', 'T-Mobile SK'],
  ['23104', '231', '2', 'Slovakia', 'T-Mobile SK'],
  ['23104', '231', '2', 'Slovakia', 'T-Mobile SK'],
  ['23104', '231', '2', 'Slovakia', 'T-Mobile SK'],
  ['23104', '231', '2', 'Slovakia', 'T-Mobile SK'],
  ['23106', '231', '2', 'Slovakia', 'O2 - SK'],
  ['23106', '231', '2', 'Slovakia', 'O2 - SK'],
  ['23106', '231', '2', 'Slovakia', 'O2 - SK'],
  ['23106', '231', '2', 'Slovakia', 'O2 - SK'],
  ['23106', '231', '2', 'Slovakia', 'O2 - SK'],
  ['2310', '231', '2', 'Slovak Republic', 'DEFAULT SLOVAK REPUBLIC'],
  ['23101', '231', '2', 'Slovak Republic', 'ORANGE SK'],
  ['23101', '231', '2', 'Slovak Republic', 'ORANGE SK'],
  ['23101', '231', '2', 'Slovak Republic', 'ORANGE SK'],
  ['23101', '231', '2', 'Slovak Republic', 'ORANGE SK'],
  ['23101', '231', '2', 'Slovak Republic', 'ORANGE SK'],
  ['23102', '231', '2', 'Slovak Republic', 'EUROTEL'],
  ['23102', '231', '2', 'Slovak Republic', 'EUROTEL'],
  ['23102', '231', '2', 'Slovak Republic', 'EUROTEL'],
  ['2930', '293', '2', 'Slovenia', 'DEFAULT SLOVENIA'],
  ['29340', '293', '2', 'Slovenia', 'SI.MOBIL'],
  ['29340', '293', '2', 'Slovenia', 'SI.MOBIL'],
  ['29341', '293', '2', 'Slovenia', 'MOBITEL'],
  ['29341', '293', '2', 'Slovenia', 'MOBITEL'],
  ['29341', '293', '2', 'Slovenia', 'MOBITEL'],
  ['29364', '293', '2', 'Slovenia', 'T-2'],
  ['29364', '293', '2', 'Slovenia', 'T-2'],
  ['29364', '293', '2', 'Slovenia', 'T-2'],
  ['29364', '293', '2', 'Slovenia', 'T-2'],
  ['29364', '293', '2', 'Slovenia', 'T-2'],
  ['29370', '293', '2', 'Slovenia', 'VEGA WESTERN WIRELESS'],
  ['310013', '310', '2', 'Solomon Islands', 'MobileTel'],
  ['310150', '310', '2', 'Solomon Islands', 'Cingular Wireless'],
  ['6370', '637', '2', 'Somalia', 'DEFAULT SOMALIA'],
  ['63701', '637', '2', 'Somalia', 'TELESOM'],
  ['63701', '637', '2', 'Somalia', 'TELSOM'],
  ['63701', '637', '2', 'Somalia', 'TELSOM'],
  ['63701', '637', '2', 'Somalia', 'TELSOM'],
  ['63701', '637', '2', 'Somalia', 'TELSOM'],
  ['63704', '637', '2', 'Somalia', 'Somafone'],
  ['63704', '637', '2', 'Somalia', 'Somafone'],
  ['63704', '637', '2', 'Somalia', 'Somafone'],
  ['63704', '637', '2', 'Somalia', 'Somafone'],
  ['63710', '637', '2', 'Somalia', 'NATIONLINK'],
  ['63710', '637', '2', 'Somalia', 'NATIONLINK'],
  ['63710', '637', '2', 'Somalia', 'NATIONLINK'],
  ['63710', '637', '2', 'Somalia', 'NATIONLINK'],
  ['63710', '637', '2', 'Somalia', 'NATIONLINK'],
  ['63710', '637', '2', 'Somalia', 'NATIONLINK'],
  ['63710', '637', '2', 'Somalia', 'NATIONLINK'],
  ['63710', '637', '2', 'Somalia', 'NATIONLINK'],
  ['63710', '637', '2', 'Somalia', 'NATIONLINK'],
  ['63710', '637', '2', 'Somalia', 'NATIONLINK'],
  ['63710', '637', '2', 'Somalia', 'NATIONLINK'],
  ['63710', '637', '2', 'Somalia', 'NATIONLINK'],
  ['63710', '637', '2', 'Somalia', 'NATIONLINK'],
  ['63710', '637', '2', 'Somalia', 'NATIONLINK'],
  ['63710', '637', '2', 'Somalia', 'NATIONLINK'],
  ['63725', '637', '2', 'Somalia', 'HORMUUD'],
  ['63725', '637', '2', 'Somalia', 'HORMUUD'],
  ['63725', '637', '2', 'Somalia', 'HORMUUD'],
  ['63725', '637', '2', 'Somalia', 'HORMUUD'],
  ['63725', '637', '2', 'Somalia', 'HORMUUD'],
  ['63725', '637', '2', 'Somalia', 'HORMUUD'],
  ['63725', '637', '2', 'Somalia', 'HORMUUD'],
  ['63730', '637', '2', 'Somalia', 'GOLIS'],
  ['63730', '637', '2', 'Somalia', 'GOLIS'],
  ['63730', '637', '2', 'Somalia', 'GOLIS'],
  ['63730', '637', '2', 'Somalia', 'GOLIS'],
  ['63730', '637', '2', 'Somalia', 'GOLIS'],
  ['63730', '637', '2', 'Somalia', 'GOLIS'],
  ['63760', '637', '2', 'Somalia', 'Nationlink'],
  ['63760', '637', '2', 'Somalia', 'Nationlink'],
  ['63760', '637', '2', 'Somalia', 'Nationlink'],
  ['63760', '637', '2', 'Somalia', 'Nationlink'],
  ['63771', '637', '2', 'Somalia', 'SOMTEL'],
  ['63782', '637', '2', 'Somalia', 'TELCOM'],
  ['63782', '637', '20', 'Somalia', 'TELCOM'],
  ['63782', '637', '20', 'Somalia', 'TELCOM SOMALIA'],
  ['63782', '637', '20', 'Somalia', 'TELCOM'],
  ['63782', '637', '20', 'Somalia', 'TELCOM'],
  ['63782', '637', '20', 'Somalia', 'TELCOM'],
  ['63782', '637', '20', 'Somalia', 'TELCOM'],
  ['63782', '637', '20', 'Somalia', 'TELCOM'],
  ['63782', '637', '20', 'Somalia', 'TELCOM'],
  ['63782', '637', '20', 'Somalia', 'TELCOM'],
  ['6550', '655', '20', 'South Africa', 'DEFAULT SOUTH AFRICA'],
  ['65501', '655', '20', 'South Africa', 'VODACOM'],
  ['65501', '655', '20', 'South Africa', 'VODACOM'],
  ['65501', '655', '20', 'South Africa', 'VODACOM'],
  ['65502', '655', '20', 'South Africa', 'Telkom'],
  ['65502', '655', '20', 'South Africa', 'Telkom'],
  ['65502', '655', '20', 'South Africa', 'Telkom'],
  ['65502', '655', '20', 'South Africa', 'Telkom'],
  ['65502', '655', '20', 'South Africa', 'Telkom'],
  ['65502', '655', '20', 'South Africa', 'Telkom'],
  ['65502', '655', '20', 'South Africa', 'Telkom'],
  ['65502', '655', '20', 'South Africa', 'Telkom'],
  ['65502', '655', '20', 'South Africa', 'Telkom'],
  ['65502', '655', '20', 'South Africa', 'Telkom'],
  ['65502', '655', '20', 'South Africa', 'Telkom'],
  ['65502', '655', '20', 'South Africa', 'Telkom'],
  ['65502', '655', '20', 'South Africa', 'Telkom'],
  ['65502', '655', '20', 'South Africa', 'Telkom'],
  ['65502', '655', '20', 'South Africa', 'Telkom'],
  ['65507', '655', '20', 'South Africa', 'CELL C'],
  ['65510', '655', '20', 'South Africa', 'MTN'],
  ['65510', '655', '20', 'South Africa', 'MTN'],
  ['65510', '655', '20', 'South Africa', 'MTN'],
  ['4500', '450', '20', 'South Korea', 'DEFAULT SOUTH KOREA'],
  ['45001', '450', '20', 'South Korea', 'SK TELECOM'],
  ['45001', '450', '20', 'South Korea', 'SK TELECOM'],
  ['45001', '450', '20', 'South Korea', 'SK TELECOM'],
  ['45001', '450', '20', 'South Korea', 'SK TELECOM'],
  ['45002', '450', '20', 'South Korea', 'KT FREETEL'],
  ['45002', '450', '20', 'South Korea', 'KT FREETEL'],
  ['6590', '659', '20', 'South Sudan', 'Gemtel'],
  ['6590', '659', '20', 'South Sudan', 'MTN'],
  ['6590', '659', '20', 'South Sudan', 'Zain Co. Ltd'],
  ['6590', '659', '20', 'South Sudan', 'Vivacell'],
  ['65902', '659', '20', 'South Sudan', 'MTN'],
  ['65902', '659', '20', 'South Sudan', 'MTN'],
  ['65903', '659', '20', 'South Sudan', 'Gemtel'],
  ['65904', '659', '20', 'South Sudan', 'Vivacell'],
  ['65906', '659', '20', 'South Sudan', 'Zain Co. Ltd'],
  ['2140', '214', '20', 'Spain', 'DEFAULT SPAIN'],
  ['21401', '214', '20', 'Spain', 'VODAFONE'],
  ['21401', '214', '20', 'Spain', 'VODAFONE'],
  ['21401', '214', '20', 'Spain', 'VODAFONE'],
  ['21401', '214', '20', 'Spain', 'VODAFONE'],
  ['21401', '214', '20', 'Spain', 'VODAFONE'],
  ['21401', '214', '20', 'Spain', 'VODAFONE'],
  ['21401', '214', '20', 'Spain', 'VODAFONE'],
  ['21401', '214', '20', 'Spain', 'UNALLOCATED PREFIX'],
  ['21401', '214', '20', 'Spain', 'VODAFONE'],
  ['21401', '214', '20', 'Spain', 'VODAFONE'],
  ['21401', '214', '20', 'Spain', 'VODAFONE'],
  ['21401', '214', '20', 'Spain', 'VODAFONE'],
  ['21401', '214', '20', 'Spain', 'VODAFONE'],
  ['21401', '214', '20', 'Spain', 'VODAFONE'],
  ['21401', '214', '20', 'Spain', 'VODAFONE'],
  ['21401', '214', '20', 'Spain', 'VODAFONE'],
  ['21401', '214', '20', 'Spain', 'VODAFONE'],
  ['21402', '214', '20', 'Spain', 'Fibracat (Altecom)'],
  ['21403', '214', '20', 'Spain', 'AMENA'],
  ['21403', '214', '20', 'Spain', 'AMENA'],
  ['21403', '214', '20', 'Spain', 'AMENA'],
  ['21403', '214', '20', 'Spain', 'AMENA'],
  ['21403', '214', '20', 'Spain', 'AMENA'],
  ['21403', '214', '20', 'Spain', 'AMENA'],
  ['21403', '214', '20', 'Spain', 'AMENA'],
  ['21403', '214', '20', 'Spain', 'AMENA'],
  ['21403', '214', '20', 'Spain', 'AMENA'],
  ['21403', '214', '20', 'Spain', 'AMENA'],
  ['21403', '214', '20', 'Spain', 'AMENA'],
  ['21403', '214', '20', 'Spain', 'AMENA'],
  ['21403', '214', '20', 'Spain', 'AMENA'],
  ['21403', '214', '20', 'Spain', 'AMENA'],
  ['21403', '214', '20', 'Spain', 'AMENA'],
  ['21403', '214', '20', 'Spain', 'AMENA'],
  ['21404', '214', '20', 'Spain', 'XFERA MOVILES (UMTS)'],
  ['21405', '214', '20', 'Spain', 'Movistar'],
  ['21406', '214', '20', 'Spain', 'Vodafone (Enabler)'],
  ['21407', '214', '20', 'Spain', 'TELEFONICA'],
  ['21407', '214', '20', 'Spain', 'TELEFONICA'],
  ['21407', '214', '20', 'Spain', 'TELEFONICA'],
  ['21407', '214', '20', 'Spain', 'TELEFONICA'],
  ['21407', '214', '20', 'Spain', 'TELEFONICA'],
  ['21407', '214', '20', 'Spain', 'TELEFONICA'],
  ['21407', '214', '20', 'Spain', 'TELEFONICA'],
  ['21407', '214', '20', 'Spain', 'TELEFONICA'],
  ['21407', '214', '20', 'Spain', 'TELEFONICA'],
  ['21407', '214', '20', 'Spain', 'TELEFONICA'],
  ['21407', '214', '20', 'Spain', 'TELEFONICA'],
  ['21407', '214', '20', 'Spain', 'TELEFONICA'],
  ['21407', '214', '20', 'Spain', 'TELEFONICA'],
  ['21407', '214', '20', 'Spain', 'TELEFONICA'],
  ['21407', '214', '20', 'Spain', 'TELEFONICA'],
  ['21407', '214', '20', 'Spain', 'TELEFONICA'],
  ['21407', '214', '20', 'Spain', 'TELEFONICA'],
  ['21407', '214', '20', 'Spain', 'TELEFONICA'],
  ['21407', '214', '20', 'Spain', 'TELEFONICA'],
  ['21407', '214', '20', 'Spain', 'TELEFONICA'],
  ['21407', '214', '20', 'Spain', 'TELEFONICA'],
  ['21407', '214', '20', 'Spain', 'TELEFONICA'],
  ['21407', '214', '20', 'Spain', 'TELEFONICA'],
  ['21407', '214', '20', 'Spain', 'TELEFONICA'],
  ['21407', '214', '20', 'Spain', 'TELEFONICA'],
  ['21407', '214', '20', 'Spain', 'TELEFONICA'],
  ['21407', '214', '20', 'Spain', 'TELEFONICA'],
  ['21407', '214', '20', 'Spain', 'TELEFONICA'],
  ['21408', '214', '20', 'Spain', 'Euskaltel'],
  ['21408', '214', '20', 'Spain', 'Euskaltel'],
  ['21408', '214', '20', 'Spain', 'Euskaltel'],
  ['21409', '214', '20', 'Spain', 'Suma (IOS)'],
  ['21409', '214', '20', 'Spain', 'Suma (IOS)'],
  ['21409', '214', '20', 'Spain', 'Suma (IOS)'],
  ['21409', '214', '20', 'Spain', 'Suma (IOS)'],
  ['21409', '214', '20', 'Spain', 'Suma (IOS)'],
  ['21409', '214', '20', 'Spain', 'Suma (IOS)'],
  ['21409', '214', '20', 'Spain', 'Suma (IOS)'],
  ['21409', '214', '20', 'Spain', 'Suma (IOS)'],
  ['21409', '214', '20', 'Spain', 'Suma (IOS)'],
  ['21409', '214', '20', 'Spain', 'Suma (IOS)'],
  ['21409', '214', '20', 'Spain', 'Suma (IOS)'],
  ['21409', '214', '20', 'Spain', 'Suma (IOS)'],
  ['21409', '214', '20', 'Spain', 'Suma (IOS)'],
  ['21409', '214', '20', 'Spain', 'Suma (IOS)'],
  ['21409', '214', '20', 'Spain', 'Suma (IOS)'],
  ['21409', '214', '20', 'Spain', 'Suma (IOS)'],
  ['21409', '214', '20', 'Spain', 'Suma (IOS)'],
  ['21409', '214', '20', 'Spain', 'Suma (IOS)'],
  ['21409', '214', '20', 'Spain', 'Suma (IOS)'],
  ['21409', '214', '20', 'Spain', 'Suma (IOS)'],
  ['21409', '214', '20', 'Spain', 'Suma (IOS)'],
  ['21409', '214', '20', 'Spain', 'Suma (IOS)'],
  ['21410', '214', '20', 'Spain', 'Lobster'],
  ['21411', '214', '20', 'Spain', 'Orange'],
  ['21411', '214', '20', 'Spain', 'Orange'],
  ['21411', '214', '20', 'Spain', 'Orange'],
  ['21411', '214', '20', 'Spain', 'Orange'],
  ['21411', '214', '20', 'Spain', 'Orange'],
  ['21411', '214', '20', 'Spain', 'Orange'],
  ['21411', '214', '20', 'Spain', 'Orange'],
  ['21411', '214', '20', 'Spain', 'Orange'],
  ['21411', '214', '20', 'Spain', 'Orange'],
  ['21411', '214', '20', 'Spain', 'Orange'],
  ['21411', '214', '20', 'Spain', 'Orange'],
  ['21411', '214', '20', 'Spain', 'Orange'],
  ['21411', '214', '20', 'Spain', 'Orange'],
  ['21411', '214', '20', 'Spain', 'Orange'],
  ['21411', '214', '20', 'Spain', 'Orange'],
  ['21411', '214', '20', 'Spain', 'Orange'],
  ['21411', '214', '20', 'Spain', 'Orange'],
  ['21411', '214', '20', 'Spain', 'Orange'],
  ['21411', '214', '20', 'Spain', 'Orange'],
  ['21411', '214', '20', 'Spain', 'Orange'],
  ['21411', '214', '20', 'Spain', 'Orange'],
  ['21411', '214', '20', 'Spain', 'Orange'],
  ['21415', '214', '20', 'Spain', 'BT Spain'],
  ['21415', '214', '20', 'Spain', 'BT Spain'],
  ['21416', '214', '20', 'Spain', 'Telecable'],
  ['21417', '214', '20', 'Spain', 'Mobil R'],
  ['21418', '214', '20', 'Spain', 'Vodafone ONO'],
  ['21418', '214', '20', 'Spain', 'Vodafone ONO'],
  ['21418', '214', '20', 'Spain', 'Vodafone ONO'],
  ['21418', '214', '20', 'Spain', 'Vodafone ONO'],
  ['21418', '214', '20', 'Spain', 'Vodafone ONO'],
  ['21418', '214', '20', 'Spain', 'Vodafone ONO'],
  ['21418', '214', '20', 'Spain', 'Vodafone ONO'],
  ['21418', '214', '20', 'Spain', 'Vodafone ONO'],
  ['21418', '214', '20', 'Spain', 'Vodafone ONO'],
  ['21418', '214', '20', 'Spain', 'Vodafone ONO'],
  ['21418', '214', '20', 'Spain', 'Vodafone ONO'],
  ['21418', '214', '20', 'Spain', 'Vodafone ONO'],
  ['21418', '214', '20', 'Spain', 'Vodafone ONO'],
  ['21418', '214', '20', 'Spain', 'Vodafone ONO'],
  ['21418', '214', '20', 'Spain', 'Vodafone ONO'],
  ['21418', '214', '20', 'Spain', 'Vodafone ONO'],
  ['21418', '214', '20', 'Spain', 'Vodafone ONO'],
  ['21418', '214', '20', 'Spain', 'Vodafone ONO'],
  ['21418', '214', '20', 'Spain', 'Vodafone ONO'],
  ['21418', '214', '20', 'Spain', 'Vodafone ONO'],
  ['21418', '214', '20', 'Spain', 'Vodafone ONO'],
  ['21419', '214', '20', 'Spain', 'Simyo (E-plus KPN)'],
  ['21419', '214', '20', 'Spain', 'Simyo (E-plus KPN)'],
  ['21419', '214', '20', 'Spain', 'Simyo (E-plus KPN)'],
  ['21419', '214', '20', 'Spain', 'Simyo (E-plus KPN)'],
  ['21419', '214', '20', 'Spain', 'Simyo (E-plus KPN)'],
  ['21419', '214', '20', 'Spain', 'Simyo (E-plus KPN)'],
  ['21419', '214', '20', 'Spain', 'Simyo (E-plus KPN)'],
  ['21419', '214', '20', 'Spain', 'Simyo (E-plus KPN)'],
  ['21419', '214', '20', 'Spain', 'Simyo (E-plus KPN)'],
  ['21419', '214', '20', 'Spain', 'Simyo (E-plus KPN)'],
  ['21419', '214', '20', 'Spain', 'Simyo (E-plus KPN)'],
  ['21419', '214', '20', 'Spain', 'Simyo (E-plus KPN)'],
  ['21419', '214', '20', 'Spain', 'Simyo (E-plus KPN)'],
  ['21419', '214', '20', 'Spain', 'Simyo (E-plus KPN)'],
  ['21419', '214', '20', 'Spain', 'Simyo (E-plus KPN)'],
  ['21419', '214', '20', 'Spain', 'Simyo (E-plus KPN)'],
  ['21419', '214', '20', 'Spain', 'Simyo (E-plus KPN)'],
  ['21419', '214', '20', 'Spain', 'Simyo (E-plus KPN)'],
  ['21419', '214', '20', 'Spain', 'Simyo (E-plus KPN)'],
  ['21419', '214', '20', 'Spain', 'Simyo (E-plus KPN)'],
  ['21419', '214', '20', 'Spain', 'Simyo (E-plus KPN)'],
  ['21419', '214', '20', 'Spain', 'Simyo (E-plus KPN)'],
  ['21420', '214', '20', 'Spain', 'Parlem Telecom (fonyou)'],
  ['21421', '214', '20', 'Spain', 'Jazztel'],
  ['21421', '214', '20', 'Spain', 'Jazztel'],
  ['21421', '214', '20', 'Spain', 'Jazztel'],
  ['21421', '214', '20', 'Spain', 'Jazztel'],
  ['21421', '214', '20', 'Spain', 'Jazztel'],
  ['21421', '214', '20', 'Spain', 'Jazztel'],
  ['21421', '214', '20', 'Spain', 'Jazztel'],
  ['21421', '214', '20', 'Spain', 'Jazztel'],
  ['21421', '214', '20', 'Spain', 'Jazztel'],
  ['21421', '214', '20', 'Spain', 'Jazztel'],
  ['21421', '214', '20', 'Spain', 'Jazztel'],
  ['21421', '214', '20', 'Spain', 'Jazztel'],
  ['21421', '214', '20', 'Spain', 'Jazztel'],
  ['21421', '214', '20', 'Spain', 'Jazztel'],
  ['21421', '214', '20', 'Spain', 'Jazztel'],
  ['21421', '214', '20', 'Spain', 'Jazztel'],
  ['21421', '214', '20', 'Spain', 'Jazztel'],
  ['21421', '214', '20', 'Spain', 'Jazztel'],
  ['21421', '214', '20', 'Spain', 'Jazztel'],
  ['21421', '214', '20', 'Spain', 'Jazztel'],
  ['21421', '214', '20', 'Spain', 'Jazztel'],
  ['21421', '214', '20', 'Spain', 'Jazztel'],
  ['21422', '214', '20', 'Spain', 'Digi Spain (Best)'],
  ['21422', '214', '20', 'Spain', 'Digi Spain (Best)'],
  ['21422', '214', '20', 'Spain', 'Digi Spain (Best)'],
  ['21422', '214', '20', 'Spain', 'Digi Spain (Best)'],
  ['21422', '214', '20', 'Spain', 'Digi Spain (Best)'],
  ['21422', '214', '20', 'Spain', 'Digi Spain (Best)'],
  ['21422', '214', '20', 'Spain', 'Digi Spain (Best)'],
  ['21422', '214', '20', 'Spain', 'Digi Spain (Best)'],
  ['21422', '214', '20', 'Spain', 'Digi Spain (Best)'],
  ['21424', '214', '20', 'Spain', 'Unknown-SPAIN-6341'],
  ['21425', '214', '20', 'Spain', 'Lycamobile'],
  ['21425', '214', '20', 'Spain', 'Lycamobile'],
  ['21425', '214', '20', 'Spain', 'Lycamobile'],
  ['21425', '214', '20', 'Spain', 'Lycamobile'],
  ['21425', '214', '20', 'Spain', 'Lycamobile'],
  ['21425', '214', '20', 'Spain', 'Lycamobile'],
  ['21425', '214', '20', 'Spain', 'Lycamobile'],
  ['21425', '214', '20', 'Spain', 'Lycamobile'],
  ['21425', '214', '20', 'Spain', 'Lycamobile'],
  ['21426', '214', '20', 'Spain', 'Lleida Networks'],
  ['21427', '214', '20', 'Spain', 'Truphone'],
  ['21434', '214', '20', 'Spain', 'Airenetworks'],
  ['21434', '214', '20', 'Spain', 'Airenetworks'],
  ['21436', '214', '20', 'Spain', 'Euskaltel'],
  ['21436', '214', '20', 'Spain', 'Euskaltel'],
  ['21436', '214', '20', 'Spain', 'Euskaltel'],
  ['4130', '413', '20', 'Sri Lanka', 'DEFAULT SRI LANKA'],
  ['41301', '413', '20', 'Sri Lanka', 'MOBITEL LANKA'],
  ['41302', '413', '20', 'Sri Lanka', 'DIALOG TELEKOM LTD'],
  ['41303', '413', '20', 'Sri Lanka', 'CELLTEL LANKA'],
  ['41305', '413', '20', 'Sri Lanka', 'Bharti Airtel'],
  ['41308', '413', '20', 'Sri Lanka', 'HUTCHISON'],
  ['41312', '413', '20', 'Sri Lanka', 'Dialog'],
  ['41312', '413', '20', 'Sri Lanka', 'Dialog'],
  ['310002', '310', '20', 'St. Kitts Nevis Anguilla', 'CABLE & WIRELESS'],
  ['310002', '310', '20', 'St. Kitts Nevis Anguilla', 'CABLE & WIRELESS'],
  ['310015', '310', '20', 'St. Lucia', 'CABLE & WIRELESS'],
  ['310015', '310', '20', 'St. Lucia', 'MOSSEL DIGICEL'],
  ['310015', '310', '20', 'St. Vincent Grenadines', 'CABLE & WIRELESS'],
  ['310015', '310', '20', 'St. Vincent Grenadines', 'CINGULAR WIRELESS'],
  ['310015', '310', '20', 'St. Vincent Grenadines', 'MOSSEL DIGICEL'],
  ['6340', '634', '20', 'Sudan', 'DEFAULT SUDAN'],
  ['63401', '634', '20', 'Sudan', 'MOBITEL'],
  ['63401', '634', '20', 'Sudan', 'MOBITEL'],
  ['63402', '634', '20', 'Sudan', 'AREEBA'],
  ['63403', '634', '20', 'Sudan', 'MTN'],
  ['63403', '634', '20', 'Sudan', 'MTN'],
  ['63407', '634', '20', 'Sudan', 'Sudani One (Sudatel)'],
  ['63407', '634', '20', 'Sudan', 'Sudani One (Sudatel)'],
  ['63407', '634', '20', 'Sudan', 'Sudani One (Sudatel)'],
  ['7460', '746', '20', 'Suriname', 'DEFAULT SURINAME'],
  ['74602', '746', '20', 'Suriname', 'TELESUR'],
  ['21201', '212', '20', 'Swaziland', 'VALA900'],
  ['6530', '653', '20', 'Swaziland', 'DEFAULT SWAZILAND'],
  ['65310', '653', '20', 'Swaziland', 'MTN'],
  ['65310', '653', '20', 'Swaziland', 'MTN'],
  ['65310', '653', '20', 'Swaziland', 'MTN'],
  ['65310', '653', '20', 'Swaziland', 'MTN'],
  ['2400', '240', '20', 'Sweden', 'DEFAULT SWEDEN'],
  ['24001', '240', '20', 'Sweden', 'SPINBOX'],
  ['24001', '240', '20', 'Sweden', 'TELIA SONERA'],
  ['24001', '240', '20', 'Sweden', 'TELIA SONERA'],
  ['24001', '240', '20', 'Sweden', 'TELIA SONERA'],
  ['24001', '240', '20', 'Sweden', 'TERRAFLED'],
  ['24001', '240', '20', 'Sweden', 'TELIA SONERA'],
  ['24001', '240', '20', 'Sweden', 'TELIA SONERA'],
  ['24001', '240', '20', 'Sweden', 'TELIA SONERA'],
  ['24001', '240', '20', 'Sweden', 'TELIA SONERA'],
  ['24001', '240', '20', 'Sweden', 'TELENOR'],
  ['24001', '240', '20', 'Sweden', 'TELENOR'],
  ['24001', '240', '20', 'Sweden', 'TELENOR'],
  ['24001', '240', '20', 'Sweden', 'TELIA SONERA'],
  ['24001', '240', '20', 'Sweden', 'TELIA SONERA'],
  ['24001', '240', '20', 'Sweden', 'TELIA SONERA'],
  ['24001', '240', '20', 'Sweden', 'TELIA SONERA'],
  ['24001', '240', '20', 'Sweden', 'TELIA SONERA'],
  ['24001', '240', '20', 'Sweden', 'TELIA SONERA'],
  ['24001', '240', '20', 'Sweden', 'SPINBOX'],
  ['24001', '240', '20', 'Sweden', 'SENSE COMMUNICATIONS'],
  ['24001', '240', '20', 'Sweden', 'ACN COMMUNICATIONS'],
  ['24001', '240', '20', 'Sweden', 'TIMEPIECE'],
  ['24001', '240', '20', 'Sweden', 'TELIA SONERA'],
  ['24001', '240', '20', 'Sweden', 'TELIA SONERA'],
  ['24001', '240', '20', 'Sweden', 'NEWPHONE'],
  ['24001', '240', '20', 'Sweden', 'NEWPHONE'],
  ['24001', '240', '20', 'Sweden', 'PHONERA'],
  ['24001', '240', '20', 'Sweden', 'TELIA SONERA'],
  ['24001', '240', '20', 'Sweden', 'UNALLOCATED PREFIX'],
  ['24001', '240', '20', 'Sweden', 'UNALLOCATED PREFIX'],
  ['24001', '240', '20', 'Sweden', 'UNALLOCATED PREFIX'],
  ['24001', '240', '20', 'Sweden', 'TELIA SONERA'],
  ['24001', '240', '20', 'Sweden', 'TELIA SONERA'],
  ['24001', '240', '20', 'Sweden', 'TELIA SONERA'],
  ['24001', '240', '20', 'Sweden', 'ABBLA MOBILE'],
  ['24001', '240', '20', 'Sweden', 'TELIA SONERA'],
  ['24001', '240', '20', 'Sweden', 'TELIA SONERA'],
  ['24001', '240', '20', 'Sweden', 'TELIA SONERA'],
  ['24001', '240', '20', 'Sweden', 'TELIA SONERA'],
  ['24001', '240', '20', 'Sweden', 'TELIA SONERA'],
  ['24001', '240', '20', 'Sweden', 'VATTENFALL'],
  ['24001', '240', '20', 'Sweden', 'VENTELO'],
  ['24001', '240', '20', 'Sweden', 'FIRST NEW MEDIA'],
  ['24001', '240', '20', 'Sweden', 'GOTALANDSNATET'],
  ['24001', '240', '20', 'Sweden', 'CHESS'],
  ['24001', '240', '20', 'Sweden', 'VENTELO'],
  ['24001', '240', '20', 'Sweden', 'TELIA SONERA'],
  ['24001', '240', '20', 'Sweden', 'TELIA SONERA'],
  ['24001', '240', '20', 'Sweden', 'TELIA SONERA'],
  ['24001', '240', '20', 'Sweden', 'NEWPHONE'],
  ['24001', '240', '20', 'Sweden', 'NEWPHONE'],
  ['24001', '240', '20', 'Sweden', 'NEWPHONE'],
  ['24001', '240', '20', 'Sweden', 'SPINBOX'],
  ['24001', '240', '20', 'Sweden', 'VENTELO'],
  ['24001', '240', '20', 'Sweden', 'NET NET'],
  ['24001', '240', '20', 'Sweden', 'TELIA SONERA'],
  ['24001', '240', '20', 'Sweden', 'INTELLIGENT'],
  ['24002', '240', '20', 'Sweden', 'TRE (H3G)'],
  ['24002', '240', '20', 'Sweden', 'TRE (H3G)'],
  ['24002', '240', '20', 'Sweden', 'TRE (H3G)'],
  ['24002', '240', '20', 'Sweden', 'TRE (H3G)'],
  ['24002', '240', '20', 'Sweden', 'TRE (H3G)'],
  ['24002', '240', '20', 'Sweden', 'TRE (H3G)'],
  ['24002', '240', '20', 'Sweden', 'TRE (H3G)'],
  ['24002', '240', '20', 'Sweden', 'TRE (H3G)'],
  ['24002', '240', '20', 'Sweden', 'TRE (H3G)'],
  ['24002', '240', '20', 'Sweden', 'TRE (H3G)'],
  ['24006', '240', '20', 'Sweden', 'Telenor'],
  ['24007', '240', '20', 'Sweden', 'TELE 2'],
  ['24007', '240', '20', 'Sweden', 'TELE 2'],
  ['24007', '240', '20', 'Sweden', 'TELE 2'],
  ['24007', '240', '20', 'Sweden', 'TELE 2'],
  ['24007', '240', '20', 'Sweden', 'TELE 2'],
  ['24007', '240', '20', 'Sweden', 'TELE 2'],
  ['24007', '240', '20', 'Sweden', 'OPTIMAL TELECOM'],
  ['24007', '240', '20', 'Sweden', 'TELE 2'],
  ['24007', '240', '20', 'Sweden', 'TELE 2'],
  ['24007', '240', '20', 'Sweden', 'TELE 2'],
  ['24007', '240', '20', 'Sweden', 'OPTIMAL TELECOM'],
  ['24007', '240', '20', 'Sweden', 'OPTIMAL TELECOM'],
  ['24007', '240', '20', 'Sweden', 'TELE 2'],
  ['24007', '240', '20', 'Sweden', 'TELE 2'],
  ['24007', '240', '20', 'Sweden', 'TELE 2'],
  ['24007', '240', '20', 'Sweden', 'TELE 2'],
  ['24007', '240', '20', 'Sweden', 'TELE 2'],
  ['24007', '240', '20', 'Sweden', 'TELE 2'],
  ['24007', '240', '20', 'Sweden', 'TELE 2'],
  ['24007', '240', '20', 'Sweden', 'TELE 2'],
  ['24007', '240', '20', 'Sweden', 'TELE 2'],
  ['24007', '240', '20', 'Sweden', 'TELE 2'],
  ['24007', '240', '20', 'Sweden', 'TELE 2'],
  ['24008', '240', '20', 'Sweden', 'VODAFONE'],
  ['24008', '240', '20', 'Sweden', 'VODAFONE'],
  ['24008', '240', '20', 'Sweden', 'VODAFONE'],
  ['24008', '240', '20', 'Sweden', 'VODAFONE'],
  ['24008', '240', '20', 'Sweden', 'VODAFONE'],
  ['24008', '240', '20', 'Sweden', 'VODAFONE'],
  ['24008', '240', '20', 'Sweden', 'VODAFONE'],
  ['24008', '240', '20', 'Sweden', 'VODAFONE'],
  ['24008', '240', '20', 'Sweden', 'VODAFONE'],
  ['24008', '240', '20', 'Sweden', 'VODAFONE'],
  ['24008', '240', '20', 'Sweden', 'VODAFONE'],
  ['24008', '240', '20', 'Sweden', 'VODAFONE'],
  ['24008', '240', '20', 'Sweden', 'CAMPUZ MOBILE'],
  ['24008', '240', '20', 'Sweden', 'VODAFONE'],
  ['24008', '240', '20', 'Sweden', 'CAMPUZ MOBILE'],
  ['24008', '240', '20', 'Sweden', 'GLOCALNET'],
  ['24008', '240', '20', 'Sweden', 'VODAFONE'],
  ['24010', '240', '20', 'Sweden', 'SWEFOUR'],
  ['24010', '240', '20', 'Sweden', 'SWEFOUR'],
  ['24010', '240', '20', 'Sweden', 'SWEFOUR'],
  ['24010', '240', '20', 'Sweden', 'SWEFOUR'],
  ['24010', '240', '20', 'Sweden', 'SWEFOUR'],
  ['24012', '240', '20', 'Sweden', 'Lycamobile'],
  ['24012', '240', '20', 'Sweden', 'Lycamobile'],
  ['24012', '240', '20', 'Sweden', 'Lycamobile'],
  ['24012', '240', '20', 'Sweden', 'Lycamobile'],
  ['24012', '240', '20', 'Sweden', 'Lycamobile'],
  ['24012', '240', '20', 'Sweden', 'Lycamobile'],
  ['24012', '240', '20', 'Sweden', 'Lycamobile'],
  ['24012', '240', '20', 'Sweden', 'Lycamobile'],
  ['24012', '240', '20', 'Sweden', 'Lycamobile'],
  ['24012', '240', '20', 'Sweden', 'Lycamobile'],
  ['24012', '240', '20', 'Sweden', 'Lycamobile'],
  ['24012', '240', '20', 'Sweden', 'Lycamobile'],
  ['24012', '240', '20', 'Sweden', 'Lycamobile'],
  ['24012', '240', '20', 'Sweden', 'Lycamobile'],
  ['24012', '240', '20', 'Sweden', 'Lycamobile'],
  ['24012', '240', '20', 'Sweden', 'Lycamobile'],
  ['24012', '240', '20', 'Sweden', 'Lycamobile'],
  ['24012', '240', '20', 'Sweden', 'Lycamobile'],
  ['24012', '240', '20', 'Sweden', 'Lycamobile'],
  ['24012', '240', '20', 'Sweden', 'Lycamobile'],
  ['24012', '240', '20', 'Sweden', 'Lycamobile'],
  ['24012', '240', '20', 'Sweden', 'Lycamobile'],
  ['24012', '240', '20', 'Sweden', 'Lycamobile'],
  ['24012', '240', '20', 'Sweden', 'Lycamobile'],
  ['24014', '240', '20', 'Sweden', 'TDC'],
  ['24016', '240', '20', 'Sweden', '42IT AB'],
  ['24016', '240', '20', 'Sweden', '42IT AB'],
  ['24016', '240', '20', 'Sweden', '42IT AB'],
  ['24016', '240', '20', 'Sweden', '42IT AB'],
  ['24016', '240', '20', 'Sweden', '42IT AB'],
  ['24016', '240', '20', 'Sweden', '42IT AB'],
  ['24016', '240', '20', 'Sweden', '42IT AB'],
  ['24016', '240', '20', 'Sweden', '42IT AB'],
  ['24016', '240', '20', 'Sweden', '42IT AB'],
  ['24016', '240', '20', 'Sweden', '42IT AB'],
  ['24016', '240', '20', 'Sweden', '42IT AB'],
  ['24016', '240', '20', 'Sweden', '42IT AB'],
  ['24016', '240', '20', 'Sweden', '42IT AB'],
  ['24016', '240', '20', 'Sweden', '42IT AB'],
  ['24016', '240', '20', 'Sweden', '42IT AB'],
  ['24016', '240', '20', 'Sweden', '42IT AB'],
  ['24016', '240', '20', 'Sweden', '42IT AB'],
  ['24016', '240', '20', 'Sweden', '42IT AB'],
  ['24016', '240', '20', 'Sweden', '42IT AB'],
  ['24016', '240', '20', 'Sweden', '42IT AB'],
  ['24016', '240', '20', 'Sweden', '42IT AB'],
  ['24016', '240', '20', 'Sweden', '42IT AB'],
  ['24016', '240', '20', 'Sweden', '42IT AB'],
  ['24016', '240', '20', 'Sweden', '42IT AB'],
  ['24016', '240', '20', 'Sweden', '42IT AB'],
  ['24016', '240', '20', 'Sweden', '42IT AB'],
  ['24016', '240', '20', 'Sweden', '42IT AB'],
  ['24016', '240', '20', 'Sweden', '42IT AB'],
  ['24016', '240', '20', 'Sweden', '42IT AB'],
  ['24016', '240', '20', 'Sweden', '42IT AB'],
  ['24016', '240', '20', 'Sweden', '42IT AB'],
  ['24016', '240', '20', 'Sweden', '42IT AB'],
  ['24016', '240', '20', 'Sweden', '42IT AB'],
  ['24016', '240', '20', 'Sweden', '42IT AB'],
  ['24016', '240', '20', 'Sweden', '42IT AB'],
  ['24016', '240', '20', 'Sweden', '42IT AB'],
  ['24016', '240', '20', 'Sweden', '42IT AB'],
  ['24016', '240', '20', 'Sweden', '42IT AB'],
  ['24016', '240', '20', 'Sweden', '42IT AB'],
  ['24016', '240', '20', 'Sweden', '42IT AB'],
  ['24016', '240', '20', 'Sweden', '42IT AB'],
  ['24016', '240', '20', 'Sweden', '42IT AB'],
  ['24016', '240', '20', 'Sweden', '42IT AB'],
  ['24016', '240', '20', 'Sweden', '42IT AB'],
  ['24016', '240', '20', 'Sweden', '42IT AB'],
  ['24016', '240', '20', 'Sweden', '42IT AB'],
  ['24016', '240', '20', 'Sweden', '42IT AB'],
  ['24016', '240', '20', 'Sweden', '42IT AB'],
  ['24016', '240', '20', 'Sweden', '42IT AB'],
  ['24017', '240', '20', 'Sweden', 'Gotalandsnatet AB'],
  ['24017', '240', '20', 'Sweden', 'Gotalandsnatet AB'],
  ['24017', '240', '20', 'Sweden', 'Gotalandsnatet AB'],
  ['24017', '240', '20', 'Sweden', 'Gotalandsnatet AB'],
  ['24017', '240', '20', 'Sweden', 'Gotalandsnatet AB'],
  ['24017', '240', '20', 'Sweden', 'Gotalandsnatet AB'],
  ['24017', '240', '20', 'Sweden', 'Gotalandsnatet AB'],
  ['24020', '240', '20', 'Sweden', 'MAINGATE'],
  ['2280', '228', '20', 'Switzerland', 'DEFAULT SWITZERLAND'],
  ['22801', '228', '20', 'Switzerland', 'SWISSCOM'],
  ['22802', '228', '20', 'Switzerland', 'SUNRISE (DIAX)'],
  ['22803', '228', '20', 'Switzerland', 'ORANGE'],
  ['22805', '228', '20', 'Switzerland', 'TOGEWANET AG'],
  ['22807', '228', '20', 'Switzerland', 'IN & PHONE'],
  ['22807', '228', '20', 'Switzerland', 'IN & PHONE'],
  ['22807', '228', '20', 'Switzerland', 'IN & PHONE'],
  ['22808', '228', '20', 'Switzerland', 'TELE 2'],
  ['22808', '228', '20', 'Switzerland', 'TELE 2'],
  ['22808', '228', '20', 'Switzerland', 'TELE 2'],
  ['22808', '228', '20', 'Switzerland', 'TELE 2'],
  ['22808', '228', '20', 'Switzerland', 'TELE 2'],
  ['22850', '228', '20', 'Switzerland', '3G MOBILE AG TELEFONICA'],
  ['22851', '228', '20', 'Switzerland', 'GLOBAL NETWORKS'],
  ['22853', '228', '20', 'Switzerland', 'UPC'],
  ['22853', '228', '20', 'Switzerland', 'UPC'],
  ['22853', '228', '20', 'Switzerland', 'UPC'],
  ['22854', '228', '20', 'Switzerland', 'Lycamobile'],
  ['4170', '417', '20', 'Syria', 'DEFAULT SYRIA'],
  ['41701', '417', '20', 'Syria', 'SYRIATEL'],
  ['41701', '417', '20', 'Syria', 'SYRIATEL'],
  ['41701', '417', '20', 'Syria', 'SYRIATEL'],
  ['41701', '417', '20', 'Syria', 'SYRIATEL'],
  ['41702', '417', '20', 'Syria', 'AREEBA'],
  ['41702', '417', '20', 'Syria', 'AREEBA'],
  ['41702', '417', '20', 'Syria', 'AREEBA'],
  ['4660', '466', '20', 'Taiwan', 'DEFAULT TAIWAN'],
  ['46601', '466', '20', 'Taiwan', 'FAR EASTONE'],
  ['46601', '466', '20', 'Taiwan', 'FAR EASTONE'],
  ['46601', '466', '20', 'Taiwan', 'FAR EASTONE'],
  ['46601', '466', '20', 'Taiwan', 'FAR EASTONE'],
  ['46601', '466', '20', 'Taiwan', 'FAR EASTONE'],
  ['46601', '466', '20', 'Taiwan', 'FAR EASTONE'],
  ['46688', '466', '20', 'Taiwan', 'KG TELECOM'],
  ['46688', '466', '20', 'Taiwan', 'KG TELECOM'],
  ['46688', '466', '20', 'Taiwan', 'KG TELECOM'],
  ['46688', '466', '20', 'Taiwan', 'KG TELECOM'],
  ['46692', '466', '20', 'Taiwan', 'CHUNGHWA'],
  ['46692', '466', '20', 'Taiwan', 'CHUNGHWA'],
  ['46692', '466', '20', 'Taiwan', 'CHUNGHWA'],
  ['46692', '466', '20', 'Taiwan', 'CHUNGHWA'],
  ['46692', '466', '20', 'Taiwan', 'CHUNGHWA'],
  ['46692', '466', '20', 'Taiwan', 'CHUNGHWA'],
  ['46692', '466', '20', 'Taiwan', 'CHUNGHWA'],
  ['46692', '466', '20', 'Taiwan', 'CHUNGHWA'],
  ['46692', '466', '20', 'Taiwan', 'CHUNGHWA'],
  ['46692', '466', '20', 'Taiwan', 'CHUNGHWA'],
  ['46692', '466', '20', 'Taiwan', 'CHUNGHWA'],
  ['46692', '466', '20', 'Taiwan', 'CHUNGHWA'],
  ['46692', '466', '20', 'Taiwan', 'CHUNGHWA'],
  ['46692', '466', '20', 'Taiwan', 'CHUNGHWA'],
  ['46692', '466', '20', 'Taiwan', 'CHUNGHWA'],
  ['46692', '466', '20', 'Taiwan', 'CHUNGHWA'],
  ['46692', '466', '20', 'Taiwan', 'CHUNGHWA'],
  ['46692', '466', '20', 'Taiwan', 'CHUNGHWA'],
  ['46692', '466', '20', 'Taiwan', 'CHUNGHWA'],
  ['46692', '466', '20', 'Taiwan', 'CHUNGHWA'],
  ['46692', '466', '20', 'Taiwan', 'CHUNGHWA'],
  ['46693', '466', '20', 'Taiwan', 'MOBITAI'],
  ['46693', '466', '20', 'Taiwan', 'MOBITAI'],
  ['46697', '466', '20', 'Taiwan', 'TAIWAN CELL'],
  ['46697', '466', '20', 'Taiwan', 'TAIWAN CELL'],
  ['46697', '466', '20', 'Taiwan', 'TAIWAN CELL'],
  ['46697', '466', '20', 'Taiwan', 'TAIWAN CELL'],
  ['46697', '466', '20', 'Taiwan', 'TAIWAN CELL'],
  ['46697', '466', '20', 'Taiwan', 'TAIWAN CELL'],
  ['46697', '466', '20', 'Taiwan', 'TAIWAN CELL'],
  ['46697', '466', '20', 'Taiwan', 'TAIWAN CELL'],
  ['46697', '466', '20', 'Taiwan', 'TAIWAN CELL'],
  ['46697', '466', '20', 'Taiwan', 'TAIWAN CELL'],
  ['46697', '466', '20', 'Taiwan', 'TAIWAN CELL'],
  ['46699', '466', '20', 'Taiwan', 'TRANS ASIA'],
  ['46699', '466', '20', 'Taiwan', 'TRANS ASIA'],
  ['90105', '901', '20', 'Taiwan', 'EMSAT SYSTEM NETWORK'],
  ['90105', '901', '20', 'Taiwan', 'SAT. SYSTEM THURAYA'],
  ['4360', '436', '20', 'Tajikistan', 'DEFAULT TAJIKISTAN'],
  ['43601', '436', '20', 'Tajikistan', 'SOMONCOM'],
  ['43602', '436', '20', 'Tajikistan', 'INDIGO'],
  ['43603', '436', '20', 'Tajikistan', 'TT MOBILE'],
  ['43604', '436', '20', 'Tajikistan', 'BABILON-M'],
  ['43605', '436', '20', 'Tajikistan', 'TK MOBILE'],
  ['43610', '436', '20', 'Tajikistan', 'TAJIKTEL'],
  ['43612', '436', '20', 'Tajikistan', 'Indigo'],
  ['43612', '436', '20', 'Tajikistan', 'Indigo'],
  ['43612', '436', '20', 'Tajikistan', 'Indigo'],
  ['43612', '436', '20', 'Tajikistan', 'Indigo'],
  ['43612', '436', '20', 'Tajikistan', 'Indigo'],
  ['43612', '436', '20', 'Tajikistan', 'Indigo'],
  ['6400', '640', '20', 'Tanzania', 'DEFAULT TANZANIA'],
  ['64002', '640', '20', 'Tanzania', 'MOBITEL'],
  ['64003', '640', '20', 'Tanzania', 'ZANTEL'],
  ['64004', '640', '20', 'Tanzania', 'VODACOM'],
  ['64004', '640', '20', 'Tanzania', 'VODACOM'],
  ['64004', '640', '20', 'Tanzania', 'VODACOM'],
  ['64004', '640', '20', 'Tanzania', 'VODACOM'],
  ['64005', '640', '20', 'Tanzania', 'CELTEL'],
  ['64007', '640', '20', 'Tanzania', 'TTCL'],
  ['64008', '640', '20', 'Tanzania', 'Benson Informatics Smart'],
  ['64009', '640', '20', 'Tanzania', 'Halotel (Viettel)'],
  ['7380', '738', '20', 'Tanzania', 'DEFAULT FRENCH GUIANA'],
  ['73801', '738', '20', 'Tanzania', 'ORANGE'],
  ['5200', '520', '20', 'Thailand', 'DEFAULT THAILAND'],
  ['52001', '520', '20', 'Thailand', 'AIS'],
  ['52001', '520', '20', 'Thailand', 'AIS'],
  ['52001', '520', '20', 'Thailand', 'AIS'],
  ['52001', '520', '20', 'Thailand', 'AIS'],
  ['52001', '520', '20', 'Thailand', 'AIS'],
  ['52001', '520', '20', 'Thailand', 'AIS'],
  ['52001', '520', '20', 'Thailand', 'AIS'],
  ['52001', '520', '20', 'Thailand', 'AIS'],
  ['52001', '520', '20', 'Thailand', 'AIS'],
  ['52001', '520', '20', 'Thailand', 'AIS'],
  ['52001', '520', '20', 'Thailand', 'AIS'],
  ['52001', '520', '20', 'Thailand', 'AIS'],
  ['52001', '520', '20', 'Thailand', 'AIS'],
  ['52001', '520', '20', 'Thailand', 'AIS'],
  ['52001', '520', '20', 'Thailand', 'AIS'],
  ['52001', '520', '20', 'Thailand', 'AIS'],
  ['52001', '520', '20', 'Thailand', 'AIS'],
  ['52001', '520', '20', 'Thailand', 'AIS'],
  ['52001', '520', '20', 'Thailand', 'AIS'],
  ['52001', '520', '20', 'Thailand', 'AIS'],
  ['52001', '520', '20', 'Thailand', 'AIS'],
  ['52001', '520', '20', 'Thailand', 'AIS'],
  ['52001', '520', '20', 'Thailand', 'AIS'],
  ['52001', '520', '20', 'Thailand', 'AIS'],
  ['52001', '520', '20', 'Thailand', 'AIS'],
  ['52001', '520', '20', 'Thailand', 'AIS'],
  ['52001', '520', '20', 'Thailand', 'AIS'],
  ['52001', '520', '20', 'Thailand', 'AIS'],
  ['52001', '520', '20', 'Thailand', 'AIS'],
  ['52001', '520', '20', 'Thailand', 'AIS'],
  ['52001', '520', '20', 'Thailand', 'AIS'],
  ['52001', '520', '20', 'Thailand', 'AIS'],
  ['52001', '520', '20', 'Thailand', 'AIS'],
  ['52001', '520', '20', 'Thailand', 'AIS'],
  ['52001', '520', '20', 'Thailand', 'AIS'],
  ['52001', '520', '20', 'Thailand', 'AIS'],
  ['52001', '520', '20', 'Thailand', 'AIS'],
  ['52001', '520', '20', 'Thailand', 'AIS'],
  ['52001', '520', '20', 'Thailand', 'AIS'],
  ['52001', '520', '20', 'Thailand', 'AIS'],
  ['52001', '520', '20', 'Thailand', 'AIS'],
  ['52001', '520', '20', 'Thailand', 'AIS'],
  ['52001', '520', '20', 'Thailand', 'AIS'],
  ['52001', '520', '20', 'Thailand', 'AIS'],
  ['52001', '520', '20', 'Thailand', 'AIS'],
  ['52001', '520', '20', 'Thailand', 'AIS'],
  ['52003', '520', '20', 'Thailand', 'AIS GSM'],
  ['52003', '520', '20', 'Thailand', 'AIS GSM'],
  ['52003', '520', '20', 'Thailand', 'AIS GSM'],
  ['52003', '520', '20', 'Thailand', 'AIS GSM'],
  ['52003', '520', '20', 'Thailand', 'AIS GSM'],
  ['52003', '520', '20', 'Thailand', 'AIS GSM'],
  ['52003', '520', '20', 'Thailand', 'AIS GSM'],
  ['52003', '520', '20', 'Thailand', 'AIS GSM'],
  ['52003', '520', '20', 'Thailand', 'AIS GSM'],
  ['52003', '520', '20', 'Thailand', 'AIS GSM'],
  ['52003', '520', '20', 'Thailand', 'AIS GSM'],
  ['52003', '520', '20', 'Thailand', 'AIS GSM'],
  ['52003', '520', '20', 'Thailand', 'AIS GSM'],
  ['52003', '520', '20', 'Thailand', 'AIS GSM'],
  ['52003', '520', '20', 'Thailand', 'AIS GSM'],
  ['52003', '520', '20', 'Thailand', 'AIS GSM'],
  ['52003', '520', '20', 'Thailand', 'AIS GSM'],
  ['52003', '520', '20', 'Thailand', 'AIS GSM'],
  ['52003', '520', '20', 'Thailand', 'AIS GSM'],
  ['52003', '520', '20', 'Thailand', 'AIS GSM'],
  ['52003', '520', '20', 'Thailand', 'AIS GSM'],
  ['52003', '520', '20', 'Thailand', 'AIS GSM'],
  ['52003', '520', '20', 'Thailand', 'AIS GSM'],
  ['52003', '520', '20', 'Thailand', 'AIS GSM'],
  ['52003', '520', '20', 'Thailand', 'AIS GSM'],
  ['52003', '520', '20', 'Thailand', 'AIS GSM'],
  ['52003', '520', '20', 'Thailand', 'AIS GSM'],
  ['52003', '520', '20', 'Thailand', 'AIS GSM'],
  ['52003', '520', '20', 'Thailand', 'AIS GSM'],
  ['52003', '520', '20', 'Thailand', 'AIS GSM'],
  ['52003', '520', '20', 'Thailand', 'AIS GSM'],
  ['52003', '520', '20', 'Thailand', 'AIS GSM'],
  ['52003', '520', '20', 'Thailand', 'AIS GSM'],
  ['52003', '520', '20', 'Thailand', 'AIS GSM'],
  ['52003', '520', '20', 'Thailand', 'AIS GSM'],
  ['52003', '520', '20', 'Thailand', 'AIS GSM'],
  ['52003', '520', '22', 'Thailand', 'AIS GSM'],
  ['52003', '520', '23', 'Thailand', 'AIS GSM'],
  ['52003', '520', '23', 'Thailand', 'AIS GSM'],
  ['52003', '520', '24', 'Thailand', 'AIS GSM'],
  ['52003', '520', '24', 'Thailand', 'AIS GSM'],
  ['52003', '520', '25', 'Thailand', 'AIS GSM'],
  ['52003', '520', '25', 'Thailand', 'AIS GSM'],
  ['52003', '520', '25', 'Thailand', 'AIS GSM'],
  ['52003', '520', '25', 'Thailand', 'AIS GSM'],
  ['52003', '520', '25', 'Thailand', 'AIS GSM'],
  ['52003', '520', '25', 'Thailand', 'AIS GSM'],
  ['52003', '520', '25', 'Thailand', 'AIS GSM'],
  ['52003', '520', '25', 'Thailand', 'AIS GSM'],
  ['52003', '520', '26', 'Thailand', 'AIS GSM'],
  ['52003', '520', '26', 'Thailand', 'AIS GSM'],
  ['52003', '520', '26', 'Thailand', 'AIS GSM'],
  ['52003', '520', '26', 'Thailand', 'AIS GSM'],
  ['52003', '520', '26', 'Thailand', 'AIS GSM'],
  ['52003', '520', '26', 'Thailand', 'AIS GSM'],
  ['52003', '520', '26', 'Thailand', 'AIS GSM'],
  ['52003', '520', '26', 'Thailand', 'AIS GSM'],
  ['52003', '520', '26', 'Thailand', 'AIS GSM'],
  ['52003', '520', '26', 'Thailand', 'AIS GSM'],
  ['52003', '520', '26', 'Thailand', 'AIS GSM'],
  ['52003', '520', '26', 'Thailand', 'AIS GSM'],
  ['52003', '520', '26', 'Thailand', 'AIS GSM'],
  ['52003', '520', '26', 'Thailand', 'AIS GSM'],
  ['52003', '520', '26', 'Thailand', 'AIS GSM'],
  ['52003', '520', '26', 'Thailand', 'AIS GSM'],
  ['52003', '520', '26', 'Thailand', 'AIS GSM'],
  ['52003', '520', '26', 'Thailand', 'AIS GSM'],
  ['52003', '520', '26', 'Thailand', 'AIS GSM'],
  ['52003', '520', '26', 'Thailand', 'AIS GSM'],
  ['52003', '520', '26', 'Thailand', 'AIS GSM'],
  ['52003', '520', '26', 'Thailand', 'AIS GSM'],
  ['52003', '520', '26', 'Thailand', 'AIS GSM'],
  ['52003', '520', '26', 'Thailand', 'AIS GSM'],
  ['52003', '520', '26', 'Thailand', 'AIS GSM'],
  ['52003', '520', '26', 'Thailand', 'AIS GSM'],
  ['52003', '520', '26', 'Thailand', 'AIS GSM'],
  ['52003', '520', '26', 'Thailand', 'AIS GSM'],
  ['52003', '520', '26', 'Thailand', 'AIS GSM'],
  ['52003', '520', '26', 'Thailand', 'AIS GSM'],
  ['52003', '520', '26', 'Thailand', 'AIS GSM'],
  ['52003', '520', '26', 'Thailand', 'AIS GSM'],
  ['52003', '520', '26', 'Thailand', 'AIS GSM'],
  ['52003', '520', '26', 'Thailand', 'AIS GSM'],
  ['52003', '520', '26', 'Thailand', 'AIS GSM'],
  ['52003', '520', '26', 'Thailand', 'AIS GSM'],
  ['52003', '520', '26', 'Thailand', 'AIS GSM'],
  ['52003', '520', '26', 'Thailand', 'AIS GSM'],
  ['52003', '520', '26', 'Thailand', 'AIS GSM'],
  ['52003', '520', '26', 'Thailand', 'AIS GSM'],
  ['52003', '520', '26', 'Thailand', 'AIS GSM'],
  ['52003', '520', '26', 'Thailand', 'AIS GSM'],
  ['52003', '520', '26', 'Thailand', 'AIS GSM'],
  ['52003', '520', '26', 'Thailand', 'AIS GSM'],
  ['52003', '520', '26', 'Thailand', 'AIS GSM'],
  ['52003', '520', '26', 'Thailand', 'AIS GSM'],
  ['52003', '520', '26', 'Thailand', 'AIS GSM'],
  ['52003', '520', '26', 'Thailand', 'AIS GSM'],
  ['52003', '520', '26', 'Thailand', 'AIS GSM'],
  ['52003', '520', '26', 'Thailand', 'AIS GSM'],
  ['52003', '520', '26', 'Thailand', 'AIS GSM'],
  ['52003', '520', '26', 'Thailand', 'AIS GSM'],
  ['52003', '520', '26', 'Thailand', 'AIS GSM'],
  ['52003', '520', '26', 'Thailand', 'AIS GSM'],
  ['52003', '520', '26', 'Thailand', 'AIS GSM'],
  ['52003', '520', '26', 'Thailand', 'AIS GSM'],
  ['52003', '520', '26', 'Thailand', 'AIS GSM'],
  ['52003', '520', '26', 'Thailand', 'AIS GSM'],
  ['52003', '520', '26', 'Thailand', 'AIS GSM'],
  ['52003', '520', '26', 'Thailand', 'AIS GSM'],
  ['52003', '520', '26', 'Thailand', 'AIS GSM'],
  ['52003', '520', '26', 'Thailand', 'AIS GSM'],
  ['52003', '520', '26', 'Thailand', 'AIS GSM'],
  ['52003', '520', '26', 'Thailand', 'AIS GSM'],
  ['52003', '520', '26', 'Thailand', 'AIS GSM'],
  ['52003', '520', '26', 'Thailand', 'AIS GSM'],
  ['52003', '520', '26', 'Thailand', 'AIS GSM'],
  ['52003', '520', '26', 'Thailand', 'AIS GSM'],
  ['52003', '520', '26', 'Thailand', 'AIS GSM'],
  ['52003', '520', '26', 'Thailand', 'AIS GSM'],
  ['52003', '520', '26', 'Thailand', 'AIS GSM'],
  ['52003', '520', '26', 'Thailand', 'AIS GSM'],
  ['52003', '520', '26', 'Thailand', 'AIS GSM'],
  ['52003', '520', '26', 'Thailand', 'AIS GSM'],
  ['52003', '520', '26', 'Thailand', 'AIS GSM'],
  ['52003', '520', '26', 'Thailand', 'AIS GSM'],
  ['52003', '520', '26', 'Thailand', 'AIS GSM'],
  ['52003', '520', '26', 'Thailand', 'AIS GSM'],
  ['52003', '520', '26', 'Thailand', 'AIS GSM'],
  ['52003', '520', '26', 'Thailand', 'AIS GSM'],
  ['52003', '520', '26', 'Thailand', 'AIS GSM'],
  ['52003', '520', '27', 'Thailand', 'AIS GSM'],
  ['52003', '520', '28', 'Thailand', 'AIS GSM'],
  ['52003', '520', '28', 'Thailand', 'AIS GSM'],
  ['52003', '520', '3', 'Thailand', 'AIS GSM'],
  ['52003', '520', '3', 'Thailand', 'AIS GSM'],
  ['52003', '520', '3', 'Thailand', 'AIS GSM'],
  ['52003', '520', '3', 'Thailand', 'AIS GSM'],
  ['52003', '520', '3', 'Thailand', 'AIS GSM'],
  ['52003', '520', '3', 'Thailand', 'AIS GSM'],
  ['52003', '520', '30', 'Thailand', 'AIS GSM'],
  ['52003', '520', '30', 'Thailand', 'AIS GSM'],
  ['52003', '520', '30', 'Thailand', 'AIS GSM'],
  ['52003', '520', '30', 'Thailand', 'AIS GSM'],
  ['52003', '520', '30', 'Thailand', 'AIS GSM'],
  ['52003', '520', '30', 'Thailand', 'AIS GSM'],
  ['52003', '520', '30', 'Thailand', 'AIS GSM'],
  ['52003', '520', '30', 'Thailand', 'AIS GSM'],
  ['52003', '520', '30', 'Thailand', 'AIS GSM'],
  ['52003', '520', '30', 'Thailand', 'AIS GSM'],
  ['52003', '520', '30', 'Thailand', 'AIS GSM'],
  ['52003', '520', '30', 'Thailand', 'AIS GSM'],
  ['52003', '520', '30', 'Thailand', 'AIS GSM'],
  ['52003', '520', '30', 'Thailand', 'AIS GSM'],
  ['52003', '520', '30', 'Thailand', 'AIS GSM'],
  ['52003', '520', '30', 'Thailand', 'AIS GSM'],
  ['52003', '520', '30', 'Thailand', 'AIS GSM'],
  ['52003', '520', '30', 'Thailand', 'AIS GSM'],
  ['52003', '520', '30', 'Thailand', 'AIS GSM'],
  ['52003', '520', '30', 'Thailand', 'AIS GSM'],
  ['52003', '520', '30', 'Thailand', 'AIS GSM'],
  ['52003', '520', '30', 'Thailand', 'AIS GSM'],
  ['52003', '520', '30', 'Thailand', 'AIS GSM'],
  ['52003', '520', '30', 'Thailand', 'AIS GSM'],
  ['52003', '520', '30', 'Thailand', 'AIS GSM'],
  ['52003', '520', '30', 'Thailand', 'AIS GSM'],
  ['52003', '520', '30', 'Thailand', 'AIS GSM'],
  ['52003', '520', '30', 'Thailand', 'AIS GSM'],
  ['52003', '520', '30', 'Thailand', 'AIS GSM'],
  ['52003', '520', '30', 'Thailand', 'AIS GSM'],
  ['52003', '520', '30', 'Thailand', 'AIS GSM'],
  ['52003', '520', '30', 'Thailand', 'AIS GSM'],
  ['52003', '520', '30', 'Thailand', 'AIS GSM'],
  ['52003', '520', '30', 'Thailand', 'AIS GSM'],
  ['52003', '520', '30', 'Thailand', 'AIS GSM'],
  ['52003', '520', '30', 'Thailand', 'AIS GSM'],
  ['52003', '520', '30', 'Thailand', 'AIS GSM'],
  ['52003', '520', '30', 'Thailand', 'AIS GSM'],
  ['52003', '520', '30', 'Thailand', 'AIS GSM'],
  ['52003', '520', '30', 'Thailand', 'AIS GSM'],
  ['52003', '520', '30', 'Thailand', 'AIS GSM'],
  ['52003', '520', '30', 'Thailand', 'AIS GSM'],
  ['52003', '520', '30', 'Thailand', 'AIS GSM'],
  ['52003', '520', '30', 'Thailand', 'AIS GSM'],
  ['52003', '520', '30', 'Thailand', 'AIS GSM'],
  ['52003', '520', '30', 'Thailand', 'AIS GSM'],
  ['52003', '520', '30', 'Thailand', 'AIS GSM'],
  ['52003', '520', '30', 'Thailand', 'AIS GSM'],
  ['52003', '520', '30', 'Thailand', 'AIS GSM'],
  ['52003', '520', '30', 'Thailand', 'AIS GSM'],
  ['52003', '520', '30', 'Thailand', 'AIS GSM'],
  ['52003', '520', '30', 'Thailand', 'AIS GSM'],
  ['52003', '520', '30', 'Thailand', 'AIS GSM'],
  ['52003', '520', '30', 'Thailand', 'AIS GSM'],
  ['52003', '520', '30', 'Thailand', 'AIS GSM'],
  ['52003', '520', '30', 'Thailand', 'AIS GSM'],
  ['52003', '520', '30', 'Thailand', 'AIS GSM'],
  ['52003', '520', '30', 'Thailand', 'AIS GSM'],
  ['52003', '520', '30', 'Thailand', 'AIS GSM'],
  ['52003', '520', '30', 'Thailand', 'AIS GSM'],
  ['52003', '520', '30', 'Thailand', 'AIS GSM'],
  ['52003', '520', '30', 'Thailand', 'AIS GSM'],
  ['52003', '520', '30', 'Thailand', 'AIS GSM'],
  ['52003', '520', '30', 'Thailand', 'AIS GSM'],
  ['52003', '520', '30', 'Thailand', 'AIS GSM'],
  ['52003', '520', '30', 'Thailand', 'AIS GSM'],
  ['52003', '520', '30', 'Thailand', 'AIS GSM'],
  ['52003', '520', '30', 'Thailand', 'AIS GSM'],
  ['52003', '520', '30', 'Thailand', 'AIS GSM'],
  ['52003', '520', '30', 'Thailand', 'AIS GSM'],
  ['52003', '520', '30', 'Thailand', 'AIS GSM'],
  ['52003', '520', '30', 'Thailand', 'AIS GSM'],
  ['52003', '520', '30', 'Thailand', 'AIS GSM'],
  ['52003', '520', '30', 'Thailand', 'AIS GSM'],
  ['52003', '520', '30', 'Thailand', 'AIS GSM'],
  ['52003', '520', '30', 'Thailand', 'AIS GSM'],
  ['52003', '520', '30', 'Thailand', 'AIS GSM'],
  ['52003', '520', '30', 'Thailand', 'AIS GSM'],
  ['52003', '520', '30', 'Thailand', 'AIS GSM'],
  ['52003', '520', '30', 'Thailand', 'AIS GSM'],
  ['52003', '520', '30', 'Thailand', 'AIS GSM'],
  ['52003', '520', '30', 'Thailand', 'AIS GSM'],
  ['52003', '520', '30', 'Thailand', 'AIS GSM'],
  ['52003', '520', '30', 'Thailand', 'AIS GSM'],
  ['52003', '520', '30', 'Thailand', 'AIS GSM'],
  ['52003', '520', '30', 'Thailand', 'AIS GSM'],
  ['52003', '520', '30', 'Thailand', 'AIS GSM'],
  ['52003', '520', '30', 'Thailand', 'AIS GSM'],
  ['52003', '520', '30', 'Thailand', 'AIS GSM'],
  ['52003', '520', '30', 'Thailand', 'AIS GSM'],
  ['52003', '520', '30', 'Thailand', 'AIS GSM'],
  ['52003', '520', '30', 'Thailand', 'AIS GSM'],
  ['52003', '520', '30', 'Thailand', 'AIS GSM'],
  ['52003', '520', '30', 'Thailand', 'AIS GSM'],
  ['52003', '520', '30', 'Thailand', 'AIS GSM'],
  ['52003', '520', '30', 'Thailand', 'AIS GSM'],
  ['52003', '520', '30', 'Thailand', 'AIS GSM'],
  ['52003', '520', '30', 'Thailand', 'AIS GSM'],
  ['52003', '520', '30', 'Thailand', 'AIS GSM'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52004', '520', '30', 'Thailand', 'True Move (Real Future)'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52005', '520', '30', 'Thailand', 'DTAC'],
  ['52015', '520', '30', 'Thailand', 'TOT'],
  ['52015', '520', '30', 'Thailand', 'TOT'],
  ['52015', '520', '30', 'Thailand', 'TOT'],
  ['52015', '520', '30', 'Thailand', 'TOT'],
  ['52015', '520', '30', 'Thailand', 'TOT'],
  ['52015', '520', '30', 'Thailand', 'TOT'],
  ['52015', '520', '30', 'Thailand', 'TOT'],
  ['52015', '520', '30', 'Thailand', 'TOT'],
  ['52015', '520', '30', 'Thailand', 'TOT'],
  ['52015', '520', '30', 'Thailand', 'TOT'],
  ['52015', '520', '30', 'Thailand', 'TOT'],
  ['52015', '520', '30', 'Thailand', 'TOT'],
  ['52015', '520', '30', 'Thailand', 'TOT'],
  ['52015', '520', '30', 'Thailand', 'TOT'],
  ['52015', '520', '30', 'Thailand', 'TOT'],
  ['52015', '520', '30', 'Thailand', 'TOT'],
  ['52015', '520', '30', 'Thailand', 'TOT'],
  ['52018', '520', '30', 'Thailand', 'DTAC'],
  ['52018', '520', '30', 'Thailand', 'DTAC'],
  ['52018', '520', '30', 'Thailand', 'DTAC'],
  ['52018', '520', '30', 'Thailand', 'DTAC'],
  ['52018', '520', '30', 'Thailand', 'DTAC'],
  ['52018', '520', '30', 'Thailand', 'DTAC'],
  ['52018', '520', '30', 'Thailand', 'DTAC'],
  ['52018', '520', '30', 'Thailand', 'DTAC'],
  ['52018', '520', '30', 'Thailand', 'DTAC'],
  ['52018', '520', '30', 'Thailand', 'DTAC'],
  ['52018', '520', '30', 'Thailand', 'DTAC'],
  ['52018', '520', '30', 'Thailand', 'DTAC'],
  ['52018', '520', '30', 'Thailand', 'DTAC'],
  ['52018', '520', '30', 'Thailand', 'DTAC'],
  ['52018', '520', '30', 'Thailand', 'DTAC'],
  ['52018', '520', '30', 'Thailand', 'DTAC'],
  ['52018', '520', '30', 'Thailand', 'DTAC'],
  ['52018', '520', '30', 'Thailand', 'DTAC'],
  ['52018', '520', '30', 'Thailand', 'DTAC'],
  ['52018', '520', '30', 'Thailand', 'DTAC'],
  ['52018', '520', '30', 'Thailand', 'DTAC'],
  ['52018', '520', '30', 'Thailand', 'DTAC'],
  ['52018', '520', '30', 'Thailand', 'DTAC'],
  ['52018', '520', '30', 'Thailand', 'DTAC'],
  ['52018', '520', '30', 'Thailand', 'DTAC'],
  ['52018', '520', '30', 'Thailand', 'DTAC'],
  ['52018', '520', '30', 'Thailand', 'DTAC'],
  ['52018', '520', '30', 'Thailand', 'DTAC'],
  ['52018', '520', '30', 'Thailand', 'DTAC'],
  ['52018', '520', '30', 'Thailand', 'DTAC'],
  ['52018', '520', '30', 'Thailand', 'DTAC'],
  ['52018', '520', '30', 'Thailand', 'DTAC'],
  ['52018', '520', '30', 'Thailand', 'DTAC'],
  ['52018', '520', '30', 'Thailand', 'DTAC'],
  ['52018', '520', '30', 'Thailand', 'DTAC'],
  ['52018', '520', '30', 'Thailand', 'DTAC'],
  ['52018', '520', '30', 'Thailand', 'DTAC'],
  ['52018', '520', '30', 'Thailand', 'DTAC'],
  ['52018', '520', '30', 'Thailand', 'DTAC'],
  ['52018', '520', '30', 'Thailand', 'DTAC'],
  ['52018', '520', '30', 'Thailand', 'DTAC'],
  ['52018', '520', '30', 'Thailand', 'DTAC'],
  ['52018', '520', '30', 'Thailand', 'DTAC'],
  ['52018', '520', '30', 'Thailand', 'DTAC'],
  ['52018', '520', '30', 'Thailand', 'DTAC'],
  ['52018', '520', '30', 'Thailand', 'DTAC'],
  ['52018', '520', '30', 'Thailand', 'DTAC'],
  ['52018', '520', '30', 'Thailand', 'DTAC'],
  ['52018', '520', '30', 'Thailand', 'DTAC'],
  ['52018', '520', '30', 'Thailand', 'DTAC'],
  ['52018', '520', '30', 'Thailand', 'DTAC'],
  ['52018', '520', '30', 'Thailand', 'DTAC'],
  ['52018', '520', '30', 'Thailand', 'DTAC'],
  ['52018', '520', '30', 'Thailand', 'DTAC'],
  ['52018', '520', '30', 'Thailand', 'DTAC'],
  ['52018', '520', '30', 'Thailand', 'DTAC'],
  ['52018', '520', '30', 'Thailand', 'DTAC'],
  ['52018', '520', '30', 'Thailand', 'DTAC'],
  ['52018', '520', '30', 'Thailand', 'DTAC'],
  ['52018', '520', '30', 'Thailand', 'DTAC'],
  ['52020', '520', '30', 'Thailand', 'TOT'],
  ['52020', '520', '30', 'Thailand', 'TOT'],
  ['52020', '520', '30', 'Thailand', 'TOT'],
  ['52020', '520', '30', 'Thailand', 'TOT'],
  ['52020', '520', '30', 'Thailand', 'TOT'],
  ['52020', '520', '30', 'Thailand', 'TOT'],
  ['52020', '520', '30', 'Thailand', 'TOT'],
  ['52020', '520', '30', 'Thailand', 'TOT'],
  ['52020', '520', '30', 'Thailand', 'TOT'],
  ['52020', '520', '30', 'Thailand', 'TOT'],
  ['52020', '520', '30', 'Thailand', 'TOT'],
  ['52020', '520', '30', 'Thailand', 'TOT'],
  ['52020', '520', '30', 'Thailand', 'TOT'],
  ['52020', '520', '30', 'Thailand', 'TOT'],
  ['52020', '520', '30', 'Thailand', 'TOT'],
  ['52020', '520', '30', 'Thailand', 'TOT'],
  ['52020', '520', '30', 'Thailand', 'TOT'],
  ['52023', '520', '30', 'Thailand', 'DIGITAL PHONE'],
  ['52023', '520', '30', 'Thailand', 'DIGITAL PHONE'],
  ['52023', '520', '30', 'Thailand', 'DIGITAL PHONE'],
  ['52023', '520', '30', 'Thailand', 'DIGITAL PHONE'],
  ['52023', '520', '30', 'Thailand', 'DIGITAL PHONE'],
  ['52023', '520', '30', 'Thailand', 'DIGITAL PHONE'],
  ['52023', '520', '30', 'Thailand', 'DIGITAL PHONE'],
  ['52023', '520', '30', 'Thailand', 'DIGITAL PHONE'],
  ['52023', '520', '30', 'Thailand', 'DIGITAL PHONE'],
  ['52023', '520', '30', 'Thailand', 'DIGITAL PHONE'],
  ['52023', '520', '30', 'Thailand', 'DIGITAL PHONE'],
  ['52023', '520', '30', 'Thailand', 'DIGITAL PHONE'],
  ['52023', '520', '30', 'Thailand', 'DIGITAL PHONE'],
  ['52023', '520', '30', 'Thailand', 'DIGITAL PHONE'],
  ['52023', '520', '30', 'Thailand', 'DIGITAL PHONE'],
  ['52088', '520', '30', 'Thailand', 'Unknown-THAILAND-8351'],
  ['52099', '520', '30', 'Thailand', 'TA ORANGE'],
  ['52099', '520', '30', 'Thailand', 'TA ORANGE'],
  ['5140', '514', '30', 'Timor-Leste', 'DEFAULT TIMOR LESTE'],
  ['51402', '514', '30', 'Timor-Leste', 'TIMOR TELECOM'],
  ['51402', '514', '30', 'Timor-Leste', 'TIMOR TELECOM'],
  ['6150', '615', '30', 'Togo', 'DEFAULT TOGO'],
  ['61501', '615', '30', 'Togo', 'TOGO TELECOM'],
  ['61501', '615', '30', 'Togo', 'TOGO TELECOM'],
  ['61501', '615', '30', 'Togo', 'TOGO TELECOM'],
  ['61501', '615', '30', 'Togo', 'TOGO TELECOM'],
  ['61502', '615', '30', 'Togo', 'TELECEL TOGO'],
  ['310002', '310', '30', 'Trinidad and Tobago', 'TSTT'],
  ['6050', '605', '30', 'Tunisia', 'DEFAULT TUNISIA'],
  ['60501', '605', '30', 'Tunisia', 'ORANGE'],
  ['60502', '605', '30', 'Tunisia', 'TUNTEL'],
  ['60502', '605', '30', 'Tunisia', 'TUNTEL'],
  ['60502', '605', '30', 'Tunisia', 'TUNTEL'],
  ['60502', '605', '30', 'Tunisia', 'TUNTEL'],
  ['60502', '605', '30', 'Tunisia', 'TUNTEL'],
  ['60502', '605', '30', 'Tunisia', 'TUNTEL'],
  ['60502', '605', '30', 'Tunisia', 'TUNTEL'],
  ['60502', '605', '30', 'Tunisia', 'TUNTEL'],
  ['60502', '605', '30', 'Tunisia', 'TUNTEL'],
  ['60503', '605', '30', 'Tunisia', 'TUNISIANA'],
  ['60503', '605', '30', 'Tunisia', 'TUNISIANA'],
  ['60503', '605', '30', 'Tunisia', 'TUNISIANA'],
  ['60503', '605', '30', 'Tunisia', 'TUNISIANA'],
  ['2860', '286', '30', 'Turkey', 'DEFAULT TURKEY'],
  ['28601', '286', '30', 'Turkey', 'TURKCELL'],
  ['28602', '286', '30', 'Turkey', 'TELSIM'],
  ['28603', '286', '30', 'Turkey', 'ARIA (IS-TIM)'],
  ['28604', '286', '30', 'Turkey', 'AYCELL'],
  ['4380', '438', '30', 'Turkmenistan', 'DEFAULT TURKMENISTAN'],
  ['43801', '438', '30', 'Turkmenistan', 'BCTI'],
  ['43802', '438', '30', 'Turkmenistan', 'TM-Cell (Altyn Asyr)'],
  ['43802', '438', '30', 'Turkmenistan', 'TM-Cell (Altyn Asyr)'],
  ['43802', '438', '30', 'Turkmenistan', 'TM-Cell (Altyn Asyr)'],
  ['43802', '438', '30', 'Turkmenistan', 'TM-Cell (Altyn Asyr)'],
  ['43802', '438', '30', 'Turkmenistan', 'TM-Cell (Altyn Asyr)'],
  ['6410', '641', '30', 'Uganda', 'DEFAULT UGANDA'],
  ['64101', '641', '30', 'Uganda', 'CELTEL'],
  ['64106', '641', '30', 'Uganda', 'Afrimax Vodafone'],
  ['64110', '641', '30', 'Uganda', 'MTN UGANDA'],
  ['64111', '641', '30', 'Uganda', 'UGANDA TELECOM'],
  ['64114', '641', '30', 'Uganda', 'Africell (Orange)'],
  ['64118', '641', '30', 'Uganda', 'Smart Telecom (Sure)'],
  ['64122', '641', '30', 'Uganda', 'Warid Telecom'],
  ['64133', '641', '30', 'Uganda', 'Smile'],
  ['2550', '255', '30', 'Ukraine', 'DEFAULT UKRAINE'],
  ['25501', '255', '30', 'Ukraine', 'UMC'],
  ['25501', '255', '30', 'Ukraine', 'UMC'],
  ['25501', '255', '30', 'Ukraine', 'UMC'],
  ['25502', '255', '30', 'Ukraine', 'UKRAINE RADIO SYSTEM'],
  ['25503', '255', '30', 'Ukraine', 'KYIVSTAR'],
  ['25503', '255', '30', 'Ukraine', 'KYIVSTAR'],
  ['25503', '255', '30', 'Ukraine', 'KYIVSTAR'],
  ['25503', '255', '30', 'Ukraine', 'KYIVSTAR'],
  ['25504', '255', '30', 'Ukraine', 'ASTELIT'],
  ['25504', '255', '30', 'Ukraine', 'ASTELIT'],
  ['25505', '255', '30', 'Ukraine', 'GOLDEN'],
  ['25506', '255', '30', 'Ukraine', 'Unknown-UKRAINE-9356'],
  ['25507', '255', '30', 'Ukraine', 'Unknown-Ukraine-9161'],
  ['25521', '255', '30', 'Ukraine', 'People.Net'],
  ['25599', '255', '30', 'Ukraine', 'Unknown-Ukraine-7140'],
  ['4240', '424', '30', 'United Arab Emirates', 'DEFAULT UNITED ARAB EMIRATES'],
  ['42402', '424', '30', 'United Arab Emirates', 'ETISALAT'],
  ['42402', '424', '30', 'United Arab Emirates', 'DU'],
  ['42403', '424', '30', 'United Arab Emirates', 'DU'],
  ['42403', '424', '30', 'United Arab Emirates', 'DU'],
  ['3100', '310', '30', 'United States', 'DEFAULT USA'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'PCS ONE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'PCS ONE'],
  ['310002', '310', '30', 'United States', 'PCS ONE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'PCS ONE'],
  ['310002', '310', '30', 'United States', 'PCS ONE'],
  ['310002', '310', '30', 'United States', 'PCS ONE'],
  ['310002', '310', '30', 'United States', 'PCS ONE'],
  ['310002', '310', '30', 'United States', 'PCS ONE'],
  ['310002', '310', '30', 'United States', 'PCS ONE'],
  ['310002', '310', '30', 'United States', 'PCS ONE'],
  ['310002', '310', '30', 'United States', 'PCS ONE'],
  ['310002', '310', '30', 'United States', 'PCS ONE'],
  ['310002', '310', '30', 'United States', 'PCS ONE'],
  ['310002', '310', '30', 'United States', 'PCS ONE'],
  ['310002', '310', '30', 'United States', 'PCS ONE'],
  ['310002', '310', '30', 'United States', 'PCS ONE'],
  ['310002', '310', '30', 'United States', 'PCS ONE'],
  ['310002', '310', '30', 'United States', 'PCS ONE'],
  ['310002', '310', '30', 'United States', 'PCS ONE'],
  ['310002', '310', '30', 'United States', 'PCS ONE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310002', '310', '30', 'United States', 'T-MOBILE'],
  ['310012', '310', '30', 'United States', 'Verizon Wireless'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310015', '310', '30', 'United States', 'CINGULAR WIRELESS'],
  ['310016', '310', '30', 'United States', 'Cricket Communications'],
  ['310038', '310', '30', 'United States', 'Cable & Wireless'],
  ['310038', '310', '30', 'United States', 'Cable & Wireless'],
  ['310038', '310', '30', 'United States', 'Cable & Wireless'],
  ['310038', '310', '30', 'United States', 'Cable & Wireless'],
  ['310038', '310', '30', 'United States', 'Cable & Wireless'],
  ['310038', '310', '30', 'United States', 'Cable & Wireless'],
  ['310038', '310', '30', 'United States', 'Cable & Wireless'],
  ['310038', '310', '30', 'United States', 'Cable & Wireless'],
  ['310038', '310', '30', 'United States', 'Cable & Wireless'],
  ['310038', '310', '30', 'United States', 'Cable & Wireless'],
  ['310038', '310', '30', 'United States', 'Cable & Wireless'],
  ['310038', '310', '30', 'United States', 'Cable & Wireless'],
  ['310038', '310', '30', 'United States', 'Cable & Wireless'],
  ['310038', '310', '30', 'United States', 'Cable & Wireless'],
  ['310038', '310', '30', 'United States', 'Cable & Wireless'],
  ['310038', '310', '30', 'United States', 'Cable & Wireless'],
  ['310038', '310', '30', 'United States', 'Cable & Wireless'],
  ['310038', '310', '30', 'United States', 'Cable & Wireless'],
  ['310038', '310', '30', 'United States', 'Cable & Wireless'],
  ['310038', '310', '30', 'United States', 'Cable & Wireless'],
  ['310038', '310', '30', 'United States', 'Cable & Wireless'],
  ['310038', '310', '30', 'United States', 'Cable & Wireless'],
  ['310038', '310', '30', 'United States', 'Cable & Wireless'],
  ['310038', '310', '30', 'United States', 'Cable & Wireless'],
  ['310038', '310', '30', 'United States', 'Cable & Wireless'],
  ['310038', '310', '30', 'United States', 'Cable & Wireless'],
  ['310038', '310', '30', 'United States', 'Cable & Wireless'],
  ['310038', '310', '30', 'United States', 'Cable & Wireless'],
  ['310038', '310', '30', 'United States', 'Cable & Wireless'],
  ['310038', '310', '30', 'United States', 'Cable & Wireless'],
  ['310038', '310', '30', 'United States', 'Cable & Wireless'],
  ['310038', '310', '30', 'United States', 'Cable & Wireless'],
  ['310038', '310', '30', 'United States', 'Cable & Wireless'],
  ['310038', '310', '30', 'United States', 'Cable & Wireless'],
  ['310038', '310', '30', 'United States', 'Cable & Wireless'],
  ['310038', '310', '30', 'United States', 'Cable & Wireless'],
  ['310038', '310', '30', 'United States', 'Cable & Wireless'],
  ['310038', '310', '30', 'United States', 'Cable & Wireless'],
  ['310038', '310', '30', 'United States', 'Cable & Wireless'],
  ['310038', '310', '30', 'United States', 'Cable & Wireless'],
  ['310038', '310', '30', 'United States', 'Cable & Wireless'],
  ['310038', '310', '30', 'United States', 'Cable & Wireless'],
  ['310038', '310', '30', 'United States', 'Cable & Wireless'],
  ['310038', '310', '30', 'United States', 'Cable & Wireless'],
  ['310038', '310', '30', 'United States', 'Cable & Wireless'],
  ['310038', '310', '30', 'United States', 'Cable & Wireless'],
  ['310038', '310', '30', 'United States', 'Cable & Wireless'],
  ['310038', '310', '30', 'United States', 'Cable & Wireless'],
  ['310038', '310', '30', 'United States', 'Cable & Wireless'],
  ['310038', '310', '30', 'United States', 'Cable & Wireless'],
  ['310038', '310', '30', 'United States', 'Cable & Wireless'],
  ['310038', '310', '30', 'United States', 'Cable & Wireless'],
  ['310038', '310', '30', 'United States', 'Cable & Wireless'],
  ['310038', '310', '30', 'United States', 'Cable & Wireless'],
  ['310038', '310', '30', 'United States', 'Cable & Wireless'],
  ['310038', '310', '30', 'United States', 'Cable & Wireless'],
  ['310038', '310', '30', 'United States', 'Cable & Wireless'],
  ['310038', '310', '30', 'United States', 'Cable & Wireless'],
  ['310038', '310', '30', 'United States', 'Cable & Wireless'],
  ['310038', '310', '30', 'United States', 'Cable & Wireless'],
  ['310038', '310', '30', 'United States', 'Cable & Wireless'],
  ['310038', '310', '30', 'United States', 'Cable & Wireless'],
  ['310038', '310', '30', 'United States', 'Cable & Wireless'],
  ['310038', '310', '30', 'United States', 'Cable & Wireless'],
  ['310038', '310', '30', 'United States', 'Cable & Wireless'],
  ['310038', '310', '30', 'United States', 'Cable & Wireless'],
  ['310038', '310', '30', 'United States', 'Cable & Wireless'],
  ['310038', '310', '30', 'United States', 'Cable & Wireless'],
  ['310038', '310', '30', 'United States', 'Cable & Wireless'],
  ['310038', '310', '30', 'United States', 'Cable & Wireless'],
  ['310038', '310', '30', 'United States', 'Cable & Wireless'],
  ['310038', '310', '30', 'United States', 'Cable & Wireless'],
  ['310038', '310', '30', 'United States', 'Cable & Wireless'],
  ['310038', '310', '30', 'United States', 'Cable & Wireless'],
  ['310038', '310', '30', 'United States', 'Cable & Wireless'],
  ['310038', '310', '30', 'United States', 'Cable & Wireless'],
  ['310038', '310', '30', 'United States', 'Cable & Wireless'],
  ['310038', '310', '30', 'United States', 'Cable & Wireless'],
  ['310038', '310', '30', 'United States', 'Cable & Wireless'],
  ['310038', '310', '30', 'United States', 'Cable & Wireless'],
  ['310038', '310', '30', 'United States', 'Cable & Wireless'],
  ['310038', '310', '30', 'United States', 'Cable & Wireless'],
  ['310038', '310', '30', 'United States', 'Cable & Wireless'],
  ['310038', '310', '30', 'United States', 'Cable & Wireless'],
  ['310038', '310', '30', 'United States', 'Cable & Wireless'],
  ['310038', '310', '30', 'United States', 'Cable & Wireless'],
  ['310180', '310', '30', 'United States', 'West Central Wireless'],
  ['310770', '310', '30', 'United States', 'Iowa Wireless'],
  ['7480', '748', '30', 'Uruguay', 'DEFAULT URUGUAY'],
  ['74801', '748', '30', 'Uruguay', 'ANCEL GSM'],
  ['74802', '748', '30', 'Uruguay', 'ABIATAR S.A'],
  ['74807', '748', '30', 'Uruguay', 'Movistar'],
  ['74807', '748', '30', 'Uruguay', 'Movistar'],
  ['74810', '748', '30', 'Uruguay', 'AM WIRELESS'],
  ['4340', '434', '30', 'Uzbekistan', 'DEFAULT UZBEKISTAN'],
  ['43401', '434', '30', 'Uzbekistan', 'Beeline (Unitel)'],
  ['43403', '434', '30', 'Uzbekistan', 'UzMobile'],
  ['43403', '434', '30', 'Uzbekistan', 'UzMobile'],
  ['43403', '434', '30', 'Uzbekistan', 'UzMobile'],
  ['43403', '434', '30', 'Uzbekistan', 'UzMobile'],
  ['43404', '434', '30', 'Uzbekistan', 'UNITEL'],
  ['43405', '434', '30', 'Uzbekistan', 'COSCOM'],
  ['43406', '434', '30', 'Uzbekistan', 'Perfectum Mobile (Rubicom)'],
  ['43407', '434', '30', 'Uzbekistan', 'UZDUNROBITA'],
  ['43408', '434', '30', 'Uzbekistan', 'UzMobile'],
  ['43408', '434', '30', 'Uzbekistan', 'UzMobile'],
  ['43408', '434', '30', 'Uzbekistan', 'UzMobile'],
  ['43408', '434', '30', 'Uzbekistan', 'UzMobile'],
  ['5410', '541', '30', 'Vanuatu', 'DEFAULT VANUATU'],
  ['54101', '541', '30', 'Vanuatu', 'SMILE'],
  ['54101', '541', '30', 'Vanuatu', 'SMILE'],
  ['7340', '734', '30', 'Venezuela', 'DEFAULT VENEZUELA'],
  ['73401', '734', '30', 'Venezuela', 'INFONET'],
  ['73401', '734', '30', 'Venezuela', 'MOVILNET'],
  ['73402', '734', '30', 'Venezuela', 'DIGITEL'],
  ['73403', '734', '30', 'Venezuela', 'DIGICEL'],
  ['73404', '734', '30', 'Venezuela', 'Movistar - Telcel'],
  ['73404', '734', '30', 'Venezuela', 'Movistar - Telcel'],
  ['73406', '734', '30', 'Venezuela', 'Movilnet'],
  ['4520', '452', '30', 'Vietnam', 'DEFAULT VIETNAM'],
  ['452001', '452', '30', 'Vietnam', 'VMS MOBIFONE'],
  ['452001', '452', '30', 'Vietnam', 'VMS MOBIFONE'],
  ['452001', '452', '30', 'Vietnam', 'VMS MIFONE'],
  ['452002', '452', '30', 'Vietnam', 'VINA'],
  ['452002', '452', '30', 'Vietnam', 'VINA'],
  ['452002', '452', '30', 'Vietnam', 'VINAPHONE'],
  ['452002', '452', '30', 'Vietnam', 'VINAPHONE'],
  ['452002', '452', '30', 'Vietnam', 'VINAPHONE'],
  ['452002', '452', '30', 'Vietnam', 'VINAPHONE'],
  ['452002', '452', '30', 'Vietnam', 'VINA'],
  ['452002', '452', '30', 'Vietnam', 'VINA'],
  ['452004', '452', '30', 'Vietnam', 'VIETTEL'],
  ['452004', '452', '30', 'Vietnam', 'VIETTEL'],
  ['452004', '452', '30', 'Vietnam', 'VIETTEL'],
  ['452004', '452', '30', 'Vietnam', 'VIETTEL'],
  ['452004', '452', '30', 'Vietnam', 'VIETTEL'],
  ['452004', '452', '30', 'Vietnam', 'VIETTEL'],
  ['452004', '452', '30', 'Vietnam', 'VIETTEL'],
  ['452004', '452', '30', 'Vietnam', 'VIETTEL'],
  ['452004', '452', '30', 'Vietnam', 'VIETTEL'],
  ['452004', '452', '30', 'Vietnam', 'VIETTEL'],
  ['452004', '452', '30', 'Vietnam', 'VIETTEL'],
  ['452007', '452', '30', 'Vietnam', 'Beeline'],
  ['452007', '452', '30', 'Vietnam', 'Beeline'],
  ['45201', '452', '30', 'Vietnam', 'Mobifone'],
  ['45201', '452', '30', 'Vietnam', 'Mobifone'],
  ['45201', '452', '30', 'Vietnam', 'Mobifone'],
  ['45202', '452', '30', 'Vietnam', 'VinaPhone'],
  ['45202', '452', '30', 'Vietnam', 'VinaPhone'],
  ['45202', '452', '30', 'Vietnam', 'VinaPhone'],
  ['45202', '452', '30', 'Vietnam', 'VinaPhone'],
  ['45202', '452', '30', 'Vietnam', 'VinaPhone'],
  ['45202', '452', '30', 'Vietnam', 'VinaPhone'],
  ['45202', '452', '30', 'Vietnam', 'VinaPhone'],
  ['45202', '452', '30', 'Vietnam', 'VinaPhone'],
  ['45204', '452', '30', 'Vietnam', 'Viettel Mobile'],
  ['45204', '452', '30', 'Vietnam', 'Viettel Mobile'],
  ['45205', '452', '30', 'Vietnam', 'Vietnamobile (Hanoi Telecom)'],
  ['45205', '452', '30', 'Vietnam', 'Vietnamobile (Hanoi Telecom)'],
  ['45205', '452', '30', 'Vietnam', 'Vietnamobile (Hanoi Telecom)'],
  ['45207', '452', '30', 'Vietnam', 'Gtel (Beeline)'],
  ['45207', '452', '30', 'Vietnam', 'Gtel (Beeline)'],
  ['45207', '452', '30', 'Vietnam', 'Gtel (Beeline)'],
  ['45207', '452', '30', 'Vietnam', 'Gtel (Beeline)'],
  ['45207', '452', '30', 'Vietnam', 'Gtel (Beeline)'],
  ['452501', '452', '30', 'Vietnam', 'HTC'],
  ['452502', '452', '30', 'Vietnam', 'SPT'],
  ['452507', '452', '30', 'Vietnam', 'VSAT'],
  ['4210', '421', '30', 'Yemen', 'DEFAULT YEMEN'],
  ['42101', '421', '30', 'Yemen', 'SABAFON'],
  ['42102', '421', '30', 'Yemen', 'SPACETEL'],
  ['42103', '421', '30', 'Yemen', 'YEMENTEL'],
  ['42104', '421', '30', 'Yemen', 'UNITEL'],
  ['64502', '545', '30', 'Zambia', 'TELECEL'],
  ['6450', '645', '30', 'Zambia', 'DEFAULT ZAMBIA'],
  ['64501', '645', '30', 'Zambia', 'CELTEL ZAMBIA'],
  ['64502', '645', '30', 'Zambia', 'MTN Zambia'],
  ['64502', '645', '30', 'Zambia', 'MTN Zambia'],
  ['64503', '645', '30', 'Zambia', 'Zamtel'],
  ['6480', '648', '30', 'Zimbabwe', 'DEFAULT ZIMBABWE'],
  ['64801', '648', '30', 'Zimbabwe', 'NET ONE'],
  ['64801', '648', '30', 'Zimbabwe', 'NET ONE'],
  ['64803', '648', '30', 'Zimbabwe', 'TELECEL'],
  ['64803', '648', '30', 'Zimbabwe', 'TELECEL'],
  ['64804', '648', '30', 'Zimbabwe', 'ECONET'],
  ['64804', '648', '30', 'Zimbabwe', 'ECONET'],
  ['64804', '648', '30', 'Zimbabwe', 'ECONET']
];
