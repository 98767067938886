import { API, Auth } from 'aws-amplify';

const getLogsErros = async () => {
  const auth = await Auth.currentAuthenticatedUser();
  const token = auth.signInUserSession.idToken.jwtToken;
  try {
    const req = await API.get('sms-api', '/get-logs-errors', {
      headers: {
        Authorization: token
      }
    });
    return req;
  } catch (err) {
    console.log(err);
    console.log(err.response);
  }
};

export { getLogsErros };
