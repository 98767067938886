import React from 'react';
import { Popconfirm, message, Spin } from 'antd';
import {
  EditOutlined,
  SaveOutlined,
  DeleteOutlined,
  ThunderboltOutlined,
  PlusCircleOutlined,
  EuroCircleOutlined,
  EyeOutlined
} from '@ant-design/icons';
import { saveToMcore, checkUsersExceptions } from '../Users/services';
import { useStateLabel } from '../../libs/debug';
import { DEFAULT_USERS } from './../../config';
import { flagEmoji } from '../../libs/helpers';
import './users.css';

const Actions = params => {
  let {
    current,
    userIdEditing,
    setUserIdEditing,
    setLoadingCozSaving,
    isMultiselector,
    setIsMultiselector,
    routesChanges,
    resetTable,
    resetChanges,
    isForceUpdating
  } = params;

  const [defaultIsEditing, setDefaultIsEditing] = useStateLabel(
    false,
    'defaultIsEditing'
  );
  const [isActive, setIsActive] = useStateLabel(false, 'isActive');
  const [exceptions, setExceptions] = useStateLabel([], 'exceptions');
  const [exceptionsTitle, setExceptionsTitle] = useStateLabel(
    '',
    'exceptionsTitle'
  );
  message.config({
    duration: 5
  });

  const saveChanges = async () => {
    await saveToMcore(routesChanges);
  };

  const checkUsersExceptionsRequest = async () => {
    const usersExceptions = await checkUsersExceptions(routesChanges);
    setExceptions(usersExceptions);
    setExceptionsTitle(
      usersExceptions.length ? 'Users exceptions' : 'No exceptions to show'
    );
  };

  const getIcon = {
    price: <EuroCircleOutlined />,
    added: <PlusCircleOutlined />,
    removed: <DeleteOutlined />
  };

  return (
    <>
      <a
        onClick={async () => {
          setUserIdEditing(current.id);
          setDefaultIsEditing(
            DEFAULT_USERS.find(({ id }) => id === current.id)
          );
        }}
        disabled={userIdEditing && current.id !== userIdEditing}
      >
        {current.id !== userIdEditing && <EditOutlined />}
      </a>
      <Popconfirm
        title="The changes will be cancelled"
        onConfirm={() => {
          resetTable();
          setDefaultIsEditing(false);
        }}
        cancelText="No, wait"
        okText="Yes, cancel them"
      >
        <a>{current.id === userIdEditing ? <DeleteOutlined /> : null}</a>
      </Popconfirm>
      <a
        style={{ marginLeft: '15px' }}
        onClick={async () => {
          let newArr = isMultiselector;
          if (isMultiselector.includes(current.id)) {
            newArr = newArr.filter(id => id !== current.id);
            message.warning('Multiselector desactivated');
          } else {
            newArr.push(current.id);
            message.success('Multiselector activated');
          }
          setIsActive(newArr.includes(current.id)); // it doesn't do anything, but if removed, className doesn't work ??
          setIsMultiselector(newArr);
        }}
      >
        <ThunderboltOutlined
          className={!isMultiselector.includes(current.id) && 'disabledColor'}
        />
      </a>
      {DEFAULT_USERS.find(({ id }) => id === current.id) && (
        <Popconfirm
          title={
            <div>
              {exceptionsTitle ? (
                <span style={{ color: '#241dba' }}>{exceptionsTitle}</span>
              ) : (
                <div
                  style={{
                    margin: '25px'
                  }}
                >
                  <Spin />
                </div>
              )}
              <br />
              {exceptions.map((c, idx) => (
                <div>
                  <span style={{ color: '#241dba' }} key={++idx}>
                    {c.userName}
                  </span>
                  <br />
                  {c.routes.map((c, idx2) => (
                    <span key={++idx2}>
                      <a>{getIcon[c.change]} </a>
                      {c.country} {flagEmoji(c.country)} | {c.mccmnc}
                      {c.change === 'added' && ` | ${c.exceptionText}`}
                      {c.change === 'removed' && ` | ${c.exceptionText}`}
                      {c.change === 'price' && ` | ${parseFloat(c.price)}€`}
                      <br />
                    </span>
                  ))}
                  <br />
                </div>
              ))}
            </div>
          }
          loading={true}
          okText="Ok"
          onConfirm={() => {
            setExceptions([]);
            setExceptionsTitle('');
          }}
          cancelButtonProps={{ className: 'd-none' }}
        >
          <a
            disabled={
              !routesChanges.find(r => `${r.userId}` === `${current.id}`)
            }
            onClick={async () => {
              checkUsersExceptionsRequest();
            }}
          >
            <EyeOutlined style={{ marginLeft: '15px' }} />
          </a>
        </Popconfirm>
      )}
      <Popconfirm
        title={
          <div>
            {!isForceUpdating
              ? defaultIsEditing
                ? 'This is a Default user, others users with these routes will be affected'
                : 'These changes will be saved'
              : 'Saving is forbidden while force update is running'}
            <br />
            {!isForceUpdating &&
              routesChanges.map((c, idx) => (
                <span key={++idx}>
                  <a>{getIcon[c.change]} </a>
                  {c.country} {flagEmoji(c.country)} | {c.mccmnc}
                  {c.change === 'added' && ` | ${c.price}€`}
                  {c.change === 'price' && ` | ${c.oldPrice}€ → ${c.price}€`}
                  <br />
                </span>
              ))}
          </div>
        }
        onConfirm={async () => {
          if (!isForceUpdating) {
            setLoadingCozSaving(true);
            try {
              await saveChanges();
              // resetTable();
              resetChanges();
            } catch (err) {
              console.log(err);
            }
          }
        }}
        cancelText={!isForceUpdating ? 'No, wait' : ''}
        okText={!isForceUpdating ? 'Yes, save them' : 'Ok'}
      >
        <a
          disabled={!routesChanges.find(r => `${r.userId}` === `${current.id}`)}
        >
          <SaveOutlined style={{ marginLeft: '15px' }} />
        </a>
      </Popconfirm>
    </>
  );
};

export default Actions;
