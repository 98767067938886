import React, { useState } from 'react';
import { Typography, Row, Input, Timeline, Popover, Tag, Button } from 'antd';
import { ClockCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { checkHlrService } from './services';
import MccMncKey from '../../libs/MccMncKey';
import { HLR_ERRORS } from '../../config/';
import { flagEmoji } from '../../libs/helpers';

const Hlr = () => {
  const { Title, Text } = Typography;
  const { TextArea } = Input;
  const [checkLoading, setCheckLoading] = useState(false);
  const [textContent, setTextContent] = useState('');
  const [events, setEvents] = useState([]);
  let eventsMutated;

  const onCheck = async () => {
    const phonesList = textContent.split(/[ ,\n]+/).filter(n => n);
    if (phonesList.length) {
      setCheckLoading(true);
      eventsMutated = [...events];
      phonesList.forEach(phone => {
        eventsMutated.push({
          phone: phone,
          waiting: true
        });
      });
      setEvents(eventsMutated);
      try {
        await Promise.all(
          phonesList.map(async phone => {
            const req = await checkHlrService({ phone: phone });
            eventsMutated.find(e => {
              if (e.phone === phone && req?.live) {
                e.waiting = false;
                if (req.live.rc !== '000') {
                  e.error = HLR_ERRORS[req.live.rc];
                } else {
                  e.result = (
                    <>
                      <span>{`cc → ${req.live.cc}`}</span>
                      <br />
                      <span>{`mcc → ${req.live.mcc}`}</span>
                      <br />
                      <span>
                        ported →{' '}
                        <Tag color="blue">{`${
                          req.live.mnc !== req.gnr.mnc
                        }`}</Tag>
                      </span>
                      <br />
                      <br />
                      <span>Current</span>
                      <br />
                      <span>{`mnc → ${req.live.mnc}`}</span>
                      <br />
                      <span>
                        {`network → ${
                          MccMncKey[`${req.live.mcc}${req.live.mnc}`]
                            ?.network || 'unknown'
                        }`}
                      </span>
                      <br />
                      <br />
                      {req.live.mnc !== req.gnr.mnc && (
                        <>
                          <span>Original</span>
                          <br />
                          <span>{`mnc → ${req.gnr?.mnc || 'unknown'}`}</span>
                          <br />
                          <span>
                            {`network → ${
                              MccMncKey[`${req.gnr.mcc}${req.gnr.mnc}`]
                                ?.network ||
                              req.gnr.cn ||
                              'unknown'
                            }`}
                          </span>
                        </>
                      )}
                    </>
                  );
                  e.flag = flagEmoji(req.live.cc);
                  e.message = 'Ok';
                }
              } else if (e.phone === phone) {
                e.waiting = false;
                e.error = 'Unknown error';
              }
            });
          })
        );
      } catch (error) {
        console.log(error);
      }
      setCheckLoading(false);
    }
  };

  return (
    <>
      <Row justify="center" style={{ marginTop: '25px' }}>
        <div
          style={{
            backgroundColor: '#fff',
            padding: '0 25px',
            display: 'flex',
            flexFlow: 'column'
          }}
        >
          <Title level={3}>Input number/s to lookup</Title>
          <TextArea
            placeholder="MSISDN1,MSISDN2,..."
            allowClear
            size="large"
            style={{ marginTop: '5px' }}
            onChange={e => {
              setTextContent(e.target.value);
            }}
          />
          <Button
            style={{ marginTop: '15px', marginLeft: 'auto' }}
            shape="round"
            type="primary"
            htmlType="submit"
            loading={checkLoading}
            onClick={onCheck}
          >
            Check
          </Button>
        </div>
      </Row>
      <Row justify="center">
        {events.length ? (
          <Timeline
            style={{
              backgroundColor: '#fff',
              padding: '25px',
              marginTop: '25px'
            }}
          >
            {events.map(e => (
              <Timeline.Item
                color={!e.waiting ? ('error' in e ? 'red' : 'green') : null}
                dot={e.waiting ? <ClockCircleOutlined /> : null}
              >
                {e.message || e.error ? (
                  <>
                    {`Checked ${e.flag ? e.flag : ''} +${e.phone} → `}
                    <Text type={'error' in e ? 'danger' : 'success'}>
                      {e.message || e.error}
                    </Text>
                    {!('error' in e) && (
                      <Popover content={e.result} title="Results">
                        <QuestionCircleOutlined
                          style={{ marginLeft: '10px' }}
                        />
                      </Popover>
                    )}
                  </>
                ) : (
                  'Checking...'
                )}
              </Timeline.Item>
            ))}
          </Timeline>
        ) : null}
      </Row>
    </>
  );
};

export default Hlr;
