import React, { useState } from 'react';
import 'antd/dist/antd.less';
import {
  Table,
  Tag,
  Alert,
  InputNumber,
  Button,
  Typography,
  message
} from 'antd';
import {
  PlusCircleOutlined,
  CheckOutlined,
  RedoOutlined,
  PlusOutlined,
  ThunderboltOutlined
} from '@ant-design/icons';
import Dragger from './Dragger.js';
import { flagEmoji } from '../../libs/helpers';
import '../UsersManagement/users.css';

const UsersUpload = params => {
  const {
    userName,
    routesChanges,
    setRoutesChanges,
    isMultiselector,
    setIsMultiselector,
    userPricelist,
    userIdEditing,
    setShowAddCountries
  } = params;
  const { Title } = Typography;
  const [pricelistUploaded, setPricelistUploaded] = useState();
  const [routesChangesLocal, setRoutesChangesLocal] = useState([]);
  const [errorMessage, setErrorMessage] = useState();

  const findChange = (mccmnc, type) =>
    routesChangesLocal.find(c => c.mccmnc === mccmnc && c.change === type);

  const routeItem = (r, mcc, mnc, value, action) => {
    let price = value;
    if (!price) {
      price = findChange(r.mccmnc, 'price')?.price || r.cost;
    }
    return {
      userId: userIdEditing,
      country: r.country,
      network: r.network,
      mccmnc: r.mccmnc,
      mcc: mcc,
      mnc: mnc,
      cost: r.cost,
      price: price,
      change: action,
      lowProfit: price - r.cost < 0.0007
    };
  };

  const routeOnChange = (r, action, value) => {
    let changes;
    let block;
    const [mcc, mnc] = r.mccmnc.match(/.{1,3}/g);
    if (isMultiselector.includes(userIdEditing)) {
      block = pricelistUploaded
        .filter(({ mccmnc }) => mccmnc.slice(0, 3) === mcc)
        .map(r => routeItem(r, mcc, r.mccmnc.slice(3), value, action));
    } else {
      block = [routeItem(r, mcc, mnc, value, action)];
    }
    const changesUniqs = routesChangesLocal.filter(
      c => !block?.find(r => r.mccmnc === c.mccmnc && r.change === c.change)
    );
    changes = [...changesUniqs, ...block];
    setRoutesChangesLocal(changes);
  };

  const step = cost => {
    const zeros = cost?.toString().length - 3;
    return zeros > 0 ? `0.${'0'.repeat(zeros)}1` : 0;
  };

  const reset = () => {
    const removeUserChanges = routesChangesLocal.filter(
      ({ userId }) => userId !== userIdEditing
    );
    setRoutesChangesLocal(removeUserChanges);
  };

  const columns = [
    {
      title: 'Country',
      render: r => (
        <>
          <span style={{ marginRight: '5px' }}>
            {findChange(r.mccmnc, 'added') ? (
              <CheckOutlined />
            ) : (
              <a
                onClick={() => {
                  routeOnChange(r, 'added');
                }}
              >
                <PlusCircleOutlined />
              </a>
            )}
          </span>
          {r.country} {flagEmoji(r.country)}
        </>
      )
    },
    {
      title: 'Operator',
      dataIndex: 'network',
      render: network =>
        network.length > 25 ? `${network.slice(0, 25)}...` : network
    },
    {
      title: 'MCCMNC',
      dataIndex: 'mccmnc'
    },
    {
      title: 'Buying €',
      dataIndex: 'cost',
      render: price => <span>{<Tag color={'green'}>{price}</Tag>}</span>
    },
    {
      title: 'Selling €',
      render: r => (
        <span>
          <InputNumber
            size="small"
            value={getValue(r)}
            step={step(r.cost)}
            onChange={routeOnChange.bind(null, r, 'price')}
            disabled={findChange(r.mccmnc, 'added')}
          />
        </span>
      )
    },
    {
      title: 'Profit €',
      render: r => {
        const newPrice = findChange(r.mccmnc, 'price')?.price;
        const profit = newPrice ? (newPrice - r.cost).toFixed(4) : 0;
        return (
          <span>
            {<Tag color={profit > 0.0007 ? 'green' : 'red'}>{profit}</Tag>}
          </span>
        );
      }
    }
  ];

  const getValue = r => {
    const newPrice = routesChangesLocal.find(
      ({ mccmnc }) => mccmnc === r.mccmnc
    )?.price;
    return routesChangesLocal.length && newPrice ? newPrice : r.cost;
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          margin: '25px 0'
        }}
      >
        <span
          style={{
            marginRight: 'auto'
          }}
        >
          <Button
            onClick={() => {
              let changeThisId = isMultiselector;
              if (isMultiselector.includes(userIdEditing)) {
                changeThisId = changeThisId.filter(id => id !== userIdEditing);
                message.warning('Multiselector desactivated');
              } else {
                changeThisId.push(userIdEditing);
                message.success('Multiselector activated');
              }
              setIsMultiselector(changeThisId);
            }}
            type="primary"
            shape="round"
            icon={<ThunderboltOutlined />}
            style={{
              marginRight: '10px'
            }}
            ghost={!isMultiselector.includes(userIdEditing)}
          >
            Multiselector
          </Button>
        </span>
        <span
          style={{
            marginLeft: '35px'
          }}
        >
          <Title level={5}>{userName}</Title>
        </span>
        <span
          style={{
            marginLeft: 'auto'
          }}
        >
          <Button
            type="primary"
            shape="round"
            icon={<PlusOutlined />}
            style={{
              marginRight: '10px'
            }}
            onClick={() => {
              setRoutesChanges([
                ...routesChanges,
                ...routesChangesLocal.filter(({ change }) => change === 'added')
              ]);
              setShowAddCountries(false);
            }}
            disabled={
              !routesChangesLocal.find(({ change }) => change === 'added')
            }
          >
            Add
          </Button>
        </span>
        <span>
          <Button
            type="primary"
            shape="round"
            icon={<RedoOutlined />}
            onClick={() => {
              reset();
            }}
            disabled={!routesChangesLocal.length}
          >
            Reset
          </Button>
        </span>
      </div>
      {errorMessage && (
        <Alert
          message="Warning"
          description={errorMessage}
          type="error"
          showIcon
          style={{ marginBottom: '15px' }}
        />
      )}
      <Table
        loading={!userPricelist.length}
        dataSource={pricelistUploaded}
        columns={columns}
        size="small"
        rowClassName={row =>
          routesChangesLocal.find(({ mccmnc }) => mccmnc === row.mccmnc)
            ? 'rowChanged'
            : ''
        }
      />
      <div
        style={{
          height: '200px'
        }}
      >
        <Dragger
          setErrorMessage={setErrorMessage}
          setPricelistUploaded={setPricelistUploaded}
          userPricelist={userPricelist}
        />
      </div>
    </>
  );
};

export default UsersUpload;
