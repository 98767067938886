import { notification } from 'antd';

const changeConjugation = str => {
  if (str === 'sending') {
    return 'sent';
  }
  return str.includes('ing') ? `${str.split('ing')[0]}ed` : str;
};

const getTitle = ({ action, what }) => ({
  info: `${action.charAt(0).toUpperCase()}${action.slice(1)} ${what}...`,
  success: `${what.charAt(0).toUpperCase()}${what.slice(1)} ${changeConjugation(
    action
  )}`,
  error: `Error ${action} ${what}`
});

const notificationUI = ({
  state,
  action,
  what,
  description = null,
  duration = 0,
  key
}) => {
  notification[state]({
    message: getTitle({ action: action, what: what })[state],
    description: description,
    duration: duration,
    key: key
  });
};

export default notificationUI;
