export default {
  Greece: 'gr',
  Netherlands: 'nl',
  Belgium: 'be',
  France: 'fr',
  Monaco: 'mc',
  Andorra: 'ad',
  Spain: 'es',
  Hungary: 'hu',
  'Bosnia-Herzegovina': 'ba',
  Croatia: 'hr',
  Serbia: 'rs',
  Italy: 'it',
  Romania: 'ro',
  Switzerland: 'ch',
  'Czech Republic': 'cz',
  'Slovak Republic': 'sk',
  Austria: 'at',
  'United Kingdom': 'gb',
  Denmark: 'dk',
  Sweden: 'se',
  Norway: 'no',
  Finland: 'fi',
  Lithuania: 'lt',
  Latvia: 'lv',
  Estonia: 'ee',
  'Russian Federation': 'ru',
  Ukraine: 'ua',
  Belarus: 'by',
  Moldavia: 'md',
  Poland: 'pl',
  Germany: 'de',
  Gibraltar: 'gi',
  Portugal: 'pt',
  Luxembourg: 'lu',
  Ireland: 'ie',
  Iceland: 'is',
  Albania: 'al',
  Malta: 'mt',
  Cyprus: 'cy',
  'Georgia Republic': 'ge',
  Armenia: 'am',
  Bulgaria: 'bg',
  Turkey: 'tr',
  'Faroe Islands': 'fo',
  Greenland: 'gl',
  'San Marino': 'sm',
  Slovenia: 'si',
  Macedonia: 'mk',
  Liechtenstein: 'li',
  Montenegro: 'me',
  'St. Pierre & Miquelon': 'pm',
  USA: 'us',
  'Puerto Rico': 'pr',
  Mexico: 'mx',
  'French Guiana': 'fg',
  Guadeloupe: 'gp',
  'Martinique (French)': 'mq',
  Bermuda: 'bm',
  'Saint Kitts and Nevis': 'kn',
  'Saint Lucia': 'lc',
  'St. Vincent & Gren.': 'vc',
  'Netherlands Antilles': 'an',
  Curacao: 'cw',
  Aruba: 'aw',
  Bahamas: 'bs',
  Cuba: 'cu',
  'Dominican Republic': 'do',
  Haiti: 'ht',
  'Trinidad and Tobago': 'tt',
  'Virgin Islands, U.S.': 'vi',
  Azerbaijan: 'az',
  Kazakhstan: 'kz',
  Bhutan: 'bt',
  India: 'in',
  Pakistan: 'pk',
  Afghanistan: 'af',
  'Sri Lanka': 'lk',
  Myanmar: 'mm',
  Lebanon: 'lb',
  Jordan: 'jo',
  Syria: 'sy',
  Iraq: 'iq',
  Kuwait: 'kw',
  'Saudi Arabia': 'sa',
  Yemen: 'ye',
  Oman: 'om',
  'United Arab Emirates': 'ae',
  Israel: 'il',
  'Palestinian Territory': 'ps',
  Bahrain: 'bh',
  Qatar: 'qa',
  Mongolia: 'mn',
  Nepal: 'np',
  Iran: 'ir',
  Uzbekistan: 'uz',
  Tajikistan: 'tk',
  Kyrgyzstan: 'kg',
  Turkmenistan: 'tm',
  Japan: 'jp',
  'South Korea': 'kr',
  Vietnam: 'vn',
  'Hong Kong': 'hk',
  Macao: 'mo',
  Cambodia: 'kh',
  Laos: 'la',
  China: 'cn',
  Taiwan: 'tw',
  Bangladesh: 'bd',
  Maldives: 'mv',
  Malaysia: 'my',
  Australia: 'au',
  Indonesia: 'id',
  'Timor-Leste': 'tp',
  Philippines: 'ph',
  Thailand: 'th',
  Singapore: 'sg',
  'Brunei Darussalam': 'bn',
  'New Zealand': 'nz',
  'Papua New Guinea': 'pg',
  Tonga: 'to',
  'Solomon Islands': 'sb',
  Vanuatu: 'vu',
  Fiji: 'fj',
  'American Samoa': 'as',
  Kiribati: 'ki',
  'New Caledonia': 'nc',
  'French Polynesia': 'pf',
  'Cook Islands': 'ck',
  Samoa: 'ws',
  Micronesia: 'fm',
  'Palau (Republic of)': 'pw',
  Tuvalu: 'tv',
  Niue: 'nu',
  Egypt: 'eg',
  Algeria: 'dz',
  Morocco: 'ma',
  Tunisia: 'tn',
  Libya: 'ly',
  Gambia: 'gm',
  Senegal: 'sn',
  Mauritania: 'mr',
  Mali: 'ml',
  Guinea: 'gn',
  'Ivory Coast': 'ci',
  'Burkina Faso': 'bf',
  Niger: 'ne',
  Togo: 'tg',
  Benin: 'bj',
  Mauritius: 'mu',
  Liberia: 'lr',
  'Sierra Leone': 'sl',
  Ghana: 'gh',
  Nigeria: 'ng',
  Chad: 'td',
  'Central Africa': 'cf',
  Cameroon: 'cm',
  'Cape Verde Island': 'cv',
  'Sao Tome & Principe': 'st',
  'Equatorial Guinea': 'gq',
  Gabon: 'ga',
  'Congo, Republic': 'cg',
  'Democratic Republic of the Congo': 'cd',
  Angola: 'ao',
  'Guinea-Bissau': 'gw',
  Seychelles: 'sc',
  Sudan: 'sd',
  Rwanda: 'rw',
  Ethiopia: 'et',
  Somalia: 'so',
  Djibouti: 'dj',
  Kenya: 'ke',
  Tanzania: 'tz',
  Uganda: 'ug',
  Burundi: 'bi',
  Mozambique: 'mz',
  Zambia: 'zm',
  Madagascar: 'mg',
  'Reunion Island': 're',
  Zimbabwe: 'zw',
  Namibia: 'na',
  Malawi: 'mw',
  Lesotho: 'ls',
  Botswana: 'bw',
  Swaziland: 'sz',
  'Comoros Islands': 'km',
  'South Africa': 'za',
  Eritrea: 'er',
  'South Sudan (Republic of)': 'ss',
  Belize: 'bz',
  Guatemala: 'gt',
  'El Salvador': 'sv',
  Nicaragua: 'ni',
  'Costa Rica': 'cr',
  Panama: 'pa',
  Peru: 'pe',
  Argentina: 'ar',
  Brazil: 'br',
  Chile: 'cl',
  Venezuela: 've',
  Bolivia: 'bo',
  Guyana: 'gy',
  Ecuador: 'ec',
  Paraguay: 'py',
  Suriname: 'sr',
  Uruguay: 'uy',
  'Satellite Networks': 'n/a',
  Canada: 'ca',
  Guam: 'gu',
  Jamaica: 'jm',
  Barbados: 'bb',
  'Antigua and Barbuda': 'ag',
  'Cayman Islands': 'ky',
  'British Virgin Islands': 'vg',
  Grenada: 'gd',
  Montserrat: 'ms',
  Anguilla: 'ai',
  Dominica: 'dm',
  'Turks and Caicos Islands': 'tc',
  "Korea N., Dem. People's Rep.": 'kp',
  Honduras: 'hn',
  Colombia: 'co',
  'Falkland Islands (Malvinas)': 'fk'
};
