import React, { useState } from 'react';
import { Layout as LayoutAnt, Tag, Image } from 'antd';
import Menu from './Menu';

// Menu.forEach(r => {
//   if (!un.find(ro => ro[0] === r[0])) {
//     un.push(r);
//   }
// });

const Layout = params => {
  const [collapsed, setCollapsed] = useState(false);
  const { Content, Sider } = LayoutAnt;
  return (
    <LayoutAnt style={{ minHeight: '100vh' }}>
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={() => setCollapsed(!collapsed)}
      >
        <Menu collapsed={collapsed} />
      </Sider>
      <LayoutAnt>
        {process.env.REACT_APP_STAGE === 'dev' && (
          <Tag color="#f50" style={{ position: 'absolute', margin: '15px' }}>
            DEV
          </Tag>
        )}
        <Content>{params.content}</Content>
        {/* <Image
          width={100}
          src="https://www.tadoo.biz/themes/custom/tadoo/logo.png"
          style={{ margin: 'auto', marginTop: '15px', marginBottom: '5px' }}
        /> */}
        <div
          style={{
            display: 'flex',
            justifyContent: 'right',
            marginBottom: '5px'
          }}
        >
          <Tag>
            <span>{`PR#${process.env.REACT_APP_PR_ID}`}</span>
          </Tag>
        </div>
      </LayoutAnt>
    </LayoutAnt>
  );
};

export default Layout;
