import PQueue from 'p-queue';
import { sendEmailService } from './services';

const sendEmail = async ({
  userData,
  newPrices,
  pricelist,
  removedCountries
}) => {
  const { name, id, address } = userData;
  let routes = [];
  if (newPrices && Object.keys(newPrices).length) {
    routes = Object.keys(newPrices).map(key => {
      const r = { ...newPrices[key] };
      if (r.change === 'Added') {
        r.priceOriginal = '-';
      } else {
        r.change = r.price > r.priceOriginal ? 'Increase ↑' : 'Decrease ↓';
      }
      return r;
    });
  }
  if (removedCountries && id in removedCountries) {
    removedCountries[id].forEach(route => {
      routes.push({
        country: route.country,
        mccmnc: route.mccmnc,
        network: route.network,
        price: '-',
        priceOriginal: '-',
        change: 'Removed'
      });
    });
  }
  const priceToSend = Object.keys(pricelist).map(key => {
    const r = pricelist[key];
    delete r.table;
    delete r.vendor;
    delete r.cost;
    delete r.user;
    delete r.changed;
    delete r.idx;
    r.mnc = r.mnc === '-' ? '' : r.mnc;
    return r;
  });
  const params = {
    userName: name,
    to: address,
    routesAll: priceToSend,
    userPricelist: routes
  };
  try {
    await sendEmailService(params);
  } catch (err) {
    console.log(err);
  }
};

const sendEmails = async ({
  usersData,
  newPrices,
  pricelist,
  removedCountries
}) => {
  const queue = new PQueue({
    concurrency: 5,
    intervalCap: 5,
    interval: 1 * 1000,
    timeout: 1 * 1000
  });
  const emails = usersData.map(
    u => () =>
      sendEmail({
        userData: u,
        newPrices: newPrices[u.id],
        pricelist: pricelist[u.id],
        removedCountries: removedCountries
      })
  );
  try {
    await queue.addAll(emails);
    queue.clear();
  } catch (err) {
    console.log(err);
  }
};

export { sendEmail, sendEmails };
