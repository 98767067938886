import { API, Auth } from 'aws-amplify';

async function checkHlrService({ phone, auth }) {
  const currentAuth = await Auth.currentAuthenticatedUser();
  try {
    const res = await API.get('sms-api', `/check-hlr/${phone}`, {
      headers: {
        Authorization: currentAuth.signInUserSession.idToken.jwtToken
      }
    });
    return res;
  } catch (err) {
    console.log(err.response);
  }
}

export { checkHlrService };
