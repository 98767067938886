import React, { useState } from 'react';
import { useAppContext } from '../../libs/context';
import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router';
import { Row, Col, Form, Input, Button, Typography, Alert } from 'antd';
import { MailOutlined, LockOutlined } from '@ant-design/icons';

import { ERRORS } from '../../config/index';
import logo from '../../static/tadoo-logo.png';

const { Title } = Typography;

const Access = () => {
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const { setUserInfo, userHasAuthenticated } = useAppContext();
  const history = useHistory();

  async function onFinish(params) {
    setErrorMessage(false);
    setLoading(true);
    try {
      await Auth.signIn(params);
      const { accessToken } = await Auth.currentSession();
      const { attributes } = await Auth.currentUserInfo();
      setUserInfo({
        isAdmin: accessToken.payload['cognito:groups'][0] === 'Admins',
        cognitoId: accessToken.payload.iss.split('/').pop(),
        email: attributes.email
      });
      userHasAuthenticated(true);
      history.push('/customers');
    } catch (err) {
      setErrorMessage(err.message);
    }
    setLoading(false);
  }

  return (
    <>
      <Row justify="center">
        <img
          src={logo}
          style={{ width: '300px', padding: '50px 50px 10px 0' }}
          alt="logo"
        />
      </Row>
      <Row justify="center">
        <Col span={6}>
          <Title
            style={{
              textAlign: 'center',
              marginTop: '20px',
              marginBottom: '40px'
            }}
          >
            SMS Rules the World
          </Title>
        </Col>
      </Row>
      <Row justify="center">
        <Col span={5}>
          <Form
            name="normal_login"
            className="login-form"
            // initialValues={{
            //   remember: true
            // }}
            onFinish={onFinish}
          >
            <Form.Item
              name="username"
              rules={[
                {
                  required: true,
                  message: 'Por favor introduce tu email'
                }
              ]}
            >
              <Input
                prefix={<MailOutlined className="site-form-item-icon" />}
                placeholder="Username"
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Por favor introduce tu contraseña'
                }
              ]}
            >
              <Input
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="Contraseña"
              />
            </Form.Item>
            {/* <Form.Item>
              <div
                style={{
                  display: 'flex'
                }}
              >
                <Form.Item name="remember" valuePropName="checked" noStyle>
                  <Checkbox>Recordarme</Checkbox>
                </Form.Item>

                <a
                  className="login-form-forgot"
                  href="/"
                  style={{ marginLeft: 'auto' }}
                >
                  He perdido la contraseña
                </a>
              </div>
            </Form.Item> */}
            {errorMessage ? (
              <Alert
                message="Error"
                description={errorMessage}
                type="error"
                showIcon
                style={{ marginBottom: '15px' }}
              />
            ) : null}
            <Form.Item>
              <Button
                style={{
                  width: '100%',
                  marginBottom: '10px'
                }}
                type="primary"
                htmlType="submit"
                className="login-form-button"
                loading={loading}
              >
                Logueate
              </Button>
              {/* O <a href="/register">regístrate ahora</a> */}
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default Access;
