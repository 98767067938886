import React from 'react';
import { Auth } from 'aws-amplify';
import { useAppContext } from '../libs/context';
import { useHistory } from 'react-router';
import Idletime from './Idletime';
import { Menu as MenuAnt, Dropdown, Avatar } from 'antd';
import {
  CrownOutlined,
  ToolOutlined,
  DownOutlined,
  GlobalOutlined
} from '@ant-design/icons';

const Menu = params => {
  const history = useHistory();
  const { userHasAuthenticated, userInfo, setIsSleepy } = useAppContext();
  const { collapsed } = params;

  const handleHistory = e => {
    if (e.key !== 'customers') {
      window.clearInterval(window.forceInterval);
    }
    history.push(e.key);
  };

  async function logOut() {
    await Auth.signOut();
    userHasAuthenticated(false);
    history.push('/login');
  }

  const menuUser = (
    <MenuAnt>
      <MenuAnt.Item key="0" onClick={() => logOut()}>
        Logout
      </MenuAnt.Item>
    </MenuAnt>
  );

  return (
    <div style={{ height: '100%', display: 'flex', flexFlow: 'column' }}>
      <div
        style={{
          margin: '25px 0 10px',
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <Dropdown overlay={menuUser} trigger={['hover']}>
          <div>
            <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
              <Avatar
                size={
                  collapsed
                    ? 'large'
                    : { xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }
                }
                src={`https://media-rcs.s3.eu-west-3.amazonaws.com/avatar-${
                  userInfo && userInfo.email.split('@')[0]
                }.jpg`}
              />
              {!collapsed && (
                <DownOutlined style={{ color: 'white', marginLeft: '10px' }} />
              )}
            </a>
            {/* {!collapsed && (
              <div style={{ position: 'absolute', top: 95, right: 60 }}>🐵</div>
            )} */}
          </div>
        </Dropdown>
      </div>
      <MenuAnt
        onClick={handleHistory}
        mode="inline"
        theme="dark"
        defaultSelectedKeys={history && history.location.pathname.replace('/')}
        style={{ marginTop: '20px' }}
      >
        <MenuAnt.Item key="customers">
          <CrownOutlined
            style={{
              fontSize: collapsed ? '22px' : '18px',
              marginRight: collapsed ? '30px' : '10px'
            }}
          />
          Customers
        </MenuAnt.Item>
        <MenuAnt.Item key="vendors">
          <GlobalOutlined
            style={{
              fontSize: collapsed ? '22px' : '18px',
              marginRight: collapsed ? '30px' : '10px'
            }}
          />
          Vendors
        </MenuAnt.Item>
        {/* <MenuAnt.Item key="notifications">
          <NotificationOutlined
            style={{
              fontSize: collapsed ? '22px' : '18px',
              marginRight: collapsed ? '30px' : '10px'
            }}
          />
          <Badge dot={true} style={{ boxShadow: 'none' }}>
            Notifications
          </Badge>
        </MenuAnt.Item> */}
        <MenuAnt.Item key="tools">
          <ToolOutlined
            style={{
              fontSize: collapsed ? '22px' : '18px',
              marginRight: collapsed ? '30px' : '10px'
            }}
          />
          Tools
        </MenuAnt.Item>
      </MenuAnt>
      <Idletime userInfo={userInfo} setIsSleepy={setIsSleepy} logOut={logOut} />
    </div>
  );
};

export default Menu;
