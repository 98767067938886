import React, { useState, useEffect } from 'react';
import { AppContext } from './libs/context';
import { Auth } from 'aws-amplify';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom';
import Layout from './components/Layout';
import Login from './scenes/Access/Login';
// import Home from './scenes/Home';
import Vendors from './scenes/Vendors';
import UsersManagement from './scenes/UsersManagement';
import Tools from './scenes/Tools';
// import Roles from './scenes/Roles';
import 'antd/dist/antd.less';

const App = () => {
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [userInfo, setUserInfo] = useState();
  const [isSleepy, setIsSleepy] = useState(false);

  useEffect(() => {
    onLoad();
  }, []);

  async function onLoad() {
    try {
      await Auth.currentSession();
      const { accessToken } = await Auth.currentSession();
      const { attributes } = await Auth.currentUserInfo();
      setUserInfo({
        isAdmin: accessToken.payload['cognito:groups'][0] === 'Admins',
        cognitoId: accessToken.payload.iss.split('/').pop(),
        email: attributes.email
      });
      userHasAuthenticated(true);
    } catch (e) {
      if (e !== 'No current user') {
        // console.log(e);
      }
    }
  }

  return (
    <AppContext.Provider
      value={{
        isAuthenticated,
        userHasAuthenticated,
        userInfo,
        setUserInfo,
        isSleepy,
        setIsSleepy
      }}
    >
      {isAuthenticated ? (
        <Router>
          <Switch>
            <Route path="/customers">
              <Layout content={<UsersManagement />} />
            </Route>
            <Route path="/vendors">
              <Layout content={<Vendors />} />
            </Route>
            {/* <Route path="/vendors">
              <Redirect to="/vendors/automatic" />
            </Route> */}
            <Route path="/tools">
              <Layout content={<Tools />} />
            </Route>
            <Route path="/">
              <Redirect to="/customers" />
            </Route>
          </Switch>
        </Router>
      ) : (
        <Router>
          <Route path="/">
            <Login />
          </Route>
        </Router>
      )}
    </AppContext.Provider>
  );
};

export default App;
