export default {
  "176": {
    "country": "Russia",
    "network": "VIMPELCOM (KB IMPULS)",
    "cc": "7",
    "cctld": "25099"
  },
  "188": {
    "country": "Singapore",
    "network": "SINGTEL MOBILE (GSM900)",
    "cc": "65",
    "cctld": "52501"
  },
  "2020": {
    "country": "Greece",
    "network": "DEFAULT GREECE",
    "cc": "30",
    "cctld": "GR"
  },
  "2040": {
    "country": "Netherlands",
    "network": "DEFAULT NETHERLANDS",
    "cc": "31",
    "cctld": "NL"
  },
  "2060": {
    "country": "Belgium",
    "network": "DEFAULT BELGIUM",
    "cc": "32",
    "cctld": "BE"
  },
  "2080": {
    "country": "France",
    "network": "DEFAULT FRANCE",
    "cc": "33",
    "cctld": "FR"
  },
  "2120": {
    "country": "Monaco",
    "network": "DEFAULT MONACO",
    "cc": "377",
    "cctld": "MC"
  },
  "2130": {
    "country": "Andorra",
    "network": "DEFAULT ANDORRA",
    "cc": "376",
    "cctld": "AD"
  },
  "2140": {
    "country": "Spain",
    "network": "DEFAULT SPAIN",
    "cc": "34",
    "cctld": "ES"
  },
  "2160": {
    "country": "Hungary",
    "network": "DEFAULT HUNGARY",
    "cc": "36",
    "cctld": "HU"
  },
  "2180": {
    "country": "Bosnia-Herzegovina",
    "network": "DEFAULT BOSNIA",
    "cc": "387",
    "cctld": "BA"
  },
  "2190": {
    "country": "Croatia",
    "network": "DEFAULT CROATIA",
    "cc": "385",
    "cctld": "HR"
  },
  "2200": {
    "country": "Serbia",
    "network": "DEFAULT SERBIA AND MONTENEGRO",
    "cc": "381",
    "cctld": "RS"
  },
  "2220": {
    "country": "Italy",
    "network": "DEFAULT ITALY",
    "cc": "39",
    "cctld": "IT"
  },
  "2260": {
    "country": "Romania",
    "network": "DEFAULT ROMANIA",
    "cc": "40",
    "cctld": "RO"
  },
  "2280": {
    "country": "Switzerland",
    "network": "DEFAULT SWITZERLAND",
    "cc": "41",
    "cctld": "CH"
  },
  "2300": {
    "country": "Czech Republic",
    "network": "DEFAULT CZEK REPUBLIC",
    "cc": "420",
    "cctld": "CZ"
  },
  "2310": {
    "country": "Slovak Republic",
    "network": "DEFAULT SLOVAK REPUBLIC",
    "cc": "421",
    "cctld": "SK"
  },
  "2320": {
    "country": "Austria",
    "network": "DEFAULT AUSTRIA",
    "cc": "43",
    "cctld": "AT"
  },
  "2340": {
    "country": "England",
    "network": "DEFAULT UK",
    "cc": "44",
    "cctld": "UK"
  },
  "2380": {
    "country": "Denmark",
    "network": "DEFAULT DENMARK",
    "cc": "45",
    "cctld": "DK"
  },
  "2400": {
    "country": "Sweden",
    "network": "DEFAULT SWEDEN",
    "cc": "46",
    "cctld": "SE"
  },
  "2420": {
    "country": "Norway",
    "network": "DEFAULT NORWAY",
    "cc": "47",
    "cctld": "NO"
  },
  "2440": {
    "country": "Finland",
    "network": "DEFAULT FINLAND",
    "cc": "358",
    "cctld": "FI"
  },
  "2460": {
    "country": "Lithuania",
    "network": "DEFAULT LITHUANIA",
    "cc": "370",
    "cctld": "LT"
  },
  "2470": {
    "country": "Latvia",
    "network": "DEFAULT LATVIA",
    "cc": "371",
    "cctld": "LV"
  },
  "2480": {
    "country": "Estonia",
    "network": "DEFAULT ESTONIA",
    "cc": "372",
    "cctld": "EE"
  },
  "2500": {
    "country": "Russia",
    "network": "DEFAULT RUSSIA",
    "cc": "7",
    "cctld": "RU"
  },
  "2550": {
    "country": "Ukraine",
    "network": "DEFAULT UKRAINE",
    "cc": "380",
    "cctld": "UA"
  },
  "2570": {
    "country": "Belarus",
    "network": "DEFAULT BELARUS",
    "cc": "375",
    "cctld": "BY"
  },
  "2590": {
    "country": "Moldavia",
    "network": "DEFAULT MOLDOVA",
    "cc": "373",
    "cctld": "MD"
  },
  "2600": {
    "country": "Poland",
    "network": "DEFAULT POLAND",
    "cc": "48",
    "cctld": "PL"
  },
  "2620": {
    "country": "Germany",
    "network": "DEFAULT GERMANY",
    "cc": "49",
    "cctld": "DE"
  },
  "2660": {
    "country": "Gibraltar",
    "network": "DEFAULT GIBILTERRA",
    "cc": "350",
    "cctld": "GI"
  },
  "2680": {
    "country": "Portugal",
    "network": "DEFAULT PORTUGAL",
    "cc": "351",
    "cctld": "PT"
  },
  "2700": {
    "country": "Luxembourg",
    "network": "DEFAULT LUXEMBOURG",
    "cc": "352",
    "cctld": "LU"
  },
  "2720": {
    "country": "Ireland",
    "network": "DEFAULT IRELAND",
    "cc": "353",
    "cctld": "IE"
  },
  "2740": {
    "country": "Iceland",
    "network": "DEFAULT ICELAND",
    "cc": "354",
    "cctld": "IS"
  },
  "2760": {
    "country": "Albania",
    "network": "DEFAULT ALBANIA",
    "cc": "355",
    "cctld": "AL"
  },
  "2780": {
    "country": "Malta",
    "network": "DEFAULT MALTA",
    "cc": "356",
    "cctld": "MT"
  },
  "2800": {
    "country": "Cyprus",
    "network": "DEFAULT CYPRUS",
    "cc": "357",
    "cctld": "CY"
  },
  "2820": {
    "country": "Georgia Republic",
    "network": "DEFAULT GEORGIA",
    "cc": "995",
    "cctld": "GE"
  },
  "2830": {
    "country": "Armenia",
    "network": "DEFAULT ARMENIA",
    "cc": "374",
    "cctld": "AM"
  },
  "2840": {
    "country": "Bulgaria",
    "network": "DEFAULT BULGARIA",
    "cc": "359",
    "cctld": "BG"
  },
  "2860": {
    "country": "Turkey",
    "network": "DEFAULT TURKEY",
    "cc": "90",
    "cctld": "TR"
  },
  "2880": {
    "country": "Faeroe Islands",
    "network": "DEFAULT FAROE ISLANDS",
    "cc": "298",
    "cctld": "DK"
  },
  "2900": {
    "country": "Greenland",
    "network": "DEFAULT GREENLAND",
    "cc": "299",
    "cctld": "GL"
  },
  "2930": {
    "country": "Slovenia",
    "network": "DEFAULT SLOVENIA",
    "cc": "386",
    "cctld": "SI"
  },
  "2940": {
    "country": "Macedonia",
    "network": "DEFAULT FYROM",
    "cc": "389",
    "cctld": "MK"
  },
  "2950": {
    "country": "Liechtenstein",
    "network": "DEFAULT LIECHTENSTEIN",
    "cc": "423",
    "cctld": "LI"
  },
  "3100": {
    "country": "United States",
    "network": "DEFAULT USA",
    "cc": "1",
    "cctld": "US"
  },
  "3340": {
    "country": "Mexico",
    "network": "DEFAULT MEXICO",
    "cc": "52",
    "cctld": "MX"
  },
  "3400": {
    "country": "Martinique (French)",
    "network": "DEFAULT MARTINIQUE",
    "cc": "596",
    "cctld": "MQ"
  },
  "3620": {
    "country": "Netherlands Antilles",
    "network": "DEFAULT NETHERLANDS ANTILLES",
    "cc": "599",
    "cctld": "AN"
  },
  "3630": {
    "country": "Aruba",
    "network": "DEFAULT ARUBA",
    "cc": "297",
    "cctld": "AW"
  },
  "3680": {
    "country": "Cuba",
    "network": "DEFAULT CUBA",
    "cc": "53",
    "cctld": "CU"
  },
  "3720": {
    "country": "Haiti",
    "network": "DEFAULT HAITI",
    "cc": "509",
    "cctld": "HT"
  },
  "4000": {
    "country": "Azerbaijan",
    "network": "DEFAULT AZERBAIJAN",
    "cc": "994",
    "cctld": "AZ"
  },
  "4020": {
    "country": "Bhutan",
    "network": "DEFAULT BHUTAN",
    "cc": "975",
    "cctld": "BT"
  },
  "4040": {
    "country": "India",
    "network": "DEFAULT INDIA",
    "cc": "91",
    "cctld": "IN"
  },
  "4100": {
    "country": "Pakistan",
    "network": "DEFAULT PAKISTAN",
    "cc": "92",
    "cctld": "PK"
  },
  "4120": {
    "country": "Afghanistan",
    "network": "DEFAULT AFGHANISTAN",
    "cc": "93",
    "cctld": "AF"
  },
  "4130": {
    "country": "Sri Lanka",
    "network": "DEFAULT SRI LANKA",
    "cc": "94",
    "cctld": "LK"
  },
  "4140": {
    "country": "Myanmar",
    "network": "DEFAULT Myanmar",
    "cc": "95",
    "cctld": "MM"
  },
  "4150": {
    "country": "Lebanon",
    "network": "DEFAULT LEBANON",
    "cc": "961",
    "cctld": "LB"
  },
  "4160": {
    "country": "Jordan",
    "network": "DEFAULT JORDAN",
    "cc": "962",
    "cctld": "JO"
  },
  "4170": {
    "country": "Syria",
    "network": "DEFAULT SYRIA",
    "cc": "963",
    "cctld": "SY"
  },
  "4180": {
    "country": "Iraq",
    "network": "DEFAULT IRAQ",
    "cc": "964",
    "cctld": "IQ"
  },
  "4190": {
    "country": "Kuwait",
    "network": "DEFAULT KUWAIT",
    "cc": "965",
    "cctld": "KW"
  },
  "4200": {
    "country": "Saudi Arabia",
    "network": "DEFAULT SAUDI ARABIA",
    "cc": "966",
    "cctld": "SA"
  },
  "4210": {
    "country": "Yemen",
    "network": "DEFAULT YEMEN",
    "cc": "967",
    "cctld": "YE"
  },
  "4220": {
    "country": "Oman",
    "network": "DEFAULT OMAN",
    "cc": "968",
    "cctld": "OM"
  },
  "4240": {
    "country": "United Arab Emirates",
    "network": "DEFAULT UNITED ARAB EMIRATES",
    "cc": "971",
    "cctld": "AE"
  },
  "4250": {
    "country": "Israel",
    "network": "DEFAULT ISRAEL",
    "cc": "972",
    "cctld": "IL"
  },
  "4260": {
    "country": "Bahrain",
    "network": "DEFAULT BAHRAIN",
    "cc": "973",
    "cctld": "BH"
  },
  "4270": {
    "country": "Qatar",
    "network": "DEFAULT QATAR",
    "cc": "974",
    "cctld": "QA"
  },
  "4280": {
    "country": "Mongolia",
    "network": "DEFAULT MONGOLIA",
    "cc": "976",
    "cctld": "MN"
  },
  "4290": {
    "country": "Nepal",
    "network": "DEFAULT Nepal",
    "cc": "977",
    "cctld": "NP"
  },
  "4320": {
    "country": "Iran",
    "network": "DEFAULT IRAN",
    "cc": "98",
    "cctld": "IR"
  },
  "4340": {
    "country": "Uzbekistan",
    "network": "DEFAULT UZBEKISTAN",
    "cc": "998",
    "cctld": "UZ"
  },
  "4360": {
    "country": "Tajikistan",
    "network": "DEFAULT TAJIKISTAN",
    "cc": "992",
    "cctld": "TJ"
  },
  "4370": {
    "country": "Kyrgyzstan",
    "network": "DEFAULT KYRGYSTHAN",
    "cc": "996",
    "cctld": "KG"
  },
  "4380": {
    "country": "Turkmenistan",
    "network": "DEFAULT TURKMENISTAN",
    "cc": "993",
    "cctld": "TM"
  },
  "4400": {
    "country": "Japan",
    "network": "DEFAULT JAPAN",
    "cc": "81",
    "cctld": "JP"
  },
  "4500": {
    "country": "South Korea",
    "network": "DEFAULT SOUTH KOREA",
    "cc": "82",
    "cctld": "KR"
  },
  "4520": {
    "country": "Vietnam",
    "network": "DEFAULT VIETNAM",
    "cc": "84",
    "cctld": "VN"
  },
  "4540": {
    "country": "Hong Kong",
    "network": "DEFAULT HONG KONG",
    "cc": "852",
    "cctld": "HK"
  },
  "4550": {
    "country": "Macao",
    "network": "DEFAULT MACAO",
    "cc": "853",
    "cctld": "MO"
  },
  "4560": {
    "country": "Cambodia",
    "network": "DEFAULT CAMBODIA",
    "cc": "855",
    "cctld": "KH"
  },
  "4570": {
    "country": "Laos",
    "network": "DEFAULT LAO P.D.R.",
    "cc": "856",
    "cctld": "LA"
  },
  "4600": {
    "country": "China Peoples Republic",
    "network": "DEFAULT CHINA",
    "cc": "86",
    "cctld": "CN"
  },
  "4660": {
    "country": "Taiwan",
    "network": "DEFAULT TAIWAN",
    "cc": "886",
    "cctld": "TW"
  },
  "4700": {
    "country": "Bangladesh",
    "network": "DEFAULT BANGLADESH",
    "cc": "880",
    "cctld": "BD"
  },
  "4720": {
    "country": "Maldives",
    "network": "DEFAULT MALDIVES",
    "cc": "960",
    "cctld": "MV"
  },
  "5020": {
    "country": "Malaysia",
    "network": "DEFAULT MALAYSIA",
    "cc": "60",
    "cctld": "MY"
  },
  "5050": {
    "country": "Australia",
    "network": "DEFAULT AUSTRALIA",
    "cc": "61",
    "cctld": "AU"
  },
  "5100": {
    "country": "Indonesia",
    "network": "DEFAULT INDONESIA",
    "cc": "62",
    "cctld": "ID"
  },
  "5140": {
    "country": "Timor-Leste",
    "network": "DEFAULT TIMOR LESTE",
    "cc": "670",
    "cctld": "TL"
  },
  "5150": {
    "country": "Philippines",
    "network": "DEFAULT PHILIPPINES",
    "cc": "63",
    "cctld": "PH"
  },
  "5200": {
    "country": "Thailand",
    "network": "DEFAULT THAILAND",
    "cc": "66",
    "cctld": "TH"
  },
  "5250": {
    "country": "Singapore",
    "network": "DEFAULT SINGAPORE",
    "cc": "65",
    "cctld": "SG"
  },
  "5280": {
    "country": "Brunei Darussalam",
    "network": "DEFAULT BRUNEI",
    "cc": "673",
    "cctld": "BN"
  },
  "5300": {
    "country": "New Zealand",
    "network": "DEFAULT NEW ZELAND",
    "cc": "64",
    "cctld": "NZ"
  },
  "5410": {
    "country": "Vanuatu",
    "network": "DEFAULT VANUATU",
    "cc": "678",
    "cctld": "VU"
  },
  "5420": {
    "country": "Fiji",
    "network": "DEFAULT FIJI",
    "cc": "679",
    "cctld": "FJ"
  },
  "5460": {
    "country": "Namibia",
    "network": "DEFAULT NEW CALEDONIA",
    "cc": "687",
    "cctld": "NA"
  },
  "5470": {
    "country": "French Polynesia",
    "network": "DEFAULT FRENCH POLYNESIA",
    "cc": "689",
    "cctld": "PF"
  },
  "6020": {
    "country": "Egypt",
    "network": "DEFAULT EGYPT",
    "cc": "20",
    "cctld": "EG"
  },
  "6030": {
    "country": "Algeria",
    "network": "DEFAULT ALGERIA",
    "cc": "213",
    "cctld": "DZ"
  },
  "6040": {
    "country": "Morocco",
    "network": "DEFAULT MOROCCO",
    "cc": "212",
    "cctld": "MA"
  },
  "6050": {
    "country": "Tunisia",
    "network": "DEFAULT TUNISIA",
    "cc": "216",
    "cctld": "TN"
  },
  "6060": {
    "country": "Libya",
    "network": "DEFAULT LIBYA",
    "cc": "218",
    "cctld": "LY"
  },
  "6070": {
    "country": "Gambia",
    "network": "DEFAULT GAMBIA",
    "cc": "220",
    "cctld": "GM"
  },
  "6080": {
    "country": "Senegal",
    "network": "DEFAULT SENEGAL",
    "cc": "221",
    "cctld": "SN"
  },
  "6090": {
    "country": "Mauritania",
    "network": "DEFAULT MAURITANIA",
    "cc": "222",
    "cctld": "MR"
  },
  "6100": {
    "country": "Mali",
    "network": "DEFAULT MALI",
    "cc": "223",
    "cctld": "ML"
  },
  "6110": {
    "country": "Guinea",
    "network": "DEFAULT GUINEA",
    "cc": "224",
    "cctld": "GN"
  },
  "6120": {
    "country": "Ivory Coast",
    "network": "DEFAULT IVORY COAST",
    "cc": "22",
    "cctld": "CI"
  },
  "6130": {
    "country": "Burkina Faso",
    "network": "DEFAULT BURKINA FASO",
    "cc": "226",
    "cctld": "BF"
  },
  "6140": {
    "country": "Niger",
    "network": "DEFAULT NIGER",
    "cc": "227",
    "cctld": "NE"
  },
  "6150": {
    "country": "Togo",
    "network": "DEFAULT TOGO",
    "cc": "228",
    "cctld": "TG"
  },
  "6160": {
    "country": "Benin",
    "network": "DEFAULT BENIN",
    "cc": "229",
    "cctld": "BJ"
  },
  "6170": {
    "country": "Mauritius",
    "network": "DEFAULT MAURITIUS",
    "cc": "230",
    "cctld": "MU"
  },
  "6180": {
    "country": "Liberia",
    "network": "MTN (Lonestar Cell)",
    "cc": "231",
    "cctld": "LR"
  },
  "6190": {
    "country": "Sierra Leone",
    "network": "DEFAULT SIERRA LEONE",
    "cc": "232",
    "cctld": "SL"
  },
  "6200": {
    "country": "Ghana",
    "network": "DEFAULT GHANA",
    "cc": "233",
    "cctld": "GH"
  },
  "6210": {
    "country": "Nigeria",
    "network": "DEFAULT NIGERIA",
    "cc": "234",
    "cctld": "NG"
  },
  "6220": {
    "country": "Chad",
    "network": "DEFAULT CHAD",
    "cc": "235",
    "cctld": "TD"
  },
  "6230": {
    "country": "Central Africa",
    "network": "DEFAULT CENTRAL AFRICA REP.",
    "cc": "236",
    "cctld": "CF"
  },
  "6240": {
    "country": "Cameroon",
    "network": "DEFAULT CAMEROON",
    "cc": "237",
    "cctld": "CM"
  },
  "6250": {
    "country": "Cape Verde Island",
    "network": "DEFAULT CAPE VERDE",
    "cc": "238",
    "cctld": "CV"
  },
  "6270": {
    "country": "Equatorial Guinea",
    "network": "DEFAULT EQUATORIAL GUINEA",
    "cc": "240",
    "cctld": "GQ"
  },
  "6280": {
    "country": "Gabon",
    "network": "DEFAULT GABON",
    "cc": "241",
    "cctld": "GA"
  },
  "6290": {
    "country": "Congo",
    "network": "DEFAULT CONGO",
    "cc": "242",
    "cctld": "CG"
  },
  "6300": {
    "country": "Democratic Republic of the Congo",
    "network": "DEFAULT CONGO DEM. REP. OF",
    "cc": "243",
    "cctld": "CD"
  },
  "6310": {
    "country": "Angola",
    "network": "DEFAULT ANGOLA",
    "cc": "244",
    "cctld": "AO"
  },
  "6330": {
    "country": "Seychelles",
    "network": "DEFAULT SEYCHELLES",
    "cc": "248",
    "cctld": "SC"
  },
  "6340": {
    "country": "Sudan",
    "network": "DEFAULT SUDAN",
    "cc": "249",
    "cctld": "SD"
  },
  "6350": {
    "country": "Rwanda",
    "network": "DEFAULT RWANDA",
    "cc": "250",
    "cctld": "RW"
  },
  "6360": {
    "country": "Ethiopia",
    "network": "DEFAULT ETHIOPIA",
    "cc": "251",
    "cctld": "ET"
  },
  "6370": {
    "country": "Somalia",
    "network": "DEFAULT SOMALIA",
    "cc": "252",
    "cctld": "SO"
  },
  "6380": {
    "country": "Djibouti",
    "network": "DEFAULT DJIBOUTI",
    "cc": "253",
    "cctld": "DJ"
  },
  "6390": {
    "country": "Kenya",
    "network": "DEFAULT KENYA",
    "cc": "254",
    "cctld": "KE"
  },
  "6400": {
    "country": "Tanzania",
    "network": "DEFAULT TANZANIA",
    "cc": "255",
    "cctld": "TZ"
  },
  "6410": {
    "country": "Uganda",
    "network": "DEFAULT UGANDA",
    "cc": "256",
    "cctld": "UG"
  },
  "6420": {
    "country": "Burundi",
    "network": "DEFAULT BURUNDI",
    "cc": "257",
    "cctld": "BI"
  },
  "6430": {
    "country": "Mozambique",
    "network": "DEFAULT MOZAMBIQUE",
    "cc": "258",
    "cctld": "MZ"
  },
  "6450": {
    "country": "Zambia",
    "network": "DEFAULT ZAMBIA",
    "cc": "260",
    "cctld": "ZM"
  },
  "6460": {
    "country": "Madagascar",
    "network": "DEFAULT MADACASCAR",
    "cc": "261",
    "cctld": "MG"
  },
  "6470": {
    "country": "Reunion Island",
    "network": "DEFAULT REUNION",
    "cc": "262",
    "cctld": "RE"
  },
  "6480": {
    "country": "Zimbabwe",
    "network": "DEFAULT ZIMBABWE",
    "cc": "263",
    "cctld": "ZW"
  },
  "6490": {
    "country": "Namibia",
    "network": "DEFAULT NAMIDIA",
    "cc": "264",
    "cctld": "NA"
  },
  "6500": {
    "country": "Malawi",
    "network": "DEFAULT MALAWI",
    "cc": "265",
    "cctld": "MW"
  },
  "6510": {
    "country": "Lesotho",
    "network": "DEFAULT LESOTHO",
    "cc": "266",
    "cctld": "LS"
  },
  "6520": {
    "country": "Botswana",
    "network": "DEFAULT BOTSWANA",
    "cc": "267",
    "cctld": "BW"
  },
  "6530": {
    "country": "Swaziland",
    "network": "DEFAULT SWAZILAND",
    "cc": "268",
    "cctld": "SZ"
  },
  "6540": {
    "country": "Comoros Islands",
    "network": "DEFAULT COMOROS & MAYOTTE",
    "cc": "269",
    "cctld": "KM"
  },
  "6550": {
    "country": "South Africa",
    "network": "DEFAULT SOUTH AFRICA",
    "cc": "27",
    "cctld": "ZA"
  },
  "6570": {
    "country": "Eritrea",
    "network": "Eritel",
    "cc": "291",
    "cctld": "ER"
  },
  "6590": {
    "country": "South Sudan",
    "network": "Gemtel",
    "cc": "211",
    "cctld": "SS"
  },
  "7020": {
    "country": "Belize",
    "network": "DEFAULT BELIZE",
    "cc": "501",
    "cctld": "BZ"
  },
  "7040": {
    "country": "Guatemala",
    "network": "DEFAULT GUATEMALA",
    "cc": "502",
    "cctld": "GT"
  },
  "7060": {
    "country": "El Salvador",
    "network": "DEFAULT EL SALVADOR",
    "cc": "503",
    "cctld": "SV"
  },
  "7080": {
    "country": "Honduras",
    "network": "DEFAULT HONDURAS",
    "cc": "504",
    "cctld": "HN"
  },
  "7100": {
    "country": "Nicaragua",
    "network": "DEFAULT NICARAGUA",
    "cc": "505",
    "cctld": "NI"
  },
  "7120": {
    "country": "Costa Rica",
    "network": "DEFAULT Costa Rica",
    "cc": "506",
    "cctld": "CR"
  },
  "7140": {
    "country": "Panama",
    "network": "DEFAULT PANAMA",
    "cc": "507",
    "cctld": "PA"
  },
  "7160": {
    "country": "Peru",
    "network": "DEFAULT PERU",
    "cc": "51",
    "cctld": "PE"
  },
  "7220": {
    "country": "Argentina",
    "network": "DEFAULT ARGENTINA",
    "cc": "54",
    "cctld": "AR"
  },
  "7240": {
    "country": "Brazil",
    "network": "DEFAULT BRAZIL",
    "cc": "55",
    "cctld": "BR"
  },
  "7300": {
    "country": "Chile",
    "network": "DEFAULT CHILE",
    "cc": "56",
    "cctld": "CL"
  },
  "7320": {
    "country": "Colombia",
    "network": "DEFAULT COLOMBIA",
    "cc": "57",
    "cctld": "CO"
  },
  "7340": {
    "country": "Venezuela",
    "network": "DEFAULT VENEZUELA",
    "cc": "58",
    "cctld": "VE"
  },
  "7360": {
    "country": "Bolivia",
    "network": "DEFAULT BOLIVIA",
    "cc": "591",
    "cctld": "BO"
  },
  "7380": {
    "country": "Tanzania",
    "network": "DEFAULT FRENCH GUIANA",
    "cc": "594",
    "cctld": "TZ"
  },
  "7400": {
    "country": "Ecuador",
    "network": "DEFAULT ECUADOR",
    "cc": "593",
    "cctld": "EC"
  },
  "7440": {
    "country": "Paraguay",
    "network": "DEFAULT PARAGUAY",
    "cc": "595",
    "cctld": "PY"
  },
  "7460": {
    "country": "Suriname",
    "network": "DEFAULT SURINAME",
    "cc": "597",
    "cctld": "SR"
  },
  "7480": {
    "country": "Uruguay",
    "network": "DEFAULT URUGUAY",
    "cc": "598",
    "cctld": "UY"
  },
  "20201": {
    "country": "Greece",
    "network": "COSMOTE",
    "cc": "30",
    "cctld": "GR"
  },
  "20202": {
    "country": "Greece",
    "network": "COSMOTE",
    "cc": "30",
    "cctld": "GR"
  },
  "20205": {
    "country": "Greece",
    "network": "VODAFONE",
    "cc": "30",
    "cctld": "GR"
  },
  "20209": {
    "country": "Greece",
    "network": "Q-TELECOM",
    "cc": "30",
    "cctld": "GR"
  },
  "20210": {
    "country": "Greece",
    "network": "WIND",
    "cc": "30",
    "cctld": "GR"
  },
  "20214": {
    "country": "Greece",
    "network": "CYTA",
    "cc": "30",
    "cctld": "GR"
  },
  "20402": {
    "country": "Netherlands",
    "network": "TELE 2",
    "cc": "31",
    "cctld": "NL"
  },
  "20404": {
    "country": "Netherlands",
    "network": "VODAFONE",
    "cc": "31",
    "cctld": "NL"
  },
  "20408": {
    "country": "Netherlands",
    "network": "KPN MOBILE",
    "cc": "31",
    "cctld": "NL"
  },
  "20412": {
    "country": "Netherlands",
    "network": "TELFORT",
    "cc": "31",
    "cctld": "NL"
  },
  "20416": {
    "country": "Netherlands",
    "network": "T-MOBILE",
    "cc": "31",
    "cctld": "NL"
  },
  "20420": {
    "country": "Netherlands",
    "network": "ORANGE",
    "cc": "31",
    "cctld": "NL"
  },
  "20601": {
    "country": "Belgium",
    "network": "PROXIMUS",
    "cc": "32",
    "cctld": "BE"
  },
  "20605": {
    "country": "Belgium",
    "network": "Telenet",
    "cc": "32",
    "cctld": "BE"
  },
  "20606": {
    "country": "Belgium",
    "network": "Lycamobile",
    "cc": "32",
    "cctld": "BE"
  },
  "20608": {
    "country": "Belgium",
    "network": "Unknown-Belgium-4970",
    "cc": "32",
    "cctld": "BE"
  },
  "20610": {
    "country": "Belgium",
    "network": "MOBISTAR",
    "cc": "32",
    "cctld": "BE"
  },
  "20620": {
    "country": "Belgium",
    "network": "BASE",
    "cc": "32",
    "cctld": "BE"
  },
  "20630": {
    "country": "Belgium",
    "network": "Unknown-Belgium-4662",
    "cc": "32",
    "cctld": "BE"
  },
  "20640": {
    "country": "Belgium",
    "network": "Join Experience",
    "cc": "32",
    "cctld": "BE"
  },
  "20801": {
    "country": "France",
    "network": "FTMI ORANGE",
    "cc": "33",
    "cctld": "FR"
  },
  "20802": {
    "country": "France",
    "network": "Orange",
    "cc": "33",
    "cctld": "FR"
  },
  "20803": {
    "country": "France",
    "network": "MobiquiThings",
    "cc": "33",
    "cctld": "FR"
  },
  "20806": {
    "country": "France",
    "network": "NRJ Mobile",
    "cc": "33",
    "cctld": "FR"
  },
  "20809": {
    "country": "France",
    "network": "SFR",
    "cc": "33",
    "cctld": "FR"
  },
  "20810": {
    "country": "France",
    "network": "SFR",
    "cc": "33",
    "cctld": "FR"
  },
  "20811": {
    "country": "France",
    "network": "SFR",
    "cc": "33",
    "cctld": "FR"
  },
  "20813": {
    "country": "France",
    "network": "SFR",
    "cc": "33",
    "cctld": "FR"
  },
  "20815": {
    "country": "France",
    "network": "Unknown-FRANCE-6147",
    "cc": "33",
    "cctld": "FR"
  },
  "20816": {
    "country": "France",
    "network": "Free",
    "cc": "33",
    "cctld": "FR"
  },
  "20817": {
    "country": "France",
    "network": "LEGOS",
    "cc": "33",
    "cctld": "FR"
  },
  "20820": {
    "country": "France",
    "network": "BOUYGUES TEL.",
    "cc": "33",
    "cctld": "FR"
  },
  "20821": {
    "country": "France",
    "network": "Bouygues",
    "cc": "33",
    "cctld": "FR"
  },
  "20822": {
    "country": "France",
    "network": "Transatel",
    "cc": "33",
    "cctld": "FR"
  },
  "20824": {
    "country": "France",
    "network": "MobiquiThings",
    "cc": "33",
    "cctld": "FR"
  },
  "20825": {
    "country": "France",
    "network": "Lycamobile",
    "cc": "33",
    "cctld": "FR"
  },
  "20826": {
    "country": "France",
    "network": "NRJ Mobile",
    "cc": "33",
    "cctld": "FR"
  },
  "20888": {
    "country": "France",
    "network": "Bouygues",
    "cc": "33",
    "cctld": "FR"
  },
  "20891": {
    "country": "France",
    "network": "Orange",
    "cc": "33",
    "cctld": "FR"
  },
  "20894": {
    "country": "France",
    "network": "Lleida Networks",
    "cc": "33",
    "cctld": "FR"
  },
  "21201": {
    "country": "Swaziland",
    "network": "VALA900",
    "cc": "377",
    "cctld": "SZ"
  },
  "21303": {
    "country": "Andorra",
    "network": "S.T.A.",
    "cc": "376",
    "cctld": "AD"
  },
  "21401": {
    "country": "Spain",
    "network": "VODAFONE",
    "cc": "34",
    "cctld": "ES"
  },
  "21402": {
    "country": "Spain",
    "network": "Fibracat (Altecom)",
    "cc": "34",
    "cctld": "ES"
  },
  "21403": {
    "country": "Spain",
    "network": "AMENA",
    "cc": "34",
    "cctld": "ES"
  },
  "21404": {
    "country": "Spain",
    "network": "XFERA MOVILES (UMTS)",
    "cc": "34",
    "cctld": "ES"
  },
  "21405": {
    "country": "Spain",
    "network": "Movistar",
    "cc": "34",
    "cctld": "ES"
  },
  "21406": {
    "country": "Spain",
    "network": "Vodafone (Enabler)",
    "cc": "34",
    "cctld": "ES"
  },
  "21407": {
    "country": "Spain",
    "network": "TELEFONICA",
    "cc": "34",
    "cctld": "ES"
  },
  "21408": {
    "country": "Spain",
    "network": "Euskaltel",
    "cc": "34",
    "cctld": "ES"
  },
  "21409": {
    "country": "Spain",
    "network": "Suma (IOS)",
    "cc": "34",
    "cctld": "ES"
  },
  "21410": {
    "country": "Spain",
    "network": "Lobster",
    "cc": "34",
    "cctld": "ES"
  },
  "21411": {
    "country": "Spain",
    "network": "Orange",
    "cc": "34",
    "cctld": "ES"
  },
  "21415": {
    "country": "Spain",
    "network": "BT Spain",
    "cc": "34",
    "cctld": "ES"
  },
  "21416": {
    "country": "Spain",
    "network": "Telecable",
    "cc": "34",
    "cctld": "ES"
  },
  "21417": {
    "country": "Spain",
    "network": "Mobil R",
    "cc": "34",
    "cctld": "ES"
  },
  "21418": {
    "country": "Spain",
    "network": "Vodafone ONO",
    "cc": "34",
    "cctld": "ES"
  },
  "21419": {
    "country": "Spain",
    "network": "Simyo (E-plus KPN)",
    "cc": "34",
    "cctld": "ES"
  },
  "21420": {
    "country": "Spain",
    "network": "Parlem Telecom (fonyou)",
    "cc": "34",
    "cctld": "ES"
  },
  "21421": {
    "country": "Spain",
    "network": "Jazztel",
    "cc": "34",
    "cctld": "ES"
  },
  "21422": {
    "country": "Spain",
    "network": "Digi Spain (Best)",
    "cc": "34",
    "cctld": "ES"
  },
  "21424": {
    "country": "Spain",
    "network": "Unknown-SPAIN-6341",
    "cc": "34",
    "cctld": "ES"
  },
  "21425": {
    "country": "Spain",
    "network": "Lycamobile",
    "cc": "34",
    "cctld": "ES"
  },
  "21426": {
    "country": "Spain",
    "network": "Lleida Networks",
    "cc": "34",
    "cctld": "ES"
  },
  "21427": {
    "country": "Spain",
    "network": "Truphone",
    "cc": "34",
    "cctld": "ES"
  },
  "21434": {
    "country": "Spain",
    "network": "Airenetworks",
    "cc": "34",
    "cctld": "ES"
  },
  "21436": {
    "country": "Spain",
    "network": "Euskaltel",
    "cc": "34",
    "cctld": "ES"
  },
  "21601": {
    "country": "Hungary",
    "network": "PANNON",
    "cc": "36",
    "cctld": "HU"
  },
  "21603": {
    "country": "Hungary",
    "network": "Digi",
    "cc": "36",
    "cctld": "HU"
  },
  "21630": {
    "country": "Hungary",
    "network": "T-MOBILE",
    "cc": "36",
    "cctld": "HU"
  },
  "21670": {
    "country": "Hungary",
    "network": "VODAFONE",
    "cc": "36",
    "cctld": "HU"
  },
  "21803": {
    "country": "Bosnia-Herzegovina",
    "network": "ERONET",
    "cc": "387",
    "cctld": "BA"
  },
  "21805": {
    "country": "Bosnia-Herzegovina",
    "network": "MOBILNA SRPSKE",
    "cc": "387",
    "cctld": "BA"
  },
  "21809": {
    "country": "Bosnia-Herzegovina",
    "network": "GSMBIH",
    "cc": "387",
    "cctld": "BA"
  },
  "21890": {
    "country": "Bosnia-Herzegovina",
    "network": "Unknown-BOSNIA-6118",
    "cc": "387",
    "cctld": "BA"
  },
  "21901": {
    "country": "Croatia",
    "network": "T-MOBILE",
    "cc": "385",
    "cctld": "HR"
  },
  "21902": {
    "country": "Croatia",
    "network": "Tele2",
    "cc": "385",
    "cctld": "HR"
  },
  "21910": {
    "country": "Croatia",
    "network": "VIP-NET",
    "cc": "385",
    "cctld": "HR"
  },
  "22001": {
    "country": "Serbia",
    "network": "MOBTEL",
    "cc": "381",
    "cctld": "RS"
  },
  "22002": {
    "country": "Serbia",
    "network": "MONTENEGROPROMONTE",
    "cc": "381",
    "cctld": "RS"
  },
  "22003": {
    "country": "Serbia",
    "network": "TELECOM SRBIJA",
    "cc": "381",
    "cctld": "RS"
  },
  "22004": {
    "country": "Serbia",
    "network": "MONET",
    "cc": "381",
    "cctld": "RS"
  },
  "22005": {
    "country": "Serbia",
    "network": "VIP MOBILE",
    "cc": "381",
    "cctld": "RS"
  },
  "22201": {
    "country": "Italy",
    "network": "TIM",
    "cc": "39",
    "cctld": "IT"
  },
  "22202": {
    "country": "Italy",
    "network": "ELSACOM",
    "cc": "39",
    "cctld": "IT"
  },
  "22204": {
    "country": "Italy",
    "network": "Intermatica",
    "cc": "39",
    "cctld": "IT"
  },
  "22206": {
    "country": "Italy",
    "network": "Vodafone Omnitel NV",
    "cc": "39",
    "cctld": "IT"
  },
  "22210": {
    "country": "Italy",
    "network": "VODAFONE",
    "cc": "39",
    "cctld": "IT"
  },
  "22234": {
    "country": "Italy",
    "network": "TIM",
    "cc": "39",
    "cctld": "IT"
  },
  "22237": {
    "country": "Italy",
    "network": "H3G",
    "cc": "39",
    "cctld": "IT"
  },
  "22249": {
    "country": "Italy",
    "network": "TIM",
    "cc": "39",
    "cctld": "IT"
  },
  "22250": {
    "country": "Italy",
    "network": "Iliad",
    "cc": "39",
    "cctld": "IT"
  },
  "22253": {
    "country": "Italy",
    "network": "TIM",
    "cc": "39",
    "cctld": "IT"
  },
  "22288": {
    "country": "Italy",
    "network": "WIND",
    "cc": "39",
    "cctld": "IT"
  },
  "22298": {
    "country": "Italy",
    "network": "Wind",
    "cc": "39",
    "cctld": "IT"
  },
  "22299": {
    "country": "Italy",
    "network": "TRE (H3G)",
    "cc": "39",
    "cctld": "IT"
  },
  "22601": {
    "country": "Romania",
    "network": "MOBIFON (CONNEX)",
    "cc": "40",
    "cctld": "RO"
  },
  "22603": {
    "country": "Romania",
    "network": "COSMOTE ROMANIAN MOBILE TEL.",
    "cc": "40",
    "cctld": "RO"
  },
  "22604": {
    "country": "Romania",
    "network": "Cosmote - Telekom Romania Mobile",
    "cc": "40",
    "cctld": "RO"
  },
  "22605": {
    "country": "Romania",
    "network": "Digi.Mobil",
    "cc": "40",
    "cctld": "RO"
  },
  "22606": {
    "country": "Romania",
    "network": "Cosmote - Telekom Romania Mobile",
    "cc": "40",
    "cctld": "RO"
  },
  "22610": {
    "country": "Romania",
    "network": "ORANGE MOBIL ROM",
    "cc": "40",
    "cctld": "RO"
  },
  "22801": {
    "country": "Switzerland",
    "network": "SWISSCOM",
    "cc": "41",
    "cctld": "CH"
  },
  "22802": {
    "country": "Switzerland",
    "network": "SUNRISE (DIAX)",
    "cc": "41",
    "cctld": "CH"
  },
  "22803": {
    "country": "Switzerland",
    "network": "ORANGE",
    "cc": "41",
    "cctld": "CH"
  },
  "22805": {
    "country": "Switzerland",
    "network": "TOGEWANET AG",
    "cc": "41",
    "cctld": "CH"
  },
  "22807": {
    "country": "Switzerland",
    "network": "IN & PHONE",
    "cc": "41",
    "cctld": "CH"
  },
  "22808": {
    "country": "Switzerland",
    "network": "TELE 2",
    "cc": "41",
    "cctld": "CH"
  },
  "22850": {
    "country": "Switzerland",
    "network": "3G MOBILE AG TELEFONICA",
    "cc": "41",
    "cctld": "CH"
  },
  "22851": {
    "country": "Switzerland",
    "network": "GLOBAL NETWORKS",
    "cc": "41",
    "cctld": "CH"
  },
  "22853": {
    "country": "Switzerland",
    "network": "UPC",
    "cc": "41",
    "cctld": "CH"
  },
  "22854": {
    "country": "Switzerland",
    "network": "Lycamobile",
    "cc": "41",
    "cctld": "CH"
  },
  "23001": {
    "country": "Czech Republic",
    "network": "T-MOBILE",
    "cc": "420",
    "cctld": "CZ"
  },
  "23002": {
    "country": "Czech Republic",
    "network": "EUROTEL",
    "cc": "420",
    "cctld": "CZ"
  },
  "23003": {
    "country": "Czech Republic",
    "network": "VODAFONE",
    "cc": "420",
    "cctld": "CZ"
  },
  "23004": {
    "country": "Czech Republic",
    "network": "Ufon (Air Telecom)",
    "cc": "420",
    "cctld": "CZ"
  },
  "23101": {
    "country": "Slovak Republic",
    "network": "ORANGE SK",
    "cc": "421",
    "cctld": "SK"
  },
  "23102": {
    "country": "Slovak Republic",
    "network": "EUROTEL",
    "cc": "421",
    "cctld": "SK"
  },
  "23103": {
    "country": "Slovakia",
    "network": "Swan",
    "cc": "421",
    "cctld": "SK"
  },
  "23104": {
    "country": "Slovakia",
    "network": "T-Mobile SK",
    "cc": "421",
    "cctld": "SK"
  },
  "23106": {
    "country": "Slovakia",
    "network": "O2 - SK",
    "cc": "421",
    "cctld": "SK"
  },
  "23201": {
    "country": "Austria",
    "network": "TRE (H3G)",
    "cc": "43",
    "cctld": "AT"
  },
  "23202": {
    "country": "Austria",
    "network": "A1 (Mobilkom)",
    "cc": "43",
    "cctld": "AT"
  },
  "23203": {
    "country": "Austria",
    "network": "T-MOBILE",
    "cc": "43",
    "cctld": "AT"
  },
  "23204": {
    "country": "Austria",
    "network": "T-Mobile",
    "cc": "43",
    "cctld": "AT"
  },
  "23205": {
    "country": "Austria",
    "network": "CONNECT ONE",
    "cc": "43",
    "cctld": "AT"
  },
  "23206": {
    "country": "Austria",
    "network": "Hutchinson (Orange)",
    "cc": "43",
    "cctld": "AT"
  },
  "23207": {
    "country": "Austria",
    "network": "TELERING",
    "cc": "43",
    "cctld": "AT"
  },
  "23209": {
    "country": "Austria",
    "network": "TELE 2",
    "cc": "43",
    "cctld": "AT"
  },
  "23211": {
    "country": "Austria",
    "network": "A1 (Mobilkom)",
    "cc": "43",
    "cctld": "AT"
  },
  "23213": {
    "country": "Austria",
    "network": "Hutchinson (Orange)",
    "cc": "43",
    "cctld": "AT"
  },
  "23401": {
    "country": "England",
    "network": "Mundio Mobile (MCom)",
    "cc": "44",
    "cctld": "UK"
  },
  "23402": {
    "country": "England",
    "network": "O2",
    "cc": "44",
    "cctld": "UK"
  },
  "23403": {
    "country": "England",
    "network": "Jersey Airtel (Vodafone)",
    "cc": "44",
    "cctld": "UK"
  },
  "23405": {
    "country": "England",
    "network": "Unknown-England-2037",
    "cc": "44",
    "cctld": "UK"
  },
  "23407": {
    "country": "England",
    "network": "Cable & Wireless",
    "cc": "44",
    "cctld": "UK"
  },
  "23410": {
    "country": "England",
    "network": "O2",
    "cc": "44",
    "cctld": "UK"
  },
  "23411": {
    "country": "England",
    "network": "O2",
    "cc": "44",
    "cctld": "UK"
  },
  "23415": {
    "country": "England",
    "network": "Vodafone",
    "cc": "44",
    "cctld": "UK"
  },
  "23416": {
    "country": "England",
    "network": "Opal Telecom (Talk talk)",
    "cc": "44",
    "cctld": "UK"
  },
  "23418": {
    "country": "England",
    "network": "Cloud 9",
    "cc": "44",
    "cctld": "UK"
  },
  "23419": {
    "country": "England",
    "network": "Teleware (plc)",
    "cc": "44",
    "cctld": "UK"
  },
  "23420": {
    "country": "England",
    "network": "Hutchison 3G",
    "cc": "44",
    "cctld": "UK"
  },
  "23423": {
    "country": "England",
    "network": "Vodafone",
    "cc": "44",
    "cctld": "UK"
  },
  "23425": {
    "country": "England",
    "network": "Truphone",
    "cc": "44",
    "cctld": "UK"
  },
  "23426": {
    "country": "England",
    "network": "Lycamobile UK Ltd",
    "cc": "44",
    "cctld": "UK"
  },
  "23430": {
    "country": "England",
    "network": "EE (T-Mobile)",
    "cc": "44",
    "cctld": "UK"
  },
  "23431": {
    "country": "England",
    "network": "EE (T-Mobile)",
    "cc": "44",
    "cctld": "UK"
  },
  "23432": {
    "country": "England",
    "network": "EE (T-Mobile)",
    "cc": "44",
    "cctld": "UK"
  },
  "23433": {
    "country": "England",
    "network": "EE (Orange)",
    "cc": "44",
    "cctld": "UK"
  },
  "23434": {
    "country": "England",
    "network": "EE (Orange)",
    "cc": "44",
    "cctld": "UK"
  },
  "23438": {
    "country": "England",
    "network": "Virgin",
    "cc": "44",
    "cctld": "UK"
  },
  "23450": {
    "country": "England",
    "network": "Jersey Telecom (JT-Wave)",
    "cc": "44",
    "cctld": "UK"
  },
  "23453": {
    "country": "England",
    "network": "Limitless",
    "cc": "44",
    "cctld": "UK"
  },
  "23455": {
    "country": "England",
    "network": "Cable & Wireless Guernsey/Sure",
    "cc": "44",
    "cctld": "UK"
  },
  "23457": {
    "country": "England",
    "network": "Unknown-England-7359",
    "cc": "44",
    "cctld": "UK"
  },
  "23458": {
    "country": "England",
    "network": "Manx",
    "cc": "44",
    "cctld": "UK"
  },
  "23476": {
    "country": "England",
    "network": "British Telecom",
    "cc": "44",
    "cctld": "UK"
  },
  "23500": {
    "country": "England",
    "network": "Unknown-England-7759",
    "cc": "44",
    "cctld": ""
  },
  "23502": {
    "country": "England",
    "network": "Unknown-England-7539",
    "cc": "44",
    "cctld": ""
  },
  "23577": {
    "country": "England",
    "network": "Unknown-England-2076",
    "cc": "44",
    "cctld": ""
  },
  "23592": {
    "country": "England",
    "network": "Unknown-England-7971",
    "cc": "44",
    "cctld": ""
  },
  "23801": {
    "country": "Denmark",
    "network": "TDC MOBIL",
    "cc": "45",
    "cctld": "DK"
  },
  "23802": {
    "country": "Denmark",
    "network": "SONOFON",
    "cc": "45",
    "cctld": "DK"
  },
  "23806": {
    "country": "Denmark",
    "network": "HI3G",
    "cc": "45",
    "cctld": "DK"
  },
  "23820": {
    "country": "Denmark",
    "network": "TELIA",
    "cc": "45",
    "cctld": "DK"
  },
  "23830": {
    "country": "Denmark",
    "network": "ORANGE",
    "cc": "45",
    "cctld": "DK"
  },
  "24001": {
    "country": "Sweden",
    "network": "TELIA SONERA",
    "cc": "46",
    "cctld": "SE"
  },
  "24002": {
    "country": "Sweden",
    "network": "TRE (H3G)",
    "cc": "46",
    "cctld": "SE"
  },
  "24006": {
    "country": "Sweden",
    "network": "Telenor",
    "cc": "46",
    "cctld": "SE"
  },
  "24007": {
    "country": "Sweden",
    "network": "TELE 2",
    "cc": "46",
    "cctld": "SE"
  },
  "24008": {
    "country": "Sweden",
    "network": "VODAFONE",
    "cc": "46",
    "cctld": "SE"
  },
  "24010": {
    "country": "Sweden",
    "network": "SWEFOUR",
    "cc": "46",
    "cctld": "SE"
  },
  "24012": {
    "country": "Sweden",
    "network": "Lycamobile",
    "cc": "46",
    "cctld": "SE"
  },
  "24014": {
    "country": "Sweden",
    "network": "TDC",
    "cc": "46",
    "cctld": "SE"
  },
  "24016": {
    "country": "Sweden",
    "network": "42IT AB",
    "cc": "46",
    "cctld": "SE"
  },
  "24017": {
    "country": "Sweden",
    "network": "Gotalandsnatet AB",
    "cc": "46",
    "cctld": "SE"
  },
  "24020": {
    "country": "Sweden",
    "network": "MAINGATE",
    "cc": "46",
    "cctld": "SE"
  },
  "24201": {
    "country": "Norway",
    "network": "TELENOR",
    "cc": "47",
    "cctld": "NO"
  },
  "24202": {
    "country": "Norway",
    "network": "TELETOPIA",
    "cc": "47",
    "cctld": "NO"
  },
  "24204": {
    "country": "Norway",
    "network": "TeliaSonera (Netcom)",
    "cc": "47",
    "cctld": "NO"
  },
  "24205": {
    "country": "Norway",
    "network": "TeliaSonera (Netcom)",
    "cc": "47",
    "cctld": "NO"
  },
  "24206": {
    "country": "Norway",
    "network": "ICE",
    "cc": "47",
    "cctld": "NO"
  },
  "24207": {
    "country": "Norway",
    "network": "PHONERO",
    "cc": "47",
    "cctld": "NO"
  },
  "24208": {
    "country": "Norway",
    "network": "TDC Mobil",
    "cc": "47",
    "cctld": "NO"
  },
  "24209": {
    "country": "Norway",
    "network": "com4",
    "cc": "47",
    "cctld": "NO"
  },
  "24214": {
    "country": "Norway",
    "network": "ICE",
    "cc": "47",
    "cctld": "NO"
  },
  "24223": {
    "country": "Norway",
    "network": "Lycamobile",
    "cc": "47",
    "cctld": "NO"
  },
  "24403": {
    "country": "Finland",
    "network": "TELIA",
    "cc": "358",
    "cctld": "FI"
  },
  "24405": {
    "country": "Finland",
    "network": "ELISA",
    "cc": "358",
    "cctld": "FI"
  },
  "24412": {
    "country": "Finland",
    "network": "FINNET",
    "cc": "358",
    "cctld": "FI"
  },
  "24413": {
    "country": "Finland",
    "network": "DNA (Finnet)",
    "cc": "358",
    "cctld": "FI"
  },
  "24414": {
    "country": "Finland",
    "network": "ALANDS",
    "cc": "358",
    "cctld": "FI"
  },
  "24421": {
    "country": "Finland",
    "network": "SAUNALAHTI",
    "cc": "358",
    "cctld": "FI"
  },
  "24491": {
    "country": "Finland",
    "network": "SONERA",
    "cc": "358",
    "cctld": "FI"
  },
  "24601": {
    "country": "Lithuania",
    "network": "OMNITEL",
    "cc": "370",
    "cctld": "LT"
  },
  "24602": {
    "country": "Lithuania",
    "network": "BITE",
    "cc": "370",
    "cctld": "LT"
  },
  "24603": {
    "country": "Lithuania",
    "network": "TELE 2",
    "cc": "370",
    "cctld": "LT"
  },
  "24701": {
    "country": "Latvia",
    "network": "LMT",
    "cc": "371",
    "cctld": "LV"
  },
  "24702": {
    "country": "Latvia",
    "network": "TELE 2 (BALTCOM)",
    "cc": "371",
    "cctld": "LV"
  },
  "24801": {
    "country": "Estonia",
    "network": "EMT",
    "cc": "372",
    "cctld": "EE"
  },
  "24802": {
    "country": "Estonia",
    "network": "RADIOLINJA",
    "cc": "372",
    "cctld": "EE"
  },
  "24803": {
    "country": "Estonia",
    "network": "TELE2",
    "cc": "372",
    "cctld": "EE"
  },
  "24805": {
    "country": "Estonia",
    "network": "Elisa Eesti",
    "cc": "372",
    "cctld": "EE"
  },
  "25001": {
    "country": "Russia",
    "network": "MOBILE TELESYSTEMS (MTS)",
    "cc": "7",
    "cctld": "RU"
  },
  "25002": {
    "country": "Russia",
    "network": "MEGAFON",
    "cc": "7",
    "cctld": "RU"
  },
  "25003": {
    "country": "Russia",
    "network": "NIZHEGORODSKAYA CELLULAR COM (NCC)",
    "cc": "7",
    "cctld": "RU"
  },
  "25004": {
    "country": "Russia",
    "network": "SIBCHALLENGE",
    "cc": "7",
    "cctld": "RU"
  },
  "25005": {
    "country": "Russia",
    "network": "SCS 900",
    "cc": "7",
    "cctld": "RU"
  },
  "25006": {
    "country": "Russian Federation",
    "network": "Skylink (Tele2)",
    "cc": "7",
    "cctld": "RU"
  },
  "25007": {
    "country": "Russia",
    "network": "ZAO SMARTS",
    "cc": "7",
    "cctld": "RU"
  },
  "25009": {
    "country": "Russian Federation",
    "network": "Skylink (Tele2)",
    "cc": "7",
    "cctld": "RU"
  },
  "25010": {
    "country": "Russia",
    "network": "DONTELECOM",
    "cc": "7",
    "cctld": "RU"
  },
  "25011": {
    "country": "Russian Federation",
    "network": "Megafon",
    "cc": "7",
    "cctld": "RU"
  },
  "25012": {
    "country": "Russia",
    "network": "FAR-EASTERN CELLULAR SYSTEMS 900",
    "cc": "7",
    "cctld": "RU"
  },
  "25013": {
    "country": "Russia",
    "network": "KUBAN GSM",
    "cc": "7",
    "cctld": "RU"
  },
  "25015": {
    "country": "Russian Federation",
    "network": "Smarts",
    "cc": "7",
    "cctld": "RU"
  },
  "25017": {
    "country": "Russia",
    "network": "ERMAK RMS",
    "cc": "7",
    "cctld": "RU"
  },
  "25020": {
    "country": "Russia",
    "network": "TELE 2",
    "cc": "7",
    "cctld": "RU"
  },
  "25022": {
    "country": "Russian Federation",
    "network": "RT Mobile - Rostelecom - Akos - Utel (Tele2)",
    "cc": "7",
    "cctld": "RU"
  },
  "25023": {
    "country": "Russia",
    "network": "Unknown-RUSSIA-9232",
    "cc": "7",
    "cctld": "RU"
  },
  "25027": {
    "country": "Russia",
    "network": "Unknown-Russia-9503",
    "cc": "7",
    "cctld": "RU"
  },
  "25028": {
    "country": "Russia",
    "network": "EXTEL",
    "cc": "7",
    "cctld": "RU"
  },
  "25032": {
    "country": "Russia",
    "network": "Unknown-Russia-9789",
    "cc": "7",
    "cctld": "RU"
  },
  "25035": {
    "country": "Russian Federation",
    "network": "MOTIV  Ekaterinburg2000",
    "cc": "7",
    "cctld": "RU"
  },
  "25039": {
    "country": "Russia",
    "network": "URALTEL",
    "cc": "7",
    "cctld": "RU"
  },
  "25050": {
    "country": "Russian Federation",
    "network": "Sberbank Telecom",
    "cc": "7",
    "cctld": "RU"
  },
  "25062": {
    "country": "Russia",
    "network": "Unknown-Russia-9109",
    "cc": "7",
    "cctld": "RU"
  },
  "25092": {
    "country": "Russia",
    "network": "PRIMTELEFON",
    "cc": "7",
    "cctld": "RU"
  },
  "25099": {
    "country": "Russia",
    "network": "VIMPELCOM (KB IMPULS)",
    "cc": "7",
    "cctld": "RU"
  },
  "25501": {
    "country": "Ukraine",
    "network": "UMC",
    "cc": "380",
    "cctld": "UA"
  },
  "25502": {
    "country": "Ukraine",
    "network": "UKRAINE RADIO SYSTEM",
    "cc": "380",
    "cctld": "UA"
  },
  "25503": {
    "country": "Ukraine",
    "network": "KYIVSTAR",
    "cc": "380",
    "cctld": "UA"
  },
  "25504": {
    "country": "Ukraine",
    "network": "ASTELIT",
    "cc": "380",
    "cctld": "UA"
  },
  "25505": {
    "country": "Ukraine",
    "network": "GOLDEN",
    "cc": "380",
    "cctld": "UA"
  },
  "25506": {
    "country": "Ukraine",
    "network": "Unknown-UKRAINE-9356",
    "cc": "380",
    "cctld": "UA"
  },
  "25507": {
    "country": "Ukraine",
    "network": "Unknown-Ukraine-9161",
    "cc": "380",
    "cctld": "UA"
  },
  "25521": {
    "country": "Ukraine",
    "network": "People.Net",
    "cc": "380",
    "cctld": "UA"
  },
  "25599": {
    "country": "Ukraine",
    "network": "Unknown-Ukraine-7140",
    "cc": "380",
    "cctld": "UA"
  },
  "25701": {
    "country": "Belarus",
    "network": "MDC (VELCOM)",
    "cc": "375",
    "cctld": "BY"
  },
  "25702": {
    "country": "Belarus",
    "network": "JLLC MOBILE (MTS)",
    "cc": "375",
    "cctld": "BY"
  },
  "25704": {
    "country": "Belarus",
    "network": "Best - Life",
    "cc": "375",
    "cctld": "BY"
  },
  "25901": {
    "country": "Moldavia",
    "network": "VOXTEL SA",
    "cc": "373",
    "cctld": "MD"
  },
  "25902": {
    "country": "Moldavia",
    "network": "MOLDCELL",
    "cc": "373",
    "cctld": "MD"
  },
  "26001": {
    "country": "Poland",
    "network": "POLKOMTEL",
    "cc": "48",
    "cctld": "PL"
  },
  "26002": {
    "country": "Poland",
    "network": "ERA GSM",
    "cc": "48",
    "cctld": "PL"
  },
  "26003": {
    "country": "Poland",
    "network": "CENTERTEL",
    "cc": "48",
    "cctld": "PL"
  },
  "26004": {
    "country": "Poland",
    "network": "Unknown-Poland-5111",
    "cc": "48",
    "cctld": "PL"
  },
  "26006": {
    "country": "Poland",
    "network": "Play (P4)",
    "cc": "48",
    "cctld": "PL"
  },
  "26009": {
    "country": "Poland",
    "network": "Unknown-Poland-7293",
    "cc": "48",
    "cctld": "PL"
  },
  "26045": {
    "country": "Poland",
    "network": "Play (P4)",
    "cc": "48",
    "cctld": "PL"
  },
  "26201": {
    "country": "Germany",
    "network": "T-MOBILE",
    "cc": "49",
    "cctld": "DE"
  },
  "26202": {
    "country": "Germany",
    "network": "VODAFONE",
    "cc": "49",
    "cctld": "DE"
  },
  "26203": {
    "country": "Germany",
    "network": "E-PLUS",
    "cc": "49",
    "cctld": "DE"
  },
  "26204": {
    "country": "Germany",
    "network": "Vodafone",
    "cc": "49",
    "cctld": "DE"
  },
  "26205": {
    "country": "Germany",
    "network": "O2 (EPlus)",
    "cc": "49",
    "cctld": "DE"
  },
  "26207": {
    "country": "Germany",
    "network": "O2",
    "cc": "49",
    "cctld": "DE"
  },
  "26208": {
    "country": "Germany",
    "network": "O2 (EPlus)",
    "cc": "49",
    "cctld": "DE"
  },
  "26209": {
    "country": "Germany",
    "network": "Vodafone",
    "cc": "49",
    "cctld": "DE"
  },
  "26210": {
    "country": "Germany",
    "network": "Vodafone",
    "cc": "49",
    "cctld": "DE"
  },
  "26211": {
    "country": "Germany",
    "network": "O2 (EPlus)",
    "cc": "49",
    "cctld": "DE"
  },
  "26213": {
    "country": "Germany",
    "network": "MOBILCOM",
    "cc": "49",
    "cctld": "DE"
  },
  "26214": {
    "country": "Germany",
    "network": "QUAM",
    "cc": "49",
    "cctld": "DE"
  },
  "26220": {
    "country": "Germany",
    "network": "O2 (EPlus)",
    "cc": "49",
    "cctld": "DE"
  },
  "26242": {
    "country": "Germany",
    "network": "Vodafone",
    "cc": "49",
    "cctld": "DE"
  },
  "26243": {
    "country": "Germany",
    "network": "Lycamobile",
    "cc": "49",
    "cctld": "DE"
  },
  "26277": {
    "country": "Germany",
    "network": "O2 (EPlus)",
    "cc": "49",
    "cctld": "DE"
  },
  "26601": {
    "country": "Gibraltar",
    "network": "GIBTEL",
    "cc": "350",
    "cctld": "GI"
  },
  "26801": {
    "country": "Portugal",
    "network": "VODAFONE",
    "cc": "351",
    "cctld": "PT"
  },
  "26803": {
    "country": "Portugal",
    "network": "OPTIMUS",
    "cc": "351",
    "cctld": "PT"
  },
  "26804": {
    "country": "Portugal",
    "network": "Lycamobile",
    "cc": "351",
    "cctld": "PT"
  },
  "26806": {
    "country": "Portugal",
    "network": "TMN",
    "cc": "351",
    "cctld": "PT"
  },
  "27001": {
    "country": "Luxembourg",
    "network": "P&T LUX",
    "cc": "352",
    "cctld": "LU"
  },
  "27077": {
    "country": "Luxembourg",
    "network": "TANGO",
    "cc": "352",
    "cctld": "LU"
  },
  "27099": {
    "country": "Luxembourg",
    "network": "VOX MOBILE",
    "cc": "352",
    "cctld": "LU"
  },
  "27201": {
    "country": "Ireland",
    "network": "VODAFONE",
    "cc": "353",
    "cctld": "IE"
  },
  "27202": {
    "country": "Ireland",
    "network": "O2 DIGIFONE",
    "cc": "353",
    "cctld": "IE"
  },
  "27203": {
    "country": "Ireland",
    "network": "METEOR",
    "cc": "353",
    "cctld": "IE"
  },
  "27205": {
    "country": "Ireland",
    "network": "HI3G",
    "cc": "353",
    "cctld": "IE"
  },
  "27213": {
    "country": "Ireland",
    "network": "Lycamobile",
    "cc": "44",
    "cctld": "IE"
  },
  "27401": {
    "country": "Iceland",
    "network": "LANDSSIMINN",
    "cc": "354",
    "cctld": "IS"
  },
  "27402": {
    "country": "Iceland",
    "network": "VODAFONE",
    "cc": "354",
    "cctld": "IS"
  },
  "27404": {
    "country": "Iceland",
    "network": "IMC",
    "cc": "354",
    "cctld": "IS"
  },
  "27601": {
    "country": "Albania",
    "network": "AMC",
    "cc": "355",
    "cctld": "AL"
  },
  "27602": {
    "country": "Albania",
    "network": "VODAFONE",
    "cc": "355",
    "cctld": "AL"
  },
  "27603": {
    "country": "Albania",
    "network": "EAGLE",
    "cc": "355",
    "cctld": "AL"
  },
  "27604": {
    "country": "Albania",
    "network": "PLUS",
    "cc": "355",
    "cctld": "AL"
  },
  "27801": {
    "country": "Malta",
    "network": "VODAFONE",
    "cc": "356",
    "cctld": "MT"
  },
  "27821": {
    "country": "Malta",
    "network": "GO MOBILE",
    "cc": "356",
    "cctld": "MT"
  },
  "28001": {
    "country": "Cyprus",
    "network": "VODAFONE",
    "cc": "357",
    "cctld": "CY"
  },
  "28010": {
    "country": "Cyprus",
    "network": "AREEBA",
    "cc": "357",
    "cctld": "CY"
  },
  "28020": {
    "country": "Cyprus",
    "network": "PRIMETEL",
    "cc": "357",
    "cctld": "CY"
  },
  "28201": {
    "country": "Georgia Republic",
    "network": "GEOCELL",
    "cc": "995",
    "cctld": "GE"
  },
  "28202": {
    "country": "Georgia Republic",
    "network": "MAGTICOM",
    "cc": "995",
    "cctld": "GE"
  },
  "28203": {
    "country": "Georgia",
    "network": "MagtiCom GSM",
    "cc": "995",
    "cctld": "GE"
  },
  "28204": {
    "country": "Georgia",
    "network": "Mobitel (Beeline)",
    "cc": "995",
    "cctld": "GE"
  },
  "28205": {
    "country": "Georgia",
    "network": "Silknet",
    "cc": "995",
    "cctld": "GE"
  },
  "28206": {
    "country": "Georgia",
    "network": "Silknet",
    "cc": "995",
    "cctld": "GE"
  },
  "28208": {
    "country": "Georgia",
    "network": "Silknet",
    "cc": "995",
    "cctld": "GE"
  },
  "28301": {
    "country": "Armenia",
    "network": "ARMENTEL",
    "cc": "374",
    "cctld": "AM"
  },
  "28304": {
    "country": "Armenia",
    "network": "K-TELECOM",
    "cc": "374",
    "cctld": "AM"
  },
  "28305": {
    "country": "Armenia",
    "network": "MTS - Vivacell",
    "cc": "374",
    "cctld": "AM"
  },
  "28310": {
    "country": "Armenia",
    "network": "Orange (Ucom)",
    "cc": "374",
    "cctld": "AM"
  },
  "28401": {
    "country": "Bulgaria",
    "network": "MOBILTEL",
    "cc": "359",
    "cctld": "BG"
  },
  "28403": {
    "country": "Bulgaria",
    "network": "VIVATEL",
    "cc": "359",
    "cctld": "BG"
  },
  "28405": {
    "country": "Bulgaria",
    "network": "GLOBUL",
    "cc": "359",
    "cctld": "BG"
  },
  "28601": {
    "country": "Turkey",
    "network": "TURKCELL",
    "cc": "90",
    "cctld": "TR"
  },
  "28602": {
    "country": "Turkey",
    "network": "TELSIM",
    "cc": "90",
    "cctld": "TR"
  },
  "28603": {
    "country": "Turkey",
    "network": "ARIA (IS-TIM)",
    "cc": "90",
    "cctld": "TR"
  },
  "28604": {
    "country": "Turkey",
    "network": "AYCELL",
    "cc": "90",
    "cctld": "TR"
  },
  "28801": {
    "country": "Faeroe Islands",
    "network": "FAROESE TELECOM",
    "cc": "298",
    "cctld": "DK"
  },
  "28802": {
    "country": "Faeroe Islands",
    "network": "KALL-GSM",
    "cc": "298",
    "cctld": "DK"
  },
  "29001": {
    "country": "Greenland",
    "network": "TELE GREENLAND",
    "cc": "299",
    "cctld": "GL"
  },
  "29340": {
    "country": "Slovenia",
    "network": "SI.MOBIL",
    "cc": "386",
    "cctld": "SI"
  },
  "29341": {
    "country": "Slovenia",
    "network": "MOBITEL",
    "cc": "386",
    "cctld": "SI"
  },
  "29364": {
    "country": "Slovenia",
    "network": "T-2",
    "cc": "386",
    "cctld": "SI"
  },
  "29370": {
    "country": "Slovenia",
    "network": "VEGA WESTERN WIRELESS",
    "cc": "386",
    "cctld": "SI"
  },
  "29400": {
    "country": "Macedonia",
    "network": "ALO (MVNO)",
    "cc": "389",
    "cctld": "MK"
  },
  "29401": {
    "country": "Macedonia",
    "network": "T-MOBILE",
    "cc": "389",
    "cctld": "MK"
  },
  "29402": {
    "country": "Macedonia",
    "network": "ONE",
    "cc": "389",
    "cctld": "MK"
  },
  "29403": {
    "country": "Macedonia",
    "network": "VIP",
    "cc": "389",
    "cctld": "MK"
  },
  "29501": {
    "country": "Liechtenstein",
    "network": "TELECOM FLAG",
    "cc": "423",
    "cctld": "LI"
  },
  "29502": {
    "country": "Liechtenstein",
    "network": "VIAG",
    "cc": "423",
    "cctld": "LI"
  },
  "29505": {
    "country": "Liechtenstein",
    "network": "MOBILKOM LIE",
    "cc": "423",
    "cctld": "LI"
  },
  "29577": {
    "country": "Liechtenstein",
    "network": "TELE 2",
    "cc": "423",
    "cctld": "LI"
  },
  "33402": {
    "country": "Mexico",
    "network": "TELCEL (RADIOMOVIL)",
    "cc": "52",
    "cctld": "MX"
  },
  "33403": {
    "country": "Mexico",
    "network": "MOVISTAR",
    "cc": "52",
    "cctld": "MX"
  },
  "33805": {
    "country": "Jamaica",
    "network": "MOSSEL DIGICEL",
    "cc": "1",
    "cctld": "JM"
  },
  "34001": {
    "country": "Martinique (French)",
    "network": "ORANGE CARAIBE",
    "cc": "596",
    "cctld": "MQ"
  },
  "34020": {
    "country": "Martinique (French)",
    "network": "BOUYGUES TELECOM",
    "cc": "596",
    "cctld": "MQ"
  },
  "34260": {
    "country": "Barbados",
    "network": "CABLE & WIRELESS",
    "cc": "1",
    "cctld": "BB"
  },
  "34281": {
    "country": "Barbados",
    "network": "CINGULAR WIRELESS",
    "cc": "1",
    "cctld": "BB"
  },
  "36251": {
    "country": "Netherlands Antilles",
    "network": "TELEM (ST MAARTEN)",
    "cc": "599",
    "cctld": "AN"
  },
  "36296": {
    "country": "Netherlands Antilles",
    "network": "SETEL (CURACAO)",
    "cc": "599",
    "cctld": "AN"
  },
  "36301": {
    "country": "Aruba",
    "network": "SETAR",
    "cc": "297",
    "cctld": "AW"
  },
  "36310": {
    "country": "Aruba",
    "network": "MOSSEL DIGICEL",
    "cc": "297",
    "cctld": "AW"
  },
  "36801": {
    "country": "Cuba",
    "network": "C-COM",
    "cc": "53",
    "cctld": "CU"
  },
  "37001": {
    "country": "Dominica",
    "network": "ORANGE",
    "cc": "1",
    "cctld": "DM"
  },
  "37201": {
    "country": "Haiti",
    "network": "VOILA!",
    "cc": "509",
    "cctld": "HT"
  },
  "37202": {
    "country": "Haiti",
    "network": "DIGICEL",
    "cc": "509",
    "cctld": "HT"
  },
  "37203": {
    "country": "Haiti",
    "network": "NATCOM",
    "cc": "509",
    "cctld": "HT"
  },
  "40001": {
    "country": "Azerbaijan",
    "network": "AZERCELL",
    "cc": "994",
    "cctld": "AZ"
  },
  "40002": {
    "country": "Azerbaijan",
    "network": "BAKCELL",
    "cc": "994",
    "cctld": "AZ"
  },
  "40003": {
    "country": "Azerbaijan",
    "network": "FONEX (Catel)",
    "cc": "994",
    "cctld": "AZ"
  },
  "40004": {
    "country": "Azerbaijan",
    "network": "Azerfon LLC (Nar Mobile)",
    "cc": "994",
    "cctld": "AZ"
  },
  "40101": {
    "country": "Kazakhstan",
    "network": "KAR TEL",
    "cc": "7",
    "cctld": "KZ"
  },
  "40102": {
    "country": "Kazakhstan",
    "network": "K'CELL",
    "cc": "7",
    "cctld": "KZ"
  },
  "40107": {
    "country": "Kazakhstan",
    "network": "Altel - Dalacom",
    "cc": "7",
    "cctld": "KZ"
  },
  "40177": {
    "country": "Kazakhstan",
    "network": "Mobile Telecom Service - Tele2",
    "cc": "7",
    "cctld": "KZ"
  },
  "40211": {
    "country": "Bhutan",
    "network": "B-MOBILE",
    "cc": "975",
    "cctld": "BT"
  },
  "40401": {
    "country": "India",
    "network": "AIRCELL DIGILINK (HARYANA)",
    "cc": "91",
    "cctld": "IN"
  },
  "40402": {
    "country": "India",
    "network": "AIRTEL BHARTI CELLULAR (PUNJAB)",
    "cc": "91",
    "cctld": "IN"
  },
  "40403": {
    "country": "India",
    "network": "Unknown-INDIA-9805",
    "cc": "91",
    "cctld": "IN"
  },
  "40405": {
    "country": "India",
    "network": "FASCEL LIMITED",
    "cc": "91",
    "cctld": "IN"
  },
  "40407": {
    "country": "India",
    "network": "IDEA CELLULAR (GUJARAT)",
    "cc": "91",
    "cctld": "IN"
  },
  "40410": {
    "country": "India",
    "network": "BHARTI AIRTEL (DELHI)",
    "cc": "91",
    "cctld": "IN"
  },
  "40411": {
    "country": "India",
    "network": "HUTCHISON ESSAR TELECOM",
    "cc": "91",
    "cctld": "IN"
  },
  "40413": {
    "country": "India",
    "network": "HUTCHISON MAX TELECOM",
    "cc": "91",
    "cctld": "IN"
  },
  "40416": {
    "country": "India",
    "network": "Unknown-INDIA-9894",
    "cc": "91",
    "cctld": "IN"
  },
  "40420": {
    "country": "India",
    "network": "HUTCHISON ESSAR TELECOM",
    "cc": "91",
    "cctld": "IN"
  },
  "40421": {
    "country": "India",
    "network": "Loop Telecom // Mumbai",
    "cc": "91",
    "cctld": "IN"
  },
  "40422": {
    "country": "India",
    "network": "IDEA CELLULAR (MAHARASHTRA)",
    "cc": "91",
    "cctld": "IN"
  },
  "40424": {
    "country": "India",
    "network": "IDEA CELLULAR (GUJARAT)",
    "cc": "91",
    "cctld": "IN"
  },
  "40427": {
    "country": "India",
    "network": "HUTCHISON ESSAR (MAHARASHTRA)",
    "cc": "91",
    "cctld": "IN"
  },
  "40434": {
    "country": "India",
    "network": "BSNL (BIHAR)",
    "cc": "91",
    "cctld": "IN"
  },
  "40440": {
    "country": "India",
    "network": "Unknown-INDIA-9500",
    "cc": "91",
    "cctld": "IN"
  },
  "40441": {
    "country": "India",
    "network": "RPG CELLULAR SERVICES",
    "cc": "91",
    "cctld": "IN"
  },
  "40442": {
    "country": "India",
    "network": "AIRCEL (TAMIL NADU)",
    "cc": "91",
    "cctld": "IN"
  },
  "40443": {
    "country": "India",
    "network": "HUTCHISON ESSAR (TAMIL NADU)",
    "cc": "91",
    "cctld": "IN"
  },
  "40444": {
    "country": "India",
    "network": "SPICE TELECOM (PUNJAB)",
    "cc": "91",
    "cctld": "IN"
  },
  "40445": {
    "country": "India",
    "network": "AIRTEL",
    "cc": "91",
    "cctld": "IN"
  },
  "40446": {
    "country": "India",
    "network": "BPL MOBILE COMM (MUMBAI)",
    "cc": "91",
    "cctld": "IN"
  },
  "40449": {
    "country": "India",
    "network": "BHARTI AIRTEL (ANDRA PRADESH)",
    "cc": "91",
    "cctld": "IN"
  },
  "40460": {
    "country": "India",
    "network": "AIRCELL DIGILINK (RAJASTHAN)",
    "cc": "91",
    "cctld": "IN"
  },
  "40467": {
    "country": "India",
    "network": "RELIANCE TELECOM",
    "cc": "91",
    "cctld": "IN"
  },
  "40468": {
    "country": "India",
    "network": "MTNL (DELHI)",
    "cc": "91",
    "cctld": "IN"
  },
  "40493": {
    "country": "India",
    "network": "BHARTI AIRTEL (MADHYA PRADESH)",
    "cc": "91",
    "cctld": "IN"
  },
  "40495": {
    "country": "India",
    "network": "BHARTI AIRTEL (KERALA)",
    "cc": "91",
    "cctld": "IN"
  },
  "40496": {
    "country": "India",
    "network": "BHARTI AIRTEL (HARYANA)",
    "cc": "91",
    "cctld": "IN"
  },
  "40498": {
    "country": "India",
    "network": "BHARTI AIRTEL (GUJARAT)",
    "cc": "91",
    "cctld": "IN"
  },
  "41001": {
    "country": "Pakistan",
    "network": "PMC MOBILINK",
    "cc": "92",
    "cctld": "PK"
  },
  "41003": {
    "country": "Pakistan",
    "network": "PAK UFONE",
    "cc": "92",
    "cctld": "PK"
  },
  "41004": {
    "country": "Pakistan",
    "network": "ZONG",
    "cc": "92",
    "cctld": "PK"
  },
  "41006": {
    "country": "Pakistan",
    "network": "TELENOR PAKISTAN",
    "cc": "92",
    "cctld": "PK"
  },
  "41007": {
    "country": "Pakistan",
    "network": "WARID TELECOM",
    "cc": "92",
    "cctld": "PK"
  },
  "41201": {
    "country": "Afghanistan",
    "network": "Afghan Wireless/AWCC",
    "cc": "93",
    "cctld": "AF"
  },
  "41220": {
    "country": "Afghanistan",
    "network": "Roshan",
    "cc": "93",
    "cctld": "AF"
  },
  "41240": {
    "country": "Afghanistan",
    "network": "MTN",
    "cc": "93",
    "cctld": "AF"
  },
  "41250": {
    "country": "Afghanistan",
    "network": "Etisalat",
    "cc": "93",
    "cctld": "AF"
  },
  "41280": {
    "country": "Afghanistan",
    "network": "Afghan Telecom (Salaam)",
    "cc": "93",
    "cctld": "AF"
  },
  "41288": {
    "country": "Afghanistan",
    "network": "Afghan Telecom Corp. (AT)",
    "cc": "93",
    "cctld": "AF"
  },
  "41301": {
    "country": "Sri Lanka",
    "network": "MOBITEL LANKA",
    "cc": "94",
    "cctld": "LK"
  },
  "41302": {
    "country": "Sri Lanka",
    "network": "DIALOG TELEKOM LTD",
    "cc": "94",
    "cctld": "LK"
  },
  "41303": {
    "country": "Sri Lanka",
    "network": "CELLTEL LANKA",
    "cc": "94",
    "cctld": "LK"
  },
  "41305": {
    "country": "Sri Lanka",
    "network": "Bharti Airtel",
    "cc": "94",
    "cctld": "LK"
  },
  "41308": {
    "country": "Sri Lanka",
    "network": "HUTCHISON",
    "cc": "94",
    "cctld": "LK"
  },
  "41312": {
    "country": "Sri Lanka",
    "network": "Dialog",
    "cc": "94",
    "cctld": "LK"
  },
  "41401": {
    "country": "Myanmar",
    "network": "MPT GSM Network",
    "cc": "95",
    "cctld": "MM"
  },
  "41405": {
    "country": "Myanmar",
    "network": "Ooredo",
    "cc": "95",
    "cctld": "MM"
  },
  "41406": {
    "country": "Myanmar",
    "network": "Telenor",
    "cc": "95",
    "cctld": "MM"
  },
  "41409": {
    "country": "Myanmar",
    "network": "Mytel (Viettel)",
    "cc": "95",
    "cctld": "MM"
  },
  "41501": {
    "country": "Lebanon",
    "network": "MIC 1 ALFA",
    "cc": "961",
    "cctld": "LB"
  },
  "41503": {
    "country": "Lebanon",
    "network": "MTC LEBANON",
    "cc": "961",
    "cctld": "LB"
  },
  "41601": {
    "country": "Jordan",
    "network": "FASTLINK",
    "cc": "962",
    "cctld": "JO"
  },
  "41602": {
    "country": "Jordan",
    "network": "UMNIAH (UMC)",
    "cc": "962",
    "cctld": "JO"
  },
  "41603": {
    "country": "Jordan",
    "network": "XPRESS",
    "cc": "962",
    "cctld": "JO"
  },
  "41677": {
    "country": "Jordan",
    "network": "MOBILECOM",
    "cc": "962",
    "cctld": "JO"
  },
  "41701": {
    "country": "Syria",
    "network": "SYRIATEL",
    "cc": "963",
    "cctld": "SY"
  },
  "41702": {
    "country": "Syria",
    "network": "AREEBA",
    "cc": "963",
    "cctld": "SY"
  },
  "41805": {
    "country": "Iraq",
    "network": "ASIA CELL",
    "cc": "964",
    "cctld": "IQ"
  },
  "41808": {
    "country": "Iraq",
    "network": "SANATEL",
    "cc": "964",
    "cctld": "IQ"
  },
  "41820": {
    "country": "Iraq",
    "network": "ATHEER",
    "cc": "964",
    "cctld": "IQ"
  },
  "41830": {
    "country": "Iraq",
    "network": "ORASCOM",
    "cc": "964",
    "cctld": "IQ"
  },
  "41840": {
    "country": "Iraq",
    "network": "KOREK",
    "cc": "964",
    "cctld": "IQ"
  },
  "41845": {
    "country": "Iraq",
    "network": "Mobitel",
    "cc": "964",
    "cctld": "IQ"
  },
  "41862": {
    "country": "Iraq",
    "network": "ITISALUNA",
    "cc": "964",
    "cctld": "IQ"
  },
  "41892": {
    "country": "Iraq",
    "network": "Omnea (Al-Nakheel)",
    "cc": "964",
    "cctld": "IQ"
  },
  "41902": {
    "country": "Kuwait",
    "network": "MTC",
    "cc": "965",
    "cctld": "KW"
  },
  "41903": {
    "country": "Kuwait",
    "network": "WATANIYA",
    "cc": "965",
    "cctld": "KW"
  },
  "42001": {
    "country": "Saudi Arabia",
    "network": "STC",
    "cc": "966",
    "cctld": "SA"
  },
  "42003": {
    "country": "Saudi Arabia",
    "network": "MOBILY",
    "cc": "966",
    "cctld": "SA"
  },
  "42004": {
    "country": "Saudi Arabia",
    "network": "ZAIN",
    "cc": "966",
    "cctld": "SA"
  },
  "42005": {
    "country": "Saudi Arabia",
    "network": "Saudi Telecom (STC) - Al Jawwal",
    "cc": "966",
    "cctld": "SA"
  },
  "42006": {
    "country": "Saudi Arabia",
    "network": "Etisalat (Mobily)",
    "cc": "966",
    "cctld": "SA"
  },
  "42101": {
    "country": "Yemen",
    "network": "SABAFON",
    "cc": "967",
    "cctld": "YE"
  },
  "42102": {
    "country": "Yemen",
    "network": "SPACETEL",
    "cc": "967",
    "cctld": "YE"
  },
  "42103": {
    "country": "Yemen",
    "network": "YEMENTEL",
    "cc": "967",
    "cctld": "YE"
  },
  "42104": {
    "country": "Yemen",
    "network": "UNITEL",
    "cc": "967",
    "cctld": "YE"
  },
  "42202": {
    "country": "Oman",
    "network": "OMANTEL",
    "cc": "968",
    "cctld": "OM"
  },
  "42203": {
    "country": "Oman",
    "network": "NAWRAS",
    "cc": "968",
    "cctld": "OM"
  },
  "42402": {
    "country": "United Arab Emirates",
    "network": "DU",
    "cc": "971",
    "cctld": "AE"
  },
  "42403": {
    "country": "United Arab Emirates",
    "network": "DU",
    "cc": "971",
    "cctld": "AE"
  },
  "42501": {
    "country": "Israel",
    "network": "PARTNER ORANGE",
    "cc": "972",
    "cctld": "IL"
  },
  "42502": {
    "country": "Israel",
    "network": "CELLCOM",
    "cc": "972",
    "cctld": "IL"
  },
  "42505": {
    "country": "Palestine",
    "network": "PALTEL",
    "cc": "972",
    "cctld": "PS"
  },
  "42508": {
    "country": "Israel",
    "network": "Unknown-ISRAEL-5493",
    "cc": "972",
    "cctld": "IL"
  },
  "42577": {
    "country": "Israel",
    "network": "MIRS",
    "cc": "972",
    "cctld": "IL"
  },
  "42601": {
    "country": "Bahrain",
    "network": "BATELCO",
    "cc": "973",
    "cctld": "BH"
  },
  "42602": {
    "country": "Bahrain",
    "network": "VODAFONE",
    "cc": "973",
    "cctld": "BH"
  },
  "42604": {
    "country": "Bahrain",
    "network": "STC Viva",
    "cc": "973",
    "cctld": "BH"
  },
  "42605": {
    "country": "Bahrain",
    "network": "Batelco",
    "cc": "973",
    "cctld": "BH"
  },
  "42701": {
    "country": "Qatar",
    "network": "Q-TEL",
    "cc": "974",
    "cctld": "QA"
  },
  "42702": {
    "country": "Qatar",
    "network": "VODAFONE",
    "cc": "974",
    "cctld": "QA"
  },
  "42888": {
    "country": "Mongolia",
    "network": "Unitel",
    "cc": "976",
    "cctld": "MN"
  },
  "42891": {
    "country": "Mongolia",
    "network": "Skytel",
    "cc": "976",
    "cctld": "MN"
  },
  "42898": {
    "country": "Mongolia",
    "network": "GMobile",
    "cc": "976",
    "cctld": "MN"
  },
  "42899": {
    "country": "Mongolia",
    "network": "MOBICOM",
    "cc": "976",
    "cctld": "MN"
  },
  "42901": {
    "country": "Nepal",
    "network": "Nepal Telecom",
    "cc": "977",
    "cctld": "NP"
  },
  "42902": {
    "country": "Nepal",
    "network": "Ncell Spice - Mero",
    "cc": "977",
    "cctld": "NP"
  },
  "42904": {
    "country": "Nepal",
    "network": "Smart Telecom",
    "cc": "977",
    "cctld": "NP"
  },
  "43211": {
    "country": "Iran",
    "network": "MCI",
    "cc": "98",
    "cctld": "IR"
  },
  "43214": {
    "country": "Iran",
    "network": "KFZO (TKC)",
    "cc": "98",
    "cctld": "IR"
  },
  "43219": {
    "country": "Iran",
    "network": "MTCE",
    "cc": "98",
    "cctld": "IR"
  },
  "43220": {
    "country": "Iran",
    "network": "RighTel Communications (Tamin Telecom)",
    "cc": "98",
    "cctld": "IR"
  },
  "43232": {
    "country": "Iran",
    "network": "Taliya",
    "cc": "98",
    "cctld": "IR"
  },
  "43235": {
    "country": "Iran",
    "network": "MTN Irancell",
    "cc": "98",
    "cctld": "IR"
  },
  "43401": {
    "country": "Uzbekistan",
    "network": "Beeline (Unitel)",
    "cc": "998",
    "cctld": "UZ"
  },
  "43403": {
    "country": "Uzbekistan",
    "network": "UzMobile",
    "cc": "998",
    "cctld": "UZ"
  },
  "43404": {
    "country": "Uzbekistan",
    "network": "UNITEL",
    "cc": "998",
    "cctld": "UZ"
  },
  "43405": {
    "country": "Uzbekistan",
    "network": "COSCOM",
    "cc": "998",
    "cctld": "UZ"
  },
  "43406": {
    "country": "Uzbekistan",
    "network": "Perfectum Mobile (Rubicom)",
    "cc": "998",
    "cctld": "UZ"
  },
  "43407": {
    "country": "Uzbekistan",
    "network": "UZDUNROBITA",
    "cc": "998",
    "cctld": "UZ"
  },
  "43408": {
    "country": "Uzbekistan",
    "network": "UzMobile",
    "cc": "998",
    "cctld": "UZ"
  },
  "43601": {
    "country": "Tajikistan",
    "network": "SOMONCOM",
    "cc": "992",
    "cctld": "TJ"
  },
  "43602": {
    "country": "Tajikistan",
    "network": "INDIGO",
    "cc": "992",
    "cctld": "TJ"
  },
  "43603": {
    "country": "Tajikistan",
    "network": "TT MOBILE",
    "cc": "992",
    "cctld": "TJ"
  },
  "43604": {
    "country": "Tajikistan",
    "network": "BABILON-M",
    "cc": "992",
    "cctld": "TJ"
  },
  "43605": {
    "country": "Tajikistan",
    "network": "TK MOBILE",
    "cc": "992",
    "cctld": "TJ"
  },
  "43610": {
    "country": "Tajikistan",
    "network": "TAJIKTEL",
    "cc": "992",
    "cctld": "TJ"
  },
  "43612": {
    "country": "Tajikistan",
    "network": "Indigo",
    "cc": "992",
    "cctld": "TJ"
  },
  "43701": {
    "country": "Kyrgyzstan",
    "network": "BITEL",
    "cc": "996",
    "cctld": "KG"
  },
  "43705": {
    "country": "Kyrgyzstan",
    "network": "Megacom",
    "cc": "996",
    "cctld": "KG"
  },
  "43801": {
    "country": "Turkmenistan",
    "network": "BCTI",
    "cc": "993",
    "cctld": "TM"
  },
  "43802": {
    "country": "Turkmenistan",
    "network": "TM-Cell (Altyn Asyr)",
    "cc": "993",
    "cctld": "TM"
  },
  "44010": {
    "country": "Japan",
    "network": "VODAFONE",
    "cc": "81",
    "cctld": "JP"
  },
  "44020": {
    "country": "Japan",
    "network": "NTT DOCOMO",
    "cc": "81",
    "cctld": "JP"
  },
  "45001": {
    "country": "South Korea",
    "network": "SK TELECOM",
    "cc": "82",
    "cctld": "KR"
  },
  "45002": {
    "country": "South Korea",
    "network": "KT FREETEL",
    "cc": "82",
    "cctld": "KR"
  },
  "45005": {
    "country": "Korea",
    "network": "SK Telecom",
    "cc": "82",
    "cctld": "KP"
  },
  "45006": {
    "country": "Korea",
    "network": "LG Telecom",
    "cc": "82",
    "cctld": "KP"
  },
  "45008": {
    "country": "Korea",
    "network": "KT FreeTel",
    "cc": "82",
    "cctld": "KP"
  },
  "45201": {
    "country": "Vietnam",
    "network": "Mobifone",
    "cc": "84",
    "cctld": "VN"
  },
  "45202": {
    "country": "Vietnam",
    "network": "VinaPhone",
    "cc": "84",
    "cctld": "VN"
  },
  "45204": {
    "country": "Vietnam",
    "network": "Viettel Mobile",
    "cc": "84",
    "cctld": "VN"
  },
  "45205": {
    "country": "Vietnam",
    "network": "Vietnamobile (Hanoi Telecom)",
    "cc": "84",
    "cctld": "VN"
  },
  "45207": {
    "country": "Vietnam",
    "network": "Gtel (Beeline)",
    "cc": "84",
    "cctld": "VN"
  },
  "45400": {
    "country": "Hong Kong",
    "network": "HONG KONG CSL",
    "cc": "852",
    "cctld": "HK"
  },
  "45401": {
    "country": "Hong Kong",
    "network": "CITIC",
    "cc": "852",
    "cctld": "HK"
  },
  "45403": {
    "country": "Hong Kong",
    "network": "H3G",
    "cc": "852",
    "cctld": "HK"
  },
  "45404": {
    "country": "Hong Kong",
    "network": "HUTCHINSON",
    "cc": "852",
    "cctld": "HK"
  },
  "45405": {
    "country": "Hong Kong",
    "network": "Hutchison",
    "cc": "852",
    "cctld": "HK"
  },
  "45406": {
    "country": "Hong Kong",
    "network": "SMARTONE",
    "cc": "852",
    "cctld": "HK"
  },
  "45407": {
    "country": "Hong Kong",
    "network": "China Unicom",
    "cc": "852",
    "cctld": "HK"
  },
  "45408": {
    "country": "Hong Kong",
    "network": "Trident (Truphone)",
    "cc": "852",
    "cctld": "HK"
  },
  "45409": {
    "country": "Hong Kong",
    "network": "China Motion",
    "cc": "852",
    "cctld": "HK"
  },
  "45410": {
    "country": "Hong Kong",
    "network": "NEW WORLD PCS",
    "cc": "852",
    "cctld": "HK"
  },
  "45411": {
    "country": "Hong Kong",
    "network": "China-Hong Kong Telecom",
    "cc": "852",
    "cctld": "HK"
  },
  "45412": {
    "country": "Hong Kong",
    "network": "PEOPLES",
    "cc": "852",
    "cctld": "HK"
  },
  "45413": {
    "country": "Hong Kong",
    "network": "China Mobile (Peoples)",
    "cc": "852",
    "cctld": "HK"
  },
  "45415": {
    "country": "Hong Kong",
    "network": "SmarTone",
    "cc": "852",
    "cctld": "HK"
  },
  "45416": {
    "country": "Hong Kong",
    "network": "SUNDAY",
    "cc": "852",
    "cctld": "HK"
  },
  "45417": {
    "country": "Hong Kong",
    "network": "SmarTone",
    "cc": "852",
    "cctld": "HK"
  },
  "45419": {
    "country": "Hong Kong",
    "network": "PCCW",
    "cc": "852",
    "cctld": "HK"
  },
  "45420": {
    "country": "Hong Kong",
    "network": "PCCW",
    "cc": "852",
    "cctld": "HK"
  },
  "45424": {
    "country": "Hong Kong",
    "network": "MultiByte",
    "cc": "852",
    "cctld": "HK"
  },
  "45429": {
    "country": "Hong Kong",
    "network": "PCCW",
    "cc": "852",
    "cctld": "HK"
  },
  "45500": {
    "country": "Macao",
    "network": "SMARTONE",
    "cc": "853",
    "cctld": "MO"
  },
  "45501": {
    "country": "Macao",
    "network": "CTM",
    "cc": "853",
    "cctld": "MO"
  },
  "45503": {
    "country": "Macao",
    "network": "HUTCHISON",
    "cc": "853",
    "cctld": "MO"
  },
  "45601": {
    "country": "Cambodia",
    "network": "CAM GSM",
    "cc": "855",
    "cctld": "KH"
  },
  "45602": {
    "country": "Cambodia",
    "network": "SAMART COMMS",
    "cc": "855",
    "cctld": "KH"
  },
  "45603": {
    "country": "Cambodia",
    "network": "SHINAWATRA",
    "cc": "855",
    "cctld": "KH"
  },
  "45604": {
    "country": "Cambodia",
    "network": "qb (Cadcomms)",
    "cc": "855",
    "cctld": "KH"
  },
  "45605": {
    "country": "Cambodia",
    "network": "Smart Axiata (Hello / Latelz)",
    "cc": "855",
    "cctld": "KH"
  },
  "45606": {
    "country": "Cambodia",
    "network": "Smart Axiata (Hello / Latelz)",
    "cc": "855",
    "cctld": "KH"
  },
  "45608": {
    "country": "Cambodia",
    "network": "Metfone (Viettel Cambodia)",
    "cc": "855",
    "cctld": "KH"
  },
  "45609": {
    "country": "Cambodia",
    "network": "Metfone (Viettel Cambodia)",
    "cc": "855",
    "cctld": "KH"
  },
  "45611": {
    "country": "Cambodia",
    "network": "Seatel Camtel",
    "cc": "855",
    "cctld": "KH"
  },
  "45618": {
    "country": "Cambodia",
    "network": "Cellcard (Mobitel/ CamGSM)",
    "cc": "855",
    "cctld": "KH"
  },
  "45701": {
    "country": "Laos",
    "network": "LAOTEL",
    "cc": "856",
    "cctld": "LA"
  },
  "45702": {
    "country": "Laos",
    "network": "ETL MOBILE",
    "cc": "856",
    "cctld": "LA"
  },
  "45703": {
    "country": "Laos",
    "network": "Unitel (Viettel)",
    "cc": "856",
    "cctld": "LA"
  },
  "45708": {
    "country": "Laos",
    "network": "MILLICOM",
    "cc": "856",
    "cctld": "LA"
  },
  "46000": {
    "country": "China Peoples Republic",
    "network": "CHINA MOBILE",
    "cc": "86",
    "cctld": "CN"
  },
  "46001": {
    "country": "China Peoples Republic",
    "network": "CHINA UNICOM",
    "cc": "86",
    "cctld": "CN"
  },
  "46002": {
    "country": "China",
    "network": "China Mobile",
    "cc": "86",
    "cctld": "CN"
  },
  "46003": {
    "country": "China",
    "network": "China Telecom",
    "cc": "86",
    "cctld": "CN"
  },
  "46005": {
    "country": "China",
    "network": "China Telecom",
    "cc": "86",
    "cctld": "CN"
  },
  "46006": {
    "country": "China",
    "network": "China Unicom",
    "cc": "86",
    "cctld": "CN"
  },
  "46007": {
    "country": "China",
    "network": "China Mobile",
    "cc": "86",
    "cctld": "CN"
  },
  "46011": {
    "country": "China",
    "network": "China Telecom",
    "cc": "86",
    "cctld": "CN"
  },
  "46601": {
    "country": "Taiwan",
    "network": "FAR EASTONE",
    "cc": "886",
    "cctld": "TW"
  },
  "46688": {
    "country": "Taiwan",
    "network": "KG TELECOM",
    "cc": "886",
    "cctld": "TW"
  },
  "46692": {
    "country": "Taiwan",
    "network": "CHUNGHWA",
    "cc": "886",
    "cctld": "TW"
  },
  "46693": {
    "country": "Taiwan",
    "network": "MOBITAI",
    "cc": "886",
    "cctld": "TW"
  },
  "46697": {
    "country": "Taiwan",
    "network": "TAIWAN CELL",
    "cc": "886",
    "cctld": "TW"
  },
  "46699": {
    "country": "Taiwan",
    "network": "TRANS ASIA",
    "cc": "886",
    "cctld": "TW"
  },
  "47001": {
    "country": "Bangladesh",
    "network": "GRAMEEN PHONE LIMITED",
    "cc": "880",
    "cctld": "BD"
  },
  "47002": {
    "country": "Bangladesh",
    "network": "SHEBA TELECOM",
    "cc": "880",
    "cctld": "BD"
  },
  "47003": {
    "country": "Bangladesh",
    "network": "AKTEL",
    "cc": "880",
    "cctld": "BD"
  },
  "47004": {
    "country": "Bangladesh",
    "network": "Teletalk",
    "cc": "880",
    "cctld": "BD"
  },
  "47005": {
    "country": "Bangladesh",
    "network": "CityCell",
    "cc": "880",
    "cctld": "BD"
  },
  "47007": {
    "country": "Bangladesh",
    "network": "Barthi Airtel Bangladesh",
    "cc": "880",
    "cctld": "BD"
  },
  "47201": {
    "country": "Maldives",
    "network": "DHIRAAGU",
    "cc": "960",
    "cctld": "MV"
  },
  "47202": {
    "country": "Maldives",
    "network": "WATANIYA",
    "cc": "960",
    "cctld": "MV"
  },
  "50210": {
    "country": "Malaysia",
    "network": "Digi",
    "cc": "60",
    "cctld": "MY"
  },
  "50211": {
    "country": "Malaysia",
    "network": "MTX (Telekom Malaysia Bhd)",
    "cc": "60",
    "cctld": "MY"
  },
  "50212": {
    "country": "Malaysia",
    "network": "MAXIS COMMUNICATIONS",
    "cc": "60",
    "cctld": "MY"
  },
  "50213": {
    "country": "Malaysia",
    "network": "TM TOUCH",
    "cc": "60",
    "cctld": "MY"
  },
  "50215": {
    "country": "Malaysia",
    "network": "YTL Communications",
    "cc": "60",
    "cctld": "MY"
  },
  "50216": {
    "country": "Malaysia",
    "network": "DIGI TELECOM",
    "cc": "60",
    "cctld": "MY"
  },
  "50217": {
    "country": "Malaysia",
    "network": "TIME WIRELESS",
    "cc": "60",
    "cctld": "MY"
  },
  "50218": {
    "country": "Malaysia",
    "network": "Umobile",
    "cc": "60",
    "cctld": "MY"
  },
  "50219": {
    "country": "Malaysia",
    "network": "CELCOM",
    "cc": "60",
    "cctld": "MY"
  },
  "50501": {
    "country": "Australia",
    "network": "TELSTRA",
    "cc": "61",
    "cctld": "AU"
  },
  "50502": {
    "country": "Australia",
    "network": "OPTUS",
    "cc": "61",
    "cctld": "AU"
  },
  "50503": {
    "country": "Australia",
    "network": "VODAFONE",
    "cc": "61",
    "cctld": "AU"
  },
  "50506": {
    "country": "Australia",
    "network": "HUTCHINSON 3G",
    "cc": "61",
    "cctld": "AU"
  },
  "50512": {
    "country": "Australia",
    "network": "HUTCHINSON TELECOMMUNICATIONS",
    "cc": "61",
    "cctld": "AU"
  },
  "51001": {
    "country": "Indonesia",
    "network": "SATELINDO",
    "cc": "62",
    "cctld": "ID"
  },
  "51010": {
    "country": "Indonesia",
    "network": "TELKOMSEL",
    "cc": "62",
    "cctld": "ID"
  },
  "51011": {
    "country": "Indonesia",
    "network": "EXCELCOMINDO",
    "cc": "62",
    "cctld": "ID"
  },
  "51021": {
    "country": "Indonesia",
    "network": "INDOSAT",
    "cc": "62",
    "cctld": "ID"
  },
  "51402": {
    "country": "Timor-Leste",
    "network": "TIMOR TELECOM",
    "cc": "670",
    "cctld": "TL"
  },
  "51501": {
    "country": "Philippines",
    "network": "ISLACOM (INNOVE)",
    "cc": "63",
    "cctld": "PH"
  },
  "51502": {
    "country": "Philippines",
    "network": "GLOBE",
    "cc": "63",
    "cctld": "PH"
  },
  "51503": {
    "country": "Philippines",
    "network": "SMART",
    "cc": "63",
    "cctld": "PH"
  },
  "51505": {
    "country": "Philippines",
    "network": "Unknown-PHILIPPINES-9327",
    "cc": "63",
    "cctld": "PH"
  },
  "51518": {
    "country": "Philippines",
    "network": "Smart Communications",
    "cc": "63",
    "cctld": "PH"
  },
  "52001": {
    "country": "Thailand",
    "network": "AIS",
    "cc": "66",
    "cctld": "TH"
  },
  "52003": {
    "country": "Thailand",
    "network": "AIS GSM",
    "cc": "66",
    "cctld": "TH"
  },
  "52004": {
    "country": "Thailand",
    "network": "True Move (Real Future)",
    "cc": "66",
    "cctld": "TH"
  },
  "52005": {
    "country": "Thailand",
    "network": "DTAC",
    "cc": "66",
    "cctld": "TH"
  },
  "52015": {
    "country": "Thailand",
    "network": "TOT",
    "cc": "66",
    "cctld": "TH"
  },
  "52018": {
    "country": "Thailand",
    "network": "DTAC",
    "cc": "66",
    "cctld": "TH"
  },
  "52020": {
    "country": "Thailand",
    "network": "TOT",
    "cc": "66",
    "cctld": "TH"
  },
  "52023": {
    "country": "Thailand",
    "network": "DIGITAL PHONE",
    "cc": "66",
    "cctld": "TH"
  },
  "52088": {
    "country": "Thailand",
    "network": "Unknown-THAILAND-8351",
    "cc": "66",
    "cctld": "TH"
  },
  "52099": {
    "country": "Thailand",
    "network": "TA ORANGE",
    "cc": "66",
    "cctld": "TH"
  },
  "52502": {
    "country": "Singapore",
    "network": "SINGTEL MOBILE (GSM1800)",
    "cc": "65",
    "cctld": "SG"
  },
  "52503": {
    "country": "Singapore",
    "network": "MOBILEONE (M1)",
    "cc": "65",
    "cctld": "SG"
  },
  "52505": {
    "country": "Singapore",
    "network": "STARHUB",
    "cc": "65",
    "cctld": "SG"
  },
  "52510": {
    "country": "Singapore",
    "network": "TPG Telecom",
    "cc": "65",
    "cctld": "SG"
  },
  "52803": {
    "country": "Brunei Darussalam",
    "network": "B-MOBILE",
    "cc": "673",
    "cctld": "BN"
  },
  "52811": {
    "country": "Brunei Darussalam",
    "network": "DST COMMUNICATIONS",
    "cc": "673",
    "cctld": "BN"
  },
  "53001": {
    "country": "New Zealand",
    "network": "TELSTRA",
    "cc": "64",
    "cctld": "NZ"
  },
  "53002": {
    "country": "New Zealand",
    "network": "Telecom New Zealand - Spark",
    "cc": "64",
    "cctld": "NZ"
  },
  "53003": {
    "country": "New Zealand",
    "network": "Telecom New Zealand - Spark",
    "cc": "64",
    "cctld": "NZ"
  },
  "53004": {
    "country": "New Zealand",
    "network": "Vodafone - Telstra",
    "cc": "64",
    "cctld": "NZ"
  },
  "53005": {
    "country": "New Zealand",
    "network": "Telecom New Zealand - Spark",
    "cc": "64",
    "cctld": "NZ"
  },
  "53024": {
    "country": "New Zealand",
    "network": "Two Degrees - Northelia Holdings",
    "cc": "64",
    "cctld": "NZ"
  },
  "54101": {
    "country": "Vanuatu",
    "network": "SMILE",
    "cc": "678",
    "cctld": "VU"
  },
  "54201": {
    "country": "Fiji",
    "network": "VODAFONE",
    "cc": "679",
    "cctld": "FJ"
  },
  "54601": {
    "country": "Namibia",
    "network": "OPT",
    "cc": "687",
    "cctld": "NA"
  },
  "54720": {
    "country": "French Polynesia",
    "network": "VINI (TIKIPHONE)",
    "cc": "689",
    "cctld": "PF"
  },
  "60201": {
    "country": "Egypt",
    "network": "MOBINIL",
    "cc": "20",
    "cctld": "EG"
  },
  "60202": {
    "country": "Egypt",
    "network": "VODAFONE",
    "cc": "20",
    "cctld": "EG"
  },
  "60203": {
    "country": "Egypt",
    "network": "ETISALAT",
    "cc": "20",
    "cctld": "EG"
  },
  "60204": {
    "country": "Egypt",
    "network": "Egypt Telecom",
    "cc": "20",
    "cctld": "EG"
  },
  "60301": {
    "country": "Algeria",
    "network": "ATM MOBILIS",
    "cc": "213",
    "cctld": "DZ"
  },
  "60302": {
    "country": "Algeria",
    "network": "DJEZZY",
    "cc": "213",
    "cctld": "DZ"
  },
  "60303": {
    "country": "Algeria",
    "network": "WATANIYA",
    "cc": "213",
    "cctld": "DZ"
  },
  "60400": {
    "country": "Morocco",
    "network": "MEDITELECOM",
    "cc": "212",
    "cctld": "MA"
  },
  "60401": {
    "country": "Morocco",
    "network": "ITISSALAT AL-MAGHRIB",
    "cc": "212",
    "cctld": "MA"
  },
  "60402": {
    "country": "Morocco",
    "network": "Unknown-MOROCCO-6332",
    "cc": "212",
    "cctld": "MA"
  },
  "60501": {
    "country": "Tunisia",
    "network": "ORANGE",
    "cc": "216",
    "cctld": "TN"
  },
  "60502": {
    "country": "Tunisia",
    "network": "TUNTEL",
    "cc": "216",
    "cctld": "TN"
  },
  "60503": {
    "country": "Tunisia",
    "network": "TUNISIANA",
    "cc": "216",
    "cctld": "TN"
  },
  "60600": {
    "country": "International",
    "network": "Unknown-Unknown-9274",
    "cc": "218",
    "cctld": ""
  },
  "60601": {
    "country": "Libya",
    "network": "EL MADAR",
    "cc": "218",
    "cctld": "LY"
  },
  "60602": {
    "country": "Libya",
    "network": "LIBYANA",
    "cc": "218",
    "cctld": "LY"
  },
  "60701": {
    "country": "Gambia",
    "network": "GAMCELL",
    "cc": "220",
    "cctld": "GM"
  },
  "60702": {
    "country": "Gambia",
    "network": "AFRICELL",
    "cc": "220",
    "cctld": "GM"
  },
  "60703": {
    "country": "Gambia",
    "network": "Comium",
    "cc": "220",
    "cctld": "GM"
  },
  "60704": {
    "country": "Gambia",
    "network": "Gambia Qcell (Qcell) ",
    "cc": "220",
    "cctld": "GM"
  },
  "60801": {
    "country": "Senegal",
    "network": "SONATEL",
    "cc": "221",
    "cctld": "SN"
  },
  "60802": {
    "country": "Senegal",
    "network": "SENTEL",
    "cc": "221",
    "cctld": "SN"
  },
  "60803": {
    "country": "Senegal",
    "network": "EXPRESSO",
    "cc": "221",
    "cctld": "SN"
  },
  "60901": {
    "country": "Mauritania",
    "network": "MATTEL",
    "cc": "222",
    "cctld": "MR"
  },
  "60910": {
    "country": "Mauritania",
    "network": "MAURITEL MOBILES",
    "cc": "222",
    "cctld": "MR"
  },
  "61001": {
    "country": "Mali",
    "network": "MALITEL",
    "cc": "223",
    "cctld": "ML"
  },
  "61002": {
    "country": "Mali",
    "network": "IKATEL",
    "cc": "223",
    "cctld": "ML"
  },
  "61003": {
    "country": "Mali",
    "network": "Telecel (ATEL)",
    "cc": "223",
    "cctld": "ML"
  },
  "61101": {
    "country": "Guinea",
    "network": "SPACETEL",
    "cc": "224",
    "cctld": "GN"
  },
  "61102": {
    "country": "Guinea",
    "network": "SOTELGUI",
    "cc": "224",
    "cctld": "GN"
  },
  "61104": {
    "country": "Guinea",
    "network": "AREEBA",
    "cc": "224",
    "cctld": "GN"
  },
  "61105": {
    "country": "Guinea",
    "network": "Cellcom",
    "cc": "224",
    "cctld": "GN"
  },
  "61110": {
    "country": "Guinea",
    "network": "TELECEL",
    "cc": "224",
    "cctld": "GN"
  },
  "61202": {
    "country": "Ivory Coast",
    "network": "MOOV",
    "cc": "22",
    "cctld": "CI"
  },
  "61203": {
    "country": "Ivory Coast",
    "network": "ORANGE",
    "cc": "22",
    "cctld": "CI"
  },
  "61204": {
    "country": "Ivory Coast",
    "network": "KOZ",
    "cc": "22",
    "cctld": "CI"
  },
  "61205": {
    "country": "Ivory Coast",
    "network": "MTN",
    "cc": "22",
    "cctld": "CI"
  },
  "61206": {
    "country": "Ivory Coast",
    "network": "GREEN",
    "cc": "22",
    "cctld": "CI"
  },
  "61301": {
    "country": "Burkina Faso",
    "network": "ONATEL",
    "cc": "226",
    "cctld": "BF"
  },
  "61302": {
    "country": "Burkina Faso",
    "network": "CELTEL",
    "cc": "226",
    "cctld": "BF"
  },
  "61303": {
    "country": "Burkina Faso",
    "network": "TELECEL",
    "cc": "226",
    "cctld": "BF"
  },
  "61401": {
    "country": "Niger",
    "network": "Sahelcom",
    "cc": "227",
    "cctld": "NE"
  },
  "61402": {
    "country": "Niger",
    "network": "CELTEL",
    "cc": "227",
    "cctld": "NE"
  },
  "61403": {
    "country": "Niger",
    "network": "TELECEL",
    "cc": "227",
    "cctld": "NE"
  },
  "61404": {
    "country": "Niger",
    "network": "Orange",
    "cc": "227",
    "cctld": "NE"
  },
  "61501": {
    "country": "Togo",
    "network": "TOGO TELECOM",
    "cc": "228",
    "cctld": "TG"
  },
  "61502": {
    "country": "Togo",
    "network": "TELECEL TOGO",
    "cc": "228",
    "cctld": "TG"
  },
  "61602": {
    "country": "Benin",
    "network": "TELECEL",
    "cc": "229",
    "cctld": "BJ"
  },
  "61603": {
    "country": "Benin",
    "network": "SPACETEL",
    "cc": "229",
    "cctld": "BJ"
  },
  "61701": {
    "country": "Mauritius",
    "network": "CELLPLUS",
    "cc": "230",
    "cctld": "MU"
  },
  "61710": {
    "country": "Mauritius",
    "network": "EMTEL",
    "cc": "230",
    "cctld": "MU"
  },
  "61801": {
    "country": "Liberia",
    "network": "MTN (Lonestar Cell)",
    "cc": "231",
    "cctld": "LR"
  },
  "61807": {
    "country": "Liberia",
    "network": "Cellcom Telecommunications - Orange",
    "cc": "231",
    "cctld": "LR"
  },
  "61901": {
    "country": "Sierra Leone",
    "network": "CELTEL",
    "cc": "232",
    "cctld": "SL"
  },
  "61902": {
    "country": "Sierra Leone",
    "network": "Millicom (Tigo)",
    "cc": "232",
    "cctld": "SL"
  },
  "61903": {
    "country": "Sierra Leone",
    "network": "Africell",
    "cc": "232",
    "cctld": "SL"
  },
  "61905": {
    "country": "Sierra Leone",
    "network": "Africell",
    "cc": "232",
    "cctld": "SL"
  },
  "61906": {
    "country": "Sierra Leone",
    "network": "Sierratel",
    "cc": "232",
    "cctld": "SL"
  },
  "61907": {
    "country": "Sierra Leone",
    "network": "QCell",
    "cc": "232",
    "cctld": "SL"
  },
  "62001": {
    "country": "Ghana",
    "network": "Scancom (MTN)",
    "cc": "233",
    "cctld": "GH"
  },
  "62002": {
    "country": "Ghana",
    "network": "Vodafone (Telecom Mobile GSM)",
    "cc": "233",
    "cctld": "GH"
  },
  "62003": {
    "country": "Ghana",
    "network": "Tigo Millicom",
    "cc": "233",
    "cctld": "GH"
  },
  "62004": {
    "country": "Ghana",
    "network": "Kasapa/Hutchison Telecom (Expresso)",
    "cc": "233",
    "cctld": "GH"
  },
  "62006": {
    "country": "Ghana",
    "network": "Zain-Airtel",
    "cc": "233",
    "cctld": "GH"
  },
  "62007": {
    "country": "Ghana",
    "network": "Glo Ghana",
    "cc": "233",
    "cctld": "GH"
  },
  "62120": {
    "country": "Nigeria",
    "network": "VMOBILE",
    "cc": "234",
    "cctld": "NG"
  },
  "62127": {
    "country": "Nigeria",
    "network": "Smile",
    "cc": "234",
    "cctld": "NG"
  },
  "62130": {
    "country": "Nigeria",
    "network": "NIGERIA MTN",
    "cc": "234",
    "cctld": "NG"
  },
  "62140": {
    "country": "Nigeria",
    "network": "M-TEL",
    "cc": "234",
    "cctld": "NG"
  },
  "62141": {
    "country": "Nigeria",
    "network": "Unknown-Nigeria-9028",
    "cc": "234",
    "cctld": "NG"
  },
  "62142": {
    "country": "Nigeria",
    "network": "Unknown-Nigeria-8076",
    "cc": "234",
    "cctld": "NG"
  },
  "62150": {
    "country": "Nigeria",
    "network": "GLO MOBILE",
    "cc": "234",
    "cctld": "NG"
  },
  "62160": {
    "country": "Nigeria",
    "network": "EMTS",
    "cc": "234",
    "cctld": "NG"
  },
  "62201": {
    "country": "Chad",
    "network": "CELLTEL",
    "cc": "235",
    "cctld": "TD"
  },
  "62202": {
    "country": "Chad",
    "network": "TCHAD MOBILE",
    "cc": "235",
    "cctld": "TD"
  },
  "62203": {
    "country": "Chad",
    "network": "Tchad Mobile - MILLICOM",
    "cc": "235",
    "cctld": "TD"
  },
  "62301": {
    "country": "Central African Republic",
    "network": "Moov - Atlantique Cellulaire RCA (A-Cell - Etisalat)",
    "cc": "236",
    "cctld": "CF"
  },
  "62302": {
    "country": "Central African Republic",
    "network": "Telecel",
    "cc": "236",
    "cctld": "CF"
  },
  "62303": {
    "country": "Central African Republic",
    "network": "Orange",
    "cc": "236",
    "cctld": "CF"
  },
  "62304": {
    "country": "Central African Republic",
    "network": "Nationlink Telecom RCA",
    "cc": "236",
    "cctld": "CF"
  },
  "62310": {
    "country": "Central Africa",
    "network": "TELECEL",
    "cc": "236",
    "cctld": "CF"
  },
  "62401": {
    "country": "Cameroon",
    "network": "MTN",
    "cc": "237",
    "cctld": "CM"
  },
  "62402": {
    "country": "Cameroon",
    "network": "ORANGE",
    "cc": "237",
    "cctld": "CM"
  },
  "62404": {
    "country": "Cameroon",
    "network": "Nexttel (Viettel)",
    "cc": "237",
    "cctld": "CM"
  },
  "62501": {
    "country": "Cape Verde Island",
    "network": "CV MOVEL",
    "cc": "238",
    "cctld": "CV"
  },
  "62701": {
    "country": "Equatorial Guinea",
    "network": "GETESA",
    "cc": "240",
    "cctld": "GQ"
  },
  "62703": {
    "country": "Equatorial Guinea",
    "network": "Green Com HiTs",
    "cc": "240",
    "cctld": "GQ"
  },
  "62801": {
    "country": "Gabon",
    "network": "LIBERTIS",
    "cc": "241",
    "cctld": "GA"
  },
  "62802": {
    "country": "Gabon",
    "network": "CELTEL GABON",
    "cc": "241",
    "cctld": "GA"
  },
  "62803": {
    "country": "Gabon",
    "network": "Zain-Airtel-Celtel",
    "cc": "2410",
    "cctld": "GA"
  },
  "62901": {
    "country": "Congo",
    "network": "CELTEL",
    "cc": "242",
    "cctld": "CG"
  },
  "62907": {
    "country": "Congo",
    "network": "WARID",
    "cc": "242",
    "cctld": "CG"
  },
  "62910": {
    "country": "Congo",
    "network": "LIBERTIS",
    "cc": "242",
    "cctld": "CG"
  },
  "62920": {
    "country": "Congo",
    "network": "CYRUS S.A.",
    "cc": "242",
    "cctld": "CG"
  },
  "63001": {
    "country": "Democratic Republic of the Congo",
    "network": "VODACOM CONGO",
    "cc": "243",
    "cctld": "CD"
  },
  "63002": {
    "country": "Democratic Republic of the Congo",
    "network": "Airtel DRC",
    "cc": "243",
    "cctld": "CD"
  },
  "63005": {
    "country": "Democratic Republic of the Congo",
    "network": "Congo Supercell",
    "cc": "243",
    "cctld": "CD"
  },
  "63086": {
    "country": "Democratic Republic of the Congo",
    "network": "Orange RDC",
    "cc": "243",
    "cctld": "CD"
  },
  "63089": {
    "country": "Democratic Republic of the Congo",
    "network": "Tigo DRC",
    "cc": "243",
    "cctld": "CD"
  },
  "63090": {
    "country": "Congo Democratic Republic",
    "network": "Africell",
    "cc": "243",
    "cctld": "CD"
  },
  "63102": {
    "country": "Angola",
    "network": "UNITEL",
    "cc": "244",
    "cctld": "AO"
  },
  "63103": {
    "country": "Angola",
    "network": "MoviCel",
    "cc": "244",
    "cctld": "AO"
  },
  "63301": {
    "country": "Seychelles",
    "network": "CABLE & WIRELESS",
    "cc": "248",
    "cctld": "SC"
  },
  "63310": {
    "country": "Seychelles",
    "network": "AIRTEL TELECOM",
    "cc": "248",
    "cctld": "SC"
  },
  "63401": {
    "country": "Sudan",
    "network": "MOBITEL",
    "cc": "249",
    "cctld": "SD"
  },
  "63402": {
    "country": "Sudan",
    "network": "AREEBA",
    "cc": "249",
    "cctld": "SD"
  },
  "63403": {
    "country": "Sudan",
    "network": "MTN",
    "cc": "249",
    "cctld": "SD"
  },
  "63407": {
    "country": "Sudan",
    "network": "Sudani One (Sudatel)",
    "cc": "249",
    "cctld": "SD"
  },
  "63510": {
    "country": "Rwanda",
    "network": "MTN RWANDA CELL",
    "cc": "250",
    "cctld": "RW"
  },
  "63601": {
    "country": "Ethiopia",
    "network": "ETHIO-TELECOM",
    "cc": "251",
    "cctld": "ET"
  },
  "63701": {
    "country": "Somalia",
    "network": "TELSOM",
    "cc": "252",
    "cctld": "SO"
  },
  "63704": {
    "country": "Somalia",
    "network": "Somafone",
    "cc": "252",
    "cctld": "SO"
  },
  "63710": {
    "country": "Somalia",
    "network": "NATIONLINK",
    "cc": "252",
    "cctld": "SO"
  },
  "63725": {
    "country": "Somalia",
    "network": "HORMUUD",
    "cc": "252",
    "cctld": "SO"
  },
  "63730": {
    "country": "Somalia",
    "network": "GOLIS",
    "cc": "252",
    "cctld": "SO"
  },
  "63760": {
    "country": "Somalia",
    "network": "Nationlink",
    "cc": "252",
    "cctld": "SO"
  },
  "63771": {
    "country": "Somalia",
    "network": "SOMTEL",
    "cc": "252",
    "cctld": "SO"
  },
  "63782": {
    "country": "Somalia",
    "network": "TELCOM",
    "cc": "252",
    "cctld": "SO"
  },
  "63801": {
    "country": "Djibouti",
    "network": "EVATIS",
    "cc": "253",
    "cctld": "DJ"
  },
  "63902": {
    "country": "Kenya",
    "network": "SAFARICOM",
    "cc": "254",
    "cctld": "KE"
  },
  "63903": {
    "country": "Kenya",
    "network": "AIRTEL",
    "cc": "254",
    "cctld": "KE"
  },
  "63905": {
    "country": "Kenya",
    "network": "ESSAR(YU)",
    "cc": "254",
    "cctld": "KE"
  },
  "63907": {
    "country": "Kenya",
    "network": "ORANGE",
    "cc": "254",
    "cctld": "KE"
  },
  "64002": {
    "country": "Tanzania",
    "network": "MOBITEL",
    "cc": "255",
    "cctld": "TZ"
  },
  "64003": {
    "country": "Tanzania",
    "network": "ZANTEL",
    "cc": "255",
    "cctld": "TZ"
  },
  "64004": {
    "country": "Tanzania",
    "network": "VODACOM",
    "cc": "255",
    "cctld": "TZ"
  },
  "64005": {
    "country": "Tanzania",
    "network": "CELTEL",
    "cc": "255",
    "cctld": "TZ"
  },
  "64007": {
    "country": "Tanzania",
    "network": "TTCL",
    "cc": "255",
    "cctld": "TZ"
  },
  "64008": {
    "country": "Tanzania",
    "network": "Benson Informatics Smart",
    "cc": "255",
    "cctld": "TZ"
  },
  "64009": {
    "country": "Tanzania",
    "network": "Halotel (Viettel)",
    "cc": "255",
    "cctld": "TZ"
  },
  "64101": {
    "country": "Uganda",
    "network": "CELTEL",
    "cc": "256",
    "cctld": "UG"
  },
  "64106": {
    "country": "Uganda",
    "network": "Afrimax Vodafone",
    "cc": "256",
    "cctld": "UG"
  },
  "64110": {
    "country": "Uganda",
    "network": "MTN UGANDA",
    "cc": "256",
    "cctld": "UG"
  },
  "64111": {
    "country": "Uganda",
    "network": "UGANDA TELECOM",
    "cc": "256",
    "cctld": "UG"
  },
  "64114": {
    "country": "Uganda",
    "network": "Africell (Orange)",
    "cc": "256",
    "cctld": "UG"
  },
  "64118": {
    "country": "Uganda",
    "network": "Smart Telecom (Sure)",
    "cc": "256",
    "cctld": "UG"
  },
  "64122": {
    "country": "Uganda",
    "network": "Warid Telecom",
    "cc": "256",
    "cctld": "UG"
  },
  "64133": {
    "country": "Uganda",
    "network": "Smile",
    "cc": "256",
    "cctld": "UG"
  },
  "64201": {
    "country": "Burundi",
    "network": "Leo - Econet UCOM (Telecel)",
    "cc": "257",
    "cctld": "BI"
  },
  "64203": {
    "country": "Burundi",
    "network": "TELECEL",
    "cc": "257",
    "cctld": "BI"
  },
  "64207": {
    "country": "Burundi",
    "network": "LACELL SU",
    "cc": "257",
    "cctld": "BI"
  },
  "64208": {
    "country": "Burundi",
    "network": "Lumitel (Viettel)",
    "cc": "257",
    "cctld": "BI"
  },
  "64282": {
    "country": "Burundi",
    "network": "Leo - Econet UCOM (Telecel)",
    "cc": "257",
    "cctld": "BI"
  },
  "64301": {
    "country": "Mozambique",
    "network": "T.D.M. MCELL",
    "cc": "258",
    "cctld": "MZ"
  },
  "64303": {
    "country": "Mozambique",
    "network": "Movitel (Viettel)",
    "cc": "258",
    "cctld": "MZ"
  },
  "64304": {
    "country": "Mozambique",
    "network": "VODACOM",
    "cc": "258",
    "cctld": "MZ"
  },
  "64501": {
    "country": "Zambia",
    "network": "CELTEL ZAMBIA",
    "cc": "260",
    "cctld": "ZM"
  },
  "64502": {
    "country": "Zambia",
    "network": "MTN Zambia",
    "cc": "260",
    "cctld": "ZM"
  },
  "64503": {
    "country": "Zambia",
    "network": "Zamtel",
    "cc": "260",
    "cctld": "ZM"
  },
  "64601": {
    "country": "Madagascar",
    "network": "MADACOM",
    "cc": "261",
    "cctld": "MG"
  },
  "64602": {
    "country": "Madagascar",
    "network": "SMM (ANTARIS)",
    "cc": "261",
    "cctld": "MG"
  },
  "64710": {
    "country": "Reunion Island",
    "network": "SFR",
    "cc": "262",
    "cctld": "RE"
  },
  "64801": {
    "country": "Zimbabwe",
    "network": "NET ONE",
    "cc": "263",
    "cctld": "ZW"
  },
  "64803": {
    "country": "Zimbabwe",
    "network": "TELECEL",
    "cc": "263",
    "cctld": "ZW"
  },
  "64804": {
    "country": "Zimbabwe",
    "network": "ECONET",
    "cc": "263",
    "cctld": "ZW"
  },
  "64901": {
    "country": "Namibia",
    "network": "MTC",
    "cc": "264",
    "cctld": "NA"
  },
  "64903": {
    "country": "Namibia",
    "network": "Cell One / Leo Orascom",
    "cc": "264",
    "cctld": "NA"
  },
  "65001": {
    "country": "Malawi",
    "network": "TELEKOM NET",
    "cc": "265",
    "cctld": "MW"
  },
  "65010": {
    "country": "Malawi",
    "network": "CELTEL",
    "cc": "265",
    "cctld": "MW"
  },
  "65101": {
    "country": "Lesotho",
    "network": "VODACOM",
    "cc": "266",
    "cctld": "LS"
  },
  "65102": {
    "country": "Lesotho",
    "network": "ECONET EZI CEL",
    "cc": "266",
    "cctld": "LS"
  },
  "65201": {
    "country": "Botswana",
    "network": "MASCOM",
    "cc": "267",
    "cctld": "BW"
  },
  "65202": {
    "country": "Botswana",
    "network": "ORANGE (VISTA CELLULAR)",
    "cc": "267",
    "cctld": "BW"
  },
  "65204": {
    "country": "Botswana",
    "network": "BTC",
    "cc": "267",
    "cctld": "BW"
  },
  "65310": {
    "country": "Swaziland",
    "network": "MTN",
    "cc": "268",
    "cctld": "SZ"
  },
  "65401": {
    "country": "Comoros Islands",
    "network": "SNPT",
    "cc": "269",
    "cctld": "KM"
  },
  "65501": {
    "country": "South Africa",
    "network": "VODACOM",
    "cc": "27",
    "cctld": "ZA"
  },
  "65502": {
    "country": "South Africa",
    "network": "Telkom",
    "cc": "27",
    "cctld": "ZA"
  },
  "65507": {
    "country": "South Africa",
    "network": "CELL C",
    "cc": "27",
    "cctld": "ZA"
  },
  "65510": {
    "country": "South Africa",
    "network": "MTN",
    "cc": "27",
    "cctld": "ZA"
  },
  "65701": {
    "country": "Eritrea",
    "network": "Eritel",
    "cc": "291",
    "cctld": "ER"
  },
  "65902": {
    "country": "South Sudan",
    "network": "MTN",
    "cc": "211",
    "cctld": "SS"
  },
  "65903": {
    "country": "South Sudan",
    "network": "Gemtel",
    "cc": "211",
    "cctld": "SS"
  },
  "65904": {
    "country": "South Sudan",
    "network": "Vivacell",
    "cc": "211",
    "cctld": "SS"
  },
  "65906": {
    "country": "South Sudan",
    "network": "Zain Co. Ltd",
    "cc": "211",
    "cctld": "SS"
  },
  "70267": {
    "country": "Belize",
    "network": "BELIZE TELECOM",
    "cc": "501",
    "cctld": "BZ"
  },
  "70401": {
    "country": "Guatemala",
    "network": "SERCOM",
    "cc": "502",
    "cctld": "GT"
  },
  "70402": {
    "country": "Guatemala",
    "network": "Tigo (COMCEL)",
    "cc": "502",
    "cctld": "GT"
  },
  "70403": {
    "country": "Guatemala",
    "network": "Movistar",
    "cc": "502",
    "cctld": "GT"
  },
  "70601": {
    "country": "El Salvador",
    "network": "CTE",
    "cc": "503",
    "cctld": "SV"
  },
  "70602": {
    "country": "El Salvador",
    "network": "DIGICEL",
    "cc": "503",
    "cctld": "SV"
  },
  "70603": {
    "country": "El Salvador",
    "network": "TELEMOVIL",
    "cc": "503",
    "cctld": "SV"
  },
  "70604": {
    "country": "El Salvador",
    "network": "Telefonica",
    "cc": "503",
    "cctld": "SV"
  },
  "70605": {
    "country": "El Salvador",
    "network": "RED (Intelfon)",
    "cc": "503",
    "cctld": "SV"
  },
  "70801": {
    "country": "Honduras",
    "network": "CLARO GSM",
    "cc": "504",
    "cctld": "HN"
  },
  "70802": {
    "country": "Honduras",
    "network": "CELTEL",
    "cc": "504",
    "cctld": "HN"
  },
  "70840": {
    "country": "Honduras",
    "network": "DIGITEL HONDURAS",
    "cc": "504",
    "cctld": "HN"
  },
  "71021": {
    "country": "Nicaragua",
    "network": "ENITEL",
    "cc": "505",
    "cctld": "NI"
  },
  "71030": {
    "country": "Nicaragua",
    "network": "Movistar",
    "cc": "505",
    "cctld": "NI"
  },
  "71073": {
    "country": "Nicaragua",
    "network": "Claro (Enitel)",
    "cc": "505",
    "cctld": "NI"
  },
  "71201": {
    "country": "Costa Rica",
    "network": "Instituto Costarricense de Electricidad (ICE)",
    "cc": "506",
    "cctld": "CR"
  },
  "71203": {
    "country": "Costa Rica",
    "network": "Claro CR Telecominicaciones",
    "cc": "506",
    "cctld": "CR"
  },
  "71204": {
    "country": "Costa Rica",
    "network": "Telefonica Moviles Costa Rica",
    "cc": "506",
    "cctld": "CR"
  },
  "71401": {
    "country": "Panama",
    "network": "CABLE & WIRELESS",
    "cc": "507",
    "cctld": "PA"
  },
  "71403": {
    "country": "Panama",
    "network": "Claro",
    "cc": "507",
    "cctld": "PA"
  },
  "71404": {
    "country": "Panama",
    "network": "Digicel",
    "cc": "507",
    "cctld": "PA"
  },
  "71606": {
    "country": "Peru",
    "network": "Unknown-PERU-9494",
    "cc": "51",
    "cctld": "PE"
  },
  "71607": {
    "country": "Peru",
    "network": "Entel",
    "cc": "51",
    "cctld": "PE"
  },
  "71610": {
    "country": "Peru",
    "network": "TIM PERU",
    "cc": "51",
    "cctld": "PE"
  },
  "71617": {
    "country": "Peru",
    "network": "Entel",
    "cc": "51",
    "cctld": "PE"
  },
  "72231": {
    "country": "Argentina",
    "network": "CTI",
    "cc": "54",
    "cctld": "AR"
  },
  "72234": {
    "country": "Argentina",
    "network": "TELECOM PERSONAL",
    "cc": "54",
    "cctld": "AR"
  },
  "72270": {
    "country": "Argentina",
    "network": "Unknown-ARGENTINA-1151",
    "cc": "54",
    "cctld": "AR"
  },
  "72400": {
    "country": "Brazil",
    "network": "Nextel Telecomunicacoes Ltda",
    "cc": "55",
    "cctld": "BR"
  },
  "72402": {
    "country": "Brazil",
    "network": "TIM (RIO NORTE)",
    "cc": "11155",
    "cctld": "BR"
  },
  "72403": {
    "country": "Brazil",
    "network": "Tim",
    "cc": "55",
    "cctld": "BR"
  },
  "72404": {
    "country": "Brazil",
    "network": "Tim",
    "cc": "55",
    "cctld": "BR"
  },
  "72405": {
    "country": "Brazil",
    "network": "CLARO SA",
    "cc": "55",
    "cctld": "BR"
  },
  "72406": {
    "country": "Brazil",
    "network": "VIVO Participacoes SA (VIVO)",
    "cc": "55",
    "cctld": "BR"
  },
  "72407": {
    "country": "Brazil",
    "network": "Sercomtel Celular SA",
    "cc": "55",
    "cctld": "BR"
  },
  "72410": {
    "country": "Brazil",
    "network": "Vivo",
    "cc": "55",
    "cctld": "BR"
  },
  "72411": {
    "country": "Brazil",
    "network": "Vivo",
    "cc": "55",
    "cctld": "BR"
  },
  "72416": {
    "country": "Brazil",
    "network": "Unknown-BRAZIL-2184",
    "cc": "11155",
    "cctld": "BR"
  },
  "72423": {
    "country": "Brazil",
    "network": "Vivo",
    "cc": "55",
    "cctld": "BR"
  },
  "72424": {
    "country": "Brazil",
    "network": "Oi (Brasil Telecom)",
    "cc": "55",
    "cctld": "BR"
  },
  "72430": {
    "country": "Brazil",
    "network": "Oi (Brasil Telecom)",
    "cc": "55",
    "cctld": "BR"
  },
  "72431": {
    "country": "Brazil",
    "network": "OI",
    "cc": "11155",
    "cctld": "BR"
  },
  "72432": {
    "country": "Brazil",
    "network": "CTBC Celular SA (CTBC)",
    "cc": "55",
    "cctld": "BR"
  },
  "72437": {
    "country": "Brazil",
    "network": "Unicel do Brasil Telecomunicacoes Ltda",
    "cc": "55",
    "cctld": "BR"
  },
  "73010": {
    "country": "Chile",
    "network": "ENTEL",
    "cc": "56",
    "cctld": "CL"
  },
  "73401": {
    "country": "Venezuela",
    "network": "INFONET",
    "cc": "58",
    "cctld": "VE"
  },
  "73402": {
    "country": "Venezuela",
    "network": "DIGITEL",
    "cc": "58",
    "cctld": "VE"
  },
  "73403": {
    "country": "Venezuela",
    "network": "DIGICEL",
    "cc": "58",
    "cctld": "VE"
  },
  "73404": {
    "country": "Venezuela",
    "network": "Movistar - Telcel",
    "cc": "58",
    "cctld": "VE"
  },
  "73406": {
    "country": "Venezuela",
    "network": "Movilnet",
    "cc": "58",
    "cctld": "VE"
  },
  "73601": {
    "country": "Bolivia",
    "network": "NUEVATEL BOLIVIA",
    "cc": "591",
    "cctld": "BO"
  },
  "73602": {
    "country": "Bolivia",
    "network": "ENTEL",
    "cc": "591",
    "cctld": "BO"
  },
  "73603": {
    "country": "Bolivia",
    "network": "Telcel (Tigo)",
    "cc": "591",
    "cctld": "BO"
  },
  "73801": {
    "country": "Tanzania",
    "network": "ORANGE",
    "cc": "594",
    "cctld": "TZ"
  },
  "74000": {
    "country": "Ecuador",
    "network": "OTECEL",
    "cc": "593",
    "cctld": "EC"
  },
  "74001": {
    "country": "Ecuador",
    "network": "CONECEL S.A.",
    "cc": "593",
    "cctld": "EC"
  },
  "74002": {
    "country": "Ecuador",
    "network": "TELECSA",
    "cc": "593",
    "cctld": "EC"
  },
  "74401": {
    "country": "Paraguay",
    "network": "HOLA PARAGUAY",
    "cc": "595",
    "cctld": "PY"
  },
  "74402": {
    "country": "Paraguay",
    "network": "HUTCHINSON TEL",
    "cc": "595",
    "cctld": "PY"
  },
  "74404": {
    "country": "Paraguay",
    "network": "Tigo",
    "cc": "595",
    "cctld": "PY"
  },
  "74405": {
    "country": "Paraguay",
    "network": "PERSONAL",
    "cc": "595",
    "cctld": "PY"
  },
  "74602": {
    "country": "Suriname",
    "network": "TELESUR",
    "cc": "597",
    "cctld": "SR"
  },
  "74801": {
    "country": "Uruguay",
    "network": "ANCEL GSM",
    "cc": "598",
    "cctld": "UY"
  },
  "74802": {
    "country": "Uruguay",
    "network": "ABIATAR S.A",
    "cc": "598",
    "cctld": "UY"
  },
  "74807": {
    "country": "Uruguay",
    "network": "Movistar",
    "cc": "598",
    "cctld": "UY"
  },
  "74810": {
    "country": "Uruguay",
    "network": "AM WIRELESS",
    "cc": "598",
    "cctld": "UY"
  },
  "90105": {
    "country": "Taiwan",
    "network": "SAT. SYSTEM THURAYA",
    "cc": "882",
    "cctld": "TW"
  },
  "234000": {
    "country": "England",
    "network": "BT Group plc",
    "cc": "44",
    "cctld": "UK"
  },
  "234001": {
    "country": "England",
    "network": "Mundio Mobile Ltd (Mundio)",
    "cc": "44",
    "cctld": "UK"
  },
  "234002": {
    "country": "England",
    "network": "Telefonica UK Ltd",
    "cc": "44",
    "cctld": "UK"
  },
  "234003": {
    "country": "England",
    "network": "Jersey Airtel  Ltd",
    "cc": "44",
    "cctld": "UK"
  },
  "234007": {
    "country": "England",
    "network": "Cable & Wireless Worldwide plc",
    "cc": "44",
    "cctld": "UK"
  },
  "234009": {
    "country": "England",
    "network": "Tismi BV",
    "cc": "44",
    "cctld": "UK"
  },
  "234010": {
    "country": "England",
    "network": "O2 BT CELLNET",
    "cc": "44",
    "cctld": "UK"
  },
  "234014": {
    "country": "England",
    "network": "Hay Systems Ltd (HSL)",
    "cc": "44",
    "cctld": "UK"
  },
  "234015": {
    "country": "England",
    "network": "Vodafone UK Ltd",
    "cc": "44",
    "cctld": "UK"
  },
  "234016": {
    "country": "England",
    "network": "TalkTalk Communications Ltd (TalkTalk Business)",
    "cc": "44",
    "cctld": "UK"
  },
  "234017": {
    "country": "England",
    "network": "FleXtel Ltd",
    "cc": "44",
    "cctld": "UK"
  },
  "234018": {
    "country": "England",
    "network": "Cloud9 Mobile Communications Plc",
    "cc": "44",
    "cctld": "UK"
  },
  "234019": {
    "country": "England",
    "network": "Teleware plc",
    "cc": "44",
    "cctld": "UK"
  },
  "234020": {
    "country": "England",
    "network": "Hutchison 3G UK Ltd",
    "cc": "44",
    "cctld": "UK"
  },
  "234022": {
    "country": "England",
    "network": "Routo Telecommunications Ltd",
    "cc": "44",
    "cctld": "UK"
  },
  "234023": {
    "country": "England",
    "network": "Vectone Network Ltd",
    "cc": "44",
    "cctld": "UK"
  },
  "234024": {
    "country": "England",
    "network": "Stour Marine Ltd",
    "cc": "44",
    "cctld": "UK"
  },
  "234025": {
    "country": "England",
    "network": "Truphone UK",
    "cc": "44",
    "cctld": "UK"
  },
  "234027": {
    "country": "England",
    "network": "Unknown-UK-7748",
    "cc": "11144",
    "cctld": "UK"
  },
  "234028": {
    "country": "England",
    "network": "Marathon Telecom Ltd",
    "cc": "44",
    "cctld": "UK"
  },
  "234030": {
    "country": "England",
    "network": "Everything Everywhere Ltd",
    "cc": "44",
    "cctld": "UK"
  },
  "234033": {
    "country": "England",
    "network": "ORANGE",
    "cc": "44",
    "cctld": "UK"
  },
  "234050": {
    "country": "England",
    "network": "Jersey Telecom Ltd",
    "cc": "44",
    "cctld": "UK"
  },
  "234055": {
    "country": "England",
    "network": "Cable & Wireless Guernsey Ltd",
    "cc": "44",
    "cctld": "UK"
  },
  "234058": {
    "country": "England",
    "network": "MANX TELECOM",
    "cc": "44",
    "cctld": "UK"
  },
  "234099": {
    "country": "England",
    "network": "08Direct Ltd",
    "cc": "44",
    "cctld": "UK"
  },
  "302032": {
    "country": "Canada",
    "network": "Unknown-CANADA-6478",
    "cc": "1",
    "cctld": "CA"
  },
  "302037": {
    "country": "Canada",
    "network": "MICROCELL",
    "cc": "1",
    "cctld": "CA"
  },
  "302065": {
    "country": "Canada",
    "network": "Unknown-CANADA-6472",
    "cc": "1",
    "cctld": "CA"
  },
  "302072": {
    "country": "Canada",
    "network": "ROGERS WIRELESS",
    "cc": "1",
    "cctld": "CA"
  },
  "302370": {
    "country": "Canada",
    "network": "MICROCELL",
    "cc": "1",
    "cctld": "CA"
  },
  "302490": {
    "country": "Canada",
    "network": "Unknown-CANADA-6477",
    "cc": "1",
    "cctld": "CA"
  },
  "310002": {
    "country": "United States",
    "network": "T-MOBILE",
    "cc": "1",
    "cctld": "US"
  },
  "310012": {
    "country": "United States",
    "network": "Verizon Wireless",
    "cc": "1",
    "cctld": "US"
  },
  "310013": {
    "country": "Solomon Islands",
    "network": "MobileTel",
    "cc": "1",
    "cctld": "SB"
  },
  "310015": {
    "country": "United States",
    "network": "CINGULAR WIRELESS",
    "cc": "1",
    "cctld": "US"
  },
  "310016": {
    "country": "United States",
    "network": "Cricket Communications",
    "cc": "1",
    "cctld": "US"
  },
  "310032": {
    "country": "Guam",
    "network": "GUAM WIRELESS",
    "cc": "1",
    "cctld": "GU"
  },
  "310038": {
    "country": "United States",
    "network": "Cable & Wireless",
    "cc": "1",
    "cctld": "US"
  },
  "310140": {
    "country": "Guam",
    "network": "GTA Wireless",
    "cc": "1",
    "cctld": "GU"
  },
  "310150": {
    "country": "Solomon Islands",
    "network": "Cingular Wireless",
    "cc": "1",
    "cctld": "SB"
  },
  "310180": {
    "country": "United States",
    "network": "West Central Wireless",
    "cc": "1",
    "cctld": "US"
  },
  "310470": {
    "country": "Guam",
    "network": "Docomo Pacific",
    "cc": "1",
    "cctld": "GU"
  },
  "310480": {
    "country": "Guam",
    "network": "iConnect",
    "cc": "1",
    "cctld": "GU"
  },
  "310770": {
    "country": "United States",
    "network": "Iowa Wireless",
    "cc": "1",
    "cctld": "US"
  },
  "311440": {
    "country": "International",
    "network": "Unknown-Unknown-0772",
    "cc": "191",
    "cctld": ""
  },
  "311580": {
    "country": "International",
    "network": "Unknown-Unknown-4577",
    "cc": "191",
    "cctld": ""
  },
  "311660": {
    "country": "International",
    "network": "Unknown-Unknown-7293",
    "cc": "191",
    "cctld": ""
  },
  "311870": {
    "country": "International",
    "network": "Unknown-Unknown-5120",
    "cc": "191",
    "cctld": ""
  },
  "316010": {
    "country": "International",
    "network": "Unknown-Unknown-2503",
    "cc": "180",
    "cctld": ""
  },
  "405001": {
    "country": "International",
    "network": "Unknown-Unknown-5337",
    "cc": "919",
    "cctld": ""
  },
  "405002": {
    "country": "International",
    "network": "TATA (DOCOMO)",
    "cc": "91",
    "cctld": ""
  },
  "405007": {
    "country": "India",
    "network": "RELIANCE (HARYANA)",
    "cc": "91",
    "cctld": "IN"
  },
  "405013": {
    "country": "India",
    "network": "RELIANCE (MAHARASHTRA)",
    "cc": "91",
    "cctld": "IN"
  },
  "405015": {
    "country": "India",
    "network": "RELIANCE (MUMBAI)",
    "cc": "91",
    "cctld": "IN"
  },
  "405025": {
    "country": "India",
    "network": "TATA TELESERVICES (Andhra Pradesh)",
    "cc": "91",
    "cctld": "IN"
  },
  "405030": {
    "country": "India",
    "network": "TEL (ORISSA)",
    "cc": "91",
    "cctld": "IN"
  },
  "405031": {
    "country": "India",
    "network": "TATA TELESERVICES (HARYANA)",
    "cc": "91",
    "cctld": "IN"
  },
  "405079": {
    "country": "International",
    "network": "IDEA (MUMBAI)",
    "cc": "91",
    "cctld": ""
  },
  "405800": {
    "country": "India",
    "network": "AIRCEL (DELHI)",
    "cc": "91",
    "cctld": "IN"
  },
  "405807": {
    "country": "India",
    "network": "AIRCEL",
    "cc": "91",
    "cctld": "IN"
  },
  "405846": {
    "country": "India",
    "network": "Unknown-INDIA-9884",
    "cc": "91",
    "cctld": "IN"
  },
  "405862": {
    "country": "India",
    "network": "LOOP (KARNATAKA)",
    "cc": "91",
    "cctld": "IN"
  },
  "405886": {
    "country": "India",
    "network": "TEL (ORISSA)",
    "cc": "91",
    "cctld": "IN"
  },
  "452001": {
    "country": "Vietnam",
    "network": "VMS MOBIFONE",
    "cc": "84",
    "cctld": "VN"
  },
  "452002": {
    "country": "Vietnam",
    "network": "VINA",
    "cc": "84",
    "cctld": "VN"
  },
  "452004": {
    "country": "Vietnam",
    "network": "VIETTEL",
    "cc": "84",
    "cctld": "VN"
  },
  "452007": {
    "country": "Vietnam",
    "network": "Beeline",
    "cc": "84",
    "cctld": "VN"
  },
  "452501": {
    "country": "Vietnam",
    "network": "HTC",
    "cc": "84",
    "cctld": "VN"
  },
  "452502": {
    "country": "Vietnam",
    "network": "SPT",
    "cc": "84",
    "cctld": "VN"
  },
  "452507": {
    "country": "Vietnam",
    "network": "VSAT",
    "cc": "84",
    "cctld": "VN"
  },
  "502152": {
    "country": "Malaysia",
    "network": "YTL Communications",
    "cc": "60",
    "cctld": "MY"
  },
  "502153": {
    "country": "Malaysia",
    "network": "UNIFI - Webe Digital (Packet One)",
    "cc": "60",
    "cctld": "MY"
  },
  "620001": {
    "country": "Ghana",
    "network": "SCANCOM",
    "cc": "233",
    "cctld": "GH"
  },
  "620002": {
    "country": "Ghana",
    "network": "VODAFONE",
    "cc": "233",
    "cctld": "GH"
  },
  "620003": {
    "country": "Ghana",
    "network": "MOBITEL",
    "cc": "233",
    "cctld": "GH"
  },
  "620004": {
    "country": "Ghana",
    "network": "GLO",
    "cc": "233",
    "cctld": "GH"
  },
  "620006": {
    "country": "Ghana",
    "network": "AIRTEL",
    "cc": "233",
    "cctld": "GH"
  },
  "620501": {
    "country": "Ghana",
    "network": "KASAPA",
    "cc": "233",
    "cctld": "GH"
  },
  "710300": {
    "country": "Nicaragua",
    "network": "Movistar",
    "cc": "505",
    "cctld": "NI"
  },
  "732101": {
    "country": "Colombia",
    "network": "CLARO",
    "cc": "57",
    "cctld": "CO"
  },
  "732102": {
    "country": "Colombia",
    "network": "MOVISTAR",
    "cc": "57",
    "cctld": "CO"
  },
  "732103": {
    "country": "Colombia",
    "network": "TIGO",
    "cc": "57",
    "cctld": "CO"
  },
  "732111": {
    "country": "Colombia",
    "network": "Tigo",
    "cc": "57",
    "cctld": "CO"
  },
  "732123": {
    "country": "Colombia",
    "network": "MOVISTAR",
    "cc": "57",
    "cctld": "CO"
  },
  "732130": {
    "country": "Colombia",
    "network": "Avantel",
    "cc": "57",
    "cctld": "CO"
  },
  "732154": {
    "country": "Colombia",
    "network": "Movistar",
    "cc": "57",
    "cctld": "CO"
  },
  "732165": {
    "country": "Colombia",
    "network": "Tigo",
    "cc": "57",
    "cctld": "CO"
  }
}