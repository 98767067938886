import React, { useState, useEffect } from 'react';
import { Table, Tag, Button, Typography, Switch, Radio, Input } from 'antd';
import { ReloadOutlined, ApiOutlined } from '@ant-design/icons';
import { getBinds } from './services';
import { capitalize } from '../../libs/helpers';

const Binds = () => {
  const { Text } = Typography;
  const { Search } = Input;
  const [loading, setLoading] = useState(false);
  const [bindsVendors, setBindsVendors] = useState([]);
  const [bindsUsers, setBindsUsers] = useState([]);
  const [bindsFilter, setBindsFilter] = useState([]);
  const [showInactive, setShowInactive] = useState(false);
  const [radioValue, setRadioValue] = useState('both');
  const [searchThis, setSearchThis] = useState('');
  const optionsRadio = [
    { label: 'Both', value: 'both' },
    { label: 'Customers', value: 'customers' },
    { label: 'Vendors', value: 'vendors' }
  ];

  const getData = async () => {
    setLoading(true);
    try {
      const bindsUsersReq = await getBinds('customers');
      setBindsUsers(bindsUsersReq);
      const bindsVendorsReq = await getBinds('vendors');
      setBindsVendors(bindsVendorsReq);
      setBindsFilter([...bindsUsersReq, ...bindsVendorsReq]);
      setRadioValue('both');
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const onSearch = ({ target: { value } }) => setSearchThis(value);

  const onChangeRadio = ({ target: { value } }) => {
    if (value === 'customers') {
      setBindsFilter(bindsUsers);
    } else if (value === 'vendors') {
      setBindsFilter(bindsVendors);
    } else {
      setBindsFilter([...bindsUsers, ...bindsVendors]);
    }
    setRadioValue(value);
  };

  const columns = [
    {
      title: 'Status',
      dataIndex: 'status',
      render: status => (
        <a>
          <Tag color={status === 'ACTIVE' ? 'green' : 'red'}>
            <ApiOutlined />
          </Tag>
        </a>
      )
    },
    {
      title: 'Type',
      dataIndex: 'type',
      render: r => capitalize(r)
    },
    {
      title: 'Name',
      dataIndex: 'name'
    },
    {
      title: 'User',
      dataIndex: 'systemId'
    },
    {
      title: 'Host',
      dataIndex: 'host'
    },
    {
      title: 'Port',
      dataIndex: 'port'
    }
  ];

  return (
    <div
      style={{
        padding: '0 25px 25px',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <div
        style={{
          display: 'flex',
          marginBottom: '10px'
        }}
      >
        <Radio.Group
          options={optionsRadio}
          onChange={onChangeRadio}
          value={radioValue}
          optionType="button"
          buttonStyle="solid"
        />
        <Search
          placeholder="Search by name"
          onChange={onSearch}
          style={{ marginLeft: '15px', width: 200 }}
        />
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Text style={{ marginLeft: '15px' }}>Inactive binds</Text>
          <Switch
            onChange={checked => {
              setShowInactive(checked);
            }}
            style={{ marginLeft: '15px' }}
          />
        </div>
        <Button
          onClick={() => getData()}
          type="primary"
          shape="round"
          icon={<ReloadOutlined />}
          style={{
            marginLeft: 'auto'
          }}
        >
          Sync
        </Button>
      </div>
      <Table
        loading={loading}
        dataSource={bindsFilter.filter(b => {
          let show = true;
          if (showInactive) {
            show = b.status !== 'ACTIVE';
          }
          if (show && searchThis) {
            show = b.name.toLowerCase().includes(searchThis.toLowerCase());
          }
          return show;
        })}
        columns={columns}
        size="small"
        pagination={{
          defaultPageSize: 15
        }}
      />
    </div>
  );
};

export default Binds;
