import React from 'react';
import { Tabs, Row, Col } from 'antd';
import { useHistory } from 'react-router';
import {
  WarningOutlined,
  ApiOutlined,
  EnvironmentOutlined
} from '@ant-design/icons';
import Errors from '../Errors';
import Binds from '../Binds';
import Hlr from '../Hlr';

const Tools = () => {
  const { TabPane } = Tabs;
  const search = window.location.search;
  const qParams = new URLSearchParams(search);
  const currentTab = qParams.get('tab') || 'hlr';
  const history = useHistory();
  const slugsAndKeys = [
    { slug: 'hlr', key: '1' },
    { slug: 'binds', key: '2' },
    {
      slug: 'errors',
      key: '3'
    }
  ];

  return (
    <Row justify="center">
      <Col span={16}>
        <Tabs
          type="card"
          style={{
            backgroundColor: 'white',
            margin: '15px 0'
          }}
          defaultActiveKey={
            slugsAndKeys.find(({ slug }) => slug === currentTab).key
          }
          onTabClick={keyClicked => {
            history.push({
              search: `?tab=${
                slugsAndKeys.find(({ key }) => key === keyClicked).slug
              }`
            });
          }}
        >
          <TabPane
            style={{ minHeight: '300px' }}
            tab={
              <>
                <EnvironmentOutlined />
                Number Lookup
              </>
            }
            key="1"
          >
            <Hlr />
          </TabPane>
          <TabPane
            tab={
              <>
                <ApiOutlined />
                Binds
              </>
            }
            key="2"
          >
            <Binds />
          </TabPane>
          <TabPane
            tab={
              <>
                <WarningOutlined />
                Errors
              </>
            }
            key="3"
          >
            <Errors />
          </TabPane>
        </Tabs>
      </Col>
    </Row>
  );
};

export default Tools;
