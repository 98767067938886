import { API, Auth } from 'aws-amplify';

async function getBinds(type) {
  const currentAuth = await Auth.currentAuthenticatedUser();
  try {
    const res = await API.get('sms-api', `/get-binds/${type}`, {
      headers: {
        Authorization: currentAuth.signInUserSession.idToken.jwtToken
      }
    });
    return res;
  } catch (err) {
    console.log(err.response);
  }
}

export { getBinds };
