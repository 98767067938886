import { API, Auth } from 'aws-amplify';

const saveToMcore = async ({ name, data, auth }) => {
  const currentAuth = await Auth.currentAuthenticatedUser();
  try {
    const res = await API.post('sms-api', `/save-vendor-pricelist/${name}`, {
      headers: {
        Authorization: currentAuth.signInUserSession.idToken.jwtToken
      },
      body: JSON.stringify(data)
    });
    return res;
  } catch (err) {
    console.log(err);
    console.log(err.response);
    return err.response;
  }
};

const vendorPriceCleaner = async params => {
  const currentAuth = await Auth.currentAuthenticatedUser();
  try {
    const req = await API.post('sms-api', '/vendor-price-cleaner', {
      headers: {
        Authorization: currentAuth.signInUserSession.idToken.jwtToken
      },
      body: params
    });
    return req;
  } catch (err) {
    console.log(err);
    console.log(err.response);
    return err.response;
  }
};

export { saveToMcore, vendorPriceCleaner };
