import React from 'react';
import 'antd/dist/antd.less';
import { Upload } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import Papa from 'papaparse';
import { MCCMNC } from '../Vendor/mccmnc.js';
import { MNOS } from '../Vendor/mnos.js';
import { swapCountries, bannedCountries } from '../Vendor/countriesMutations';
import doubles from '../Vendor/doubles';

const DraggerComp = params => {
  const { userPricelist, setPricelistUploaded, setErrorMessage, disabled } =
    params;
  const routesAdded = [];
  const defaults = {};
  const errors = [];
  let prevRow = null;
  let key = 1;

  const routes = [];

  const { Dragger } = Upload;

  const props = {
    name: 'file',
    accept: '.csv',
    customRequest: () => true,
    data: file => {
      Papa.parse(file, {
        header: true,
        transformHeader: h =>
          h
            .split(' ')[0]
            .split('(')[0]
            .toLocaleLowerCase()
            .replace('countryname', 'country')
            .replace('operatorname', 'network')
            .replace('operator', 'network')
            .replace('rate', 'price'),
        step: function (row) {
          const route = row.data;
          if (
            !bannedCountries.find(
              r =>
                route.country &&
                route.country.toLowerCase().includes(r.toLowerCase())
            )
          ) {
            if (route.country in swapCountries) {
              route.country = swapCountries[route.country];
            }
            const parsedPrice = parseFloat(route.price);
            // Creates an mccmnc if not any
            if (!route.mccmnc) {
              if (route.mcc && route.mnc) {
                route.mccmnc = `${route.mcc}${
                  route.mnc.length === 1 ? '0' : ''
                }${route.mnc}`;
              }
            }
            // ----- this is because issue with hicell ----- //
            if (route.mccmnc && !route.mcc) {
              route.mcc = route.mccmnc.slice(0, 3);
            }
            if (route.mccmnc && !route.mnc) {
              route.mnc = route.mccmnc.replace(route.mcc, '');
            }
            // ----- this is because issue with hicell ----- //
            // Adds price for default
            if (!defaults[route.country]) {
              defaults[route.country] = [];
            }
            defaults[route.country].price =
              !defaults[route.country].price ||
              parsedPrice > defaults[route.country].price
                ? parsedPrice
                : defaults[route.country].price;
            // Find if we should save the default
            let prevMcc =
              prevRow !== null &&
              prevRow.mccmnc !== null &&
              prevRow.mccmnc !== undefined
                ? prevRow.mccmnc.slice(0, 3)
                : null;
            // Because Identity
            prevMcc =
              prevMcc === null && prevRow !== null && prevRow.mcc
                ? prevRow.mcc
                : prevMcc;
            // Because lleida
            // Find if route has been added using the country, then find the full
            // details of the route matching the mcc with the MNOs list
            if (
              (prevMcc &&
                route.country !== prevRow.country &&
                routesAdded.find(row => row.country === prevRow.country) &&
                MNOS.find(row => row[1].slice(0, 3) === prevMcc) &&
                prevRow.price !== '-') ||
              (prevRow !== null &&
                route.country !== prevRow.country &&
                defaults[prevRow.country].found &&
                prevRow.price !== '-')
            ) {
              prevMcc = `${prevMcc}0`;
              const route = {
                country: prevRow.country,
                network: 'Default',
                mccmnc: prevMcc,
                cost: defaults[prevRow.country].price,
                key: key
              };
              routes.push(route);
              const duplicate = routesAdded.find(row => row.mccmnc === prevMcc);
              if (duplicate) {
                errors.push(`${duplicate.country} & ${route.country}`);
              } else {
                routesAdded.push(route);
              }
              key++;
            }

            // Find if mccmnc exists in cytech mno & scheme
            // FIXME route.mnc.slice(-2) !== '00' can remove good routes
            // Special cases:
            // Lleida: Default MCCMNC & MNC '-'
            if (
              MCCMNC.includes(route.mccmnc) &&
              !routesAdded.find(row => row.mccmnc === route.mccmnc)
            ) {
              if (
                route.mnc !== '-' &&
                route.mnc.slice(-2) !== '00' &&
                route.mnc !== '0' &&
                row.network !== 'Rest' &&
                row.network !== 'All networks' &&
                route.mnc !== -1 &&
                route.cost !== '-'
              ) {
                routes.push({
                  country: route.country,
                  network: route.network,
                  mccmnc: route.mccmnc,
                  cost: parsedPrice,
                  key: key
                });
                routesAdded.push(route);
                key++;
              } else {
                // Routes with only 1 case in the MNOs will fail because the country
                // has not been added to the variable
                defaults[route.country].found = true;
              }
            } else if (
              (route.mcc && route.mcc !== '-' && route.mnc === '-') ||
              route.mnc !== -1
            ) {
              // Because lleida
              defaults[route.country].found = true;
            }
            prevRow = route;
          }
        },
        complete: function () {
          if (
            routes.find(({ mccmnc }) => mccmnc.slice(0, 3) === prevRow.mcc) &&
            !routes.find(({ mccmnc }) => mccmnc === `${prevRow.mcc}0`)
          ) {
            routes.push({
              country: prevRow.country,
              network: 'Default',
              mccmnc: `${prevRow.mcc}0`,
              cost: defaults[prevRow.country].price,
              key: key
            });
          }
          // Add double default for some Vendor
          const doubleRoute = routes
            .filter(r => doubles.find(c => c.find(m => m === r.mccmnc)))
            .sort((x, y) => x.mccmnc - y.mccmnc);
          if (doubleRoute.length) {
            doubleRoute.forEach((c, idx) => {
              const row = doubles.find(row => row.includes(c.mccmnc));
              if (row.length) {
                const byCountry = row.filter(r => r !== c.mccmnc);
                byCountry.forEach(mccmnc => {
                  routes.splice(c.key, 0, {
                    country: c.country,
                    mccmnc: mccmnc,
                    network: 'Default',
                    cost: c.price
                  });
                });
              }
            });
          }
          const uniqs = routes.filter(
            ({ mccmnc }) => !userPricelist.find(r => r.mccmnc === mccmnc)
          );
          setPricelistUploaded(uniqs);
          if (errors.length > 0) {
            setErrorMessage(
              `The Vendor "${errors.join(
                ', '
              )}" have the same Default's MCCMNC, you must leave it just one`
            );
          } else {
            setErrorMessage('');
          }
        }
      });
    }
  };

  return (
    <Dragger
      {...props}
      showUploadList={false}
      style={{ height: '200px' }}
      disabled={disabled}
    >
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">
        Click or drag file to this area to upload
      </p>
      <p className="ant-upload-hint">The file must be a CSV</p>
    </Dragger>
  );
};

export default DraggerComp;
