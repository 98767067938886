import React, { useState, useEffect } from 'react';
import 'antd/dist/antd.less';
import { Table, Tag, Popover, Button } from 'antd';
import { ReloadOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { getLogsErros } from './services';

const dateToString = date => {
  console.log(date);
  const mom = { base: new Date(date) };
  mom.day = mom.base.toISOString().split('T')[0].split('-').reverse().join('/');
  mom.time = mom.base.toLocaleTimeString('es-ES', {
    timeZone: 'Europe/Madrid',
    timeStyle: 'short'
  });
  if (mom.time.length === 4) {
    mom.time = `0${mom.time}`;
  }
  mom.full = `${mom.day} ${mom.time}`;
  return mom;
};

const Errors = () => {
  const [errorsUpdates, setErrorsUpdates] = useState();
  const [loading, setLoading] = useState(false);

  const onLoad = async () => {
    setLoading(true);
    const req = await getLogsErros();
    const errorsOrdered = req
      .sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp))
      .reverse();
    setErrorsUpdates(errorsOrdered);
    setLoading(false);
  };

  useEffect(() => {
    onLoad();
  }, []);

  const columns = [
    {
      title: 'Log',
      dataIndex: 'log',
      render: log => <Tag color={'blue'}>{log.split('smsapi-dev-').pop()}</Tag>,
      width: 160
    },
    {
      title: 'Moment',
      dataIndex: 'timestamp',
      render: d => dateToString(d).full.replace(' ', ' @ '),
      width: 160
    },
    {
      title: 'Message',
      dataIndex: 'error',
      render: m => {
        if (m?.length > 137) {
          return (
            <>
              {m.substr(0, 137)}...
              <Popover
                title="Full message"
                content={m}
                overlayStyle={{ maxWidth: '600px' }}
              >
                <a style={{ marginLeft: '10px' }}>
                  <InfoCircleOutlined />
                </a>
              </Popover>
            </>
          );
        } else {
          return m;
        }
      }
    }
  ];

  return (
    <div
      style={{
        padding: '0 25px 25px',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <div
        style={{
          display: 'flex',
          marginBottom: '10px'
        }}
      >
        <Button
          onClick={() => onLoad()}
          type="primary"
          shape="round"
          icon={<ReloadOutlined />}
          style={{
            marginLeft: 'auto'
          }}
        >
          Sync
        </Button>
      </div>
      <Table
        loading={loading}
        dataSource={errorsUpdates}
        columns={columns}
        size="small"
        pagination={{
          defaultPageSize: 15
        }}
      />
    </div>
  );
};

export default Errors;
