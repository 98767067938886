import React from 'react';
import { Tabs, Row, Col } from 'antd';
import { useHistory } from 'react-router';
import { FlagOutlined, UploadOutlined, RobotOutlined } from '@ant-design/icons';
import Vendor from '../Vendor';
import Countries from '../Countries';
import Automatic from '../Automatic';

const Vendors = () => {
  const { TabPane } = Tabs;
  const search = window.location.search;
  const qParams = new URLSearchParams(search);
  const currentTab = qParams.get('tab') || 'automatic';
  const history = useHistory();
  const slugsAndKeys = [
    {
      slug: 'automatic',
      key: '1'
    },
    { slug: 'countries', key: '2' },
    { slug: 'upload', key: '3' }
  ];

  return (
    <Row justify="center">
      <Col span={16}>
        <Tabs
          type="card"
          style={{
            backgroundColor: 'white',
            margin: '15px 0'
          }}
          defaultActiveKey={
            slugsAndKeys.find(({ slug }) => slug === currentTab).key
          }
          onTabClick={keyClicked => {
            history.push({
              search: `?tab=${
                slugsAndKeys.find(({ key }) => key === keyClicked).slug
              }`
            });
          }}
        >
          <TabPane
            tab={
              <>
                <RobotOutlined />
                Automatic
              </>
            }
            key="1"
          >
            <Automatic />
          </TabPane>
          <TabPane
            tab={
              <>
                <FlagOutlined />
                Countries
              </>
            }
            key="2"
          >
            <Countries />
          </TabPane>
          <TabPane
            tab={
              <>
                <UploadOutlined />
                Upload
              </>
            }
            key="3"
          >
            <Vendor />
          </TabPane>
        </Tabs>
      </Col>
    </Row>
  );
};

export default Vendors;
