import React from 'react';
import { Select, Switch, Typography, Form } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { getPricelist } from '../Users/services';
import { MCC } from '../Countries/mcc.js';
import { camelize } from '../../libs/helpers';
import mccCountry from './../Countries/mccCountry';

const defValue = 888;
const { Option } = Select;
const { Text } = Typography;

const getPricelistFiltered = async params => {
  const {
    filterReq,
    filters,
    setFilters,
    setUsersPricelist,
    setUserFiltered,
    users,
    setExpandedRowKeys
  } = params;
  const key = Object.keys(filterReq)[0];
  let currentFilters;
  // If default state is selected, then removed it from the filters
  if (
    filterReq[key] === defValue ||
    filterReq[key] === false ||
    !filterReq[key]
  ) {
    const substractFilter = { ...filters };
    delete substractFilter[key];
    currentFilters = substractFilter;
  } else {
    currentFilters = { ...filters, ...filterReq };
  }
  setFilters(currentFilters);
  // If no filters, then no calls
  if (Object.keys(currentFilters).length) {
    const routes = await getPricelist(currentFilters);
    setUsersPricelist(routes);
    setUserFiltered(
      users.filter(({ id }) => Object.keys(routes).includes(`${id}`))
    );
  } else {
    setUsersPricelist({});
    setUserFiltered(false);
  }
  // Close all rows for each change
  setExpandedRowKeys([]);
};

const SelectCustom = params => {
  const {
    dataKey,
    users,
    vendors,
    routing,
    name,
    setLoadingExtendable,
    loading
  } = params;
  let data;
  switch (dataKey) {
    case 'userId':
      data = users.map((user, idx) => (
        <Option value={user.id} key={++idx}>
          {user.name}
        </Option>
      ));
      break;

    case 'mcc':
      const countriesAdded = [];
      data = MCC.filter(item => {
        const mcc = item[0].slice(0, 3);
        if (!countriesAdded.includes(mcc)) {
          countriesAdded.push(mcc);
          return true;
        } else {
          return false;
        }
      })
        .sort((a, b) => a[3].localeCompare(b[3]))
        .map((item, idx) => {
          const mcc = item[0].slice(0, 3);
          return (
            <Option value={mcc} key={++idx}>
              {mccCountry[mcc] || item[3]}
            </Option>
          );
        });
      break;

    case 'vendor':
      data = vendors
        .sort((a, b) => a.localeCompare(b))
        .map((vendor, idx) => (
          <Option value={vendor} key={++idx}>
            {vendor}
          </Option>
        ));
      break;

    case 'table':
      data = routing
        .sort((a, b) => a.name?.localeCompare(b.name))
        .map((item, idx) => (
          <Option value={item.name} key={++idx}>
            {item.name}
          </Option>
        ));
      break;
  }

  return (
    <Form.Item
      key={`${name}`}
      name={`${name}`}
      style={{ minWidth: '140px', marginLeft: '15px' }}
    >
      <Select
        showSearch
        filterOption={(input, option) =>
          option.children.toLowerCase().includes(input.toLowerCase())
        }
        onChange={value => {
          setLoadingExtendable(true);
          getPricelistFiltered({
            filterReq: { [dataKey]: value },
            ...params
          });
          setLoadingExtendable(false);
        }}
        disabled={loading}
        allowClear
      >
        <Option value={defValue} key={0}>
          {name}
        </Option>
        {data}
      </Select>
    </Form.Item>
  );
};

const SwitchCustom = params => {
  const { name, setLoadingExtendable, loading, filters, filtersDefault } =
    params;
  const camelName = camelize(name);
  return (
    <div
      style={{
        minWidth: '100px',
        marginTop: '-12px',
        marginLeft: '15px',
        display: 'flex',
        alignItems: 'center',
        flexFlow: 'column'
      }}
    >
      <Text>{name}</Text>
      <Switch
        checkedChildren={<CheckOutlined />}
        unCheckedChildren={<CloseOutlined />}
        onChange={isActive => {
          setLoadingExtendable(true);
          getPricelistFiltered({
            filterReq: { [camelName]: isActive },
            ...params
          });
          setLoadingExtendable(false);
        }}
        disabled={loading}
        checked={filters[camelName]}
      />
    </div>
  );
};

export { SelectCustom, SwitchCustom };
