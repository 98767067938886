import React, { useState, useCallback, useEffect } from 'react';
import { InputNumber } from 'antd';
import { RedoOutlined } from '@ant-design/icons';
import { DEFAULT_USERS } from '../../config';
import { getPricelist } from '../Users/services';

const Selling = params => {
  let {
    usersPricelist,
    userIdEditing,
    selling,
    row,
    routesChanges,
    setRoutesChanges,
    isMultiselector
  } = params;

  const zeros = selling?.toString().length - 3;
  const step = zeros > 0 ? `0.${'0'.repeat(zeros)}1` : 0;
  const defaultConfig = DEFAULT_USERS.find(
    ({ routing }) => routing === row.table
  );
  const [defaultPricelist, setDefaultPricelist] = useState([]);
  const [defaultData, setDefaultData] = useState();

  const routeItem = (r, price) => {
    const o = {
      userId: r.userId,
      country: r.country,
      network: r.network,
      mccmnc: r.mccmnc,
      price: price,
      oldPrice: usersPricelist[r.userId].find(
        ({ mccmnc }) => mccmnc === r.mccmnc
      )?.price,
      table: r.table,
      change: 'price'
    };
    if (r?.mnpCost) {
      o.mnpCost = r.mnpCost;
    }
    if (r?.cost) {
      o.cost = r.cost;
    }
    return o;
  };

  const priceOnChange = (row, value) => {
    let block;
    if (isMultiselector.includes(parseInt(row.userId))) {
      block = usersPricelist[row.userId]
        .filter(({ mcc }) => mcc === row.mcc)
        .map(r => routeItem(r, value));
    } else {
      block = [routeItem(row, value)];
    }
    const changesUniqs = routesChanges.filter(
      ({ mccmnc }) => !block?.find(r => r.mccmnc === mccmnc)
    );
    const changes = [...changesUniqs, ...block].filter(
      r => r.oldPrice !== r.price
    );
    setRoutesChanges(changes);
  };

  const getValue = () => {
    const newPrice = routesChanges.find(
      ({ mccmnc }) => mccmnc === row.mccmnc
    )?.price;
    return routesChanges.length && newPrice ? newPrice : selling;
  };

  const getDefaultPricelist = async () => {
    const pricelist = await getPricelist({
      userId: defaultConfig.id,
      hiddeMsg: true
    });
    setDefaultPricelist(pricelist);
    const _defaultData = pricelist[defaultConfig.id].find(
      ({ mccmnc }) => mccmnc === row.mccmnc
    );
    setDefaultData(_defaultData);
    return pricelist;
  };

  const resetPrice = async () => {
    // let pricelist = defaultPricelist;
    // if (!defaultPricelist.length) {
    //   pricelist = await getDefaultPricelist();
    // }
    if (defaultData) {
      if (isMultiselector.includes(parseInt(row.userId))) {
        const blockCountry = usersPricelist[row.userId].filter(
          ({ mcc }) => mcc === row.mcc
        );
        blockCountry.forEach(countryRow => {
          priceOnChange(countryRow, defaultData.price);
        });
      } else {
        priceOnChange(row, defaultData.price);
      }
    }
  };

  useEffect(() => {
    if (row.custom && defaultConfig) {
      getDefaultPricelist();
    }
  }, [defaultConfig]);

  return (
    <>
      <InputNumber
        size="small"
        value={getValue()}
        step={step}
        onChange={priceOnChange.bind(null, row)}
        disabled={row.userId !== `${userIdEditing}`}
      />
      {row.custom && defaultConfig && defaultData && (
        <a
          style={{ paddingLeft: '5px' }}
          disabled={row.userId !== `${userIdEditing}`}
          onClick={resetPrice}
        >
          <RedoOutlined />
        </a>
      )}
    </>
  );
};

export default Selling;
