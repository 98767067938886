import { message } from 'antd';

message.config({
  maxCount: 1
});

const getText = name => ({
  loading: `Loading ${name}...`,
  success: `${name.charAt(0).toUpperCase()}${name.slice(1)} loaded`,
  error: `Error loading ${name}...`
});

const messageUI = ({ state, name }) => {
  message[state]({
    content: getText(name)[state],
    key: name
  });
};

export default messageUI;
