import React from 'react';
import { Popconfirm } from 'antd';
import { SendOutlined } from '@ant-design/icons';
import { getPricelist } from '../Users/services';
import { sendEmail } from '../Users/sendEmail';

const NotificationsCurrent = params => {
  let { current, pricelist } = params;

  return (
    <Popconfirm
      title={`${current.name}'s current routing will be send to them`}
      onConfirm={async () => {
        try {
          if (!pricelist) {
            pricelist = (
              await getPricelist({
                userId: current.id
              })
            )?.[current.id];
          }
          await sendEmail({
            userData: current,
            pricelist: pricelist
          });
        } catch (err) {
          console.log(err);
        }
      }}
      cancelText="No, wait"
      okText="Yes, send the email"
    >
      <a>
        <SendOutlined />
      </a>
    </Popconfirm>
  );
};

export default NotificationsCurrent;
