import React from 'react';
import Papa from 'papaparse';
import { Upload } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { vendorPriceCleaner } from './services';

const DraggerComp = params => {
  const { Dragger } = Upload;
  const errors = [];

  const props = {
    name: 'file',
    accept: '.csv',
    customRequest: () => true,
    data: file => {
      Papa.parse(file, {
        header: true,
        transformHeader: h =>
          h
            .split(' ')[0]
            .split('(')[0]
            .toLocaleLowerCase()
            .replace('countryname', 'country')
            .replace('operatorname', 'network')
            .replace('operator', 'network')
            .replace('rate', 'price'),
        complete: async routes => {
          const clean = await vendorPriceCleaner({
            vendor: params.vendorSelected,
            pricelist: routes.data
          });
          params.setCountries(clean);
          if (errors.length > 0) {
            params.setErrorMessage(
              `The countries "${errors.join(
                ', '
              )}" have the same Default's MCCMNC, you must leave it just one`
            );
          } else {
            params.setErrorMessage('');
          }
        }
      });
    }
  };

  return (
    <Dragger {...props} showUploadList={false} style={{ height: '200px' }}>
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">
        Click or drag file to this area to upload
      </p>
      <p className="ant-upload-hint">The file must be a CSV</p>
    </Dragger>
  );
};

export default DraggerComp;
