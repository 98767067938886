import React, { useState, useEffect } from 'react';
import { API, Auth } from 'aws-amplify';
import { Table, Tag, Select, Switch, Typography } from 'antd';
import { getVendors } from '../Users/services';
import { flagEmoji, capitalize } from '../../libs/helpers';
import mccCountry from './mccCountry';
import multiMcc from './multiMcc';

const Countries = () => {
  const { Text } = Typography;
  const { Option } = Select;
  const [vendors, setVendors] = useState([]);
  const [isDefaultActive, setIsDefaultActive] = useState(false);
  const [isPriceOrderActive, setIsPriceOrderActive] = useState(false);
  const [searchCountry, setSearchCountry] = useState('');
  const [searchVendor, setSearchVendor] = useState('');
  const [searchType, setSearchType] = useState('');
  const [searchMccmnc, setSearchMccmnc] = useState('');
  const [countriesList, setCountriesList] = useState([]);
  const [vendorsList, setVendorsList] = useState([]);
  const [typeList, setTypeList] = useState([]);
  const [mccMncList, setMccMncList] = useState([]);

  async function getData() {
    const currentAuth = await Auth.currentAuthenticatedUser();

    try {
      let rawVendors = [];
      let current = {};
      let pagination = 0;
      do {
        if (current?.meta?.pagination?.next) {
          pagination = current.meta.pagination.next;
        }
        current = await API.get(
          'sms-api',
          `/get-vendors-pricelist?pagination=${pagination}`,
          {
            headers: {
              Authorization: currentAuth.signInUserSession.idToken.jwtToken
            }
          }
        );
        rawVendors = [...rawVendors, ...current.data];
      } while (current.meta.pagination?.next);
      const vendorsNames = await getVendors();
      setVendorsList(
        vendorsNames.sort((a, b) => a.vendor.localeCompare(b.vendor))
      );
      const rawVendorsFlat = rawVendors.flat();
      const addCountriesList = [];
      const addMccMncList = [];
      rawVendorsFlat.sort((a, b) => a.country.localeCompare(b.country));
      setVendors(rawVendorsFlat);
      rawVendorsFlat.forEach(r => {
        const mcc = r.mccmnc.slice(0, 3);
        if (
          !addCountriesList.find(
            ({ name }) =>
              name === mccCountry[mcc] ||
              capitalize(name) === capitalize(r.country)
          )
        ) {
          addCountriesList.push({
            name: mccCountry[mcc] || r.country,
            mcc: mcc
          });
        } else if (multiMcc.includes(mcc)) {
          const route = addCountriesList.find(
            route => route.name === mccCountry[mcc] || route.name === r.country
          );
          if (
            route &&
            !route.mcc.includes(',') &&
            route.mcc !== mcc &&
            multiMcc.includes(route.mcc)
          ) {
            route.mcc = `${route.mcc},${mcc}`;
          }
        }
      });
      addCountriesList.sort((a, b) => a.name.localeCompare(b.name));
      setCountriesList(addCountriesList);
      const addTypeList = [];
      vendorsNames.forEach(item => {
        if (item.type && !addTypeList.includes(item.type)) {
          addTypeList.push(item.type);
        }
      });
      setTypeList(addTypeList);
      rawVendorsFlat.forEach(r => {
        if (!addMccMncList.find(mccmnc => mccmnc === r.mccmnc)) {
          addMccMncList.push(r.mccmnc);
        }
      });
      addMccMncList.sort((a, b) => parseInt(a) > parseInt(b));
      setMccMncList(addMccMncList);
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    getData();
  }, []);

  const columns = [
    {
      title: 'Country',
      dataIndex: 'country',
      render: country => (
        <span>
          {country} {flagEmoji(country)}
        </span>
      )
    },
    {
      title: 'Network',
      dataIndex: 'network',
      render: network => (
        <span>
          {network === 'Default' ? (
            <Tag color={'blue'}> {network} </Tag>
          ) : network && network.length > 25 ? (
            `${network.slice(0, 25)}...`
          ) : (
            network
          )}
        </span>
      )
    },
    {
      title: 'Vendor',
      dataIndex: 'vendor'
    },
    {
      title: 'Type',
      render: all => vendorsList.find(item => item.vendor === all.vendor)?.type
    },
    {
      title: 'MccMnc',
      dataIndex: 'mccmnc'
    },
    {
      title: 'Price €',
      dataIndex: 'price',
      render: price => (
        <span> {<Tag color={'green'}> {parseFloat(price)} </Tag>}</span>
      )
    },
    {
      title: (
        <span style={{ visibility: 'hidden' }}>
          '____________________________________'
        </span>
      )
    }
  ];

  return (
    <div
      style={{
        padding: '0 25px 25px'
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: '10px'
        }}
      >
        <Select
          defaultValue="Country"
          showSearch
          filterOption={(input, option) =>
            option.children.toLowerCase().includes(input.toLowerCase())
          }
          onChange={mcc => {
            setSearchCountry(mcc);
          }}
          style={{ minWidth: '180px' }}
          loading={!countriesList.length}
        >
          <Option value="Country" key={0}>
            Country
          </Option>
          {countriesList.map((r, idx) => (
            <Option value={r.mcc} key={++idx}>
              {r.name}
            </Option>
          ))}
        </Select>
        <Select
          defaultValue="Vendor"
          showSearch
          filterOption={(input, option) =>
            option.children.toLowerCase().includes(input.toLowerCase())
          }
          onChange={vendor => {
            setSearchVendor(vendor);
          }}
          style={{ minWidth: '180px', marginLeft: '15px' }}
          loading={!vendorsList.length}
        >
          <Option value="Vendor" key={0}>
            Vendor
          </Option>
          {vendorsList.map((item, idx) => (
            <Option value={item.vendor} key={++idx}>
              {item.vendor}
            </Option>
          ))}
        </Select>
        <Select
          defaultValue="Type"
          showSearch
          filterOption={(input, option) =>
            option.children.toLowerCase().includes(input.toLowerCase())
          }
          onChange={type => {
            setSearchType(type);
          }}
          style={{ minWidth: '120px', marginLeft: '15px' }}
          loading={!typeList.length}
        >
          <Option value="Type" key={0}>
            Type
          </Option>
          {typeList.map((type, idx) => (
            <Option value={type} key={++idx}>
              {type}
            </Option>
          ))}
        </Select>
        <Select
          defaultValue="MccMnc"
          showSearch
          filterOption={(input, option) =>
            option.children.toLowerCase().includes(input.toLowerCase())
          }
          onChange={mccmnc => {
            setSearchMccmnc(mccmnc);
          }}
          style={{ minWidth: '120px', marginLeft: '15px' }}
          loading={!mccMncList.length}
        >
          <Option value="MccMnc" key={0}>
            MccMnc
          </Option>
          {mccMncList.map((mccmnc, idx) => (
            <Option value={mccmnc} key={++idx}>
              {mccmnc}
            </Option>
          ))}
        </Select>
        <Text
          style={{
            marginRight: '10px',
            marginLeft: '20px'
          }}
        >
          Show defaults
        </Text>
        <Switch
          onChange={checked => {
            setIsDefaultActive(checked);
          }}
          style={{ marginLeft: '15px' }}
        />
        <Text
          style={{
            marginRight: '10px',
            marginLeft: '20px'
          }}
        >
          Price order
        </Text>
        <Switch
          onChange={checked => {
            setIsPriceOrderActive(checked);
          }}
          style={{ marginLeft: '15px' }}
        />
      </div>
      <Table
        loading={!vendors.length}
        dataSource={vendors
          .filter(
            r =>
              (searchCountry && searchCountry !== 'Country'
                ? searchCountry.includes(',')
                  ? searchCountry.split(',').includes(r.mccmnc.slice(0, 3))
                  : r.mccmnc.slice(0, 3) === searchCountry
                : true) &&
              (searchVendor && searchVendor !== 'Vendor'
                ? r.vendor === searchVendor
                : true) &&
              (searchType && searchType !== 'Type'
                ? vendorsList.find(item => item.vendor === r.vendor)?.type ===
                  searchType
                : true) &&
              (searchMccmnc && searchMccmnc !== 'MccMnc'
                ? r.mccmnc === searchMccmnc
                : true) &&
              ((isDefaultActive && r.network === 'Default') || !isDefaultActive)
          )
          .sort((a, b) =>
            isPriceOrderActive
              ? parseFloat(a.price) > parseFloat(b.price) ||
                -(parseFloat(a.price) < parseFloat(b.price))
              : 0
          )}
        columns={columns}
        size="small"
        pagination={{
          defaultPageSize: 50,
          showSizeChanger: true,
          pageSizeOptions: ['10', '50', '100']
        }}
        style={{ paddingTop: '10px' }}
      />
    </div>
  );
};

export default Countries;
