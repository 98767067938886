const swapCountries = {
  'Cote d Ivoire': 'Ivory Coast',
  Georgia: 'Georgia Republic'
};

const bannedCountries = [
  'Palestinian',
  'Guam',
  'Norfolk',
  'Puerto Rico',
  'Jersey',
  'Isle of man',
  'Martinique',
  'St Martin',
  'Palestine',
  'South Sahara',
  'Guadeloupe',
  'Guernsey',
  'Mayotte',
  'Curasao',
  'Virgin Islands',
  'Northern Mariana Islands',
  'Morocco Western Sahara',
  'Cayman Islands',
  'St. Martin'
];

export { swapCountries, bannedCountries };
