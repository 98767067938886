import React from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { flagEmoji } from '../../libs/helpers';

const Countries = params => {
  let {
    usersPricelist,
    userIdEditing,
    row,
    user,
    routesChanges,
    setRoutesChanges,
    isMultiselector
  } = params;

  const routeItem = r => ({
    userId: r.userId,
    country: r.country,
    network: r.network,
    mccmnc: r.mccmnc,
    price: '-',
    oldPrice: r.price,
    change: 'removed'
  });

  const removeCountry = async r => {
    let block;
    if (isMultiselector.includes(user.id)) {
      block = usersPricelist[user.id]
        .filter(({ mcc }) => mcc === r.mcc)
        .map(r => routeItem(r));
    } else {
      block = [routeItem(r)];
    }
    const changesUniqs = routesChanges.filter(
      ({ mccmnc }) => !block?.find(r => r.mccmnc === mccmnc)
    );
    const changes = [...changesUniqs, ...block];
    setRoutesChanges(changes);
  };

  return (
    <span>
      {user.id === userIdEditing && (
        <a
          onClick={() => {
            removeCountry(row);
          }}
        >
          <DeleteOutlined style={{ marginRight: '10px' }} />
        </a>
      )}
      {row.country} {flagEmoji(row.country)}
    </span>
  );
};

export default Countries;
